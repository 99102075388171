import { Inject, Injectable, Injector }from '@angular/core';


import { BarTender_ds_get_receiving_tasks_by_orderIdService } from './BarTender.datasource.index';

@Injectable({ providedIn: 'root' })
export class BarTender_DatasourceService {

  constructor(
    private injector: Injector
  ) {
  }

  public BarTender: BarTender_DatasourceService = this;

  // injecting lazily in order to avoid circular dependencies
  private _ds_get_receiving_tasks_by_orderId: BarTender_ds_get_receiving_tasks_by_orderIdService;
  public get ds_get_receiving_tasks_by_orderId(): BarTender_ds_get_receiving_tasks_by_orderIdService {
    if(!this._ds_get_receiving_tasks_by_orderId) {
      this._ds_get_receiving_tasks_by_orderId = this.injector.get(BarTender_ds_get_receiving_tasks_by_orderIdService);
    }
    return this._ds_get_receiving_tasks_by_orderId;
  }
}

