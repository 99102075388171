import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $types } from './app.types'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'
import { Materials_measurement_units_dd_singleComponent } from './Materials.measurement_units_dd_single.component'


interface IFootPrintManager_purchase_order_lines_gridComponentEntity {
OrderId?: number, LineNumber?: number, GrossWeight?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, Price?: number, StatusId?: number, TotalPrice?: number, VendorLotId?: number, WeightUomId?: number, Material?: { Description?: string, LookupCode?: string }, Lot?: { LookupCode?: string }, VendorLot?: { LookupCode?: string }, InventoryMeasurementUnit?: { ShortName?: string }, Status?: { Name?: string }, WeightUom?: { Name?: string, Short_name?: string }, ShipmentLines?: { ActualPackagedAmount?: number, ActualPackagedId?: number }[]}

interface IFootPrintManager_purchase_order_lines_gridComponentInParams {
  orderId: number, shipmentId: number, projectId: number, orderStatusId: number}


class FootPrintManager_purchase_order_lines_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_purchase_order_lines_gridComponent;
  entity: IFootPrintManager_purchase_order_lines_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    material_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    packaged_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    gross_weight_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    uom_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    price_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    error: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    linenumber: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    new_material: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(null, null), false, ' ', 'ms-Icon ms-Icon--Add')
,
null
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['packaged_amount_edit'] as DatexFormControl, null, false, '', '')
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    gross_weight: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['gross_weight_edit'] as DatexFormControl, null, false, '0.00', '')
      ),
    uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['uom_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    price: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['price_edit'] as DatexFormControl, null, false, '0.0000', '')
      ),
    total_price: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
  }

  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.projectId;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.vendorlot?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.lot?.editControl?.value;
    
    return expr;
  }
  get $fields_uom_selector_inParams_typeId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 3;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) {
    super();
    
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
  }

  async $initializeExisting(grid: FootPrintManager_purchase_order_lines_gridComponent, entity: IFootPrintManager_purchase_order_lines_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_purchase_order_lines_gridComponent, entity?: IFootPrintManager_purchase_order_lines_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.OrderId,this.entity.LineNumber].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'OrderId',this.entity.OrderId);
    set($resultKey, 'LineNumber',this.entity.LineNumber);
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.app.custom_ds_purchase_order_lines_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.linenumber.displayControl as TextModel).text = $row.entity.LineNumber?.toString();
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.material.displayControl as TextModel).text = $row.entity.Material?.LookupCode;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity.MaterialId);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.InventoryMeasurementUnit?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity.PackagedId);
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.packaged_amount.editControl as NumberBoxModel).reset($row.entity.PackagedAmount);
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity.LotId);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity.VendorLotId);
    (this.cells.gross_weight.displayControl as TextModel).text = $row.entity.GrossWeight?.toString();
    (this.cells.gross_weight.editControl as NumberBoxModel).reset($row.entity.GrossWeight);
    (this.cells.uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name;
    (this.cells.uom.editControl as SelectBoxModel).reset($row.entity.WeightUomId);
    (this.cells.price.displayControl as TextModel).text = $row.entity.Price?.toString();
    (this.cells.price.editControl as NumberBoxModel).reset($row.entity.Price);
    (this.cells.total_price.displayControl as TextModel).text = $row.entity.TotalPrice?.toString();
    (this.cells.description.displayControl as TextModel).text = $row.entity.Material?.Description;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  $row.entity = {
      OrderId: $grid.inParams.orderId,
      StatusId: 1,
      PackagedAmount: null
  };
  
  // Unlock new material button
  $row.cells.new_material.displayControl.styles.resetStyle();
  $row.cells.new_material.displayControl.readOnly = false;
  
  // Lock down the weight and weight uom fields
  $row.cells.gross_weight.editControl.readOnly = true;
  $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
  $row.cells.uom.editControl.readOnly = true;
  $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
  // Focus on the material cell
  $row.cells.material.editControl.focus();
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.material.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.packaged_amount.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  if ($utils.isDefined($row.cells.gross_weight.editControl.value) && !$utils.isDefined($row.cells.uom.editControl.value)) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing weight Uom');
      throw new Error('Missing weight Uom'); // to prevent displayMode 
  }
  
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  if ($utils.isDefined($row.cells.price.editControl.value)) {
      if ($row.cells.price.editControl.value <= 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Order line price must be greater than 0.');
          throw new Error('Order line price must be greater than 0.'); // to prevent displayMode 
      }
  }
  
  var orderStatusId = $grid.inParams.orderStatusId;
  if (orderStatusId === 1) {
  
      try {
          var linenumber;
          var orderId = $grid.inParams.orderId;
  
  
  
          if ($utils.isAllDefined($row.cells.gross_weight.editControl.value, $row.cells.uom.editControl.value)) {
              var grossWeight = $row.cells.gross_weight.editControl.value;
              var uom = $row.cells.uom.editControl.value;
          }
  
          // Lot provided
          if ($utils.isDefined($row.cells.lot.editControl.value)) {
              const orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  lotId: $row.cells.lot.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipmentId
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
  
          }
          // Vendor lot provided but lot is null
          else if ($utils.isDefined($row.cells.vendorlot.editControl.value) && !$utils.isDefined($row.cells.lot.editControl.value)) {
              const orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  vendorlotId: $row.cells.vendorlot.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipmentId
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
  
          }
          // Material only provided
          else {
              const orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                  orderId: orderId,
                  packagedAmount: $row.cells.packaged_amount.editControl.value,
                  materialId: $row.cells.material.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  shipmentId: $grid.inParams.shipmentId
              }));
              if ($utils.isDefined(orderLine)) {
  
                  if ($utils.isDefined(orderLine.linenumber)) {
                      linenumber = orderLine.linenumber;
                      $row.entity.LineNumber = linenumber;
                      $row.entity.OrderId = orderId;
                  }
                  else {
                      throw new Error('Unable to create order line.')
                  }
              }
              else {
                  throw new Error('Unable to create order line.')
              }
  
  
          }
  
          if ($utils.isDefined(linenumber)) {
              const payload = {
                  GrossWeight: $row.cells.gross_weight.editControl.value,
                  NetWeight: $row.cells.gross_weight.editControl.value,
                  WeightUomId: $row.cells.uom.editControl.value,
                  Notes: $row.cells.notes.editControl.value,
                  Price: $row.cells.price.editControl.value
  
              }
  
              if ($utils.isDefined(payload)) {
                  await $flows.Utilities.crud_update_dual_pk_flow({
                      entitySet: 'OrderLines',
                      pk1String: 'OrderId',
                      pk1: orderId,
                      pk2String: 'LineNumber',
                      pk2: linenumber,
                      entity: payload
                  });
  
              }
  
          }
  
  
          // Clear any errors then refresh
          $row.cells.error.displayControl.text = '';
          await $grid.refresh();
  
      } catch (error) {
          $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      }
  
  }
  // Legacy implementation to add a order line after the order has been processed, to be removed with oData API Actions
  else if (orderStatusId === 2) {
  
      try {
  
          // Check if material is  g-lot controlled
          const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
              materialId: $row.cells.material.editControl.value
          })).result;
  
          var materialControllerTypeId = material[0].ControllerTypeId;
          if (materialControllerTypeId === 1 || materialControllerTypeId === 3) {
  
              const lot = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
              if ($utils.isDefined(lot) && lot[0].TypeId === 1) {
                  var lotId = lot[0].Id;
                  var vendorLotId = lot[0].VendorLotId;
              }
          }
  
          const result = await $flows.PurchaseOrders.create_purchase_order_line_in_processing_status_flow({
              projectId: $grid.inParams.projectId,
              orderId: $grid.inParams.orderId,
              shipmentId: $grid.inParams.shipmentId,
              materialId: $row.cells.material.editControl.value,
              packagingId: $row.cells.packaging.editControl.value,
              packagedAmount: $row.cells.packaged_amount.editControl.value,
              lotId: $utils.isDefined(lotId) ? lotId : $row.cells.lot.editControl.value,
              vendorLotId: $utils.isDefined(vendorLotId) ? vendorLotId : $row.cells.vendorlot.editControl.value,
              grossWeight: $row.cells.gross_weight.editControl.value,
              weightUom: $row.cells.uom.editControl.value,
              notes: $row.cells.notes.editControl.value
          });
  
          if ($utils.isDefined(result)) {
              if ($utils.isDefined(result.lineNumber)) {
                  $row.entity.LineNumber = result.lineNumber;
                  await $grid.refresh();
  
              }
          }
  
      } catch (error) {
          const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
          const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
          const errorDescription = errorMessage;
          await $shell.FootPrintManager.openErrorDialog('Order line error', 'An error occurred during while adding the order line', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
      }
  }
  
  
  else {
      $shell.FootPrintManager.openErrorDialog('Save', 'Order is not in a created or processing status');
      throw new Error('Order is not in a created or processing status'); // to prevent displayMode 
  
  }
  
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.cells.lot.editControl.value)) {
      const lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({
          lotId: $row.cells.lot.editControl.value
      })).result;
  
      if ($utils.isDefined(lot)) {
          $row.cells.vendorlot.editControl.value = lot[0].VendorLotId;
      }
  } else {
      $row.cells.vendorlot.editControl.value = null;
  }
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $row.cells.lot.editControl.value = null;
  
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $row.cells.packaging.editControl.value = null;
  $row.cells.gross_weight.editControl.value = null;
  $row.cells.uom.editControl.value = null;
  $row.cells.lot.editControl.value = null;
  $row.cells.vendorlot.editControl.value = null;
  $row.cells.gross_weight.displayControl.text = null;
  $row.cells.uom.displayControl.text = null;
  $row.cells.lot.displayControl.text = null;
  $row.cells.vendorlot.displayControl.text = null;
  
  
  var materialId = $row.cells.material.editControl.value;
  
  
  if ($utils.isDefined(materialId)) {
  
   // Default the packaging
          const defaultPackaging = (await $flows.Materials.get_material_default_packaging({ materialId: materialId }));
          var packagingId = defaultPackaging.packagingId;
  
          if ($utils.isDefined(packagingId)) {
  
              $row.cells.packaging.editControl.value = packagingId;
       
  
  
              // Default the decimal formatting
              const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
                  materialId: materialId,
                  packagingId: packagingId
              }));
  
              if ($utils.isDefined(decimalFormat)) {
                  $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
              }
  
          }
  
  
  
      // Extract packaging detail
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId:packagingId
      }))?.result;
  
      // Check if material is serial controlled and or lot controlled
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      const materialControllerTypeId = material[0].ControllerTypeId;
      const materialIsFixedWeight = material[0].IsFixedWeight;
      const materialIsFixedLength = material[0].IsFixedLength;
      const materialIsFixedVolume = material[0].IsFixedVolume;
  
      // Serial controlled
      if (materialIsFixedWeight === false || materialControllerTypeId === 3 || materialControllerTypeId === 4 || materialIsFixedLength === false ||
          materialIsFixedVolume === false) {
          $row.cells.gross_weight.editControl.readOnly = false;
          $row.cells.gross_weight.editControl.styles.resetStyle();
          $row.cells.uom.editControl.readOnly = false;
          $row.cells.uom.editControl.styles.resetStyle();
          const weightUomId = packaging[0].WeightUomId;
          if ($utils.isDefined(weightUomId)) {
              $row.cells.uom.editControl.value = weightUomId;
          }
  
  
      } else {
          $row.cells.gross_weight.editControl.readOnly = true;
          $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.uom.editControl.readOnly = true;
          $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
         
      }
  
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
          $row.cells.lot.editControl.readOnly = false;
          $row.cells.lot.editControl.styles.resetStyle();
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
  
      } else {
          $row.cells.lot.editControl.readOnly = true;
          $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
  
      }
  
  
  
  }
  
  
  
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  var materialId = $row.entity.MaterialId;
  
  if ($utils.isDefined(materialId)) {
  
      // Check if material is serial controlled and or lot controlled
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      const materialControllerTypeId = material[0].ControllerTypeId;
      const materialIsFixedWeight = material[0].IsFixedWeight;
      const materialIsFixedLength = material[0].IsFixedLength;
      const materialIsFixedVolume = material[0].IsFixedVolume;
  
      // Serial controlled
      if (materialIsFixedWeight === false || materialControllerTypeId === 3 || materialControllerTypeId === 4 || materialIsFixedLength === false ||
          materialIsFixedVolume === false) {
          $row.cells.gross_weight.editControl.readOnly = false;
          $row.cells.gross_weight.editControl.styles.resetStyle();
          $row.cells.uom.editControl.readOnly = false;
          $row.cells.uom.editControl.styles.resetStyle();
  
      } else {
          $row.cells.gross_weight.editControl.readOnly = true;
          $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.uom.editControl.readOnly = true;
          $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
      }
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
          $row.cells.lot.editControl.readOnly = false;
          $row.cells.lot.editControl.styles.resetStyle();
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
      } else {
          $row.cells.lot.editControl.readOnly = true;
          $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
      }
  
  
  
      // Lock down new lot and new material button if the order is not in a created status
      if ($grid.inParams.orderStatusId !== 1) {
          $row.cells.new_material.displayControl.styles.setStyle('background', 'transparent');
          $row.cells.new_material.displayControl.readOnly = true;
      }
      // Get material packaging properties to determine what UOM items were received in. 
      var materialPackaging = (await $datasources.Materials.ds_get_material_packagings_details_by_materialId.get({ materialId: materialId })).result
      var basePackageQty = 1
      for (let packaging of materialPackaging) {
          if (packaging.PackagingId === $row.entity.ShipmentLines[0]?.ActualPackagedId) {
              var pickedPackaging = packaging.PackagingId
          }
          if (packaging.PackagingId === $row.entity.PackagedId) {
              var orderLinePackaging = packaging.PackagingId
              if (packaging.IsBasePackaging === true) {
                  var basePackaging = true
              } else {
                  basePackaging = false
                  basePackageQty = packaging.BasePackagingQuantity
              }
          }
      }
  
  }
  
  // Set Expected / Actual calculation
  let list: number[] = [1, 8]; // Created and Cancelled
  if (!($grid.inParams.orderStatusId in list) && $utils.isDefined($row.entity.PackagedAmount)) {
      const actualPackagedAmount = $utils.isDefined($row.entity.ShipmentLines[0]?.ActualPackagedAmount) ? $row.entity.ShipmentLines[0]?.ActualPackagedAmount : 0;
      if (pickedPackaging === orderLinePackaging) {
          var calculation = `${actualPackagedAmount} of ${$row.entity?.PackagedAmount}`;
      } else {
          calculation = `${(actualPackagedAmount / basePackageQty) % 1 !== 0 ? (actualPackagedAmount / basePackageQty).toFixed(2) : (actualPackagedAmount / basePackageQty)} of ${$row.entity?.PackagedAmount}`;
      }
      $row.cells.packaged_amount.displayControl.text = calculation
  
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.material.editControl.value) &&
      $utils.isDefined($row.cells.packaging.editControl.value) &&
      $utils.isDefined($row.cells.packaged_amount.editControl.value);
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  
  const payload: any = {};
  if ($row.cells.material.editControl.isChanged) {
      payload.MaterialId = $row.cells.material.editControl.value;
  }
  if ($row.cells.packaging.editControl.isChanged) {
      payload.PackagedId = $row.cells.packaging.editControl.value;
  }
  if ($row.cells.packaged_amount.editControl.isChanged) {
      if ($row.cells.packaged_amount.editControl.value <= 0) {
          $shell.FootPrintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
          throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
      }
      else {
          payload.PackagedAmount = $row.cells.packaged_amount.editControl.value;
      }
  }
  if ($row.cells.lot.editControl.isChanged) {
      payload.LotId = $row.cells.lot.editControl.value;
  }
  if ($row.cells.vendorlot.editControl.isChanged) {
      payload.VendorLotId = $row.cells.vendorlot.editControl.value;
  }
  if ($row.cells.gross_weight.editControl.isChanged) {
      payload.GrossWeight = $row.cells.gross_weight.editControl.value;
      payload.NetWeight = $row.cells.gross_weight.editControl.value;
  }
  if ($row.cells.uom.editControl.isChanged) {
      payload.WeightUomId = $row.cells.uom.editControl.value;
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  if ($row.cells.price.editControl.isChanged) {
      payload.Price = $row.cells.price.editControl.value;
  }
  try {
  
  
      await $flows.Utilities.crud_update_dual_pk_flow({
          entitySet: 'OrderLines',
          pk1String: 'OrderId',
          pk1: $row.entity.OrderId,
          pk2String: 'LineNumber',
          pk2: $row.entity.LineNumber,
          entity: payload
      });
  
  
      await $row.refresh();
  } catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  var materialId = $row.cells.material.editControl.value;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  if ($utils.isAllDefined(materialId, packagingId)) {
  
      // Default weight Uom
      const packaging = (await $datasources.Materials.ds_get_material_packaging_by_materialId_and_packagingId.get({
          materialId: materialId,
          packagingId: packagingId
      }))?.result;
  
      if ($utils.isDefined(packaging)) {
          if ($utils.isDefined(packaging[0].WeightUomId)) {
              const uomId = packaging[0].WeightUomId;
              $row.cells.uom.editControl.value = uomId;
          }
      }
  
  
  
      // Default the decimal formatting
      const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
          materialId: materialId,
          packagingId: packagingId
      }));
  
      if ($utils.isDefined(decimalFormat)) {
          $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
      }
  
  }
  
  }
  on_new_material_clicked(event = null) {
    return this.on_new_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_material_clickedInternal(
    $row: FootPrintManager_purchase_order_lines_gridComponentRowModel,
  $grid: FootPrintManager_purchase_order_lines_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openmaterial_creation_formDialog({ projectId: $grid.inParams.projectId }, 'modal');
  const userConfirmed = dialogResult.confirm;
  const materialId = dialogResult.materialId;
  
  
  
  if (userConfirmed === true && $utils.isDefined(materialId)) {
  
  
      // Check if material is serial controlled and or lot controlled
      const material = (await $datasources.Materials.ds_get_material_by_materialId.get({
          materialId: materialId
      })).result;
  
      var materialControllerTypeId = material[0].ControllerTypeId;
      var materialIsFixedWeight = material[0].IsFixedWeight;
      // Serial controlled
      if (materialIsFixedWeight === false || materialControllerTypeId === 3 || materialControllerTypeId === 4) {
          $row.cells.gross_weight.editControl.readOnly = false;
          $row.cells.gross_weight.editControl.styles.resetStyle();
          $row.cells.uom.editControl.readOnly = false;
          $row.cells.uom.editControl.styles.resetStyle();
  
      } else {
          $row.cells.gross_weight.editControl.readOnly = true;
          $row.cells.gross_weight.editControl.styles.setStyle('background', 'transparent');
          $row.cells.uom.editControl.readOnly = true;
          $row.cells.uom.editControl.styles.setStyle('background', 'transparent');
  
      }
      // Lot controlled
      if (materialControllerTypeId === 2 || materialControllerTypeId === 4) {
          $row.cells.lot.editControl.readOnly = false;
          $row.cells.lot.editControl.styles.resetStyle();
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.vendorlot.editControl.styles.resetStyle();
      } else {
          $row.cells.lot.editControl.readOnly = true;
          $row.cells.lot.editControl.styles.setStyle('background', 'transparent');
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.vendorlot.editControl.styles.setStyle('background', 'transparent');
      }
  
      const basePackaging = (await $datasources.Materials.ds_get_materials_basepackaging_by_materialId.get({ materialId: materialId })).result;
      if ($utils.isDefined(basePackaging)) {
  
          var packagingId = basePackaging.PackagingId;
  
      } else {
          throw new Error('Error creating new material')
      }
  
      if ($utils.isDefined(packagingId)) {
  
          if ($utils.isAllDefined($row.entity.LineNumber, $row.entity.OrderId)) {
              try {
                  const payload: any = {};
                  // None Lot controlled
                  if (materialControllerTypeId === 1 || materialControllerTypeId === 3) {
  
                      const lot = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: materialId })).result;
                      if ($utils.isDefined(lot) && lot[0].TypeId === 1) {
  
                          payload.LotId = lot[0].Id;
                          payload.VendorLotId = lot[0].VendorLotId;
  
                      }
                      else {
                          payload.LotId = null;
                          payload.VendorLotId = null;
                      }
                  }
  
                  else {
                      payload.LotId = null;
                      payload.VendorLotId = null;
                  }
                  payload.MaterialId = materialId;
                  payload.PackagedId = packagingId
  
                  payload.WeightUomId = null;
                  payload.GrossWeight = null;
                  payload.NetWeight = null;
  
                  await $flows.Utilities.crud_update_dual_pk_flow({
                      entitySet: 'OrderLines',
                      pk1String: 'OrderId',
                      pk1: $row.entity.OrderId,
                      pk2String: 'LineNumber',
                      pk2: $row.entity.LineNumber,
                      entity: payload
                  });
                  await $row.refresh();
  
  
              } catch (error) {
                  $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
                  throw error; // to prevent displayMode 
              }
          }
          else {
              $row.cells.material.editControl.value = materialId;
              $row.cells.packaging.editControl.value = packagingId;
  
          }
  
      }
      else {
          throw new Error('Error creating new material')
      }
  
  }
  
  
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_measurement_units_dd_singleComponent),
  ],
  selector: 'FootPrintManager-purchase_order_lines_grid',
  templateUrl: './FootPrintManager.purchase_order_lines_grid.component.html'
})
export class FootPrintManager_purchase_order_lines_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_purchase_order_lines_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_purchase_order_lines_gridComponentInParams = { orderId: null, shipmentId: null, projectId: null, orderStatusId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     error: new GridHeaderModel(new HeaderStyles(null, null), 'Error', false, false, null),       linenumber: new GridHeaderModel(new HeaderStyles(null, null), '#', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       new_material: new GridHeaderModel(new HeaderStyles(null, null), ' ', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', true, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', true, false, null),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Quantity', true, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor Lot', false, false, null),       gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'Gross Weight', false, false, null),       uom: new GridHeaderModel(new HeaderStyles(null, null), 'Uom', false, false, null),       price: new GridHeaderModel(new HeaderStyles(null, null), 'Price', false, false, null),       total_price: new GridHeaderModel(new HeaderStyles(null, null), 'Total Price', false, false, null),       description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_purchase_order_lines_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('shipmentId') set $inParams_shipmentId(value: any) {
    this.inParams['shipmentId'] = value;
  }
  get $inParams_shipmentId(): any {
    return this.inParams['shipmentId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }

  topToolbar = {
      linecount: new ToolModel(new ButtonModel('linecount', new ButtonStyles(null, null), false, 'LineCount', '')
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    ) {
    this.title = 'Order Lines';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.shipmentId)) {
        this.$missingRequiredInParams.push('shipmentId');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
      if(isNil(this.inParams.orderStatusId)) {
        this.$missingRequiredInParams.push('orderStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.app.custom_ds_purchase_order_lines_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_purchase_order_lines_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_purchase_order_lines_gridComponentEntity) {
    const row = new FootPrintManager_purchase_order_lines_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Disable Delete button
  $grid.topToolbar.on_delete.control.readOnly = true;
  
  // Hide line count on initial load
  $grid.topToolbar.linecount.hidden = true;
  
  
  const orderStatusId = $grid.inParams.orderStatusId;
  
  // Created
  if (orderStatusId === 1) {
  
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.canEdit = true;
      $grid.headers.material.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.packaged_amount.required = true;
  
  
  }
  // Processing
  else if (orderStatusId === 2) {
      $grid.headers.material.required = true;
      $grid.headers.packaging.required = true;
      $grid.headers.packaged_amount.required = true;
      $grid.canEdit = false;
      $grid.bottomToolbar.addLine.control.readOnly = false;
      $grid.headers.packaged_amount.name = 'Expected';
  
  }
  // Cancelled
  else if (orderStatusId === 8) {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.canEdit = false;
      $grid.headers.material.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.packaged_amount.required = false;
      $grid.headers.packaged_amount.name = 'Quantity';
  
  }
  else {
  
      $grid.bottomToolbar.addLine.control.readOnly = true;
      $grid.canEdit = false;
      $grid.headers.material.required = false;
      $grid.headers.packaging.required = false;
      $grid.headers.packaged_amount.required = false;
      $grid.headers.packaged_amount.name = 'Expected';
  
  }
  
  
  
  
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const orderStatusId = $grid.inParams.orderStatusId;
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  // One or more rows selected and order status is created
  if (selectedRowsCount > 0 && orderStatusId === 1) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
  
  }
  
  
  
  
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Order Line Delete Error', 'No order lines selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              orderId: row.entity.OrderId,
              lineNumber: row.entity.LineNumber
          };
          const result = await $flows.PurchaseOrders.is_purchase_order_line_deletable_flow(flowParams);
          const reasons = result.reasons;
          if ($utils.isDefined(reasons)) {
              failures.push(row);
              errors.push(`Order Line ${row.entity.LineNumber.toString()} ${reasons.map(r => r.toLowerCase()).join(', ').replace(/, ([^,]*)$/, ', and $1')}.`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete order line errors';
          const errorMessage = `Order line(s) ${failures.map(c => c.entity.LineNumber.toString()).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Order line(s) ${candidates.map(c => c.entity.LineNumber.toString()).join(',')} - Once deleted, the order line(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some order line(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following order line(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams = {
                          orderId: candidate.entity.OrderId,
                          lineNumber: candidate.entity.LineNumber
                      }
                      const result = await $flows.PurchaseOrders.delete_purchase_order_line_flow(flowParams);
  
                      processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Order line ${candidate.entity.LineNumber.toString()} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All order line(s) deleted';
                  const message = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All order line(s) failed to delete';
                      const message = `Order line(s) ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some order lines could not be deleted';
                      const success = `Order line(s) ${processSuccess.map(c => c.entity.LineNumber.toString()).join(',')} were deleted.`;
                      const errors = `The following order lin(s) could not be deleted: ${processFailures.map(c => c.entity.LineNumber.toString()).join(',')}`;
                      const message = `${success} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_excel_import(event = null) {
    return this.on_excel_importInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_importInternal(
    $grid: FootPrintManager_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const orderStatusId = $grid.inParams.orderStatusId;
  
  if (orderStatusId !== 1) {
      throw new Error('Order must be in a created status to import new order lines.')
  }
  
  const project = (await $datasources.PurchaseOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  var projectLookupcode: string[] = [project.Project?.LookupCode];
  
  
  
  const wb = $event;
  const wsname = wb.SheetNames[0];
  const ws = wb.Sheets[wsname];
  
  $grid.topToolbar.linecount.hidden = false;
  $grid.topToolbar.linecount.control.icon = "icon ms-Icon ms-Icon--ProgressRingDots";
  $grid.topToolbar.linecount.control.label = 'Extracting Data..';
  
  const OrderLines = $utils.excel.sheet_to_json<{
      MaterialLookupCode: string,
      Packaging: string,
      Quantity: number,
      Lot: string,
      VendorLot: string,
      GrossWeight: string,
      Uom: string,
      Notes: string,
  
  }>(ws);
  
  $grid.topToolbar.linecount.control.label = 'Preparing Data...';
  
  // Initialize grid and data
  const candidates = [];
  var linecount = 0;
  var confirmLineCount = 0;
  var orderCheckLineCount = 0;
  var totalLineCount = OrderLines.length;
  
  if (totalLineCount > 150) {
      $grid.topToolbar.linecount.hidden = true;
      throw new Error('Excel file cannot exceed 150 rows, please break file into smaller groups of order lines.')
  }
  
  var materialsLookupCode = OrderLines.filter(ol => $utils.isDefinedTrimmed(ol.MaterialLookupCode?.toString())).map(ol => ol.MaterialLookupCode?.toString());
  var materialPackagings = OrderLines.filter(ol => $utils.isDefinedTrimmed(ol.Packaging?.toString())).map(ol => ol.Packaging?.toString());
  
  var packagingMaterialsProjects = (await $datasources.Materials.ds_get_packagings_by_materials_projects_packagings.get({
      materialPackagings: materialPackagings,
      materialLookupcodes: materialsLookupCode,
      projectLookupcodes: projectLookupcode
  
  })).result;
  
  
  for (const OrderLine of OrderLines) {
  
  
      linecount = linecount + 1;
      $grid.topToolbar.linecount.control.label = 'Validating Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      var errors = '';
  
  
      // Add row to grid
      const row = await $grid.addRow({
      });
  
  
  
      if ($utils.isAllDefined(OrderLine.Packaging, OrderLine.MaterialLookupCode, project.Project.LookupCode)) {
          const packagingMaterialProjectFound = packagingMaterialsProjects.find(
              p => p.Packaging.ShortName === OrderLine.Packaging
                  && p.Material.LookupCode === OrderLine.MaterialLookupCode
                  && p.Material.Project.LookupCode === project.Project.LookupCode
  
          );
          if ($utils.isDefined(packagingMaterialProjectFound)) {
              var packagingId = packagingMaterialProjectFound.PackagingId;
              var materialId = packagingMaterialProjectFound.MaterialId;
              var projectId = packagingMaterialProjectFound.Material.Project.Id;
              row.cells.packaging.editControl.value = packagingId;
              row.cells.material.editControl.value = materialId;
  
  
              if ($utils.isDefined(OrderLine.Lot)) {
  
                  var lotLookupcode = OrderLine.Lot.toString();
                  var vendorLotLookupcode = OrderLine.VendorLot.toString();
  
                  const lotcontrolled = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result;
                  // Check if material is lot controlled
                  if ($utils.isDefined(lotcontrolled) && (lotcontrolled[0].ControllerTypeId === 2 || lotcontrolled[0].ControllerTypeId === 4)) {
                      const lot = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
                          materialId: materialId,
                          lotLookupcode: lotLookupcode,
                          vendorLookupcode: $utils.isDefined(vendorLotLookupcode) ? vendorLotLookupcode : lotLookupcode
                      })).result;
                      if ($utils.isDefined(lot)) {
                          row.cells.lot.editControl.value = lot[0].Id;
                          row.cells.vendorlot.editControl.value = lot[0].VendorLotId;
  
                      } else {
  
                          var vendorLotId;
  
                          const vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
                              materialId: materialId,
                              vendorLookupcode: $utils.isDefined(vendorLotLookupcode) ? vendorLotLookupcode : lotLookupcode
                          })).result;
                          if ($utils.isDefined(vendorLot)) {
                              var foundVendorLotId = vendorLot[0].Id
                              vendorLotId = foundVendorLotId
                          }
  
                          else {
  
                              vendorLotId = (await $flows.Lots.create_vendor_lot({
                                  materialId: materialId,
                                  lookupCode: $utils.isDefined(vendorLotLookupcode) ? vendorLotLookupcode : lotLookupcode,
                                  manufactureDate: $utils.date.now(),
                                  expirationDate: $utils.date.now()
                              })).vendorLotId;
                          }
  
                          var lotId = (await $flows.Lots.create_lot({
                              lookupCode: lotLookupcode,
                              vendorLotId: vendorLotId,
                              statusId: 1
                          })).lotId;
  
                          if ($utils.isAllDefined(lotId, vendorLotId)) {
  
                              row.cells.lot.editControl.value = lotId
                              row.cells.vendorlot.editControl.value = vendorLotId
                          }
                          else {
                              row.cells.lot.editControl.value = null;
                              row.cells.vendorlot.editControl.value = null;
  
                              errors += 'Lot '
                                  + lotLookupcode
                                  + ', VendorLot '
                                  + $utils.isDefined(vendorLotLookupcode) ? vendorLotLookupcode : lotLookupcode
                                  + ' could not be created' + ' : ';
  
                          }
  
                      }
                  }
  
              }
  
          } else {
              row.cells.packaging.editControl.value = null;
              row.cells.material.editControl.value = null;
  
              errors += 'Packaging '
                  + OrderLine.Packaging
                  + ', material '
                  + OrderLine.MaterialLookupCode
                  + ' could not be identified' + ' : ';
              row.cells.packaging.editControl.readOnly = false;
              row.cells.material.editControl.readOnly = false;
  
          }
      } else {
          errors += 'Packaging, material and or project not provided' + ' : ';
          row.cells.packaging.editControl.readOnly = false;
          row.cells.material.editControl.readOnly = false;
      }
  
  
  
      if (!$utils.isDefined(OrderLine.Quantity)) {
          errors += 'Quantity not provided' + ' : '
      } else {
          if (parseInt(OrderLine.Quantity.toString(), 10) > 0) {
              row.cells.packaged_amount.editControl.value = OrderLine.Quantity;
          } else {
              errors += 'Quantity not a number' + ' : '
          }
      }
  
      if ($utils.isDefined(OrderLine.Notes)) {
          row.cells.notes.editControl.value = OrderLine.Notes
      }
  
      if ($utils.isAllDefined(OrderLine.Uom, OrderLine.GrossWeight)) {
  
          var uom = OrderLine.Uom.toString();
  
          const weightUom = (await $datasources.Materials.ds_get_measurement_unit_by_shortname.get({ shortName: uom })).result;
          if ($utils.isDefined(weightUom)) {
  
              if (parseInt(OrderLine.GrossWeight.toString(), 10) > 0) {
                  row.cells.gross_weight.editControl.value = OrderLine.GrossWeight;
                  row.cells.uom.editControl.value = weightUom[0].Id;
              } else {
                  errors += 'Gross weight not a number' + ' : '
              }
          }
          else {
              errors += 'Weight uom not available'
          }
  
      }
  
      $grid.topToolbar.linecount.control.label = 'Validated Line ' + linecount.toString() + ' of ' + totalLineCount.toString();
  
      row.cells.error.displayControl.text = errors;
  
      candidates.push({ row: row, errors: errors });
  
  
  }
  
  $grid.topToolbar.linecount.control.label = 'Gathering Data...'
  $grid.topToolbar.linecount.control.label = 'Updating Grid...'
  var totalConfirmLineCount = candidates.length;
  
  for (const candidate of candidates) {
      const noErrors = candidate.errors === '';
      if (noErrors) {
  
          try {
  
              confirmLineCount = confirmLineCount + 1;
              $grid.topToolbar.linecount.control.label = 'Creating Order Line ' + confirmLineCount.toString() + ' of ' + totalConfirmLineCount.toString();
  
              var orderLine;
  
  
              if ($utils.isDefined(candidate.row.cells.lot.editControl.value)) {
                  orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                      orderId: $grid.inParams.orderId,
                      packagedAmount: candidate.row.cells.packaged_amount.editControl.value,
                      lotId: candidate.row.cells.lot.editControl.value,
                      packagingId: candidate.row.cells.packaging.editControl.value,
                      shipmentId: $grid.inParams.shipmentId
                  }));
  
              }
              else {
                  orderLine = (await $flows.PurchaseOrders.create_purchase_order_line_flow({
                      orderId: $grid.inParams.orderId,
                      packagedAmount: candidate.row.cells.packaged_amount.editControl.value,
                      materialId: candidate.row.cells.material.editControl.value,
                      packagingId: candidate.row.cells.packaging.editControl.value,
                      shipmentId: $grid.inParams.shipmentId
                  }));
              }
  
              if (!$utils.isDefined(orderLine)) {
                  errors += 'Error calling create order line API'
                  candidate.row.cells.error.displayControl.text = errors;
              }
              else {
                  var linenumber = orderLine.linenumber;
              }
  
              const grossWeight = candidate.row.cells.gross_weight.editControl.value;
              const weightUom = candidate.row.cells.uom.editControl.value;
              const notes = candidate.row.cells.notes.editControl.value;
  
              if ($utils.isAllDefined(linenumber, grossWeight, weightUom)) {
                  const payload = {
                      GrossWeight: grossWeight,
                      NetWeight: grossWeight,
                      WeightUomId: weightUom
  
                  }
                  await $flows.Utilities.crud_update_dual_pk_flow({
                      entitySet: 'OrderLines',
                      pk1String: 'OrderId',
                      pk1: $grid.inParams.orderId,
                      pk2String: 'LineNumber',
                      pk2: linenumber,
                      entity: payload
                  });
  
              }
  
              if ($utils.isAllDefined(linenumber, notes)) {
                  const payload = {
                      Notes: notes
                  }
                  await $flows.Utilities.crud_update_dual_pk_flow({
                      entitySet: 'OrderLines',
                      pk1String: 'OrderId',
                      pk1: $grid.inParams.orderId,
                      pk2String: 'LineNumber',
                      pk2: linenumber,
                      entity: payload
                  });
  
              }
  
  
              candidate.row.entity.LineNumber = linenumber;
              candidate.row.entity.OrderId = $grid.inParams.orderId;
  
              candidate.row.refresh();
              candidate.row.setDisplayMode();
              candidate.row.isNew = false;
  
          }
  
          catch (error) {
              // $shell.ExcelOrderImport.showErrorDetails('Save', 'Error on save.', error);
  
              errors += 'Error calling create order line API'
              candidate.row.cells.error.displayControl.text = errors;
  
              $grid.topToolbar.linecount.hidden = true;
              throw error; // to prevent displayMode 
          }
      }
  
  }
  
  $grid.topToolbar.linecount.hidden = true;
  
  
  
  }
  on_excel_export(event = null) {
    return this.on_excel_exportInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_excel_exportInternal(
    $grid: FootPrintManager_purchase_order_lines_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  const wb = $event;
  
  const orderLines = [];
  
  orderLines.push({
  MaterialLookupCode: 'Required',	
  Packaging: 'EA, CS, Etc',	
  Quantity: 'Required',	
  Lot: '',
  VendorLot: '',
  GrossWeight: '',
  Uom: '',
  Notes: ''
  });
  
  
  const ws = $utils.excel.json_to_sheet(orderLines);
  
  $utils.excel.book_append_sheet(wb, ws, 'OrderLineImport');
   
     
  
  }
  //#endregion private flows

  $importExcel(fileInput: HTMLInputElement) {
    if(fileInput.files?.length !== 1) {
      throw new Error('Cannot import multiple files');
    }
    const file = fileInput.files[0];
    if(file.size > 15 * 1000 * 1000) {
      throw new Error('Cannot import a file with size greater than 15MB');
    }
    const reader: FileReader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      const ab: ArrayBuffer = e.target?.result as ArrayBuffer;
      const wb: WorkBook = readExcelFile(ab);
      this.on_excel_import(wb);
    };
    reader.readAsArrayBuffer(file);
    fileInput.value = '';
  }

  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_excel_export(wb);

    writeExcelFile(wb, 'FootPrintManager_purchase_order_lines_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
