<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-linecount" *ngIf="!topToolbar.linecount.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.linecount.control.readOnly"
            [ngStyle]="topToolbar.linecount.control.styles.style"
            [ngClass]="topToolbar.linecount.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.linecount.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.linecount.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.linecount.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['lookupcode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.lookupcode.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.lookupcode.editControl.placeholder}}"
            [ngStyle]="row.cells.lookupcode.editControl.styles.style"
            [ngClass]="row.cells.lookupcode.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.name.editControl.placeholder}}"
            [ngStyle]="row.cells.name.editControl.styles.style"
            [ngClass]="row.cells.name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['description_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.description.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.description.editControl.placeholder}}"
            [ngStyle]="row.cells.description.editControl.styles.style"
            [ngClass]="row.cells.description.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-owners_dd_single 
        data-cy="selector"
        [type]="row.cells.owner.editControl.type"
        [formControl]="row.formGroup.controls['owner_edit']"
        (displayTextChange)="row.cells.owner.editControl.displayText=$event"
        [placeholder]="row.cells.owner.editControl.placeholder"
        [styles]="row.cells.owner.editControl.styles"
      [statusId]="row.$fields_owner_selector_inParams_statusId"
    >
    </ExcelMaterialImport-owners_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.project.editControl.type"
        [formControl]="row.formGroup.controls['project_edit']"
        (displayTextChange)="row.cells.project.editControl.displayText=$event"
        [placeholder]="row.cells.project.editControl.placeholder"
        [styles]="row.cells.project.editControl.styles"
      [statusId]="row.$fields_project_selector_inParams_statusId"
      [ownerId]="row.$fields_project_selector_inParams_ownerId"
    >
    </ExcelMaterialImport-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="group">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.group.displayControl.styles.style"
          [ngClass]="row.cells.group.displayControl.styles.classes">{{row.cells.group.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-material_groups_dd_single 
        data-cy="selector"
        [type]="row.cells.group.editControl.type"
        [formControl]="row.formGroup.controls['group_edit']"
        (displayTextChange)="row.cells.group.editControl.displayText=$event"
        [placeholder]="row.cells.group.editControl.placeholder"
        [styles]="row.cells.group.editControl.styles"
    >
    </ExcelMaterialImport-material_groups_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-material_statuses_dd_single 
        data-cy="selector"
        [type]="row.cells.status.editControl.type"
        [formControl]="row.formGroup.controls['status_edit']"
        (displayTextChange)="row.cells.status.editControl.displayText=$event"
        [placeholder]="row.cells.status.editControl.placeholder"
        [styles]="row.cells.status.editControl.styles"
    >
    </ExcelMaterialImport-material_statuses_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_controlled">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot_controlled.displayControl.styles.style"
          [ngClass]="row.cells.lot_controlled.displayControl.styles.classes">{{row.cells.lot_controlled.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.lot_controlled.editControl.type"
        [formControl]="row.formGroup.controls['lot_controlled_edit']"
        (displayTextChange)="row.cells.lot_controlled.editControl.displayText=$event"
        [placeholder]="row.cells.lot_controlled.editControl.placeholder"
        [styles]="row.cells.lot_controlled.editControl.styles"
    >
    </ExcelMaterialImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serial_controlled">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.serial_controlled.displayControl.styles.style"
          [ngClass]="row.cells.serial_controlled.displayControl.styles.classes">{{row.cells.serial_controlled.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.serial_controlled.editControl.type"
        [formControl]="row.formGroup.controls['serial_controlled_edit']"
        (displayTextChange)="row.cells.serial_controlled.editControl.displayText=$event"
        [placeholder]="row.cells.serial_controlled.editControl.placeholder"
        [styles]="row.cells.serial_controlled.editControl.styles"
    >
    </ExcelMaterialImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="fixed_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.fixed_weight.displayControl.styles.style"
          [ngClass]="row.cells.fixed_weight.displayControl.styles.classes">{{row.cells.fixed_weight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.fixed_weight.editControl.type"
        [formControl]="row.formGroup.controls['fixed_weight_edit']"
        (displayTextChange)="row.cells.fixed_weight.editControl.displayText=$event"
        [placeholder]="row.cells.fixed_weight.editControl.placeholder"
        [styles]="row.cells.fixed_weight.editControl.styles"
    >
    </ExcelMaterialImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="fixed_dimension">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.fixed_dimension.displayControl.styles.style"
          [ngClass]="row.cells.fixed_dimension.displayControl.styles.classes">{{row.cells.fixed_dimension.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.fixed_dimension.editControl.type"
        [formControl]="row.formGroup.controls['fixed_dimension_edit']"
        (displayTextChange)="row.cells.fixed_dimension.editControl.displayText=$event"
        [placeholder]="row.cells.fixed_dimension.editControl.placeholder"
        [styles]="row.cells.fixed_dimension.editControl.styles"
    >
    </ExcelMaterialImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="fixed_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.fixed_volume.displayControl.styles.style"
          [ngClass]="row.cells.fixed_volume.displayControl.styles.classes">{{row.cells.fixed_volume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-yn_dd_single 
        data-cy="selector"
        [type]="row.cells.fixed_volume.editControl.type"
        [formControl]="row.formGroup.controls['fixed_volume_edit']"
        (displayTextChange)="row.cells.fixed_volume.editControl.displayText=$event"
        [placeholder]="row.cells.fixed_volume.editControl.placeholder"
        [styles]="row.cells.fixed_volume.editControl.styles"
    >
    </ExcelMaterialImport-yn_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allocation_strategy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.allocation_strategy.displayControl.styles.style"
          [ngClass]="row.cells.allocation_strategy.displayControl.styles.classes">{{row.cells.allocation_strategy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-allocation_strategy_dd_single 
        data-cy="selector"
        [type]="row.cells.allocation_strategy.editControl.type"
        [formControl]="row.formGroup.controls['allocation_strategy_edit']"
        (displayTextChange)="row.cells.allocation_strategy.editControl.displayText=$event"
        [placeholder]="row.cells.allocation_strategy.editControl.placeholder"
        [styles]="row.cells.allocation_strategy.editControl.styles"
    >
    </ExcelMaterialImport-allocation_strategy_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="storage_category_rule">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.storage_category_rule.displayControl.styles.style"
          [ngClass]="row.cells.storage_category_rule.displayControl.styles.classes">{{row.cells.storage_category_rule.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-storage_category_rules_dd_single 
        data-cy="selector"
        [type]="row.cells.storage_category_rule.editControl.type"
        [formControl]="row.formGroup.controls['storage_category_rule_edit']"
        (displayTextChange)="row.cells.storage_category_rule.editControl.displayText=$event"
        [placeholder]="row.cells.storage_category_rule.editControl.placeholder"
        [styles]="row.cells.storage_category_rule.editControl.styles"
    >
    </ExcelMaterialImport-storage_category_rules_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom.displayControl.styles.style"
          [ngClass]="row.cells.base_uom.displayControl.styles.classes">{{row.cells.base_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.base_uom.editControl.type"
        [formControl]="row.formGroup.controls['base_uom_edit']"
        (displayTextChange)="row.cells.base_uom.editControl.displayText=$event"
        [placeholder]="row.cells.base_uom.editControl.placeholder"
        [styles]="row.cells.base_uom.editControl.styles"
    >
    </ExcelMaterialImport-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_net_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_net_weight.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_net_weight.displayControl.styles.classes">{{row.cells.base_uom_net_weight.displayControl.text | formatText : row.cells.base_uom_net_weight.displayControl.formatType : row.cells.base_uom_net_weight.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_net_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_net_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_net_weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_net_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_net_weight.editControl.styles.style"
            [ngClass]="row.cells.base_uom_net_weight.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_shipping_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_shipping_weight.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_shipping_weight.displayControl.styles.classes">{{row.cells.base_uom_shipping_weight.displayControl.text | formatText : row.cells.base_uom_shipping_weight.displayControl.formatType : row.cells.base_uom_shipping_weight.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_shipping_weight_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_shipping_weight.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_shipping_weight.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_shipping_weight.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_shipping_weight.editControl.styles.style"
            [ngClass]="row.cells.base_uom_shipping_weight.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_upc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_upc.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_upc.displayControl.styles.classes">{{row.cells.base_uom_upc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['base_uom_upc_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.base_uom_upc.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_upc.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_upc.editControl.styles.style"
            [ngClass]="row.cells.base_uom_upc.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_pallet_tie">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_pallet_tie.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_pallet_tie.displayControl.styles.classes">{{row.cells.base_uom_pallet_tie.displayControl.text | formatText : row.cells.base_uom_pallet_tie.displayControl.formatType : row.cells.base_uom_pallet_tie.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_pallet_tie_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_pallet_tie.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_pallet_tie.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_pallet_tie.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_pallet_tie.editControl.styles.style"
            [ngClass]="row.cells.base_uom_pallet_tie.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_pallet_high">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_pallet_high.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_pallet_high.displayControl.styles.classes">{{row.cells.base_uom_pallet_high.displayControl.text | formatText : row.cells.base_uom_pallet_high.displayControl.formatType : row.cells.base_uom_pallet_high.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_pallet_high_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_pallet_high.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_pallet_high.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_pallet_high.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_pallet_high.editControl.styles.style"
            [ngClass]="row.cells.base_uom_pallet_high.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_length">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_length.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_length.displayControl.styles.classes">{{row.cells.base_uom_length.displayControl.text | formatText : row.cells.base_uom_length.displayControl.formatType : row.cells.base_uom_length.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_length_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_length.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_length.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_length.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_length.editControl.styles.style"
            [ngClass]="row.cells.base_uom_length.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_width">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_width.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_width.displayControl.styles.classes">{{row.cells.base_uom_width.displayControl.text | formatText : row.cells.base_uom_width.displayControl.formatType : row.cells.base_uom_width.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_width_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_width.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_width.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_width.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_width.editControl.styles.style"
            [ngClass]="row.cells.base_uom_width.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_height">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_height.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_height.displayControl.styles.classes">{{row.cells.base_uom_height.displayControl.text | formatText : row.cells.base_uom_height.displayControl.formatType : row.cells.base_uom_height.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_height_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_height.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_height.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_height.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_height.editControl.styles.style"
            [ngClass]="row.cells.base_uom_height.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_net_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_net_volume.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_net_volume.displayControl.styles.classes">{{row.cells.base_uom_net_volume.displayControl.text | formatText : row.cells.base_uom_net_volume.displayControl.formatType : row.cells.base_uom_net_volume.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_net_volume_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_net_volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_net_volume.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_net_volume.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_net_volume.editControl.styles.style"
            [ngClass]="row.cells.base_uom_net_volume.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_shipping_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_shipping_volume.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_shipping_volume.displayControl.styles.classes">{{row.cells.base_uom_shipping_volume.displayControl.text | formatText : row.cells.base_uom_shipping_volume.displayControl.formatType : row.cells.base_uom_shipping_volume.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['base_uom_shipping_volume_edit']"
            matInput
            numberBox
            [format]="row.cells.base_uom_shipping_volume.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.base_uom_shipping_volume.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.base_uom_shipping_volume.editControl.placeholder}}"
            [ngStyle]="row.cells.base_uom_shipping_volume.editControl.styles.style"
            [ngClass]="row.cells.base_uom_shipping_volume.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_weight_unit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_weight_unit.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_weight_unit.displayControl.styles.classes">{{row.cells.base_uom_weight_unit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.base_uom_weight_unit.editControl.type"
        [formControl]="row.formGroup.controls['base_uom_weight_unit_edit']"
        (displayTextChange)="row.cells.base_uom_weight_unit.editControl.displayText=$event"
        [placeholder]="row.cells.base_uom_weight_unit.editControl.placeholder"
        [styles]="row.cells.base_uom_weight_unit.editControl.styles"
      [typeId]="row.$fields_base_uom_weight_unit_selector_inParams_typeId"
    >
    </ExcelMaterialImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_dimension_unit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_dimension_unit.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_dimension_unit.displayControl.styles.classes">{{row.cells.base_uom_dimension_unit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.base_uom_dimension_unit.editControl.type"
        [formControl]="row.formGroup.controls['base_uom_dimension_unit_edit']"
        (displayTextChange)="row.cells.base_uom_dimension_unit.editControl.displayText=$event"
        [placeholder]="row.cells.base_uom_dimension_unit.editControl.placeholder"
        [styles]="row.cells.base_uom_dimension_unit.editControl.styles"
      [typeId]="row.$fields_base_uom_dimension_unit_selector_inParams_typeId"
    >
    </ExcelMaterialImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="base_uom_volume_unit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.base_uom_volume_unit.displayControl.styles.style"
          [ngClass]="row.cells.base_uom_volume_unit.displayControl.styles.classes">{{row.cells.base_uom_volume_unit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <ExcelMaterialImport-measurement_units_dd_single 
        data-cy="selector"
        [type]="row.cells.base_uom_volume_unit.editControl.type"
        [formControl]="row.formGroup.controls['base_uom_volume_unit_edit']"
        (displayTextChange)="row.cells.base_uom_volume_unit.editControl.displayText=$event"
        [placeholder]="row.cells.base_uom_volume_unit.editControl.placeholder"
        [styles]="row.cells.base_uom_volume_unit.editControl.styles"
      [typeId]="row.$fields_base_uom_volume_unit_selector_inParams_typeId"
    >
    </ExcelMaterialImport-measurement_units_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
