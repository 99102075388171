<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.on_instructions_clicked.readOnly"
                [ngStyle]="row.options.on_instructions_clicked.styles.style"
                [ngClass]="row.options.on_instructions_clicked.styles.classes"
                (click)="row.on_row_instructions_clicked()">
          <span *ngIf="row.options.on_instructions_clicked.label">{{row.options.on_instructions_clicked.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
    <div data-cy="tool-id-on_delete" *ngIf="!topToolbar.on_delete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_delete.control.readOnly"
            [ngStyle]="topToolbar.on_delete.control.styles.style"
            [ngClass]="topToolbar.on_delete.control.styles.classes"
            (click)="on_delete($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_delete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_delete.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="servicetype_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.servicetype_name.displayControl.styles.style"
          [ngClass]="row.cells.servicetype_name.displayControl.styles.classes">{{row.cells.servicetype_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carrierservicetypes_excl_carrier_dd_single 
        data-cy="selector"
        [type]="row.cells.servicetype_name.editControl.type"
        [formControl]="row.formGroup.controls['servicetype_name_edit']"
        (displayTextChange)="row.cells.servicetype_name.editControl.displayText=$event"
        [placeholder]="row.cells.servicetype_name.editControl.placeholder"
        [styles]="row.cells.servicetype_name.editControl.styles"
      [carrierId]="row.$fields_servicetype_name_selector_inParams_carrierId"
    >
    </Carriers-carrierservicetypes_excl_carrier_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="service_mode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.service_mode.displayControl.styles.style"
          [ngClass]="row.cells.service_mode.displayControl.styles.classes">{{row.cells.service_mode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['service_mode_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.service_mode.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.service_mode.editControl.placeholder}}"
            [ngStyle]="row.cells.service_mode.editControl.styles.style"
            [ngClass]="row.cells.service_mode.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="service_type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.service_type.displayControl.styles.style"
          [ngClass]="row.cells.service_type.displayControl.styles.classes">{{row.cells.service_type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['service_type_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.service_type.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.service_type.editControl.placeholder}}"
            [ngStyle]="row.cells.service_type.editControl.styles.style"
            [ngClass]="row.cells.service_type.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="service_group">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.service_group.displayControl.styles.style"
          [ngClass]="row.cells.service_group.displayControl.styles.classes">{{row.cells.service_group.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['service_group_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.service_group.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.service_group.editControl.placeholder}}"
            [ngStyle]="row.cells.service_group.editControl.styles.style"
            [ngClass]="row.cells.service_group.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="service_class">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.service_class.displayControl.styles.style"
          [ngClass]="row.cells.service_class.displayControl.styles.classes">{{row.cells.service_class.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['service_class_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.service_class.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.service_class.editControl.placeholder}}"
            [ngStyle]="row.cells.service_class.editControl.styles.style"
            [ngClass]="row.cells.service_class.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
