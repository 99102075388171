import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './FootPrintApiManager.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_footprint_json_entity_import_entity_schemaService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { example?: boolean }): Promise<{ entity_schema?: { Type?: string, ReferenceKey?: string, Action?: string, MatchCriteria?: { Limit?: number, Criteria?: { Type?: string, PropertyName?: string, Value?: string }[], OrderBy?: { PropertyName?: string, Direction?: string }[] }, Conditions?: { Type?: string, Group?: number, Test?: string, Value?: string }[], Properties?: { Name?: string, Value?: string, EvaluateAsExpression?: string, Update?: string }[], UserDefinedFields?: { Name?: string, Values?: string[] }[], IsValidation?: string }, workflow_schema?: { WorkflowCode?: string, ReferenceKey?: string, Parameters?: { Name?: string, Value?: string, OnAlert?: string }[] } }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/functions/footprint_json_entity_import_entity_schema`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

