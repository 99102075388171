import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class FootPrintManager_ds_transload_outbound_shipments_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number }): 
  Promise<{ result: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BillOfLading?: string, BookingNumber?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, LookupCode?: string, Notes?: string, ReferenceNumber?: string, StatusId?: number, TrailerId?: string, Carrier?: { ShortName?: string }, CarrierServiceType?: { ShortName?: string }, Status?: { Name?: string }, LicensePlateShipmentAssociation?: { LicensePlateId?: number, LicensePlate?: { Id?: number, ChildLicensePlates?: { Id?: number }[] } }[], Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } } }, Order?: { Id?: number, OrderStatusId?: number, PreferredWarehouseId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Status?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_transload_outbound_shipments_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BillOfLading?: string, BookingNumber?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, LookupCode?: string, Notes?: string, ReferenceNumber?: string, StatusId?: number, TrailerId?: string, Carrier?: { ShortName?: string }, CarrierServiceType?: { ShortName?: string }, Status?: { Name?: string }, LicensePlateShipmentAssociation?: { LicensePlateId?: number, LicensePlate?: { Id?: number, ChildLicensePlates?: { Id?: number }[] } }[], Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } } }, Order?: { Id?: number, OrderStatusId?: number, PreferredWarehouseId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Status?: { Name?: string } } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_transload_outbound_shipments_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, $keys: { OrderId?: number, ShipmentId?: number }[] }): 
  Promise<{ result: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BillOfLading?: string, BookingNumber?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, LookupCode?: string, Notes?: string, ReferenceNumber?: string, StatusId?: number, TrailerId?: string, Carrier?: { ShortName?: string }, CarrierServiceType?: { ShortName?: string }, Status?: { Name?: string }, LicensePlateShipmentAssociation?: { LicensePlateId?: number, LicensePlate?: { Id?: number, ChildLicensePlates?: { Id?: number }[] } }[], Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } } }, Order?: { Id?: number, OrderStatusId?: number, PreferredWarehouseId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Status?: { Name?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/FootPrintManager/datasources/ds_transload_outbound_shipments_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
