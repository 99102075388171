import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_ds_order_total_received_units_by_date_widgetService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async get(inParams: { date: string, ownerId?: number, projectId?: number, warehouseId?: number[], orderStatusIds?: number[] }): Promise<{ result?: { TotalUnits?: number } }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.date)) {
      missingRequiredInParams.push('\'date\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/PurchaseOrders/datasources/ds_order_total_received_units_by_date_widget/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
