<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-new_reading" *ngIf="!topToolbar.new_reading.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.new_reading.control.readOnly"
            [ngStyle]="topToolbar.new_reading.control.styles.style"
            [ngClass]="topToolbar.new_reading.control.styles.classes"
            (click)="on_new_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.new_reading.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.new_reading.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.new_reading.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-delete_reading" *ngIf="!topToolbar.delete_reading.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_reading.control.readOnly"
            [ngStyle]="topToolbar.delete_reading.control.styles.style"
            [ngClass]="topToolbar.delete_reading.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_reading.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_reading.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_reading.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="DateCaptured">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.DateCaptured.displayControl.styles.style"
          [ngClass]="row.cells.DateCaptured.displayControl.styles.classes">{{row.cells.DateCaptured.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Employee">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Employee.displayControl.styles.style"
          [ngClass]="row.cells.Employee.displayControl.styles.classes">{{row.cells.Employee.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature1">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Temperature1.displayControl.styles.style"
          [ngClass]="row.cells.Temperature1.displayControl.styles.classes">{{row.cells.Temperature1.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature1MeasurementUnit_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Temperature1MeasurementUnit_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.Temperature1MeasurementUnit_Short_name.displayControl.styles.classes">{{row.cells.Temperature1MeasurementUnit_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature2">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Temperature2.displayControl.styles.style"
          [ngClass]="row.cells.Temperature2.displayControl.styles.classes">{{row.cells.Temperature2.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature2MeasurementUnit_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Temperature2MeasurementUnit_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.Temperature2MeasurementUnit_Short_name.displayControl.styles.classes">{{row.cells.Temperature2MeasurementUnit_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature3">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Temperature3.displayControl.styles.style"
          [ngClass]="row.cells.Temperature3.displayControl.styles.classes">{{row.cells.Temperature3.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Temperature3MeasurementUnit_Short_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Temperature3MeasurementUnit_Short_name.displayControl.styles.style"
          [ngClass]="row.cells.Temperature3MeasurementUnit_Short_name.displayControl.styles.classes">{{row.cells.Temperature3MeasurementUnit_Short_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReadingPoint_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ReadingPoint_Name.displayControl.styles.style"
          [ngClass]="row.cells.ReadingPoint_Name.displayControl.styles.classes">{{row.cells.ReadingPoint_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReadingType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ReadingType_Name.displayControl.styles.style"
          [ngClass]="row.cells.ReadingType_Name.displayControl.styles.classes">{{row.cells.ReadingType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LicensePlate_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LicensePlate_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LicensePlate_LookupCode.displayControl.styles.classes">{{row.cells.LicensePlate_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LoadContainer_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LoadContainer_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LoadContainer_LookupCode.displayControl.styles.classes">{{row.cells.LoadContainer_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_LookupCode.displayControl.styles.classes">{{row.cells.Shipment_LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ShipmentLine_LineNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ShipmentLine_LineNumber.displayControl.styles.style"
          [ngClass]="row.cells.ShipmentLine_LineNumber.displayControl.styles.classes">{{row.cells.ShipmentLine_LineNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
