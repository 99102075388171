import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  NgZone,
  Inject
} from '@angular/core';

import {
  ChartComponent,
  ApexTitleSubtitle,
  ApexChart,
  ApexLegend,
  ApexTooltip,
  ApexDataLabels,
  ApexStroke,
  ApexTheme
} from 'ng-apexcharts';

import { isNil } from 'lodash-es';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintApiManager.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintApiManager-control_partners_directions_widget',
  templateUrl: './FootPrintApiManager.control_partners_directions_widget.component.html'
})
export class FootPrintApiManager_control_partners_directions_widgetComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  outParamsChange = new EventEmitter<{ slice?: { entity?: { Direction?: string, Count?: number } } }>();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { integration_name?: string } = { integration_name: null };
  //#region Inputs
  @Input('integration_name') set $inParams_integration_name(v: string) {
    this.inParams.integration_name = v;
  }
  get $inParams_integration_name(): string {
    return this.inParams.integration_name;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion
  @ViewChild('pieChart') 
  pieChart: ChartComponent;

  private selected = false;
  private entities: { Direction?: string, Count?: number }[];

  series: number[] = [];
  labels: string[] = [];

  chart: ApexChart = {
    type: 'pie',
    width: '100%',
    height: '170px', // fixed heigh because of issues with ApexCharts not being able to respect the container height
    redrawOnParentResize: true,
    fontFamily: '"Segoe UI", "Roboto", sans-serif',
    events: {
      dataPointSelection: (event, chartContext, config) => {
        // run inside the Angular zone, since ng-apexcharts runs almost everything outside of it
        // see: https://github.com/apexcharts/ng-apexcharts/blob/master/projects/ng-apexcharts/src/lib/chart/chart.component.ts
        this.ngZone.run(() => {
          if (this.isPiePointSelected(config.dataPointIndex)) {
            this.outParamsChange.emit({ slice: null});
          } else {
            this.outParamsChange.emit({ slice: { entity: this.entities[config.dataPointIndex] } } );
          }
        });
      }
    }
  };
  dataLabels: ApexDataLabels = {
    enabled: false,
  };
  stroke: ApexStroke = {
    width: 0
  };
  title: ApexTitleSubtitle = {
  };
  legend: ApexLegend = {
    show: false
  };
  tooltip: ApexTooltip = {
    followCursor: false,
    fillSeriesColor: true,
    onDatasetHover: {
      highlightDataSeries: true,
    },
    style: {
      fontSize: '14px',
      fontFamily: '"Segoe UI", "Roboto", sans-serif',
    },
    enabled: true
  };
  theme: ApexTheme = {
    palette: 'palette2',
  };

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    private ngZone: NgZone
  ) { 
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  async $init() {
    
    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      integration_name:  $widget.inParams.integration_name 
    };

    const data = await this.datasources.FootPrintApiManager.ds_storage_control_partners_widget.getList(dsParams);
    this.entities = data.result;
    await this.$dataLoaded();
  }

   async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    if(this.entities) {
      this.labels = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice.entity.Direction;
      });

      this.series = this.entities.map(e => { 
        const $slice = { entity:  e };
        return $slice.entity.Count;
      });
    }

  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  private isPiePointSelected(pointIndex: number) {
    if (this.pieChart) {
      const pieSlice = (this.pieChart as any).chartObj.w.globals.dom.Paper.select(`.apexcharts-pie-slice-${pointIndex}`).members[0];
      const isSelected = pieSlice.attr('data:pieClicked');
      return isSelected === 'true';
    }
    return false;
  }

}
