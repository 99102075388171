import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EntityImport_ShellService, EModalSize, EToasterType, EToasterPosition } from './EntityImport.shell.service';
import { EntityImport_OperationService } from './EntityImport.operation.service';
import { EntityImport_DatasourceService } from './EntityImport.datasource.index';
import { EntityImport_FlowService } from './EntityImport.flow.index';
import { EntityImport_ReportService } from './EntityImport.report.index';
import { EntityImport_LocalizationService } from './EntityImport.localization.service';
import { Language } from './localization.service';
import { $types } from './EntityImport.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EntityImport-entity_import_total_errors_by_date_widget',
  templateUrl: './EntityImport.entity_import_total_errors_by_date_widget.component.html'
})
export class EntityImport_entity_import_total_errors_by_date_widgetComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { date: string } = { date: null };
  //#region Inputs
  @Input('date') set $inParams_date(v: string) {
    this.inParams.date = v;
  }
  get $inParams_date(): string {
    return this.inParams.date;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { TotalCount?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EntityImport_ShellService,
private datasources: EntityImport_DatasourceService,
private flows: EntityImport_FlowService,
private reports: EntityImport_ReportService,
private localization: EntityImport_LocalizationService,
private operations: EntityImport_OperationService,
) { 

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.date)) {
        this.$missingRequiredInParams.push('date');
      }
  }

  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      date:  $widget.inParams.date 
    };

    const data = await this.datasources.EntityImport.ds_entity_import_total_errors_by_date_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = $utils.isDefined($widget.entity.TotalCount) ? $widget.entity.TotalCount : 0;

    
    this.originalValue = $utils.isDefined($widget.entity.TotalCount) ? $widget.entity.TotalCount : 0;
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
