<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
        <div data-cy="tool-id-edit" *ngIf="!toolbar.edit.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.edit.control.readOnly"
                [ngStyle]="toolbar.edit.control.styles.style"
                [ngClass]="toolbar.edit.control.styles.classes"
                (click)="on_edit_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.edit.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.edit.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.edit.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-create_inventory" *ngIf="!toolbar.create_inventory.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_inventory.control.readOnly"
                [ngStyle]="toolbar.create_inventory.control.styles.style"
                [ngClass]="toolbar.create_inventory.control.styles.classes"
                (click)="on_create_inventory_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_inventory.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_inventory.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_inventory.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-create_project" *ngIf="!toolbar.create_project.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_project.control.readOnly"
                [ngStyle]="toolbar.create_project.control.styles.style"
                [ngClass]="toolbar.create_project.control.styles.classes"
                (click)="on_create_project_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_project.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_project.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_project.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-create_material" *ngIf="!toolbar.create_material.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_material.control.readOnly"
                [ngStyle]="toolbar.create_material.control.styles.style"
                [ngClass]="toolbar.create_material.control.styles.classes"
                (click)="on_create_material_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_material.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_material.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_material.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator2" *ngIf="!toolbar.separator2.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-on_delete" *ngIf="!toolbar.on_delete.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.on_delete.control.readOnly"
                [ngStyle]="toolbar.on_delete.control.styles.style"
                [ngClass]="toolbar.on_delete.control.styles.classes"
                (click)="on_delete($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.on_delete.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.on_delete.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator3" *ngIf="!toolbar.separator3.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-attachments" *ngIf="!toolbar.attachments.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.attachments.control.readOnly"
                [ngStyle]="toolbar.attachments.control.styles.style"
                [ngClass]="toolbar.attachments.control.styles.classes"
                (click)="on_attachments_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.attachments.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.attachments.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.attachments.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-tools">
            <div data-cy="tool-id-ship_from_addresses" *ngIf="!actionbar.ship_from_addresses.hidden" class="toolContainer">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="actionbar.ship_from_addresses.control.readOnly"
                    [ngStyle]="actionbar.ship_from_addresses.control.styles.style"
                    [ngClass]="actionbar.ship_from_addresses.control.styles.classes"
                    (click)="on_ship_from_addresses_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="actionbar.ship_from_addresses.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{actionbar.ship_from_addresses.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{actionbar.ship_from_addresses.control.label}}</div>
              </div>
            
            </button>
            </div>
            <div data-cy="tool-id-ship_to_addresses" *ngIf="!actionbar.ship_to_addresses.hidden" class="toolContainer">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="actionbar.ship_to_addresses.control.readOnly"
                    [ngStyle]="actionbar.ship_to_addresses.control.styles.style"
                    [ngClass]="actionbar.ship_to_addresses.control.styles.classes"
                    (click)="on_ship_to_addresses_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="actionbar.ship_to_addresses.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{actionbar.ship_to_addresses.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{actionbar.ship_to_addresses.control.label}}</div>
              </div>
            
            </button>
            </div>
            <div data-cy="tool-id-assigned_containers_action" *ngIf="!actionbar.assigned_containers_action.hidden" class="toolContainer">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="actionbar.assigned_containers_action.control.readOnly"
                    [ngStyle]="actionbar.assigned_containers_action.control.styles.style"
                    [ngClass]="actionbar.assigned_containers_action.control.styles.classes"
                    (click)="on_assignment_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="actionbar.assigned_containers_action.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{actionbar.assigned_containers_action.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{actionbar.assigned_containers_action.control.label}}</div>
              </div>
            
            </button>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.materials_by_count_widget.hidden" class="widget-container">
            <Materials-materials_by_count_widget
            #$widgets_materials_by_count_widget 
              [ownerId]="$widgets_materials_by_count_widget_inParams_ownerId"
            ($refreshEvent)="refresh(false, false, '$widgets_materials_by_count_widget')"
            >
            </Materials-materials_by_count_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden" class="widget-container">
            <Inventory-total_packaged_amount_widget
            #$widgets_total_packaged_amount_widget 
              [ownerId]="$widgets_total_packaged_amount_widget_inParams_ownerId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </Inventory-total_packaged_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [ownerId]="$widgets_total_gross_weight_widget_inParams_ownerId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.projects.hidden" class="tab" data-cy="tab-projects">
              <h2 [className]="tabs.projects.active? 'active': ''" (click)="tabs.projects.activate()">{{tabs.projects.title}}</h2>
            </div>
            <div *ngIf="!tabs.materials.hidden" class="tab" data-cy="tab-materials">
              <h2 [className]="tabs.materials.active? 'active': ''" (click)="tabs.materials.activate()">{{tabs.materials.title}}</h2>
            </div>
            <div *ngIf="!tabs.inventory.hidden" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.carriers.hidden" class="tab" data-cy="tab-carriers">
              <h2 [className]="tabs.carriers.active? 'active': ''" (click)="tabs.carriers.activate()">{{tabs.carriers.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="!tabs.instructions.hidden" class="tab" data-cy="tab-instructions">
              <h2 [className]="tabs.instructions.active? 'active': ''" (click)="tabs.instructions.activate()">{{tabs.instructions.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-projects_grid *ngIf="tabs.projects.active"
              #$tabs_projects
              [projectStatusId]="$tabs_projects_projects_grid_inParams_projectStatusId"
              [ownerId]="$tabs_projects_projects_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_projects')">
              </FootPrintManager-projects_grid>
              <FootPrintManager-materials_by_base_packaging_grid *ngIf="tabs.materials.active"
              #$tabs_materials
              [ownerId]="$tabs_materials_materials_by_base_packaging_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_materials')">
              </FootPrintManager-materials_by_base_packaging_grid>
              <FootPrintManager-inventory_location_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [ownerId]="$tabs_inventory_inventory_location_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootPrintManager-inventory_location_grid>
              <FootPrintManager-ownerscarrierslookup_grid *ngIf="tabs.carriers.active"
              #$tabs_carriers
              [ownerId]="$tabs_carriers_ownerscarrierslookup_grid_inParams_ownerId"
              ($refreshEvent)="refresh(false, false, '$tabs_carriers')">
              </FootPrintManager-ownerscarrierslookup_grid>
              <FootPrintManager-contact_addresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [entity]="$tabs_contacts_contact_addresses_grid_inParams_entity"
              [entityId]="$tabs_contacts_contact_addresses_grid_inParams_entityId"
              [typeIds]="$tabs_contacts_contact_addresses_grid_inParams_typeIds"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-contact_addresses_grid>
              <FootPrintManager-instructions_grid *ngIf="tabs.instructions.active"
              #$tabs_instructions
              [entityName]="$tabs_instructions_instructions_grid_inParams_entityName"
              [enabled]="$tabs_instructions_instructions_grid_inParams_enabled"
              [entityKey1]="$tabs_instructions_instructions_grid_inParams_entityKey1"
              ($refreshEvent)="refresh(false, false, '$tabs_instructions')">
              </FootPrintManager-instructions_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>