<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-clean_logs" *ngIf="!topToolbar.clean_logs.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.clean_logs.control.readOnly"
            [ngStyle]="topToolbar.clean_logs.control.styles.style"
            [ngClass]="topToolbar.clean_logs.control.styles.classes"
            (click)="on_clean($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.clean_logs.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.clean_logs.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.clean_logs.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-level" *ngIf="!filters.level.hidden" 
            class="field-container standard {{filters.level.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.level.styles.style"
            [ngClass]="filters.level.styles.classes">
        <div class="label-container"
              title="{{filters.level.label}}{{filters.level.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.level.label}}">{{filters.level.label}}<span *ngIf="filters.level.required"
                  class="required-asterisk">*</span></label>
        </div>
        <input data-cy="numberBox" formControlName="level"
                matInput
                numberBox
                [format]="filters.level.control.format"
                autocomplete="off"
                spellcheck="false"
                class="datex-numberbox {{filters.level.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.level.control.placeholder}}"
                [ngStyle]="filters.level.control.styles.style"
                [ngClass]="filters.level.control.styles.classes">
        <ng-container *ngIf="filters.level.invalid">
          <ng-container *ngFor="let error of filters.level.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-projectId" *ngIf="!filters.projectId.hidden" 
            class="field-container standard {{filters.projectId.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.projectId.styles.style"
            [ngClass]="filters.projectId.styles.classes">
        <div class="label-container"
              title="{{filters.projectId.label}}{{filters.projectId.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.projectId.label}}">{{filters.projectId.label}}<span *ngIf="filters.projectId.required"
                  class="required-asterisk">*</span></label>
        </div>
        <input data-cy="numberBox" formControlName="projectId"
                matInput
                numberBox
                [format]="filters.projectId.control.format"
                autocomplete="off"
                spellcheck="false"
                class="datex-numberbox {{filters.projectId.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.projectId.control.placeholder}}"
                [ngStyle]="filters.projectId.control.styles.style"
                [ngClass]="filters.projectId.control.styles.classes">
        <ng-container *ngIf="filters.projectId.invalid">
          <ng-container *ngFor="let error of filters.projectId.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-invoiceId" *ngIf="!filters.invoiceId.hidden" 
            class="field-container standard {{filters.invoiceId.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.invoiceId.styles.style"
            [ngClass]="filters.invoiceId.styles.classes">
        <div class="label-container"
              title="{{filters.invoiceId.label}}{{filters.invoiceId.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.invoiceId.label}}">{{filters.invoiceId.label}}<span *ngIf="filters.invoiceId.required"
                  class="required-asterisk">*</span></label>
        </div>
        <input data-cy="numberBox" formControlName="invoiceId"
                matInput
                numberBox
                [format]="filters.invoiceId.control.format"
                autocomplete="off"
                spellcheck="false"
                class="datex-numberbox {{filters.invoiceId.control.readOnly ? 'readonly ' : ''}}"
                placeholder="{{filters.invoiceId.control.placeholder}}"
                [ngStyle]="filters.invoiceId.control.styles.style"
                [ngClass]="filters.invoiceId.control.styles.classes">
        <ng-container *ngIf="filters.invoiceId.invalid">
          <ng-container *ngFor="let error of filters.invoiceId.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="level">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.level.displayControl.styles.style"
          [ngClass]="row.cells.level.displayControl.styles.classes">{{row.cells.level.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="projectId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.projectId.displayControl.styles.style"
          [ngClass]="row.cells.projectId.displayControl.styles.classes">{{row.cells.projectId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billingContractId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.billingContractId.displayControl.styles.style"
          [ngClass]="row.cells.billingContractId.displayControl.styles.classes">{{row.cells.billingContractId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="invoiceId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.invoiceId.displayControl.styles.style"
          [ngClass]="row.cells.invoiceId.displayControl.styles.classes">{{row.cells.invoiceId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="instructionId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.instructionId.displayControl.styles.style"
          [ngClass]="row.cells.instructionId.displayControl.styles.classes">{{row.cells.instructionId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="process">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.process.displayControl.styles.style"
          [ngClass]="row.cells.process.displayControl.styles.classes">{{row.cells.process.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="transactionId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.transactionId.displayControl.styles.style"
          [ngClass]="row.cells.transactionId.displayControl.styles.classes">{{row.cells.transactionId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="requestId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.requestId.displayControl.styles.style"
          [ngClass]="row.cells.requestId.displayControl.styles.classes">{{row.cells.requestId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="createdOn">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.createdOn.displayControl.styles.style"
          [ngClass]="row.cells.createdOn.displayControl.styles.classes">{{row.cells.createdOn.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="alertedOn">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.alertedOn.displayControl.styles.style"
          [ngClass]="row.cells.alertedOn.displayControl.styles.classes">{{row.cells.alertedOn.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
