import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Utilities.types'

@Injectable({ providedIn: 'root' })
export class Utilities_convert_volume_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { inputVolume: number, fromUom: number, toUom: number }): Promise<{ convertedVolume?: number, reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inputVolume)) {
      missingRequiredInParams.push('\'inputVolume\'');
    }
    if (isNil(inParams.fromUom)) {
      missingRequiredInParams.push('\'fromUom\'');
    }
    if (isNil(inParams.toUom)) {
      missingRequiredInParams.push('\'toUom\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/functions/convert_volume_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

