import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './WarehouseTransfers.types'

@Injectable({ providedIn: 'root' })
export class WarehouseTransfers_set_target_inbound_receiving_task_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { license_plate_id: number, lot_id: number, packaging_id: number, order_id: number, material_id: number, vendor_lot_id: number, packaged_amount: number, existing_task_id: number, serial_numbers_to_create?: { DimensionUOM?: number, GrossVolumeValue?: number, GrossWeightValue?: number, Height?: number, Length?: number, LookupCode?: string, NetVolumeValue?: number, NetWeightValue?: number, VolumeUOM?: number, WeightUOM?: number, Width?: number }[], serial_number_ids_to_delete?: number[] }): Promise<{ task_id?: number, reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.license_plate_id)) {
      missingRequiredInParams.push('\'license_plate_id\'');
    }
    if (isNil(inParams.lot_id)) {
      missingRequiredInParams.push('\'lot_id\'');
    }
    if (isNil(inParams.packaging_id)) {
      missingRequiredInParams.push('\'packaging_id\'');
    }
    if (isNil(inParams.order_id)) {
      missingRequiredInParams.push('\'order_id\'');
    }
    if (isNil(inParams.material_id)) {
      missingRequiredInParams.push('\'material_id\'');
    }
    if (isNil(inParams.vendor_lot_id)) {
      missingRequiredInParams.push('\'vendor_lot_id\'');
    }
    if (isNil(inParams.packaged_amount)) {
      missingRequiredInParams.push('\'packaged_amount\'');
    }
    if (isNil(inParams.existing_task_id)) {
      missingRequiredInParams.push('\'existing_task_id\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/WarehouseTransfers/functions/set_target_inbound_receiving_task_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

