<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-process" *ngIf="!toolbar.process.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.process.control.readOnly"
              [ngStyle]="toolbar.process.control.styles.style"
              [ngClass]="toolbar.process.control.styles.classes"
              (click)="on_process_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.process.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.process.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.process.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-auto_pick" *ngIf="!toolbar.auto_pick.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.auto_pick.control.readOnly"
              [ngStyle]="toolbar.auto_pick.control.styles.style"
              [ngClass]="toolbar.auto_pick.control.styles.classes"
              (click)="on_auto_pick_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.auto_pick.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.auto_pick.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.auto_pick.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-auto_load" *ngIf="!toolbar.auto_load.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.auto_load.control.readOnly"
              [ngStyle]="toolbar.auto_load.control.styles.style"
              [ngClass]="toolbar.auto_load.control.styles.classes"
              (click)="on_auto_load_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.auto_load.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.auto_load.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.auto_load.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-complete" *ngIf="!toolbar.complete.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.complete.control.readOnly"
              [ngStyle]="toolbar.complete.control.styles.style"
              [ngClass]="toolbar.complete.control.styles.classes"
              (click)="on_complete_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.complete.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.complete.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.complete.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-on_delete" *ngIf="!toolbar.on_delete.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.on_delete.control.readOnly"
              [ngStyle]="toolbar.on_delete.control.styles.style"
              [ngClass]="toolbar.on_delete.control.styles.classes"
              (click)="on_delete_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.on_delete.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.on_delete.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-revert" *ngIf="!toolbar.revert.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.revert.control.readOnly"
              [ngStyle]="toolbar.revert.control.styles.style"
              [ngClass]="toolbar.revert.control.styles.classes"
              (click)="on_revert_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.revert.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.revert.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.revert.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-appointment" *ngIf="!toolbar.appointment.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.appointment.control.readOnly"
              [ngStyle]="toolbar.appointment.control.styles.style"
              [ngClass]="toolbar.appointment.control.styles.classes"
              (click)="on_appointment_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.appointment.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.appointment.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.appointment.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-manifest_print" *ngIf="!toolbar.manifest_print.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.manifest_print.control.readOnly"
              [ngStyle]="toolbar.manifest_print.control.styles.style"
              [ngClass]="toolbar.manifest_print.control.styles.classes"
              (click)="on_manifest_print_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.manifest_print.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.manifest_print.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.manifest_print.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-attachments" *ngIf="!toolbar.attachments.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.attachments.control.readOnly"
              [ngStyle]="toolbar.attachments.control.styles.style"
              [ngClass]="toolbar.attachments.control.styles.classes"
              (click)="on_attachments_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.attachments.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.attachments.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.attachments.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-email_requests" *ngIf="!toolbar.email_requests.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.email_requests.control.readOnly"
              [ngStyle]="toolbar.email_requests.control.styles.style"
              [ngClass]="toolbar.email_requests.control.styles.classes"
              (click)="on_email_requests_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.email_requests.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.email_requests.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.email_requests.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-print" *ngIf="!toolbar.print.hidden" class="toolContainer">
      <button mat-button 
              data-cy="splitbutton"
              class="datex-button splitbutton" 
              [disabled]="toolbar.print.control.readOnly"
              [ngStyle]="toolbar.print.control.styles.style"
              [ngClass]="toolbar.print.control.styles.classes"
              [matMenuTriggerFor]="dropdownMenuOne">
        <div class="button-label">
          <div *ngIf="toolbar.print.control.icon"
                class="button-icon">
            <i data-cy="splitbutton-icon" class="icon {{toolbar.print.control.icon}}"></i>
          </div>
          <div data-cy="splitbutton-label" class="button-text">{{toolbar.print.control.label}}</div>
          <div class="button-icon splitbutton-drop-icon">
            <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
          </div>
        </div>
      </button>
      <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
        <div data-cy="splitbutton-options">
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.print.control.buttons.sales_order_details.readOnly"
                    [ngStyle]="toolbar.print.control.buttons.sales_order_details.styles.style"
                    [ngClass]="toolbar.print.control.buttons.sales_order_details.styles.classes"
                    (click)="on_print_so_report_clicked($event)">
              <div class="button-label">
                <div *ngIf="toolbar.print.control.buttons.sales_order_details.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.print.control.buttons.sales_order_details.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.print.control.buttons.sales_order_details.label}}</div>
              </div>
            </button>
          </div>
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.print.control.buttons.pick_slip.readOnly"
                    [ngStyle]="toolbar.print.control.buttons.pick_slip.styles.style"
                    [ngClass]="toolbar.print.control.buttons.pick_slip.styles.classes"
                    (click)="on_print_pick_slip_report_clicked($event)">
              <div class="button-label">
                <div *ngIf="toolbar.print.control.buttons.pick_slip.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.print.control.buttons.pick_slip.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.print.control.buttons.pick_slip.label}}</div>
              </div>
            </button>
          </div>
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.print.control.buttons.sales_order_export.readOnly"
                    [ngStyle]="toolbar.print.control.buttons.sales_order_export.styles.style"
                    [ngClass]="toolbar.print.control.buttons.sales_order_export.styles.classes"
                    (click)="on_sales_order_export_report_clicked($event)">
              <div class="button-label">
                <div *ngIf="toolbar.print.control.buttons.sales_order_export.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.print.control.buttons.sales_order_export.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.print.control.buttons.sales_order_export.label}}</div>
              </div>
            </button>
          </div>
        </div>
      </mat-menu>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Order</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_class.label}}{{fields.order_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_class.label}}">{{fields.order_class.label}}<span *ngIf="fields.order_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <SalesOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                        >
                        </SalesOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_status" *ngIf="!fields.order_status.hidden" 
                            class="field-container standard {{fields.order_status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_status.styles.style"
                            [ngClass]="fields.order_status.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_status.label}}{{fields.order_status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_status.label}}">{{fields.order_status.label}}<span *ngIf="fields.order_status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.order_status.control.styles.style"
                              [ngClass]="fields.order_status.control.styles.classes">{{fields.order_status.control.text }}</div>
                        <ng-container *ngIf="fields.order_status.invalid">
                          <ng-container *ngFor="let error of fields.order_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-procedure_code" *ngIf="!fields.procedure_code.hidden" 
                            class="field-container standard {{fields.procedure_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.procedure_code.styles.style"
                            [ngClass]="fields.procedure_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.procedure_code.label}}{{fields.procedure_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.procedure_code.label}}">{{fields.procedure_code.label}}<span *ngIf="fields.procedure_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-custom_outbound_procedure_codes_single 
                            data-cy="selector"
                            [type]="fields.procedure_code.control.type"
                            formControlName="procedure_code"
                            (displayTextChange)="fields.procedure_code.control.displayText=$event"
                            [placeholder]="fields.procedure_code.control.placeholder"
                            [styles]="fields.procedure_code.control.styles"
                        >
                        </app-custom_outbound_procedure_codes_single>
                        <ng-container *ngIf="fields.procedure_code.invalid">
                          <ng-container *ngFor="let error of fields.procedure_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.expected_date.label}}{{fields.expected_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.expected_date.label}}">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-ship_to_selection" *ngIf="!fields.ship_to_selection.hidden" 
                            class="field-container standard {{fields.ship_to_selection.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_to_selection.styles.style"
                            [ngClass]="fields.ship_to_selection.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_to_selection.label}}{{fields.ship_to_selection.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_to_selection.label}}">{{fields.ship_to_selection.label}}<span *ngIf="fields.ship_to_selection.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_to_selection.control.readOnly"
                                [ngStyle]="fields.ship_to_selection.control.styles.style"
                                [ngClass]="fields.ship_to_selection.control.styles.classes"
                                (click)="on_ship_to_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_to_selection.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_to_selection.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_to_selection.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_to_selection.invalid">
                          <ng-container *ngFor="let error of fields.ship_to_selection.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_to_clear" *ngIf="!fields.ship_to_clear.hidden" 
                            class="field-container standard {{fields.ship_to_clear.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_to_clear.styles.style"
                            [ngClass]="fields.ship_to_clear.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_to_clear.label}}{{fields.ship_to_clear.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_to_clear.label}}">{{fields.ship_to_clear.label}}<span *ngIf="fields.ship_to_clear.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.ship_to_clear.control.readOnly"
                                [ngStyle]="fields.ship_to_clear.control.styles.style"
                                [ngClass]="fields.ship_to_clear.control.styles.classes"
                                (click)="on_ship_to_cleared($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.ship_to_clear.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.ship_to_clear.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.ship_to_clear.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.ship_to_clear.invalid">
                          <ng-container *ngFor="let error of fields.ship_to_clear.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-ship_to" *ngIf="!fields.ship_to.hidden" 
                            class="field-container double {{fields.ship_to.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.ship_to.styles.style"
                            [ngClass]="fields.ship_to.styles.classes">
                        <div class="label-container"
                              title="{{fields.ship_to.label}}{{fields.ship_to.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.ship_to.label}}">{{fields.ship_to.label}}<span *ngIf="fields.ship_to.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.ship_to.control.styles.style"
                              [ngClass]="fields.ship_to.control.styles.classes">{{fields.ship_to.control.text }}</div>
                        <ng-container *ngIf="fields.ship_to.invalid">
                          <ng-container *ngFor="let error of fields.ship_to.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_reference" *ngIf="!fields.vendor_reference.hidden" 
                            class="field-container standard {{fields.vendor_reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendor_reference.styles.style"
                            [ngClass]="fields.vendor_reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendor_reference.label}}{{fields.vendor_reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendor_reference.label}}">{{fields.vendor_reference.label}}<span *ngIf="fields.vendor_reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vendor_reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendor_reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_reference.control.placeholder}}"
                                [ngStyle]="fields.vendor_reference.control.styles.style"
                                [ngClass]="fields.vendor_reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.vendor_reference.invalid">
                          <ng-container *ngFor="let error of fields.vendor_reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.reference.label}}{{fields.reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reference.label}}">{{fields.reference.label}}<span *ngIf="fields.reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier.label}}{{fields.carrier.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier.label}}">{{fields.carrier.label}}<span *ngIf="fields.carrier.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Carriers-carriers_ownerscarriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                          [ownerId]="$fields_carrier_selector_inParams_ownerId"
                        >
                        </Carriers-carriers_ownerscarriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service" *ngIf="!fields.carrier_service.hidden" 
                            class="field-container standard {{fields.carrier_service.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier_service.styles.style"
                            [ngClass]="fields.carrier_service.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier_service.label}}{{fields.carrier_service.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier_service.label}}">{{fields.carrier_service.label}}<span *ngIf="fields.carrier_service.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Carriers-carrierservicetype_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier_service.control.type"
                            formControlName="carrier_service"
                            (displayTextChange)="fields.carrier_service.control.displayText=$event"
                            [placeholder]="fields.carrier_service.control.placeholder"
                            [styles]="fields.carrier_service.control.styles"
                          [carrierId]="$fields_carrier_service_selector_inParams_carrierId"
                        >
                        </Carriers-carrierservicetype_dd_single>
                        <ng-container *ngIf="fields.carrier_service.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container standard {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tracking_identifier" *ngIf="!fields.tracking_identifier.hidden" 
                            class="field-container standard {{fields.tracking_identifier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.tracking_identifier.styles.style"
                            [ngClass]="fields.tracking_identifier.styles.classes">
                        <div class="label-container"
                              title="{{fields.tracking_identifier.label}}{{fields.tracking_identifier.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.tracking_identifier.label}}">{{fields.tracking_identifier.label}}<span *ngIf="fields.tracking_identifier.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="tracking_identifier"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.tracking_identifier.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.tracking_identifier.control.placeholder}}"
                                [ngStyle]="fields.tracking_identifier.control.styles.style"
                                [ngClass]="fields.tracking_identifier.control.styles.classes"> 
                        <ng-container *ngIf="fields.tracking_identifier.invalid">
                          <ng-container *ngFor="let error of fields.tracking_identifier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-gross_weight" *ngIf="!fields.gross_weight.hidden" 
                            class="field-container standard {{fields.gross_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.gross_weight.styles.style"
                            [ngClass]="fields.gross_weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.gross_weight.label}}{{fields.gross_weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.gross_weight.label}}">{{fields.gross_weight.label}}<span *ngIf="fields.gross_weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="gross_weight"
                                matInput
                                numberBox
                                [format]="fields.gross_weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.gross_weight.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.gross_weight.control.placeholder}}"
                                [ngStyle]="fields.gross_weight.control.styles.style"
                                [ngClass]="fields.gross_weight.control.styles.classes">
                        <ng-container *ngIf="fields.gross_weight.invalid">
                          <ng-container *ngFor="let error of fields.gross_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pallet_count" *ngIf="!fields.pallet_count.hidden" 
                            class="field-container standard {{fields.pallet_count.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pallet_count.styles.style"
                            [ngClass]="fields.pallet_count.styles.classes">
                        <div class="label-container"
                              title="{{fields.pallet_count.label}}{{fields.pallet_count.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pallet_count.label}}">{{fields.pallet_count.label}}<span *ngIf="fields.pallet_count.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="pallet_count"
                                matInput
                                numberBox
                                [format]="fields.pallet_count.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.pallet_count.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.pallet_count.control.placeholder}}"
                                [ngStyle]="fields.pallet_count.control.styles.style"
                                [ngClass]="fields.pallet_count.control.styles.classes">
                        <ng-container *ngIf="fields.pallet_count.invalid">
                          <ng-container *ngFor="let error of fields.pallet_count.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-item_count" *ngIf="!fields.item_count.hidden" 
                            class="field-container standard {{fields.item_count.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.item_count.styles.style"
                            [ngClass]="fields.item_count.styles.classes">
                        <div class="label-container"
                              title="{{fields.item_count.label}}{{fields.item_count.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.item_count.label}}">{{fields.item_count.label}}<span *ngIf="fields.item_count.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="item_count"
                                matInput
                                numberBox
                                [format]="fields.item_count.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.item_count.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.item_count.control.placeholder}}"
                                [ngStyle]="fields.item_count.control.styles.style"
                                [ngClass]="fields.item_count.control.styles.classes">
                        <ng-container *ngIf="fields.item_count.invalid">
                          <ng-container *ngFor="let error of fields.item_count.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-incoterms" *ngIf="!fields.incoterms.hidden" 
                            class="field-container standard {{fields.incoterms.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.incoterms.styles.style"
                            [ngClass]="fields.incoterms.styles.classes">
                        <div class="label-container"
                              title="{{fields.incoterms.label}}{{fields.incoterms.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.incoterms.label}}">{{fields.incoterms.label}}<span *ngIf="fields.incoterms.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Manifesting-incoterms_dd_single 
                            data-cy="selector"
                            [type]="fields.incoterms.control.type"
                            formControlName="incoterms"
                            (displayTextChange)="fields.incoterms.control.displayText=$event"
                            [placeholder]="fields.incoterms.control.placeholder"
                            [styles]="fields.incoterms.control.styles"
                        >
                        </Manifesting-incoterms_dd_single>
                        <ng-container *ngIf="fields.incoterms.invalid">
                          <ng-container *ngFor="let error of fields.incoterms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pva" *ngIf="!fields.pva.hidden" 
                            class="field-container standard {{fields.pva.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pva.styles.style"
                            [ngClass]="fields.pva.styles.classes">
                        <div class="label-container"
                              title="{{fields.pva.label}}{{fields.pva.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pva.label}}">{{fields.pva.label}}<span *ngIf="fields.pva.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="pva"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.pva.control.styles.style"
                                      [ngClass]="fields.pva.control.styles.classes">{{fields.pva.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.pva.invalid">
                          <ng-container *ngFor="let error of fields.pva.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-freight_charge" *ngIf="!fields.freight_charge.hidden" 
                            class="field-container standard {{fields.freight_charge.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.freight_charge.styles.style"
                            [ngClass]="fields.freight_charge.styles.classes">
                        <div class="label-container"
                              title="{{fields.freight_charge.label}}{{fields.freight_charge.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.freight_charge.label}}">{{fields.freight_charge.label}}<span *ngIf="fields.freight_charge.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="freight_charge"
                                matInput
                                numberBox
                                [format]="fields.freight_charge.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.freight_charge.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.freight_charge.control.placeholder}}"
                                [ngStyle]="fields.freight_charge.control.styles.style"
                                [ngClass]="fields.freight_charge.control.styles.classes">
                        <ng-container *ngIf="fields.freight_charge.invalid">
                          <ng-container *ngFor="let error of fields.freight_charge.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">Project Substitutions</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-project_priority_one" *ngIf="!fields.project_priority_one.hidden" 
                            class="field-container standard {{fields.project_priority_one.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project_priority_one.styles.style"
                            [ngClass]="fields.project_priority_one.styles.classes">
                        <div class="label-container"
                              title="{{fields.project_priority_one.label}}{{fields.project_priority_one.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project_priority_one.label}}">{{fields.project_priority_one.label}}<span *ngIf="fields.project_priority_one.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project_priority_one.control.type"
                            formControlName="project_priority_one"
                            (displayTextChange)="fields.project_priority_one.control.displayText=$event"
                            [placeholder]="fields.project_priority_one.control.placeholder"
                            [styles]="fields.project_priority_one.control.styles"
                          [statusId]="$fields_project_priority_one_selector_inParams_statusId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project_priority_one.invalid">
                          <ng-container *ngFor="let error of fields.project_priority_one.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project_priority_two" *ngIf="!fields.project_priority_two.hidden" 
                            class="field-container standard {{fields.project_priority_two.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project_priority_two.styles.style"
                            [ngClass]="fields.project_priority_two.styles.classes">
                        <div class="label-container"
                              title="{{fields.project_priority_two.label}}{{fields.project_priority_two.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project_priority_two.label}}">{{fields.project_priority_two.label}}<span *ngIf="fields.project_priority_two.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project_priority_two.control.type"
                            formControlName="project_priority_two"
                            (displayTextChange)="fields.project_priority_two.control.displayText=$event"
                            [placeholder]="fields.project_priority_two.control.placeholder"
                            [styles]="fields.project_priority_two.control.styles"
                          [statusId]="$fields_project_priority_two_selector_inParams_statusId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project_priority_two.invalid">
                          <ng-container *ngFor="let error of fields.project_priority_two.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project_priority_three" *ngIf="!fields.project_priority_three.hidden" 
                            class="field-container standard {{fields.project_priority_three.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project_priority_three.styles.style"
                            [ngClass]="fields.project_priority_three.styles.classes">
                        <div class="label-container"
                              title="{{fields.project_priority_three.label}}{{fields.project_priority_three.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project_priority_three.label}}">{{fields.project_priority_three.label}}<span *ngIf="fields.project_priority_three.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project_priority_three.control.type"
                            formControlName="project_priority_three"
                            (displayTextChange)="fields.project_priority_three.control.displayText=$event"
                            [placeholder]="fields.project_priority_three.control.placeholder"
                            [styles]="fields.project_priority_three.control.styles"
                          [statusId]="$fields_project_priority_three_selector_inParams_statusId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project_priority_three.invalid">
                          <ng-container *ngFor="let error of fields.project_priority_three.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-bypass_allocation_quantity" *ngIf="!fields.bypass_allocation_quantity.hidden" 
                            class="field-container standard {{fields.bypass_allocation_quantity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.bypass_allocation_quantity.styles.style"
                            [ngClass]="fields.bypass_allocation_quantity.styles.classes">
                        <div class="label-container"
                              title="{{fields.bypass_allocation_quantity.label}}{{fields.bypass_allocation_quantity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.bypass_allocation_quantity.label}}">{{fields.bypass_allocation_quantity.label}}<span *ngIf="fields.bypass_allocation_quantity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="bypass_allocation_quantity"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.bypass_allocation_quantity.control.styles.style"
                                      [ngClass]="fields.bypass_allocation_quantity.control.styles.classes">{{fields.bypass_allocation_quantity.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.bypass_allocation_quantity.invalid">
                          <ng-container *ngFor="let error of fields.bypass_allocation_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.order_total_units_widget.hidden" class="widget-container">
            <SalesOrders-order_total_units_widget
            #$widgets_order_total_units_widget 
              [orderId]="$widgets_order_total_units_widget_inParams_orderId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_units_widget')"
            >
            </SalesOrders-order_total_units_widget>
          </div>
          <div *ngIf="!widgets.order_total_picked_units_widget.hidden" class="widget-container">
            <SalesOrders-order_total_picked_units_widget
            #$widgets_order_total_picked_units_widget 
              [orderId]="$widgets_order_total_picked_units_widget_inParams_orderId"
              [shipmentId]="$widgets_order_total_picked_units_widget_inParams_shipmentId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_picked_units_widget')"
            >
            </SalesOrders-order_total_picked_units_widget>
          </div>
          <div *ngIf="!widgets.order_total_picked_gross_widget.hidden" class="widget-container">
            <SalesOrders-order_total_picked_gross_widget
            #$widgets_order_total_picked_gross_widget 
              [orderId]="$widgets_order_total_picked_gross_widget_inParams_orderId"
              [shipmentId]="$widgets_order_total_picked_gross_widget_inParams_shipmentId"
            ($refreshEvent)="refresh(false, false, '$widgets_order_total_picked_gross_widget')"
            >
            </SalesOrders-order_total_picked_gross_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.sales_order_lines.hidden" class="tab" data-cy="tab-sales_order_lines">
              <h2 [className]="tabs.sales_order_lines.active? 'active': ''" (click)="tabs.sales_order_lines.activate()">{{tabs.sales_order_lines.title}}</h2>
            </div>
            <div *ngIf="!tabs.picking_tasks.hidden" class="tab" data-cy="tab-picking_tasks">
              <h2 [className]="tabs.picking_tasks.active? 'active': ''" (click)="tabs.picking_tasks.activate()">{{tabs.picking_tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.manual_allocation_tasks.hidden" class="tab" data-cy="tab-manual_allocation_tasks">
              <h2 [className]="tabs.manual_allocation_tasks.active? 'active': ''" (click)="tabs.manual_allocation_tasks.activate()">{{tabs.manual_allocation_tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.shipping_contents.hidden" class="tab" data-cy="tab-shipping_contents">
              <h2 [className]="tabs.shipping_contents.active? 'active': ''" (click)="tabs.shipping_contents.activate()">{{tabs.shipping_contents.title}}</h2>
            </div>
            <div *ngIf="!tabs.scanned_barcodes.hidden" class="tab" data-cy="tab-scanned_barcodes">
              <h2 [className]="tabs.scanned_barcodes.active? 'active': ''" (click)="tabs.scanned_barcodes.activate()">{{tabs.scanned_barcodes.title}}</h2>
            </div>
            <div *ngIf="!tabs.reports.hidden" class="tab" data-cy="tab-reports">
              <h2 [className]="tabs.reports.active? 'active': ''" (click)="tabs.reports.activate()">{{tabs.reports.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing.hidden" class="tab" data-cy="tab-billing">
              <h2 [className]="tabs.billing.active? 'active': ''" (click)="tabs.billing.activate()">{{tabs.billing.title}}</h2>
            </div>
            <div *ngIf="!tabs.contacts.hidden" class="tab" data-cy="tab-contacts">
              <h2 [className]="tabs.contacts.active? 'active': ''" (click)="tabs.contacts.activate()">{{tabs.contacts.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-sales_order_lines_grid *ngIf="tabs.sales_order_lines.active"
              #$tabs_sales_order_lines
              [orderId]="$tabs_sales_order_lines_sales_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_sales_order_lines_sales_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_sales_order_lines_sales_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_sales_order_lines_sales_order_lines_grid_inParams_orderStatusId"
              [warehouseId]="$tabs_sales_order_lines_sales_order_lines_grid_inParams_warehouseId"
              ($refreshEvent)="refresh(false, false, '$tabs_sales_order_lines')">
              </FootPrintManager-sales_order_lines_grid>
              <FootPrintManager-sales_order_picking_grid *ngIf="tabs.picking_tasks.active"
              #$tabs_picking_tasks
              [orderId]="$tabs_picking_tasks_sales_order_picking_grid_inParams_orderId"
              [shipmentId]="$tabs_picking_tasks_sales_order_picking_grid_inParams_shipmentId"
              [orderStatusId]="$tabs_picking_tasks_sales_order_picking_grid_inParams_orderStatusId"
              ($refreshEvent)="refresh(false, false, '$tabs_picking_tasks')">
              </FootPrintManager-sales_order_picking_grid>
              <FootPrintManager-manual_allocations_grid *ngIf="tabs.manual_allocation_tasks.active"
              #$tabs_manual_allocation_tasks
              [orderId]="$tabs_manual_allocation_tasks_manual_allocations_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_manual_allocation_tasks')">
              </FootPrintManager-manual_allocations_grid>
              <FootPrintManager-shippingcontents_grid *ngIf="tabs.shipping_contents.active"
              #$tabs_shipping_contents
              [orderId]="$tabs_shipping_contents_shippingcontents_grid_inParams_orderId"
              [shipmentId]="$tabs_shipping_contents_shippingcontents_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_shipping_contents')">
              </FootPrintManager-shippingcontents_grid>
              <app-custom_outbound_material_scanned_grid *ngIf="tabs.scanned_barcodes.active"
              #$tabs_scanned_barcodes
              [orderId]="$tabs_scanned_barcodes_custom_outbound_material_scanned_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_scanned_barcodes')">
              </app-custom_outbound_material_scanned_grid>
              <ng-container
                *ngIf="tabs.reports.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.reports.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.reports.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.reports.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <SalesOrders-outbound_bill_of_lading_report
                   *ngIf="tabs.reports.tabs.bol_report.active"
                  #$tabs_reports_bol_report
                  [orderId]="$tabs_reports_bol_report_outbound_bill_of_lading_report_inParams_orderId"
                  ($refreshEvent)="refresh(false, false, '$tabs_reports_bol_report')">
                  </SalesOrders-outbound_bill_of_lading_report>
                  <Reports-custom_packing_list_report
                   *ngIf="tabs.reports.tabs.packing_list_report.active"
                  #$tabs_reports_packing_list_report
                  [orderId]="$tabs_reports_packing_list_report_custom_packing_list_report_inParams_orderId"
                  ($refreshEvent)="refresh(false, false, '$tabs_reports_packing_list_report')">
                  </Reports-custom_packing_list_report>
                  <Reports-custom_commercial_invoice_report
                   *ngIf="tabs.reports.tabs.commerical_invoice_report.active"
                  #$tabs_reports_commerical_invoice_report
                  [orderId]="$tabs_reports_commerical_invoice_report_custom_commercial_invoice_report_inParams_orderId"
                  ($refreshEvent)="refresh(false, false, '$tabs_reports_commerical_invoice_report')">
                  </Reports-custom_commercial_invoice_report>
                  <Reports-custom_packing_list_b2c_report
                   *ngIf="tabs.reports.tabs.b2c_packing_list_report.active"
                  #$tabs_reports_b2c_packing_list_report
                  [orderId]="$tabs_reports_b2c_packing_list_report_custom_packing_list_b2c_report_inParams_orderId"
                  ($refreshEvent)="refresh(false, false, '$tabs_reports_b2c_packing_list_report')">
                  </Reports-custom_packing_list_b2c_report>
              </ng-container>
              <ng-container
                *ngIf="tabs.billing.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.billing.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.billing.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.billing.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-accessorial_tasks_grid
                   *ngIf="tabs.billing.tabs.accessorial_tasks.active"
                  #$tabs_billing_accessorial_tasks
                  [projectId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_projectId"
                  [entityStatusId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_entityStatusId"
                  [orderId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_orderId"
                  [warehouseId]="$tabs_billing_accessorial_tasks_accessorial_tasks_grid_inParams_warehouseId"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_accessorial_tasks')">
                  </FootPrintManager-accessorial_tasks_grid>
                  <FootPrintManager-billing_records_grid
                   *ngIf="tabs.billing.tabs.billing_records.active"
                  #$tabs_billing_billing_records
                  [orderIds]="$tabs_billing_billing_records_billing_records_grid_inParams_orderIds"
                  [includeInvoiced]="$tabs_billing_billing_records_billing_records_grid_inParams_includeInvoiced"
                  [shipmentIds]="$tabs_billing_billing_records_billing_records_grid_inParams_shipmentIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_billing_billing_records')">
                  </FootPrintManager-billing_records_grid>
              </ng-container>
              <FootPrintManager-orderaddresses_grid *ngIf="tabs.contacts.active"
              #$tabs_contacts
              [orderId]="$tabs_contacts_orderaddresses_grid_inParams_orderId"
              ($refreshEvent)="refresh(false, false, '$tabs_contacts')">
              </FootPrintManager-orderaddresses_grid>
              <FootPrintManager-sales_order_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [orderId]="$tabs_tasks_sales_order_tasks_grid_inParams_orderId"
              [shipmentId]="$tabs_tasks_sales_order_tasks_grid_inParams_shipmentId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-sales_order_tasks_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>