<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-release" *ngIf="!toolbar.release.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.release.control.readOnly"
              [ngStyle]="toolbar.release.control.styles.style"
              [ngClass]="toolbar.release.control.styles.classes"
              (click)="on_release_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.release.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.release.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.release.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Source</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-is_licenseplate_move" *ngIf="!fields.is_licenseplate_move.hidden" 
                            class="field-container standard {{fields.is_licenseplate_move.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_licenseplate_move.styles.style"
                            [ngClass]="fields.is_licenseplate_move.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_licenseplate_move.label}}{{fields.is_licenseplate_move.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_licenseplate_move.label}}">{{fields.is_licenseplate_move.label}}<span *ngIf="fields.is_licenseplate_move.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_licenseplate_move"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_licenseplate_move.control.styles.style"
                                      [ngClass]="fields.is_licenseplate_move.control.styles.classes">{{fields.is_licenseplate_move.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_licenseplate_move.invalid">
                          <ng-container *ngFor="let error of fields.is_licenseplate_move.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_location" *ngIf="!fields.source_location.hidden" 
                            class="field-container standard {{fields.source_location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.source_location.styles.style"
                            [ngClass]="fields.source_location.styles.classes">
                        <div class="label-container"
                              title="{{fields.source_location.label}}{{fields.source_location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.source_location.label}}">{{fields.source_location.label}}<span *ngIf="fields.source_location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.source_location.control.type"
                            formControlName="source_location"
                            (displayTextChange)="fields.source_location.control.displayText=$event"
                            [placeholder]="fields.source_location.control.placeholder"
                            [styles]="fields.source_location.control.styles"
                          [warehouseId]="$fields_source_location_selector_inParams_warehouseId"
                          [typeId]="$fields_source_location_selector_inParams_typeId"
                          [isLoose]="$fields_source_location_selector_inParams_isLoose"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.source_location.invalid">
                          <ng-container *ngFor="let error of fields.source_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_licenseplate" *ngIf="!fields.source_licenseplate.hidden" 
                            class="field-container standard {{fields.source_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.source_licenseplate.styles.style"
                            [ngClass]="fields.source_licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.source_licenseplate.label}}{{fields.source_licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.source_licenseplate.label}}">{{fields.source_licenseplate.label}}<span *ngIf="fields.source_licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.source_licenseplate.control.type"
                            formControlName="source_licenseplate"
                            (displayTextChange)="fields.source_licenseplate.control.displayText=$event"
                            [placeholder]="fields.source_licenseplate.control.placeholder"
                            [styles]="fields.source_licenseplate.control.styles"
                          [locationId]="$fields_source_licenseplate_selector_inParams_locationId"
                          [typeId]="$fields_source_licenseplate_selector_inParams_typeId"
                          [archived]="$fields_source_licenseplate_selector_inParams_archived"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.source_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.source_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-materials_by_licenseplate_dd_single 
                            data-cy="selector"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                          [licenseplateId]="$fields_material_selector_inParams_licenseplateId"
                        >
                        </Inventory-materials_by_licenseplate_dd_single>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendorlot" *ngIf="!fields.vendorlot.hidden" 
                            class="field-container standard {{fields.vendorlot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendorlot.styles.style"
                            [ngClass]="fields.vendorlot.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendorlot.label}}{{fields.vendorlot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendorlot.label}}">{{fields.vendorlot.label}}<span *ngIf="fields.vendorlot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-vendorlots_by_licenseplate_dd_single 
                            data-cy="selector"
                            [type]="fields.vendorlot.control.type"
                            formControlName="vendorlot"
                            (displayTextChange)="fields.vendorlot.control.displayText=$event"
                            [placeholder]="fields.vendorlot.control.placeholder"
                            [styles]="fields.vendorlot.control.styles"
                          [licenseplateId]="$fields_vendorlot_selector_inParams_licenseplateId"
                          [materialId]="$fields_vendorlot_selector_inParams_materialId"
                        >
                        </Inventory-vendorlots_by_licenseplate_dd_single>
                        <ng-container *ngIf="fields.vendorlot.invalid">
                          <ng-container *ngFor="let error of fields.vendorlot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                              title="{{fields.lot.label}}{{fields.lot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lot.label}}">{{fields.lot.label}}<span *ngIf="fields.lot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-lots_by_licenseplate_dd_single 
                            data-cy="selector"
                            [type]="fields.lot.control.type"
                            formControlName="lot"
                            (displayTextChange)="fields.lot.control.displayText=$event"
                            [placeholder]="fields.lot.control.placeholder"
                            [styles]="fields.lot.control.styles"
                          [licenseplateId]="$fields_lot_selector_inParams_licenseplateId"
                          [materialId]="$fields_lot_selector_inParams_materialId"
                          [vendorlotId]="$fields_lot_selector_inParams_vendorlotId"
                        >
                        </Inventory-lots_by_licenseplate_dd_single>
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.packaging.label}}{{fields.packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.packaging.label}}">{{fields.packaging.label}}<span *ngIf="fields.packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-packagings_by_licenseplate_material_dd_single 
                            data-cy="selector"
                            [type]="fields.packaging.control.type"
                            formControlName="packaging"
                            (displayTextChange)="fields.packaging.control.displayText=$event"
                            [placeholder]="fields.packaging.control.placeholder"
                            [styles]="fields.packaging.control.styles"
                          [licenseplateId]="$fields_packaging_selector_inParams_licenseplateId"
                          [materialId]="$fields_packaging_selector_inParams_materialId"
                        >
                        </Inventory-packagings_by_licenseplate_material_dd_single>
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-current_packaged_amount" *ngIf="!fields.current_packaged_amount.hidden" 
                            class="field-container standard {{fields.current_packaged_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.current_packaged_amount.styles.style"
                            [ngClass]="fields.current_packaged_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.current_packaged_amount.label}}{{fields.current_packaged_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.current_packaged_amount.label}}">{{fields.current_packaged_amount.label}}<span *ngIf="fields.current_packaged_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.current_packaged_amount.control.styles.style"
                              [ngClass]="fields.current_packaged_amount.control.styles.classes">{{fields.current_packaged_amount.control.text }}</div>
                        <ng-container *ngIf="fields.current_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.current_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-available_packaged_amount" *ngIf="!fields.available_packaged_amount.hidden" 
                            class="field-container standard {{fields.available_packaged_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.available_packaged_amount.styles.style"
                            [ngClass]="fields.available_packaged_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.available_packaged_amount.label}}{{fields.available_packaged_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.available_packaged_amount.label}}">{{fields.available_packaged_amount.label}}<span *ngIf="fields.available_packaged_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.available_packaged_amount.control.styles.style"
                              [ngClass]="fields.available_packaged_amount.control.styles.classes">{{fields.available_packaged_amount.control.text }}</div>
                        <ng-container *ngIf="fields.available_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.available_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-moved_packaged_amount" *ngIf="!fields.moved_packaged_amount.hidden" 
                            class="field-container full {{fields.moved_packaged_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.moved_packaged_amount.styles.style"
                            [ngClass]="fields.moved_packaged_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.moved_packaged_amount.label}}{{fields.moved_packaged_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.moved_packaged_amount.label}}">{{fields.moved_packaged_amount.label}}<span *ngIf="fields.moved_packaged_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="moved_packaged_amount"
                                matInput
                                numberBox
                                [format]="fields.moved_packaged_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.moved_packaged_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.moved_packaged_amount.control.placeholder}}"
                                [ngStyle]="fields.moved_packaged_amount.control.styles.style"
                                [ngClass]="fields.moved_packaged_amount.control.styles.classes">
                        <ng-container *ngIf="fields.moved_packaged_amount.invalid">
                          <ng-container *ngFor="let error of fields.moved_packaged_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-details" *ngIf="!fields.details.hidden" 
                            class="field-container full {{fields.details.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.details.styles.style"
                            [ngClass]="fields.details.styles.classes">
                        <div class="label-container"
                              title="{{fields.details.label}}{{fields.details.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.details.label}}">{{fields.details.label}}<span *ngIf="fields.details.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="details"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.details.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.details.control.placeholder}}"
                                [ngStyle]="fields.details.control.styles.style"
                                [ngClass]="fields.details.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.details.invalid">
                          <ng-container *ngFor="let error of fields.details.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Target</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-target_location" *ngIf="!fields.target_location.hidden" 
                            class="field-container standard {{fields.target_location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.target_location.styles.style"
                            [ngClass]="fields.target_location.styles.classes">
                        <div class="label-container"
                              title="{{fields.target_location.label}}{{fields.target_location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.target_location.label}}">{{fields.target_location.label}}<span *ngIf="fields.target_location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.target_location.control.type"
                            formControlName="target_location"
                            (displayTextChange)="fields.target_location.control.displayText=$event"
                            [placeholder]="fields.target_location.control.placeholder"
                            [styles]="fields.target_location.control.styles"
                          [warehouseId]="$fields_target_location_selector_inParams_warehouseId"
                          [typeId]="$fields_target_location_selector_inParams_typeId"
                          [isLoose]="$fields_target_location_selector_inParams_isLoose"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.target_location.invalid">
                          <ng-container *ngFor="let error of fields.target_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_new_target_licenseplate" *ngIf="!fields.is_new_target_licenseplate.hidden" 
                            class="field-container standard {{fields.is_new_target_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_new_target_licenseplate.styles.style"
                            [ngClass]="fields.is_new_target_licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_new_target_licenseplate.label}}{{fields.is_new_target_licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_new_target_licenseplate.label}}">{{fields.is_new_target_licenseplate.label}}<span *ngIf="fields.is_new_target_licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_new_target_licenseplate"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_new_target_licenseplate.control.styles.style"
                                      [ngClass]="fields.is_new_target_licenseplate.control.styles.classes">{{fields.is_new_target_licenseplate.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_new_target_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.is_new_target_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-target_licenseplate" *ngIf="!fields.target_licenseplate.hidden" 
                            class="field-container standard {{fields.target_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.target_licenseplate.styles.style"
                            [ngClass]="fields.target_licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.target_licenseplate.label}}{{fields.target_licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.target_licenseplate.label}}">{{fields.target_licenseplate.label}}<span *ngIf="fields.target_licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.target_licenseplate.control.type"
                            formControlName="target_licenseplate"
                            (displayTextChange)="fields.target_licenseplate.control.displayText=$event"
                            [placeholder]="fields.target_licenseplate.control.placeholder"
                            [styles]="fields.target_licenseplate.control.styles"
                          [locationId]="$fields_target_licenseplate_selector_inParams_locationId"
                          [typeId]="$fields_target_licenseplate_selector_inParams_typeId"
                          [archived]="$fields_target_licenseplate_selector_inParams_archived"
                          [shipmentId]="$fields_target_licenseplate_selector_inParams_shipmentId"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.target_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.target_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-new_target_licenseplate" *ngIf="!fields.new_target_licenseplate.hidden" 
                            class="field-container standard {{fields.new_target_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.new_target_licenseplate.styles.style"
                            [ngClass]="fields.new_target_licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.new_target_licenseplate.label}}{{fields.new_target_licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.new_target_licenseplate.label}}">{{fields.new_target_licenseplate.label}}<span *ngIf="fields.new_target_licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="new_target_licenseplate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.new_target_licenseplate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.new_target_licenseplate.control.placeholder}}"
                                [ngStyle]="fields.new_target_licenseplate.control.styles.style"
                                [ngClass]="fields.new_target_licenseplate.control.styles.classes"> 
                        <ng-container *ngIf="fields.new_target_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.new_target_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reason_code" *ngIf="!fields.reason_code.hidden" 
                            class="field-container standard {{fields.reason_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reason_code.styles.style"
                            [ngClass]="fields.reason_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.reason_code.label}}{{fields.reason_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reason_code.label}}">{{fields.reason_code.label}}<span *ngIf="fields.reason_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-reasoncodes_dd_single 
                            data-cy="selector"
                            [type]="fields.reason_code.control.type"
                            formControlName="reason_code"
                            (displayTextChange)="fields.reason_code.control.displayText=$event"
                            [placeholder]="fields.reason_code.control.placeholder"
                            [styles]="fields.reason_code.control.styles"
                          [parentId]="$fields_reason_code_selector_inParams_parentId"
                          [parentEntity]="$fields_reason_code_selector_inParams_parentEntity"
                        >
                        </Inventory-reasoncodes_dd_single>
                        <ng-container *ngIf="fields.reason_code.invalid">
                          <ng-container *ngFor="let error of fields.reason_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>