import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { PalletTransactions_is_pallet_class_deletableService } from './PalletTransactions.flow.index';
import { PalletTransactions_is_pallet_class_name_validService } from './PalletTransactions.flow.index';
import { PalletTransactions_is_pallet_transaction_deletableService } from './PalletTransactions.flow.index';
import { PalletTransactions_is_pallet_transaction_neededService } from './PalletTransactions.flow.index';
import { PalletTransactions_is_pallet_transaction_validService } from './PalletTransactions.flow.index';
import { PalletTransactions_pallet_class_create_or_updateService } from './PalletTransactions.flow.index';
import { PalletTransactions_pallet_inventory_add_or_deductService } from './PalletTransactions.flow.index';
import { PalletTransactions_pallet_transaction_create_or_updateService } from './PalletTransactions.flow.index';

import { $types } from './PalletTransactions.types'

@Injectable({ providedIn: 'root' })
export class PalletTransactions_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public PalletTransactions: PalletTransactions_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _is_pallet_class_deletable: PalletTransactions_is_pallet_class_deletableService;
  public async is_pallet_class_deletable(inParams: { id?: number }): Promise< { valid?: boolean, exceptions?: string }> {
    if(!this._is_pallet_class_deletable) {
      this._is_pallet_class_deletable = this.injector.get(PalletTransactions_is_pallet_class_deletableService);
    }
    return this._is_pallet_class_deletable.run(inParams);
  }
  private _is_pallet_class_name_valid: PalletTransactions_is_pallet_class_name_validService;
  public async is_pallet_class_name_valid(inParams: { name?: string, id?: number }): Promise< { valid?: boolean, exceptions?: string }> {
    if(!this._is_pallet_class_name_valid) {
      this._is_pallet_class_name_valid = this.injector.get(PalletTransactions_is_pallet_class_name_validService);
    }
    return this._is_pallet_class_name_valid.run(inParams);
  }
  private _is_pallet_transaction_deletable: PalletTransactions_is_pallet_transaction_deletableService;
  public async is_pallet_transaction_deletable(inParams: { id?: number, project_id?: number, shipment_id?: number, carrier_id?: number, account_id?: number, pallet_class_id?: number, reference?: string, notes?: string, amount?: number }): Promise< { valid?: boolean, exceptions?: string }> {
    if(!this._is_pallet_transaction_deletable) {
      this._is_pallet_transaction_deletable = this.injector.get(PalletTransactions_is_pallet_transaction_deletableService);
    }
    return this._is_pallet_transaction_deletable.run(inParams);
  }
  private _is_pallet_transaction_needed: PalletTransactions_is_pallet_transaction_neededService;
  public async is_pallet_transaction_needed(inParams: { order_entity?: any, shipment_entity?: any, project_entity?: any }): Promise< { needed?: boolean }> {
    if(!this._is_pallet_transaction_needed) {
      this._is_pallet_transaction_needed = this.injector.get(PalletTransactions_is_pallet_transaction_neededService);
    }
    return this._is_pallet_transaction_needed.run(inParams);
  }
  private _is_pallet_transaction_valid: PalletTransactions_is_pallet_transaction_validService;
  public async is_pallet_transaction_valid(inParams: { id?: number, project_id?: number, shipment_id?: number, carrier_id?: number, account_id?: number, pallet_class_id?: number, reference?: string, notes?: string, amount?: number }): Promise< { valid?: boolean, exceptions?: string }> {
    if(!this._is_pallet_transaction_valid) {
      this._is_pallet_transaction_valid = this.injector.get(PalletTransactions_is_pallet_transaction_validService);
    }
    return this._is_pallet_transaction_valid.run(inParams);
  }
  private _pallet_class_create_or_update: PalletTransactions_pallet_class_create_or_updateService;
  public async pallet_class_create_or_update(inParams: { id?: number, name?: string, notes?: string, remove?: boolean }): Promise< { success?: boolean, exceptions?: string, id?: number }> {
    if(!this._pallet_class_create_or_update) {
      this._pallet_class_create_or_update = this.injector.get(PalletTransactions_pallet_class_create_or_updateService);
    }
    return this._pallet_class_create_or_update.run(inParams);
  }
  private _pallet_inventory_add_or_deduct: PalletTransactions_pallet_inventory_add_or_deductService;
  public async pallet_inventory_add_or_deduct(inParams: { project_id?: number, pallet_class_id?: number, amount?: number }): Promise< { success?: boolean, exceptions?: string, id?: number }> {
    if(!this._pallet_inventory_add_or_deduct) {
      this._pallet_inventory_add_or_deduct = this.injector.get(PalletTransactions_pallet_inventory_add_or_deductService);
    }
    return this._pallet_inventory_add_or_deduct.run(inParams);
  }
  private _pallet_transaction_create_or_update: PalletTransactions_pallet_transaction_create_or_updateService;
  public async pallet_transaction_create_or_update(inParams: { remove?: boolean, id?: number, pallet_class_id?: number, project_id?: number, shipment_id?: number, account_id?: number, carrier_id?: number, notes?: string, reference?: string, amount?: number, date?: string }): Promise< { success?: boolean, exceptions?: string, id?: number }> {
    if(!this._pallet_transaction_create_or_update) {
      this._pallet_transaction_create_or_update = this.injector.get(PalletTransactions_pallet_transaction_create_or_updateService);
    }
    return this._pallet_transaction_create_or_update.run(inParams);
  }
}
