import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelProjectImport_excel_project_import_library_homeComponent } from './ExcelProjectImport.excel_project_import_library_home.component';
import { ExcelProjectImport_excel_project_import_gridComponent } from './ExcelProjectImport.excel_project_import_grid.component';
import { ExcelProjectImport_owners_dd_singleComponent } from './ExcelProjectImport.owners_dd_single.component';
import { ExcelProjectImport_owners_dd_multiComponent } from './ExcelProjectImport.owners_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class ExcelProjectImport_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public ExcelProjectImport: ExcelProjectImport_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openexcel_project_import_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'ExcelProjectImport_excel_project_import_library_home',
        component: ExcelProjectImport_excel_project_import_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_project_import_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelProjectImport_excel_project_import_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openexcel_project_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Project Import',
        referenceName: 'ExcelProjectImport_excel_project_import_grid',
        component: ExcelProjectImport_excel_project_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_project_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelProjectImport_excel_project_import_gridComponent,
      'Project Import',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'ExcelProjectImport_excel_project_import_library_home') {
      const title = 'Home';
      const component = ExcelProjectImport_excel_project_import_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelProjectImport_excel_project_import_grid') {
      const title = 'Project Import';
      const component = ExcelProjectImport_excel_project_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelProjectImport_owners_dd_single') {
      const title = 'Owners dropdown';
      const component = ExcelProjectImport_owners_dd_singleComponent;
      const inParams:{ statusId?: number } = { statusId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelProjectImport_owners_dd_multi') {
      const title = 'Owners dropdown';
      const component = ExcelProjectImport_owners_dd_multiComponent;
      const inParams:{ statusId?: number } = { statusId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
