<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-delete_configuration" *ngIf="!topToolbar.delete_configuration.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_configuration.control.readOnly"
            [ngStyle]="topToolbar.delete_configuration.control.styles.style"
            [ngClass]="topToolbar.delete_configuration.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_configuration.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_configuration.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_configuration.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-field_selector" *ngIf="!topToolbar.field_selector.hidden" class="toolContainer">
    <button mat-button 
            data-cy="splitbutton"
            class="datex-button splitbutton" 
            [disabled]="topToolbar.field_selector.control.readOnly"
            [ngStyle]="topToolbar.field_selector.control.styles.style"
            [ngClass]="topToolbar.field_selector.control.styles.classes"
            [matMenuTriggerFor]="dropdownMenuOne">
      <div class="button-label">
        <div *ngIf="topToolbar.field_selector.control.icon"
              class="button-icon">
          <i data-cy="splitbutton-icon" class="icon {{topToolbar.field_selector.control.icon}}"></i>
        </div>
        <div data-cy="splitbutton-label" class="button-text">{{topToolbar.field_selector.control.label}}</div>
        <div class="button-icon splitbutton-drop-icon">
          <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
        </div>
      </div>
    </button>
    <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
      <div data-cy="splitbutton-options">
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_id.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_id.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_id.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_id.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_id.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_id.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_context.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_context.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_context.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_context.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_context.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_context.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_label.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_label.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_label.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_label.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_label.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_label.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_component_package.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_component_package.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_component_package.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_component_package.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_component_package.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_component_package.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_report_name.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_report_name.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_report_name.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_report_name.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_report_name.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_report_name.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_notes.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_notes.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_notes.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_notes.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_notes.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_notes.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_created_by.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_created_by.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_created_by.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_created_by.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_created_by.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_created_by.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_created_on.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_created_on.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_created_on.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_created_on.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_created_on.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_created_on.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_modified_by.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_modified_by.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_modified_by.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_modified_by.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_modified_by.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_modified_by.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_modified_on.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_modified_on.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_modified_on.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_modified_on.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_modified_on.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_modified_on.label}}</div>
            </div>
          </button>
        </div>
      </div>
    </mat-menu>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="context">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.context.displayControl.styles.style"
          [ngClass]="row.cells.context.displayControl.styles.classes">{{row.cells.context.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Notifications-auto_email_rule_contexts_dd_single 
        data-cy="selector"
        [type]="row.cells.context.editControl.type"
        [formControl]="row.formGroup.controls['context_edit']"
        (displayTextChange)="row.cells.context.editControl.displayText=$event"
        [placeholder]="row.cells.context.editControl.placeholder"
        [styles]="row.cells.context.editControl.styles"
    >
    </Notifications-auto_email_rule_contexts_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="label">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.label.displayControl.styles.style"
          [ngClass]="row.cells.label.displayControl.styles.classes">{{row.cells.label.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['label_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.label.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.label.editControl.placeholder}}"
            [ngStyle]="row.cells.label.editControl.styles.style"
            [ngClass]="row.cells.label.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="package_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.package_name.displayControl.styles.style"
          [ngClass]="row.cells.package_name.displayControl.styles.classes">{{row.cells.package_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['package_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.package_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.package_name.editControl.placeholder}}"
            [ngStyle]="row.cells.package_name.editControl.styles.style"
            [ngClass]="row.cells.package_name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="report_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.report_name.displayControl.styles.style"
          [ngClass]="row.cells.report_name.displayControl.styles.classes">{{row.cells.report_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['report_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.report_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.report_name.editControl.placeholder}}"
            [ngStyle]="row.cells.report_name.editControl.styles.style"
            [ngClass]="row.cells.report_name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.modified_by.displayControl.styles.style"
          [ngClass]="row.cells.modified_by.displayControl.styles.classes">{{row.cells.modified_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
