import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-integration_info_by_order_id',
  templateUrl: './FootPrintManager.integration_info_by_order_id.component.html'
})
export class FootPrintManager_integration_info_by_order_idComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { order_id?: number } = { order_id: null };
  //#region Inputs
  @Input('order_id') set $inParams_order_id(v: number) {
    this.inParams.order_id = v;
  }
  get $inParams_order_id(): number {
    return this.inParams.order_id;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { outbound_transactions?: { transaction_id?: string, transmission_id?: number, entity?: string }[] } = { };
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    IntegrationHubId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    IntegrationHubSourceId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    IntegrationHubSourceLookupCode: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    IntegrationHubSourcePlatform: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_Type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_CreatedSysDateTime: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_IntegrationId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_TransactionId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_Notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_Payload_Xml: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    In_Payload_Json: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_Type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_CreatedSysDateTime: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_IntegrationId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_TransactionId: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_Notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_Payload_Xml: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    Out_Payload_Json: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
  };

  fields = {
    IntegrationHubId: new FieldModel(new TextBoxModel(this.formGroup.controls['IntegrationHubId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Integration hub id', false)
,
    IntegrationHubSourceId: new FieldModel(new TextBoxModel(this.formGroup.controls['IntegrationHubSourceId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Integration hub source id', false)
,
    IntegrationHubSourceLookupCode: new FieldModel(new TextBoxModel(this.formGroup.controls['IntegrationHubSourceLookupCode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Integration hub source lookup', false)
,
    IntegrationHubSourcePlatform: new FieldModel(new TextBoxModel(this.formGroup.controls['IntegrationHubSourcePlatform'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Integration hub source platform', false)
,
    In_Type: new FieldModel(new TextBoxModel(this.formGroup.controls['In_Type'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Type', false)
,
    In_CreatedSysDateTime: new FieldModel(new DateBoxModel(this.formGroup.controls['In_CreatedSysDateTime'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Created sys date time', false)
,
    In_IntegrationId: new FieldModel(new TextBoxModel(this.formGroup.controls['In_IntegrationId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Integration id', false)
,
    In_TransactionId: new FieldModel(new TextBoxModel(this.formGroup.controls['In_TransactionId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Transaction id', false)
,
    In_Notes: new FieldModel(new TextBoxModel(this.formGroup.controls['In_Notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    In_Payload_Xml: new FieldModel( new CodeBoxModel(this.formGroup.controls['In_Payload_Xml'] as DatexFormControl, null, null,'xml')
, new ControlContainerStyles(null, null), 'Xml payload', false)
,
    In_Payload_Json: new FieldModel( new CodeBoxModel(this.formGroup.controls['In_Payload_Json'] as DatexFormControl, null, null,'json')
, new ControlContainerStyles(null, null), 'Json payload', false)
,
    Out_Type: new FieldModel(new TextBoxModel(this.formGroup.controls['Out_Type'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Type', false)
,
    Out_CreatedSysDateTime: new FieldModel(new DateBoxModel(this.formGroup.controls['Out_CreatedSysDateTime'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Created sys date time', false)
,
    Out_IntegrationId: new FieldModel(new TextBoxModel(this.formGroup.controls['Out_IntegrationId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Integration id', false)
,
    Out_TransactionId: new FieldModel(new TextBoxModel(this.formGroup.controls['Out_TransactionId'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Transaction id', false)
,
    Out_Notes: new FieldModel(new TextBoxModel(this.formGroup.controls['Out_Notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    Out_Payload_Xml: new FieldModel( new CodeBoxModel(this.formGroup.controls['Out_Payload_Xml'] as DatexFormControl, null, null,'xml')
, new ControlContainerStyles(null, null), 'Xml payload', false)
,
    Out_Payload_Json: new FieldModel( new CodeBoxModel(this.formGroup.controls['Out_Payload_Json'] as DatexFormControl, null, null,'json')
, new ControlContainerStyles(null, null), 'Json payload', false)
,
    Out_Delete: new FieldModel(new ButtonModel('', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
, new ControlContainerStyles(null, null), 'Deleting the transmission may re-trigger the export process.', false)
,
  };

  fieldsets = {
  integration_hub: new FieldsetModel('Integration information', false, true, true),
  inbound_transmission: new FieldsetModel('Inbound transmission', false, true, true),
  outbound_transmission: new FieldsetModel('Outbound transmission', false, true, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    IntegrationHubId: this.fields.IntegrationHubId.control.valueChanges
    ,
    IntegrationHubSourceId: this.fields.IntegrationHubSourceId.control.valueChanges
    ,
    IntegrationHubSourceLookupCode: this.fields.IntegrationHubSourceLookupCode.control.valueChanges
    ,
    IntegrationHubSourcePlatform: this.fields.IntegrationHubSourcePlatform.control.valueChanges
    ,
    In_Type: this.fields.In_Type.control.valueChanges
    ,
    In_CreatedSysDateTime: this.fields.In_CreatedSysDateTime.control.valueChanges
    ,
    In_IntegrationId: this.fields.In_IntegrationId.control.valueChanges
    ,
    In_TransactionId: this.fields.In_TransactionId.control.valueChanges
    ,
    In_Notes: this.fields.In_Notes.control.valueChanges
    ,
    Out_Type: this.fields.Out_Type.control.valueChanges
    ,
    Out_CreatedSysDateTime: this.fields.Out_CreatedSysDateTime.control.valueChanges
    ,
    Out_IntegrationId: this.fields.Out_IntegrationId.control.valueChanges
    ,
    Out_TransactionId: this.fields.Out_TransactionId.control.valueChanges
    ,
    Out_Notes: this.fields.Out_Notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'integration_info_by_order_id';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    
    
    
    
    
    
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .IntegrationHubId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .IntegrationHubSourceId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .IntegrationHubSourceLookupCode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .IntegrationHubSourcePlatform
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .In_Type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .In_CreatedSysDateTime
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .In_IntegrationId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .In_TransactionId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .In_Notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .Out_Type
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .Out_CreatedSysDateTime
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .Out_IntegrationId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .Out_TransactionId
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .Out_Notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_integration_info_by_order_idComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/05/2024
  
  $form.title = `Integration information for order id ${$form.inParams.order_id}`
  
  let integration_info = (await $flows.FootPrintManager.get_integration_info_by_order_id({ order_id: $form.inParams.order_id }));
  
  if ($utils.isDefined(integration_info)) {
  
      $form.fieldsets.integration_hub.hidden = true;
      $form.fields.IntegrationHubId.hidden = true;
      $form.fields.IntegrationHubSourceId.hidden = true;
      $form.fields.IntegrationHubSourceLookupCode.hidden = true;
      $form.fields.IntegrationHubSourcePlatform.hidden = true;
  
      if (integration_info.IntegrationHubId !== "") {
          $form.fieldsets.integration_hub.hidden = false;
          $form.fields.IntegrationHubId.hidden = false;
          $form.fields.IntegrationHubId.control.value = integration_info.IntegrationHubId;
      }
  
      if (integration_info.IntegrationHubSourceId !== "") {
          $form.fieldsets.integration_hub.hidden = false;
          $form.fields.IntegrationHubSourceId.hidden = false;
          $form.fields.IntegrationHubSourceId.control.value = integration_info.IntegrationHubSourceId;
      }
  
      if (integration_info.IntegrationHubSourceLookupCode !== "") {
          $form.fieldsets.integration_hub.hidden = false;
          $form.fields.IntegrationHubSourceLookupCode.hidden = false;
          $form.fields.IntegrationHubSourceLookupCode.control.value = integration_info.IntegrationHubSourceLookupCode;
      }
  
      if (integration_info.IntegrationHubSourcePlatform !== "") {
          $form.fieldsets.integration_hub.hidden = false;
          $form.fields.IntegrationHubSourcePlatform.hidden = false;
          $form.fields.IntegrationHubSourcePlatform.control.value = integration_info.IntegrationHubSourcePlatform;
      }
  
      $form.fieldsets.inbound_transmission.hidden = true;
      $form.fields.In_CreatedSysDateTime.hidden = true;
      $form.fields.In_IntegrationId.hidden = true;
      $form.fields.In_Notes.hidden = true;
      $form.fields.In_Payload_Json.hidden = true;
      $form.fields.In_Payload_Xml.hidden = true;
      $form.fields.In_TransactionId.hidden = true;
      $form.fields.In_Type.hidden = true;
  
      let Inbounds = integration_info.WavelengthConnectorTransmissions?.filter(t => t.Direction?.toUpperCase() === "IN")
  
      if ($utils.isDefined(Inbounds)) {
  
          Inbounds = Inbounds.sort((a, b) => new Date(b.CreatedSysDateTime).getTime() - new Date(a.CreatedSysDateTime).getTime());; //Sort newest to oldest
  
          let Inbound = Inbounds[0]; //Get the oldest record
  
          $form.fieldsets.inbound_transmission.hidden = false;
  
          $form.fields.In_CreatedSysDateTime.hidden = false;
          $form.fields.In_IntegrationId.hidden = false;
          $form.fields.In_TransactionId.hidden = false;
          $form.fields.In_Type.hidden = false;
  
          $form.fields.In_CreatedSysDateTime.control.value = Inbound.CreatedSysDateTime;
          $form.fields.In_IntegrationId.control.value = Inbound.IntegrationId.toUpperCase();
          $form.fields.In_TransactionId.control.value = Inbound.TransactionId.toUpperCase();
          $form.fields.In_Type.control.value = `${Inbound.Type}${(Inbounds.length > 1) ? ` (${Inbounds.length} records)` : ``}`;
  
          let payload = Inbound.Notes;
  
          if ($utils.isDefined(payload)) {
  
              try {
  
                  payload = payload.trim();
  
                  if (is_json(payload)) {
                      $form.fields.In_Payload_Json.control.value = JSON.parse(payload);
                      $form.fields.In_Payload_Json.hidden = false;
                  } else if (is_xml(payload)) {
                      $form.fields.In_Payload_Xml.control.value = $utils.parseXml(payload);
                      $form.fields.In_Payload_Xml.hidden = false;
                  } else {
                      $form.fields.In_Notes.control.value = payload;
                      $form.fields.In_Notes.hidden = false;
                  }
  
              } catch (e) {
                  $form.fields.In_Notes.control.value = payload;
                  $form.fields.In_Payload_Json.hidden = true;
                  $form.fields.In_Payload_Xml.hidden = true;
                  $form.fields.In_Notes.hidden = false;
              }
          }
      }
  
      $form.fieldsets.outbound_transmission.hidden = true;
      $form.fields.Out_CreatedSysDateTime.hidden = true;
      $form.fields.Out_IntegrationId.hidden = true;
      $form.fields.Out_Notes.hidden = true;
      $form.fields.Out_Payload_Json.hidden = true;
      $form.fields.Out_Payload_Xml.hidden = true;
      $form.fields.Out_TransactionId.hidden = true;
      $form.fields.Out_Type.hidden = true;
      $form.fields.Out_Delete.hidden = true;
  
      let Outbounds = integration_info.WavelengthConnectorTransmissions.filter(t => t.Direction?.toUpperCase() === "OUT");
  
      if ($utils.isDefined(Outbounds)) {
  
          Outbounds = Outbounds.sort((a, b) => new Date(b.CreatedSysDateTime).getTime() - new Date(a.CreatedSysDateTime).getTime());; //Sort newest to oldest
  
          let Outbound = Outbounds[0]; //Get the oldest record
  
          $form.fieldsets.outbound_transmission.hidden = false;
  
          $form.fields.Out_CreatedSysDateTime.hidden = false;
          $form.fields.Out_IntegrationId.hidden = false;
          $form.fields.Out_TransactionId.hidden = false;
          $form.fields.Out_Type.hidden = false;
          $form.fields.Out_Delete.hidden = false;
  
          $form.vars.outbound_transactions = Outbounds.map(o => {
              return {
                  transaction_id: o.TransactionId,
                  transmission_id: o.Id,
                  entity: o.Entity
              }
          })
  
          $form.fields.Out_CreatedSysDateTime.control.value = Outbound.CreatedSysDateTime;
          $form.fields.Out_IntegrationId.control.value = Outbound.IntegrationId.toUpperCase();
          $form.fields.Out_TransactionId.control.value = Outbound.TransactionId.toUpperCase();
          $form.fields.Out_Type.control.value = `${Outbound.Type}${(Outbounds.length > 1) ? ` (${Outbounds.length} records)` : ``}`;
  
          let payload = Outbound.Notes;
  
          if ($utils.isDefined(payload)) {
  
              try {
  
                  payload = payload.trim();
  
                  if (is_json(payload)) {
                      $form.fields.Out_Payload_Json.control.value = JSON.parse(payload);
                      $form.fields.Out_Payload_Json.hidden = false;
                  } else if (is_xml(payload)) {
                      $form.fields.Out_Payload_Xml.control.value = $utils.parseXml(payload);
                      $form.fields.Out_Payload_Xml.hidden = false;
                  } else {
                      $form.fields.Out_Notes.control.value = payload;
                      $form.fields.Out_Notes.hidden = false;
                  }
  
              } catch (e) {
                  $form.fields.Out_Notes.control.value = payload;
                  $form.fields.Out_Payload_Json.hidden = true;
                  $form.fields.Out_Payload_Xml.hidden = true;
                  $form.fields.Out_Notes.hidden = false;
              }
          }
      }
  }
  
  function is_xml(payload: any) {
      let payload_string: string = payload;
      let is_xml: boolean = false;
      let payload_xml: any;
  
      try {
          if (payload_string.startsWith("<") && payload_string.endsWith(">")) {
              payload_xml = $utils.parseXml(payload_string)
              if ($utils.isDefined(payload_xml)) {
                  is_xml = true;
              };
          };
      } catch (e) { }
      return is_xml;
  }
  
  function is_json(payload: any) {
      let payload_string: string = payload;
      let is_json: boolean = false;
      let payload_json: any;
  
      try {
          payload_string = payload_string.trim();
          payload_json = JSON.parse(payload_string);
          if ($utils.isDefined(payload_json)) {
              is_json = true;
          }
      } catch (e) { }
      return is_json;
  }
  }
  on_out_delete(event = null) {
    return this.on_out_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_out_deleteInternal(
    $form: FootPrintManager_integration_info_by_order_idComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/05/2024
  
  if ($utils.isDefined($form.vars.outbound_transactions)) {
  
      let count: number = 0;
  
      try {
  
          for (let outbound_transaction of $form.vars.outbound_transactions) {
  
              if (outbound_transaction.entity === `Order`) {
                  await $flows.Utilities.crud_delete_flow({ entitySet: 'WavelengthConnectorOrderTransmissions', id: outbound_transaction.transmission_id });
              } else if (outbound_transaction.entity === `Shipment`) {
                  await $flows.Utilities.crud_delete_flow({ entitySet: 'WavelengthConnectorShipmentTransmissions', id: outbound_transaction.transmission_id });
              }
  
              count++;
          }
  
          await $shell.FootPrintManager.openInfoDialog(`Success!`, `Deleted ${count} record(s)!`)
  
      } catch (error) {
  
          let targetError = error;
          while ($utils.isDefined(targetError?.error)) { targetError = targetError.error };
          if (typeof targetError === "string") { targetError = { "message": targetError } };
          if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } };
  
          await $shell.FootPrintManager.openErrorDialog(`Error!`, targetError?.message)
      }
  
      await $form.on_init();
  }
  }
  //#endregion private flows
}
