<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-complete" *ngIf="!toolbar.complete.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.complete.control.readOnly"
              [ngStyle]="toolbar.complete.control.styles.style"
              [ngClass]="toolbar.complete.control.styles.classes"
              (click)="on_complete_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.complete.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.complete.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.complete.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-status_change" *ngIf="!toolbar.status_change.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.status_change.control.readOnly"
              [ngStyle]="toolbar.status_change.control.styles.style"
              [ngClass]="toolbar.status_change.control.styles.classes"
              (click)="on_status_change_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.status_change.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.status_change.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.status_change.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-on_export" *ngIf="!toolbar.on_export.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.on_export.control.readOnly"
              [ngStyle]="toolbar.on_export.control.styles.style"
              [ngClass]="toolbar.on_export.control.styles.classes"
              (click)="on_export_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.on_export.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.on_export.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.on_export.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-on_delete" *ngIf="!toolbar.on_delete.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.on_delete.control.readOnly"
              [ngStyle]="toolbar.on_delete.control.styles.style"
              [ngClass]="toolbar.on_delete.control.styles.classes"
              (click)="on_delete_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.on_delete.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.on_delete.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator2" *ngIf="!toolbar.separator2.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-print" *ngIf="!toolbar.print.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.print.control.readOnly"
              [ngStyle]="toolbar.print.control.styles.style"
              [ngClass]="toolbar.print.control.styles.classes"
              (click)="on_print_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.print.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.print.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.print.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-attachments" *ngIf="!toolbar.attachments.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.attachments.control.readOnly"
              [ngStyle]="toolbar.attachments.control.styles.style"
              [ngClass]="toolbar.attachments.control.styles.classes"
              (click)="on_attachments_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.attachments.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.attachments.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.attachments.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-discussions" *ngIf="!toolbar.discussions.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.discussions.control.readOnly"
              [ngStyle]="toolbar.discussions.control.styles.style"
              [ngClass]="toolbar.discussions.control.styles.classes"
              (click)="on_discussions_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.discussions.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.discussions.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.discussions.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-email_requests" *ngIf="!toolbar.email_requests.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.email_requests.control.readOnly"
              [ngStyle]="toolbar.email_requests.control.styles.style"
              [ngClass]="toolbar.email_requests.control.styles.classes"
              (click)="on_email_requests_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.email_requests.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.email_requests.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.email_requests.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Invoice</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-invoice_type" *ngIf="!fields.invoice_type.hidden" 
                            class="field-container standard {{fields.invoice_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.invoice_type.styles.style"
                            [ngClass]="fields.invoice_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.invoice_type.label}}{{fields.invoice_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.invoice_type.label}}">{{fields.invoice_type.label}}<span *ngIf="fields.invoice_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-invoice_types_dd_single 
                            data-cy="selector"
                            [type]="fields.invoice_type.control.type"
                            formControlName="invoice_type"
                            (displayTextChange)="fields.invoice_type.control.displayText=$event"
                            [placeholder]="fields.invoice_type.control.placeholder"
                            [styles]="fields.invoice_type.control.styles"
                        >
                        </Invoices-invoice_types_dd_single>
                        <ng-container *ngIf="fields.invoice_type.invalid">
                          <ng-container *ngFor="let error of fields.invoice_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-invoice_status" *ngIf="!fields.invoice_status.hidden" 
                            class="field-container standard {{fields.invoice_status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.invoice_status.styles.style"
                            [ngClass]="fields.invoice_status.styles.classes">
                        <div class="label-container"
                              title="{{fields.invoice_status.label}}{{fields.invoice_status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.invoice_status.label}}">{{fields.invoice_status.label}}<span *ngIf="fields.invoice_status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.invoice_status.control.styles.style"
                              [ngClass]="fields.invoice_status.control.styles.classes">{{fields.invoice_status.control.text }}</div>
                        <ng-container *ngIf="fields.invoice_status.invalid">
                          <ng-container *ngFor="let error of fields.invoice_status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-invoice_terms" *ngIf="!fields.invoice_terms.hidden" 
                            class="field-container standard {{fields.invoice_terms.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.invoice_terms.styles.style"
                            [ngClass]="fields.invoice_terms.styles.classes">
                        <div class="label-container"
                              title="{{fields.invoice_terms.label}}{{fields.invoice_terms.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.invoice_terms.label}}">{{fields.invoice_terms.label}}<span *ngIf="fields.invoice_terms.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-invoice_terms_dd_single 
                            data-cy="selector"
                            [type]="fields.invoice_terms.control.type"
                            formControlName="invoice_terms"
                            (displayTextChange)="fields.invoice_terms.control.displayText=$event"
                            [placeholder]="fields.invoice_terms.control.placeholder"
                            [styles]="fields.invoice_terms.control.styles"
                        >
                        </Invoices-invoice_terms_dd_single>
                        <ng-container *ngIf="fields.invoice_terms.invalid">
                          <ng-container *ngFor="let error of fields.invoice_terms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-due_date" *ngIf="!fields.due_date.hidden" 
                            class="field-container standard {{fields.due_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.due_date.styles.style"
                            [ngClass]="fields.due_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.due_date.label}}{{fields.due_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.due_date.label}}">{{fields.due_date.label}}<span *ngIf="fields.due_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="due_date"
                            [format]="fields.due_date.control.format"
                            [mode]="fields.due_date.control.mode"
                            [ngStyle]="fields.due_date.control.styles.style"
                            [ngClass]="fields.due_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.due_date.invalid">
                          <ng-container *ngFor="let error of fields.due_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_date" *ngIf="!fields.created_date.hidden" 
                            class="field-container standard {{fields.created_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.created_date.styles.style"
                            [ngClass]="fields.created_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.created_date.label}}{{fields.created_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.created_date.label}}">{{fields.created_date.label}}<span *ngIf="fields.created_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="created_date"
                            [format]="fields.created_date.control.format"
                            [mode]="fields.created_date.control.mode"
                            [ngStyle]="fields.created_date.control.styles.style"
                            [ngClass]="fields.created_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.created_date.invalid">
                          <ng-container *ngFor="let error of fields.created_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reference" *ngIf="!fields.reference.hidden" 
                            class="field-container standard {{fields.reference.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reference.styles.style"
                            [ngClass]="fields.reference.styles.classes">
                        <div class="label-container"
                              title="{{fields.reference.label}}{{fields.reference.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reference.label}}">{{fields.reference.label}}<span *ngIf="fields.reference.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="reference"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.reference.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.reference.control.placeholder}}"
                                [ngStyle]="fields.reference.control.styles.style"
                                [ngClass]="fields.reference.control.styles.classes"> 
                        <ng-container *ngIf="fields.reference.invalid">
                          <ng-container *ngFor="let error of fields.reference.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-attention_of" *ngIf="!fields.attention_of.hidden" 
                            class="field-container standard {{fields.attention_of.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.attention_of.styles.style"
                            [ngClass]="fields.attention_of.styles.classes">
                        <div class="label-container"
                              title="{{fields.attention_of.label}}{{fields.attention_of.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.attention_of.label}}">{{fields.attention_of.label}}<span *ngIf="fields.attention_of.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="attention_of"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.attention_of.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.attention_of.control.placeholder}}"
                                [ngStyle]="fields.attention_of.control.styles.style"
                                [ngClass]="fields.attention_of.control.styles.classes"> 
                        <ng-container *ngIf="fields.attention_of.invalid">
                          <ng-container *ngFor="let error of fields.attention_of.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.invoice_total_units_widget.hidden" class="widget-container">
            <Invoices-invoice_total_units_widget
            #$widgets_invoice_total_units_widget 
              [invoiceId]="$widgets_invoice_total_units_widget_inParams_invoiceId"
            ($refreshEvent)="refresh(false, false, '$widgets_invoice_total_units_widget')"
            >
            </Invoices-invoice_total_units_widget>
          </div>
          <div *ngIf="!widgets.invoice_total_billed_widget.hidden" class="widget-container">
            <Invoices-invoice_total_billed_widget
            #$widgets_invoice_total_billed_widget 
              [invoiceId]="$widgets_invoice_total_billed_widget_inParams_invoiceId"
            ($refreshEvent)="refresh(false, false, '$widgets_invoice_total_billed_widget')"
            >
            </Invoices-invoice_total_billed_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.invoice_lines.hidden" class="tab" data-cy="tab-invoice_lines">
              <h2 [className]="tabs.invoice_lines.active? 'active': ''" (click)="tabs.invoice_lines.activate()">{{tabs.invoice_lines.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-invoice_lines_grid *ngIf="tabs.invoice_lines.active"
              #$tabs_invoice_lines
              [invoiceId]="$tabs_invoice_lines_invoice_lines_grid_inParams_invoiceId"
              ($refreshEvent)="refresh(false, false, '$tabs_invoice_lines')">
              </FootPrintManager-invoice_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>