import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_support_tests_gridComponent } from './FootPrintManager.support_tests_grid.component';
import { FootPrintManager_support_tests_dd_multiComponent } from './FootPrintManager.support_tests_dd_multi.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_support_tests_gridComponent),
    forwardRef(() => FootPrintManager_support_tests_dd_multiComponent),
  ],
  selector: 'FootPrintManager-support_tests_hub',
  templateUrl: './FootPrintManager.support_tests_hub.component.html'
})
export class FootPrintManager_support_tests_hubComponent implements OnInit, OnDestroy {


  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    test_ids: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    test_runs: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    rows_override: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    test_ids: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['test_ids'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Tests', false)
,
    test_runs: new FieldModel(new NumberBoxModel(this.formGroup.controls['test_runs'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'No. of runs to perform per test', false)
,
    rows_override: new FieldModel(new NumberBoxModel(this.formGroup.controls['rows_override'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Override the number of rows', false)
,
  };


  filtersets = {
  options: new FieldsetModel('Options', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      tests: new TabItemModel(
        this.rootTabGroup, 
        'Tests', 
        ),
    };
  
    //#region tabs inParams
    cacheValueFor_$tabs_tests_support_tests_grid_inParams_test_ids: number[];
    get $tabs_tests_support_tests_grid_inParams_test_ids(): number[] {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.test_ids.control.value;
      
      if(!isEqual(this.cacheValueFor_$tabs_tests_support_tests_grid_inParams_test_ids, expr)) {
        this.cacheValueFor_$tabs_tests_support_tests_grid_inParams_test_ids = expr;
      }
      return this.cacheValueFor_$tabs_tests_support_tests_grid_inParams_test_ids;
    }
  
    get $tabs_tests_support_tests_grid_inParams_test_runs(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.test_runs.control.value;
      
      return expr;
    }
  
    get $tabs_tests_support_tests_grid_inParams_rows_override(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.rows_override.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_tests', { read: FootPrintManager_support_tests_gridComponent }) $tabs_tests: FootPrintManager_support_tests_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Support tests hub`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Displays the tests grid to review the performance of the oData api.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.tests,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Support Tests Hub';
    
    const $hub = this;
    const $utils = this.utils;

    (this.filters.test_ids.control as SelectBoxModel).reset([1,2,3,4,5,6,7,8,9]);
    (this.filters.test_runs.control as NumberBoxModel).reset(1);


    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_tests') {
      if (!isNil(this.$tabs_tests) && !this.tabs.tests.hidden) {
        this.$tabs_tests.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_support_tests_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 01/24/2024
  
  
  $hub.tabs.tests.hidden = false;
  $hub.filtersets.options.hidden = false;
  $hub.tabs.tests.activate();
  
  }
  //#endregion private flows
}
