<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container double {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container double {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                        >
                        </Materials-material_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container double {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                              title="{{fields.name.label}}{{fields.name.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.name.label}}">{{fields.name.label}}<span *ngIf="fields.name.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container double {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                              title="{{fields.description.label}}{{fields.description.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.description.label}}">{{fields.description.label}}<span *ngIf="fields.description.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"> 
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_group" *ngIf="!fields.material_group.hidden" 
                            class="field-container full {{fields.material_group.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_group.styles.style"
                            [ngClass]="fields.material_group.styles.classes">
                        <div class="label-container"
                              title="{{fields.material_group.label}}{{fields.material_group.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material_group.label}}">{{fields.material_group.label}}<span *ngIf="fields.material_group.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_groups_dd_single 
                            data-cy="selector"
                            [type]="fields.material_group.control.type"
                            formControlName="material_group"
                            (displayTextChange)="fields.material_group.control.displayText=$event"
                            [placeholder]="fields.material_group.control.placeholder"
                            [styles]="fields.material_group.control.styles"
                        >
                        </Materials-material_groups_dd_single>
                        <ng-container *ngIf="fields.material_group.invalid">
                          <ng-container *ngFor="let error of fields.material_group.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lot_controlled" *ngIf="!fields.lot_controlled.hidden" 
                            class="field-container standard {{fields.lot_controlled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot_controlled.styles.style"
                            [ngClass]="fields.lot_controlled.styles.classes">
                        <div class="label-container"
                              title="{{fields.lot_controlled.label}}{{fields.lot_controlled.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lot_controlled.label}}">{{fields.lot_controlled.label}}<span *ngIf="fields.lot_controlled.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="lot_controlled"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.lot_controlled.control.styles.style"
                                      [ngClass]="fields.lot_controlled.control.styles.classes">{{fields.lot_controlled.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.lot_controlled.invalid">
                          <ng-container *ngFor="let error of fields.lot_controlled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_controlled" *ngIf="!fields.serial_controlled.hidden" 
                            class="field-container standard {{fields.serial_controlled.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.serial_controlled.styles.style"
                            [ngClass]="fields.serial_controlled.styles.classes">
                        <div class="label-container"
                              title="{{fields.serial_controlled.label}}{{fields.serial_controlled.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.serial_controlled.label}}">{{fields.serial_controlled.label}}<span *ngIf="fields.serial_controlled.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="serial_controlled"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.serial_controlled.control.styles.style"
                                      [ngClass]="fields.serial_controlled.control.styles.classes">{{fields.serial_controlled.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.serial_controlled.invalid">
                          <ng-container *ngFor="let error of fields.serial_controlled.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_dimension" *ngIf="!fields.fixed_dimension.hidden" 
                            class="field-container standard {{fields.fixed_dimension.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_dimension.styles.style"
                            [ngClass]="fields.fixed_dimension.styles.classes">
                        <div class="label-container"
                              title="{{fields.fixed_dimension.label}}{{fields.fixed_dimension.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.fixed_dimension.label}}">{{fields.fixed_dimension.label}}<span *ngIf="fields.fixed_dimension.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_dimension"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_dimension.control.styles.style"
                                      [ngClass]="fields.fixed_dimension.control.styles.classes">{{fields.fixed_dimension.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_dimension.invalid">
                          <ng-container *ngFor="let error of fields.fixed_dimension.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-fixed_weight" *ngIf="!fields.fixed_weight.hidden" 
                            class="field-container standard {{fields.fixed_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.fixed_weight.styles.style"
                            [ngClass]="fields.fixed_weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.fixed_weight.label}}{{fields.fixed_weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.fixed_weight.label}}">{{fields.fixed_weight.label}}<span *ngIf="fields.fixed_weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="fixed_weight"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.fixed_weight.control.styles.style"
                                      [ngClass]="fields.fixed_weight.control.styles.classes">{{fields.fixed_weight.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.fixed_weight.invalid">
                          <ng-container *ngFor="let error of fields.fixed_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-storage_category_rule" *ngIf="!fields.storage_category_rule.hidden" 
                            class="field-container standard {{fields.storage_category_rule.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.storage_category_rule.styles.style"
                            [ngClass]="fields.storage_category_rule.styles.classes">
                        <div class="label-container"
                              title="{{fields.storage_category_rule.label}}{{fields.storage_category_rule.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.storage_category_rule.label}}">{{fields.storage_category_rule.label}}<span *ngIf="fields.storage_category_rule.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-storage_category_rules_dd_single 
                            data-cy="selector"
                            [type]="fields.storage_category_rule.control.type"
                            formControlName="storage_category_rule"
                            (displayTextChange)="fields.storage_category_rule.control.displayText=$event"
                            [placeholder]="fields.storage_category_rule.control.placeholder"
                            [styles]="fields.storage_category_rule.control.styles"
                        >
                        </Materials-storage_category_rules_dd_single>
                        <ng-container *ngIf="fields.storage_category_rule.invalid">
                          <ng-container *ngFor="let error of fields.storage_category_rule.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shelf_life_span" *ngIf="!fields.shelf_life_span.hidden" 
                            class="field-container standard {{fields.shelf_life_span.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shelf_life_span.styles.style"
                            [ngClass]="fields.shelf_life_span.styles.classes">
                        <div class="label-container"
                              title="{{fields.shelf_life_span.label}}{{fields.shelf_life_span.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shelf_life_span.label}}">{{fields.shelf_life_span.label}}<span *ngIf="fields.shelf_life_span.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="shelf_life_span"
                                matInput
                                numberBox
                                [format]="fields.shelf_life_span.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.shelf_life_span.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shelf_life_span.control.placeholder}}"
                                [ngStyle]="fields.shelf_life_span.control.styles.style"
                                [ngClass]="fields.shelf_life_span.control.styles.classes">
                        <ng-container *ngIf="fields.shelf_life_span.invalid">
                          <ng-container *ngFor="let error of fields.shelf_life_span.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-minimum_dating_span" *ngIf="!fields.minimum_dating_span.hidden" 
                            class="field-container standard {{fields.minimum_dating_span.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.minimum_dating_span.styles.style"
                            [ngClass]="fields.minimum_dating_span.styles.classes">
                        <div class="label-container"
                              title="{{fields.minimum_dating_span.label}}{{fields.minimum_dating_span.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.minimum_dating_span.label}}">{{fields.minimum_dating_span.label}}<span *ngIf="fields.minimum_dating_span.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="minimum_dating_span"
                                matInput
                                numberBox
                                [format]="fields.minimum_dating_span.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.minimum_dating_span.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.minimum_dating_span.control.placeholder}}"
                                [ngStyle]="fields.minimum_dating_span.control.styles.style"
                                [ngClass]="fields.minimum_dating_span.control.styles.classes">
                        <ng-container *ngIf="fields.minimum_dating_span.invalid">
                          <ng-container *ngFor="let error of fields.minimum_dating_span.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_cycle_count" *ngIf="!fields.skip_cycle_count.hidden" 
                            class="field-container standard {{fields.skip_cycle_count.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_cycle_count.styles.style"
                            [ngClass]="fields.skip_cycle_count.styles.classes">
                        <div class="label-container"
                              title="{{fields.skip_cycle_count.label}}{{fields.skip_cycle_count.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.skip_cycle_count.label}}">{{fields.skip_cycle_count.label}}<span *ngIf="fields.skip_cycle_count.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="skip_cycle_count"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.skip_cycle_count.control.styles.style"
                                      [ngClass]="fields.skip_cycle_count.control.styles.classes">{{fields.skip_cycle_count.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.skip_cycle_count.invalid">
                          <ng-container *ngFor="let error of fields.skip_cycle_count.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-skip_count_back" *ngIf="!fields.skip_count_back.hidden" 
                            class="field-container standard {{fields.skip_count_back.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.skip_count_back.styles.style"
                            [ngClass]="fields.skip_count_back.styles.classes">
                        <div class="label-container"
                              title="{{fields.skip_count_back.label}}{{fields.skip_count_back.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.skip_count_back.label}}">{{fields.skip_count_back.label}}<span *ngIf="fields.skip_count_back.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="skip_count_back"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.skip_count_back.control.styles.style"
                                      [ngClass]="fields.skip_count_back.control.styles.classes">{{fields.skip_count_back.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.skip_count_back.invalid">
                          <ng-container *ngFor="let error of fields.skip_count_back.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-scan_serial_of_all_package_levels" *ngIf="!fields.scan_serial_of_all_package_levels.hidden" 
                            class="field-container standard {{fields.scan_serial_of_all_package_levels.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.scan_serial_of_all_package_levels.styles.style"
                            [ngClass]="fields.scan_serial_of_all_package_levels.styles.classes">
                        <div class="label-container"
                              title="{{fields.scan_serial_of_all_package_levels.label}}{{fields.scan_serial_of_all_package_levels.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.scan_serial_of_all_package_levels.label}}">{{fields.scan_serial_of_all_package_levels.label}}<span *ngIf="fields.scan_serial_of_all_package_levels.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="scan_serial_of_all_package_levels"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.scan_serial_of_all_package_levels.control.styles.style"
                                      [ngClass]="fields.scan_serial_of_all_package_levels.control.styles.classes">{{fields.scan_serial_of_all_package_levels.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.scan_serial_of_all_package_levels.invalid">
                          <ng-container *ngFor="let error of fields.scan_serial_of_all_package_levels.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-allow_receiving" *ngIf="!fields.allow_receiving.hidden" 
                            class="field-container standard {{fields.allow_receiving.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.allow_receiving.styles.style"
                            [ngClass]="fields.allow_receiving.styles.classes">
                        <div class="label-container"
                              title="{{fields.allow_receiving.label}}{{fields.allow_receiving.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.allow_receiving.label}}">{{fields.allow_receiving.label}}<span *ngIf="fields.allow_receiving.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="allow_receiving"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.allow_receiving.control.styles.style"
                                      [ngClass]="fields.allow_receiving.control.styles.classes">{{fields.allow_receiving.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.allow_receiving.invalid">
                          <ng-container *ngFor="let error of fields.allow_receiving.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>