import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Inspections_change_task_statuses_flowService } from './Inspections.flow.index';
import { Inspections_create_inspection_task_flowService } from './Inspections.flow.index';
import { Inspections_create_operation_code_flowService } from './Inspections.flow.index';
import { Inspections_delete_operation_code_flowService } from './Inspections.flow.index';

import { $types } from './Inspections.types'

@Injectable({ providedIn: 'root' })
export class Inspections_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Inspections: Inspections_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _change_task_statuses_flow: Inspections_change_task_statuses_flowService;
  public async change_task_statuses_flow(inParams: { taskIds: number[], targetStatusId: number }): Promise< { reasons?: string[] }> {
    if(!this._change_task_statuses_flow) {
      this._change_task_statuses_flow = this.injector.get(Inspections_change_task_statuses_flowService);
    }
    return this._change_task_statuses_flow.run(inParams);
  }
  private _create_inspection_task_flow: Inspections_create_inspection_task_flowService;
  public async create_inspection_task_flow(inParams: {  }): Promise< { reasons?: string[], taskId?: number }> {
    if(!this._create_inspection_task_flow) {
      this._create_inspection_task_flow = this.injector.get(Inspections_create_inspection_task_flowService);
    }
    return this._create_inspection_task_flow.run(inParams);
  }
  private _create_operation_code_flow: Inspections_create_operation_code_flowService;
  public async create_operation_code_flow(inParams: { name: string, description?: string }): Promise< { reasons?: string[], operationCodeId?: number }> {
    if(!this._create_operation_code_flow) {
      this._create_operation_code_flow = this.injector.get(Inspections_create_operation_code_flowService);
    }
    return this._create_operation_code_flow.run(inParams);
  }
  private _delete_operation_code_flow: Inspections_delete_operation_code_flowService;
  public async delete_operation_code_flow(inParams: { operationCodeId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_operation_code_flow) {
      this._delete_operation_code_flow = this.injector.get(Inspections_delete_operation_code_flowService);
    }
    return this._delete_operation_code_flow.run(inParams);
  }
}
