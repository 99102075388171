import { NgModule, ErrorHandler, ModuleWithProviders } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from '@angular/material/legacy-autocomplete';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatPseudoCheckboxModule } from '@angular/material/core';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list'
import { MatTooltipModule } from '@angular/material/tooltip'

import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

import {
  NgxMatMomentModule,
  NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  NgxMatMomentDateAdapterOptions
} from '@angular-material-components/moment-adapter';

import {
  MatMomentDateModule,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateAdapterOptions
} from '@angular/material-moment-adapter';


import { NgApexchartsModule } from 'ng-apexcharts';

import { DynamicModule } from 'ng-dynamic-component';

import {
  ActiveReportsModule,
  AR_EXPORTS,
  PdfExportService,
  XlsxExportService,
  TabularDataExportService,
  HtmlExportService
} from '@grapecity/activereports-angular';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { DialogComponent } from './components/dialog.component';

import {
  GridComponent,
  GridCellDisplayControlDef,
  GridCellEditControlDef,
  GridColumnDef,
  ExpandableRowDef
} from './components/grid.component';

import {
  ListComponent,
  ListItemDef
} from './components/list.component';

import { DropdownSingleComponent } from './components/dropdown-single.component';
import { DropdownMultiComponent } from './components/dropdown-multi.component';
import { DateboxComponent } from './components/datebox.component';
import { DrawBoxComponent } from './components/draw-box.component';
import { NumberBoxDirective } from './components/number-box.directive';
import { FormatTextPipe } from './components/formatText.pipe';
import { ConfirmationDialogComponent } from './components/confirmation-dialog.component';
import { ImageViewerDialogComponent } from './components/image-viewer-dialog.component';
import { JsonformatterDirective } from './components/jsonformatter.directive';
import { CodeValidatorDirective } from './components/code-validator.directive';

import { DatexErrorHandler } from './error/datex-error-handler';
import { ErrorComponent } from './error/error.component';
import { ErrorDialogComponent } from './error/error-dialog.component';

//#region Calendar
import { CalendarDayViewComponent } from './components/calendar/calendar-day-view.component';
import { CalendarWeekViewCurrentTimeMarkerComponent } from './components/calendar/calendar-week-view-current-time-marker.component';
import { CalendarWeekViewHourSegmentComponent } from './components/calendar/calendar-week-view-hour-segment.component';
import { CalendarDatePipe } from './components/calendar/calendar-date.pipe';
import { DateAdapter } from './components/calendar/date-adapter';
import { CalendarDateFormatter } from './components/calendar/calendar-date-formatter.provider';
import { SafeHtmlPipe } from './components/calendar/safe-html.pipe';
import { SafeUrlPipe } from './components/embed/safe-url.pipe';
//#endregion

import { WizardComponent } from './components/wizard.component';
import { SelectorComponent } from './components/selector.component';

import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { CodemirrorModule } from '@ctrl/ngx-codemirror';
import { ImageViewerModule } from './components/image-viewer/image-viewer.module';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';

const momentDateAdapterConfig: MatMomentDateAdapterOptions = {
  useUtc: true
};

const ngxMomentDateAdapterConfig: NgxMatMomentDateAdapterOptions = {
  useUtc: true
};

@NgModule({
  declarations: [
    DialogComponent,
    ConfirmationDialogComponent,
    ImageViewerDialogComponent,
    ErrorComponent,
    ErrorDialogComponent,
    JsonformatterDirective,
    GridComponent,
    GridCellDisplayControlDef,
    GridCellEditControlDef,
    GridColumnDef,
    ExpandableRowDef,
    ListItemDef,
    ListComponent,
    DropdownSingleComponent,
    DropdownMultiComponent,
    DateboxComponent,
    DrawBoxComponent,
    NumberBoxDirective,
    FormatTextPipe,
    //#region Calendar
    CalendarDayViewComponent,
    CalendarWeekViewCurrentTimeMarkerComponent,
    CalendarWeekViewHourSegmentComponent,
    CalendarDatePipe,
    SafeHtmlPipe,
    //#endregion
    SafeUrlPipe,
    WizardComponent,
    SelectorComponent,
    CodeValidatorDirective
  ],
  imports: [
    CommonModule,
    ImageViewerModule.forRoot({
      containerBackgroundColor: '#fff',
      allowFullscreen: true,
      btnIcons: {
        fullscreen: 'icon icon-ic_fluent_full_screen_maximize_20_regular',
        modalScreen: 'icon icon-ic_fluent_full_screen_minimize_20_regular',
        zoomOut: 'icon icon-ic_fluent_zoom_out_20_regular',
        zoomIn: 'icon icon-ic_fluent_zoom_in_20_regular',
        rotateCounterClockwise: 'icon icon-ic_fluent_arrow_rotate_counterclockwise_20_regular',
        rotateClockwise: 'icon icon-ic_fluent_arrow_rotate_clockwise_20_regular'
      },
      btnShow: {
        zoomIn: true,
        zoomOut: true,
        rotateClockwise: true,
        rotateCounterClockwise: true
      }
    }),
    CodemirrorModule,
    AngularSignaturePadModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatCardModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPseudoCheckboxModule,
    MatListModule,
    NgApexchartsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    DynamicModule,
    ActiveReportsModule,
    TranslateModule.forRoot(),
    ToastrModule.forRoot()
  ],
  exports: [
    DialogComponent,
    ConfirmationDialogComponent,
    ImageViewerDialogComponent,
    ErrorComponent,
    ErrorDialogComponent,
    JsonformatterDirective,
    GridComponent,
    GridCellDisplayControlDef,
    GridCellEditControlDef,
    GridColumnDef,
    ExpandableRowDef,
    ListItemDef,
    ListComponent,
    DropdownSingleComponent,
    DropdownMultiComponent,
    DateboxComponent,
    DrawBoxComponent,
    NumberBoxDirective,
    FormatTextPipe,
    //#region Calendar
    CalendarDayViewComponent,
    CalendarWeekViewCurrentTimeMarkerComponent,
    CalendarWeekViewHourSegmentComponent,
    CalendarDatePipe,
    SafeHtmlPipe,
    //#endregion
    SafeUrlPipe,
    WizardComponent,
    SelectorComponent,
    CodeValidatorDirective,
    CommonModule,
    ImageViewerModule,
    CodemirrorModule,
    AngularSignaturePadModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingBarHttpClientModule,
    MatDialogModule,
    MatButtonModule,
    MatInputModule,
    MatMenuModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSnackBarModule,
    MatChipsModule,
    MatIconModule,
    MatCheckboxModule,
    MatRadioModule,
    MatProgressBarModule,
    MatCardModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPseudoCheckboxModule,
    MatListModule,
    NgApexchartsModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    DynamicModule,
    ActiveReportsModule,
    TranslateModule
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        {
          provide: ErrorHandler,
          useClass: DatexErrorHandler
        },
        {
          provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: momentDateAdapterConfig
        },
        {
          provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS,
          useValue: ngxMomentDateAdapterConfig
        },
        {
          provide: AR_EXPORTS,
          useClass: PdfExportService,
          multi: true
        },
        {
          provide: AR_EXPORTS,
          useClass: XlsxExportService,
          multi: true
        },
        {
          provide: AR_EXPORTS,
          useClass: TabularDataExportService,
          multi: true
        },
        {
          provide: AR_EXPORTS,
          useClass: HtmlExportService,
          multi: true
        },
        //#region Calendar
        DateAdapter,
        CalendarDateFormatter
      ]
    }
  }
}