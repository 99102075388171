import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class ShippingContainers_ds_shipping_containers_tasks_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { shippingContainerId: number, dateTo?: string, dateFrom?: string, dateType?: string, fullTextSearch?: string, chainHead?: number, $top?: number, $skip?: number }): Promise<{ result?: { Id?: number, ActualSourceShippingContainerId?: number, ActualTargetShippingContainerId?: number, ExpectedSourceShippingContainerId?: number, ExpectedTargetShippingContainerId?: number, ActualSourceShippingContainer?: string, ActualTargetShippingContainer?: string, ExpectedSourceShippingContainer?: string, ExpectedTargetShippingContainer?: string, OrderId?: number, OrderLookupCode?: string, LoadContainerId?: number, LoadContainerLookupCode?: string, OperationCodeId?: number, OperationCode?: string, StatusId?: number, Status?: string, ChainHead?: number, StartDateTime?: string, CompletedDateTime?: string, CreatedDateTime?: string, CreatedBy?: string, ModifiedDateTime?: string, ModifiedBy?: string, Employee?: string, ExpectedPackagedAmount?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, ActualPackaging?: string, WarehouseId?: number, WarehouseName?: string, ActualSourceLocationId?: number, ActualSourceLocation?: string, ActualTargetLocationId?: number, ActualTargetLocation?: string, ExpectedSourceLocationId?: number, ExpectedSourceLocation?: string, ExpectedTargetLocationId?: number, ExpectedTargetLocation?: string, MaterialId?: number, Material?: string, LotId?: number, Lot?: string, VendorLotId?: number, VendorLot?: string, ProjectId?: number, Project?: string, OwnerId?: number, Owner?: string, Notes?: string }[], totalCount?: number }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.shippingContainerId)) {
      missingRequiredInParams.push('\'shippingContainerId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_shipping_containers_tasks_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { shippingContainerId?: number, dateTo?: string, dateFrom?: string, dateType?: string, fullTextSearch?: string, chainHead?: number, $keys: number[] }): Promise<{ result?: { Id?: number, ActualSourceShippingContainerId?: number, ActualTargetShippingContainerId?: number, ExpectedSourceShippingContainerId?: number, ExpectedTargetShippingContainerId?: number, ActualSourceShippingContainer?: string, ActualTargetShippingContainer?: string, ExpectedSourceShippingContainer?: string, ExpectedTargetShippingContainer?: string, OrderId?: number, OrderLookupCode?: string, LoadContainerId?: number, LoadContainerLookupCode?: string, OperationCodeId?: number, OperationCode?: string, StatusId?: number, Status?: string, ChainHead?: number, StartDateTime?: string, CompletedDateTime?: string, CreatedDateTime?: string, CreatedBy?: string, ModifiedDateTime?: string, ModifiedBy?: string, Employee?: string, ExpectedPackagedAmount?: number, ActualPackagedAmount?: number, ActualPackagedId?: number, ActualPackaging?: string, WarehouseId?: number, WarehouseName?: string, ActualSourceLocationId?: number, ActualSourceLocation?: string, ActualTargetLocationId?: number, ActualTargetLocation?: string, ExpectedSourceLocationId?: number, ExpectedSourceLocation?: string, ExpectedTargetLocationId?: number, ExpectedTargetLocation?: string, MaterialId?: number, Material?: string, LotId?: number, Lot?: string, VendorLotId?: number, VendorLot?: string, ProjectId?: number, Project?: string, OwnerId?: number, Owner?: string, Notes?: string }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ShippingContainers/datasources/ds_shipping_containers_tasks_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
