<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
    <div data-cy="tool-id-new_alert" *ngIf="!topToolbar.new_alert.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.new_alert.control.readOnly"
            [ngStyle]="topToolbar.new_alert.control.styles.style"
            [ngClass]="topToolbar.new_alert.control.styles.classes"
            (click)="on_new_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.new_alert.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.new_alert.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.new_alert.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-test" *ngIf="!topToolbar.test.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.test.control.readOnly"
            [ngStyle]="topToolbar.test.control.styles.style"
            [ngClass]="topToolbar.test.control.styles.classes"
            (click)="on_test_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.test.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.test.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.test.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-insert_log" *ngIf="!topToolbar.insert_log.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.insert_log.control.readOnly"
            [ngStyle]="topToolbar.insert_log.control.styles.style"
            [ngClass]="topToolbar.insert_log.control.styles.classes"
            (click)="on_insert_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.insert_log.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.insert_log.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.insert_log.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-delete_selected" *ngIf="!topToolbar.delete_selected.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_selected.control.readOnly"
            [ngStyle]="topToolbar.delete_selected.control.styles.style"
            [ngClass]="topToolbar.delete_selected.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_selected.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_selected.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_selected.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="alert_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.alert_name.displayControl.styles.style"
          [ngClass]="row.cells.alert_name.displayControl.styles.classes">{{row.cells.alert_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="integration_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.integration_name.displayControl.styles.style"
          [ngClass]="row.cells.integration_name.displayControl.styles.classes">{{row.cells.integration_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="log_level">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.log_level.displayControl.styles.style"
          [ngClass]="row.cells.log_level.displayControl.styles.classes">{{row.cells.log_level.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="enabled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['enabled_display']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.enabled.displayControl.styles.style"
                  [ngClass]="row.cells.enabled.displayControl.styles.classes">{{row.cells.enabled.displayControl.label}}</mat-slide-toggle>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="to">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.to.displayControl.styles.style"
          [ngClass]="row.cells.to.displayControl.styles.classes">{{row.cells.to.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.cc.displayControl.styles.style"
          [ngClass]="row.cells.cc.displayControl.styles.classes">{{row.cells.cc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bcc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.bcc.displayControl.styles.style"
          [ngClass]="row.cells.bcc.displayControl.styles.classes">{{row.cells.bcc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="subject">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.subject.displayControl.styles.style"
          [ngClass]="row.cells.subject.displayControl.styles.classes">{{row.cells.subject.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="pdf">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['pdf_display']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.pdf.displayControl.styles.style"
                  [ngClass]="row.cells.pdf.displayControl.styles.classes">{{row.cells.pdf.displayControl.label}}</mat-slide-toggle>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_by.displayControl.styles.style"
          [ngClass]="row.cells.created_by.displayControl.styles.classes">{{row.cells.created_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_by">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.modified_by.displayControl.styles.style"
          [ngClass]="row.cells.modified_by.displayControl.styles.classes">{{row.cells.modified_by.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintApiManager-alerts_filters_grid
    [integration_name]="row.$rowExpand_FootPrintApiManager_alerts_filters_grid_inParams_integration_name"
    [alert_id]="row.$rowExpand_FootPrintApiManager_alerts_filters_grid_inParams_alert_id"
    ($refreshEvent)="row.refresh()">>
  </FootPrintApiManager-alerts_filters_grid>
</ng-template>

</datex-grid>
