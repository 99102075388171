import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Addresses_orderaddresses_types_dd_singleComponent } from './Addresses.orderaddresses_types_dd_single.component'


interface IFootPrintManager_orderaddresses_gridComponentEntity {
Id?: number, AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, Name?: string, Notes?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string, TypeId?: number, Type?: { Name?: string }}

interface IFootPrintManager_orderaddresses_gridComponentInParams {
  orderId: number}


class FootPrintManager_orderaddresses_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_orderaddresses_gridComponent;
  entity: IFootPrintManager_orderaddresses_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    type_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    first_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    last_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    line1_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    line2_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    city_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    state_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    postal_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    country_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    attention_of_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_telephone_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    primary_email_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['type_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    first_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['first_name_edit'] as DatexFormControl, null, false, '')
      ),
    last_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['last_name_edit'] as DatexFormControl, null, false, '')
      ),
    line1: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line1_edit'] as DatexFormControl, null, false, '')
      ),
    line2: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['line2_edit'] as DatexFormControl, null, false, '')
      ),
    city: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['city_edit'] as DatexFormControl, null, false, '')
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['state_edit'] as DatexFormControl, null, false, '')
      ),
    postal_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['postal_code_edit'] as DatexFormControl, null, false, '')
      ),
    country: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['country_edit'] as DatexFormControl, null, false, '')
      ),
    attention_of: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['attention_of_edit'] as DatexFormControl, null, false, '')
      ),
    primary_telephone: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['primary_telephone_edit'] as DatexFormControl, null, false, '')
      ),
    primary_email: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['primary_email_edit'] as DatexFormControl, null, false, '')
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_orderaddresses_gridComponent, entity: IFootPrintManager_orderaddresses_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_orderaddresses_gridComponent, entity?: IFootPrintManager_orderaddresses_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_init_new_row();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Addresses.ds_orderaddresses_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.type.displayControl as TextModel).text = $row.entity.Type?.Name;
    (this.cells.type.editControl as SelectBoxModel).reset($row.entity.TypeId);
    (this.cells.first_name.displayControl as TextModel).text = $row.entity.FirstName;
    (this.cells.first_name.editControl as TextBoxModel).reset($row.entity.FirstName);
    (this.cells.last_name.displayControl as TextModel).text = $row.entity.LastName;
    (this.cells.last_name.editControl as TextBoxModel).reset($row.entity.LastName);
    (this.cells.line1.displayControl as TextModel).text = $row.entity.Line1;
    (this.cells.line1.editControl as TextBoxModel).reset($row.entity.Line1);
    (this.cells.line2.displayControl as TextModel).text = $row.entity.Line2;
    (this.cells.line2.editControl as TextBoxModel).reset($row.entity.Line2);
    (this.cells.city.displayControl as TextModel).text = $row.entity.City;
    (this.cells.city.editControl as TextBoxModel).reset($row.entity.City);
    (this.cells.state.displayControl as TextModel).text = $row.entity.State;
    (this.cells.state.editControl as TextBoxModel).reset($row.entity.State);
    (this.cells.postal_code.displayControl as TextModel).text = $row.entity.PostalCode;
    (this.cells.postal_code.editControl as TextBoxModel).reset($row.entity.PostalCode);
    (this.cells.country.displayControl as TextModel).text = $row.entity.Country;
    (this.cells.country.editControl as TextBoxModel).reset($row.entity.Country);
    (this.cells.attention_of.displayControl as TextModel).text = $row.entity.AttentionOf;
    (this.cells.attention_of.editControl as TextBoxModel).reset($row.entity.AttentionOf);
    (this.cells.primary_telephone.displayControl as TextModel).text = $row.entity.PrimaryTelephone;
    (this.cells.primary_telephone.editControl as TextBoxModel).reset($row.entity.PrimaryTelephone);
    (this.cells.primary_email.displayControl as TextModel).text = $row.entity.PrimaryEmail;
    (this.cells.primary_email.editControl as TextBoxModel).reset($row.entity.PrimaryEmail);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Notes);

  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_init_new_row(event = null) {
    return this.on_init_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_init_new_rowInternal(
    $row: FootPrintManager_orderaddresses_gridComponentRowModel,
  $grid: FootPrintManager_orderaddresses_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'OrderAddress' })).nextId;
  
  $row.entity = {
      Id: nextId
  };
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_orderaddresses_gridComponentRowModel,
  $grid: FootPrintManager_orderaddresses_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  // Update carrier service type
  let payload: any = {};
  
  
  if ($row.cells.first_name.editControl.isChanged) {
      payload.FirstName = $row.cells.first_name.editControl.value;
      payload.Name = $row.cells.first_name.editControl.value;
  
  }
  if ($row.cells.last_name.editControl.isChanged) {
      payload.LastName = $row.cells.last_name.editControl.value;
  }
  if ($row.cells.primary_email.editControl.isChanged) {
      payload.PrimaryEmail = $row.cells.primary_email.editControl.value;
  }
  if ($row.cells.primary_telephone.editControl.isChanged) {
      payload.PrimaryTelephone = $row.cells.primary_telephone.editControl.value;
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  if ($row.cells.line1.editControl.isChanged) {
      payload.Line1 = $row.cells.line1.editControl.value;
  }
  if ($row.cells.line2.editControl.isChanged) {
      payload.Line2 = $row.cells.line2.editControl.value;
  }
  if ($row.cells.attention_of.editControl.isChanged) {
      payload.AttentionOf = $row.cells.attention_of.editControl.value;
  }
  if ($row.cells.city.editControl.isChanged) {
      payload.City = $row.cells.city.editControl.value;
  }
  if ($row.cells.state.editControl.isChanged) {
      payload.State = $row.cells.state.editControl.value;
  }
  if ($row.cells.postal_code.editControl.isChanged) {
      payload.PostalCode = $row.cells.postal_code.editControl.value;
  }
  if ($row.cells.country.editControl.isChanged) {
      payload.Country = $row.cells.country.editControl.value;
  }
  
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'OrderAddresses', id: $row.entity.Id, entity: payload });
      await $row.refresh();
  
  }
  
  catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  
  }
  
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_orderaddresses_gridComponentRowModel,
  $grid: FootPrintManager_orderaddresses_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $row.cells.type.editControl.value,
      $row.cells.first_name.editControl.value,
      $row.cells.line1.editControl.value
  );
  
  if (allRequiredFieldHaveValue === false) {
      $shell.Addresses.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  }
  
  try {
  
  
      const payload = {
          "Id": $row.entity.Id,
          "Orderid": $grid.inParams.orderId,
          "TypeId": $row.cells.type.editControl.value,
          "AttentionOf": $row.cells.attention_of?.editControl.value,
          "Name": $row.cells.first_name.editControl.value,
          "Line1": $row.cells.line1?.editControl.value,
          "Line2": $row.cells.line2?.editControl.value,
          "City": $row.cells.city?.editControl.value,
          "State": $row.cells.state?.editControl.value,
          "PostalCode": $row.cells.postal_code?.editControl.value,
          "Country": $row.cells.country?.editControl.value,
          "FirstName": $row.cells.first_name.editControl.value,
          "LastName": $row.cells.last_name.editControl.value,
          "PrimaryEmail": $row.cells.primary_email.editControl.value,
          "PrimaryTelephone": $row.cells.primary_telephone.editControl.value,
          "Notes": $row.cells.notes?.editControl.value,
          "IsResidential": false
      };
      await $flows.Utilities.crud_create_flow({ entitySet: 'OrderAddresses', entity: payload });
  
      await $grid.refresh();
  
  } catch (error) {
      $shell.Addresses.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Addresses_orderaddresses_types_dd_singleComponent),
  ],
  selector: 'FootPrintManager-orderaddresses_grid',
  templateUrl: './FootPrintManager.orderaddresses_grid.component.html'
})
export class FootPrintManager_orderaddresses_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_orderaddresses_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_orderaddresses_gridComponentInParams = { orderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', true, false, null),       first_name: new GridHeaderModel(new HeaderStyles(null, null), 'First name', true, false, null),       last_name: new GridHeaderModel(new HeaderStyles(null, null), 'Last name', false, false, null),       line1: new GridHeaderModel(new HeaderStyles(null, null), 'Line 1', true, false, null),       line2: new GridHeaderModel(new HeaderStyles(null, null), 'Line 2', false, false, null),       city: new GridHeaderModel(new HeaderStyles(null, null), 'City', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       postal_code: new GridHeaderModel(new HeaderStyles(null, null), 'Postal code', false, false, null),       country: new GridHeaderModel(new HeaderStyles(null, null), 'Country', false, false, null),       attention_of: new GridHeaderModel(new HeaderStyles(null, null), 'Attention of', false, false, null),       primary_telephone: new GridHeaderModel(new HeaderStyles(null, null), 'Phone', false, false, null),       primary_email: new GridHeaderModel(new HeaderStyles(null, null), 'Email', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_orderaddresses_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Order addresses';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.Addresses.ds_orderaddresses_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_orderaddresses_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_orderaddresses_gridComponentEntity) {
    const row = new FootPrintManager_orderaddresses_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_orderaddresses_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.Addresses.openErrorDialog('Address Delete Error', 'No Address selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              addressId: row.entity.Id
          };
          const result = await $flows.Addresses.is_orderaddress_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Address ${row.entity.FirstName} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete Address errors';
          const errorMessage = `Address ${failures.map(c => c.entity.FirstName).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.Addresses.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Address ${candidates.map(c => c.entity.FirstName).join(',')} - Once deleted, the Address cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some Addresses cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following Addresses';
              const message = confirmCandidates;
              confirm = await $shell.Addresses.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      addressId: candidate.entity.Id
                      }
                      const result = await $flows.Addresses.delete_orderaddress_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Address ${candidate.entity.FirstName} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All the selected Addresses deleted';
                  const message = `Addresses ${processSuccess.map(c => c.entity.FirstName).join(',')} deleted`;
                  await $shell.Addresses.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All the selected Addresses failed to delete';
                      const message = `Addresses ${processFailures.map(c => c.entity.FirstName).join(',')} could not be deleted`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some Addresses could not be deleted';
                      const deleted = `Addresses ${processSuccess.map(c => c.entity.FirstName).join(',')} were deleted.`;
                      const errors = `The following Addresses could not be deleted: ${processFailures.map(c => c.entity.FirstName).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.Addresses.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_orderaddresses_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
  }
  else{
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
