<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-execute_main" *ngIf="!toolbar.execute_main.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.execute_main.control.readOnly"
                [ngStyle]="toolbar.execute_main.control.styles.style"
                [ngClass]="toolbar.execute_main.control.styles.classes"
                (click)="on_execute_rules_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.execute_main.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.execute_main.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.execute_main.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-manage" *ngIf="!toolbar.manage.hidden" class="toolContainer">
        <button mat-button 
                data-cy="splitbutton"
                class="datex-button splitbutton" 
                [disabled]="toolbar.manage.control.readOnly"
                [ngStyle]="toolbar.manage.control.styles.style"
                [ngClass]="toolbar.manage.control.styles.classes"
                [matMenuTriggerFor]="dropdownMenuOne">
          <div class="button-label">
            <div *ngIf="toolbar.manage.control.icon"
                  class="button-icon">
              <i data-cy="splitbutton-icon" class="icon {{toolbar.manage.control.icon}}"></i>
            </div>
            <div data-cy="splitbutton-label" class="button-text">{{toolbar.manage.control.label}}</div>
            <div class="button-icon splitbutton-drop-icon">
              <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
            </div>
          </div>
        </button>
        <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
          <div data-cy="splitbutton-options">
            <div mat-menu-item class="splitbutton-item-container">
              <button mat-button
                      data-cy="splitbutton-sub-item"
                      class="datex-button splitbutton-sub-item" 
                      [disabled]="toolbar.manage.control.buttons.report_configurations.readOnly"
                      [ngStyle]="toolbar.manage.control.buttons.report_configurations.styles.style"
                      [ngClass]="toolbar.manage.control.buttons.report_configurations.styles.classes"
                      (click)="on_manage_reports_clicked($event)">
                <div class="button-label">
                  <div *ngIf="toolbar.manage.control.buttons.report_configurations.icon"
                      class="button-icon">
                    <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.manage.control.buttons.report_configurations.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-sub-item-label"
                      class="button-text">{{toolbar.manage.control.buttons.report_configurations.label}}</div>
                </div>
              </button>
            </div>
            <div mat-menu-item class="splitbutton-item-container">
              <button mat-button
                      data-cy="splitbutton-sub-item"
                      class="datex-button splitbutton-sub-item" 
                      [disabled]="toolbar.manage.control.buttons.manage_configurations.readOnly"
                      [ngStyle]="toolbar.manage.control.buttons.manage_configurations.styles.style"
                      [ngClass]="toolbar.manage.control.buttons.manage_configurations.styles.classes"
                      (click)="on_manage_configurations_clicked($event)">
                <div class="button-label">
                  <div *ngIf="toolbar.manage.control.buttons.manage_configurations.icon"
                      class="button-icon">
                    <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.manage.control.buttons.manage_configurations.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-sub-item-label"
                      class="button-text">{{toolbar.manage.control.buttons.manage_configurations.label}}</div>
                </div>
              </button>
            </div>
            <div mat-menu-item class="splitbutton-item-container">
              <button mat-button
                      data-cy="splitbutton-sub-item"
                      class="datex-button splitbutton-sub-item" 
                      [disabled]="toolbar.manage.control.buttons.manage_logs.readOnly"
                      [ngStyle]="toolbar.manage.control.buttons.manage_logs.styles.style"
                      [ngClass]="toolbar.manage.control.buttons.manage_logs.styles.classes"
                      (click)="on_manage_logs_clicked($event)">
                <div class="button-label">
                  <div *ngIf="toolbar.manage.control.buttons.manage_logs.icon"
                      class="button-icon">
                    <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.manage.control.buttons.manage_logs.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-sub-item-label"
                      class="button-text">{{toolbar.manage.control.buttons.manage_logs.label}}</div>
                </div>
              </button>
            </div>
          </div>
        </mat-menu>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Notifications-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Notifications-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Notifications-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Notifications-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-context" *ngIf="!filters.context.hidden" 
                                  class="field-container standard {{filters.context.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.context.styles.style"
                                  [ngClass]="filters.context.styles.classes">
                              <div class="label-container"
                                    title="{{filters.context.label}}{{filters.context.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.context.label}}">{{filters.context.label}}<span *ngIf="filters.context.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Notifications-auto_email_rule_contexts_dd_single 
                                  data-cy="selector"
                                  [type]="filters.context.control.type"
                                  formControlName="context"
                                  (displayTextChange)="filters.context.control.displayText=$event"
                                  [placeholder]="filters.context.control.placeholder"
                                  [styles]="filters.context.control.styles"
                              >
                              </Notifications-auto_email_rule_contexts_dd_single>
                              <ng-container *ngIf="filters.context.invalid">
                                <ng-container *ngFor="let error of filters.context.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.auto_email_rules.hidden" class="tab" data-cy="tab-auto_email_rules">
              <h2 [className]="tabs.auto_email_rules.active? 'active': ''" (click)="tabs.auto_email_rules.activate()">{{tabs.auto_email_rules.title}}</h2>
            </div>
            <div *ngIf="!tabs.requests.hidden" class="tab" data-cy="tab-requests">
              <h2 [className]="tabs.requests.active? 'active': ''" (click)="tabs.requests.activate()">{{tabs.requests.title}}</h2>
            </div>
          </div>
        
              <Notifications-auto_email_rules_grid *ngIf="tabs.auto_email_rules.active"
              #$tabs_auto_email_rules
              [projectId]="$tabs_auto_email_rules_auto_email_rules_grid_inParams_projectId"
              [context]="$tabs_auto_email_rules_auto_email_rules_grid_inParams_context"
              ($refreshEvent)="refresh(false, false, '$tabs_auto_email_rules')">
              </Notifications-auto_email_rules_grid>
              <Notifications-email_requests_grid *ngIf="tabs.requests.active"
              #$tabs_requests
              [projectId]="$tabs_requests_email_requests_grid_inParams_projectId"
              [context]="$tabs_requests_email_requests_grid_inParams_context"
              ($refreshEvent)="refresh(false, false, '$tabs_requests')">
              </Notifications-email_requests_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>