<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-discard" *ngIf="!toolbar.discard.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.discard.control.readOnly"
              [ngStyle]="toolbar.discard.control.styles.style"
              [ngClass]="toolbar.discard.control.styles.classes"
              (click)="on_discard_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.discard.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.discard.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.discard.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_class.label}}{{fields.order_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_class.label}}">{{fields.order_class.label}}<span *ngIf="fields.order_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <TransloadOrders-orderclasses_dd_single 
                            data-cy="selector"
                            [type]="fields.order_class.control.type"
                            formControlName="order_class"
                            (displayTextChange)="fields.order_class.control.displayText=$event"
                            [placeholder]="fields.order_class.control.placeholder"
                            [styles]="fields.order_class.control.styles"
                        >
                        </TransloadOrders-orderclasses_dd_single>
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>