import { Inject, Injectable, Injector } from '@angular/core';

import { Document360_FlowService } from './Document360.flow.index';
import { Usersnap_FlowService } from './Usersnap.flow.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Addresses_FlowService } from './Addresses.flow.index';
import { Carriers_FlowService } from './Carriers.flow.index';
import { Locations_FlowService } from './Locations.flow.index';
import { Lots_FlowService } from './Lots.flow.index';
import { Manifesting_FlowService } from './Manifesting.flow.index';
import { Owners_FlowService } from './Owners.flow.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { DockAppointments_FlowService } from './DockAppointments.flow.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { SalesOrders_FlowService } from './SalesOrders.flow.index';
import { Materials_FlowService } from './Materials.flow.index';
import { PurchaseOrders_FlowService } from './PurchaseOrders.flow.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { Reports_FlowService } from './Reports.flow.index';

import { app_custom_complete_sales_order_flowService } from './app.flow.index';
import { app_custom_copy_material_udf_flowService } from './app.flow.index';
import { app_custom_excel_export_flowService } from './app.flow.index';
import { app_custom_export_duty_excel_flowService } from './app.flow.index';
import { app_custom_get_buyer_addressService } from './app.flow.index';
import { app_custom_get_customs_items_contentService } from './app.flow.index';
import { app_custom_get_packinglist_contentService } from './app.flow.index';
import { app_custom_get_ship_from_address_flowService } from './app.flow.index';
import { app_custom_get_third_party_addressService } from './app.flow.index';
import { app_custom_is_pallet_transaction_neededService } from './app.flow.index';
import { app_custom_is_sales_order_completable_flowService } from './app.flow.index';
import { app_custom_substitution_sales_order_flowService } from './app.flow.index';
import { app_set_sales_order_procedure_codeService } from './app.flow.index';

import { $types } from './app.types'

@Injectable({ providedIn: 'root' })
export class app_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Document360 = this.injector.get(Document360_FlowService);
    this.Usersnap = this.injector.get(Usersnap_FlowService);
    this.Utilities = this.injector.get(Utilities_FlowService);
    this.Addresses = this.injector.get(Addresses_FlowService);
    this.Carriers = this.injector.get(Carriers_FlowService);
    this.Locations = this.injector.get(Locations_FlowService);
    this.Lots = this.injector.get(Lots_FlowService);
    this.Manifesting = this.injector.get(Manifesting_FlowService);
    this.Owners = this.injector.get(Owners_FlowService);
    this.Notifications = this.injector.get(Notifications_FlowService);
    this.DockAppointments = this.injector.get(DockAppointments_FlowService);
    this.Inventory = this.injector.get(Inventory_FlowService);
    this.SalesOrders = this.injector.get(SalesOrders_FlowService);
    this.Materials = this.injector.get(Materials_FlowService);
    this.PurchaseOrders = this.injector.get(PurchaseOrders_FlowService);
    this.FootPrintApiManager = this.injector.get(FootPrintApiManager_FlowService);
    this.FootPrintManager = this.injector.get(FootPrintManager_FlowService);
    this.Reports = this.injector.get(Reports_FlowService);
  }

    public Document360: Document360_FlowService;
    public Usersnap: Usersnap_FlowService;
    public Utilities: Utilities_FlowService;
    public Addresses: Addresses_FlowService;
    public Carriers: Carriers_FlowService;
    public Locations: Locations_FlowService;
    public Lots: Lots_FlowService;
    public Manifesting: Manifesting_FlowService;
    public Owners: Owners_FlowService;
    public Notifications: Notifications_FlowService;
    public DockAppointments: DockAppointments_FlowService;
    public Inventory: Inventory_FlowService;
    public SalesOrders: SalesOrders_FlowService;
    public Materials: Materials_FlowService;
    public PurchaseOrders: PurchaseOrders_FlowService;
    public FootPrintApiManager: FootPrintApiManager_FlowService;
    public FootPrintManager: FootPrintManager_FlowService;
    public Reports: Reports_FlowService;
  public app: app_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _custom_complete_sales_order_flow: app_custom_complete_sales_order_flowService;
  public async custom_complete_sales_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._custom_complete_sales_order_flow) {
      this._custom_complete_sales_order_flow = this.injector.get(app_custom_complete_sales_order_flowService);
    }
    return this._custom_complete_sales_order_flow.run(inParams);
  }
  private _custom_copy_material_udf_flow: app_custom_copy_material_udf_flowService;
  public async custom_copy_material_udf_flow(inParams: { sourceMaterialId?: number, targetMaterialId?: number }): Promise< { reason?: string }> {
    if(!this._custom_copy_material_udf_flow) {
      this._custom_copy_material_udf_flow = this.injector.get(app_custom_copy_material_udf_flowService);
    }
    return this._custom_copy_material_udf_flow.run(inParams);
  }
  private _custom_excel_export_flow: app_custom_excel_export_flowService;
  public async custom_excel_export_flow(inParams: { ownerId?: number, projectId?: number, warehouseIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string, carrierId?: number, carrierServiceTypeId?: number, materialIds?: number[], fullTextSearch?: string, reference?: string, orderIds?: number[], exportLimit?: number, exportType?: string, dateType?: string, dateFormat?: string, countries?: string[], waveId?: number, ship_to_search?: string }): Promise< { transactions?: any, logMessage?: string[], debugging?: any[] }> {
    if(!this._custom_excel_export_flow) {
      this._custom_excel_export_flow = this.injector.get(app_custom_excel_export_flowService);
    }
    return this._custom_excel_export_flow.run(inParams);
  }
  private _custom_export_duty_excel_flow: app_custom_export_duty_excel_flowService;
  public async custom_export_duty_excel_flow(inParams: {  }): Promise<void> {
    if(!this._custom_export_duty_excel_flow) {
      this._custom_export_duty_excel_flow = this.injector.get(app_custom_export_duty_excel_flowService);
    }
    return this._custom_export_duty_excel_flow.run(inParams);
  }
  private _custom_get_buyer_address: app_custom_get_buyer_addressService;
  public async custom_get_buyer_address(inParams: { orderId: number, shipmentId: number }): Promise< { address?: { line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, name?: string, eori?: string, vat?: string } }> {
    if(!this._custom_get_buyer_address) {
      this._custom_get_buyer_address = this.injector.get(app_custom_get_buyer_addressService);
    }
    return this._custom_get_buyer_address.run(inParams);
  }
  private _custom_get_customs_items_content: app_custom_get_customs_items_contentService;
  public async custom_get_customs_items_content(inParams: { shipmentId: number, orderId: number }): Promise< { error?: string, content?: any[] }> {
    if(!this._custom_get_customs_items_content) {
      this._custom_get_customs_items_content = this.injector.get(app_custom_get_customs_items_contentService);
    }
    return this._custom_get_customs_items_content.run(inParams);
  }
  private _custom_get_packinglist_content: app_custom_get_packinglist_contentService;
  public async custom_get_packinglist_content(inParams: { fileType: string, dataType: string, parameters: any }): Promise< { error?: string, contentString?: string }> {
    if(!this._custom_get_packinglist_content) {
      this._custom_get_packinglist_content = this.injector.get(app_custom_get_packinglist_contentService);
    }
    return this._custom_get_packinglist_content.run(inParams);
  }
  private _custom_get_ship_from_address_flow: app_custom_get_ship_from_address_flowService;
  public async custom_get_ship_from_address_flow(inParams: { orderId?: number, shipmentId?: number }): Promise< { address?: { name?: string, line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, eori?: string, vat?: string } }> {
    if(!this._custom_get_ship_from_address_flow) {
      this._custom_get_ship_from_address_flow = this.injector.get(app_custom_get_ship_from_address_flowService);
    }
    return this._custom_get_ship_from_address_flow.run(inParams);
  }
  private _custom_get_third_party_address: app_custom_get_third_party_addressService;
  public async custom_get_third_party_address(inParams: { orderId?: number, shipmentId?: number }): Promise< { address?: { name?: string, line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, vat?: string, eori?: string } }> {
    if(!this._custom_get_third_party_address) {
      this._custom_get_third_party_address = this.injector.get(app_custom_get_third_party_addressService);
    }
    return this._custom_get_third_party_address.run(inParams);
  }
  private _custom_is_pallet_transaction_needed: app_custom_is_pallet_transaction_neededService;
  public async custom_is_pallet_transaction_needed(inParams: { order_entity?: any, shipment_entity?: any, project_entity?: any }): Promise< { needed?: boolean }> {
    if(!this._custom_is_pallet_transaction_needed) {
      this._custom_is_pallet_transaction_needed = this.injector.get(app_custom_is_pallet_transaction_neededService);
    }
    return this._custom_is_pallet_transaction_needed.run(inParams);
  }
  private _custom_is_sales_order_completable_flow: app_custom_is_sales_order_completable_flowService;
  public async custom_is_sales_order_completable_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._custom_is_sales_order_completable_flow) {
      this._custom_is_sales_order_completable_flow = this.injector.get(app_custom_is_sales_order_completable_flowService);
    }
    return this._custom_is_sales_order_completable_flow.run(inParams);
  }
  private _custom_substitution_sales_order_flow: app_custom_substitution_sales_order_flowService;
  public async custom_substitution_sales_order_flow(inParams: { orderId: number }): Promise< { reason?: string }> {
    if(!this._custom_substitution_sales_order_flow) {
      this._custom_substitution_sales_order_flow = this.injector.get(app_custom_substitution_sales_order_flowService);
    }
    return this._custom_substitution_sales_order_flow.run(inParams);
  }
  private _set_sales_order_procedure_code: app_set_sales_order_procedure_codeService;
  public async set_sales_order_procedure_code(inParams: { orderId: number }): Promise< { reasons?: string[] }> {
    if(!this._set_sales_order_procedure_code) {
      this._set_sales_order_procedure_code = this.injector.get(app_set_sales_order_procedure_codeService);
    }
    return this._set_sales_order_procedure_code.run(inParams);
  }
}
