import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PackVerification_DatasourceService } from './PackVerification.datasource.index';

interface IPackVerification_packing_list_reportServiceInParams {
  shippingContainerId: number}

interface IPackVerification_packing_list_reportServiceData {
}

@Injectable({ providedIn: 'root' })
export class PackVerification_packing_list_reportService extends ReportBaseService<IPackVerification_packing_list_reportServiceInParams, IPackVerification_packing_list_reportServiceData> {

  protected reportReferenceName = 'packing_list_report';
  protected appReferenceName = 'PackVerification';

  constructor(
    utils: UtilsService,
    private datasources: PackVerification_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IPackVerification_packing_list_reportServiceInParams) {
  }

  protected async getData(inParams: IPackVerification_packing_list_reportServiceInParams): Promise<IPackVerification_packing_list_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IPackVerification_packing_list_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPackVerification_packing_list_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
