<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-create_inventory_count" *ngIf="!toolbar.create_inventory_count.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_inventory_count.control.readOnly"
                [ngStyle]="toolbar.create_inventory_count.control.styles.style"
                [ngClass]="toolbar.create_inventory_count.control.styles.classes"
                (click)="on_create_inventory_count_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_inventory_count.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_inventory_count.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_inventory_count.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filter"
                     *ngIf="!filtersets.filter.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filter.collapsible }">
                    <div *ngIf="!filtersets.filter.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filter.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filter.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filter.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filter.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filter.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouses.label}}{{filters.warehouses.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouses.label}}">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <InventoryCounts-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                              >
                              </InventoryCounts-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
                                  class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                    title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <InventoryCounts-inventory_count_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                              >
                              </InventoryCounts-inventory_count_statuses_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.inventory_counts_in_progress.hidden" class="widget-container">
            <InventoryCounts-inventory_counts_in_progress_widget
            #$widgets_inventory_counts_in_progress 
              [warehouseIds]="$widgets_inventory_counts_in_progress_inParams_warehouseIds"
            ($refreshEvent)="refresh(false, false, '$widgets_inventory_counts_in_progress')"
            >
            </InventoryCounts-inventory_counts_in_progress_widget>
          </div>
          <div *ngIf="!widgets.accuracy.hidden" class="widget-container">
            <InventoryCounts-count_accuracy_widget
            #$widgets_accuracy 
              [warehouseIds]="$widgets_accuracy_inParams_warehouseIds"
              [statusIds]="$widgets_accuracy_inParams_statusIds"
            ($refreshEvent)="refresh(false, false, '$widgets_accuracy')"
            >
            </InventoryCounts-count_accuracy_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory_counts_grid.hidden" class="tab" data-cy="tab-inventory_counts_grid">
              <h2 [className]="tabs.inventory_counts_grid.active? 'active': ''" (click)="tabs.inventory_counts_grid.activate()">{{tabs.inventory_counts_grid.title}}</h2>
            </div>
            <div *ngIf="!tabs.standalone_count_tasks.hidden" class="tab" data-cy="tab-standalone_count_tasks">
              <h2 [className]="tabs.standalone_count_tasks.active? 'active': ''" (click)="tabs.standalone_count_tasks.activate()">{{tabs.standalone_count_tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_counts_grid *ngIf="tabs.inventory_counts_grid.active"
              #$tabs_inventory_counts_grid
              [warehouseIds]="$tabs_inventory_counts_grid_inventory_counts_grid_inParams_warehouseIds"
              [statusIds]="$tabs_inventory_counts_grid_inventory_counts_grid_inParams_statusIds"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory_counts_grid')">
              </FootPrintManager-inventory_counts_grid>
              <FootPrintManager-count_tasks_no_filter_grid *ngIf="tabs.standalone_count_tasks.active"
              #$tabs_standalone_count_tasks
              [allowEdit]="$tabs_standalone_count_tasks_count_tasks_no_filter_grid_inParams_allowEdit"
              [statusIds]="$tabs_standalone_count_tasks_count_tasks_no_filter_grid_inParams_statusIds"
              [warehouseIds]="$tabs_standalone_count_tasks_count_tasks_no_filter_grid_inParams_warehouseIds"
              ($refreshEvent)="refresh(false, false, '$tabs_standalone_count_tasks')">
              </FootPrintManager-count_tasks_no_filter_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>