import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService, EModalSize, EToasterType, EToasterPosition } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { $types } from './Notifications.types'

import { Notifications_auto_email_rules_gridComponent } from './Notifications.auto_email_rules_grid.component';
import { Notifications_email_requests_gridComponent } from './Notifications.email_requests_grid.component';
import { Notifications_owners_dd_singleComponent } from './Notifications.owners_dd_single.component'
import { Notifications_projects_dd_singleComponent } from './Notifications.projects_dd_single.component'
import { Notifications_auto_email_rule_contexts_dd_singleComponent } from './Notifications.auto_email_rule_contexts_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_auto_email_rules_gridComponent),
    forwardRef(() => Notifications_email_requests_gridComponent),
    forwardRef(() => Notifications_owners_dd_singleComponent),
    forwardRef(() => Notifications_projects_dd_singleComponent),
    forwardRef(() => Notifications_auto_email_rule_contexts_dd_singleComponent),
  ],
  selector: 'Notifications-auto_email_rules_hub',
  templateUrl: './Notifications.auto_email_rules_hub.component.html'
})
export class Notifications_auto_email_rules_hubComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number } = { projectId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    context: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
      execute_main: new ToolModel(new ButtonModel('execute_main', new ButtonStyles(null, null), false, 'Execute rules', 'icon-ic_fluent_navigation_play_20_regular')
    ),
      manage: new ToolModel(new SplitButtonModel<{ report_configurations: ButtonModel, manage_configurations: ButtonModel, manage_logs: ButtonModel }>(
        'manage',
        new ButtonStyles(null, null),
        false,
        'Manage',
        'icon-ic_fluent_settings_20_regular',
        [
          new ButtonModel('report_configurations', new ButtonStyles(null, null), false, 'Reports', ''),
          new ButtonModel('manage_configurations', new ButtonStyles(null, null), false, 'Configurations', ''),
          new ButtonModel('manage_logs', new ButtonStyles(null, null), false, 'Logs', '')
        ])
    )
  };

  actionbar = {
  };

 filters = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    context: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['context'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Context', false)
,
  };


  filtersets = {
  filters: new FieldsetModel('Filters', false, true, false),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      auto_email_rules: new TabItemModel(
        this.rootTabGroup, 
        'Rules', 
        ),
      requests: new TabItemModel(
        this.rootTabGroup, 
        'Requests', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_auto_email_rules_auto_email_rules_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_auto_email_rules_auto_email_rules_grid_inParams_context(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.context.control.value;
      
      return expr;
    }
  
    get $tabs_requests_email_requests_grid_inParams_projectId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.project.control.value;
      
      return expr;
    }
  
    get $tabs_requests_email_requests_grid_inParams_context(): string {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.filters.context.control.value;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_auto_email_rules', { read: Notifications_auto_email_rules_gridComponent }) $tabs_auto_email_rules: Notifications_auto_email_rules_gridComponent;
      @ViewChild('$tabs_requests', { read: Notifications_email_requests_gridComponent }) $tabs_requests: Notifications_email_requests_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  get $fields_owner_selector_inParams_projectId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.project.control.value;
    
    return expr;
  }



  get $fields_project_selector_inParams_ownerId(): number {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    const expr = $hub.filters.owner.control.value;
    
    return expr;
  }



  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Auto-email rules`;
  }

  get hubDescription(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return `Configure and monitor your automated emails.`;
  }

  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: Notifications_ShellService,
  private datasources: Notifications_DatasourceService,
  private flows: Notifications_FlowService,
  private reports: Notifications_ReportService,
  private localization: Notifications_LocalizationService,
  private operations: Notifications_OperationService,
  ) { 
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.auto_email_rules,
      this.tabs.requests,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }



  initialized = false;

  async $init() {
    this.title = 'Auto-email rules';
    
    const $hub = this;
    const $utils = this.utils;


    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_auto_email_rules') {
      if (!isNil(this.$tabs_auto_email_rules) && !this.tabs.auto_email_rules.hidden) {
        this.$tabs_auto_email_rules.refresh(true, false, null);
      }
    }
    if (childToSkip !== '$tabs_requests') {
      if (!isNil(this.$tabs_requests) && !this.tabs.requests.hidden) {
        this.$tabs_requests.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_execute_rules_clicked(event = null) {
    return this.on_execute_rules_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_execute_rules_clickedInternal(
    $hub: Notifications_auto_email_rules_hubComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  // let result = await $flows.Notifications.main_auto_email_execute_rules_flow({});
  
  // let sendResult = await $flows.Notifications.main_email_requests_send({});
  
  // await $shell.Notifications.openErrorDialog('Rules execution results', null, null, 'Results', result.results.map(r => ({message: r.ruleId,  detail: r.message})));
  
  // $hub.refresh();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: Notifications_auto_email_rules_hubComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $hub.toolbar.execute_main.hidden = true;
  
  if ($utils.isDefined($hub.inParams.projectId)) {
      $hub.filters.project.control.value = $hub.inParams.projectId
  }
  
  // Initialize auto-emailing if not already
  let initialization_result = await $flows.Notifications.initialize_auto_emailing_flow({});
  
  if ($utils.isDefined(initialization_result.reasons)) {
      await $shell.Notifications.openErrorDialog('Failed to initialize auto-emailing', `Error: ${(await $flows.Utilities.grammar_format_string_array_flow({ values: initialization_result.reasons })).formattedValue}`);
  }
  }
  on_manage_reports_clicked(event = null) {
    return this.on_manage_reports_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_reports_clickedInternal(
    $hub: Notifications_auto_email_rules_hubComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $shell.Notifications.openauto_email_report_configurations_gridDialog('flyout', EModalSize.Xlarge);
  }
  on_manage_configurations_clicked(event = null) {
    return this.on_manage_configurations_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_configurations_clickedInternal(
    $hub: Notifications_auto_email_rules_hubComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openconfigurations_gridDialog({ application_name: 'AutoEmail'}, 'flyout', EModalSize.Xlarge);
  
  $hub.on_init();
  }
  on_manage_logs_clicked(event = null) {
    return this.on_manage_logs_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manage_logs_clickedInternal(
    $hub: Notifications_auto_email_rules_hubComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  await $shell.Utilities.openlogs_gridDialog({ application_name: 'AutoEmail'}, 'flyout', EModalSize.Xlarge);
  }
  //#endregion private flows
}
