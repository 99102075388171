<div class="blade-wrapper">
  <div class="blade-header">
    <div          class="blade-tools">
      <div data-cy="tool-id-beautify" *ngIf="!toolbar.beautify.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.beautify.control.readOnly"
              [ngStyle]="toolbar.beautify.control.styles.style"
              [ngClass]="toolbar.beautify.control.styles.classes"
              (click)="on_beautify_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.beautify.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.beautify.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.beautify.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content code-editor">
    <div>
      <ng-container
                    *ngIf="initialized ">
        <ngx-codemirror [(ngModel)]="value"
                        [options]="codeMirrorOptions"
                        [CodeValidator]="mode"></ngx-codemirror>
      </ng-container>

    </div>
  </div>
</div>