import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './ExcelInventoryImport.types'

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_create_serials_inventory_on_new_licenseplate_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { licenseplateLookupcode: string, lotId: number, packagingId: number, reasoncodeId: number, operationcodeId: number, locationId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[] }): Promise<{ reasons?: string[], confirm?: boolean, licenseplateId?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.licenseplateLookupcode)) {
      missingRequiredInParams.push('\'licenseplateLookupcode\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.reasoncodeId)) {
      missingRequiredInParams.push('\'reasoncodeId\'');
    }
    if (isNil(inParams.operationcodeId)) {
      missingRequiredInParams.push('\'operationcodeId\'');
    }
    if (isNil(inParams.locationId)) {
      missingRequiredInParams.push('\'locationId\'');
    }
    if (isNil(inParams.serialNumbers)) {
      missingRequiredInParams.push('\'serialNumbers\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/functions/create_serials_inventory_on_new_licenseplate_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

