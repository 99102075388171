<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.attachments.readOnly"
                [ngStyle]="row.options.attachments.styles.style"
                [ngClass]="row.options.attachments.styles.classes"
                (click)="row.on_attachments_clicked()">
          <span *ngIf="row.options.attachments.label">{{row.options.attachments.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
    <div data-cy="tool-id-update" *ngIf="!topToolbar.update.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.update.control.readOnly"
            [ngStyle]="topToolbar.update.control.styles.style"
            [ngClass]="topToolbar.update.control.styles.classes"
            (click)="on_update_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.update.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.update.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.update.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-on_delete" *ngIf="!topToolbar.on_delete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_delete.control.readOnly"
            [ngStyle]="topToolbar.on_delete.control.styles.style"
            [ngClass]="topToolbar.on_delete.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_delete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_delete.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total_primarypick_location_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total_primarypick_location_count.displayControl.styles.style"
          [ngClass]="row.cells.total_primarypick_location_count.displayControl.styles.classes">{{row.cells.total_primarypick_location_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total_primarypick_location_sum">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total_primarypick_location_sum.displayControl.styles.style"
          [ngClass]="row.cells.total_primarypick_location_sum.displayControl.styles.classes">{{row.cells.total_primarypick_location_sum.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total_non_primarypick_location_sum">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total_non_primarypick_location_sum.displayControl.styles.style"
          [ngClass]="row.cells.total_non_primarypick_location_sum.displayControl.styles.classes">{{row.cells.total_non_primarypick_location_sum.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_controlled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['lot_controlled_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.lot_controlled.displayControl.styles.style"
                  [ngClass]="row.cells.lot_controlled.displayControl.styles.classes">{{row.cells.lot_controlled.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="serial_controlled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['serial_controlled_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.serial_controlled.displayControl.styles.style"
                  [ngClass]="row.cells.serial_controlled.displayControl.styles.classes">{{row.cells.serial_controlled.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_fixed_weight">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_weight_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_weight.displayControl.styles.style"
                  [ngClass]="row.cells.is_fixed_weight.displayControl.styles.classes">{{row.cells.is_fixed_weight.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_fixed_volume">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_volume_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_volume.displayControl.styles.style"
                  [ngClass]="row.cells.is_fixed_volume.displayControl.styles.classes">{{row.cells.is_fixed_volume.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_fixed_dimension">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_fixed_dimension_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_fixed_dimension.displayControl.styles.style"
                  [ngClass]="row.cells.is_fixed_dimension.displayControl.styles.classes">{{row.cells.is_fixed_dimension.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.description.displayControl.styles.style"
          [ngClass]="row.cells.description.displayControl.styles.classes">{{row.cells.description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
