import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { FootPrintManager_orders_by_project_dd_singleComponent } from './FootPrintManager.orders_by_project_dd_single.component'
import { FootPrintManager_pallet_classes_dd_singleComponent } from './FootPrintManager.pallet_classes_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => FootPrintManager_orders_by_project_dd_singleComponent),
    forwardRef(() => FootPrintManager_pallet_classes_dd_singleComponent),
  ],
  selector: 'FootPrintManager-pallet_transaction_creation_form',
  templateUrl: './FootPrintManager.pallet_transaction_creation_form.component.html'
})
export class FootPrintManager_pallet_transaction_creation_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { project_id?: number, shipment_id?: number, pallet_class_id?: number, order_lookup_code?: string, pallets_in?: number, pallets_out?: number, transaction_id?: number, amount?: number } = { project_id: null, shipment_id: null, pallet_class_id: null, order_lookup_code: null, pallets_in: null, pallets_out: null, transaction_id: null, amount: null };
  //#region Inputs
  @Input('project_id') set $inParams_project_id(v: number) {
    this.inParams.project_id = v;
  }
  get $inParams_project_id(): number {
    return this.inParams.project_id;
  }
  @Input('shipment_id') set $inParams_shipment_id(v: number) {
    this.inParams.shipment_id = v;
  }
  get $inParams_shipment_id(): number {
    return this.inParams.shipment_id;
  }
  @Input('pallet_class_id') set $inParams_pallet_class_id(v: number) {
    this.inParams.pallet_class_id = v;
  }
  get $inParams_pallet_class_id(): number {
    return this.inParams.pallet_class_id;
  }
  @Input('order_lookup_code') set $inParams_order_lookup_code(v: string) {
    this.inParams.order_lookup_code = v;
  }
  get $inParams_order_lookup_code(): string {
    return this.inParams.order_lookup_code;
  }
  @Input('pallets_in') set $inParams_pallets_in(v: number) {
    this.inParams.pallets_in = v;
  }
  get $inParams_pallets_in(): number {
    return this.inParams.pallets_in;
  }
  @Input('pallets_out') set $inParams_pallets_out(v: number) {
    this.inParams.pallets_out = v;
  }
  get $inParams_pallets_out(): number {
    return this.inParams.pallets_out;
  }
  @Input('transaction_id') set $inParams_transaction_id(v: number) {
    this.inParams.transaction_id = v;
  }
  get $inParams_transaction_id(): number {
    return this.inParams.transaction_id;
  }
  @Input('amount') set $inParams_amount(v: number) {
    this.inParams.amount = v;
  }
  get $inParams_amount(): number {
    return this.inParams.amount;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { project_id?: number, shipment_id?: number, pallet_class_id?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    order: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pallet_class: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    inbound: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    outbound: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    amount: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    reference: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', false)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', false)
,
    order: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['order'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Order', false)
,
    pallet_class: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['pallet_class'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Pallet class', true)
,
    inbound: new FieldModel(new NumberBoxModel(this.formGroup.controls['inbound'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Pallets in', true)
,
    outbound: new FieldModel(new NumberBoxModel(this.formGroup.controls['outbound'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Pallets out', true)
,
    amount: new FieldModel(new NumberBoxModel(this.formGroup.controls['amount'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Amount', true)
,
    date: new FieldModel(new DateBoxModel(this.formGroup.controls['date'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'Date', false)
,
    reference: new FieldModel(new TextBoxModel(this.formGroup.controls['reference'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Reference', false)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
  };

  fieldsets = {
  pallet_options: new FieldsetModel('Pallet options', true, false, true),
  pallet_transaction: new FieldsetModel('Pallet transaction', true, false, true),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.project_id;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_order_selector_inParams_project_id(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.project_id;
    
    return expr;
  }

  get $fields_pallet_class_selector_inParams_project_id(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.vars.project_id;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    order: this.fields.order.control.valueChanges
    ,
    pallet_class: this.fields.pallet_class.control.valueChanges
    ,
    inbound: this.fields.inbound.control.valueChanges
    ,
    outbound: this.fields.outbound.control.valueChanges
    ,
    amount: this.fields.amount.control.valueChanges
    ,
    date: this.fields.date.control.valueChanges
    ,
    reference: this.fields.reference.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Pallet transaction creation form';
  
    const $form = this;
    const $utils = this.utils;

    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .order
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_order_change();
      });
    this.$formGroupFieldValidationObservables
      .pallet_class
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_pallet_class_change();
      });
    this.$formGroupFieldValidationObservables
      .inbound
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.check_required_fields();
      });
    this.$formGroupFieldValidationObservables
      .outbound
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.check_required_fields();
      });
    this.$formGroupFieldValidationObservables
      .amount
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.check_required_fields();
      });
    this.$formGroupFieldValidationObservables
      .date
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .reference
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_confirm(event = null) {
    return this.on_confirmInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirmInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  
  let Id: number = $form.inParams.transaction_id;
  let Amount: number = $form.fields.amount.control.value;
  
  let ProjectId: number = $form.vars.project_id;
  let ShipmentId: number = $form.vars.shipment_id;
  let PalletClassId: number = $form.vars.pallet_class_id;
  
  let Reference: string = $form.fields.reference.control.value;
  let Notes: string = $form.fields.notes.control.value;
  let InDate: string = $form.fields.date.control.value ?? $utils.date.now();
  
  let InboundOld: number = $form.inParams.pallets_in ?? 0;
  let OutboundOld: number = $form.inParams.pallets_out ?? 0;
  
  let Inbound: number = $form.fields.inbound.control.value ?? 0;
  let Outbound: number = $form.fields.outbound.control.value ?? 0;
  
  if (Inbound < 0) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The "In" value cannot be less than zero! To deduct pallets use the "Out" value!`);
      return;
  }
  
  if (Outbound < 0) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The "Out" value cannot be less than zero! To add pallets use the "In" value!`);
      return;
  }
  
  if (!$utils.isDefined(PalletClassId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `You need to select a pallet class!`);
      return;
  }
  
  let inbound_delta: number = Inbound - InboundOld;
  let outbound_delta: number = Outbound - OutboundOld;
  let pallet_class_update: boolean = false;
  
  //Check if there was a pallet class change
  if (PalletClassId !== $form.inParams.pallet_class_id && (InboundOld > 0 || Outbound > 0)) {
      pallet_class_update = true;
  }
  
  let payload: {
      remove?: boolean,
      id?: number,
      project_id: number,
      shipment_id?: number,
      carrier_id?: number,
      account_id?: number,
      pallet_class_id: number,
      reference?: string,
      notes?: string,
      amount?: number,
      date?: string
  }
  
  payload = {
      project_id: ProjectId,
      pallet_class_id: PalletClassId
  }
  
  if ($utils.isDefined(ShipmentId)) { payload.shipment_id = ShipmentId }
  if ($utils.isDefined(Reference)) { payload.reference = Reference.trim() }
  if ($utils.isDefined(InDate)) { payload.date = InDate.trim() }
  if ($utils.isDefined(Notes)) { payload.notes = Notes.trim() }
  
  try {
  
      if (!$utils.isDefined(Id)) {
  
          if (pallet_class_update) {
              //Since we're updating the pallet class at the aggregate level we need to make four transactions to support the change
  
              //Undo the old transactions
              if (InboundOld > 0) {
                  payload.pallet_class_id = $form.inParams.pallet_class_id
                  payload.amount = -1 * InboundOld;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
              if (OutboundOld > 0) {
                  payload.pallet_class_id = $form.inParams.pallet_class_id
                  payload.amount = OutboundOld;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
  
              //Apply the new transactions
              if (Inbound > 0) {
                  payload.pallet_class_id = PalletClassId
                  payload.amount = Inbound;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
              if (Outbound > 0) {
                  payload.pallet_class_id = PalletClassId
                  payload.amount = -1 * Outbound;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
          } else {
  
              //if inbound delta is a positive number it means we're adding pallets to inventory, else we're deducting
              if (inbound_delta != 0) {
                  payload.amount = inbound_delta;
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
  
              //if outbound delta is a positive number it means we're deducting pallets from inventory, else we're adding
              if (outbound_delta != 0) {
                  payload.amount = -1 * outbound_delta; //It's the opposite of the inbound, hence the -1.
                  let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
                  if (!create_or_update.success) {
                      await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
                  }
              }
          }
      } else {
  
          payload.id = Id;
          payload.amount = Amount ?? 0;
          let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
          if (!create_or_update.success) {
              await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
          }
      }
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; };
      if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } };
      await $shell.PalletTransactions.openErrorDialog(`Failed!`, targetError.message);
  }
  
  $form.close()
  
  
  /*
  
  
  
  
  if (Inbound < 0) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The "In" value cannot be less than zero! To deduct pallets use the "Out" value!`);
      return;
  }
  
  if (Outbound < 0) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `The "Out" value cannot be less than zero! To add pallets use the "In" value!`);
      return;
  }
  
  if (!$utils.isDefined(ProjectId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `You need to select a project!`);
      return;
  }
  
  if (!$utils.isDefined(PalletClassId)) {
      await $shell.PalletTransactions.openErrorDialog(`Error!`, `You need to select a pallet class!`);
      return;
  }
  
  try {
  
      let payload: {
          remove?: boolean,
          id?: number,
          project_id: number,
          shipment_id?: number,
          carrier_id?: number,
          account_id?: number,
          pallet_class_id: number,
          reference?: string,
          notes?: string,
          amount?: number,
          date?: string
      }
  
      payload = {
          project_id: ProjectId,
          pallet_class_id: PalletClassId
      }
  
      if ($utils.isDefined(InDate)) { payload.date = InDate }
      if ($utils.isDefined(Notes)) { payload.notes = Notes.trim() }
      if ($utils.isDefined(ShipmentId)) { payload.shipment_id = ShipmentId }
      if ($utils.isDefined(Reference)) { payload.reference = Reference.trim() }
  
      if (Inbound > 0) {
          payload.amount = Inbound;
          let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
          if (!create_or_update.success) {
              await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
              return;
          }
      }
  
      if (Outbound > 0) {
          payload.amount = -1 * Outbound; //It's the opposite of the inbound, hence the -1.
          let create_or_update = (await $flows.PalletTransactions.pallet_transaction_create_or_update(payload))
          if (!create_or_update.success) {
              await $shell.PalletTransactions.openErrorDialog(`Failed!`, create_or_update.exceptions);
              return;
          }
      }
  } catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError?.error)) { targetError = targetError.error; };
      if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } };
      throw new Error(`${targetError.message} [pallet_transaction_creation_form]`);
  }
  
  
  
  */
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  
  let PalletClassId: number = $form.inParams.pallet_class_id;
  let PalletQuantity: number = $form.inParams.pallets_in ?? $form.inParams.pallets_out ?? $form.inParams.amount;
  
  if ($utils.isAllDefined(PalletClassId, PalletQuantity)) {
      $form.title = `Edit pallet transaction`;
  } else {
      $form.title = `New pallet transaction`;
  }
  
  $form.vars.project_id = $form.inParams.project_id;
  $form.vars.shipment_id = $form.inParams.shipment_id;
  $form.vars.pallet_class_id = $form.inParams.pallet_class_id;
  
  $form.fields.order.hidden = true;
  $form.toolbar.confirm.control.readOnly = true;
  
  if ($utils.isDefined($form.vars.pallet_class_id)) {
      $form.fields.pallet_class.control.value = $form.vars.pallet_class_id;
  }
  
  if ($utils.isDefined($form.vars.project_id) && !$utils.isDefined($form.vars.shipment_id)) {
      $form.fields.project.control.value = $form.vars.project_id;
      $form.on_project_change();
  }
  
  if ($utils.isDefined($form.vars.shipment_id) && $utils.isDefined($form.vars.project_id)) {
      $form.fields.owner.hidden = true;
      $form.fields.project.hidden = true;
      $form.fieldsets.pallet_options.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.pallets_in)) {
      $form.fields.inbound.control.value = $form.inParams.pallets_in;
      $form.fields.amount.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.pallets_out)) {
      $form.fields.outbound.control.value = $form.inParams.pallets_out;
      $form.fields.amount.hidden = true;
  }
  
  if ($utils.isDefined($form.inParams.amount)) {
      $form.fields.amount.control.value = $form.inParams.amount;
      $form.fields.inbound.hidden = true;
      $form.fields.outbound.hidden = true;
  }
  
  await $form.check_required_fields();
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.fields.project.control.value = null;
  $form.fields.order.control.value = null;
  
  await $form.check_required_fields();
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  const projectId = $form.fields.project.control.value;
  
  // Set Owner from Project
  if ($utils.isDefined(projectId) && !$utils.isDefined($form.fields.owner.control.value)) {
      const project = (await $datasources.PalletTransactions.ds_get_project_by_projectId.get({
          projectId: projectId
      })).result;
  
      if ($utils.isDefined(project)) {
          $form.fields.owner.control.value = project[0].OwnerId;
      }
  }
  
  $form.fields.order.control.value = null;
  $form.vars.shipment_id = null;
  
  if ($utils.isDefined(projectId)) {
      $form.vars.project_id = projectId;
      $form.fields.order.hidden = false;
  } else {
      $form.vars.project_id = null;
      $form.fields.order.hidden = true;
  }
  
  await $form.check_required_fields();
  }
  on_order_change(event = null) {
    return this.on_order_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_changeInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  
  let order_id = $form.fields.order.control.value;
  
  if ($utils.isDefined(order_id)) {
  
      let order = (await $datasources.PalletTransactions.ds_get_orders_by_project_dd.get({
          order_id: order_id
      }))?.result;
  
      if ($utils.isDefined(order)) {
  
          $form.vars.shipment_id = order[0].ShipmentOrderLookups[0].ShipmentId;
      }
  } else {
      $form.vars.shipment_id = null;
  }
  
  await $form.check_required_fields();
  }
  on_pallet_class_change(event = null) {
    return this.on_pallet_class_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_pallet_class_changeInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/14/2024
  let pallet_class_id = $form.fields.pallet_class.control.value;
  
  if ($utils.isDefined(pallet_class_id)) {
      $form.vars.pallet_class_id = pallet_class_id;
  } else {
      $form.vars.pallet_class_id = null;
  }
  
  await $form.check_required_fields();
  }
  check_required_fields(event = null) {
    return this.check_required_fieldsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async check_required_fieldsInternal(
    $form: FootPrintManager_pallet_transaction_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/24/2024
  
  let PalletClassId: number = $form.vars.pallet_class_id;
  let PalletEntity: number = $form.vars.shipment_id ?? $form.vars.project_id;
  
  let Inbound: number = $form.fields.inbound.control.value;
  let Outbound: number = $form.fields.outbound.control.value;
  let Amount: number = $form.fields.amount.control.value;
  
  let PalletQuantity: number = Inbound ?? Outbound ?? Amount;
  
  if ($utils.isAllDefined(PalletClassId, PalletEntity, PalletQuantity)) {
      $form.toolbar.confirm.control.readOnly = false;
  } else {
      $form.toolbar.confirm.control.readOnly = true;
  }
  
  if (!$utils.isDefined(Amount)) {
  
      if ($utils.isDefined(Inbound)) {
          $form.fields.outbound.required = false
      } else {
          $form.fields.outbound.required = true
      }
  
      if ($utils.isDefined(Outbound)) {
          $form.fields.inbound.required = false
      } else {
          $form.fields.inbound.required = true
      }
  }
  }
  //#endregion private flows
}
