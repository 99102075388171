import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './FootPrintApiManager.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_footprint_generic_json_order_schemaService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { example?: boolean, map?: boolean }): Promise<{ DatexOrderInfo?: $types.FootPrintApiManager.DatexOrderInfo, Addresses?: $types.FootPrintApiManager.Address[], Shipments?: $types.FootPrintApiManager.Shipment[], Conveyance?: $types.FootPrintApiManager.Conveyance, ShipmentTotals?: $types.FootPrintApiManager.ShipmentTotals, Orders?: $types.FootPrintApiManager.Order[], OrderLines?: $types.FootPrintApiManager.OrderLine[], DockAppointments?: $types.FootPrintApiManager.DockAppointment[], UserDefinedFields?: $types.FootPrintApiManager.UserDefinedField[], ReasonCodes?: $types.FootPrintApiManager.ReasonCode[], Material?: $types.FootPrintApiManager.Material, MaterialPackaging?: $types.FootPrintApiManager.MaterialPackaging, Lot?: $types.FootPrintApiManager.Lot, ShippingContainer?: $types.FootPrintApiManager.ShippingContainer, LicensePlates?: $types.FootPrintApiManager.LicensePlate[], LicensePlateContents?: $types.FootPrintApiManager.LicensePlateContents[], SerialNumbers?: $types.FootPrintApiManager.SerialNumber[], WavelengthConnectorTransmissions?: $types.FootPrintApiManager.WavelengthConnectorTransmission[], PalletTransactions?: $types.FootPrintApiManager.PalletTransaction[], AddressEntityTypes?: string[] }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/functions/footprint_generic_json_order_schema`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

