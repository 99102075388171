<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-process" *ngIf="!toolbar.process.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.process.control.readOnly"
              [ngStyle]="toolbar.process.control.styles.style"
              [ngClass]="toolbar.process.control.styles.classes"
              (click)="on_process_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.process.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.process.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.process.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator2" *ngIf="!toolbar.separator2.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-delete_transfer" *ngIf="!toolbar.delete_transfer.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.delete_transfer.control.readOnly"
              [ngStyle]="toolbar.delete_transfer.control.styles.style"
              [ngClass]="toolbar.delete_transfer.control.styles.classes"
              (click)="on_delete_transfer_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.delete_transfer.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.delete_transfer.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.delete_transfer.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-print" *ngIf="!toolbar.print.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.print.control.readOnly"
              [ngStyle]="toolbar.print.control.styles.style"
              [ngClass]="toolbar.print.control.styles.classes"
              (click)="on_print_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.print.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.print.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.print.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-attachments" *ngIf="!toolbar.attachments.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.attachments.control.readOnly"
              [ngStyle]="toolbar.attachments.control.styles.style"
              [ngClass]="toolbar.attachments.control.styles.classes"
              (click)="on_attachments_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.attachments.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.attachments.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.attachments.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Transfer information</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.status.control.styles.style"
                              [ngClass]="fields.status.control.styles.classes">{{fields.status.control.text }}</div>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_warehouse" *ngIf="!fields.source_warehouse.hidden" 
                            class="field-container standard {{fields.source_warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.source_warehouse.styles.style"
                            [ngClass]="fields.source_warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.source_warehouse.label}}{{fields.source_warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.source_warehouse.label}}">{{fields.source_warehouse.label}}<span *ngIf="fields.source_warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.source_warehouse.control.type"
                            formControlName="source_warehouse"
                            (displayTextChange)="fields.source_warehouse.control.displayText=$event"
                            [placeholder]="fields.source_warehouse.control.placeholder"
                            [styles]="fields.source_warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.source_warehouse.invalid">
                          <ng-container *ngFor="let error of fields.source_warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-target_warehouse" *ngIf="!fields.target_warehouse.hidden" 
                            class="field-container standard {{fields.target_warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.target_warehouse.styles.style"
                            [ngClass]="fields.target_warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.target_warehouse.label}}{{fields.target_warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.target_warehouse.label}}">{{fields.target_warehouse.label}}<span *ngIf="fields.target_warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.target_warehouse.control.type"
                            formControlName="target_warehouse"
                            (displayTextChange)="fields.target_warehouse.control.displayText=$event"
                            [placeholder]="fields.target_warehouse.control.placeholder"
                            [styles]="fields.target_warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.target_warehouse.invalid">
                          <ng-container *ngFor="let error of fields.target_warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-outbound_order" *ngIf="!fields.outbound_order.hidden" 
                            class="field-container standard {{fields.outbound_order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.outbound_order.styles.style"
                            [ngClass]="fields.outbound_order.styles.classes">
                        <div class="label-container"
                              title="{{fields.outbound_order.label}}{{fields.outbound_order.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.outbound_order.label}}">{{fields.outbound_order.label}}<span *ngIf="fields.outbound_order.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.outbound_order.control.readOnly"
                                [ngStyle]="fields.outbound_order.control.styles.style"
                                [ngClass]="fields.outbound_order.control.styles.classes"
                                (click)="on_outbound_order_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.outbound_order.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.outbound_order.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.outbound_order.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.outbound_order.invalid">
                          <ng-container *ngFor="let error of fields.outbound_order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-inbound_order" *ngIf="!fields.inbound_order.hidden" 
                            class="field-container standard {{fields.inbound_order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.inbound_order.styles.style"
                            [ngClass]="fields.inbound_order.styles.classes">
                        <div class="label-container"
                              title="{{fields.inbound_order.label}}{{fields.inbound_order.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.inbound_order.label}}">{{fields.inbound_order.label}}<span *ngIf="fields.inbound_order.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.inbound_order.control.readOnly"
                                [ngStyle]="fields.inbound_order.control.styles.style"
                                [ngClass]="fields.inbound_order.control.styles.classes"
                                (click)="on_inbound_order_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.inbound_order.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.inbound_order.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.inbound_order.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.inbound_order.invalid">
                          <ng-container *ngFor="let error of fields.inbound_order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.transfer_lines.hidden" class="tab" data-cy="tab-transfer_lines">
              <h2 [className]="tabs.transfer_lines.active? 'active': ''" (click)="tabs.transfer_lines.activate()">{{tabs.transfer_lines.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-warehouse_transfer_outbound_order_lines_grid *ngIf="tabs.transfer_lines.active"
              #$tabs_transfer_lines
              [orderId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderId"
              [shipmentId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_shipmentId"
              [projectId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_projectId"
              [orderStatusId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_orderStatusId"
              [warehouseId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseId"
              [warehouseTransferId]="$tabs_transfer_lines_warehouse_transfer_outbound_order_lines_grid_inParams_warehouseTransferId"
              ($refreshEvent)="refresh(false, false, '$tabs_transfer_lines')">
              </FootPrintManager-warehouse_transfer_outbound_order_lines_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>