import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class DockAppointments_ds_dock_appointments_dashboard_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds: number[], typeIds?: number[], timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[], statusIds?: number[], $top?: number, $skip?: number }): Promise<{ result?: { Id?: number, LookupCode?: string, Notes?: string, RequestedDeliveryDate?: string, ProjectId?: number, OwnerReference?: string, OrderStatusId?: number, dock_appointment?: { Id?: number, DockAppointmentId?: number, DockAppointment?: { StatusId?: number, ScheduledArrival?: string, TypeId?: number, AssignedlLocationContainerId?: number, ScheduledLocationContainerId?: number, CheckedInOn?: string, CheckedInOnUtc?: string, ScheduledArrivalUtc?: string, Location?: string } }, OrderClassId?: number, OrderTypeId?: number, ProjectLookup?: string, ProjectName?: string, OwnerLookup?: string, OwnerName?: string, OrderClassName?: string, OrderStatus?: string, OrderClassTypeId?: number, WaveId?: number, PreferredWarehouseId?: number }[], totalCount?: number }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.warehouseIds)) {
      missingRequiredInParams.push('\'warehouseIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_dashboard_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, ownerId?: number, projectId?: number, warehouseIds?: number[], typeIds?: number[], timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[], statusIds?: number[], $keys: number[] }): Promise<{ result?: { Id?: number, LookupCode?: string, Notes?: string, RequestedDeliveryDate?: string, ProjectId?: number, OwnerReference?: string, OrderStatusId?: number, dock_appointment?: { Id?: number, DockAppointmentId?: number, DockAppointment?: { StatusId?: number, ScheduledArrival?: string, TypeId?: number, AssignedlLocationContainerId?: number, ScheduledLocationContainerId?: number, CheckedInOn?: string, CheckedInOnUtc?: string, ScheduledArrivalUtc?: string, Location?: string } }, OrderClassId?: number, OrderTypeId?: number, ProjectLookup?: string, ProjectName?: string, OwnerLookup?: string, OwnerName?: string, OrderClassName?: string, OrderStatus?: string, OrderClassTypeId?: number, WaveId?: number, PreferredWarehouseId?: number }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/DockAppointments/datasources/ds_dock_appointments_dashboard_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
