<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
      <div data-cy="tool-id-ingress" *ngIf="!toolbar.ingress.hidden" class="toolContainer">
      <button mat-button 
              data-cy="splitbutton"
              class="datex-button splitbutton" 
              [disabled]="toolbar.ingress.control.readOnly"
              [ngStyle]="toolbar.ingress.control.styles.style"
              [ngClass]="toolbar.ingress.control.styles.classes"
              [matMenuTriggerFor]="dropdownMenuOne">
        <div class="button-label">
          <div *ngIf="toolbar.ingress.control.icon"
                class="button-icon">
            <i data-cy="splitbutton-icon" class="icon {{toolbar.ingress.control.icon}}"></i>
          </div>
          <div data-cy="splitbutton-label" class="button-text">{{toolbar.ingress.control.label}}</div>
          <div class="button-icon splitbutton-drop-icon">
            <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
          </div>
        </div>
      </button>
      <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
        <div data-cy="splitbutton-options">
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.ingress.control.buttons.order_sample.readOnly"
                    [ngStyle]="toolbar.ingress.control.buttons.order_sample.styles.style"
                    [ngClass]="toolbar.ingress.control.buttons.order_sample.styles.classes"
                    (click)="order_sample($event)">
              <div class="button-label">
                <div *ngIf="toolbar.ingress.control.buttons.order_sample.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.ingress.control.buttons.order_sample.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.ingress.control.buttons.order_sample.label}}</div>
              </div>
            </button>
          </div>
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.ingress.control.buttons.order_import.readOnly"
                    [ngStyle]="toolbar.ingress.control.buttons.order_import.styles.style"
                    [ngClass]="toolbar.ingress.control.buttons.order_import.styles.classes"
                    (click)="import_order($event)">
              <div class="button-label">
                <div *ngIf="toolbar.ingress.control.buttons.order_import.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.ingress.control.buttons.order_import.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.ingress.control.buttons.order_import.label}}</div>
              </div>
            </button>
          </div>
        </div>
      </mat-menu>
      </div>
      <div data-cy="tool-id-egress" *ngIf="!toolbar.egress.hidden" class="toolContainer">
      <button mat-button 
              data-cy="splitbutton"
              class="datex-button splitbutton" 
              [disabled]="toolbar.egress.control.readOnly"
              [ngStyle]="toolbar.egress.control.styles.style"
              [ngClass]="toolbar.egress.control.styles.classes"
              [matMenuTriggerFor]="dropdownMenuOne">
        <div class="button-label">
          <div *ngIf="toolbar.egress.control.icon"
                class="button-icon">
            <i data-cy="splitbutton-icon" class="icon {{toolbar.egress.control.icon}}"></i>
          </div>
          <div data-cy="splitbutton-label" class="button-text">{{toolbar.egress.control.label}}</div>
          <div class="button-icon splitbutton-drop-icon">
            <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
          </div>
        </div>
      </button>
      <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
        <div data-cy="splitbutton-options">
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.egress.control.buttons.order_sample.readOnly"
                    [ngStyle]="toolbar.egress.control.buttons.order_sample.styles.style"
                    [ngClass]="toolbar.egress.control.buttons.order_sample.styles.classes"
                    (click)="order_sample($event)">
              <div class="button-label">
                <div *ngIf="toolbar.egress.control.buttons.order_sample.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.egress.control.buttons.order_sample.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.egress.control.buttons.order_sample.label}}</div>
              </div>
            </button>
          </div>
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.egress.control.buttons.order_export.readOnly"
                    [ngStyle]="toolbar.egress.control.buttons.order_export.styles.style"
                    [ngClass]="toolbar.egress.control.buttons.order_export.styles.classes"
                    (click)="order_export($event)">
              <div class="button-label">
                <div *ngIf="toolbar.egress.control.buttons.order_export.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.egress.control.buttons.order_export.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.egress.control.buttons.order_export.label}}</div>
              </div>
            </button>
          </div>
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.egress.control.buttons.inventory_export.readOnly"
                    [ngStyle]="toolbar.egress.control.buttons.inventory_export.styles.style"
                    [ngClass]="toolbar.egress.control.buttons.inventory_export.styles.classes"
                    (click)="inventory_export($event)">
              <div class="button-label">
                <div *ngIf="toolbar.egress.control.buttons.inventory_export.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.egress.control.buttons.inventory_export.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.egress.control.buttons.inventory_export.label}}</div>
              </div>
            </button>
          </div>
        </div>
      </mat-menu>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-settings"
                     *ngIf="!fieldsets.settings.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.settings.collapsible }">
                    <div *ngIf="!fieldsets.settings.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.settings.toggle()">
                      <span class="fieldsetsTitle-text">Settings</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.settings.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.settings.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.settings.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.settings.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="project"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.project.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.project.control.placeholder}}"
                                [ngStyle]="fields.project.control.styles.style"
                                [ngClass]="fields.project.control.styles.classes"> 
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="warehouse"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.warehouse.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.warehouse.control.placeholder}}"
                                [ngStyle]="fields.warehouse.control.styles.style"
                                [ngClass]="fields.warehouse.control.styles.classes"> 
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_class" *ngIf="!fields.order_class.hidden" 
                            class="field-container standard {{fields.order_class.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_class.styles.style"
                            [ngClass]="fields.order_class.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_class.label}}{{fields.order_class.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_class.label}}">{{fields.order_class.label}}<span *ngIf="fields.order_class.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="order_class"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.order_class.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_class.control.placeholder}}"
                                [ngStyle]="fields.order_class.control.styles.style"
                                [ngClass]="fields.order_class.control.styles.classes"> 
                        <ng-container *ngIf="fields.order_class.invalid">
                          <ng-container *ngFor="let error of fields.order_class.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.material.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.packaging.label}}{{fields.packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.packaging.label}}">{{fields.packaging.label}}<span *ngIf="fields.packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="packaging"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.packaging.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.packaging.control.placeholder}}"
                                [ngStyle]="fields.packaging.control.styles.style"
                                [ngClass]="fields.packaging.control.styles.classes"> 
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-shipment_id" *ngIf="!fields.shipment_id.hidden" 
                            class="field-container standard {{fields.shipment_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.shipment_id.styles.style"
                            [ngClass]="fields.shipment_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.shipment_id.label}}{{fields.shipment_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.shipment_id.label}}">{{fields.shipment_id.label}}<span *ngIf="fields.shipment_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="shipment_id"
                                matInput
                                numberBox
                                [format]="fields.shipment_id.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.shipment_id.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.shipment_id.control.placeholder}}"
                                [ngStyle]="fields.shipment_id.control.styles.style"
                                [ngClass]="fields.shipment_id.control.styles.classes">
                        <ng-container *ngIf="fields.shipment_id.invalid">
                          <ng-container *ngFor="let error of fields.shipment_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_lookup" *ngIf="!fields.order_lookup.hidden" 
                            class="field-container standard {{fields.order_lookup.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_lookup.styles.style"
                            [ngClass]="fields.order_lookup.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_lookup.label}}{{fields.order_lookup.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_lookup.label}}">{{fields.order_lookup.label}}<span *ngIf="fields.order_lookup.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="order_lookup"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.order_lookup.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.order_lookup.control.placeholder}}"
                                [ngStyle]="fields.order_lookup.control.styles.style"
                                [ngClass]="fields.order_lookup.control.styles.classes"> 
                        <ng-container *ngIf="fields.order_lookup.invalid">
                          <ng-container *ngFor="let error of fields.order_lookup.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-render_lots" *ngIf="!fields.render_lots.hidden" 
                            class="field-container standard {{fields.render_lots.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.render_lots.styles.style"
                            [ngClass]="fields.render_lots.styles.classes">
                        <div class="label-container"
                              title="{{fields.render_lots.label}}{{fields.render_lots.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.render_lots.label}}">{{fields.render_lots.label}}<span *ngIf="fields.render_lots.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="render_lots"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.render_lots.control.styles.style"
                                      [ngClass]="fields.render_lots.control.styles.classes">{{fields.render_lots.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.render_lots.invalid">
                          <ng-container *ngFor="let error of fields.render_lots.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-render_license_plates" *ngIf="!fields.render_license_plates.hidden" 
                            class="field-container standard {{fields.render_license_plates.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.render_license_plates.styles.style"
                            [ngClass]="fields.render_license_plates.styles.classes">
                        <div class="label-container"
                              title="{{fields.render_license_plates.label}}{{fields.render_license_plates.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.render_license_plates.label}}">{{fields.render_license_plates.label}}<span *ngIf="fields.render_license_plates.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="render_license_plates"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.render_license_plates.control.styles.style"
                                      [ngClass]="fields.render_license_plates.control.styles.classes">{{fields.render_license_plates.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.render_license_plates.invalid">
                          <ng-container *ngFor="let error of fields.render_license_plates.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-export_to_file" *ngIf="!fields.export_to_file.hidden" 
                            class="field-container standard {{fields.export_to_file.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.export_to_file.styles.style"
                            [ngClass]="fields.export_to_file.styles.classes">
                        <div class="label-container"
                              title="{{fields.export_to_file.label}}{{fields.export_to_file.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.export_to_file.label}}">{{fields.export_to_file.label}}<span *ngIf="fields.export_to_file.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="export_to_file"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.export_to_file.control.styles.style"
                                      [ngClass]="fields.export_to_file.control.styles.classes">{{fields.export_to_file.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.export_to_file.invalid">
                          <ng-container *ngFor="let error of fields.export_to_file.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-async" *ngIf="!fields.async.hidden" 
                            class="field-container standard {{fields.async.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.async.styles.style"
                            [ngClass]="fields.async.styles.classes">
                        <div class="label-container"
                              title="{{fields.async.label}}{{fields.async.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.async.label}}">{{fields.async.label}}<span *ngIf="fields.async.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="async"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.async.control.styles.style"
                                      [ngClass]="fields.async.control.styles.classes">{{fields.async.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.async.invalid">
                          <ng-container *ngFor="let error of fields.async.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-payload"
                     *ngIf="!fieldsets.payload.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.payload.collapsible }">
                    <div *ngIf="!fieldsets.payload.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.payload.toggle()">
                      <span class="fieldsetsTitle-text">Payload</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.payload.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.payload.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.payload.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.payload.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-code_box" *ngIf="!fields.code_box.hidden" 
                            class="field-container full {{fields.code_box.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.code_box.styles.style"
                            [ngClass]="fields.code_box.styles.classes">
                        <div class="label-container"
                              title="{{fields.code_box.label}}{{fields.code_box.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.code_box.label}}">{{fields.code_box.label}}<span *ngIf="fields.code_box.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <ngx-codemirror
                                    formControlName="code_box"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.code_box.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.code_box.control.mode"
                                    >
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.code_box.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.code_box.invalid">
                          <ng-container *ngFor="let error of fields.code_box.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-messages"
                     *ngIf="!fieldsets.messages.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.messages.collapsible }">
                    <div *ngIf="!fieldsets.messages.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.messages.toggle()">
                      <span class="fieldsetsTitle-text">Messages</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.messages.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.messages.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.messages.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.messages.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-details_box" *ngIf="!fields.details_box.hidden" 
                            class="field-container full {{fields.details_box.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.details_box.styles.style"
                            [ngClass]="fields.details_box.styles.classes">
                        <div class="label-container"
                              title="{{fields.details_box.label}}{{fields.details_box.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.details_box.label}}">{{fields.details_box.label}}<span *ngIf="fields.details_box.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <ngx-codemirror
                                    formControlName="details_box"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.details_box.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.details_box.control.mode"
                                    >
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.details_box.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.details_box.invalid">
                          <ng-container *ngFor="let error of fields.details_box.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>