import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_ReportService } from './Utilities.report.index';

import { Invoices_invoice_reportService } from './Invoices.report.index';
import { Invoices_invoice_report_non_expandedService } from './Invoices.report.index';

@Injectable({ providedIn: 'root' })
export class Invoices_ReportService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_ReportService);
  }
    public Utilities: Utilities_ReportService;

  public Invoices: Invoices_ReportService = this;

  // injecting lazily in order to avoid circular dependencies
  private _invoice_report: Invoices_invoice_reportService;
  public get invoice_report(): Invoices_invoice_reportService {
    if(!this._invoice_report) {
      this._invoice_report = this.injector.get(Invoices_invoice_reportService);
    }
    return this._invoice_report;
  }
  private _invoice_report_non_expanded: Invoices_invoice_report_non_expandedService;
  public get invoice_report_non_expanded(): Invoices_invoice_report_non_expandedService {
    if(!this._invoice_report_non_expanded) {
      this._invoice_report_non_expanded = this.injector.get(Invoices_invoice_report_non_expandedService);
    }
    return this._invoice_report_non_expanded;
  }
}

