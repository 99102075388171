import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Owners_owner_project_statuses_dd_singleComponent } from './Owners.owner_project_statuses_dd_single.component'


interface IFootPrintManager_projects_gridComponentEntity {
Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number, Status?: { Label?: string }, Owner?: { LookupCode?: string, Name?: string }, LicenseplateCount?: { TotalLicenseplates?: number, Lot?: { Material?: { ProjectId?: number } } }}

interface IFootPrintManager_projects_gridComponentInParams {
  projectStatusId?: number, ownerId?: number, projectIds?: number[]}


class FootPrintManager_projects_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_projects_gridComponent;
  entity: IFootPrintManager_projects_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    owner_lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    owner_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    licenseplate_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'MM/DD/YYYY')
,
null
      ),
    created_by: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_projects_gridComponent, entity: IFootPrintManager_projects_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_projects_gridComponent, entity?: IFootPrintManager_projects_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      projectStatusId:  $grid.filters.project_status.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectIds:  $grid.inParams.projectIds ,
    };
    const data = await this.datasources.Owners.ds_projects_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.name.displayControl as TextModel).text = $row.entity.Name;
    (this.cells.owner_lookupcode.displayControl as TextModel).text = $row.entity.Owner.LookupCode;
    (this.cells.owner_name.displayControl as TextModel).text = $row.entity.Owner.Name;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Label;
    (this.cells.licenseplate_count.displayControl as TextModel).text = $utils.isDefined($row.entity.LicenseplateCount?.TotalLicenseplates) ? $row.entity.LicenseplateCount?.TotalLicenseplates?.toString(): '0';
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.CreatedSysDateTime?.toString();
    (this.cells.created_by.displayControl as TextModel).text = $row.entity.CreatedSysUser;
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Notes;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'lookupcode' : {
        this.on_lookupcode_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lookupcode_clicked(event = null) {
    return this.on_lookupcode_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lookupcode_clickedInternal(
    $row: FootPrintManager_projects_gridComponentRowModel,
  $grid: FootPrintManager_projects_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  
  const dialogResults = await $shell.FootPrintManager.opensingle_project_hub({ projectId: $row.entity.Id });
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owner_project_statuses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-projects_grid',
  templateUrl: './FootPrintManager.projects_grid.component.html'
})
export class FootPrintManager_projects_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_projects_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_projects_gridComponentInParams = { projectStatusId: null, ownerId: null, projectIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookup', false, false, null),       name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       owner_lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Owner lookup', false, false, null),       owner_name: new GridHeaderModel(new HeaderStyles(null, null), 'Owner name', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       licenseplate_count: new GridHeaderModel(new HeaderStyles(null, null), 'Lp count', false, false, null),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       created_by: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_projects_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('projectStatusId') set $inParams_projectStatusId(value: any) {
    this.inParams['projectStatusId'] = value;
  }
  get $inParams_projectStatusId(): any {
    return this.inParams['projectStatusId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectIds') set $inParams_projectIds(value: any) {
    this.inParams['projectIds'] = value;
  }
  get $inParams_projectIds(): any {
    return this.inParams['projectIds'] ;
  }

  topToolbar = {
      update: new ToolModel(new ButtonModel('update', new ButtonStyles(null, null), false, 'Update', 'ms-Icon ms-Icon--UpdateRestore')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    project_status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    project_status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project_status'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Status', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Projects';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.project_status.control as SelectBoxModel).reset($grid.inParams.projectStatusId);

    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectStatusId:  $grid.filters.project_status.control.value ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectIds:  $grid.inParams.projectIds ,
    };
    try {
    const data = await this.datasources.Owners.ds_projects_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_projects_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_create_project_clicked(event = null) {
    return this.on_create_project_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_project_clickedInternal(
    $grid: FootPrintManager_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openproject_creation_formDialog({ownerId: $grid.inParams.ownerId},'flyout');
  const userConfirmed = dialogResult.confirm;
  
  if (userConfirmed) {
      await $grid.refresh()
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRowsCount = $grid.selectedRows.length;
  
  if (selectedRowsCount > 0) {
      $grid.topToolbar.on_delete.control.readOnly = false;
      $grid.topToolbar.update.control.readOnly = false;
  }
  else {
      $grid.topToolbar.on_delete.control.readOnly = true;
      $grid.topToolbar.update.control.readOnly = true;
  }
  }
  on_delete(event = null) {
    return this.on_deleteInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_deleteInternal(
    $grid: FootPrintManager_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Project Delete Error', 'No projects selected.');
      return;
  } else {
      const candidates = [];
      const failures = [];
      const errors = [];
      for (const row of selectedRows) {
          const flowParams = {
              projectId: row.entity.Id
          };
          const result = await $flows.Owners.is_project_deletable_flow(flowParams);
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              failures.push(row);
              errors.push(`Project ${row.entity.LookupCode} - ${reason}`);
          } else {
              candidates.push(row);
          }
      }
  
      // no candidate
      if (candidates.length === 0) {
          const title = 'Delete project errors';
          const errorMessage = `Project(s) ${failures.map(c => c.entity.LookupCode).join(',')} cannot be deleted`;
          const errorList = errors;
          await $shell.FootPrintManager.openErrorDialog(title, errorMessage, errorList);
          return;
      } else {
          const confirmCandidates = `Project(s) ${candidates.map(c => c.entity.LookupCode).join(',')} - Once deleted, the project(s) cannot be restored.`
  
          let confirm = false;
          if (failures.length >= 1) {
              const title = 'Some project(s) cannot be deleted';
              const message = `Do you still want to continue?\r\n\r\n ${confirmCandidates}\r\n\r\n ${errors.join('\r\n\r\n')}`;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message);
          } else {
              const title = 'Delete the following project(s)';
              const message = confirmCandidates;
              confirm = await $shell.FootPrintManager.openConfirmationDialog(title, message, 'Proceed');
          }
  
          if (confirm) {
              const processSuccess = [];
              const processFailures = [];
              const errorMsgList = [];
              const errorMsgListDetails = [];
  
              for (const candidate of candidates) {
                  try {
                      const flowParams =  {
                      projectId: candidate.entity.Id
                      }
                      const result = await $flows.Owners.delete_project_flow(flowParams);
                    
                    processSuccess.push(candidate);
                  } catch (error) {
                      processFailures.push(candidate);
                      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                      const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                      const errorDescription = `Project ${candidate.entity.LookupCode} - ${errorMessage}`;
                      errorMsgList.push(errorDescription);
                      errorMsgListDetails.push({ message: errorDescription, detail: errorDetail });
                  }
              }
  
              // all succeeded
              if (processSuccess.length === candidates.length) {
                  const title = 'All project(s) deleted';
                  const message = `Project(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} deleted`;
                  await $shell.FootPrintManager.openInfoDialog(title, message);
                  await $grid.refresh();
              } else {
                  // all failures
                  if (processFailures.length === candidates.length) {
                      const title = 'All project(s) failed to delete';
                      const message = `Project(s) ${processFailures.map(c => c.entity.LookupCode).join(',')} could not be deleted`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                  } else {
                      const title = 'Some projects could not be deleted';
                      const deleted = `Project(s) ${processSuccess.map(c => c.entity.LookupCode).join(',')} were deleted.`;
                      const errors = `The following project(s) could not be deleted: ${processFailures.map(c => c.entity.LookupCode).join(',')}`;
                      const message = `${deleted} \r\n\r\n${errors}`;
                      await $shell.FootPrintManager.openErrorDialog(title, message, errorMsgList, null, errorMsgListDetails);
                      await $grid.refresh();
                  }
              }
          }
      }
  }
  }
  on_update(event = null) {
    return this.on_updateInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_updateInternal(
    $grid: FootPrintManager_projects_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const selectedRows = $grid.selectedRows;
  
  if (selectedRows.length === 0) {
      $shell.FootPrintManager.openErrorDialog('Owner Update Error', 'No owners selected.');
      return;
  } else {
  
      const dialogResult = await $shell.FootPrintManager.openproject_update_formDialog();
      const userConfirmed = dialogResult.confirm;
      const projectStatusId = dialogResult.projectStatusId;
  
      let payload: any = {};
  
      if (userConfirmed) {
          for (const row of selectedRows) {
  
              if ($utils.isDefined(projectStatusId)) {
                  payload.StatusId = projectStatusId;
              }
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Projects', id: row.entity.Id, entity: payload });
          }
          await $grid.refresh();
      }
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
