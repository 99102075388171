import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Returns.types'

@Injectable({ providedIn: 'root' })
export class Returns_get_blind_return_lot_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { vendorLotId: number, vendorLotCode: string, materialId: number, lotCode?: string, expirationDate?: string, shippingContainerId?: number }): Promise<{ reasons?: string[], lot?: { id?: number, lookupcode?: string, statusId?: number } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.vendorLotId)) {
      missingRequiredInParams.push('\'vendorLotId\'');
    }
    if (isNil(inParams.vendorLotCode)) {
      missingRequiredInParams.push('\'vendorLotCode\'');
    }
    if (isNil(inParams.materialId)) {
      missingRequiredInParams.push('\'materialId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Returns/functions/get_blind_return_lot_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

