<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-create_lot" *ngIf="!toolbar.create_lot.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_lot.control.readOnly"
                [ngStyle]="toolbar.create_lot.control.styles.style"
                [ngClass]="toolbar.create_lot.control.styles.classes"
                (click)="on_new_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_lot.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_lot.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_lot.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-materials" *ngIf="!filters.materials.hidden" 
                                  class="field-container standard {{filters.materials.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.materials.styles.style"
                                  [ngClass]="filters.materials.styles.classes">
                              <div class="label-container"
                                    title="{{filters.materials.label}}{{filters.materials.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.materials.label}}">{{filters.materials.label}}<span *ngIf="filters.materials.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Materials-materials_by_project_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.materials.control.type"
                                  formControlName="materials"
                                  (displayTextChange)="filters.materials.control.displayText=$event"
                                  [placeholder]="filters.materials.control.placeholder"
                                  [styles]="filters.materials.control.styles"
                                [projectId]="$fields_materials_selector_inParams_projectId"
                              >
                              </Materials-materials_by_project_dd_multi>
                              <ng-container *ngIf="filters.materials.invalid">
                                <ng-container *ngFor="let error of filters.materials.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-statuses" *ngIf="!filters.statuses.hidden" 
                                  class="field-container standard {{filters.statuses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.statuses.styles.style"
                                  [ngClass]="filters.statuses.styles.classes">
                              <div class="label-container"
                                    title="{{filters.statuses.label}}{{filters.statuses.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.statuses.label}}">{{filters.statuses.label}}<span *ngIf="filters.statuses.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Lots-lot_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.statuses.control.type"
                                  formControlName="statuses"
                                  (displayTextChange)="filters.statuses.control.displayText=$event"
                                  [placeholder]="filters.statuses.control.placeholder"
                                  [styles]="filters.statuses.control.styles"
                              >
                              </Lots-lot_statuses_dd_multi>
                              <ng-container *ngIf="filters.statuses.invalid">
                                <ng-container *ngFor="let error of filters.statuses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
          <div class="hub-tools">
            <div data-cy="tool-id-custom_fields" *ngIf="!actionbar.custom_fields.hidden" class="toolContainer">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="actionbar.custom_fields.control.readOnly"
                    [ngStyle]="actionbar.custom_fields.control.styles.style"
                    [ngClass]="actionbar.custom_fields.control.styles.classes"
                    (click)="on_custom_fields_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="actionbar.custom_fields.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{actionbar.custom_fields.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{actionbar.custom_fields.control.label}}</div>
              </div>
            
            </button>
            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.lots.hidden" class="tab" data-cy="tab-lots">
              <h2 [className]="tabs.lots.active? 'active': ''" (click)="tabs.lots.activate()">{{tabs.lots.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-lots_grid_flat *ngIf="tabs.lots.active"
              #$tabs_lots
              [ownerIds]="$tabs_lots_lots_grid_flat_inParams_ownerIds"
              [projectIds]="$tabs_lots_lots_grid_flat_inParams_projectIds"
              [materialIds]="$tabs_lots_lots_grid_flat_inParams_materialIds"
              [statusIds]="$tabs_lots_lots_grid_flat_inParams_statusIds"
              ($refreshEvent)="refresh(false, false, '$tabs_lots')">
              </FootPrintManager-lots_grid_flat>
        </div>

      </ng-container>
    </div>
  </div>
</div>