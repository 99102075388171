<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-confirm_and_close" *ngIf="!toolbar.confirm_and_close.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm_and_close.control.readOnly"
              [ngStyle]="toolbar.confirm_and_close.control.styles.style"
              [ngClass]="toolbar.confirm_and_close.control.styles.classes"
              (click)="on_confirm_and_close($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm_and_close.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm_and_close.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm_and_close.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-confirm_and_new" *ngIf="!toolbar.confirm_and_new.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm_and_new.control.readOnly"
              [ngStyle]="toolbar.confirm_and_new.control.styles.style"
              [ngClass]="toolbar.confirm_and_new.control.styles.classes"
              (click)="on_confirm_and_new($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm_and_new.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm_and_new.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm_and_new.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                          [projectId]="$fields_owner_selector_inParams_projectId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-materials_by_project_dd_single 
                            data-cy="selector"
                            [type]="fields.material.control.type"
                            formControlName="material"
                            (displayTextChange)="fields.material.control.displayText=$event"
                            [placeholder]="fields.material.control.placeholder"
                            [styles]="fields.material.control.styles"
                          [projectId]="$fields_material_selector_inParams_projectId"
                        >
                        </Materials-materials_by_project_dd_single>
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-packaging" *ngIf="!fields.packaging.hidden" 
                            class="field-container standard {{fields.packaging.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.packaging.styles.style"
                            [ngClass]="fields.packaging.styles.classes">
                        <div class="label-container"
                              title="{{fields.packaging.label}}{{fields.packaging.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.packaging.label}}">{{fields.packaging.label}}<span *ngIf="fields.packaging.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.packaging.control.type"
                            formControlName="packaging"
                            (displayTextChange)="fields.packaging.control.displayText=$event"
                            [placeholder]="fields.packaging.control.placeholder"
                            [styles]="fields.packaging.control.styles"
                          [materialId]="$fields_packaging_selector_inParams_materialId"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.packaging.invalid">
                          <ng-container *ngFor="let error of fields.packaging.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-quantity" *ngIf="!fields.quantity.hidden" 
                            class="field-container standard {{fields.quantity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.quantity.styles.style"
                            [ngClass]="fields.quantity.styles.classes">
                        <div class="label-container"
                              title="{{fields.quantity.label}}{{fields.quantity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.quantity.label}}">{{fields.quantity.label}}<span *ngIf="fields.quantity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="quantity"
                                matInput
                                numberBox
                                [format]="fields.quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.quantity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.quantity.control.placeholder}}"
                                [ngStyle]="fields.quantity.control.styles.style"
                                [ngClass]="fields.quantity.control.styles.classes">
                        <ng-container *ngIf="fields.quantity.invalid">
                          <ng-container *ngFor="let error of fields.quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-location" *ngIf="!fields.location.hidden" 
                            class="field-container standard {{fields.location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.location.styles.style"
                            [ngClass]="fields.location.styles.classes">
                        <div class="label-container"
                              title="{{fields.location.label}}{{fields.location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.location.label}}">{{fields.location.label}}<span *ngIf="fields.location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.location.control.type"
                            formControlName="location"
                            (displayTextChange)="fields.location.control.displayText=$event"
                            [placeholder]="fields.location.control.placeholder"
                            [styles]="fields.location.control.styles"
                          [warehouseId]="$fields_location_selector_inParams_warehouseId"
                          [typeId]="$fields_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.location.invalid">
                          <ng-container *ngFor="let error of fields.location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-new_licenseplate_checkbox" *ngIf="!fields.new_licenseplate_checkbox.hidden" 
                            class="field-container standard {{fields.new_licenseplate_checkbox.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.new_licenseplate_checkbox.styles.style"
                            [ngClass]="fields.new_licenseplate_checkbox.styles.classes">
                        <div class="label-container"
                              title="{{fields.new_licenseplate_checkbox.label}}{{fields.new_licenseplate_checkbox.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.new_licenseplate_checkbox.label}}">{{fields.new_licenseplate_checkbox.label}}<span *ngIf="fields.new_licenseplate_checkbox.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="new_licenseplate_checkbox"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.new_licenseplate_checkbox.control.styles.style"
                                      [ngClass]="fields.new_licenseplate_checkbox.control.styles.classes">{{fields.new_licenseplate_checkbox.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.new_licenseplate_checkbox.invalid">
                          <ng-container *ngFor="let error of fields.new_licenseplate_checkbox.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-licenseplate" *ngIf="!fields.licenseplate.hidden" 
                            class="field-container standard {{fields.licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.licenseplate.styles.style"
                            [ngClass]="fields.licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.licenseplate.label}}{{fields.licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.licenseplate.label}}">{{fields.licenseplate.label}}<span *ngIf="fields.licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-licenseplates_dd_single 
                            data-cy="selector"
                            [type]="fields.licenseplate.control.type"
                            formControlName="licenseplate"
                            (displayTextChange)="fields.licenseplate.control.displayText=$event"
                            [placeholder]="fields.licenseplate.control.placeholder"
                            [styles]="fields.licenseplate.control.styles"
                          [locationId]="$fields_licenseplate_selector_inParams_locationId"
                          [typeId]="$fields_licenseplate_selector_inParams_typeId"
                          [archived]="$fields_licenseplate_selector_inParams_archived"
                        >
                        </Inventory-licenseplates_dd_single>
                        <ng-container *ngIf="fields.licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-new_licenseplate" *ngIf="!fields.new_licenseplate.hidden" 
                            class="field-container standard {{fields.new_licenseplate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.new_licenseplate.styles.style"
                            [ngClass]="fields.new_licenseplate.styles.classes">
                        <div class="label-container"
                              title="{{fields.new_licenseplate.label}}{{fields.new_licenseplate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.new_licenseplate.label}}">{{fields.new_licenseplate.label}}<span *ngIf="fields.new_licenseplate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="new_licenseplate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.new_licenseplate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.new_licenseplate.control.placeholder}}"
                                [ngStyle]="fields.new_licenseplate.control.styles.style"
                                [ngClass]="fields.new_licenseplate.control.styles.classes"> 
                        <ng-container *ngIf="fields.new_licenseplate.invalid">
                          <ng-container *ngFor="let error of fields.new_licenseplate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                              title="{{fields.lot.label}}{{fields.lot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lot.label}}">{{fields.lot.label}}<span *ngIf="fields.lot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-lots_dd_single 
                            data-cy="selector"
                            [type]="fields.lot.control.type"
                            formControlName="lot"
                            (displayTextChange)="fields.lot.control.displayText=$event"
                            [placeholder]="fields.lot.control.placeholder"
                            [styles]="fields.lot.control.styles"
                          [materialId]="$fields_lot_selector_inParams_materialId"
                        >
                        </FootPrintManager-lots_dd_single>
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reasoncode" *ngIf="!fields.reasoncode.hidden" 
                            class="field-container standard {{fields.reasoncode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reasoncode.styles.style"
                            [ngClass]="fields.reasoncode.styles.classes">
                        <div class="label-container"
                              title="{{fields.reasoncode.label}}{{fields.reasoncode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reasoncode.label}}">{{fields.reasoncode.label}}<span *ngIf="fields.reasoncode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Inventory-reasoncodes_dd_single 
                            data-cy="selector"
                            [type]="fields.reasoncode.control.type"
                            formControlName="reasoncode"
                            (displayTextChange)="fields.reasoncode.control.displayText=$event"
                            [placeholder]="fields.reasoncode.control.placeholder"
                            [styles]="fields.reasoncode.control.styles"
                          [parentId]="$fields_reasoncode_selector_inParams_parentId"
                          [parentEntity]="$fields_reasoncode_selector_inParams_parentEntity"
                        >
                        </Inventory-reasoncodes_dd_single>
                        <ng-container *ngIf="fields.reasoncode.invalid">
                          <ng-container *ngFor="let error of fields.reasoncode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>