import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { TemperatureReadings_create_temperature_reading_flowService } from './TemperatureReadings.flow.index';
import { TemperatureReadings_create_temperature_reading_point_flowService } from './TemperatureReadings.flow.index';
import { TemperatureReadings_create_temperature_reading_type_flowService } from './TemperatureReadings.flow.index';
import { TemperatureReadings_delete_temperature_reading_points_flowService } from './TemperatureReadings.flow.index';
import { TemperatureReadings_delete_temperature_reading_types_flowService } from './TemperatureReadings.flow.index';
import { TemperatureReadings_update_temperature_reading_point_flowService } from './TemperatureReadings.flow.index';
import { TemperatureReadings_update_temperature_reading_point_type_flowService } from './TemperatureReadings.flow.index';

import { $types } from './TemperatureReadings.types'

@Injectable({ providedIn: 'root' })
export class TemperatureReadings_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public TemperatureReadings: TemperatureReadings_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _create_temperature_reading_flow: TemperatureReadings_create_temperature_reading_flowService;
  public async create_temperature_reading_flow(inParams: { shipmentId?: number, shipmentLineId?: number, licensePlateId?: number, loadContainerId?: number, readingPointId: number, readingTypeId: number, temperature1: number, measurementUnit1: number, temperature2?: number, measurementUnit2?: number, temperature3?: number, measurementUnit3?: number, notes?: string }): Promise< { temperatureReadingId?: number, reasons?: string[] }> {
    if(!this._create_temperature_reading_flow) {
      this._create_temperature_reading_flow = this.injector.get(TemperatureReadings_create_temperature_reading_flowService);
    }
    return this._create_temperature_reading_flow.run(inParams);
  }
  private _create_temperature_reading_point_flow: TemperatureReadings_create_temperature_reading_point_flowService;
  public async create_temperature_reading_point_flow(inParams: { readingPointName: string, isDefault?: boolean }): Promise< { reasons?: string[] }> {
    if(!this._create_temperature_reading_point_flow) {
      this._create_temperature_reading_point_flow = this.injector.get(TemperatureReadings_create_temperature_reading_point_flowService);
    }
    return this._create_temperature_reading_point_flow.run(inParams);
  }
  private _create_temperature_reading_type_flow: TemperatureReadings_create_temperature_reading_type_flowService;
  public async create_temperature_reading_type_flow(inParams: { readingTypeName: string, isDefault?: boolean }): Promise< { reasons?: string[] }> {
    if(!this._create_temperature_reading_type_flow) {
      this._create_temperature_reading_type_flow = this.injector.get(TemperatureReadings_create_temperature_reading_type_flowService);
    }
    return this._create_temperature_reading_type_flow.run(inParams);
  }
  private _delete_temperature_reading_points_flow: TemperatureReadings_delete_temperature_reading_points_flowService;
  public async delete_temperature_reading_points_flow(inParams: { readingPointId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_temperature_reading_points_flow) {
      this._delete_temperature_reading_points_flow = this.injector.get(TemperatureReadings_delete_temperature_reading_points_flowService);
    }
    return this._delete_temperature_reading_points_flow.run(inParams);
  }
  private _delete_temperature_reading_types_flow: TemperatureReadings_delete_temperature_reading_types_flowService;
  public async delete_temperature_reading_types_flow(inParams: { readingTypeId: number }): Promise< { reasons?: string[] }> {
    if(!this._delete_temperature_reading_types_flow) {
      this._delete_temperature_reading_types_flow = this.injector.get(TemperatureReadings_delete_temperature_reading_types_flowService);
    }
    return this._delete_temperature_reading_types_flow.run(inParams);
  }
  private _update_temperature_reading_point_flow: TemperatureReadings_update_temperature_reading_point_flowService;
  public async update_temperature_reading_point_flow(inParams: { readingPoint?: string, isDefault?: boolean, readingPointId: number }): Promise< { reasons?: string[] }> {
    if(!this._update_temperature_reading_point_flow) {
      this._update_temperature_reading_point_flow = this.injector.get(TemperatureReadings_update_temperature_reading_point_flowService);
    }
    return this._update_temperature_reading_point_flow.run(inParams);
  }
  private _update_temperature_reading_point_type_flow: TemperatureReadings_update_temperature_reading_point_type_flowService;
  public async update_temperature_reading_point_type_flow(inParams: { readingPointType?: string, isDefault?: boolean, readingTypeId: number }): Promise< { reasons?: string[] }> {
    if(!this._update_temperature_reading_point_type_flow) {
      this._update_temperature_reading_point_type_flow = this.injector.get(TemperatureReadings_update_temperature_reading_point_type_flowService);
    }
    return this._update_temperature_reading_point_type_flow.run(inParams);
  }
}
