<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-adjust_inventory" *ngIf="!topToolbar.adjust_inventory.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.adjust_inventory.control.readOnly"
            [ngStyle]="topToolbar.adjust_inventory.control.styles.style"
            [ngClass]="topToolbar.adjust_inventory.control.styles.classes"
            (click)="on_adjust_inventory_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.adjust_inventory.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.adjust_inventory.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.adjust_inventory.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-move_inventory" *ngIf="!topToolbar.move_inventory.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.move_inventory.control.readOnly"
            [ngStyle]="topToolbar.move_inventory.control.styles.style"
            [ngClass]="topToolbar.move_inventory.control.styles.classes"
            (click)="on_move_inventory_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.move_inventory.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.move_inventory.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.move_inventory.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-transfer_inventory" *ngIf="!topToolbar.transfer_inventory.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.transfer_inventory.control.readOnly"
            [ngStyle]="topToolbar.transfer_inventory.control.styles.style"
            [ngClass]="topToolbar.transfer_inventory.control.styles.classes"
            (click)="on_transfer_inventory_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.transfer_inventory.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.transfer_inventory.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.transfer_inventory.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="parent">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.parent.displayControl.styles.style"
          [ngClass]="row.cells.parent.displayControl.styles.classes">{{row.cells.parent.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.location_name.displayControl.styles.style"
          [ngClass]="row.cells.location_name.displayControl.styles.classes">{{row.cells.location_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lot_lookupcode.displayControl.styles.classes">{{row.cells.lot_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.total_packaged_amount.displayControl.styles.classes">{{row.cells.total_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.available_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.available_packaged_amount.displayControl.styles.classes">{{row.cells.available_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="inbound_order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.inbound_order.displayControl.styles.style"
          [ngClass]="row.cells.inbound_order.displayControl.styles.classes">{{row.cells.inbound_order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.manufacture_date.displayControl.styles.style"
          [ngClass]="row.cells.manufacture_date.displayControl.styles.classes">{{row.cells.manufacture_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="received_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.received_date.displayControl.styles.style"
          [ngClass]="row.cells.received_date.displayControl.styles.classes">{{row.cells.received_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
