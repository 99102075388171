import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './FootPrintManager.types'

@Injectable({ providedIn: 'root' })
export class FootPrintManager_get_integration_info_by_order_idService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { order_id?: number }): Promise<{ Source?: string, IntegrationHubId?: string, IntegrationHubSourceId?: string, IntegrationHubSourceLookupCode?: string, IntegrationHubSourcePlatform?: string, WavelengthConnectorTransmissions?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, Direction?: string, IntegrationId?: string, ModifiedSysDateTime?: string, ModifiedSysUser?: string, Notes?: string, TransactionId?: string, Type?: string, Entity?: string }[] }> 
  {
    let url = `${environment.backendUrl}api/FootPrintManager/functions/get_integration_info_by_order_id`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

