import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintApiManager.types'
import { FootPrintApiManager_message_properties_singleComponent } from './FootPrintApiManager.message_properties_single.component'


interface IFootPrintApiManager_alerts_filters_gridComponentEntity {
id?: string, application_name?: string, alert_id?: string, filter_name?: string, filter_value?: string}

interface IFootPrintApiManager_alerts_filters_gridComponentInParams {
  integration_name?: string, alert_id?: string}


class FootPrintApiManager_alerts_filters_gridComponentRowModel extends GridRowModel {
  grid: FootPrintApiManager_alerts_filters_gridComponent;
  entity: IFootPrintApiManager_alerts_filters_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    integration_name_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    alert_id_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    filter_name_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    filter_value_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    integration_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['integration_name_edit'] as DatexFormControl, null, false, '')
      ),
    alert_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['alert_id_edit'] as DatexFormControl, null, false, '')
      ),
    filter_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['filter_name_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    filter_value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['filter_value_edit'] as DatexFormControl, null, false, '')
      ),
    delete: new GridCellModel(
      new CellStyles(null, null),
      new ButtonModel('', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintApiManager_alerts_filters_gridComponent, entity: IFootPrintApiManager_alerts_filters_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintApiManager_alerts_filters_gridComponent, entity?: IFootPrintApiManager_alerts_filters_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_new_clicked();
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      application_name:  $grid.inParams.integration_name ,
      alert_id:  $grid.inParams.alert_id ,
    };
    const data = await this.datasources.Notifications.ds_storage_alerts_filters.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.integration_name.displayControl as TextModel).text = $row.entity.application_name;
    (this.cells.integration_name.editControl as TextBoxModel).reset($row.entity.application_name);
    (this.cells.alert_id.displayControl as TextModel).text = $row.entity.alert_id;
    (this.cells.alert_id.editControl as TextBoxModel).reset($row.entity.alert_id);
    (this.cells.filter_name.displayControl as TextModel).text = $row.entity.filter_name;
    (this.cells.filter_name.editControl as SelectBoxModel).reset($row.entity.filter_name);
    (this.cells.filter_value.displayControl as TextModel).text = $row.entity.filter_value;
    (this.cells.filter_value.editControl as TextBoxModel).reset($row.entity.filter_value);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_existing_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintApiManager_alerts_filters_gridComponentRowModel,
  $grid: FootPrintApiManager_alerts_filters_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/18/2024
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($row.cells.filter_name.editControl.value) &&
      $utils.isDefined($row.cells.filter_value.editControl.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintApiManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  };
  
  if (
      $row.cells.filter_name.editControl.isChanged ||
      $row.cells.filter_value.editControl.isChanged
  ) {
  
      let id: string = $row.entity?.id ?? "";
      let integration_name: string = $grid.inParams.integration_name ?? "";
  
      if (integration_name.toUpperCase() === "NULL") {
          integration_name = "";
      };
  
      if (integration_name === "") {
          integration_name = $row.cells.integration_name.editControl.value ?? "";
      };
  
      let alert_id: string = $grid.inParams.alert_id ?? "";
  
      if (alert_id.toUpperCase() === "NULL") {
          alert_id = "";
      };
  
      if (alert_id === "") {
          alert_id = $row.cells.alert_id.editControl.value ?? "";
      };
  
      try {
  
          if (integration_name === "") {
              throw new Error("You need to specify the integration to insert an alert!")
          };
  
          if (alert_id === "") {
              throw new Error("The alert id cannot be blank!")
          };
  
          if (($row.cells.filter_name.editControl.value ?? "") === "") {
              throw new Error("The filter name cannot be blank!")
          };
  
          if (($row.cells.filter_value.editControl.value ?? "") === "") {
              throw new Error("The to filter value cannot be blank!")
          };
  
          let action: string = "Update";
  
          if (id === "") {
              action = "Write";
          };
  
          try {
  
              await $flows.Notifications.storage_alerts_filters({
                  action: action,
                  payload: {
                      id: id,
                      alert_id: alert_id,
                      filter_name: $row.cells.filter_name.editControl.value,
                      filter_value: $row.cells.filter_value.editControl.value
                  },
                  application_name: integration_name
              });
  
          } catch (error) {
  
              let targetError = error;
              while ($utils.isDefined(targetError?.error)) {
                  targetError = targetError.error;
              };
              if (!$utils.isDefined(targetError?.message)) {
                  targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` }
              };
          }
  
          await $grid.refresh();
  
      } catch (error) {
          $shell.FootPrintApiManager.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      };
  };
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $row: FootPrintApiManager_alerts_filters_gridComponentRowModel,
  $grid: FootPrintApiManager_alerts_filters_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/15/2024
  
  let integration_name: string = $grid.inParams.integration_name ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name !== "") {
      $row.cells.integration_name.displayControl.text = integration_name;
      $row.cells.integration_name.editControl.value = integration_name;
  };
  
  let alert_id: string = $grid.inParams.alert_id ?? "";
  
  if (alert_id.toUpperCase() === "NULL") {
      alert_id = "";
  };
  
  if (alert_id !== "") {
      $row.cells.alert_id.displayControl.text = alert_id;
      $row.cells.alert_id.editControl.value = alert_id;
  };
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $row: FootPrintApiManager_alerts_filters_gridComponentRowModel,
  $grid: FootPrintApiManager_alerts_filters_gridComponent, 
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/15/2024
  
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  let current_error: string;
  let success = false;
  current_error = null;
  
  try {
  
      let entity = $row.entity
  
      await alert_delete({
          "id": entity.id
      }, entity.application_name);
  
      success = true;
  
  } catch (error) {
  
      let target_error = error;
  
      if (typeof target_error === "string") {
          target_error = { message: target_error };
      } else {
          while ($utils.isDefined(target_error.error)) {
              target_error = target_error.error;
          };
      };
  
      if (!$utils.isDefined(target_error.message)) {
          target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
      };
  
      current_error = target_error.message;
  };
  
  if (success) {
      deleted_records.push($row.entity.id.toString())
      $grid.refresh();
  } else {
      errors.push({
          id: $row.entity.id, errorMessages: [current_error]
      });
  };
  
  if ($utils.isDefined(errors)) {
      await $shell.FootPrintApiManager.openErrorDialog(
          `Delete record`,
          deleted_records.length > 0 ? `Record successfully deleted.` : `No records were deleted.`,
          null,
          `Errors deleting record`,
          errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
  };
  
  if (deleted_records.length > 0) {
      await log_add({
          "process": "alerts_filters_grid",
          "level": 1,
          "notes": `Deleted alerts filters! Ids: ${JSON.stringify(deleted_records)} Filters: ${$row.entity.filter_name}`
      }, $row.entity.application_name);
  };
  
  const log_schema = (await $flows.FootPrintApiManager.storage_logs({ action: "", integration_name: "" })).payload;
  async function log_add(input: typeof log_schema[0], integration_name: string) {
      await $flows.FootPrintApiManager.storage_logs({ action: "Write", payload: input, integration_name: integration_name });
  };
  
  const alert_schema = (await $flows.Notifications.storage_alerts_filters({ action: "", application_name: "" })).payload;
  async function alert_delete(input: typeof alert_schema[0], application_name: string) {
      await $flows.Notifications.storage_alerts_filters({ action: "Delete", payload: input, application_name: application_name });
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintApiManager_message_properties_singleComponent),
  ],
  selector: 'FootPrintApiManager-alerts_filters_grid',
  templateUrl: './FootPrintApiManager.alerts_filters_grid.component.html'
})
export class FootPrintApiManager_alerts_filters_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintApiManager_alerts_filters_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['set-width-table']);

  fullTextSearch: string;

  inParams: IFootPrintApiManager_alerts_filters_gridComponentInParams = { integration_name: null, alert_id: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     integration_name: new GridHeaderModel(new HeaderStyles(null, null), 'Integration name', false, false, 150),       alert_id: new GridHeaderModel(new HeaderStyles(null, null), 'Alert id', false, false, 150),       filter_name: new GridHeaderModel(new HeaderStyles(null, null), 'Filter name', true, false, 200),       filter_value: new GridHeaderModel(new HeaderStyles(null, null), 'Filter value', true, false, 200),       delete: new GridHeaderModel(new HeaderStyles(null, null), 'Delete', false, false, 100),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintApiManager_alerts_filters_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('integration_name') set $inParams_integration_name(value: any) {
    this.inParams['integration_name'] = value;
  }
  get $inParams_integration_name(): any {
    return this.inParams['integration_name'] ;
  }
  @Input('alert_id') set $inParams_alert_id(value: any) {
    this.inParams['alert_id'] = value;
  }
  get $inParams_alert_id(): any {
    return this.inParams['alert_id'] ;
  }

  topToolbar = {
      delete_selected: new ToolModel(new ButtonModel('delete_selected', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintApiManager_ShellService,
    private datasources: FootPrintApiManager_DatasourceService,
    private flows: FootPrintApiManager_FlowService,
    private reports: FootPrintApiManager_ReportService,
    private localization: FootPrintApiManager_LocalizationService,
    private operations: FootPrintApiManager_OperationService,
    ) {
    this.title = 'Alerts filters grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      application_name:  $grid.inParams.integration_name ,
      alert_id:  $grid.inParams.alert_id ,
    };
    try {
    const data = await this.datasources.Notifications.ds_storage_alerts_filters.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintApiManager_alerts_filters_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintApiManager_alerts_filters_gridComponentEntity) {
    const row = new FootPrintApiManager_alerts_filters_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintApiManager_alerts_filters_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/15/2024
  
  $grid.title = 'FootPrint API Manager Alerts Filters Grid'
  
  let integration_name: string = $grid.inParams.integration_name ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name !== "") {
      $grid.headers.integration_name.required = false;
      $grid.headers.integration_name.hidden = true;
  };
  
  
  let alert_id: string = $grid.inParams.alert_id ?? "";
  
  if (alert_id.toUpperCase() === "NULL") {
      alert_id = "";
  };
  
  if (alert_id !== "") {
      $grid.headers.alert_id.required = false;
      $grid.headers.alert_id.hidden = true;
  };
  
  if (alert_id === "") {
      $grid.headers.delete.hidden = true;
  }
  
  $grid.topToolbar.delete_selected.control.readOnly = true;
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintApiManager_alerts_filters_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/18/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.FootPrintApiManager.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.FootPrintApiManager.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await alert_delete({
                      "id": entity.id
                  }, entity.application_name);
  
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.id.toString())
              } else {
                  errors.push({
                      id: row.entity.id, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.FootPrintApiManager.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id}` })))
          };
  
          if (deleted_records.length > 0) {
              await log_add({
                  "process": "alerts_filters_grid",
                  "level": 1,
                  "notes": `Deleted alerts filters! Ids: ${JSON.stringify(deleted_records)} Filters: ${selected_rows.map(s => s.entity.filter_name).join(",")}`
              }, selected_rows[0].entity.application_name);
          };
      };
  };
  
  $grid.refresh();
  
  const log_schema = (await $flows.FootPrintApiManager.storage_logs({ action: "", integration_name: "" })).payload;
  async function log_add(input: typeof log_schema[0], integration_name: string) {
      await $flows.FootPrintApiManager.storage_logs({ action: "Write", payload: input, integration_name: integration_name });
  };
  
  const alert_schema = (await $flows.Notifications.storage_alerts_filters({ action: "", application_name: "" })).payload;
  async function alert_delete(input: typeof alert_schema[0], application_name: string) {
      await $flows.Notifications.storage_alerts_filters({ action: "Delete", payload: input, application_name: application_name });
  };
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintApiManager_alerts_filters_gridComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 03/15/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_selected.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
