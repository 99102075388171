import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Invoices.types'

@Injectable({ providedIn: 'root' })
export class Invoices_commodity_priceService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { payload?: $types.Invoices.CommodityPrice[], action: string, skip?: number, take?: number, date_from?: string, date_to?: string, full_text_search?: string }): Promise<{ payload?: $types.Invoices.CommodityPrice[], success?: boolean, error?: any[], count?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.action)) {
      missingRequiredInParams.push('\'action\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/functions/commodity_price`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

