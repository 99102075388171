<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-create_work_class" *ngIf="!toolbar.create_work_class.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_work_class.control.readOnly"
                [ngStyle]="toolbar.create_work_class.control.styles.style"
                [ngClass]="toolbar.create_work_class.control.styles.classes"
                (click)="on_create_work_class_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_work_class.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_work_class.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_work_class.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.work_classes.hidden" class="tab" data-cy="tab-work_classes">
              <h2 [className]="tabs.work_classes.active? 'active': ''" (click)="tabs.work_classes.activate()">{{tabs.work_classes.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-work_classes_grid *ngIf="tabs.work_classes.active"
              #$tabs_work_classes
              ($refreshEvent)="refresh(false, false, '$tabs_work_classes')">
              </FootPrintManager-work_classes_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>