import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { PalletTransactions_pallet_totals_widgetComponent } from './PalletTransactions.pallet_totals_widget.component';
import { PalletTransactions_pallet_transactions_by_direction_widgetComponent } from './PalletTransactions.pallet_transactions_by_direction_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class PalletTransactions_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public PalletTransactions: PalletTransactions_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'PalletTransactions_pallet_totals_widget') {
      const title = 'Total pallets';
      const component = PalletTransactions_pallet_totals_widgetComponent;
      const inParams:{ project_id?: number, pallet_class_id?: number } = { project_id: null, pallet_class_id: null };
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('pallet_class_id'))) {
        const paramValueString = params.get('pallet_class_id');
        inParams.pallet_class_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'PalletTransactions_pallet_transactions_by_direction_widget') {
      const title = 'Pallets in / out (30 days)';
      const component = PalletTransactions_pallet_transactions_by_direction_widgetComponent;
      const inParams:{ days?: number, project_id?: number, pallet_class_id?: number } = { days: null, project_id: null, pallet_class_id: null };
      if (!isNil(params.get('days'))) {
        const paramValueString = params.get('days');
        inParams.days = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('project_id'))) {
        const paramValueString = params.get('project_id');
        inParams.project_id = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('pallet_class_id'))) {
        const paramValueString = params.get('pallet_class_id');
        inParams.pallet_class_id = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
