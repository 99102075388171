import { 
  Component, 
  OnInit,
  OnChanges,
  OnDestroy,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  ViewChild,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { isEqual, isNil, isEmpty } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  SeparatorModel,
  ButtonStyles 
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { ToolModel } from './models/tool';
import { TabItemModel, TabGroupModel } from './models/tab';
import { WidgetModel } from './models/widget';
import { FieldsetModel } from './models/fieldset';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'

import { FootPrintManager_submitted_survey_inputs_gridComponent } from './FootPrintManager.submitted_survey_inputs_grid.component';

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintManager_submitted_survey_inputs_gridComponent),
  ],
  selector: 'FootPrintManager-single_survey_hub',
  templateUrl: './FootPrintManager.single_survey_hub.component.html'
})
export class FootPrintManager_single_survey_hubComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { surveyId: number } = { surveyId: null };
  //#region Inputs
  @Input('surveyId') set $inParams_surveyId(v: number) {
    this.inParams.surveyId = v;
  }
  get $inParams_surveyId(): number {
    return this.inParams.surveyId;
  }
  //#endregion Inputs

  @Input() showInDialog: boolean = false; 
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();  
  //#endregion Outputs
  hasToolbar: boolean = true;



  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { hubTitle?: string } = { };
  //#endregion
  formGroup: FormGroup = new FormGroup({
    operation_context_type: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    equipment: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    created_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    completed_on: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    completed_by: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });
  
  toolbar = {
  };

  actionbar = {
  };

 filters = {
    operation_context_type: new FieldModel(new TextBoxModel(this.formGroup.controls['operation_context_type'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Context', false)
,
    equipment: new FieldModel(new TextBoxModel(this.formGroup.controls['equipment'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Equipment', false)
,
    created_on: new FieldModel(new TextBoxModel(this.formGroup.controls['created_on'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Created on', false)
,
    created_by: new FieldModel(new TextBoxModel(this.formGroup.controls['created_by'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Created by', false)
,
    completed_on: new FieldModel(new TextBoxModel(this.formGroup.controls['completed_on'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Completed on', false)
,
    completed_by: new FieldModel(new TextBoxModel(this.formGroup.controls['completed_by'] as DatexFormControl, null, true, '')
, new ControlContainerStyles(null, null), 'Completed by', false)
,
  };


  filtersets = {
  fields: new FieldsetModel('', true, false, true),
};

    rootTabGroup = new TabGroupModel();
  
    subTabGroups = {
    };
  
    onTabSelected(event: MatSelectChange) {
      event.value.activate();
    }
  
    tabs = {
      inputs: new TabItemModel(
        this.rootTabGroup, 
        'Inputs', 
        ),
    };
  
    //#region tabs inParams
    get $tabs_inputs_submitted_survey_inputs_grid_inParams_surveyId(): number {
      const $hub = this;
      const $utils = this.utils;
      const expr = $hub.inParams.surveyId;
      
      return expr;
    }
  
    //#endregion tabs inParams
  
    //#region tabs children
      @ViewChild('$tabs_inputs', { read: FootPrintManager_submitted_survey_inputs_gridComponent }) $tabs_inputs: FootPrintManager_submitted_survey_inputs_gridComponent;
    //#endregion tabs children

  //#region filters inParams
  //#endregion filters inParams

  get hubTitle(): string {
    const $hub = this;
    const $utils = this.utils;
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //const $l10n = this.localization;
    return $hub.vars.hubTitle;
  }


  constructor(
  private utils: UtilsService,
  private settings: SettingsValuesService,
  private shell: FootPrintManager_ShellService,
  private datasources: FootPrintManager_DatasourceService,
  private flows: FootPrintManager_FlowService,
  private reports: FootPrintManager_ReportService,
  private localization: FootPrintManager_LocalizationService,
  private operations: FootPrintManager_OperationService,
  ) { 
    this.$subscribeFormControlValueChanges();
    this.hasToolbar = !isEmpty(this.toolbar);

    //#region tabs tab init
    this.rootTabGroup.tabs = [
      this.tabs.inputs,
    ]; 
    //#endregion tabs tab init
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.surveyId)) {
        this.$missingRequiredInParams.push('surveyId');
      }
  }



  initialized = false;

  async $init() {
    this.title = 'Submitted survey';
    
    const $hub = this;
    const $utils = this.utils;

    
    
    
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
  }
  close() {
    this.$finish.emit();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = Promise.resolve(null);
    
    // children
    if (skipChildren === false) {
      this.$refreshChildren(childToSkip);
    }

    return result;
  }

  $refreshChildren(childToSkip: string) {
    //#region tabs children
    if (childToSkip !== '$tabs_inputs') {
      if (!isNil(this.$tabs_inputs) && !this.tabs.inputs.hidden) {
        this.$tabs_inputs.refresh(true, false, null);
      }
    }
    //#endregion tabs children
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $hub: FootPrintManager_single_survey_hubComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Get survey data
  let survey = (await $datasources.Surveys.ds_get_survey_by_surveyId.get({ surveyId: $hub.inParams.surveyId })).result;
  
  // Check for Survey entity
  let result = (await $flows.Surveys.get_survey_context_details_flow({ surveyIds: [survey.id]}));
  
  $hub.vars.hubTitle = `${survey.SurveyDefinition.name} survey ${survey.id} for ${result.entities[0].type} ${result.entities[0].name}`;
  
  // Equipment
  if ($utils.isDefined(survey.Equipment.LookupCode)) {
      $hub.filters.equipment.control.value = survey.Equipment.LookupCode;
  }
  else {
      $hub.filters.equipment.hidden = true;
  }
  
  // Operation context type
  if ($utils.isDefined(survey.OperationContextType.Name)) {
      $hub.filters.operation_context_type.control.value = survey.OperationContextType.Name;
  }
  else {
      $hub.filters.operation_context_type.hidden = true;
  }
  
  // Created / Completed sys user
  $hub.filters.completed_by.control.value = survey.modifiedSysUser;
  
  if (survey.createdSysUser == survey.modifiedSysUser) {
      $hub.filters.created_by.hidden = true;
  }
  else {
      $hub.filters.created_by.control.value = survey.createdSysUser;
  }
  
  // Created / Completed sys date time
  $hub.filters.completed_on.control.value = survey.modifiedSysDateTime;
  if (survey.createdSysDateTime == survey.modifiedSysDateTime) {
      $hub.filters.created_on.hidden = true;
  }
  else {
      $hub.filters.created_on.control.value = survey.createdSysDateTime;
  }
  
  
  // Format user names
  $hub.filters.created_by.control.value = (await $flows.Utilities.format_username_flow({ inUserName: survey.createdSysUser})).outUserName ?? survey.createdSysUser;
  $hub.filters.completed_by.control.value = (await $flows.Utilities.format_username_flow({ inUserName: survey.modifiedSysUser})).outUserName ?? survey.modifiedSysUser;
  
  // Format dates
  let format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.trim().toUpperCase() === '24 HOUR' ? 'HH:mm' : 'LT'}`;
  $hub.filters.created_on.control.value = $utils.date.format(survey.createdSysDateTime, format);
  $hub.filters.completed_on.control.value = $utils.date.format(survey.modifiedSysDateTime, format);
  }
  //#endregion private flows
}
