<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-delete_line" *ngIf="!topToolbar.delete_line.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_line.control.readOnly"
            [ngStyle]="topToolbar.delete_line.control.styles.style"
            [ngClass]="topToolbar.delete_line.control.styles.classes"
            (click)="on_delete_line_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_line.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_line.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_line.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="line_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.line_number.displayControl.styles.style"
          [ngClass]="row.cells.line_number.displayControl.styles.classes">{{row.cells.line_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error_message">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.error_message.displayControl.styles.style"
          [ngClass]="row.cells.error_message.displayControl.styles.classes">{{row.cells.error_message.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_material.displayControl.styles.style"
          [ngClass]="row.cells.source_material.displayControl.styles.classes">{{row.cells.source_material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.source_material.editControl.type"
        [formControl]="row.formGroup.controls['source_material_edit']"
        (displayTextChange)="row.cells.source_material.editControl.displayText=$event"
        [placeholder]="row.cells.source_material.editControl.placeholder"
        [styles]="row.cells.source_material.editControl.styles"
      [projectId]="row.$fields_source_material_selector_inParams_projectId"
    >
    </Materials-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.source_vendorlot.displayControl.styles.classes">{{row.cells.source_vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Lots-vendorlots_dd_single 
        data-cy="selector"
        [type]="row.cells.source_vendorlot.editControl.type"
        [formControl]="row.formGroup.controls['source_vendorlot_edit']"
        (displayTextChange)="row.cells.source_vendorlot.editControl.displayText=$event"
        [placeholder]="row.cells.source_vendorlot.editControl.placeholder"
        [styles]="row.cells.source_vendorlot.editControl.styles"
      [materialId]="row.$fields_source_vendorlot_selector_inParams_materialId"
      [lotId]="row.$fields_source_vendorlot_selector_inParams_lotId"
    >
    </Lots-vendorlots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_lot.displayControl.styles.style"
          [ngClass]="row.cells.source_lot.displayControl.styles.classes">{{row.cells.source_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Lots-lots_dd_single 
        data-cy="selector"
        [type]="row.cells.source_lot.editControl.type"
        [formControl]="row.formGroup.controls['source_lot_edit']"
        (displayTextChange)="row.cells.source_lot.editControl.displayText=$event"
        [placeholder]="row.cells.source_lot.editControl.placeholder"
        [styles]="row.cells.source_lot.editControl.styles"
      [materialId]="row.$fields_source_lot_selector_inParams_materialId"
      [vendorLotId]="row.$fields_source_lot_selector_inParams_vendorLotId"
    >
    </Lots-lots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_packaging.displayControl.styles.style"
          [ngClass]="row.cells.source_packaging.displayControl.styles.classes">{{row.cells.source_packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.source_packaging.editControl.type"
        [formControl]="row.formGroup.controls['source_packaging_edit']"
        (displayTextChange)="row.cells.source_packaging.editControl.displayText=$event"
        [placeholder]="row.cells.source_packaging.editControl.placeholder"
        [styles]="row.cells.source_packaging.editControl.styles"
      [materialId]="row.$fields_source_packaging_selector_inParams_materialId"
    >
    </Materials-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.source_packaged_amount.displayControl.styles.classes">{{row.cells.source_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['source_packaged_amount_edit']"
            matInput
            numberBox
            [format]="row.cells.source_packaged_amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.source_packaged_amount.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.source_packaged_amount.editControl.placeholder}}"
            [ngStyle]="row.cells.source_packaged_amount.editControl.styles.style"
            [ngClass]="row.cells.source_packaged_amount.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.source_licenseplate.displayControl.styles.classes">{{row.cells.source_licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-licenseplate_by_inventory_dd_single 
        data-cy="selector"
        [type]="row.cells.source_licenseplate.editControl.type"
        [formControl]="row.formGroup.controls['source_licenseplate_edit']"
        (displayTextChange)="row.cells.source_licenseplate.editControl.displayText=$event"
        [placeholder]="row.cells.source_licenseplate.editControl.placeholder"
        [styles]="row.cells.source_licenseplate.editControl.styles"
      [lotId]="row.$fields_source_licenseplate_selector_inParams_lotId"
      [packagingId]="row.$fields_source_licenseplate_selector_inParams_packagingId"
      [warehouseId]="row.$fields_source_licenseplate_selector_inParams_warehouseId"
    >
    </Inventory-licenseplate_by_inventory_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="source_serial_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.source_serial_number.displayControl.styles.style"
          [ngClass]="row.cells.source_serial_number.displayControl.styles.classes">{{row.cells.source_serial_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Inventory-serialnumbers_by_inventory_dd_single 
        data-cy="selector"
        [type]="row.cells.source_serial_number.editControl.type"
        [formControl]="row.formGroup.controls['source_serial_number_edit']"
        (displayTextChange)="row.cells.source_serial_number.editControl.displayText=$event"
        [placeholder]="row.cells.source_serial_number.editControl.placeholder"
        [styles]="row.cells.source_serial_number.editControl.styles"
      [lotId]="row.$fields_source_serial_number_selector_inParams_lotId"
      [packagingId]="row.$fields_source_serial_number_selector_inParams_packagingId"
      [warehouseId]="row.$fields_source_serial_number_selector_inParams_warehouseId"
      [licenseplateId]="row.$fields_source_serial_number_selector_inParams_licenseplateId"
    >
    </Inventory-serialnumbers_by_inventory_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="target_material_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.target_material_id.displayControl.styles.style"
          [ngClass]="row.cells.target_material_id.displayControl.styles.classes">{{row.cells.target_material_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-materials_by_project_dd_single 
        data-cy="selector"
        [type]="row.cells.target_material_id.editControl.type"
        [formControl]="row.formGroup.controls['target_material_id_edit']"
        (displayTextChange)="row.cells.target_material_id.editControl.displayText=$event"
        [placeholder]="row.cells.target_material_id.editControl.placeholder"
        [styles]="row.cells.target_material_id.editControl.styles"
      [projectId]="row.$fields_target_material_id_selector_inParams_projectId"
    >
    </Materials-materials_by_project_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="allow_inactive_inventory">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['allow_inactive_inventory_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.allow_inactive_inventory.displayControl.styles.style"
                  [ngClass]="row.cells.allow_inactive_inventory.displayControl.styles.classes">{{row.cells.allow_inactive_inventory.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['allow_inactive_inventory_edit']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.allow_inactive_inventory.editControl.styles.style"
                  [ngClass]="row.cells.allow_inactive_inventory.editControl.styles.classes">{{row.cells.allow_inactive_inventory.editControl.label}}</mat-checkbox>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
