import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { TransloadOrders_cancel_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_complete_inbound_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_complete_outbound_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_complete_outbound_transload_shipment_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_create_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_create_transload_order_line_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_delete_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_get_transload_order_stateService } from './TransloadOrders.flow.index';
import { TransloadOrders_get_transload_shipment_stateService } from './TransloadOrders.flow.index';
import { TransloadOrders_is_transload_order_cancelable_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_is_transload_order_deletable_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_is_transload_order_processable_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_load_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_pick_transload_composite_licenseplate_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_pick_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_process_transload_order_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_receive_on_new_licenseplate_flowService } from './TransloadOrders.flow.index';
import { TransloadOrders_receive_transload_order_flowService } from './TransloadOrders.flow.index';

import { $types } from './TransloadOrders.types'

@Injectable({ providedIn: 'root' })
export class TransloadOrders_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public TransloadOrders: TransloadOrders_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _cancel_transload_order_flow: TransloadOrders_cancel_transload_order_flowService;
  public async cancel_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._cancel_transload_order_flow) {
      this._cancel_transload_order_flow = this.injector.get(TransloadOrders_cancel_transload_order_flowService);
    }
    return this._cancel_transload_order_flow.run(inParams);
  }
  private _complete_inbound_transload_order_flow: TransloadOrders_complete_inbound_transload_order_flowService;
  public async complete_inbound_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._complete_inbound_transload_order_flow) {
      this._complete_inbound_transload_order_flow = this.injector.get(TransloadOrders_complete_inbound_transload_order_flowService);
    }
    return this._complete_inbound_transload_order_flow.run(inParams);
  }
  private _complete_outbound_transload_order_flow: TransloadOrders_complete_outbound_transload_order_flowService;
  public async complete_outbound_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._complete_outbound_transload_order_flow) {
      this._complete_outbound_transload_order_flow = this.injector.get(TransloadOrders_complete_outbound_transload_order_flowService);
    }
    return this._complete_outbound_transload_order_flow.run(inParams);
  }
  private _complete_outbound_transload_shipment_flow: TransloadOrders_complete_outbound_transload_shipment_flowService;
  public async complete_outbound_transload_shipment_flow(inParams: { shipment_id: number, order_id: number, keep_parent_order_open: boolean }): Promise< { reason?: string }> {
    if(!this._complete_outbound_transload_shipment_flow) {
      this._complete_outbound_transload_shipment_flow = this.injector.get(TransloadOrders_complete_outbound_transload_shipment_flowService);
    }
    return this._complete_outbound_transload_shipment_flow.run(inParams);
  }
  private _create_transload_order_flow: TransloadOrders_create_transload_order_flowService;
  public async create_transload_order_flow(inParams: { projectId: number, warehouseId: number, inboundTransloadOrderClassId: number, outboundTransloadOrderClassId: number, lookupcode?: string }): Promise< { reason?: string, transloadId?: number, inboundOrderId?: number, outboundOrderId?: number }> {
    if(!this._create_transload_order_flow) {
      this._create_transload_order_flow = this.injector.get(TransloadOrders_create_transload_order_flowService);
    }
    return this._create_transload_order_flow.run(inParams);
  }
  private _create_transload_order_line_flow: TransloadOrders_create_transload_order_line_flowService;
  public async create_transload_order_line_flow(inParams: { orderId: number, packagingId: number, packagedAmount: number, materialId?: number, lotId?: number, vendorlotId?: number }): Promise< { reasons?: string[], linenumber?: number, confirm?: boolean }> {
    if(!this._create_transload_order_line_flow) {
      this._create_transload_order_line_flow = this.injector.get(TransloadOrders_create_transload_order_line_flowService);
    }
    return this._create_transload_order_line_flow.run(inParams);
  }
  private _delete_transload_order_flow: TransloadOrders_delete_transload_order_flowService;
  public async delete_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._delete_transload_order_flow) {
      this._delete_transload_order_flow = this.injector.get(TransloadOrders_delete_transload_order_flowService);
    }
    return this._delete_transload_order_flow.run(inParams);
  }
  private _get_transload_order_state: TransloadOrders_get_transload_order_stateService;
  public async get_transload_order_state(inParams: { transloadId: number }): Promise< { state?: number }> {
    if(!this._get_transload_order_state) {
      this._get_transload_order_state = this.injector.get(TransloadOrders_get_transload_order_stateService);
    }
    return this._get_transload_order_state.run(inParams);
  }
  private _get_transload_shipment_state: TransloadOrders_get_transload_shipment_stateService;
  public async get_transload_shipment_state(inParams: { orders: { orderId: number, orderStatusId?: number, orderStatus?: string, shipmentId?: number, shipmentStatusId?: number, waveId?: number, waveStatus?: string, waveStatusId?: number }[] }): Promise< { states?: { orderId?: number, stateId?: number, stateName?: string, has_manual?: boolean, picked_total?: number, expected_total?: number, shipmentId?: number }[], messages?: any[] }> {
    if(!this._get_transload_shipment_state) {
      this._get_transload_shipment_state = this.injector.get(TransloadOrders_get_transload_shipment_stateService);
    }
    return this._get_transload_shipment_state.run(inParams);
  }
  private _is_transload_order_cancelable_flow: TransloadOrders_is_transload_order_cancelable_flowService;
  public async is_transload_order_cancelable_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._is_transload_order_cancelable_flow) {
      this._is_transload_order_cancelable_flow = this.injector.get(TransloadOrders_is_transload_order_cancelable_flowService);
    }
    return this._is_transload_order_cancelable_flow.run(inParams);
  }
  private _is_transload_order_deletable_flow: TransloadOrders_is_transload_order_deletable_flowService;
  public async is_transload_order_deletable_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._is_transload_order_deletable_flow) {
      this._is_transload_order_deletable_flow = this.injector.get(TransloadOrders_is_transload_order_deletable_flowService);
    }
    return this._is_transload_order_deletable_flow.run(inParams);
  }
  private _is_transload_order_processable_flow: TransloadOrders_is_transload_order_processable_flowService;
  public async is_transload_order_processable_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._is_transload_order_processable_flow) {
      this._is_transload_order_processable_flow = this.injector.get(TransloadOrders_is_transload_order_processable_flowService);
    }
    return this._is_transload_order_processable_flow.run(inParams);
  }
  private _load_transload_order_flow: TransloadOrders_load_transload_order_flowService;
  public async load_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._load_transload_order_flow) {
      this._load_transload_order_flow = this.injector.get(TransloadOrders_load_transload_order_flowService);
    }
    return this._load_transload_order_flow.run(inParams);
  }
  private _pick_transload_composite_licenseplate_flow: TransloadOrders_pick_transload_composite_licenseplate_flowService;
  public async pick_transload_composite_licenseplate_flow(inParams: { licenseplate_id: number }): Promise< { reason?: string }> {
    if(!this._pick_transload_composite_licenseplate_flow) {
      this._pick_transload_composite_licenseplate_flow = this.injector.get(TransloadOrders_pick_transload_composite_licenseplate_flowService);
    }
    return this._pick_transload_composite_licenseplate_flow.run(inParams);
  }
  private _pick_transload_order_flow: TransloadOrders_pick_transload_order_flowService;
  public async pick_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._pick_transload_order_flow) {
      this._pick_transload_order_flow = this.injector.get(TransloadOrders_pick_transload_order_flowService);
    }
    return this._pick_transload_order_flow.run(inParams);
  }
  private _process_transload_order_flow: TransloadOrders_process_transload_order_flowService;
  public async process_transload_order_flow(inParams: { transloadId: number }): Promise< { reason?: string }> {
    if(!this._process_transload_order_flow) {
      this._process_transload_order_flow = this.injector.get(TransloadOrders_process_transload_order_flowService);
    }
    return this._process_transload_order_flow.run(inParams);
  }
  private _receive_on_new_licenseplate_flow: TransloadOrders_receive_on_new_licenseplate_flowService;
  public async receive_on_new_licenseplate_flow(inParams: { lotId: number, packagingId: number, packagedAmount: number, taskId: number, locationId: number, serialNumbers: { LookupCode?: string, NetWeightValue?: number, GrossWeightValue?: number, WeightUOM?: number, Width?: number, Height?: number, Length?: number, DimensionUOM?: number, NetVolumeValue?: number, GrossVolumeValue?: number, VolumeUOM?: number }[], licenseplateLookupcode?: string, shipmentId?: number }): Promise< { reasons?: string[], confirm?: boolean, taskId?: number }> {
    if(!this._receive_on_new_licenseplate_flow) {
      this._receive_on_new_licenseplate_flow = this.injector.get(TransloadOrders_receive_on_new_licenseplate_flowService);
    }
    return this._receive_on_new_licenseplate_flow.run(inParams);
  }
  private _receive_transload_order_flow: TransloadOrders_receive_transload_order_flowService;
  public async receive_transload_order_flow(inParams: { transloadId: number }): Promise< { result?: any, reason?: string }> {
    if(!this._receive_transload_order_flow) {
      this._receive_transload_order_flow = this.injector.get(TransloadOrders_receive_transload_order_flowService);
    }
    return this._receive_transload_order_flow.run(inParams);
  }
}
