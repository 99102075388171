<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-create" *ngIf="!toolbar.create.hidden" class="toolContainer">
        <button mat-button 
                data-cy="splitbutton"
                class="datex-button splitbutton" 
                [disabled]="toolbar.create.control.readOnly"
                [ngStyle]="toolbar.create.control.styles.style"
                [ngClass]="toolbar.create.control.styles.classes"
                [matMenuTriggerFor]="dropdownMenuOne">
          <div class="button-label">
            <div *ngIf="toolbar.create.control.icon"
                  class="button-icon">
              <i data-cy="splitbutton-icon" class="icon {{toolbar.create.control.icon}}"></i>
            </div>
            <div data-cy="splitbutton-label" class="button-text">{{toolbar.create.control.label}}</div>
            <div class="button-icon splitbutton-drop-icon">
              <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
            </div>
          </div>
        </button>
        <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
          <div data-cy="splitbutton-options">
            <div mat-menu-item class="splitbutton-item-container">
              <button mat-button
                      data-cy="splitbutton-sub-item"
                      class="datex-button splitbutton-sub-item" 
                      [disabled]="toolbar.create.control.buttons.create_rma_order.readOnly"
                      [ngStyle]="toolbar.create.control.buttons.create_rma_order.styles.style"
                      [ngClass]="toolbar.create.control.buttons.create_rma_order.styles.classes"
                      (click)="on_create_rma_order_clicked($event)">
                <div class="button-label">
                  <div *ngIf="toolbar.create.control.buttons.create_rma_order.icon"
                      class="button-icon">
                    <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.create.control.buttons.create_rma_order.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-sub-item-label"
                      class="button-text">{{toolbar.create.control.buttons.create_rma_order.label}}</div>
                </div>
              </button>
            </div>
            <div mat-menu-item class="splitbutton-item-container">
              <button mat-button
                      data-cy="splitbutton-sub-item"
                      class="datex-button splitbutton-sub-item" 
                      [disabled]="toolbar.create.control.buttons.create_blind_return.readOnly"
                      [ngStyle]="toolbar.create.control.buttons.create_blind_return.styles.style"
                      [ngClass]="toolbar.create.control.buttons.create_blind_return.styles.classes"
                      (click)="on_create_blind_return_clicked($event)">
                <div class="button-label">
                  <div *ngIf="toolbar.create.control.buttons.create_blind_return.icon"
                      class="button-icon">
                    <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.create.control.buttons.create_blind_return.icon}}"></i>
                  </div>
                  <div data-cy="splitbutton-sub-item-label"
                      class="button-text">{{toolbar.create.control.buttons.create_blind_return.label}}</div>
                </div>
              </button>
            </div>
          </div>
        </mat-menu>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-filters"
                     *ngIf="!filtersets.filters.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.filters.collapsible }">
                    <div *ngIf="!filtersets.filters.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.filters.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.filters.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.filters.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.filters.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.filters.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouses.label}}{{filters.warehouses.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouses.label}}">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-order_statuses" *ngIf="!filters.order_statuses.hidden" 
                                  class="field-container standard {{filters.order_statuses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.order_statuses.styles.style"
                                  [ngClass]="filters.order_statuses.styles.classes">
                              <div class="label-container"
                                    title="{{filters.order_statuses.label}}{{filters.order_statuses.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.order_statuses.label}}">{{filters.order_statuses.label}}<span *ngIf="filters.order_statuses.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Returns-order_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.order_statuses.control.type"
                                  formControlName="order_statuses"
                                  (displayTextChange)="filters.order_statuses.control.displayText=$event"
                                  [placeholder]="filters.order_statuses.control.placeholder"
                                  [styles]="filters.order_statuses.control.styles"
                                [statusIds]="$fields_order_statuses_selector_inParams_statusIds"
                              >
                              </Returns-order_statuses_dd_multi>
                              <ng-container *ngIf="filters.order_statuses.invalid">
                                <ng-container *ngFor="let error of filters.order_statuses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-order_classes" *ngIf="!filters.order_classes.hidden" 
                                  class="field-container standard {{filters.order_classes.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.order_classes.styles.style"
                                  [ngClass]="filters.order_classes.styles.classes">
                              <div class="label-container"
                                    title="{{filters.order_classes.label}}{{filters.order_classes.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.order_classes.label}}">{{filters.order_classes.label}}<span *ngIf="filters.order_classes.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Returns-order_classes_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.order_classes.control.type"
                                  formControlName="order_classes"
                                  (displayTextChange)="filters.order_classes.control.displayText=$event"
                                  [placeholder]="filters.order_classes.control.placeholder"
                                  [styles]="filters.order_classes.control.styles"
                              >
                              </Returns-order_classes_dd_multi>
                              <ng-container *ngIf="filters.order_classes.invalid">
                                <ng-container *ngFor="let error of filters.order_classes.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-reference" *ngIf="!filters.reference.hidden" 
                                  class="field-container standard {{filters.reference.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.reference.styles.style"
                                  [ngClass]="filters.reference.styles.classes">
                              <div class="label-container"
                                    title="{{filters.reference.label}}{{filters.reference.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.reference.label}}">{{filters.reference.label}}<span *ngIf="filters.reference.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="reference"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.reference.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.reference.control.placeholder}}"
                                      [ngStyle]="filters.reference.control.styles.style"
                                      [ngClass]="filters.reference.control.styles.classes"> 
                              <ng-container *ngIf="filters.reference.invalid">
                                <ng-container *ngFor="let error of filters.reference.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-Scan"
                     *ngIf="!filtersets.Scan.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.Scan.collapsible }">
                    <div *ngIf="!filtersets.Scan.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.Scan.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.Scan.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.Scan.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.Scan.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.Scan.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-input_scan" *ngIf="!filters.input_scan.hidden" 
                                  class="field-container full {{filters.input_scan.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.input_scan.styles.style"
                                  [ngClass]="filters.input_scan.styles.classes">
                              <div class="label-container"
                                    title="{{filters.input_scan.label}}{{filters.input_scan.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.input_scan.label}}">{{filters.input_scan.label}}<span *ngIf="filters.input_scan.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="input_scan"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.input_scan.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.input_scan.control.placeholder}}"
                                      [ngStyle]="filters.input_scan.control.styles.style"
                                      [ngClass]="filters.input_scan.control.styles.classes"> 
                              <ng-container *ngIf="filters.input_scan.invalid">
                                <ng-container *ngFor="let error of filters.input_scan.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.orders.hidden" class="tab" data-cy="tab-orders">
              <h2 [className]="tabs.orders.active? 'active': ''" (click)="tabs.orders.activate()">{{tabs.orders.title}}</h2>
            </div>
            <div *ngIf="!tabs.shipping_containers.hidden" class="tab" data-cy="tab-shipping_containers">
              <h2 [className]="tabs.shipping_containers.active? 'active': ''" (click)="tabs.shipping_containers.activate()">{{tabs.shipping_containers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-return_orders_grid *ngIf="tabs.orders.active"
              #$tabs_orders
              [ownerId]="$tabs_orders_return_orders_grid_inParams_ownerId"
              [projectId]="$tabs_orders_return_orders_grid_inParams_projectId"
              [warehouseIds]="$tabs_orders_return_orders_grid_inParams_warehouseIds"
              [statusIds]="$tabs_orders_return_orders_grid_inParams_statusIds"
              [orderClassIds]="$tabs_orders_return_orders_grid_inParams_orderClassIds"
              [reference]="$tabs_orders_return_orders_grid_inParams_reference"
              ($refreshEvent)="refresh(false, false, '$tabs_orders')">
              </FootPrintManager-return_orders_grid>
              <FootPrintManager-return_shipping_containers_grid *ngIf="tabs.shipping_containers.active"
              #$tabs_shipping_containers
              [ownerId]="$tabs_shipping_containers_return_shipping_containers_grid_inParams_ownerId"
              [projectId]="$tabs_shipping_containers_return_shipping_containers_grid_inParams_projectId"
              [warehouseIds]="$tabs_shipping_containers_return_shipping_containers_grid_inParams_warehouseIds"
              [orderStatusIds]="$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderStatusIds"
              [orderClassIds]="$tabs_shipping_containers_return_shipping_containers_grid_inParams_orderClassIds"
              [reference]="$tabs_shipping_containers_return_shipping_containers_grid_inParams_reference"
              ($refreshEvent)="refresh(false, false, '$tabs_shipping_containers')">
              </FootPrintManager-return_shipping_containers_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>