import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './SerialNumbers.types'

@Injectable({ providedIn: 'root' })
export class SerialNumbers_get_serialnumber_parent_pathService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { serialnumberIds: number[] }): Promise<{ serialInfo?: { serialId?: number, serialPath?: string }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.serialnumberIds)) {
      missingRequiredInParams.push('\'serialnumberIds\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/SerialNumbers/functions/get_serialnumber_parent_path`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

