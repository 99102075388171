<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-run_import" *ngIf="!topToolbar.run_import.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.run_import.control.readOnly"
            [ngStyle]="topToolbar.run_import.control.styles.style"
            [ngClass]="topToolbar.run_import.control.styles.classes"
            (click)="on_run_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.run_import.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.run_import.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.run_import.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-confirm_entity_import" *ngIf="!topToolbar.confirm_entity_import.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.confirm_entity_import.control.readOnly"
            [ngStyle]="topToolbar.confirm_entity_import.control.styles.style"
            [ngClass]="topToolbar.confirm_entity_import.control.styles.classes"
            (click)="on_confirm_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.confirm_entity_import.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.confirm_entity_import.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.confirm_entity_import.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-line_count" *ngIf="!topToolbar.line_count.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.line_count.control.readOnly"
            [ngStyle]="topToolbar.line_count.control.styles.style"
            [ngClass]="topToolbar.line_count.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.line_count.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.line_count.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.line_count.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-refresh" *ngIf="!topToolbar.refresh.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.refresh.control.readOnly"
            [ngStyle]="topToolbar.refresh.control.styles.style"
            [ngClass]="topToolbar.refresh.control.styles.classes"
            (click)="on_refresh_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.refresh.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.refresh.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.refresh.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-delete_row" *ngIf="!topToolbar.delete_row.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_row.control.readOnly"
            [ngStyle]="topToolbar.delete_row.control.styles.style"
            [ngClass]="topToolbar.delete_row.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_row.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_row.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_row.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-purge_data" *ngIf="!topToolbar.purge_data.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.purge_data.control.readOnly"
            [ngStyle]="topToolbar.purge_data.control.styles.style"
            [ngClass]="topToolbar.purge_data.control.styles.classes"
            (click)="on_purge_data($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.purge_data.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.purge_data.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.purge_data.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator2" *ngIf="!topToolbar.separator2.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-submitted" *ngIf="!topToolbar.submitted.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.submitted.control.readOnly"
            [ngStyle]="topToolbar.submitted.control.styles.style"
            [ngClass]="topToolbar.submitted.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.submitted.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.submitted.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.submitted.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-ready" *ngIf="!topToolbar.ready.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.ready.control.readOnly"
            [ngStyle]="topToolbar.ready.control.styles.style"
            [ngClass]="topToolbar.ready.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.ready.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.ready.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.ready.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-errors" *ngIf="!topToolbar.errors.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.errors.control.readOnly"
            [ngStyle]="topToolbar.errors.control.styles.style"
            [ngClass]="topToolbar.errors.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.errors.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.errors.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.errors.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-import_status" *ngIf="!filters.import_status.hidden" 
            class="field-container standard {{filters.import_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.import_status.styles.style"
            [ngClass]="filters.import_status.styles.classes">
        <div class="label-container"
              title="{{filters.import_status.label}}{{filters.import_status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.import_status.label}}">{{filters.import_status.label}}<span *ngIf="filters.import_status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <ExcelMaterialImport-import_statuses_dd_single 
            data-cy="selector"
            [type]="filters.import_status.control.type"
            formControlName="import_status"
            (displayTextChange)="filters.import_status.control.displayText=$event"
            [placeholder]="filters.import_status.control.placeholder"
            [styles]="filters.import_status.control.styles"
        >
        </ExcelMaterialImport-import_statuses_dd_single>
        <ng-container *ngIf="filters.import_status.invalid">
          <ng-container *ngFor="let error of filters.import_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportStatus">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ImportStatus.displayControl.styles.style"
          [ngClass]="row.cells.ImportStatus.displayControl.styles.classes">{{row.cells.ImportStatus.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportCreatedDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ImportCreatedDate.displayControl.styles.style"
          [ngClass]="row.cells.ImportCreatedDate.displayControl.styles.classes">{{row.cells.ImportCreatedDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportCompletedDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ImportCompletedDate.displayControl.styles.style"
          [ngClass]="row.cells.ImportCompletedDate.displayControl.styles.classes">{{row.cells.ImportCompletedDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportErrorMessage">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ImportErrorMessage.displayControl.styles.style"
          [ngClass]="row.cells.ImportErrorMessage.displayControl.styles.classes">{{row.cells.ImportErrorMessage.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ImportRequestId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ImportRequestId.displayControl.styles.style"
          [ngClass]="row.cells.ImportRequestId.displayControl.styles.classes">{{row.cells.ImportRequestId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LookupCode.displayControl.styles.classes">{{row.cells.LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OwnerLookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.OwnerLookupCode.displayControl.styles.style"
          [ngClass]="row.cells.OwnerLookupCode.displayControl.styles.classes">{{row.cells.OwnerLookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ProjectLookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ProjectLookupCode.displayControl.styles.style"
          [ngClass]="row.cells.ProjectLookupCode.displayControl.styles.classes">{{row.cells.ProjectLookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="GlobalMaterialName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.GlobalMaterialName.displayControl.styles.style"
          [ngClass]="row.cells.GlobalMaterialName.displayControl.styles.classes">{{row.cells.GlobalMaterialName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialName">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MaterialName.displayControl.styles.style"
          [ngClass]="row.cells.MaterialName.displayControl.styles.classes">{{row.cells.MaterialName.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialDescription">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MaterialDescription.displayControl.styles.style"
          [ngClass]="row.cells.MaterialDescription.displayControl.styles.classes">{{row.cells.MaterialDescription.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialGroup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MaterialGroup.displayControl.styles.style"
          [ngClass]="row.cells.MaterialGroup.displayControl.styles.classes">{{row.cells.MaterialGroup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialStatus">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MaterialStatus.displayControl.styles.style"
          [ngClass]="row.cells.MaterialStatus.displayControl.styles.classes">{{row.cells.MaterialStatus.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReceiveStatus">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ReceiveStatus.displayControl.styles.style"
          [ngClass]="row.cells.ReceiveStatus.displayControl.styles.classes">{{row.cells.ReceiveStatus.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NmfcNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.NmfcNumber.displayControl.styles.style"
          [ngClass]="row.cells.NmfcNumber.displayControl.styles.classes">{{row.cells.NmfcNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NmfcSubNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.NmfcSubNumber.displayControl.styles.style"
          [ngClass]="row.cells.NmfcSubNumber.displayControl.styles.classes">{{row.cells.NmfcSubNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LotControlled_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LotControlled_YN.displayControl.styles.style"
          [ngClass]="row.cells.LotControlled_YN.displayControl.styles.classes">{{row.cells.LotControlled_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SerialControlled_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.SerialControlled_YN.displayControl.styles.style"
          [ngClass]="row.cells.SerialControlled_YN.displayControl.styles.classes">{{row.cells.SerialControlled_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="FixedWeight_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.FixedWeight_YN.displayControl.styles.style"
          [ngClass]="row.cells.FixedWeight_YN.displayControl.styles.classes">{{row.cells.FixedWeight_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="FixedDimension_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.FixedDimension_YN.displayControl.styles.style"
          [ngClass]="row.cells.FixedDimension_YN.displayControl.styles.classes">{{row.cells.FixedDimension_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="FixedVolume_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.FixedVolume_YN.displayControl.styles.style"
          [ngClass]="row.cells.FixedVolume_YN.displayControl.styles.classes">{{row.cells.FixedVolume_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AllowReceiving_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AllowReceiving_YN.displayControl.styles.style"
          [ngClass]="row.cells.AllowReceiving_YN.displayControl.styles.classes">{{row.cells.AllowReceiving_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AllowShipping_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AllowShipping_YN.displayControl.styles.style"
          [ngClass]="row.cells.AllowShipping_YN.displayControl.styles.classes">{{row.cells.AllowShipping_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AllowReturns_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AllowReturns_YN.displayControl.styles.style"
          [ngClass]="row.cells.AllowReturns_YN.displayControl.styles.classes">{{row.cells.AllowReturns_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AllowBlindReceiving_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AllowBlindReceiving_YN.displayControl.styles.style"
          [ngClass]="row.cells.AllowBlindReceiving_YN.displayControl.styles.classes">{{row.cells.AllowBlindReceiving_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SkipLpFifoAllocation_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.SkipLpFifoAllocation_YN.displayControl.styles.style"
          [ngClass]="row.cells.SkipLpFifoAllocation_YN.displayControl.styles.classes">{{row.cells.SkipLpFifoAllocation_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="IncludeInCycleCount_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.IncludeInCycleCount_YN.displayControl.styles.style"
          [ngClass]="row.cells.IncludeInCycleCount_YN.displayControl.styles.classes">{{row.cells.IncludeInCycleCount_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="KitType">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.KitType.displayControl.styles.style"
          [ngClass]="row.cells.KitType.displayControl.styles.classes">{{row.cells.KitType.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CurrencyId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CurrencyId.displayControl.styles.style"
          [ngClass]="row.cells.CurrencyId.displayControl.styles.classes">{{row.cells.CurrencyId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ShelfLifeDays">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ShelfLifeDays.displayControl.styles.style"
          [ngClass]="row.cells.ShelfLifeDays.displayControl.styles.classes">{{row.cells.ShelfLifeDays.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MinimumDays">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MinimumDays.displayControl.styles.style"
          [ngClass]="row.cells.MinimumDays.displayControl.styles.classes">{{row.cells.MinimumDays.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="StandardPrice">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.StandardPrice.displayControl.styles.style"
          [ngClass]="row.cells.StandardPrice.displayControl.styles.classes">{{row.cells.StandardPrice.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="StandardCost">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.StandardCost.displayControl.styles.style"
          [ngClass]="row.cells.StandardCost.displayControl.styles.classes">{{row.cells.StandardCost.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AllocationStrategy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AllocationStrategy.displayControl.styles.style"
          [ngClass]="row.cells.AllocationStrategy.displayControl.styles.classes">{{row.cells.AllocationStrategy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PackageAllocationStrategy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.PackageAllocationStrategy.displayControl.styles.style"
          [ngClass]="row.cells.PackageAllocationStrategy.displayControl.styles.classes">{{row.cells.PackageAllocationStrategy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="DaysToFirstTasteTest">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.DaysToFirstTasteTest.displayControl.styles.style"
          [ngClass]="row.cells.DaysToFirstTasteTest.displayControl.styles.classes">{{row.cells.DaysToFirstTasteTest.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="TasteTestCycleDays">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.TasteTestCycleDays.displayControl.styles.style"
          [ngClass]="row.cells.TasteTestCycleDays.displayControl.styles.classes">{{row.cells.TasteTestCycleDays.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReallocationExpirationWindow">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ReallocationExpirationWindow.displayControl.styles.style"
          [ngClass]="row.cells.ReallocationExpirationWindow.displayControl.styles.classes">{{row.cells.ReallocationExpirationWindow.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PrimaryPickLocationsPerWarehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.PrimaryPickLocationsPerWarehouse.displayControl.styles.style"
          [ngClass]="row.cells.PrimaryPickLocationsPerWarehouse.displayControl.styles.classes">{{row.cells.PrimaryPickLocationsPerWarehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ReceivingOverageThreshold">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ReceivingOverageThreshold.displayControl.styles.style"
          [ngClass]="row.cells.ReceivingOverageThreshold.displayControl.styles.classes">{{row.cells.ReceivingOverageThreshold.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="StandardUnitsPerHour">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.StandardUnitsPerHour.displayControl.styles.style"
          [ngClass]="row.cells.StandardUnitsPerHour.displayControl.styles.classes">{{row.cells.StandardUnitsPerHour.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AmbientTimeInHours">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AmbientTimeInHours.displayControl.styles.style"
          [ngClass]="row.cells.AmbientTimeInHours.displayControl.styles.classes">{{row.cells.AmbientTimeInHours.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CycleCountTolerance">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CycleCountTolerance.displayControl.styles.style"
          [ngClass]="row.cells.CycleCountTolerance.displayControl.styles.classes">{{row.cells.CycleCountTolerance.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="StorageCategoryRule">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.StorageCategoryRule.displayControl.styles.style"
          [ngClass]="row.cells.StorageCategoryRule.displayControl.styles.classes">{{row.cells.StorageCategoryRule.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Uom.displayControl.styles.style"
          [ngClass]="row.cells.Uom.displayControl.styles.classes">{{row.cells.Uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SubUom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.SubUom.displayControl.styles.style"
          [ngClass]="row.cells.SubUom.displayControl.styles.classes">{{row.cells.SubUom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="SubUomQuantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.SubUomQuantity.displayControl.styles.style"
          [ngClass]="row.cells.SubUomQuantity.displayControl.styles.classes">{{row.cells.SubUomQuantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="DynamicUom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.DynamicUom.displayControl.styles.style"
          [ngClass]="row.cells.DynamicUom.displayControl.styles.classes">{{row.cells.DynamicUom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomUpc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomUpc.displayControl.styles.style"
          [ngClass]="row.cells.UomUpc.displayControl.styles.classes">{{row.cells.UomUpc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomAllowSale_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomAllowSale_YN.displayControl.styles.style"
          [ngClass]="row.cells.UomAllowSale_YN.displayControl.styles.classes">{{row.cells.UomAllowSale_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomAllowPurchase_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomAllowPurchase_YN.displayControl.styles.style"
          [ngClass]="row.cells.UomAllowPurchase_YN.displayControl.styles.classes">{{row.cells.UomAllowPurchase_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomReporting_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomReporting_YN.displayControl.styles.style"
          [ngClass]="row.cells.UomReporting_YN.displayControl.styles.classes">{{row.cells.UomReporting_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomNonDefault_YN">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomNonDefault_YN.displayControl.styles.style"
          [ngClass]="row.cells.UomNonDefault_YN.displayControl.styles.classes">{{row.cells.UomNonDefault_YN.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomPalletTie">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomPalletTie.displayControl.styles.style"
          [ngClass]="row.cells.UomPalletTie.displayControl.styles.classes">{{row.cells.UomPalletTie.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomPalletHigh">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomPalletHigh.displayControl.styles.style"
          [ngClass]="row.cells.UomPalletHigh.displayControl.styles.classes">{{row.cells.UomPalletHigh.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomStandardPrice">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomStandardPrice.displayControl.styles.style"
          [ngClass]="row.cells.UomStandardPrice.displayControl.styles.classes">{{row.cells.UomStandardPrice.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomStandardCost">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomStandardCost.displayControl.styles.style"
          [ngClass]="row.cells.UomStandardCost.displayControl.styles.classes">{{row.cells.UomStandardCost.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomWeight.displayControl.styles.style"
          [ngClass]="row.cells.UomWeight.displayControl.styles.classes">{{row.cells.UomWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomShippingWeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomShippingWeight.displayControl.styles.style"
          [ngClass]="row.cells.UomShippingWeight.displayControl.styles.classes">{{row.cells.UomShippingWeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomLength">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomLength.displayControl.styles.style"
          [ngClass]="row.cells.UomLength.displayControl.styles.classes">{{row.cells.UomLength.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomWidth">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomWidth.displayControl.styles.style"
          [ngClass]="row.cells.UomWidth.displayControl.styles.classes">{{row.cells.UomWidth.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomHeight">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomHeight.displayControl.styles.style"
          [ngClass]="row.cells.UomHeight.displayControl.styles.classes">{{row.cells.UomHeight.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomVolume">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomVolume.displayControl.styles.style"
          [ngClass]="row.cells.UomVolume.displayControl.styles.classes">{{row.cells.UomVolume.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomWeightUnit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomWeightUnit.displayControl.styles.style"
          [ngClass]="row.cells.UomWeightUnit.displayControl.styles.classes">{{row.cells.UomWeightUnit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomDimensionUnit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomDimensionUnit.displayControl.styles.style"
          [ngClass]="row.cells.UomDimensionUnit.displayControl.styles.classes">{{row.cells.UomDimensionUnit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UomVolumeUnit">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UomVolumeUnit.displayControl.styles.style"
          [ngClass]="row.cells.UomVolumeUnit.displayControl.styles.classes">{{row.cells.UomVolumeUnit.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ManufacturingCrewComplement">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ManufacturingCrewComplement.displayControl.styles.style"
          [ngClass]="row.cells.ManufacturingCrewComplement.displayControl.styles.classes">{{row.cells.ManufacturingCrewComplement.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LpMixingRestriction">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LpMixingRestriction.displayControl.styles.style"
          [ngClass]="row.cells.LpMixingRestriction.displayControl.styles.classes">{{row.cells.LpMixingRestriction.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AutoPopulateLot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AutoPopulateLot.displayControl.styles.style"
          [ngClass]="row.cells.AutoPopulateLot.displayControl.styles.classes">{{row.cells.AutoPopulateLot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="AutoPopulateVendorLot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.AutoPopulateVendorLot.displayControl.styles.style"
          [ngClass]="row.cells.AutoPopulateVendorLot.displayControl.styles.classes">{{row.cells.AutoPopulateVendorLot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MaterialTagList">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MaterialTagList.displayControl.styles.style"
          [ngClass]="row.cells.MaterialTagList.displayControl.styles.classes">{{row.cells.MaterialTagList.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF01Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF01Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF01Name.displayControl.styles.classes">{{row.cells.UDF01Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF01Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF01Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF01Value.displayControl.styles.classes">{{row.cells.UDF01Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF02Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF02Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF02Name.displayControl.styles.classes">{{row.cells.UDF02Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF02Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF02Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF02Value.displayControl.styles.classes">{{row.cells.UDF02Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF03Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF03Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF03Name.displayControl.styles.classes">{{row.cells.UDF03Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF03Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF03Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF03Value.displayControl.styles.classes">{{row.cells.UDF03Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF04Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF04Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF04Name.displayControl.styles.classes">{{row.cells.UDF04Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF04Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF04Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF04Value.displayControl.styles.classes">{{row.cells.UDF04Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF05Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF05Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF05Name.displayControl.styles.classes">{{row.cells.UDF05Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF05Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF05Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF05Value.displayControl.styles.classes">{{row.cells.UDF05Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF06Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF06Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF06Name.displayControl.styles.classes">{{row.cells.UDF06Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF06Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF06Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF06Value.displayControl.styles.classes">{{row.cells.UDF06Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF07Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF07Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF07Name.displayControl.styles.classes">{{row.cells.UDF07Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF07Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF07Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF07Value.displayControl.styles.classes">{{row.cells.UDF07Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF08Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF08Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF08Name.displayControl.styles.classes">{{row.cells.UDF08Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF08Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF08Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF08Value.displayControl.styles.classes">{{row.cells.UDF08Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF09Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF09Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF09Name.displayControl.styles.classes">{{row.cells.UDF09Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF09Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF09Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF09Value.displayControl.styles.classes">{{row.cells.UDF09Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF10Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF10Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF10Name.displayControl.styles.classes">{{row.cells.UDF10Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF10Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF10Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF10Value.displayControl.styles.classes">{{row.cells.UDF10Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF11Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF11Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF11Name.displayControl.styles.classes">{{row.cells.UDF11Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF11Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF11Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF11Value.displayControl.styles.classes">{{row.cells.UDF11Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF12Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF12Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF12Name.displayControl.styles.classes">{{row.cells.UDF12Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF12Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF12Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF12Value.displayControl.styles.classes">{{row.cells.UDF12Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF13Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF13Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF13Name.displayControl.styles.classes">{{row.cells.UDF13Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF13Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF13Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF13Value.displayControl.styles.classes">{{row.cells.UDF13Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF14Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF14Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF14Name.displayControl.styles.classes">{{row.cells.UDF14Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF14Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF14Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF14Value.displayControl.styles.classes">{{row.cells.UDF14Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF15Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF15Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF15Name.displayControl.styles.classes">{{row.cells.UDF15Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF15Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF15Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF15Value.displayControl.styles.classes">{{row.cells.UDF15Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF16Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF16Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF16Name.displayControl.styles.classes">{{row.cells.UDF16Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF16Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF16Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF16Value.displayControl.styles.classes">{{row.cells.UDF16Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF17Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF17Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF17Name.displayControl.styles.classes">{{row.cells.UDF17Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF17Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF17Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF17Value.displayControl.styles.classes">{{row.cells.UDF17Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF18Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF18Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF18Name.displayControl.styles.classes">{{row.cells.UDF18Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF18Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF18Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF18Value.displayControl.styles.classes">{{row.cells.UDF18Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF19Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF19Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF19Name.displayControl.styles.classes">{{row.cells.UDF19Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF19Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF19Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF19Value.displayControl.styles.classes">{{row.cells.UDF19Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF20Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF20Name.displayControl.styles.style"
          [ngClass]="row.cells.UDF20Name.displayControl.styles.classes">{{row.cells.UDF20Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="UDF20Value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.UDF20Value.displayControl.styles.style"
          [ngClass]="row.cells.UDF20Value.displayControl.styles.classes">{{row.cells.UDF20Value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="json_test">

    <ng-template gridCellDisplayControlDef let-row>
    <ngx-codemirror
                [formControl]="row.formGroup.controls['json_test_display']"
                [options]="{
                theme: 'base16-light',
                mode: row.cells.json_test.displayControl.codeMirrorMode,
                lineNumbers: true,
                lineWrapping: true,
                foldGutter: true,
                gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                autoCloseBrackets: true,
                matchBrackets: true,
                lint: true
                }"
                [CodeValidator]="row.cells.json_test.displayControl.mode"
                >
    </ngx-codemirror>
    
    <button type="button margin-top-10"
            (click)="row.cells.json_test.displayControl.beautify();"
            class="datex-button secondary ">Beautify</button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="xml_test">

    <ng-template gridCellDisplayControlDef let-row>
    <ngx-codemirror
                [formControl]="row.formGroup.controls['xml_test_display']"
                [options]="{
                theme: 'base16-light',
                mode: row.cells.xml_test.displayControl.codeMirrorMode,
                lineNumbers: true,
                lineWrapping: true,
                foldGutter: true,
                gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                autoCloseBrackets: true,
                matchBrackets: true,
                lint: true
                }"
                [CodeValidator]="row.cells.xml_test.displayControl.mode"
                >
    </ngx-codemirror>
    
    <button type="button margin-top-10"
            (click)="row.cells.xml_test.displayControl.beautify();"
            class="datex-button secondary ">Beautify</button>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
