import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { EntityImport_delete_request_flowService } from './EntityImport.flow.index';
import { EntityImport_entity_import_parse_errorsService } from './EntityImport.flow.index';
import { EntityImport_entity_import_resubmit_flowService } from './EntityImport.flow.index';
import { EntityImport_entity_import_update_flowService } from './EntityImport.flow.index';
import { EntityImport_get_entity_import_request_xml_chunk_flowService } from './EntityImport.flow.index';
import { EntityImport_submit_request_flowService } from './EntityImport.flow.index';

import { $types } from './EntityImport.types'

@Injectable({ providedIn: 'root' })
export class EntityImport_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public EntityImport: EntityImport_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _delete_request_flow: EntityImport_delete_request_flowService;
  public async delete_request_flow(inParams: { requestId: string }): Promise< { reasons?: string[] }> {
    if(!this._delete_request_flow) {
      this._delete_request_flow = this.injector.get(EntityImport_delete_request_flowService);
    }
    return this._delete_request_flow.run(inParams);
  }
  private _entity_import_parse_errors: EntityImport_entity_import_parse_errorsService;
  public async entity_import_parse_errors(inParams: { request_id?: string }): Promise< { project?: string, warehouse?: string, reference?: string, summary?: string, request?: string, messages?: any[] }> {
    if(!this._entity_import_parse_errors) {
      this._entity_import_parse_errors = this.injector.get(EntityImport_entity_import_parse_errorsService);
    }
    return this._entity_import_parse_errors.run(inParams);
  }
  private _entity_import_resubmit_flow: EntityImport_entity_import_resubmit_flowService;
  public async entity_import_resubmit_flow(inParams: { requestId: string }): Promise< { reason?: string }> {
    if(!this._entity_import_resubmit_flow) {
      this._entity_import_resubmit_flow = this.injector.get(EntityImport_entity_import_resubmit_flowService);
    }
    return this._entity_import_resubmit_flow.run(inParams);
  }
  private _entity_import_update_flow: EntityImport_entity_import_update_flowService;
  public async entity_import_update_flow(inParams: { requestId: string, requestXml: string }): Promise< { reasons?: string[] }> {
    if(!this._entity_import_update_flow) {
      this._entity_import_update_flow = this.injector.get(EntityImport_entity_import_update_flowService);
    }
    return this._entity_import_update_flow.run(inParams);
  }
  private _get_entity_import_request_xml_chunk_flow: EntityImport_get_entity_import_request_xml_chunk_flowService;
  public async get_entity_import_request_xml_chunk_flow(inParams: { request_id: string, page_number: number }): Promise< { chunk?: string, total_chunks?: number }> {
    if(!this._get_entity_import_request_xml_chunk_flow) {
      this._get_entity_import_request_xml_chunk_flow = this.injector.get(EntityImport_get_entity_import_request_xml_chunk_flowService);
    }
    return this._get_entity_import_request_xml_chunk_flow.run(inParams);
  }
  private _submit_request_flow: EntityImport_submit_request_flowService;
  public async submit_request_flow(inParams: { requestXml?: string, referenceCode?: string }): Promise< { reasons?: string[], requestId?: string }> {
    if(!this._submit_request_flow) {
      this._submit_request_flow = this.injector.get(EntityImport_submit_request_flowService);
    }
    return this._submit_request_flow.run(inParams);
  }
}
