<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"


[rowCanExpand]="true"
>
    <ng-container topToolbar>
    <div data-cy="tool-id-process_wave" *ngIf="!topToolbar.process_wave.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.process_wave.control.readOnly"
            [ngStyle]="topToolbar.process_wave.control.styles.style"
            [ngClass]="topToolbar.process_wave.control.styles.classes"
            (click)="on_process_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.process_wave.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.process_wave.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.process_wave.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-release_wave" *ngIf="!topToolbar.release_wave.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.release_wave.control.readOnly"
            [ngStyle]="topToolbar.release_wave.control.styles.style"
            [ngClass]="topToolbar.release_wave.control.styles.classes"
            (click)="on_release_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.release_wave.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.release_wave.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.release_wave.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-cancel_wave" *ngIf="!topToolbar.cancel_wave.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.cancel_wave.control.readOnly"
            [ngStyle]="topToolbar.cancel_wave.control.styles.style"
            [ngClass]="topToolbar.cancel_wave.control.styles.classes"
            (click)="on_cancel_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.cancel_wave.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.cancel_wave.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.cancel_wave.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator2" *ngIf="!topToolbar.separator2.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-print_wave" *ngIf="!topToolbar.print_wave.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.print_wave.control.readOnly"
            [ngStyle]="topToolbar.print_wave.control.styles.style"
            [ngClass]="topToolbar.print_wave.control.styles.classes"
            (click)="on_print_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.print_wave.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.print_wave.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.print_wave.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-from_date" *ngIf="!filters.from_date.hidden" 
            class="field-container standard {{filters.from_date.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.from_date.styles.style"
            [ngClass]="filters.from_date.styles.classes">
        <div class="label-container"
              title="{{filters.from_date.label}}{{filters.from_date.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.from_date.label}}">{{filters.from_date.label}}<span *ngIf="filters.from_date.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="from_date"
            [format]="filters.from_date.control.format"
            [mode]="filters.from_date.control.mode"
            [ngStyle]="filters.from_date.control.styles.style"
            [ngClass]="filters.from_date.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.from_date.invalid">
          <ng-container *ngFor="let error of filters.from_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-to_date" *ngIf="!filters.to_date.hidden" 
            class="field-container standard {{filters.to_date.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.to_date.styles.style"
            [ngClass]="filters.to_date.styles.classes">
        <div class="label-container"
              title="{{filters.to_date.label}}{{filters.to_date.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.to_date.label}}">{{filters.to_date.label}}<span *ngIf="filters.to_date.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="to_date"
            [format]="filters.to_date.control.format"
            [mode]="filters.to_date.control.mode"
            [ngStyle]="filters.to_date.control.styles.style"
            [ngClass]="filters.to_date.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.to_date.invalid">
          <ng-container *ngFor="let error of filters.to_date.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_type" *ngIf="!filters.date_type.hidden" 
            class="field-container standard {{filters.date_type.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_type.styles.style"
            [ngClass]="filters.date_type.styles.classes">
        <div class="label-container"
              title="{{filters.date_type.label}}{{filters.date_type.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_type.label}}">{{filters.date_type.label}}<span *ngIf="filters.date_type.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Waves-wave_date_types_single 
            data-cy="selector"
            [type]="filters.date_type.control.type"
            formControlName="date_type"
            (displayTextChange)="filters.date_type.control.displayText=$event"
            [placeholder]="filters.date_type.control.placeholder"
            [styles]="filters.date_type.control.styles"
        >
        </Waves-wave_date_types_single>
        <ng-container *ngIf="filters.date_type.invalid">
          <ng-container *ngFor="let error of filters.date_type.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Shipment_Wave_Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_Wave_Id.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Id.displayControl.styles.classes">{{row.cells.Shipment_Wave_Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manual_allocations">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.manual_allocations.displayControl.styles.style"
          [ngClass]="row.cells.manual_allocations.displayControl.styles.classes">{{row.cells.manual_allocations.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_Description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_Wave_Description.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Description.displayControl.styles.classes">{{row.cells.Shipment_Wave_Description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_Status_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_Wave_Status_Name.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Status_Name.displayControl.styles.classes">{{row.cells.Shipment_Wave_Status_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_Priority_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_Wave_Priority_Name.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_Priority_Name.displayControl.styles.classes">{{row.cells.Shipment_Wave_Priority_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_CreatedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.styles.classes">{{row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.text | formatText : row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.formatType : row.cells.Shipment_Wave_CreatedSysDateTime.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Shipment_Wave_CreatedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Shipment_Wave_CreatedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.Shipment_Wave_CreatedSysUser.displayControl.styles.classes">{{row.cells.Shipment_Wave_CreatedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="released_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.released_on.displayControl.styles.style"
          [ngClass]="row.cells.released_on.displayControl.styles.classes">{{row.cells.released_on.displayControl.text | formatText : row.cells.released_on.displayControl.formatType : row.cells.released_on.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="is_batch_pick">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['is_batch_pick_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.is_batch_pick.displayControl.styles.style"
                  [ngClass]="row.cells.is_batch_pick.displayControl.styles.classes">{{row.cells.is_batch_pick.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="staging_location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.staging_location.displayControl.styles.style"
          [ngClass]="row.cells.staging_location.displayControl.styles.classes">{{row.cells.staging_location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>

<ng-template expandableRowDef
              let-row>
    <FootPrintManager-pick_waves_expanded_grid
    [WaveId]="row.$rowExpand_FootPrintManager_pick_waves_expanded_grid_inParams_WaveId"
    ($refreshEvent)="row.refresh()">>
  </FootPrintManager-pick_waves_expanded_grid>
</ng-template>

</datex-grid>
