<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
        <div data-cy="tool-id-edit" *ngIf="!toolbar.edit.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.edit.control.readOnly"
                [ngStyle]="toolbar.edit.control.styles.style"
                [ngClass]="toolbar.edit.control.styles.classes"
                (click)="on_edit_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.edit.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.edit.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.edit.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-location_path" *ngIf="!filters.location_path.hidden" 
                                  class="field-container double {{filters.location_path.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.location_path.styles.style"
                                  [ngClass]="filters.location_path.styles.classes">
                              <div class="label-container"
                                    title="{{filters.location_path.label}}{{filters.location_path.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.location_path.label}}">{{filters.location_path.label}}<span *ngIf="filters.location_path.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <label data-cy="label" class="datex-label"
                                      [ngStyle]="filters.location_path.control.styles.style"
                                      [ngClass]="filters.location_path.control.styles.classes">{{filters.location_path.control.label}}</label>
                              <ng-container *ngIf="filters.location_path.invalid">
                                <ng-container *ngFor="let error of filters.location_path.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
          <div class="hub-tools">
            <div data-cy="tool-id-custom_fields" *ngIf="!actionbar.custom_fields.hidden" class="toolContainer">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="actionbar.custom_fields.control.readOnly"
                    [ngStyle]="actionbar.custom_fields.control.styles.style"
                    [ngClass]="actionbar.custom_fields.control.styles.classes"
                    (click)="on_custom_fields_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="actionbar.custom_fields.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{actionbar.custom_fields.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{actionbar.custom_fields.control.label}}</div>
              </div>
            
            </button>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.serialnumber_net_weight_widget.hidden" class="widget-container">
            <Inventory-serialnumber_net_weight_widget
            #$widgets_serialnumber_net_weight_widget 
              [serialnumberId]="$widgets_serialnumber_net_weight_widget_inParams_serialnumberId"
            ($refreshEvent)="refresh(false, false, '$widgets_serialnumber_net_weight_widget')"
            >
            </Inventory-serialnumber_net_weight_widget>
          </div>
          <div *ngIf="!widgets.serialnumber_gross_weight_widget.hidden" class="widget-container">
            <Inventory-serialnumber_gross_weight_widget
            #$widgets_serialnumber_gross_weight_widget 
              [serialnumberId]="$widgets_serialnumber_gross_weight_widget_inParams_serialnumberId"
            ($refreshEvent)="refresh(false, false, '$widgets_serialnumber_gross_weight_widget')"
            >
            </Inventory-serialnumber_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.child_serialnumbers.hidden" class="tab" data-cy="tab-child_serialnumbers">
              <h2 [className]="tabs.child_serialnumbers.active? 'active': ''" (click)="tabs.child_serialnumbers.activate()">{{tabs.child_serialnumbers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [serialnumberId]="$tabs_tasks_inventory_tasks_grid_inParams_serialnumberId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-child_serialnumbers_grid *ngIf="tabs.child_serialnumbers.active"
              #$tabs_child_serialnumbers
              [chainHead]="$tabs_child_serialnumbers_child_serialnumbers_grid_inParams_chainHead"
              [archived]="$tabs_child_serialnumbers_child_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_child_serialnumbers')">
              </FootPrintManager-child_serialnumbers_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>