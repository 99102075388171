import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Carriers_carriers_ownerscarriers_dd_singleComponent } from './Carriers.carriers_ownerscarriers_dd_single.component'
import { Carriers_carrierservicetype_dd_singleComponent } from './Carriers.carrierservicetype_dd_single.component'


interface IFootPrintManager_transload_outbound_shipments_gridComponentEntity {
OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, ActualWarehouseId?: number, AvailableDate?: string, BillOfLading?: string, BookingNumber?: string, BrokerReference?: string, CarrierId?: number, CarrierServiceTypeId?: number, LookupCode?: string, Notes?: string, ReferenceNumber?: string, StatusId?: number, TrailerId?: string, Carrier?: { ShortName?: string }, CarrierServiceType?: { ShortName?: string }, Status?: { Name?: string }, LicensePlateShipmentAssociation?: { LicensePlateId?: number, LicensePlate?: { Id?: number, ChildLicensePlates?: { Id?: number }[] } }[], Wave?: { Id?: number, StatusId?: number, Status?: { Name?: string } } }, Order?: { Id?: number, OrderStatusId?: number, PreferredWarehouseId?: number, ProjectId?: number, Project?: { OwnerId?: number }, Status?: { Name?: string } }}

interface IFootPrintManager_transload_outbound_shipments_gridComponentInParams {
  orderId: number}


class FootPrintManager_transload_outbound_shipments_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_transload_outbound_shipments_gridComponent;
  entity: IFootPrintManager_transload_outbound_shipments_gridComponentEntity;

  vars: { stateId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    available_date_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    bill_of_lading_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    reference_number_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    booking_number_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    broker_reference_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    trailer_id_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    carrier_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    carrier_service_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    notes_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    state: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    licenseplate_count: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    available_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'MM/DD/YYYY')
,
      new DateBoxModel(this.formGroup.controls['available_date_edit'] as DatexFormControl, null, false, '', 'date')
      ),
    bill_of_lading: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['bill_of_lading_edit'] as DatexFormControl, null, false, '')
      ),
    reference_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['reference_number_edit'] as DatexFormControl, null, false, '')
      ),
    booking_number: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['booking_number_edit'] as DatexFormControl, null, false, '')
      ),
    broker_reference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['broker_reference_edit'] as DatexFormControl, null, false, '')
      ),
    trailer_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['trailer_id_edit'] as DatexFormControl, null, false, '')
      ),
    carrier: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['carrier_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    carrier_service: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['carrier_service_edit'] as DatexFormControl, 
        null, null,
        false, 
        '')
      ),
    notes: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['notes_edit'] as DatexFormControl, null, false, '')
      ),
  }

  get $fields_carrier_selector_inParams_ownerId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.Order?.Project?.OwnerId;
    
    return expr;
  }
  get $fields_carrier_service_selector_inParams_carrierId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.carrier.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_transload_outbound_shipments_gridComponent, entity: IFootPrintManager_transload_outbound_shipments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.OrderId,this.entity.ShipmentId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_transload_outbound_shipments_gridComponent, entity?: IFootPrintManager_transload_outbound_shipments_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.OrderId,this.entity.ShipmentId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'OrderId',this.entity.OrderId);
    set($resultKey, 'ShipmentId',this.entity.ShipmentId);
    const inParams = {
      $keys:[$resultKey],
      orderId:  $grid.inParams.orderId ,
    };
    const data = await this.datasources.FootPrintManager.ds_transload_outbound_shipments_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.Shipment?.LookupCode;
    (this.cells.available_date.displayControl as TextModel).text = $row.entity.Shipment?.AvailableDate?.toString();
    (this.cells.available_date.editControl as DateBoxModel).reset($row.entity.Shipment?.AvailableDate);
    (this.cells.bill_of_lading.displayControl as TextModel).text = $row.entity.Shipment?.BillOfLading;
    (this.cells.bill_of_lading.editControl as TextBoxModel).reset($row.entity.Shipment?.BillOfLading);
    (this.cells.reference_number.displayControl as TextModel).text = $row.entity.Shipment?.ReferenceNumber;
    (this.cells.reference_number.editControl as TextBoxModel).reset($row.entity.Shipment?.ReferenceNumber);
    (this.cells.booking_number.displayControl as TextModel).text = $row.entity.Shipment?.BookingNumber;
    (this.cells.booking_number.editControl as TextBoxModel).reset($row.entity.Shipment?.BookingNumber);
    (this.cells.broker_reference.displayControl as TextModel).text = $row.entity.Shipment?.BrokerReference;
    (this.cells.broker_reference.editControl as TextBoxModel).reset($row.entity.Shipment?.BrokerReference);
    (this.cells.trailer_id.displayControl as TextModel).text = $row.entity.Shipment?.TrailerId;
    (this.cells.trailer_id.editControl as TextBoxModel).reset($row.entity.Shipment?.TrailerId);
    (this.cells.carrier.displayControl as TextModel).text = $row.entity.Shipment?.Carrier?.ShortName;
    (this.cells.carrier.editControl as SelectBoxModel).reset($row.entity.Shipment?.CarrierId);
    (this.cells.carrier_service.displayControl as TextModel).text = $row.entity.Shipment?.CarrierServiceType?.ShortName;
    (this.cells.carrier_service.editControl as SelectBoxModel).reset($row.entity.Shipment?.CarrierServiceTypeId);
    (this.cells.notes.displayControl as TextModel).text = $row.entity.Shipment?.Notes;
    (this.cells.notes.editControl as TextBoxModel).reset($row.entity.Shipment?.Notes);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_transload_outbound_shipments_gridComponentRowModel,
  $grid: FootPrintManager_transload_outbound_shipments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var shipmentId = $row.entity.ShipmentId;
  
  const licenseplates = (await $datasources.TransloadOrders.ds_get_licenseplate_count_by_composite_shipment.get({
      shipment_id: shipmentId
  })).result;
  
  if ($utils.isDefined(licenseplates)){
  
      const count = licenseplates[0]?.LicensePlate?.ChildLicensePlates?.length ?? 0;
      $row.cells.licenseplate_count.displayControl.text = count.toString();
  }
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_transload_outbound_shipments_gridComponentRowModel,
  $grid: FootPrintManager_transload_outbound_shipments_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const payload: any = {};
  if ($row.cells.available_date.editControl.isChanged) {
      payload.AvailableDate = $row.cells.available_date.editControl.value;
  }
  if ($row.cells.bill_of_lading.editControl.isChanged) {
      payload.BillOfLading = $row.cells.bill_of_lading.editControl.value;
  }
  
  if ($row.cells.booking_number.editControl.isChanged) {
      payload.BookingNumber = $row.cells.booking_number.editControl.value;
  }
  if ($row.cells.broker_reference.editControl.isChanged) {
      payload.BrokerReference = $row.cells.broker_reference.editControl.value;
  }
  if ($row.cells.reference_number.editControl.isChanged) {
      payload.ReferenceNumber = $row.cells.reference_number.editControl.value;
  }
  if ($row.cells.notes.editControl.isChanged) {
      payload.Notes = $row.cells.notes.editControl.value;
  }
  if ($row.cells.carrier.editControl.isChanged) {
      payload.CarrierId = $row.cells.carrier.editControl.value;
  }
  
  if ($row.cells.carrier_service.editControl.isChanged) {
      payload.CarrierServiceTypeId = $row.cells.carrier_service.editControl.value;
  }
  
  if ($row.cells.trailer_id.editControl.isChanged) {
      payload.TrailerId = $row.cells.trailer_id.editControl.value;
  }
  
  
  
  
  
  try {
  
  
      await $flows.Utilities.crud_update_flow({
          entitySet: 'Shipments',
          id: $row.entity.ShipmentId,
          entity: payload
      });
  
  
      await $row.refresh();
  } catch (error) {
      $shell.FootPrintManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Carriers_carriers_ownerscarriers_dd_singleComponent),
    forwardRef(() => Carriers_carrierservicetype_dd_singleComponent),
  ],
  selector: 'FootPrintManager-transload_outbound_shipments_grid',
  templateUrl: './FootPrintManager.transload_outbound_shipments_grid.component.html'
})
export class FootPrintManager_transload_outbound_shipments_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_transload_outbound_shipments_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootPrintManager_transload_outbound_shipments_gridComponentInParams = { orderId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookup code', false, false, null),       state: new GridHeaderModel(new HeaderStyles(null, null), 'State', false, false, null),       licenseplate_count: new GridHeaderModel(new HeaderStyles(null, null), 'LP count', false, false, null),       available_date: new GridHeaderModel(new HeaderStyles(null, null), 'Available date', false, false, null),       bill_of_lading: new GridHeaderModel(new HeaderStyles(null, null), 'Bill of lading', false, false, null),       reference_number: new GridHeaderModel(new HeaderStyles(null, null), 'Reference number', false, false, null),       booking_number: new GridHeaderModel(new HeaderStyles(null, null), 'Booking number', false, false, null),       broker_reference: new GridHeaderModel(new HeaderStyles(null, null), 'Broker reference', false, false, null),       trailer_id: new GridHeaderModel(new HeaderStyles(null, null), 'TrailerId', false, false, null),       carrier: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier', false, false, null),       carrier_service: new GridHeaderModel(new HeaderStyles(null, null), 'Carrier service', false, false, null),       notes: new GridHeaderModel(new HeaderStyles(null, null), 'Notes', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_transload_outbound_shipments_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }

  topToolbar = {
      create_shipment: new ToolModel(new ButtonModel('create_shipment', new ButtonStyles(null, null), false, 'New outbound', 'ms-Icon ms-Icon--Add')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cross_dock: new ToolModel(new ButtonModel('cross_dock', new ButtonStyles(null, null), false, 'Cross dock', 'icon-datex-Carrier')
    ),
      auto_load: new ToolModel(new ButtonModel('auto_load', new ButtonStyles(null, null), false, 'Auto load', 'icon-datex-Load')
    ),
      complete: new ToolModel(new ButtonModel('complete', new ButtonStyles(null, null), false, 'Complete', 'ms-Icon ms-Icon--Completed')
    ),
      separator2: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      on_delete: new ToolModel(new ButtonModel('on_delete', new ButtonStyles(null, null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Transload outbound shipments';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      orderId:  $grid.inParams.orderId ,
    };
    try {
    const data = await this.datasources.FootPrintManager.ds_transload_outbound_shipments_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_transload_outbound_shipments_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_new_outbound_shipment(event = null) {
    return this.on_new_outbound_shipmentInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_outbound_shipmentInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Get order details
  const order = (await $datasources.TransloadOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  // Get warehouse default receiving location
  var defaultReceivingLocationId;
  const warehouse = (await $datasources.PurchaseOrders.ds_get_warehouse_by_warehouseId.get({ warehouseId: order.PreferredWarehouseId })).result;
  if ($utils.isDefined(warehouse)) {
      if ($utils.isDefined(warehouse.DefaultReceivingLocationId)) {
          defaultReceivingLocationId = warehouse.DefaultReceivingLocationId;
      } else {
    
          throw new Error('Missing a default receiving location on the preferred warehouse.');
      }
  } else {
     
      throw new Error('Unable to determine warehouse from inbound order.');
  }
  
  
  if ($utils.isDefined(order)) {
  
      const nextShipmentId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Shipment' })).nextId;
      // create shipment order lookup
      const payload = {
          "OrderId": $grid.inParams.orderId,
          "Shipment": {
              "Id": nextShipmentId,
              "LookupCode": nextShipmentId.toString(),
              "CarrierId": order.PreferredCarrierId,
              "ExpectedWarehouseId": order.PreferredWarehouseId,
              "Cartonized": false,
              "ConfirmSeal": false,
              "StatusId": 1,
              "Transhipment": false,
              "TypeId": 2
          }
      }
  
      await $flows.Utilities.crud_create_flow({ entitySet: 'ShipmentOrderLookup', entity: payload });
  
      // Create the composite license plate and the licenseplate shipment association
      const nextLicensePlateId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LicensePlate' })).nextId;
      const associationPayload = {
          "ShipmentId": nextShipmentId,
          "LicensePlate": {
              "Id": nextLicensePlateId,
              "LookupCode": `COMP1 for SH${nextShipmentId.toString()}`,
              "WarehouseId": order.PreferredWarehouseId,
              "LocationId": defaultReceivingLocationId,
              "StatusId": 1,
              "Archived": false,
              "TypeId": 2
          }
      }
      await $flows.Utilities.crud_create_flow({ entitySet: 'LicensePlatesShipmentsAssociation', entity: associationPayload });
  
      $grid.refresh();
  
  }
  else {
      throw new Error('Unable to determine outbound order details.')
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let order = (await $datasources.SalesOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  const outbound_shipment_state = $grid.selectedRows[0]?.vars.stateId ?? 0;
  // { stateId: 1, stateName: 'Empty' },
  // { stateId: 3, stateName: 'Ready to process wave' },
  // { stateId: 4, stateName: 'Ready to cross dock' },
  // { stateId: 5, stateName: 'Ready to cross dock' },
  // { stateId: 6, stateName: 'Cross dock in progress' },
  // { stateId: 7, stateName: 'Ready to load' },
  // { stateId: 8, stateName: 'Ready to complete' },
  // { stateId: 9, stateName: 'Ready to cancel' },
  // { stateId: 10, stateName: 'Loading in progress' },
  // { stateId: 11, stateName: 'Completed' },
  // { stateId: 12, stateName: 'Cancelled' }
  
  // Not completed or cancelled
  if (order.OrderStatusId !== 4 && order.OrderStatusId !== 8) {
  
      $grid.topToolbar.create_shipment.control.readOnly = false;
  
      const hasOneSelectedRows = $grid.selectedRows.length === 1;
  
      if (hasOneSelectedRows) {
  
          const shipment_status = $grid.selectedRows[0].entity.Shipment?.StatusId;
          const childrenlength = $grid.selectedRows[0].entity.Shipment?.LicensePlateShipmentAssociation[0]?.LicensePlate?.ChildLicensePlates?.length;
          const childrenCount = $utils.isDefined(childrenlength) ? childrenlength : 0;
  
          // Created status
          if (shipment_status === 1) {
  
              $grid.topToolbar.auto_load.control.readOnly = true;
              $grid.topToolbar.complete.control.readOnly = true;
  
              if (childrenCount === 0) {
                  $grid.topToolbar.on_delete.control.readOnly = false;
                  $grid.topToolbar.cross_dock.control.readOnly = true;
              } else {
                  $grid.topToolbar.cross_dock.control.readOnly = false;
              }
          }
          // Executing status
          else if (shipment_status === 4) {
  
              $grid.topToolbar.cross_dock.control.readOnly = true;
  
              if (outbound_shipment_state === 7 || outbound_shipment_state === 10) {
                  $grid.topToolbar.auto_load.control.readOnly = false;
              }
              else {
                  $grid.topToolbar.auto_load.control.readOnly = true;
              }
  
              if (outbound_shipment_state === 8) {
                  $grid.topToolbar.complete.control.readOnly = false;
              }
              else {
                  $grid.topToolbar.complete.control.readOnly = true;
              }
              
              $grid.topToolbar.on_delete.control.readOnly = true;
          }
          else {
              $grid.topToolbar.cross_dock.control.readOnly = true;
              $grid.topToolbar.auto_load.control.readOnly = true;
              $grid.topToolbar.complete.control.readOnly = true
              $grid.topToolbar.on_delete.control.readOnly = true;
          }
  
      }
      else {
          $grid.topToolbar.cross_dock.control.readOnly = true;
          $grid.topToolbar.auto_load.control.readOnly = true;
          $grid.topToolbar.complete.control.readOnly = true;
          $grid.topToolbar.on_delete.control.readOnly = true;
      }
  
  } else {
      $grid.topToolbar.create_shipment.control.readOnly = true;
      $grid.topToolbar.cross_dock.control.readOnly = true;
      $grid.topToolbar.auto_load.control.readOnly = true;
      $grid.topToolbar.complete.control.readOnly = true;
      $grid.topToolbar.on_delete.control.readOnly = true;
  }
  
  
  }
  on_cross_dock(event = null) {
    return this.on_cross_dockInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cross_dockInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const confirmed = await $shell.FootPrintManager.openConfirmationDialog('Cross dock', `Are you sure you want to cross dock shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode}? You will not be able to add more pallets to this shipment once completed.`, 'Yes', 'No');
  if (confirmed) {
      const licenseplate_id = $grid.selectedRows[0].entity.Shipment?.LicensePlateShipmentAssociation[0]?.LicensePlateId;
  
      if ($utils.isDefined(licenseplate_id)) {
          const result = await $flows.TransloadOrders.pick_transload_composite_licenseplate_flow({ licenseplate_id: licenseplate_id });
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              await $shell.FootPrintManager.openErrorDialog('Shipment cannot be cross docked.', reason);
  
          }
          else {
              const title = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} cross docked`;
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
              await $grid.refresh();
          }
  
      }
      else {
  
          throw new Error('Unable to determine composite licenseplate for the given outbound shipment.')
      }
  
  }
  }
  on_complete(event = null) {
    return this.on_completeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_completeInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const confirmed = await $shell.FootPrintManager.openConfirmationDialog('Complete', `Are you sure you want to complete shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode}?`, 'Yes', 'No');
  if (confirmed) {
  
      const shipment_id = $grid.selectedRows[0].entity.ShipmentId;
      const order_id = $grid.selectedRows[0].entity.OrderId
  
      if ($utils.isDefined(shipment_id)) {
          const result = await $flows.TransloadOrders.complete_outbound_transload_shipment_flow({
              shipment_id: shipment_id,
              order_id: order_id,
              keep_parent_order_open: true
          });
  
          const reason = result.reason;
          if ($utils.isDefined(reason)) {
              await $shell.FootPrintManager.openErrorDialog('Shipment cannot be completed.', reason);
  
          }
          else {
              const title = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} completed`;
              $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
              await $grid.refresh();
          }
  
      }
      else {
  
          throw new Error('Unable to determine the shipment from the selected row.')
      }
  
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const order_data = $grid.rows;
  
  const orders = order_data.map(o => {
      return {
          orderId: o.entity.OrderId,
          orderStatusId: o.entity.Order?.OrderStatusId,
          orderStatus: o.entity.Order?.Status?.Name,
          shipmentId: o.entity.ShipmentId,
          shipmentStatusId: o.entity.Shipment?.StatusId,
          waveId: o.entity.Shipment?.Wave?.Id,
          waveStatus: o.entity.Shipment?.Wave?.Status?.Name,
          waveStatusId: o.entity.Shipment?.Wave?.StatusId
      }
  });
  
  const order_states = (await $flows.TransloadOrders.get_transload_shipment_state({ orders: orders })).states;
  
  const state_array = order_states.map(row => row.shipmentId);
  
  for (let row of order_data) {
  
      let orderIndex = -1;
      let shipmentId = row.entity.ShipmentId;
      let orderStatusId = row.entity.Order?.OrderStatusId;
  
      let stateId: number = orderStatusId;
      let stateName: string = row.entity.Shipment?.Status?.Name;
  
      orderIndex = state_array.indexOf(shipmentId)
      if (orderIndex > -1) {
          stateId = order_states[orderIndex].stateId;
          stateName = order_states[orderIndex].stateName;
      };
  
      row.vars.stateId = stateId;
      row.cells.state.displayControl.text = stateName;
  
  };
  }
  on_auto_load_clicked(event = null) {
    return this.on_auto_load_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_auto_load_clickedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  const confirmed = await $shell.FootPrintManager.openConfirmationDialog('Auto load', `Are you sure you want to auto load shipment ${$grid.selectedRows[0].entity.Shipment?.LookupCode}?`, 'Yes', 'No');
  if (confirmed) {
  
      const result = await $flows.SalesOrders.is_sales_order_loadable_flow({
          orderId: $grid.selectedRows[0].entity.OrderId,
          shipmentId: $grid.selectedRows[0].entity.ShipmentId
      });
  
      const reason = result.reason;
      if ($utils.isDefined(reason)) {
          await $shell.FootPrintManager.openErrorDialog('Order cannot be loaded.', reason);
          return;
      } else {
  
          var warehouseId = $grid.selectedRows[0].entity.Shipment?.ActualWarehouseId
          if (!$utils.isDefined(warehouseId)) {
  
              $shell.FootPrintManager.openErrorDialog('Shipment Auto Load Error', 'Unable to determine warehouse from the selected shipment.');
              return;
          }
  
  
          const dialogResult = await $shell.FootPrintManager.openauto_load_options_formDialog({
              warehouseId: warehouseId
          });
  
          var userConfirmed = dialogResult.confirm;
          var loadLocation = dialogResult.loadLocation;
  
          if ($utils.isDefined(userConfirmed) && userConfirmed === false) {
  
              return;
          }
          if (userConfirmed) {
              try {
  
                  const result = await $flows.SalesOrders.auto_load_sales_order_flow({
                      orderId: $grid.selectedRows[0].entity.OrderId,
                      shipmentId: $grid.selectedRows[0].entity.ShipmentId,
                      loadLocation: loadLocation
                  });
                  const reason = result.reason;
  
                  if ($utils.isDefined(reason)) {
                      await $shell.FootPrintManager.openErrorDialog('Shipment cannot be fully loaded.', reason);
                      return;
                  }
                  else {
                      const title = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} loaded`;
                      $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
                      await $grid.refresh();
  
                  }
  
              } catch (error) {
                  const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
                  const errorDetail = $utils.isDefined(error?.error?.error) ? error?.error?.error : error;
                  const errorDescription = `Shipment ${$grid.selectedRows[0].entity.Shipment.LookupCode} - ${errorMessage}`;
                  await $shell.FootPrintManager.openErrorDialog('Load shipment error', 'An error occurred during the loading of the shipment', [errorDescription], null, [{ message: errorDescription, detail: errorDetail }]);
              }
  
          }
  
      }
  
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: FootPrintManager_transload_outbound_shipments_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Initialize data
      const shipmentId = $grid.selectedRows[0].entity.ShipmentId;
      const orderId = $grid.selectedRows[0].entity.OrderId;
      const licenseplateId = $grid.selectedRows[0].entity.Shipment?.LicensePlateShipmentAssociation[0]?.LicensePlateId;
  
      // Delete shipment order lookup
      await $flows.Utilities.crud_delete_dual_pk_flow({
          entitySet: 'ShipmentOrderLookup',
          pk1String: 'ShipmentId',
          pk1: shipmentId,
          pk2String: 'OrderId',
          pk2: orderId
      });
  
      // Delete licenseplate shipment association
      await $flows.Utilities.crud_delete_dual_pk_flow({
          entitySet: 'LicensePlatesShipmentsAssociation',
          pk1String: 'ShipmentId',
          pk1: shipmentId,
          pk2String: 'LicensePlateId',
          pk2: licenseplateId
      });
  
      // Delete shipment
      await $flows.Utilities.crud_delete_flow({ entitySet: 'Shipments', id: shipmentId });
  
      // Delete licenseplate
      await $flows.Utilities.crud_delete_flow({ entitySet: 'LicensePlates', id: licenseplateId });
  
      const title = `Shipment ${shipmentId.toString()} deleted`;
      $shell.FootPrintManager.openToaster(title, '', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight });
      await $grid.refresh();
  
      $grid.refresh();
  
  }
  
  catch (error) {
  
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
  
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
