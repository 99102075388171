import { Utilities_Types } from './Utilities.types'

export namespace Notifications_Types {
  /**
   * Describes the composition of the Alert payload.
   */
  export interface Alert {
    alert_id?: string;
    application_name?: string;
    alert_name?: string;
    level?: number;
    enabled?: boolean;
    to?: string;
    cc?: string;
    bcc?: string;
    subject?: string;
    pdf?: boolean;
    created_on?: string;
    created_by?: string;
    modified_on?: string;
    modified_by?: string;
  }
  /**
   * Provides a list of email entity types.
   */
  export enum AutoEmailEntityTypes {
    Order = 'Order',
    Invoice = 'Invoice',
    Project = 'Project'
  }
  /**
   * 
   */
  export interface AutoEmailRule {
    context?: string;
    filters?: string;
    to?: string;
    cc?: string;
    bcc?: string;
    subject?: string;
    body?: string;
    notes?: string;
    last_executed_on?: string;
    frequency?: any;
    projectId?: number;
    is_enabled?: boolean;
    include_attachments?: boolean;
  }
  /**
   * 
   */
  export interface AutoEmailRuleAttachment {
    rule_id: string;
    type: string;
    name: string;
    options?: string;
  }
  /**
   * 
   */
  export enum AutoEmailRuleContexts {
    Schedule = 'Schedule',
    OutboundOrderCompleted = 'Outbound order completed',
    InboundOrderCompleted = 'Inbound order completed',
    InvoiceExported = 'Invoice exported'
  }
  /**
   * 
   */
  export interface AutoEmailSchedule {
    dateOption: string;
    dateInterval: any;
    timeOption: string;
    timeInterval: any;
    timeOffset: number;
  }
  /**
   * 
   */
  export interface EmailRequest {
    rule_id?: string;
    entity_type?: string;
    entity_id?: number;
    status?: string;
    to?: string;
    cc?: string;
    bcc?: string;
    subject?: string;
    body?: string;
    sent_on?: string;
  }
}

export namespace $types {
  export import Utilities =  Utilities_Types;
  export import Notifications = Notifications_Types;
}




