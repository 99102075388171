import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Carriers_delete_carrier_flowService } from './Carriers.flow.index';
import { Carriers_delete_carrierservice_flowService } from './Carriers.flow.index';
import { Carriers_delete_carrierservicetype_flowService } from './Carriers.flow.index';
import { Carriers_delete_ownerscarrierslookup_flowService } from './Carriers.flow.index';
import { Carriers_is_carrier_deletable_flowService } from './Carriers.flow.index';
import { Carriers_is_carrierservice_deletable_flowService } from './Carriers.flow.index';
import { Carriers_is_carrierservicetype_deletable_flowService } from './Carriers.flow.index';
import { Carriers_is_ownerscarrierslookup_deletable_flowService } from './Carriers.flow.index';

import { $types } from './Carriers.types'

@Injectable({ providedIn: 'root' })
export class Carriers_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Carriers: Carriers_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _delete_carrier_flow: Carriers_delete_carrier_flowService;
  public async delete_carrier_flow(inParams: { carrierId: number }): Promise< { reason?: string }> {
    if(!this._delete_carrier_flow) {
      this._delete_carrier_flow = this.injector.get(Carriers_delete_carrier_flowService);
    }
    return this._delete_carrier_flow.run(inParams);
  }
  private _delete_carrierservice_flow: Carriers_delete_carrierservice_flowService;
  public async delete_carrierservice_flow(inParams: { carrierId: number, serviceTypeId: number }): Promise< { reason?: string }> {
    if(!this._delete_carrierservice_flow) {
      this._delete_carrierservice_flow = this.injector.get(Carriers_delete_carrierservice_flowService);
    }
    return this._delete_carrierservice_flow.run(inParams);
  }
  private _delete_carrierservicetype_flow: Carriers_delete_carrierservicetype_flowService;
  public async delete_carrierservicetype_flow(inParams: { carrierservicetypeId: number }): Promise< { reason?: string }> {
    if(!this._delete_carrierservicetype_flow) {
      this._delete_carrierservicetype_flow = this.injector.get(Carriers_delete_carrierservicetype_flowService);
    }
    return this._delete_carrierservicetype_flow.run(inParams);
  }
  private _delete_ownerscarrierslookup_flow: Carriers_delete_ownerscarrierslookup_flowService;
  public async delete_ownerscarrierslookup_flow(inParams: { ownerId: number, carrierId: number }): Promise< { reason?: string }> {
    if(!this._delete_ownerscarrierslookup_flow) {
      this._delete_ownerscarrierslookup_flow = this.injector.get(Carriers_delete_ownerscarrierslookup_flowService);
    }
    return this._delete_ownerscarrierslookup_flow.run(inParams);
  }
  private _is_carrier_deletable_flow: Carriers_is_carrier_deletable_flowService;
  public async is_carrier_deletable_flow(inParams: { carrierId: number }): Promise< { reason?: string }> {
    if(!this._is_carrier_deletable_flow) {
      this._is_carrier_deletable_flow = this.injector.get(Carriers_is_carrier_deletable_flowService);
    }
    return this._is_carrier_deletable_flow.run(inParams);
  }
  private _is_carrierservice_deletable_flow: Carriers_is_carrierservice_deletable_flowService;
  public async is_carrierservice_deletable_flow(inParams: { carrierId: number, serviceTypeId: number }): Promise< { reason?: string }> {
    if(!this._is_carrierservice_deletable_flow) {
      this._is_carrierservice_deletable_flow = this.injector.get(Carriers_is_carrierservice_deletable_flowService);
    }
    return this._is_carrierservice_deletable_flow.run(inParams);
  }
  private _is_carrierservicetype_deletable_flow: Carriers_is_carrierservicetype_deletable_flowService;
  public async is_carrierservicetype_deletable_flow(inParams: { carrierservicetypeId: number }): Promise< { reason?: string }> {
    if(!this._is_carrierservicetype_deletable_flow) {
      this._is_carrierservicetype_deletable_flow = this.injector.get(Carriers_is_carrierservicetype_deletable_flowService);
    }
    return this._is_carrierservicetype_deletable_flow.run(inParams);
  }
  private _is_ownerscarrierslookup_deletable_flow: Carriers_is_ownerscarrierslookup_deletable_flowService;
  public async is_ownerscarrierslookup_deletable_flow(inParams: { ownerId: number, carrierId: number }): Promise< { reason?: string }> {
    if(!this._is_ownerscarrierslookup_deletable_flow) {
      this._is_ownerscarrierslookup_deletable_flow = this.injector.get(Carriers_is_ownerscarrierslookup_deletable_flowService);
    }
    return this._is_ownerscarrierslookup_deletable_flow.run(inParams);
  }
}
