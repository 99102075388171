import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';

@Injectable({ providedIn: 'root'})
export class OdataService {

  constructor() {
  }

  public formatValue(value: any): string {
    if (isNil(value)) {
      return 'null';
    } else if (typeof value === 'string') {
      return `'${this.escapeIllegalChars(value)}'`;
    } else if (value instanceof Date) {
      return value.toISOString();
    } else if (Array.isArray(value)) {
      return `(${value
        .map(v => this.formatValue(v))
        .join(', ')})`;
    }

    return value.toString();
  }

  private escapeIllegalChars(string: string) {
    if (string) {
      string = string.replace(/%/g, '%25');
      string = string.replace(/\+/g, '%2B');
      string = string.replace(/\//g, '%2F');
      string = string.replace(/\?/g, '%3F');
      string = string.replace(/#/g, '%23');
      string = string.replace(/&/g, '%26');
      string = string.replace(/'/g, '\'\'');
    }
    return string;
  }
}



