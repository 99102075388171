import { 
  Component, 
  OnInit,
  OnChanges,
  Input,
  SimpleChanges, 
  EventEmitter,
  Output,
  Inject
} from '@angular/core';

import { FatNumberStyles } from './models/widget';
import { isNil } from 'lodash-es';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Inventory_ShellService, EModalSize, EToasterType, EToasterPosition } from './Inventory.shell.service';
import { Inventory_OperationService } from './Inventory.operation.service';
import { Inventory_DatasourceService } from './Inventory.datasource.index';
import { Inventory_FlowService } from './Inventory.flow.index';
import { Inventory_ReportService } from './Inventory.report.index';
import { Inventory_LocalizationService } from './Inventory.localization.service';
import { Language } from './localization.service';
import { $types } from './Inventory.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Inventory-total_available_amount_widget',
  templateUrl: './Inventory.total_available_amount_widget.component.html'
})
export class Inventory_total_available_amount_widgetComponent implements OnInit, OnChanges {
  //#region Outputs
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  inParams: { ownerId?: number, projectId?: number, lotId?: number, materialIds?: number[], locationId?: number, materialId?: number, warehouseId?: number } = { ownerId: null, projectId: null, lotId: null, materialIds: [], locationId: null, materialId: null, warehouseId: null };
  //#region Inputs
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('lotId') set $inParams_lotId(v: number) {
    this.inParams.lotId = v;
  }
  get $inParams_lotId(): number {
    return this.inParams.lotId;
  }
  @Input('materialIds') set $inParams_materialIds(v: number[]) {
    this.inParams.materialIds = v;
  }
  get $inParams_materialIds(): number[] {
    return this.inParams.materialIds;
  }
  @Input('locationId') set $inParams_locationId(v: number) {
    this.inParams.locationId = v;
  }
  get $inParams_locationId(): number {
    return this.inParams.locationId;
  }
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Variables
  //#endregion  
  private entity: { TotalAvailableAmount?: number };

  value: any;
  styles: FatNumberStyles;
  originalValue: any;
  prefix: string = '';
  suffix: string = '';

  constructor(private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Inventory_ShellService,
private datasources: Inventory_DatasourceService,
private flows: Inventory_FlowService,
private reports: Inventory_ReportService,
private localization: Inventory_LocalizationService,
private operations: Inventory_OperationService,
) { 

    this.styles = new FatNumberStyles();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;
  $hasDataLoaded = false;

  async $init() {

    await this.$dataLoad();

    this.initialized = true;
  }
  
  async $dataLoad() {
    const $widget = this;
    const $utils = this.utils;

    const dsParams = {
      warehouseId:  $widget.inParams.warehouseId ,
      ownerId:  $widget.inParams.ownerId ,
      projectId:  $widget.inParams.projectId ,
      lotId:  $widget.inParams.lotId ,
      locationId:  $widget.inParams.locationId ,
      materialIds:  $widget.inParams.materialIds ,
      materialId:  $widget.inParams.materialId 
    };

    const data = await this.datasources.Inventory.ds_total_available_amount_widget.get(dsParams);
    if (isNil(data.result)) {
      this.$hasDataLoaded = false;
      this.entity = null;
    } else {
      this.$hasDataLoaded = true;
      this.entity = data.result;
      await this.$dataLoaded();
    }
  }

  async $dataLoaded() {
    const $widget = this;
    const $utils = this.utils;

    this.value = this.format($utils.isDefined($widget.entity.TotalAvailableAmount) ? $widget.entity.TotalAvailableAmount : 0, 1);

    
    this.originalValue = $utils.isDefined($widget.entity.TotalAvailableAmount) ? $widget.entity.TotalAvailableAmount : 0;
  }

  format(num: number, digits: number): string {
    // at some point in time we should probably respect the selected culture from the localization service
    return isNil(num)
      ? ''
      : Intl.NumberFormat('en-US', { notation: "compact", maximumFractionDigits: digits}).format(num);
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  getStyleClass() {
    const valString = isNil(this.value) ? '' : this.value.toString();
    const length = (valString).length;
    if (length === 2) {
      return 'tens';
    }
    if (length === 3) {
      return 'hundreds';
    }
    if (length === 4) {
      return 'thousands';
    }
    if (length === 5) {
      return 'tenThousands';
    }
    if (length === 6) {
      return 'hundredThousands';
    }
    if (length > 6) {
      return 'millions';
    }
    return null;
  }

}
