import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Notifications.types'

@Injectable({ providedIn: 'root' })
export class Notifications_get_blob_by_requestAttachmentIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { requestAttachmentId: string }): Promise<{ fileContent?: Blob }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.requestAttachmentId)) {
      missingRequiredInParams.push('\'requestAttachmentId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Notifications/functions/get_blob_by_requestAttachmentId`;
    
    const options = {
    }
    
    const body = inParams;
    
    const blobOptions: any = {
      ...options,
      observe: 'response',
      responseType: 'blob'
    };
    const response = await this.utils.http.post<HttpResponse<Blob>>(url, body, blobOptions);
    const blobParamValue = response.status === 200 ? this.utils.http.httpClientResponseToBlob(response) : null;
    return { fileContent: blobParamValue };
    
  }

}

