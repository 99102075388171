import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './FootPrintApiManager.types'

@Injectable({ providedIn: 'root' })
export class FootPrintApiManager_purchase_order_createService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { owner?: string, project?: string, ownerReference?: string, vendorReference?: string, requestedDeliveryDate?: string, notes?: string, orderLines?: $types.FootPrintApiManager.Cloud_OrderLine[], warehouse?: string, addresses?: $types.FootPrintApiManager.Cloud_OrderAddress[], customFields?: any, carrier?: string, carrierServiceType?: string }): Promise<{ orderId?: number }> 
  {
    let url = `${environment.backendUrl}api/FootPrintApiManager/functions/purchase_order_create`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

