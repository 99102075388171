import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Reports_custom_ds_commercial_invoice_report_linesService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId?: number }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderLine?: { PackagedId?: number, Price?: number, TotalPrice?: number, Material?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, LookupCode?: string } } }, materialPackaging?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, UpcCode?: string, Weight?: number }[], totalWeight?: number }[] }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_commercial_invoice_report_lines/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderLine?: { PackagedId?: number, Price?: number, TotalPrice?: number, Material?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, LookupCode?: string } } }, materialPackaging?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, UpcCode?: string, Weight?: number }[], totalWeight?: number }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_commercial_invoice_report_lines/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId?: number, $keys: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number }[] }): 
  Promise<{ result: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderLine?: { PackagedId?: number, Price?: number, TotalPrice?: number, Material?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, LookupCode?: string } } }, materialPackaging?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, UpcCode?: string, Weight?: number }[], totalWeight?: number }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Reports/datasources/custom_ds_commercial_invoice_report_lines/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
