import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { TemperatureReadings_shipment_lines_singleComponent } from './TemperatureReadings.shipment_lines_single.component';
import { TemperatureReadings_temperature_measurement_units_singleComponent } from './TemperatureReadings.temperature_measurement_units_single.component';
import { TemperatureReadings_temperature_reading_points_singleComponent } from './TemperatureReadings.temperature_reading_points_single.component';
import { TemperatureReadings_temperature_reading_types_singleComponent } from './TemperatureReadings.temperature_reading_types_single.component';
import { TemperatureReadings_shipment_lines_multiComponent } from './TemperatureReadings.shipment_lines_multi.component';
import { TemperatureReadings_temperature_measurement_units_multiComponent } from './TemperatureReadings.temperature_measurement_units_multi.component';
import { TemperatureReadings_temperature_reading_points_multiComponent } from './TemperatureReadings.temperature_reading_points_multi.component';
import { TemperatureReadings_temperature_reading_types_multiComponent } from './TemperatureReadings.temperature_reading_types_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class TemperatureReadings_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public TemperatureReadings: TemperatureReadings_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'TemperatureReadings_shipment_lines_single') {
      const title = 'Shipment lines';
      const component = TemperatureReadings_shipment_lines_singleComponent;
      const inParams:{ shipmentId?: number } = { shipmentId: null };
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_temperature_measurement_units_single') {
      const title = 'Temperature measurement units';
      const component = TemperatureReadings_temperature_measurement_units_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_temperature_reading_points_single') {
      const title = 'Temperature reading points';
      const component = TemperatureReadings_temperature_reading_points_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_temperature_reading_types_single') {
      const title = 'Temperature reading types';
      const component = TemperatureReadings_temperature_reading_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_shipment_lines_multi') {
      const title = 'Shipment lines';
      const component = TemperatureReadings_shipment_lines_multiComponent;
      const inParams:{ shipmentId?: number } = { shipmentId: null };
      if (!isNil(params.get('shipmentId'))) {
        const paramValueString = params.get('shipmentId');
        inParams.shipmentId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_temperature_measurement_units_multi') {
      const title = 'Temperature measurement units';
      const component = TemperatureReadings_temperature_measurement_units_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_temperature_reading_points_multi') {
      const title = 'Temperature reading points';
      const component = TemperatureReadings_temperature_reading_points_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'TemperatureReadings_temperature_reading_types_multi') {
      const title = 'Temperature reading types';
      const component = TemperatureReadings_temperature_reading_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
