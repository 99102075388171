<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-new_transaction" *ngIf="!topToolbar.new_transaction.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.new_transaction.control.readOnly"
            [ngStyle]="topToolbar.new_transaction.control.styles.style"
            [ngClass]="topToolbar.new_transaction.control.styles.classes"
            (click)="on_create_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.new_transaction.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.new_transaction.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.new_transaction.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-Project" *ngIf="!filters.Project.hidden" 
            class="field-container standard {{filters.Project.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.Project.styles.style"
            [ngClass]="filters.Project.styles.classes">
        <div class="label-container"
              title="{{filters.Project.label}}{{filters.Project.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.Project.label}}">{{filters.Project.label}}<span *ngIf="filters.Project.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Owners-projects_dd_single 
            data-cy="selector"
            [type]="filters.Project.control.type"
            formControlName="Project"
            (displayTextChange)="filters.Project.control.displayText=$event"
            [placeholder]="filters.Project.control.placeholder"
            [styles]="filters.Project.control.styles"
          [statusId]="$fields_Project_selector_inParams_statusId"
        >
        </Owners-projects_dd_single>
        <ng-container *ngIf="filters.Project.invalid">
          <ng-container *ngFor="let error of filters.Project.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-PalletClass" *ngIf="!filters.PalletClass.hidden" 
            class="field-container standard {{filters.PalletClass.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.PalletClass.styles.style"
            [ngClass]="filters.PalletClass.styles.classes">
        <div class="label-container"
              title="{{filters.PalletClass.label}}{{filters.PalletClass.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.PalletClass.label}}">{{filters.PalletClass.label}}<span *ngIf="filters.PalletClass.required"
                  class="required-asterisk">*</span></label>
        </div>
        <FootPrintManager-pallet_classes_dd_single 
            data-cy="selector"
            [type]="filters.PalletClass.control.type"
            formControlName="PalletClass"
            (displayTextChange)="filters.PalletClass.control.displayText=$event"
            [placeholder]="filters.PalletClass.control.placeholder"
            [styles]="filters.PalletClass.control.styles"
          [project_id]="$fields_PalletClass_selector_inParams_project_id"
        >
        </FootPrintManager-pallet_classes_dd_single>
        <ng-container *ngIf="filters.PalletClass.invalid">
          <ng-container *ngFor="let error of filters.PalletClass.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Project.displayControl.styles.style"
          [ngClass]="row.cells.Project.displayControl.styles.classes">{{row.cells.Project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PalletClass">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.PalletClass.displayControl.styles.style"
          [ngClass]="row.cells.PalletClass.displayControl.styles.classes">{{row.cells.PalletClass.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Amount.displayControl.styles.style"
          [ngClass]="row.cells.Amount.displayControl.styles.classes">{{row.cells.Amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
