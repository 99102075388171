import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Waves.types'

@Injectable({ providedIn: 'root' })
export class Waves_associate_order_to_wave_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { waveId?: number, shipmentIds?: number[] }): Promise<{ reason?: string }> 
  {
    let url = `${environment.backendUrl}api/Waves/functions/associate_order_to_wave_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

