import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { EasyPost_ShellService, EModalSize, EToasterType, EToasterPosition } from './EasyPost.shell.service';
import { EasyPost_OperationService } from './EasyPost.operation.service';
import { EasyPost_DatasourceService } from './EasyPost.datasource.index';
import { EasyPost_FlowService } from './EasyPost.flow.index';
import { EasyPost_ReportService } from './EasyPost.report.index';
import { EasyPost_LocalizationService } from './EasyPost.localization.service';
import { Language } from './localization.service';
import { $types } from './EasyPost.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'EasyPost-easypost_tester_form',
  templateUrl: './EasyPost.easypost_tester_form.component.html'
})
export class EasyPost_easypost_tester_formComponent implements OnInit, OnDestroy {


  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    easypost_key: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    carrier_account: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    carrier_service: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    label_size: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    label_format: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    print_custom_one: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    contents_explanation: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      one_call_buy_shipment: new ToolModel(new ButtonModel('one_call_buy_shipment', new ButtonStyles(null, null), false, 'Manifest One Call Buy', 'ms-Icon ms-Icon--RecordRouting')
    ),
      retrieve_tracker_status: new ToolModel(new ButtonModel('retrieve_tracker_status', new ButtonStyles(null, null), false, 'Retrieve Tracker Status', 'ms-Icon ms-Icon--WebSearch')
    )
  };

  fields = {
    easypost_key: new FieldModel(new TextBoxModel(this.formGroup.controls['easypost_key'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'easypost Key', false)
,
    carrier_account: new FieldModel(new TextBoxModel(this.formGroup.controls['carrier_account'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Carrier Account', true)
,
    carrier_service: new FieldModel(new TextBoxModel(this.formGroup.controls['carrier_service'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Service', true)
,
    label_size: new FieldModel(new TextBoxModel(this.formGroup.controls['label_size'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Label Size', false)
,
    label_format: new FieldModel(new TextBoxModel(this.formGroup.controls['label_format'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Label Format', true)
,
    print_custom_one: new FieldModel(new TextBoxModel(this.formGroup.controls['print_custom_one'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Print Custom One', false)
,
    contents_explanation: new FieldModel(new TextBoxModel(this.formGroup.controls['contents_explanation'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Contents Explanation', false)
,
    tracker_status: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost TrackerStatus', false)
,
    easypost_tracking_code: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost TrackingCode', false)
,
    easypost_trackerId: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost TrackerId', false)
,
    easypost_shipmentId: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost ShipmentId', false)
,
    easypost_parcel_weight: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost ParcelWeight', false)
,
    easypost_parcel_length: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost ParcelLength', false)
,
    easypost_parcel_width: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost ParcelWidth', false)
,
    easypost_parcel_height: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost ParcelHeight', false)
,
    easypost_shipment_creation_date: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost ShipmentCreationDate', false)
,
    easypost_selected_rate: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost SelectedRate', false)
,
    easypost_selected_rate_delivery_date: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost SelectedRateDeliveryDate', false)
,
    open_postage_label_url: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Label URL', 'ms-Icon ms-Icon--World')
, new ControlContainerStyles(null, null), '', false)
,
    easypost_postage_label_url: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost PostageLabelUrl', false)
,
    open_tracker_public_url: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Tracker Url', 'ms-Icon ms-Icon--World')
, new ControlContainerStyles(null, null), '', false)
,
    easypost_tracker_public_url: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost TrackerPublicUrl', false)
,
    open_commercial_invoice_url: new FieldModel(new ButtonModel('', new ButtonStyles(null, null), false, 'Commercial Invoice URL', 'ms-Icon ms-Icon--World')
, new ControlContainerStyles(null, null), '', false)
,
    easypost_commercial_invoice_url: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'easypost CommercialInvoiceURL', false)
,
    output_details: new FieldModel(new TextModel(null, null )
, new ControlContainerStyles(null, null), 'outputDetails', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Inputs', false, true, true),
  newGroup2: new FieldsetModel('Response', false, true, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    easypost_key: this.fields.easypost_key.control.valueChanges
    ,
    carrier_account: this.fields.carrier_account.control.valueChanges
    ,
    carrier_service: this.fields.carrier_service.control.valueChanges
    ,
    label_size: this.fields.label_size.control.valueChanges
    ,
    label_format: this.fields.label_format.control.valueChanges
    ,
    print_custom_one: this.fields.print_custom_one.control.valueChanges
    ,
    contents_explanation: this.fields.contents_explanation.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: EasyPost_ShellService,
private datasources: EasyPost_DatasourceService,
private flows: EasyPost_FlowService,
private reports: EasyPost_ReportService,
private localization: EasyPost_LocalizationService,
private operations: EasyPost_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Test harness';
  
    const $form = this;
    const $utils = this.utils;

    
    (this.fields.carrier_account.control as TextBoxModel).reset('ca_03dd529135584a87bcee58fefc8b7494');
    (this.fields.carrier_service.control as TextBoxModel).reset('EconomySelect');
    (this.fields.label_size.control as TextBoxModel).reset('4X6');
    (this.fields.label_format.control as TextBoxModel).reset('PDF');
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .easypost_key
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier_account
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .carrier_service
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .label_size
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .label_format
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .print_custom_one
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .contents_explanation
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_one_call_buy_shipment_button_clicked(event = null) {
    return this.on_one_call_buy_shipment_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_one_call_buy_shipment_button_clickedInternal(
    $form: EasyPost_easypost_tester_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.carrier_account.control.value,
      $form.fields.carrier_service.control.value,
      $form.fields.label_format
  );
  
  if (!allRequiredFieldHaveValue) {
      $shell.EasyPost.openErrorDialog('Manifest One Call Error', 'Please enter in data in all required fields.');
      return;
  }
  var parcel: any[] = [];
  for (let i = 0; i < 2; i++) {
      parcel.push(
          {
              "length": "1",
              "width": "1",
              "height": "1",
              "weight": "2"
          }
      )
  }
  
  console.log(parcel);
  const fromAddress = {
      "name": "Partners In Logistics",
      "street1": "103 Shannon Free Zone East",
      "city": "Shannon",
      "zip": "V14 PH21",
      "country": "IE",
      "phone": "4153334445",
      "email": "support@easypost.com"
  }
  const toAddress = {
      "name": "Partners In Logistics",
      "street1": "3 Banner Park",
      "city": "Coventry",
      "zip": "CV4 9XA",
      "country": "GB",
      "phone": "8573875756",
      "email": "dr_steve_brule@gmail.com"
  }
  
  let thirdPartyAddress = fromAddress;
  thirdPartyAddress.phone = '5555555555';
  
  const customsItems = 
  [
      {
          "description": "iPhone 11 case",
          "quantity": "1",
          "weight": "1", // If no value, throw error?
          "value": "10", // If no value, throw error?
          "hs_tariff_number": '3926909790', // Commodity Code
          "origin_country": fromAddress.country // Country of Origin
      },
      {
          "description": "iPhone 13 case",
          "quantity": "2",
          "weight": "2",
          "value": "30",
          "hs_tariff_number": '3926909790',
          "origin_country": fromAddress.country
      }
  ]
  
  
  const flowEasyPostParams = {
      carrierAccount: $form.fields.carrier_account.control.value,
      service: $form.fields.carrier_service.control.value,
      parcels: parcel,
      fromAddress: fromAddress,
      toAddress: toAddress,
      labelSize: $form.fields.label_size.control.value,
      labelFormat: $form.fields.label_format.control.value,
      printCustomOne: $form.fields.print_custom_one.control.value,
      contentsExplanation: $form.fields.contents_explanation.control.value,
      customsItems: customsItems,
      thirdPartyAddress: thirdPartyAddress
  };
  
  const easypostResponse = await $flows.EasyPost.one_call_buy_shipment_request_flow(flowEasyPostParams);
  
  $form.fields.easypost_shipmentId.control.text = easypostResponse.easyPostShipmentId;
  $form.fields.easypost_trackerId.control.text = easypostResponse.easyPostTrackerId;
  $form.fields.easypost_tracker_public_url.control.text = easypostResponse.easyPostTrackerPublicUrl;
  $form.fields.easypost_tracking_code.control.text = easypostResponse.easyPostTrackingCode;
  $form.fields.easypost_parcel_weight.control.text = easypostResponse.easyPostParcelWeight.toString();
  $form.fields.easypost_parcel_length.control.text = easypostResponse.easyPostParcelLength.toString();
  $form.fields.easypost_parcel_width.control.text = easypostResponse.easyPostParcelWidth.toString();
  $form.fields.easypost_parcel_height.control.text = easypostResponse.easyPostParcelHeight.toString();
  $form.fields.easypost_shipment_creation_date.control.text = easypostResponse.easyPostShipmentCreationDate;
  $form.fields.easypost_selected_rate.control.text = easypostResponse.easyPostSelectedRate.toString();
  $form.fields.easypost_selected_rate_delivery_date.control.text = easypostResponse.easyPostSelectedRateDeliveryDate;
  $form.fields.easypost_postage_label_url.control.text = easypostResponse.easyPostPostageLabelUrl[0];
  $form.fields.easypost_commercial_invoice_url.control.text = easypostResponse.easyPostCommercialInvoiceUrl;
  $form.fields.output_details.control.text = easypostResponse.outputDetails;
  }
  on_easypost_tracker_public_url_clicked(event = null) {
    return this.on_easypost_tracker_public_url_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_easypost_tracker_public_url_clickedInternal(
    $form: EasyPost_easypost_tester_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.easypost_tracker_public_url.control.text
  );
  
  if (!allRequiredFieldHaveValue) {
      $shell.EasyPost.openErrorDialog('Tracker Url Error', 'easypost tracker url missing.');
      return;
  }
  
  
  const trackerUrl =  $form.fields.easypost_tracker_public_url.control.text;
  
  //window.open(trackerUrl, '_blank').focus();
  
  
  window.open(trackerUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
  on_easypost_postage_label_url_clicked(event = null) {
    return this.on_easypost_postage_label_url_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_easypost_postage_label_url_clickedInternal(
    $form: EasyPost_easypost_tester_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.easypost_postage_label_url.control.text
     
  );
  
  if (!allRequiredFieldHaveValue) {
      $shell.EasyPost.openErrorDialog('Label Url Error', 'easypost label url missing.');
      return;
  }
  
  
  
  const labelUrl =  $form.fields.easypost_postage_label_url.control.text;
  
  //window.open(labelUrl, '_blank').focus();
  
  window.open(labelUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
  on_retrieve_tracker_status_button_clicked(event = null) {
    return this.on_retrieve_tracker_status_button_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_retrieve_tracker_status_button_clickedInternal(
    $form: EasyPost_easypost_tester_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.easypost_trackerId.control.text
  );
  
  if (!allRequiredFieldHaveValue) {
      $shell.EasyPost.openErrorDialog('Retrieve Tracker Status Error', 'easypost Tracker Id missing.');
      return;
  }
  
  
  
  
  
  const flowEasyPostParams = {
      easyPostTrackerId: $form.fields.easypost_trackerId.control.text
  };
  
  const easypostResponse = await $flows.EasyPost.retrieve_tracker_status_request_flow(flowEasyPostParams);
  
  $form.fields.tracker_status.control.text = easypostResponse.status;
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: EasyPost_easypost_tester_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
  
  
  $form.fields.easypost_key.control.value = $settings.EasyPost.EasyPostKey;
  }
  on_easypost_commercial_invoice_url_clicked(event = null) {
    return this.on_easypost_commercial_invoice_url_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_easypost_commercial_invoice_url_clickedInternal(
    $form: EasyPost_easypost_tester_formComponent,
  
    $shell: EasyPost_ShellService,
    $datasources: EasyPost_DatasourceService,
    $flows: EasyPost_FlowService,
    $reports: EasyPost_ReportService,
    $settings: SettingsValuesService,
    $operations: EasyPost_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: EasyPost_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.easypost_commercial_invoice_url.control.text
  );
  
  if (!allRequiredFieldHaveValue) {
      $shell.EasyPost.openErrorDialog('Invoice Url Error', 'easypost commercial invoice url missing.');
      return;
  }
  
  const labelUrl =  $form.fields.easypost_postage_label_url.control.text;
  
  //window.open(labelUrl, '_blank').focus();
  
  window.open(labelUrl, '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
  }
  //#endregion private flows
}
