import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $types } from './app.types'
import { Lots_vendorlots_dd_singleComponent } from './Lots.vendorlots_dd_single.component'
import { app_custom_inbound_procedure_codes_singleComponent } from './app.custom_inbound_procedure_codes_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Lots_vendorlots_dd_singleComponent),
    forwardRef(() => app_custom_inbound_procedure_codes_singleComponent),
  ],
  selector: 'app-custom_lot_creation_form',
  templateUrl: './app.custom_lot_creation_form.component.html'
})
export class app_custom_lot_creation_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { materialId: number, lotLookupCode?: string, vendorLotLookupCode?: string, vendorLotId?: number } = { materialId: null, lotLookupCode: null, vendorLotLookupCode: null, vendorLotId: null };
  //#region Inputs
  @Input('materialId') set $inParams_materialId(v: number) {
    this.inParams.materialId = v;
  }
  get $inParams_materialId(): number {
    return this.inParams.materialId;
  }
  @Input('lotLookupCode') set $inParams_lotLookupCode(v: string) {
    this.inParams.lotLookupCode = v;
  }
  get $inParams_lotLookupCode(): string {
    return this.inParams.lotLookupCode;
  }
  @Input('vendorLotLookupCode') set $inParams_vendorLotLookupCode(v: string) {
    this.inParams.vendorLotLookupCode = v;
  }
  get $inParams_vendorLotLookupCode(): string {
    return this.inParams.vendorLotLookupCode;
  }
  @Input('vendorLotId') set $inParams_vendorLotId(v: number) {
    this.inParams.vendorLotId = v;
  }
  get $inParams_vendorLotId(): number {
    return this.inParams.vendorLotId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { lotId?: number, confirm?: boolean } = { lotId: null, confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number, shelfLife?: number, isManufactureManuallySet?: boolean, isExpirationManuallySet?: boolean } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ lotId?: number, confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    create_new_vendorlot: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendor_lot_lookup: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vendor_lot: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    manufacture_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    expiration_date: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    notes: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    vat: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    procedure_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    lot: new FieldModel(new TextBoxModel(this.formGroup.controls['lot'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lot', true)
,
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Description', false)
,
    create_new_vendorlot: new FieldModel(new CheckBoxModel(this.formGroup.controls['create_new_vendorlot'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'New Vendor Lot', false)
,
    vendor_lot_lookup: new FieldModel(new TextBoxModel(this.formGroup.controls['vendor_lot_lookup'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vendor Lot', false)
,
    vendor_lot: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['vendor_lot'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Vendor Lot', true)
,
    manufacture_date: new FieldModel(new DateBoxModel(this.formGroup.controls['manufacture_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Manufacture Date', true)
,
    expiration_date: new FieldModel(new DateBoxModel(this.formGroup.controls['expiration_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'Expiration Date', true)
,
    notes: new FieldModel(new TextBoxModel(this.formGroup.controls['notes'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Notes', false)
,
    vat: new FieldModel(new TextBoxModel(this.formGroup.controls['vat'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vat', false)
,
    procedure_code: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['procedure_code'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Procedure Code', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  get $fields_vendor_lot_selector_inParams_materialId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.materialId;
    
    return expr;
  }

  get $fields_vendor_lot_selector_inParams_vendorLotLookupCode(): string {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.vendorLotLookupCode;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    lot: this.fields.lot.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    create_new_vendorlot: this.fields.create_new_vendorlot.control.valueChanges
    ,
    vendor_lot_lookup: this.fields.vendor_lot_lookup.control.valueChanges
    ,
    vendor_lot: this.fields.vendor_lot.control.valueChanges
    ,
    manufacture_date: this.fields.manufacture_date.control.valueChanges
    ,
    expiration_date: this.fields.expiration_date.control.valueChanges
    ,
    notes: this.fields.notes.control.valueChanges
    ,
    vat: this.fields.vat.control.valueChanges
    ,
    procedure_code: this.fields.procedure_code.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.materialId)) {
        this.$missingRequiredInParams.push('materialId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Create Lot';
  
    const $form = this;
    const $utils = this.utils;

    (this.fields.lot.control as TextBoxModel).reset($form.inParams.lotLookupCode);
    
    (this.fields.vendor_lot_lookup.control as TextBoxModel).reset($form.inParams.vendorLotLookupCode);
    (this.fields.manufacture_date.control as DateBoxModel).reset($utils.date.now());
    (this.fields.expiration_date.control as DateBoxModel).reset($utils.date.now());
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .lot
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .create_new_vendorlot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_create_new_vendorlot_clicked();
      });
    this.$formGroupFieldValidationObservables
      .vendor_lot_lookup
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vendor_lot
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
    this.$formGroupFieldValidationObservables
      .manufacture_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_manufacture_date_changed();
      });
    this.$formGroupFieldValidationObservables
      .expiration_date
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_expiration_date_changed();
      });
    this.$formGroupFieldValidationObservables
      .notes
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .vat
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .procedure_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.lotId = null;
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.lot.control.value,
      $form.fields.manufacture_date.control.value,
      $form.fields.expiration_date.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.Lots.openErrorDialog('Lot Creation Error', 'Please enter data in all required fields.');
      return;
  }
  
  const materialId = $form.inParams.materialId
  
  
  if (!$utils.isDefinedTrimmed($form.fields.lot.control.value)) {
      $form.toolbar.confirm.control.readOnly = false;
      let message = 'Please enter a valid value for the lot lookup code.';
  
      throw new Error(message);
  }
  
  // Default vendor lot field to lot field if null
  if ($utils.isDefined($form.fields.vendor_lot.control.value)) {
      if (!$utils.isDefinedTrimmed($form.fields.vendor_lot.control.value)) {
  
          $form.toolbar.confirm.control.readOnly = false;
          let message = 'Please enter a valid value for the vendor lot lookup code.';
  
          throw new Error(message);
      }
  
      var vendorLotId = $form.fields.vendor_lot.control.value;
      var vendorLotLookup = $form.fields.vendor_lot.control.displayText;
  }
  else {
  
      if ($utils.isDefined($form.fields.vendor_lot_lookup.control.value)) {
          vendorLotLookup = $form.fields.vendor_lot_lookup.control.value;
      }
      else {
          vendorLotLookup = $form.fields.lot.control.value;
      }
  
  
  }
  
  // Check if lot exists
  const Lot = (await $datasources.Lots.ds_get_lot_by_lookupcode_materialId_and_vendor_lookupcode.get({
      materialId: $form.inParams.materialId,
      vendorLookupcode: vendorLotLookup,
      lotLookupcode: $form.fields.lot.control.value
  })).result;
  if ($utils.isDefined(Lot)) {
      $form.toolbar.confirm.control.readOnly = false;
      throw new Error('Lot already exists for selected material!');
  }
  
  // Check if vendor lot exists
  const vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({
      materialId: $form.inParams.materialId,
      vendorLookupcode: vendorLotLookup
  })).result;
  if ($utils.isDefined(vendorLot)) {
      var foundVendorLotId = vendorLot[0].Id
      vendorLotId = foundVendorLotId
  }
  
  else {
  
      const vendorlot = (await $flows.Lots.create_vendor_lot({
  
          materialId: materialId,
          lookupCode: vendorLotLookup,
          manufactureDate: $form.fields.manufacture_date.control.value,
          expirationDate: $form.fields.expiration_date.control.value
      }));
  
      vendorLotId = vendorlot.vendorLotId;
  
  
      // Update newly created vendor lot with vat value
      if ($utils.isDefined(vendorLotId)) {
          // Update other vendorlot properties
          let payload: any = {};
  
          // VAT
          if ($utils.isDefined($form.fields.vat.control.value)) {
              payload.Vat = $form.fields.vat.control.value;
          }
  
          // Notes
          if ($utils.isDefined($form.fields.notes.control.value)) {
              payload.Notes = $form.fields.notes.control.value;
          }
  
          // Description
          if ($utils.isDefined($form.fields.description.control.value)) {
              payload.Description = $form.fields.description.control.value;
          }
  
          if (Object.keys(payload).length > 0) {
              try {
                  await $flows.Utilities.crud_update_flow({ entitySet: 'VendorLots', id: vendorLotId, entity: payload });
              }
              catch (error) {
                  $shell.Lots.showErrorDetails('Save', 'Error on updating newly created vendor lot.', error);
                  throw error; // to prevent displayMode 
              }
          }
      }
  
  
  
  
  }
  
  const lot = (await $flows.Lots.create_lot({
  
      lookupCode: $form.fields.lot.control.value,
      vendorLotId: vendorLotId,
      statusId: 1
  }));
  
  var nextLotId = lot.lotId;
  
  let lotpayload: any = {};
  // ProcedureCode
  if ($utils.isDefined($form.fields.procedure_code.control.value)) {
      lotpayload.ProcedureCode = $form.fields.procedure_code.control.value;
  }
  try {
      await $flows.Utilities.crud_update_flow({ entitySet: 'Lots', id: nextLotId, entity: lotpayload });
  }
  catch (error) {
      $shell.Lots.showErrorDetails('Save', 'Error on updating newly created lot.', error);
      throw error; // to prevent displayMode 
  }
  
  
  
  $form.outParams.lotId = nextLotId;
  $form.outParams.confirm = true;
  
  
  $form.close();
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  $form.vars.shelfLife = (await $datasources.Lots.ds_get_material_by_materialId.get({ materialId: $form.inParams.materialId })).result?.ShelfLifeSpan ?? 0;
  
  // If the input lot lookupcode is not provided default to the next Id
  let result = (await $flows.Lots.recommend_lot_creation({
      lotLookupCode: $form.inParams.lotLookupCode,
      vendorLotId: $form.inParams.vendorLotId,
      vendorLotLookupCode: $form.inParams.vendorLotLookupCode,
      recommendVendorLotOnly: false,
      materialId: $form.inParams.materialId,
      optimalInputs: {
          shelfLifeSpan: $form.vars.shelfLife
      }
  }));
  
  if ($utils.isDefined(result.lotId)) {
      $form.vars.nextId = result.lotId;
  }
  else {
        $form.vars.nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'Lot' })).nextId;
  }
  
  $form.fields.lot.control.value = result.lotLookupCode ?? $form.vars.nextId.toString();
  $form.fields.vendor_lot.control.value = result.vendorLotId;
  $form.fields.vendor_lot_lookup.control.value = result.vendorLotLookupCode ?? $form.fields.lot.control.value;
  
  
  // Set field values
  if ($utils.isDefined($form.fields.vendor_lot.control.value)) {
      // Lock Down Fields
      $form.fields.manufacture_date.control.readOnly = true;
      $form.fields.expiration_date.control.readOnly = true;
      $form.fields.vat.control.readOnly = true;
      $form.fields.notes.control.readOnly = true;
      $form.fields.description.control.readOnly = true;
  
      // Populate Values
      let vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({ vendorLotId: $form.fields.vendor_lot.control.value })).result;
  
      $form.fields.vat.control.value = result.vat;
      $form.fields.description.control.value = vendorLot?.Description;
      $form.fields.notes.control.value = vendorLot?.Notes;
  
      $form.fields.create_new_vendorlot.control.value = false;
      $form.fields.vendor_lot_lookup.hidden = true;
      $form.fields.vendor_lot.hidden = false;
  }
  else {
      $form.fields.create_new_vendorlot.control.value = true;
      $form.fields.vendor_lot_lookup.hidden = false;
      $form.fields.vendor_lot.hidden = true;
  }
  
  $form.fields.manufacture_date.control.value = $utils.isDefined(result.manufactureDate) ? $utils.date.format(result.manufactureDate) : $utils.date.now();
  $form.fields.expiration_date.control.value = $utils.isDefined(result.expirationDate) ? $utils.date.format(result.expirationDate) : $utils.date.add($form.vars.shelfLife, 'day', $utils.date.now());
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.fields.vendor_lot.control.value)) {
      let vendorLot = (await $datasources.Lots.ds_get_vendorlot_by_vendorlotId.get({ vendorLotId: $form.fields.vendor_lot.control.value })).result;
  
      // Set form fields for selected vendor lot
      $form.fields.manufacture_date.control.value = vendorLot.ManufactureDate;
      $form.fields.expiration_date.control.value = vendorLot.ExpirationDate;
      $form.fields.vat.control.value = vendorLot.Vat;
      $form.fields.notes.control.value = vendorLot.Notes;
      $form.fields.description.control.value = vendorLot.Description;
  
      // Lock Down Fields
      $form.fields.manufacture_date.control.readOnly = true;
      $form.fields.expiration_date.control.readOnly = true;
      $form.fields.vat.control.readOnly = true;
      $form.fields.notes.control.readOnly = true;
      $form.fields.description.control.readOnly = true;
  }
  else {
      $form.fields.vat.control.value = null;
      $form.fields.manufacture_date.control.value = null;
      $form.fields.expiration_date.control.value = null;
      $form.fields.description.control.value = null;
      $form.fields.notes.control.value = null;
  
      // Unlock Fields
      $form.fields.manufacture_date.control.readOnly = false;
      $form.fields.expiration_date.control.readOnly = false;
      $form.fields.vat.control.readOnly = false;
      $form.fields.notes.control.readOnly = false;
      $form.fields.description.control.readOnly = false;
  }
  }
  on_expiration_date_changed(event = null) {
    return this.on_expiration_date_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_expiration_date_changedInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // ExpirationDate Changed
  if ($form.fields.expiration_date.control.isChanged) {
      $form.vars.isExpirationManuallySet = true;
  
      // Auto set ManufactureDate to ExpirationDate - Material.ShelfLife (if Manufacture date has not manually been set)
      if (!$form.vars.isManufactureManuallySet) {
          let newDate = $utils.date.subtract($form.vars.shelfLife, 'day', $form.fields.expiration_date.control.value);
          
          $form.fields.manufacture_date.control.value = $utils.date.format(newDate);
      }
  }
  }
  on_manufacture_date_changed(event = null) {
    return this.on_manufacture_date_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_manufacture_date_changedInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  // ManufactureDate Changed
  if ($form.fields.manufacture_date.control.isChanged) {
      $form.vars.isManufactureManuallySet = true;
  
      // Auto set ExpirationDate to ManufactureDate + Material.ShelfLife (if Expiration date has not manually been set)
      if (!$form.vars.isExpirationManuallySet) {
          let newDate = $utils.date.add($form.vars.shelfLife, 'day', $form.fields.manufacture_date.control.value);
  
          $form.fields.expiration_date.control.value = $utils.date.format(newDate);
      }
  }
  
  }
  on_create_new_vendorlot_clicked(event = null) {
    return this.on_create_new_vendorlot_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_create_new_vendorlot_clickedInternal(
    $form: app_custom_lot_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($form.fields.create_new_vendorlot.control.value)) {
  
      if ($form.fields.create_new_vendorlot.control.value == true) {
  
          $form.fields.vendor_lot_lookup.hidden = false;
          $form.fields.vendor_lot.hidden = true;
  
          $form.fields.manufacture_date.control.readOnly = false;
          $form.fields.expiration_date.control.readOnly = false;
          $form.fields.description.control.readOnly = false;
          $form.fields.vat.control.readOnly = false;
          $form.fields.notes.control.readOnly = false;
      }
      else {
  
          $form.fields.vendor_lot_lookup.hidden = true;
          $form.fields.vendor_lot.hidden = false;
  
          $form.fields.manufacture_date.control.readOnly = true;
          $form.fields.expiration_date.control.readOnly = true;
          $form.fields.description.control.readOnly = true;
          $form.fields.vat.control.readOnly = true;
          $form.fields.notes.control.readOnly = true;
      }
  
  
  
  }
  
  
  
  }
  //#endregion private flows
}
