import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService, EModalSize, EToasterType, EToasterPosition } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { $types } from './Notifications.types'


import { Notifications_email_request_attachments_gridComponent } from './Notifications.email_request_attachments_grid.component';

interface INotifications_email_requests_gridComponentEntity {
id?: string, rule_id?: string, entity_type?: string, entity_id?: number, status?: string, to?: string, cc?: string, bcc?: string, subject?: string, body?: string, created_on?: string, sent_on?: string}

interface INotifications_email_requests_gridComponentInParams {
  entityType?: string, entityId?: number, ruleId?: string, projectId?: number, context?: string}


class Notifications_email_requests_gridComponentRowModel extends GridRowModel {
  grid: Notifications_email_requests_gridComponent;
  entity: INotifications_email_requests_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    rule_id: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    entity_type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    to: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    cc: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    bcc: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    subject: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    body: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    created_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    sent_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }


  //#region rowExpand inParams


  get $rowExpand_Notifications_email_request_attachments_grid_inParams_requestId(): string {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity.id;
    
    return expr;
  }
  //#endregion rowExpand inParams

  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: Notifications_email_requests_gridComponent, entity: INotifications_email_requests_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Notifications_email_requests_gridComponent, entity?: INotifications_email_requests_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      entityType:  $grid.inParams.entityType ,
      entityId:  $grid.inParams.entityId ,
      ruleId:  $grid.inParams.ruleId ,
      projectId:  $grid.inParams.projectId ,
      context:  $grid.inParams.context ,
      unsent_only:  $grid.filters.unsent_only.control.value ,
    };
    const data = await this.datasources.Notifications.ds_email_requests_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.rule_id.displayControl as TextModel).text = $row.entity.rule_id;
    (this.cells.entity_type.displayControl as TextModel).text = $row.entity.entity_type;
    (this.cells.status.displayControl as TextModel).text = $row.entity.status;
    (this.cells.to.displayControl as TextModel).text = $row.entity.to;
    (this.cells.cc.displayControl as TextModel).text = $row.entity.cc;
    (this.cells.bcc.displayControl as TextModel).text = $row.entity.bcc;
    (this.cells.subject.displayControl as TextModel).text = $row.entity.subject;
    (this.cells.body.displayControl as TextModel).text = $row.entity.body;
    (this.cells.created_on.displayControl as TextModel).text = $row.entity.created_on?.toString();
    (this.cells.sent_on.displayControl as TextModel).text = $row.entity.sent_on?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'body' : {
        this.on_body_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Notifications_email_requests_gridComponentRowModel,
  $grid: Notifications_email_requests_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let dateFormat = 'MM/DD/YYYY hh:mm A';
  
  $row.cells.created_on.displayControl.text = $utils.date.format(new Date($row.cells.created_on.displayControl.text).toLocaleString(), dateFormat);
  $row.cells.sent_on.displayControl.text = $utils.isDefinedTrimmed($row.cells.sent_on.displayControl.text) ? $utils.date.format(new Date($row.cells.sent_on.displayControl.text).toLocaleString(), dateFormat) : '';
  }
  on_rule_id_clicked(event = null) {
    return this.on_rule_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_rule_id_clickedInternal(
    $row: Notifications_email_requests_gridComponentRowModel,
  $grid: Notifications_email_requests_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  
  }
  on_body_clicked(event = null) {
    return this.on_body_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_body_clickedInternal(
    $row: Notifications_email_requests_gridComponentRowModel,
  $grid: Notifications_email_requests_gridComponent, 
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity.body)) {
      await $shell.Notifications.openview_field_code_editorDialog({ field_name: 'Email body', field_value: $row.entity.body }, 'modal', EModalSize.Standard);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Notifications_email_request_attachments_gridComponent)
  ],
  selector: 'Notifications-email_requests_grid',
  templateUrl: './Notifications.email_requests_grid.component.html'
})
export class Notifications_email_requests_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: INotifications_email_requests_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['default','fit-content-table']);

  fullTextSearch: string;

  inParams: INotifications_email_requests_gridComponentInParams = { entityType: null, entityId: null, ruleId: null, projectId: null, context: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     rule_id: new GridHeaderModel(new HeaderStyles(null, null), 'Rule ID', false, false, null),       entity_type: new GridHeaderModel(new HeaderStyles(null, null), 'Associated entity', false, false, null),       status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       to: new GridHeaderModel(new HeaderStyles(null, null), 'To', false, false, null),       cc: new GridHeaderModel(new HeaderStyles(null, null), 'CC', false, false, null),       bcc: new GridHeaderModel(new HeaderStyles(null, null), 'BCC', false, false, null),       subject: new GridHeaderModel(new HeaderStyles(null, null), 'Subject', false, false, null),       body: new GridHeaderModel(new HeaderStyles(null, null), 'Body', false, false, 300),       created_on: new GridHeaderModel(new HeaderStyles(null, null), 'Request initiated on', false, false, null),       sent_on: new GridHeaderModel(new HeaderStyles(null, null), 'Email sent on', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Notifications_email_requests_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('entityType') set $inParams_entityType(value: any) {
    this.inParams['entityType'] = value;
  }
  get $inParams_entityType(): any {
    return this.inParams['entityType'] ;
  }
  @Input('entityId') set $inParams_entityId(value: any) {
    this.inParams['entityId'] = value;
  }
  get $inParams_entityId(): any {
    return this.inParams['entityId'] ;
  }
  @Input('ruleId') set $inParams_ruleId(value: any) {
    this.inParams['ruleId'] = value;
  }
  get $inParams_ruleId(): any {
    return this.inParams['ruleId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('context') set $inParams_context(value: any) {
    this.inParams['context'] = value;
  }
  get $inParams_context(): any {
    return this.inParams['context'] ;
  }

  topToolbar = {
      send_request: new ToolModel(new ButtonModel('send_request', new ButtonStyles(null, null), false, 'Send email', 'icon-ic_fluent_send_20_regular')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_rows: new ToolModel(new ButtonModel('delete_rows', new ButtonStyles(['destructive'], null), false, 'Delete', 'icon-ic_fluent_delete_20_regular')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    unsent_only: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  filters = {
    unsent_only: new FieldModel(new CheckBoxModel(this.formGroup.controls['unsent_only'] as DatexFormControl, null, false, 'Unsent only')
, new ControlContainerStyles(null, null), '', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Notifications_ShellService,
    private datasources: Notifications_DatasourceService,
    private flows: Notifications_FlowService,
    private reports: Notifications_ReportService,
    private localization: Notifications_LocalizationService,
    private operations: Notifications_OperationService,
    ) {
    this.title = 'Email requests';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.unsent_only.control as CheckBoxModel).reset(false);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      entityType:  $grid.inParams.entityType ,
      entityId:  $grid.inParams.entityId ,
      ruleId:  $grid.inParams.ruleId ,
      projectId:  $grid.inParams.projectId ,
      context:  $grid.inParams.context ,
      unsent_only:  $grid.filters.unsent_only.control.value ,
    };
    try {
    const data = await this.datasources.Notifications.ds_email_requests_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Notifications_email_requests_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Notifications_email_requests_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  for (let key in $grid.inParams) {
      if ($grid.inParams[key] === 'null' || $grid.inParams[key] === 'undefined') {
          $grid.inParams[key] = null;
      }
  }
  
  if ($utils.isDefined($grid.inParams.ruleId)) {
      $grid.headers.rule_id.hidden = true;
      $grid.title += ' for rule ' + $grid.inParams.ruleId;
  }
  
  if ($utils.isDefined($grid.inParams.entityType) && $utils.isDefined($grid.inParams.entityId)) {
      let entity = $grid.inParams.entityType === 'Order' ? (await $datasources.Notifications.ds_get_orders_by_orderIds.getList({ orderIds: [$grid.inParams.entityId], $top: 1})).result[0] :
          $grid.inParams.entityType === 'Invoice' ? (await $datasources.Notifications.ds_get_invoices_by_invoiceIds.getList({ invoiceIds: [$grid.inParams.entityId], $top: 1 })).result[0] : null;
  
      let lookupCode = entity?.LookupCode;
  
      $grid.title += ` for ${$grid.inParams.entityType} ${lookupCode}`;
      $grid.headers.entity_type.hidden = true;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Notifications_email_requests_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.delete_rows.control.readOnly = $grid.selectedRows.length === 0;
  $grid.topToolbar.send_request.control.readOnly = $grid.selectedRows.length === 0;
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Notifications_email_requests_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length === 0) {
      return;
  }
  
  let plural_char = $grid.selectedRows.length > 1 ? 's' : '';
  let is_confirmed = await $shell.Notifications.openConfirmationDialog(
      `Delete email request${plural_char}?`, 
      `Are you sure you want to delete the ${$grid.selectedRows.length > 1 ? `${$grid.selectedRows.length} ` : ''}selected request${plural_char}?`,
      `Delete request${plural_char}`, 'Cancel');
  if (!is_confirmed) {
      return;
  }
  
  let promises: any[] = [];
  for (let row of $grid.selectedRows) {
      promises.push($flows.Notifications.delete_email_request_flow({ requestId: row.entity.id}));
  }
  
  await Promise.all(promises);
  
  $shell.Notifications.openToaster(`Email request${plural_char} deleted`, `Successfully deleted ${$grid.selectedRows.length > 1 ? $grid.selectedRows.length + ' ' : ''}request${plural_char}`,
      EToasterType.Success,
      { timeOut: 5000, positionClass: EToasterPosition.topRight }
  )
  
  await $grid.refresh();
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Notifications_email_requests_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  let orderIds = $grid.rows.filter(row => row.entity.entity_type === 'Order' && $utils.isDefined(row.entity.entity_id)).map(row => row.entity.entity_id);
  let invoiceIds = $grid.rows.filter(row => row.entity.entity_type === 'Invoice' && $utils.isDefined(row.entity.entity_id)).map(row => row.entity.entity_id);
  
  if ($utils.isDefined(orderIds)) {
      var ordersPromise = $datasources.Notifications.ds_get_orders_by_orderIds.get({ orderIds: orderIds });
  }
  
  if ($utils.isDefined(invoiceIds)) {
      var invoicesPromise = $datasources.Notifications.ds_get_invoices_by_invoiceIds.get({ invoiceIds: invoiceIds});
  }
  
  let [ordersResponse, invoicesResponse] = await Promise.all([ordersPromise, invoicesPromise]);
  
  for (let row of $grid.rows) {
      if (!$utils.isDefined(row.entity.entity_type) || !$utils.isDefined(row.entity.entity_id)) {
          continue;
      }
  
      let lookupCode = row.entity.entity_type === 'Order' ? ordersResponse.result.find(o => o.Id === row.entity.entity_id)?.LookupCode :
          row.entity.entity_type === 'Invoice' ? invoicesResponse.result.find(i => i.Id === row.entity.entity_id)?.LookupCode : '';
  
      row.cells.entity_type.displayControl.text = `${row.entity.entity_type} ${lookupCode}`;
  }
  
  }
  on_send_request_clicked(event = null) {
    return this.on_send_request_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_send_request_clickedInternal(
    $grid: Notifications_email_requests_gridComponent,
  
    $shell: Notifications_ShellService,
    $datasources: Notifications_DatasourceService,
    $flows: Notifications_FlowService,
    $reports: Notifications_ReportService,
    $settings: SettingsValuesService,
    $operations: Notifications_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Notifications_LocalizationService,
    $event: any
  ) {
  for (let row of $grid.selectedRows) {
      await $flows.Notifications.email_request_send({ requestId: row.entity.id});
  }
  
  $shell.Notifications.openToaster('Emails sent', $grid.selectedRows.length > 1 ? `The ${$grid.selectedRows.length} selected emails have been sent.` : 'The selected email has been sent', EToasterType.Success, { timeOut: 5000, positionClass: EToasterPosition.topRight})
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
