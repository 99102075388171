<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-expected_date" *ngIf="!fields.expected_date.hidden" 
                            class="field-container standard {{fields.expected_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expected_date.styles.style"
                            [ngClass]="fields.expected_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.expected_date.label}}{{fields.expected_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.expected_date.label}}">{{fields.expected_date.label}}<span *ngIf="fields.expected_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="expected_date"
                            [format]="fields.expected_date.control.format"
                            [mode]="fields.expected_date.control.mode"
                            [ngStyle]="fields.expected_date.control.styles.style"
                            [ngClass]="fields.expected_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.expected_date.invalid">
                          <ng-container *ngFor="let error of fields.expected_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tracking" *ngIf="!fields.tracking.hidden" 
                            class="field-container standard {{fields.tracking.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.tracking.styles.style"
                            [ngClass]="fields.tracking.styles.classes">
                        <div class="label-container"
                              title="{{fields.tracking.label}}{{fields.tracking.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.tracking.label}}">{{fields.tracking.label}}<span *ngIf="fields.tracking.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="tracking"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.tracking.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.tracking.control.placeholder}}"
                                [ngStyle]="fields.tracking.control.styles.style"
                                [ngClass]="fields.tracking.control.styles.classes"> 
                        <ng-container *ngIf="fields.tracking.invalid">
                          <ng-container *ngFor="let error of fields.tracking.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier.label}}{{fields.carrier.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier.label}}">{{fields.carrier.label}}<span *ngIf="fields.carrier.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Carriers-carriers_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier.control.type"
                            formControlName="carrier"
                            (displayTextChange)="fields.carrier.control.displayText=$event"
                            [placeholder]="fields.carrier.control.placeholder"
                            [styles]="fields.carrier.control.styles"
                        >
                        </Carriers-carriers_dd_single>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service" *ngIf="!fields.carrier_service.hidden" 
                            class="field-container standard {{fields.carrier_service.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier_service.styles.style"
                            [ngClass]="fields.carrier_service.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier_service.label}}{{fields.carrier_service.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier_service.label}}">{{fields.carrier_service.label}}<span *ngIf="fields.carrier_service.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Carriers-carrierservicetype_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier_service.control.type"
                            formControlName="carrier_service"
                            (displayTextChange)="fields.carrier_service.control.displayText=$event"
                            [placeholder]="fields.carrier_service.control.placeholder"
                            [styles]="fields.carrier_service.control.styles"
                          [carrierId]="$fields_carrier_service_selector_inParams_carrierId"
                        >
                        </Carriers-carrierservicetype_dd_single>
                        <ng-container *ngIf="fields.carrier_service.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-freight_charge" *ngIf="!fields.freight_charge.hidden" 
                            class="field-container standard {{fields.freight_charge.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.freight_charge.styles.style"
                            [ngClass]="fields.freight_charge.styles.classes">
                        <div class="label-container"
                              title="{{fields.freight_charge.label}}{{fields.freight_charge.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.freight_charge.label}}">{{fields.freight_charge.label}}<span *ngIf="fields.freight_charge.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="freight_charge"
                                matInput
                                numberBox
                                [format]="fields.freight_charge.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.freight_charge.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.freight_charge.control.placeholder}}"
                                [ngStyle]="fields.freight_charge.control.styles.style"
                                [ngClass]="fields.freight_charge.control.styles.classes">
                        <ng-container *ngIf="fields.freight_charge.invalid">
                          <ng-container *ngFor="let error of fields.freight_charge.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-incoterms" *ngIf="!fields.incoterms.hidden" 
                            class="field-container standard {{fields.incoterms.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.incoterms.styles.style"
                            [ngClass]="fields.incoterms.styles.classes">
                        <div class="label-container"
                              title="{{fields.incoterms.label}}{{fields.incoterms.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.incoterms.label}}">{{fields.incoterms.label}}<span *ngIf="fields.incoterms.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Manifesting-incoterms_dd_single 
                            data-cy="selector"
                            [type]="fields.incoterms.control.type"
                            formControlName="incoterms"
                            (displayTextChange)="fields.incoterms.control.displayText=$event"
                            [placeholder]="fields.incoterms.control.placeholder"
                            [styles]="fields.incoterms.control.styles"
                        >
                        </Manifesting-incoterms_dd_single>
                        <ng-container *ngIf="fields.incoterms.invalid">
                          <ng-container *ngFor="let error of fields.incoterms.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pva" *ngIf="!fields.pva.hidden" 
                            class="field-container standard {{fields.pva.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pva.styles.style"
                            [ngClass]="fields.pva.styles.classes">
                        <div class="label-container"
                              title="{{fields.pva.label}}{{fields.pva.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pva.label}}">{{fields.pva.label}}<span *ngIf="fields.pva.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="pva"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.pva.control.styles.style"
                                      [ngClass]="fields.pva.control.styles.classes">{{fields.pva.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.pva.invalid">
                          <ng-container *ngFor="let error of fields.pva.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>