<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-create_wave" *ngIf="!topToolbar.create_wave.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.create_wave.control.readOnly"
            [ngStyle]="topToolbar.create_wave.control.styles.style"
            [ngClass]="topToolbar.create_wave.control.styles.classes"
            (click)="on_create_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.create_wave.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.create_wave.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.create_wave.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-assign_to_wave" *ngIf="!topToolbar.assign_to_wave.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.assign_to_wave.control.readOnly"
            [ngStyle]="topToolbar.assign_to_wave.control.styles.style"
            [ngClass]="topToolbar.assign_to_wave.control.styles.classes"
            (click)="on_assign_to_wave_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.assign_to_wave.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.assign_to_wave.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.assign_to_wave.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="order_lookup">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.order_lookup.displayControl.styles.style"
          [ngClass]="row.cells.order_lookup.displayControl.styles.classes">{{row.cells.order_lookup.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="create_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.create_date.displayControl.styles.style"
          [ngClass]="row.cells.create_date.displayControl.styles.classes">{{row.cells.create_date.displayControl.text | formatText : row.cells.create_date.displayControl.formatType : row.cells.create_date.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Carrier_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Carrier_Name.displayControl.styles.style"
          [ngClass]="row.cells.Carrier_Name.displayControl.styles.classes">{{row.cells.Carrier_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CarrierServiceType_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CarrierServiceType_Name.displayControl.styles.style"
          [ngClass]="row.cells.CarrierServiceType_Name.displayControl.styles.classes">{{row.cells.CarrierServiceType_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="date_needed">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.date_needed.displayControl.styles.style"
          [ngClass]="row.cells.date_needed.displayControl.styles.classes">{{row.cells.date_needed.displayControl.text | formatText : row.cells.date_needed.displayControl.formatType : row.cells.date_needed.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="order_owner_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.order_owner_reference.displayControl.styles.style"
          [ngClass]="row.cells.order_owner_reference.displayControl.styles.classes">{{row.cells.order_owner_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vendor_reference.displayControl.styles.style"
          [ngClass]="row.cells.vendor_reference.displayControl.styles.classes">{{row.cells.vendor_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
