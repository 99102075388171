<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Address</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-street1" *ngIf="!fields.street1.hidden" 
                            class="field-container standard {{fields.street1.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.street1.styles.style"
                            [ngClass]="fields.street1.styles.classes">
                        <div class="label-container"
                              title="{{fields.street1.label}}{{fields.street1.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.street1.label}}">{{fields.street1.label}}<span *ngIf="fields.street1.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="street1"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.street1.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.street1.control.placeholder}}"
                                [ngStyle]="fields.street1.control.styles.style"
                                [ngClass]="fields.street1.control.styles.classes"> 
                        <ng-container *ngIf="fields.street1.invalid">
                          <ng-container *ngFor="let error of fields.street1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-street2" *ngIf="!fields.street2.hidden" 
                            class="field-container standard {{fields.street2.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.street2.styles.style"
                            [ngClass]="fields.street2.styles.classes">
                        <div class="label-container"
                              title="{{fields.street2.label}}{{fields.street2.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.street2.label}}">{{fields.street2.label}}<span *ngIf="fields.street2.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="street2"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.street2.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.street2.control.placeholder}}"
                                [ngStyle]="fields.street2.control.styles.style"
                                [ngClass]="fields.street2.control.styles.classes"> 
                        <ng-container *ngIf="fields.street2.invalid">
                          <ng-container *ngFor="let error of fields.street2.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-city" *ngIf="!fields.city.hidden" 
                            class="field-container standard {{fields.city.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.city.styles.style"
                            [ngClass]="fields.city.styles.classes">
                        <div class="label-container"
                              title="{{fields.city.label}}{{fields.city.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.city.label}}">{{fields.city.label}}<span *ngIf="fields.city.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="city"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.city.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.city.control.placeholder}}"
                                [ngStyle]="fields.city.control.styles.style"
                                [ngClass]="fields.city.control.styles.classes"> 
                        <ng-container *ngIf="fields.city.invalid">
                          <ng-container *ngFor="let error of fields.city.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-state" *ngIf="!fields.state.hidden" 
                            class="field-container standard {{fields.state.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.state.styles.style"
                            [ngClass]="fields.state.styles.classes">
                        <div class="label-container"
                              title="{{fields.state.label}}{{fields.state.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.state.label}}">{{fields.state.label}}<span *ngIf="fields.state.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="state"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.state.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.state.control.placeholder}}"
                                [ngStyle]="fields.state.control.styles.style"
                                [ngClass]="fields.state.control.styles.classes"> 
                        <ng-container *ngIf="fields.state.invalid">
                          <ng-container *ngFor="let error of fields.state.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-zip" *ngIf="!fields.zip.hidden" 
                            class="field-container standard {{fields.zip.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.zip.styles.style"
                            [ngClass]="fields.zip.styles.classes">
                        <div class="label-container"
                              title="{{fields.zip.label}}{{fields.zip.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.zip.label}}">{{fields.zip.label}}<span *ngIf="fields.zip.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="zip"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.zip.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.zip.control.placeholder}}"
                                [ngStyle]="fields.zip.control.styles.style"
                                [ngClass]="fields.zip.control.styles.classes"> 
                        <ng-container *ngIf="fields.zip.invalid">
                          <ng-container *ngFor="let error of fields.zip.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-country" *ngIf="!fields.country.hidden" 
                            class="field-container standard {{fields.country.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.country.styles.style"
                            [ngClass]="fields.country.styles.classes">
                        <div class="label-container"
                              title="{{fields.country.label}}{{fields.country.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.country.label}}">{{fields.country.label}}<span *ngIf="fields.country.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="country"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.country.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.country.control.placeholder}}"
                                [ngStyle]="fields.country.control.styles.style"
                                [ngClass]="fields.country.control.styles.classes"> 
                        <ng-container *ngIf="fields.country.invalid">
                          <ng-container *ngFor="let error of fields.country.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-company" *ngIf="!fields.company.hidden" 
                            class="field-container standard {{fields.company.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.company.styles.style"
                            [ngClass]="fields.company.styles.classes">
                        <div class="label-container"
                              title="{{fields.company.label}}{{fields.company.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.company.label}}">{{fields.company.label}}<span *ngIf="fields.company.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="company"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.company.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.company.control.placeholder}}"
                                [ngStyle]="fields.company.control.styles.style"
                                [ngClass]="fields.company.control.styles.classes"> 
                        <ng-container *ngIf="fields.company.invalid">
                          <ng-container *ngFor="let error of fields.company.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-phone" *ngIf="!fields.phone.hidden" 
                            class="field-container standard {{fields.phone.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.phone.styles.style"
                            [ngClass]="fields.phone.styles.classes">
                        <div class="label-container"
                              title="{{fields.phone.label}}{{fields.phone.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.phone.label}}">{{fields.phone.label}}<span *ngIf="fields.phone.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="phone"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.phone.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.phone.control.placeholder}}"
                                [ngStyle]="fields.phone.control.styles.style"
                                [ngClass]="fields.phone.control.styles.classes"> 
                        <ng-container *ngIf="fields.phone.invalid">
                          <ng-container *ngFor="let error of fields.phone.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Other</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-verify" *ngIf="!fields.verify.hidden" 
                            class="field-container standard {{fields.verify.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.verify.styles.style"
                            [ngClass]="fields.verify.styles.classes">
                        <div class="label-container"
                              title="{{fields.verify.label}}{{fields.verify.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.verify.label}}">{{fields.verify.label}}<span *ngIf="fields.verify.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="verify"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.verify.control.styles.style"
                                      [ngClass]="fields.verify.control.styles.classes">{{fields.verify.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.verify.invalid">
                          <ng-container *ngFor="let error of fields.verify.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-verify_strict" *ngIf="!fields.verify_strict.hidden" 
                            class="field-container standard {{fields.verify_strict.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.verify_strict.styles.style"
                            [ngClass]="fields.verify_strict.styles.classes">
                        <div class="label-container"
                              title="{{fields.verify_strict.label}}{{fields.verify_strict.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.verify_strict.label}}">{{fields.verify_strict.label}}<span *ngIf="fields.verify_strict.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="verify_strict"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.verify_strict.control.styles.style"
                                      [ngClass]="fields.verify_strict.control.styles.classes">{{fields.verify_strict.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.verify_strict.invalid">
                          <ng-container *ngFor="let error of fields.verify_strict.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>