<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-invoicing_instructions"
                     *ngIf="!filtersets.invoicing_instructions.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.invoicing_instructions.collapsible }">
                    <div *ngIf="!filtersets.invoicing_instructions.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.invoicing_instructions.toggle()">
                      <span class="fieldsetsTitle-text">Invoicing instructions</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.invoicing_instructions.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.invoicing_instructions.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.invoicing_instructions.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.invoicing_instructions.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-invoicing_instructions" *ngIf="!filters.invoicing_instructions.hidden" 
                                  class="field-container full {{filters.invoicing_instructions.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.invoicing_instructions.styles.style"
                                  [ngClass]="filters.invoicing_instructions.styles.classes">
                              <div class="label-container"
                                    title="{{filters.invoicing_instructions.label}}{{filters.invoicing_instructions.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.invoicing_instructions.label}}">{{filters.invoicing_instructions.label}}<span *ngIf="filters.invoicing_instructions.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Invoices-invoicing_instructions_dd_single 
                                  data-cy="selector"
                                  [type]="filters.invoicing_instructions.control.type"
                                  formControlName="invoicing_instructions"
                                  (displayTextChange)="filters.invoicing_instructions.control.displayText=$event"
                                  [placeholder]="filters.invoicing_instructions.control.placeholder"
                                  [styles]="filters.invoicing_instructions.control.styles"
                                [billingContractId]="$fields_invoicing_instructions_selector_inParams_billingContractId"
                              >
                              </Invoices-invoicing_instructions_dd_single>
                              <ng-container *ngIf="filters.invoicing_instructions.invalid">
                                <ng-container *ngFor="let error of filters.invoicing_instructions.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-invoicing_instruction_execute" *ngIf="!filters.invoicing_instruction_execute.hidden" 
                                  class="field-container standard {{filters.invoicing_instruction_execute.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.invoicing_instruction_execute.styles.style"
                                  [ngClass]="filters.invoicing_instruction_execute.styles.classes">
                              <div class="label-container"
                                    title="{{filters.invoicing_instruction_execute.label}}{{filters.invoicing_instruction_execute.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.invoicing_instruction_execute.label}}">{{filters.invoicing_instruction_execute.label}}<span *ngIf="filters.invoicing_instruction_execute.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <button mat-button
                                      data-cy="button"
                                      class="datex-button"
                                      [disabled]="filters.invoicing_instruction_execute.control.readOnly"
                                      [ngStyle]="filters.invoicing_instruction_execute.control.styles.style"
                                      [ngClass]="filters.invoicing_instruction_execute.control.styles.classes"
                                      (click)="on_instruction_execute($event)"
                                      >
                                <div class="button-label">
                                  <ng-container *ngIf="filters.invoicing_instruction_execute.control.icon === 'datex-default-spinner'; else showIcon">
                                    <div class="button-icon">
                                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #showIcon>
                                    <div class="button-icon">
                                      <i data-cy="button-icon" class="icon {{filters.invoicing_instruction_execute.control.icon}}"></i>
                                    </div>
                                  </ng-template>
                                  <div data-cy="button-label" class="button-text">{{filters.invoicing_instruction_execute.control.label}}</div>
                                </div>
                              
                              </button>
                              <ng-container *ngIf="filters.invoicing_instruction_execute.invalid">
                                <ng-container *ngFor="let error of filters.invoicing_instruction_execute.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-execute_invoice_line_creation" *ngIf="!filters.execute_invoice_line_creation.hidden" 
                                  class="field-container standard {{filters.execute_invoice_line_creation.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.execute_invoice_line_creation.styles.style"
                                  [ngClass]="filters.execute_invoice_line_creation.styles.classes">
                              <div class="label-container"
                                    title="{{filters.execute_invoice_line_creation.label}}{{filters.execute_invoice_line_creation.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.execute_invoice_line_creation.label}}">{{filters.execute_invoice_line_creation.label}}<span *ngIf="filters.execute_invoice_line_creation.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <button mat-button
                                      data-cy="button"
                                      class="datex-button"
                                      [disabled]="filters.execute_invoice_line_creation.control.readOnly"
                                      [ngStyle]="filters.execute_invoice_line_creation.control.styles.style"
                                      [ngClass]="filters.execute_invoice_line_creation.control.styles.classes"
                                      (click)="on_invoice_lines_create_execute($event)"
                                      >
                                <div class="button-label">
                                  <ng-container *ngIf="filters.execute_invoice_line_creation.control.icon === 'datex-default-spinner'; else showIcon">
                                    <div class="button-icon">
                                      <i data-cy="button-icon" class="datex-default-spinner"></i>
                                    </div>
                                  </ng-container>
                                  <ng-template #showIcon>
                                    <div class="button-icon">
                                      <i data-cy="button-icon" class="icon {{filters.execute_invoice_line_creation.control.icon}}"></i>
                                    </div>
                                  </ng-template>
                                  <div data-cy="button-label" class="button-text">{{filters.execute_invoice_line_creation.control.label}}</div>
                                </div>
                              
                              </button>
                              <ng-container *ngIf="filters.execute_invoice_line_creation.invalid">
                                <ng-container *ngFor="let error of filters.execute_invoice_line_creation.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                <div data-cy="fieldset-id-invoicing_projects"
                     *ngIf="!filtersets.invoicing_projects.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.invoicing_projects.collapsible }">
                    <div *ngIf="!filtersets.invoicing_projects.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.invoicing_projects.toggle()">
                      <span class="fieldsetsTitle-text">Projects</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.invoicing_projects.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.invoicing_projects.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.invoicing_projects.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.invoicing_projects.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-invoicing_projects" *ngIf="!filters.invoicing_projects.hidden" 
                                  class="field-container full {{filters.invoicing_projects.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.invoicing_projects.styles.style"
                                  [ngClass]="filters.invoicing_projects.styles.classes">
                              <div class="label-container"
                                    title="{{filters.invoicing_projects.label}}{{filters.invoicing_projects.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.invoicing_projects.label}}">{{filters.invoicing_projects.label}}<span *ngIf="filters.invoicing_projects.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Invoices-invoicing_projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.invoicing_projects.control.type"
                                  formControlName="invoicing_projects"
                                  (displayTextChange)="filters.invoicing_projects.control.displayText=$event"
                                  [placeholder]="filters.invoicing_projects.control.placeholder"
                                  [styles]="filters.invoicing_projects.control.styles"
                                [projectIds]="$fields_invoicing_projects_selector_inParams_projectIds"
                              >
                              </Invoices-invoicing_projects_dd_single>
                              <ng-container *ngIf="filters.invoicing_projects.invalid">
                                <ng-container *ngFor="let error of filters.invoicing_projects.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.invoicing_instructions.hidden" class="tab" data-cy="tab-invoicing_instructions">
              <h2 [className]="tabs.invoicing_instructions.active? 'active': ''" (click)="tabs.invoicing_instructions.activate()">{{tabs.invoicing_instructions.title}}</h2>
            </div>
            <div *ngIf="!tabs.invoicing_logs.hidden" class="tab" data-cy="tab-invoicing_logs">
              <h2 [className]="tabs.invoicing_logs.active? 'active': ''" (click)="tabs.invoicing_logs.activate()">{{tabs.invoicing_logs.title}}</h2>
            </div>
            <div *ngIf="!tabs.invoicing_transactions.hidden" class="tab" data-cy="tab-invoicing_transactions">
              <h2 [className]="tabs.invoicing_transactions.active? 'active': ''" (click)="tabs.invoicing_transactions.activate()">{{tabs.invoicing_transactions.title}}</h2>
            </div>
            <div *ngIf="!tabs.options.hidden" class="tab" data-cy="tab-options">
              <h2 [className]="tabs.options.active? 'active': ''" (click)="tabs.options.activate()">{{tabs.options.title}}</h2>
            </div>
          </div>
        
              <Invoices-invoicing_instructions_grid *ngIf="tabs.invoicing_instructions.active"
              #$tabs_invoicing_instructions
              [billingContractId]="$tabs_invoicing_instructions_invoicing_instructions_grid_inParams_billingContractId"
              [projectId]="$tabs_invoicing_instructions_invoicing_instructions_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_invoicing_instructions')">
              </Invoices-invoicing_instructions_grid>
              <Invoices-invoicing_logs_grid *ngIf="tabs.invoicing_logs.active"
              #$tabs_invoicing_logs
              [billingContractId]="$tabs_invoicing_logs_invoicing_logs_grid_inParams_billingContractId"
              [projectId]="$tabs_invoicing_logs_invoicing_logs_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_invoicing_logs')">
              </Invoices-invoicing_logs_grid>
              <Invoices-invoicing_transactions_grid *ngIf="tabs.invoicing_transactions.active"
              #$tabs_invoicing_transactions
              [billingContractId]="$tabs_invoicing_transactions_invoicing_transactions_grid_inParams_billingContractId"
              [projectId]="$tabs_invoicing_transactions_invoicing_transactions_grid_inParams_projectId"
              ($refreshEvent)="refresh(false, false, '$tabs_invoicing_transactions')">
              </Invoices-invoicing_transactions_grid>
              <Invoices-invoicing_options_grid *ngIf="tabs.options.active"
              #$tabs_options
              ($refreshEvent)="refresh(false, false, '$tabs_options')">
              </Invoices-invoicing_options_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>