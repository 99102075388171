import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Inventory.types'

@Injectable({ providedIn: 'root' })
export class Inventory_create_released_inventory_move_task_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { sourceLicenseplateId: number, lotId: number, packagingId: number, packagedAmount: number, targetLicenseplate: { id?: number, lookupcode?: string }, notes?: string, reasoncodeId?: number, targetLocationId: number, serialNumberIds?: number[] }): Promise<{ reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.sourceLicenseplateId)) {
      missingRequiredInParams.push('\'sourceLicenseplateId\'');
    }
    if (isNil(inParams.lotId)) {
      missingRequiredInParams.push('\'lotId\'');
    }
    if (isNil(inParams.packagingId)) {
      missingRequiredInParams.push('\'packagingId\'');
    }
    if (isNil(inParams.packagedAmount)) {
      missingRequiredInParams.push('\'packagedAmount\'');
    }
    if (isNil(inParams.targetLicenseplate)) {
      missingRequiredInParams.push('\'targetLicenseplate\'');
    }
    if (isNil(inParams.targetLocationId)) {
      missingRequiredInParams.push('\'targetLocationId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/functions/create_released_inventory_move_task_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

