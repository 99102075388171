import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $types } from './app.types'


interface IFootPrintManager_materials_udf_gridComponentEntity {
Id?: number, AllocationQuantity?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Name?: string, RequireOutboundScanning?: boolean, SpecialInstructions?: string, InventoryByWarehouseMaterial?: { AvailablePackagedAmount?: number }[], PackagingLookups?: { PackagingId?: number, UpcCode?: string }[]}

interface IFootPrintManager_materials_udf_gridComponentInParams {
  projectId?: number, ownerId?: number, materialIds?: number[]}


class FootPrintManager_materials_udf_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_materials_udf_gridComponent;
  entity: IFootPrintManager_materials_udf_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    description_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    upc_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    allocation_quantity_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    commodity_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    country_of_origin_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    duty_rate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    require_outbound_scanning_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    require_outbound_scanning_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    special_instructions_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    lookupcode: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['description_edit'] as DatexFormControl, null, false, '')
      ),
    upc_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['upc_code_edit'] as DatexFormControl, null, false, '')
      ),
    available: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'number', '0')
,
null
      ),
    allocation_quantity: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['allocation_quantity_edit'] as DatexFormControl, null, false, '', '')
      ),
    commodity_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['commodity_code_edit'] as DatexFormControl, null, false, '')
      ),
    country_of_origin: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['country_of_origin_edit'] as DatexFormControl, null, false, '')
      ),
    duty_rate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['duty_rate_edit'] as DatexFormControl, null, false, '', '')
      ),
    require_outbound_scanning: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['require_outbound_scanning_display'] as DatexFormControl, null, false, '')
,
      new CheckBoxModel(this.formGroup.controls['require_outbound_scanning_edit'] as DatexFormControl, null, false, '')
      ),
    special_instructions: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['special_instructions_edit'] as DatexFormControl, null, false, '')
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_materials_udf_gridComponent, entity: IFootPrintManager_materials_udf_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_materials_udf_gridComponent, entity?: IFootPrintManager_materials_udf_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      materialIds:  $grid.inParams.materialIds ,
      take:  null ,
      skip:  null ,
    };
    const data = await this.datasources.app.custom_ds_materials_udf_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.lookupcode.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.description.displayControl as TextModel).text = $row.entity.Description;
    (this.cells.description.editControl as TextBoxModel).reset($row.entity.Description);
    (this.cells.upc_code.displayControl as TextModel).text = $row.entity.PackagingLookups[0]?.UpcCode;
    (this.cells.upc_code.editControl as TextBoxModel).reset($row.entity.PackagingLookups[0]?.UpcCode);
    (this.cells.available.displayControl as TextModel).text = $row.entity.InventoryByWarehouseMaterial[0]?.AvailablePackagedAmount?.toString();
    (this.cells.allocation_quantity.displayControl as TextModel).text = $row.entity.AllocationQuantity?.toString();
    (this.cells.allocation_quantity.editControl as NumberBoxModel).reset($row.entity.AllocationQuantity);
    (this.cells.commodity_code.displayControl as TextModel).text = $row.entity.CommodityCode;
    (this.cells.commodity_code.editControl as TextBoxModel).reset($row.entity.CommodityCode);
    (this.cells.country_of_origin.displayControl as TextModel).text = $row.entity.CountryOfOrigin;
    (this.cells.country_of_origin.editControl as TextBoxModel).reset($row.entity.CountryOfOrigin);
    (this.cells.duty_rate.displayControl as TextModel).text = $row.entity.DutyRate?.toString();
    (this.cells.duty_rate.editControl as NumberBoxModel).reset($row.entity.DutyRate);
    (this.cells.require_outbound_scanning.displayControl as CheckBoxModel).reset($row.entity.RequireOutboundScanning);
    (this.cells.require_outbound_scanning.editControl as CheckBoxModel).reset($row.entity.RequireOutboundScanning);
    (this.cells.special_instructions.displayControl as TextModel).text = $row.entity.SpecialInstructions;
    (this.cells.special_instructions.editControl as TextBoxModel).reset($row.entity.SpecialInstructions);

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_materials_udf_gridComponentRowModel,
  $grid: FootPrintManager_materials_udf_gridComponent, 
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  let payload: any = {};
  let payloadpackaging: any = {};
  
  if ($row.cells.description?.editControl.isChanged) {
      payload.Description = $row.cells.description?.editControl.value;
  }
  
  if ($row.cells.commodity_code.editControl.isChanged) {
      payload.CommodityCode = $row.cells.commodity_code.editControl.value;
  }
  
  if ($row.cells.country_of_origin.editControl.isChanged) {
      payload.CountryOfOrigin = $row.cells.country_of_origin.editControl.value;
  }
  
  if ($row.cells.duty_rate.editControl.isChanged) {
      payload.DutyRate = $row.cells.duty_rate.editControl.value;
  }
  
  if ($row.cells.special_instructions.editControl.isChanged) {
      payload.SpecialInstructions = $row.cells.special_instructions.editControl.value;
  }
  
  if ($row.cells.require_outbound_scanning.editControl.isChanged) {
      payload.RequireOutboundScanning = $row.cells.require_outbound_scanning.editControl.value;
  }
  
  if ($row.cells.allocation_quantity.editControl.isChanged) {
      payload.AllocationQuantity = $row.cells.allocation_quantity.editControl.value;
  }
  
  if ($row.cells.upc_code.editControl.isChanged) {
      payloadpackaging.UpcCode = $row.cells.upc_code?.editControl.value;
  }
  try {
  
      await $flows.Utilities.crud_update_flow({ entitySet: 'Materials', id: $row.entity.Id, entity: payload });
  
      await $flows.Utilities.crud_update_dual_pk_flow({
          entitySet: 'MaterialsPackagingsLookup',
          entity: payloadpackaging,
          pk1String: 'MaterialId',
          pk1: $row.entity.Id,
          pk2String: 'PackagingId',
          pk2: $row.entity.PackagingLookups[0]?.PackagingId
      })
  
      $row.refresh();
  } catch (error) {
      $shell.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  
  
  
  
  
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-materials_udf_grid',
  templateUrl: './FootPrintManager.materials_udf_grid.component.html'
})
export class FootPrintManager_materials_udf_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_materials_udf_gridComponentEntity[];

  pageSize = 40;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_materials_udf_gridComponentInParams = { projectId: null, ownerId: null, materialIds: [] };


  //#region Variables
  vars: { entities?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Name?: string, SpecialInstructions?: string }[], totalCount?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     lookupcode: new GridHeaderModel(new HeaderStyles(null, null), 'Lookupcode', false, false, null),       description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),       upc_code: new GridHeaderModel(new HeaderStyles(null, null), 'Upc', false, false, null),       available: new GridHeaderModel(new HeaderStyles(null, null), 'Available', false, false, null),       allocation_quantity: new GridHeaderModel(new HeaderStyles(null, null), 'Allocation Quantity', false, false, null),       commodity_code: new GridHeaderModel(new HeaderStyles(null, null), 'Commodity Code', false, false, null),       country_of_origin: new GridHeaderModel(new HeaderStyles(null, null), 'Country Of Origin', false, false, null),       duty_rate: new GridHeaderModel(new HeaderStyles(null, null), 'Duty Rate', false, false, null),       require_outbound_scanning: new GridHeaderModel(new HeaderStyles(null, null), 'Require Outbound Scanning', false, false, null),       special_instructions: new GridHeaderModel(new HeaderStyles(null, null), 'Special Instructions', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_materials_udf_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: app_ShellService,
    private datasources: app_DatasourceService,
    private flows: app_FlowService,
    private reports: app_ReportService,
    private localization: app_LocalizationService,
    private operations: app_OperationService,
    ) {
    this.title = 'Custom Materials UDF';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      projectId:  $grid.inParams.projectId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      materialIds:  $grid.inParams.materialIds ,
      take:  null ,
      skip:  null ,
    };
    try {
    const data = await this.datasources.app.custom_ds_materials_udf_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_materials_udf_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_materials_udf_gridComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  // Initialize
  var wb = $event;
  
  var transactions = [];
  
  
  const batchSize = 250;
  
  var allData: any[] = [];
  
  
  
  
  // Get all data from datasource
  do {
  
      var response = await $datasources.custom_ds_materials_udf_grid.get({
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          materialIds: $grid.inParams.materialIds,
          fullTextSearch: $grid.fullTextSearch,
          take: batchSize,
          skip: allData.length
      });
  
      allData = allData.concat(response.result);
  
  } while (allData.length < response.totalCount)
  
  // Compile all data into JSON
  for (let record of allData) {
  
      transactions.push({
          "LookupCode": record.LookupCode,
          "Name": record.Name,
          "Description": record.Description,
          "Upc": record.PackagingLookups[0]?.UpcCode,
          "Available": record.InventoryByWarehouseMaterial[0]?.AvailablePackagedAmount,
          "CommodityCode": record.CommodityCode,
          "CountryOfOrigin": record.CountryOfOrigin,
          "DutyRate": record.DutyRate,
          "SpecialInstructions": record.SpecialInstructions,
          "RequireOutboundScanning": record.RequireOutboundScanning,
          "AllocationQuantity": record.AllocationQuantity
  
      });
  }
  
  var ws = $utils.excel.json_to_sheet(transactions);
  
  $utils.excel.book_append_sheet(wb, ws, "MaterialUdfs");
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_materials_udf_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
