import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintApiManager.types'
import { FootPrintApiManager_integrations_dd_singleComponent } from './FootPrintApiManager.integrations_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => FootPrintApiManager_integrations_dd_singleComponent),
  ],
  selector: 'FootPrintApiManager-insert_alert_form',
  templateUrl: './FootPrintApiManager.insert_alert_form.component.html'
})
export class FootPrintApiManager_insert_alert_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { alert?: any } = { alert: null };
  //#region Inputs
  @Input('alert') set $inParams_alert(v: any) {
    this.inParams.alert = v;
  }
  get $inParams_alert(): any {
    return this.inParams.alert;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  formGroup: FormGroup = new FormGroup({
    enabled: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    pdf: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    integration_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    alert_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    log_level: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    subject: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    to: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    cc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    bcc: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    enabled: new FieldModel(new CheckBoxModel(this.formGroup.controls['enabled'] as DatexFormControl, null, false, 'Enable the alert')
, new ControlContainerStyles(null, null), '', false)
,
    pdf: new FieldModel(new CheckBoxModel(this.formGroup.controls['pdf'] as DatexFormControl, null, false, 'Attach the logs and messages as a PDF to the email.')
, new ControlContainerStyles(null, null), '', false)
,
    integration_name: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['integration_name'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Integration name', true)
,
    alert_name: new FieldModel(new TextBoxModel(this.formGroup.controls['alert_name'] as DatexFormControl, null, false, 'A name to easily identify the purpose of your alert.')
, new ControlContainerStyles(null, null), 'Alert name', true)
,
    log_level: new FieldModel(new NumberBoxModel(this.formGroup.controls['log_level'] as DatexFormControl, null, false, '', '3 and above.')
, new ControlContainerStyles(null, null), 'Log level', true)
,
    subject: new FieldModel(new TextBoxModel(this.formGroup.controls['subject'] as DatexFormControl, null, false, 'This will appear on the subject of your email.')
, new ControlContainerStyles(null, null), 'Subject', true)
,
    to: new FieldModel(new TextBoxModel(this.formGroup.controls['to'] as DatexFormControl, null, false, 'This is the distribution list for the \x22TO\x22 field of your email.')
, new ControlContainerStyles(null, null), 'TO', true)
,
    cc: new FieldModel(new TextBoxModel(this.formGroup.controls['cc'] as DatexFormControl, null, false, 'This is the distribution list for the \x22CC\x22 field of your email.')
, new ControlContainerStyles(null, null), 'CC', false)
,
    bcc: new FieldModel(new TextBoxModel(this.formGroup.controls['bcc'] as DatexFormControl, null, false, 'This is the distribution list for the \x22BCC\x22 field of your email.')
, new ControlContainerStyles(null, null), 'BCC', false)
,
  };

  fieldsets = {
  alert_insert: new FieldsetModel('Alert insert', true, false, true),
};

  //#region fields inParams
  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    enabled: this.fields.enabled.control.valueChanges
    ,
    pdf: this.fields.pdf.control.valueChanges
    ,
    integration_name: this.fields.integration_name.control.valueChanges
    ,
    alert_name: this.fields.alert_name.control.valueChanges
    ,
    log_level: this.fields.log_level.control.valueChanges
    ,
    subject: this.fields.subject.control.valueChanges
    ,
    to: this.fields.to.control.valueChanges
    ,
    cc: this.fields.cc.control.valueChanges
    ,
    bcc: this.fields.bcc.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'insert_alert_form';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .enabled
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .pdf
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .integration_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .alert_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .log_level
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .subject
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .to
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .cc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .bcc
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel(event = null) {
    return this.on_cancelInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancelInternal(
    $form: FootPrintApiManager_insert_alert_formComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  $form.close()
  }
  on_confirm(event = null) {
    return this.on_confirmInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirmInternal(
    $form: FootPrintApiManager_insert_alert_formComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/22/2024
  
  const allRequiredFieldHaveValue =
      $utils.isDefined($form.fields.integration_name.control.value) &&
      $utils.isDefined($form.fields.alert_name.control.value) &&
      $utils.isDefined($form.fields.subject.control.value) &&
      $utils.isDefined($form.fields.to.control.value)
  
  if (allRequiredFieldHaveValue === false) {
      $shell.FootPrintApiManager.openErrorDialog('Save', 'Missing Required fields');
      throw new Error('Missing Required fields'); // to prevent displayMode 
  };
  
  let alert_row: $types.Notifications.Alert = $form.inParams.alert;
  
  let integration_name: string = $form.fields.integration_name.control.value ?? "";
  
  if (integration_name.toUpperCase() === "NULL") {
      integration_name = "";
  };
  
  if (integration_name === "") {
      integration_name = $form.fields.integration_name.control.value ?? "";
  };
  
  try {
  
      if (integration_name === "") {
          throw new Error("You need to specify the integration to insert an alert!")
      };
  
      if (($form.fields.log_level.control.value ?? 0) < 3) {
          throw new Error("To prevent spam, the log level cannot be lesser than three (3)!")
      };
  
      if (($form.fields.alert_name.control.value ?? "") === "") {
          throw new Error("The alert name cannot be blank!")
      };
  
      if (($form.fields.subject.control.value ?? "") === "") {
          throw new Error("The subject cannot be blank!")
      };
  
      if (($form.fields.to.control.value ?? "") === "") {
          throw new Error("The to email addresses cannot be blank!")
      };
  
      let alert_id: string = "";
      let action: string = "Write";
  
      if ($utils.isDefined(alert_row)) {
          action = "Update";
          alert_id = alert_row.alert_id;
      }
  
      let to: string = $form.fields.to.control.value ?? "";
      let cc: string = $form.fields.cc.control.value ?? "";
      let bcc: string = $form.fields.bcc.control.value ?? "";
  
      if (to !== "") {
          to = split_emails(to).join(';');
      };
  
      if (cc !== "") {
          cc = split_emails(cc).join(';');
      };
  
      if (bcc !== "") {
          bcc = split_emails(bcc).join(';');
      };
  
      await $flows.Notifications.storage_alerts({
          action: action,
          payload: {
              to: to,
              cc: cc,
              bcc: bcc,
              alert_id: alert_id,
              alert_name: $form.fields.alert_name.control.value,
              level: $form.fields.log_level.control.value,
              subject: $form.fields.subject.control.value,
              enabled: $form.fields.enabled.control.value ?? false,
              pdf: $form.fields.pdf.control.value ?? false
          },
          application_name: integration_name
      });
  
      $form.close()
  
  } catch (error) {
      $shell.FootPrintApiManager.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  };
  
  function split_emails(emails: string) {
      const split_on: string[] = [';', ',', '"', '<', '>', ' ']
      let split_items: string[] = [emails];
      let split_final: string[] = [];
  
      for (let split of split_on) {
          for (let item of split_items) {
              split_final = split_final.concat(item.split(split));
          }
          split_items = JSON.parse(JSON.stringify(split_final));
          split_final = [];
      };
  
      split_final = split_items;
      split_final = split_final.filter(e => is_valid_email(e)); //Valid
      split_final = split_final.filter((value, index, array) => array.indexOf(value) === index); //Distinct
      return split_final;
  };
  
  function is_valid_email(email: string) {
      const email_regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      return email_regex.test(email);
  };
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintApiManager_insert_alert_formComponent,
  
    $shell: FootPrintApiManager_ShellService,
    $datasources: FootPrintApiManager_DatasourceService,
    $flows: FootPrintApiManager_FlowService,
    $reports: FootPrintApiManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintApiManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintApiManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 05/22/2024
  
  let alert_row: $types.Notifications.Alert = $form.inParams.alert;
  
  if ($utils.isDefined(alert_row)) {
  
      $form.title = `Update alert ${alert_row.alert_id}`
      $form.fields.alert_name.control.value = alert_row.alert_name;
      $form.fields.bcc.control.value = alert_row.bcc;
      $form.fields.cc.control.value = alert_row.cc;
      $form.fields.enabled.control.value = alert_row.enabled;
      $form.fields.integration_name.control.value = alert_row.application_name;
      $form.fields.log_level.control.value = alert_row.level;
      $form.fields.pdf.control.value = alert_row.pdf;
      $form.fields.subject.control.value = alert_row.subject;
      $form.fields.to.control.value = alert_row.to;
  
  } else {
  
      $form.title = `Create new alert`
      $form.fields.enabled.hidden = true;
      
  }
  }
  //#endregion private flows
}
