<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-linecount" *ngIf="!topToolbar.linecount.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.linecount.control.readOnly"
            [ngStyle]="topToolbar.linecount.control.styles.style"
            [ngClass]="topToolbar.linecount.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.linecount.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.linecount.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.linecount.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <label class="import-button">
        <input type="file" class="hidden" #excelFileInput (change)="$importExcel(excelFileInput)" multiple="false" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, text/csv" />
        <div class="button-label">
          <div class="button-icon"><i class="icon icon-ic_fluent_cloud_arrow_up_20_regular"></i></div>
          <div class="button-text">Import</div>
        </div>
      </label>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="orderId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.orderId.displayControl.styles.style"
          [ngClass]="row.cells.orderId.displayControl.styles.classes">{{row.cells.orderId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['orderId_edit']"
            matInput
            numberBox
            [format]="row.cells.orderId.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.orderId.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.orderId.editControl.placeholder}}"
            [ngStyle]="row.cells.orderId.editControl.styles.style"
            [ngClass]="row.cells.orderId.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipmentId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.shipmentId.displayControl.styles.style"
          [ngClass]="row.cells.shipmentId.displayControl.styles.classes">{{row.cells.shipmentId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['shipmentId_edit']"
            matInput
            numberBox
            [format]="row.cells.shipmentId.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.shipmentId.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.shipmentId.editControl.placeholder}}"
            [ngStyle]="row.cells.shipmentId.editControl.styles.style"
            [ngClass]="row.cells.shipmentId.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner_reference.displayControl.styles.style"
          [ngClass]="row.cells.owner_reference.displayControl.styles.classes">{{row.cells.owner_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['owner_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.owner_reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.owner_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.owner_reference.editControl.styles.style"
            [ngClass]="row.cells.owner_reference.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="tracking">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.tracking.displayControl.styles.style"
          [ngClass]="row.cells.tracking.displayControl.styles.classes">{{row.cells.tracking.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['tracking_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.tracking.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.tracking.editControl.placeholder}}"
            [ngStyle]="row.cells.tracking.editControl.styles.style"
            [ngClass]="row.cells.tracking.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="error">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.error.displayControl.styles.style"
          [ngClass]="row.cells.error.displayControl.styles.classes">{{row.cells.error.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
