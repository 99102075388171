import {
Component,
OnInit,
OnDestroy,
OnChanges,
Input,
SimpleChanges,
Output,
EventEmitter,
Inject
} from '@angular/core';

import {
FormGroup,
Validators
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import {
TextBoxModel,
NumberBoxModel,
SelectBoxModel,
ESelectBoxType,
DateBoxModel,
CheckBoxModel,
TextModel,
LabelModel,
ButtonModel,
SplitButtonModel,
SeparatorModel,
ImageModel,
DrawModel,
CodeBoxModel,
ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import * as vkbeautify from 'vkbeautify';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintApiManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintApiManager.shell.service';
import { FootPrintApiManager_OperationService } from './FootPrintApiManager.operation.service';
import { FootPrintApiManager_DatasourceService } from './FootPrintApiManager.datasource.index';
import { FootPrintApiManager_FlowService } from './FootPrintApiManager.flow.index';
import { FootPrintApiManager_ReportService } from './FootPrintApiManager.report.index';
import { FootPrintApiManager_LocalizationService } from './FootPrintApiManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintApiManager.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintApiManager-messages_json_payload',
  templateUrl: './FootPrintApiManager.messages_json_payload.component.html'
})
export class FootPrintApiManager_messages_json_payloadComponent implements OnInit, OnDestroy, OnChanges
{

inParams: { payload?: any, title?: string, id?: string, integration_name?: string } = { payload: null, title: null, id: null, integration_name: null };
//#region Inputs
@Input('payload') set $inParams_payload(v: any) {
  this.inParams.payload = v;
}
get $inParams_payload(): any {
  return this.inParams.payload;
}
@Input('title') set $inParams_title(v: string) {
  this.inParams.title = v;
}
get $inParams_title(): string {
  return this.inParams.title;
}
@Input('id') set $inParams_id(v: string) {
  this.inParams.id = v;
}
get $inParams_id(): string {
  return this.inParams.id;
}
@Input('integration_name') set $inParams_integration_name(v: string) {
  this.inParams.integration_name = v;
}
get $inParams_integration_name(): string {
  return this.inParams.integration_name;
}
//#endregion Inputs

//#region Outputs
@Output()
$finish = new EventEmitter();
//#endregion

//#region title
// Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
// The title is often meant to be shown from the parent (shell breadcrumb for example)
// and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
// the parent has already been checked and the child now change something on the parent 
// in dev, CD is run twice
$titleChange = new EventEmitter<string>(true);
private $_title: string;
get title(): string {
  return this.$_title;
}
set title(t: string) {
  this.$_title = t;
  this.$titleChange.emit(this.$_title);
}
//#endregion title
codeMirrorOptions = {
theme: 'base16-light',
mode: 'application/json',
lineNumbers: true,
lineWrapping: true,
foldGutter: true,
gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
autoCloseBrackets: true,
matchBrackets: true,
lint: true
};

mode = 'application/json';

value: string;

//#region Variables
//#endregion
//#region Events

//#endregion

toolbar = {
  save: new ToolModel(new ButtonModel('save', new ButtonStyles(['primary'], null), false, 'Save', 'icon-ic_fluent_save_20_regular')
),
  export_to_file: new ToolModel(new ButtonModel('export_to_file', new ButtonStyles(['secondary'], null), false, 'Export', 'ms-Icon ms-Icon--Export')
)
};

constructor(
private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintApiManager_ShellService,
private datasources: FootPrintApiManager_DatasourceService,
private flows: FootPrintApiManager_FlowService,
private reports: FootPrintApiManager_ReportService,
private localization: FootPrintApiManager_LocalizationService,
private operations: FootPrintApiManager_OperationService,
) {
}

ngOnInit(): void {
  this.$init();
}

private $isFirstNgOnChanges = true;
ngOnChanges(changes: SimpleChanges): void {
  if (this.$isFirstNgOnChanges) {
    this.$isFirstNgOnChanges = false;
  } else {
    this.$init();
  }
}

private $unsubscribe$ = new Subject();
ngOnDestroy(): void {
  this.$unsubscribe$.next(null);
  this.$unsubscribe$.complete();
}

initialized = false;

async $init() {
this.title = 'messages_json_payload';

const $codeEditor = this;
const $utils = this.utils;

this.value = $codeEditor.inParams.payload;

await this.on_init();

this.initialized = true;
}

close() {
this.$finish.emit();
}

beautify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xml(this.value) : vkbeautify.json(this.value);
}

minify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xmlmin(this.value, [, true]) : vkbeautify.jsonmin(this.value);
}

refresh(
skipParent = false,
skipChildren = false,
childToSkip: string = null) {
}

//#region private flows
on_beautify_clicked(event = null) {
  return this.on_beautify_clickedInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_beautify_clickedInternal(
  $codeEditor: FootPrintApiManager_messages_json_payloadComponent,

  $shell: FootPrintApiManager_ShellService,
  $datasources: FootPrintApiManager_DatasourceService,
  $flows: FootPrintApiManager_FlowService,
  $reports: FootPrintApiManager_ReportService,
  $settings: SettingsValuesService,
  $operations: FootPrintApiManager_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: FootPrintApiManager_LocalizationService,
  $event: any
) {
//O.Arias - 02/06/2024

$codeEditor.beautify();
}
on_init(event = null) {
  return this.on_initInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_initInternal(
  $codeEditor: FootPrintApiManager_messages_json_payloadComponent,

  $shell: FootPrintApiManager_ShellService,
  $datasources: FootPrintApiManager_DatasourceService,
  $flows: FootPrintApiManager_FlowService,
  $reports: FootPrintApiManager_ReportService,
  $settings: SettingsValuesService,
  $operations: FootPrintApiManager_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: FootPrintApiManager_LocalizationService,
  $event: any
) {
//O.Arias - 02/06/2024

$codeEditor.beautify();

let title: string = $codeEditor.inParams.title ?? "";

if (title === "") {
    $codeEditor.title = "Code Editor";
} else {
    $codeEditor.title = title;
};
}
on_save(event = null) {
  return this.on_saveInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_saveInternal(
  $codeEditor: FootPrintApiManager_messages_json_payloadComponent,

  $shell: FootPrintApiManager_ShellService,
  $datasources: FootPrintApiManager_DatasourceService,
  $flows: FootPrintApiManager_FlowService,
  $reports: FootPrintApiManager_ReportService,
  $settings: SettingsValuesService,
  $operations: FootPrintApiManager_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: FootPrintApiManager_LocalizationService,
  $event: any
) {
let payload = $codeEditor.value;
let id = $codeEditor.inParams.id;
let integration_name = $codeEditor.inParams.integration_name;

if ($utils.isDefined(payload) && $utils.isDefined(id)) {

    await $flows.FootPrintApiManager.storage_messages({
        integration_name: integration_name,
        action: "Update",
        payload: {
            id: id,
            payload: payload
        }
    })
}
}
on_export_to_file(event = null) {
  return this.on_export_to_fileInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_export_to_fileInternal(
  $codeEditor: FootPrintApiManager_messages_json_payloadComponent,

  $shell: FootPrintApiManager_ShellService,
  $datasources: FootPrintApiManager_DatasourceService,
  $flows: FootPrintApiManager_FlowService,
  $reports: FootPrintApiManager_ReportService,
  $settings: SettingsValuesService,
  $operations: FootPrintApiManager_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: FootPrintApiManager_LocalizationService,
  $event: any
) {
//O.Arias - 05/28/2024

try {

    const now = new Date();
    const day = now.getDate();
    const month = now.getMonth() + 1;
    const year = now.getFullYear();
    const hour = now.getHours();
    const minute = now.getMinutes();
    const second = now.getSeconds();

    const lookup_array = [`${year}`, (`00${month}`).slice(-2), (`00${day}`).slice(-2), (`00${hour}`).slice(-2), (`00${minute}`).slice(-2), (`00${second}`).slice(-2)];
    const lookup_seed = lookup_array.join("");

    let id = $codeEditor.inParams.id;
    let payload = $codeEditor.value;

    const payload_blob = new Blob([payload]);

    await $utils.blob.saveFile(payload_blob, {
        fileName: `Message_${id.toLocaleUpperCase()}_${lookup_seed}.json`,
        extensions: ['.json']
    });

} catch (error) {
    // Get inner-most error message
    let targetError = error;
    while ($utils.isDefined(targetError?.error)) { targetError = targetError.error }
    if (!$utils.isDefined(targetError?.message)) { targetError = { "message": `Uncaught exception! ${JSON.stringify(targetError)}` } }
    throw new Error(`[on_export_to_file] Error! ${targetError.message}`);
}
}
//#endregion private flows
}