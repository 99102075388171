<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div data-cy="tool-id-create_shipment" *ngIf="!topToolbar.create_shipment.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.create_shipment.control.readOnly"
            [ngStyle]="topToolbar.create_shipment.control.styles.style"
            [ngClass]="topToolbar.create_shipment.control.styles.classes"
            (click)="on_new_outbound_shipment($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.create_shipment.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.create_shipment.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.create_shipment.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-cross_dock" *ngIf="!topToolbar.cross_dock.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.cross_dock.control.readOnly"
            [ngStyle]="topToolbar.cross_dock.control.styles.style"
            [ngClass]="topToolbar.cross_dock.control.styles.classes"
            (click)="on_cross_dock($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.cross_dock.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.cross_dock.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.cross_dock.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-auto_load" *ngIf="!topToolbar.auto_load.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.auto_load.control.readOnly"
            [ngStyle]="topToolbar.auto_load.control.styles.style"
            [ngClass]="topToolbar.auto_load.control.styles.classes"
            (click)="on_auto_load_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.auto_load.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.auto_load.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.auto_load.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-complete" *ngIf="!topToolbar.complete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.complete.control.readOnly"
            [ngStyle]="topToolbar.complete.control.styles.style"
            [ngClass]="topToolbar.complete.control.styles.classes"
            (click)="on_complete($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.complete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.complete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.complete.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator2" *ngIf="!topToolbar.separator2.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-on_delete" *ngIf="!topToolbar.on_delete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_delete.control.readOnly"
            [ngStyle]="topToolbar.on_delete.control.styles.style"
            [ngClass]="topToolbar.on_delete.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_delete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_delete.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="state">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.state.displayControl.styles.style"
          [ngClass]="row.cells.state.displayControl.styles.classes">{{row.cells.state.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.licenseplate_count.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_count.displayControl.styles.classes">{{row.cells.licenseplate_count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.available_date.displayControl.styles.style"
          [ngClass]="row.cells.available_date.displayControl.styles.classes">{{row.cells.available_date.displayControl.text | formatText : row.cells.available_date.displayControl.formatType : row.cells.available_date.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <app-datebox 
        data-cy="dateBox"
        [formControl]="row.formGroup.controls['available_date_edit']"
        [format]="row.cells.available_date.editControl.format"
        [mode]="row.cells.available_date.editControl.mode"
        [ngStyle]="row.cells.available_date.editControl.styles.style"
        [ngClass]="row.cells.available_date.editControl.styles.classes">
    </app-datebox>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bill_of_lading">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.bill_of_lading.displayControl.styles.style"
          [ngClass]="row.cells.bill_of_lading.displayControl.styles.classes">{{row.cells.bill_of_lading.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['bill_of_lading_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.bill_of_lading.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.bill_of_lading.editControl.placeholder}}"
            [ngStyle]="row.cells.bill_of_lading.editControl.styles.style"
            [ngClass]="row.cells.bill_of_lading.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.reference_number.displayControl.styles.style"
          [ngClass]="row.cells.reference_number.displayControl.styles.classes">{{row.cells.reference_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['reference_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.reference_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.reference_number.editControl.placeholder}}"
            [ngStyle]="row.cells.reference_number.editControl.styles.style"
            [ngClass]="row.cells.reference_number.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="booking_number">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.booking_number.displayControl.styles.style"
          [ngClass]="row.cells.booking_number.displayControl.styles.classes">{{row.cells.booking_number.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['booking_number_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.booking_number.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.booking_number.editControl.placeholder}}"
            [ngStyle]="row.cells.booking_number.editControl.styles.style"
            [ngClass]="row.cells.booking_number.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="broker_reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.broker_reference.displayControl.styles.style"
          [ngClass]="row.cells.broker_reference.displayControl.styles.classes">{{row.cells.broker_reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['broker_reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.broker_reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.broker_reference.editControl.placeholder}}"
            [ngStyle]="row.cells.broker_reference.editControl.styles.style"
            [ngClass]="row.cells.broker_reference.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="trailer_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.trailer_id.displayControl.styles.style"
          [ngClass]="row.cells.trailer_id.displayControl.styles.classes">{{row.cells.trailer_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['trailer_id_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.trailer_id.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.trailer_id.editControl.placeholder}}"
            [ngStyle]="row.cells.trailer_id.editControl.styles.style"
            [ngClass]="row.cells.trailer_id.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.carrier.displayControl.styles.style"
          [ngClass]="row.cells.carrier.displayControl.styles.classes">{{row.cells.carrier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carriers_ownerscarriers_dd_single 
        data-cy="selector"
        [type]="row.cells.carrier.editControl.type"
        [formControl]="row.formGroup.controls['carrier_edit']"
        (displayTextChange)="row.cells.carrier.editControl.displayText=$event"
        [placeholder]="row.cells.carrier.editControl.placeholder"
        [styles]="row.cells.carrier.editControl.styles"
      [ownerId]="row.$fields_carrier_selector_inParams_ownerId"
    >
    </Carriers-carriers_ownerscarriers_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="carrier_service">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.carrier_service.displayControl.styles.style"
          [ngClass]="row.cells.carrier_service.displayControl.styles.classes">{{row.cells.carrier_service.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Carriers-carrierservicetype_dd_single 
        data-cy="selector"
        [type]="row.cells.carrier_service.editControl.type"
        [formControl]="row.formGroup.controls['carrier_service_edit']"
        (displayTextChange)="row.cells.carrier_service.editControl.displayText=$event"
        [placeholder]="row.cells.carrier_service.editControl.placeholder"
        [styles]="row.cells.carrier_service.editControl.styles"
      [carrierId]="row.$fields_carrier_service_selector_inParams_carrierId"
    >
    </Carriers-carrierservicetype_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.notes.editControl.placeholder}}"
            [ngStyle]="row.cells.notes.editControl.styles.style"
            [ngClass]="row.cells.notes.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
