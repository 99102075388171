import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PurchaseOrders_DatasourceService } from './PurchaseOrders.datasource.index';

interface IPurchaseOrders_open_putaway_reportServiceInParams {
  startDate?: string, endDate?: string, ownerId?: number, projectId?: number, warehouseId?: number[]}

interface IPurchaseOrders_open_putaway_reportServiceData {
  details?: { result?: { Id?: number, ActualSourceLicensePlateId?: number, CreatedSysDateTime?: string, ShipmentId?: number, WarehouseId?: number, Warehouse?: { Name?: string }, shipment?: { OrderId?: number, ShipmentId?: number, Order?: { LookupCode?: string }, Shipment?: { LookupCode?: string } }, inventory?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, LicensePlate?: { LookupCode?: string, Location?: { Name?: string } }, Lot?: { Id?: number, Material?: { Description?: string, LookupCode?: string } } } }[] }
}

@Injectable({ providedIn: 'root' })
export class PurchaseOrders_open_putaway_reportService extends ReportBaseService<IPurchaseOrders_open_putaway_reportServiceInParams, IPurchaseOrders_open_putaway_reportServiceData> {

  protected reportReferenceName = 'open_putaway_report';
  protected appReferenceName = 'PurchaseOrders';

  constructor(
    utils: UtilsService,
    private datasources: PurchaseOrders_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IPurchaseOrders_open_putaway_reportServiceInParams) {
  }

  protected async getData(inParams: IPurchaseOrders_open_putaway_reportServiceInParams): Promise<IPurchaseOrders_open_putaway_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IPurchaseOrders_open_putaway_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPurchaseOrders_open_putaway_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        startDate:  $report.inParams.startDate ,
        endDate:  $report.inParams.endDate ,
        ownerId:  $report.inParams.ownerId ,
        projectId:  $report.inParams.projectId ,
        warehouseId:  $report.inParams.warehouseId 
      };
      const dsData = await this.datasources.PurchaseOrders.ds_open_putaway_report.get(dsParams);
      
      data.details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
