import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Inventory_ds_inventory_licenseplate_snapshot_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { fullTextSearch?: string, captureDate: string, projectId: number, warehouseId: number, materialIds?: number[], $top?: number, $skip?: number }): Promise<{ result?: { id?: string, captureDate?: string, materialId?: number, packagingId?: number, warehouseId?: number, totalPackagedAmount?: number, totalBaseAmount?: number, projectId?: number, materialLookupcode?: string, packagingShortName?: string, warehouseName?: string, totalNetWeight?: number, totalGrossWeight?: number, weightShortName?: string, weightUomId?: number, lotId?: number, lotLookupcode?: string, licenseplateId?: number, licenseplateLookupcode?: string, serialNumbers?: string, receiveDate?: string, lastReceiveDate?: string }[], totalCount?: number }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.captureDate)) {
      missingRequiredInParams.push('\'captureDate\'');
    }
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (isNil(inParams.warehouseId)) {
      missingRequiredInParams.push('\'warehouseId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_inventory_licenseplate_snapshot_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { fullTextSearch?: string, captureDate?: string, projectId?: number, warehouseId?: number, materialIds?: number[], $keys: string[] }): Promise<{ result?: { id?: string, captureDate?: string, materialId?: number, packagingId?: number, warehouseId?: number, totalPackagedAmount?: number, totalBaseAmount?: number, projectId?: number, materialLookupcode?: string, packagingShortName?: string, warehouseName?: string, totalNetWeight?: number, totalGrossWeight?: number, weightShortName?: string, weightUomId?: number, lotId?: number, lotLookupcode?: string, licenseplateId?: number, licenseplateLookupcode?: string, serialNumbers?: string, receiveDate?: string, lastReceiveDate?: string }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Inventory/datasources/ds_inventory_licenseplate_snapshot_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
