import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'


interface IFootPrintManager_dock_appointments_dashboard_gridComponentEntity {
Id?: number, LookupCode?: string, Notes?: string, RequestedDeliveryDate?: string, ProjectId?: number, OwnerReference?: string, OrderStatusId?: number, dock_appointment?: { Id?: number, DockAppointmentId?: number, DockAppointment?: { StatusId?: number, ScheduledArrival?: string, TypeId?: number, AssignedlLocationContainerId?: number, ScheduledLocationContainerId?: number, CheckedInOn?: string, CheckedInOnUtc?: string, ScheduledArrivalUtc?: string, Location?: string } }, OrderClassId?: number, OrderTypeId?: number, ProjectLookup?: string, ProjectName?: string, OwnerLookup?: string, OwnerName?: string, OrderClassName?: string, OrderStatus?: string, OrderClassTypeId?: number, WaveId?: number, PreferredWarehouseId?: number}

interface IFootPrintManager_dock_appointments_dashboard_gridComponentInParams {
  ownerId?: number, projectId?: number, warehouseIds: number[], typeIds?: number[], statusIds?: number[], fromDate?: string, toDate?: string}


class FootPrintManager_dock_appointments_dashboard_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_dock_appointments_dashboard_gridComponent;
  entity: IFootPrintManager_dock_appointments_dashboard_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    appointment: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    location_name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    scheduled_arrival: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    checked_in_on: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    wait_time: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    order: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    order_class: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    order_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    wave_id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    manual_allocations: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Picked: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Loaded: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Received: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    Putaway: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    OwnerReference: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_dock_appointments_dashboard_gridComponent, entity: IFootPrintManager_dock_appointments_dashboard_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_dock_appointments_dashboard_gridComponent, entity?: IFootPrintManager_dock_appointments_dashboard_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      typeIds:  $grid.inParams.typeIds ,
      timeZoneDates:  $grid.vars.timeZoneDates as { timeZoneId: string, fromDate: string, toDate:string }[] ,
      statusIds:  $grid.inParams.statusIds ,
    };
    const data = await this.datasources.DockAppointments.ds_dock_appointments_dashboard_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.appointment.displayControl as TextModel).text = $row.entity.dock_appointment?.DockAppointmentId?.toString();
    (this.cells.location_name.displayControl as TextModel).text = $row.entity.dock_appointment?.DockAppointment?.Location;
    (this.cells.order.displayControl as TextModel).text = $row.entity.LookupCode;
    (this.cells.order_class.displayControl as TextModel).text = $row.entity.OrderClassName;
    (this.cells.order_status.displayControl as TextModel).text = $row.entity.OrderStatus;
    (this.cells.wave_id.displayControl as TextModel).text = $row.entity.WaveId?.toString();
    (this.cells.project.displayControl as TextModel).text = $row.entity.ProjectLookup;
    (this.cells.OwnerReference.displayControl as TextModel).text = $row.entity.OwnerReference;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'appointment' : {
        this.on_appointment_clicked();
        break;
      }
      case 'order' : {
        this.on_order_clicked();
        break;
      }
      case 'wave_id' : {
        this.on_wave_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_dock_appointments_dashboard_gridComponentRowModel,
  $grid: FootPrintManager_dock_appointments_dashboard_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}, ${$settings.FootPrintManager.TimeFormat.toUpperCase() == '24 HOUR' ? 'HH:mm' : 'LT'}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($row.entity.dock_appointment?.DockAppointment?.ScheduledArrival)) {
          $row.cells.scheduled_arrival.displayControl.text = $utils.date.format($row.entity.dock_appointment?.DockAppointment?.ScheduledArrival, format);
      };
  
      if ($utils.isAllDefined($row.entity.dock_appointment?.DockAppointment?.CheckedInOn, $row.entity.dock_appointment?.DockAppointment?.ScheduledArrival, $row.entity.PreferredWarehouseId)) {
          $row.cells.checked_in_on.displayControl.text = $utils.date.format($row.entity.dock_appointment?.DockAppointment?.CheckedInOn, format);
  
          let warehouseId = $row.entity.PreferredWarehouseId;
  
          let warehouseDate = (await $flows.DockAppointments.date_get_warehouse_flow({
              warehouseId: warehouseId
          }));
  
          let currentWarehouseDate = warehouseDate.date;
  
          var inputDateString
  
          if ($row.entity.dock_appointment?.DockAppointment?.ScheduledArrival > $row.entity.dock_appointment?.DockAppointment?.CheckedInOn) {
              inputDateString = $row.entity.dock_appointment?.DockAppointment?.ScheduledArrival;
          }
          else {
              inputDateString = $row.entity.dock_appointment?.DockAppointment?.CheckedInOn;
          }
  
          const timeDifference = subtractDates(currentWarehouseDate, inputDateString);
  
          if (timeDifference !== null) {
  
              $row.cells.wait_time.displayControl.text = (timeDifference ? ( timeDifference < 0 ? '0' : timeDifference?.toString()) : '0') + ' Minutes';
              if (timeDifference > 120) {
                  $row.cells.wait_time.styles.setStatusCanceledClass();
              } else {
                  $row.cells.wait_time.styles.setStatusCompleteClass();
              }
          }
          else {
              $row.cells.wait_time.displayControl.text = 'N/A'
          }
  
      }
      else {
          $row.cells.wait_time.displayControl.text = 'N/A'
      }
  
  };
  
  
  // Function to subtract the current date and time from a date in string format
  function subtractDates(startDateTimeString: string, endDateString: string): number | null {
      // Parse the date strings into Date objects
      const startDate = new Date(startDateTimeString);
      const endDate = new Date(endDateString);
  
      // Check if the parsing was successful
      if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
          return null;
      }
  
      // Calculate the time difference in minutes
      const timeDifference = Math.floor((startDate.getTime() - endDate.getTime()) / 60000);
  
      return timeDifference;
  }
  
  
  
  }
  on_order_clicked(event = null) {
    return this.on_order_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_order_clickedInternal(
    $row: FootPrintManager_dock_appointments_dashboard_gridComponentRowModel,
  $grid: FootPrintManager_dock_appointments_dashboard_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  if ($row.entity.OrderClassTypeId === 12) {
  
      //ASN
      $shell.FootPrintManager.openasn_order_editor({ order_id: $row.entity.Id });
  }
  else {
  
      if ($row.entity.OrderClassTypeId === 1) {
  
          //PO
          $shell.FootPrintManager.openpurchase_order_editor({ orderId: $row.entity.Id });
      } else {
  
          if ($row.entity.OrderClassTypeId === 5) {
  
              //SO
              $shell.FootPrintManager.opensales_order_editor({ orderId: $row.entity.Id });
          };
  
      };
  
  };
  }
  on_appointment_clicked(event = null) {
    return this.on_appointment_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_appointment_clickedInternal(
    $row: FootPrintManager_dock_appointments_dashboard_gridComponentRowModel,
  $grid: FootPrintManager_dock_appointments_dashboard_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  if ($utils.isDefined($row.entity.dock_appointment?.DockAppointmentId)) {
  
      const dialogResult = await $shell.FootPrintManager.opendock_appointment_editorDialog({
          dockAppointmentId: $row.entity.dock_appointment?.DockAppointmentId
      });
      if ($utils.isDefined(dialogResult)) {
          const userConfirm = dialogResult.confirm;
          if (userConfirm) {
              $grid.refresh();
          }
      }
  
  }
  
  
  }
  on_wave_clicked(event = null) {
    return this.on_wave_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_wave_clickedInternal(
    $row: FootPrintManager_dock_appointments_dashboard_gridComponentRowModel,
  $grid: FootPrintManager_dock_appointments_dashboard_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let waveId = $row.entity.WaveId
  
  if ($utils.isDefined(waveId)) { 
      $shell.FootPrintManager.opensingle_wave_hub({
          waveId: waveId
      })
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-dock_appointments_dashboard_grid',
  templateUrl: './FootPrintManager.dock_appointments_dashboard_grid.component.html'
})
export class FootPrintManager_dock_appointments_dashboard_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_dock_appointments_dashboard_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_dock_appointments_dashboard_gridComponentInParams = { ownerId: null, projectId: null, warehouseIds: [], typeIds: [], statusIds: [], fromDate: null, toDate: null };


  //#region Variables
  vars: { ownerId?: number, projectId?: number, timeZoneDates?: { timeZoneId?: string, fromDate?: string, toDate?: string }[] } = { };
  //#endregion
  //#region Events
  @Output()
  filtersUpdated = new EventEmitter<{ fromDate?: string, toDate?: string }>();
  
  events = {
    filtersUpdated: this.filtersUpdated,
  }
  //#endregion

  headers = {
     appointment: new GridHeaderModel(new HeaderStyles(null, null), 'Appointment', false, false, null),       location_name: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       scheduled_arrival: new GridHeaderModel(new HeaderStyles(null, null), 'Scheduled arrival', false, false, null),       checked_in_on: new GridHeaderModel(new HeaderStyles(null, null), 'Checked in on', false, false, null),       wait_time: new GridHeaderModel(new HeaderStyles(null, null), 'Wait time', false, false, null),       order: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, null),       order_class: new GridHeaderModel(new HeaderStyles(null, null), 'Order class', false, false, null),       order_status: new GridHeaderModel(new HeaderStyles(null, null), 'Order status', false, false, null),       wave_id: new GridHeaderModel(new HeaderStyles(null, null), 'Wave', false, false, null),       manual_allocations: new GridHeaderModel(new HeaderStyles(null, null), 'Manual allocation', false, false, null),       Picked: new GridHeaderModel(new HeaderStyles(null, null), 'Picked %', false, false, null),       Loaded: new GridHeaderModel(new HeaderStyles(null, null), 'Loaded %', false, false, null),       Received: new GridHeaderModel(new HeaderStyles(null, null), 'Received %', false, false, null),       Putaway: new GridHeaderModel(new HeaderStyles(null, null), 'Putaway %', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       OwnerReference: new GridHeaderModel(new HeaderStyles(null, null), 'Owner reference', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_dock_appointments_dashboard_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseIds') set $inParams_warehouseIds(value: any) {
    this.inParams['warehouseIds'] = value;
  }
  get $inParams_warehouseIds(): any {
    return this.inParams['warehouseIds'] ;
  }
  @Input('typeIds') set $inParams_typeIds(value: any) {
    this.inParams['typeIds'] = value;
  }
  get $inParams_typeIds(): any {
    return this.inParams['typeIds'] ;
  }
  @Input('statusIds') set $inParams_statusIds(value: any) {
    this.inParams['statusIds'] = value;
  }
  get $inParams_statusIds(): any {
    return this.inParams['statusIds'] ;
  }
  @Input('fromDate') set $inParams_fromDate(value: any) {
    this.inParams['fromDate'] = value;
  }
  get $inParams_fromDate(): any {
    return this.inParams['fromDate'] ;
  }
  @Input('toDate') set $inParams_toDate(value: any) {
    this.inParams['toDate'] = value;
  }
  get $inParams_toDate(): any {
    return this.inParams['toDate'] ;
  }

  topToolbar = {
      refresh: new ToolModel(new ButtonModel('refresh', new ButtonStyles(null, null), false, 'Refresh', 'ms-Icon ms-Icon--Process')
    ),
      on_previous_day: new ToolModel(new ButtonModel('on_previous_day', new ButtonStyles(null, null), false, 'Previous day ', 'ms-Icon ms-Icon--NavigateBack')
    ),
      on_next_day: new ToolModel(new ButtonModel('on_next_day', new ButtonStyles(null, null), false, 'Next day', 'ms-Icon ms-Icon--NavigateBackMirrored')
    ),
      on_today: new ToolModel(new ButtonModel('on_today', new ButtonStyles(null, null), false, 'Today', 'ms-Icon ms-Icon--DateTime')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    from_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    to_date: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    from_date: new FieldModel(new DateBoxModel(this.formGroup.controls['from_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'From date', false)
,
    to_date: new FieldModel(new DateBoxModel(this.formGroup.controls['to_date'] as DatexFormControl, null, false, '', 'date')
, new ControlContainerStyles(null, null), 'To date', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Dock appointments dashboard';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseIds)) {
        this.$missingRequiredInParams.push('warehouseIds');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    (this.filters.from_date.control as DateBoxModel).reset($grid.inParams.fromDate);
    (this.filters.to_date.control as DateBoxModel).reset($grid.inParams.toDate);

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {
    this.formGroup
      .controls['from_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });
    this.formGroup
      .controls['to_date']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_filter_changed();
      });

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseIds:  $grid.inParams.warehouseIds ,
      typeIds:  $grid.inParams.typeIds ,
      timeZoneDates:  $grid.vars.timeZoneDates as { timeZoneId: string, fromDate: string, toDate:string }[] ,
      statusIds:  $grid.inParams.statusIds ,
    };
    try {
    const data = await this.datasources.DockAppointments.ds_dock_appointments_dashboard_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_dock_appointments_dashboard_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  const inbound_orders = [];
  const outbound_orders = [];
  
  let createdCount = 0;
  
  for (let row of $grid.rows) {
  
      if (row.entity.OrderTypeId === 1) {
          inbound_orders.push(row.entity.Id)
      };
      if (row.entity.OrderTypeId === 2) {
          outbound_orders.push(row.entity.Id)
      };
  
      if (row.entity.OrderStatusId === 1) {
          createdCount = createdCount + 1;
      };
  
  };
  
  
  
  //Only run if inbound orders are being requested
  if (inbound_orders.length > 0) {
  
      const inbound_shipment_orders = (await $datasources.LoadContainers.ds_get_shipments_from_orders.get({ orderIds: inbound_orders })).result;
      const inbound_shipments = inbound_shipment_orders.map(row => row.ShipmentId);
  
      const receiving_tasks = (await $datasources.LoadContainers.ds_shipment_tasks.get({ shipmentIds: inbound_shipments, statusIds: [2], operationCodeIds: [2] })).result;
      const putaway_tasks = (await $datasources.LoadContainers.ds_shipment_tasks.get({ shipmentIds: inbound_shipments, statusIds: [2], operationCodeIds: [3] })).result;
      const expected_inbound = (await $datasources.LoadContainers.ds_shipment_processed.get({ shipmentIds: inbound_shipments })).result;
  
      const receiving_array = receiving_tasks.map(row => row.ShipmentId);
      const putaway_array = putaway_tasks.map(row => row.ShipmentId);
      const expected_array = expected_inbound.map(row => row.Shipment.Id);
  
      for (let row of $grid.rows) {
  
          let received_total = 0;
          let putaway_total = 0;
          let expected_total = 0;
  
          if (row.entity.OrderTypeId === 1) {
  
              for (let shipment_order of inbound_shipment_orders) {
  
                  if (shipment_order.OrderId === row.entity.Id) {
  
                      let shipmentId = shipment_order.ShipmentId;
                      let shipmentIndex = -1;
  
  
                      //Get the total received inventory
                      shipmentIndex = receiving_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          received_total = received_total + receiving_tasks[shipmentIndex].ActualAmount;
                      };
  
                      //Get the total putaway inventory
                      shipmentIndex = putaway_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          putaway_total = putaway_total + putaway_tasks[shipmentIndex].ActualAmount;
                      };
  
                      //Get the total expected inventory
                      shipmentIndex = expected_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          expected_total = expected_total + expected_inbound[shipmentIndex].Amount;
                      };
                  };
              };
  
              if (received_total > 0 && expected_total > 0) {
                  row.cells.Received.displayControl.text = calculatePercentage(received_total, expected_total);
  
                  if (calculateIndicator(received_total, expected_total) == 'Completed') {
                      row.cells.Received.styles.setStatusCompleteClass();
                  }
                  else {
                      row.cells.Received.styles.setStatusCanceledClass();
                  }
  
              } else {
                  row.cells.Received.displayControl.text = "0.0%"
                  row.cells.Received.styles.setAttentionClass();
  
              };
  
              if (putaway_total > 0 && expected_total > 0) {
                  row.cells.Putaway.displayControl.text = calculatePercentage(putaway_total, expected_total);
  
                  if (calculateIndicator(putaway_total, expected_total) == 'Completed') {
                      row.cells.Putaway.styles.setStatusCompleteClass();
                  }
                  else {
                      row.cells.Putaway.styles.setStatusCanceledClass();
                  }
  
              } else {
                  row.cells.Putaway.displayControl.text = "0.0%"
                  row.cells.Putaway.styles.setAttentionClass();
              };
          };
      };
  };
  
  //Only run if outbound load containers are being requested
  if (outbound_orders.length > 0) {
  
      const outbound_shipment_orders = (await $datasources.LoadContainers.ds_get_shipments_from_orders.get({ orderIds: outbound_orders })).result;
      const outbound_shipments = outbound_shipment_orders.map(row => row.ShipmentId);
  
      const contents = (await $datasources.LoadContainers.ds_shipment_contents.get({ shipmentIds: outbound_shipments, loadTasks: false })).result;
      const contents_loaded = (await $datasources.LoadContainers.ds_shipment_contents.get({ shipmentIds: outbound_shipments, loadTasks: true })).result;
      const contents_archived = (await $datasources.LoadContainers.ds_shipment_archived_contents.get({ shipmentIds: outbound_shipments })).result;
      const expected = (await $datasources.LoadContainers.ds_shipment_expected.get({ shipmentIds: outbound_shipments })).result;
      const processed = (await $datasources.LoadContainers.ds_shipment_processed.get({ shipmentIds: outbound_shipments })).result;
      const available = (await $datasources.LoadContainers.ds_shipment_expected.get({ shipmentIds: outbound_shipments, statusId: 1, available: true })).result;
  
      const contents_array = contents.map(row => row.LicensePlate.ShipmentId);
      const contents_loaded_array = contents_loaded.map(row => row.LicensePlate.ShipmentId);
      const contents_archived_array = contents_archived.map(row => row.LicensePlate.ShipmentId);
      const expected_array = expected.map(row => row.Shipment.Id);
      const processed_array = processed.map(row => row.Shipment.Id);
      const available_array = available.map(row => row.Shipment.Id);
  
      //Display wave manual allocation data and formatting
      let waveResults = [];
      var waveIds = $grid.rows.map(row => row.entity.WaveId)
  
      // Filter out records with a null waveId
      const filteredWaves: number[] = waveIds.filter((value): value is number => value !== null);
  
      if ($utils.isDefined(filteredWaves)) {
          waveResults = (await $datasources.Waves.get_manual_allocations.get({ WaveIds: filteredWaves })).result
      }
  
      for (let row of $grid.rows) {
  
          let picked_content = 0;
          let picked_content_loaded = 0;
          let picked_archived_content = 0;
          let expected_total = 0;
          let processed_total = 0;
          let available_total = 0;
          let loaded_total = 0;
          let picked_total = 0;
          let pick_pending = 0;
          let total_amount = 0;
          let total_available = 0;
  
          if (row.entity.OrderTypeId === 2) {
  
              for (let shipment_order of outbound_shipment_orders) {
  
                  if (shipment_order.OrderId === row.entity.Id) {
  
                      let shipmentId = shipment_order.ShipmentId;
                      let shipmentIndex = -1;
  
                      //Get the total picked amount for the load container
                      shipmentIndex = contents_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          picked_content = picked_content + contents[shipmentIndex].Amount;
                      };
  
                      //Get the total picked & loaded amount for the load container
                      shipmentIndex = contents_loaded_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          picked_content_loaded = picked_content_loaded + contents_loaded[shipmentIndex].Amount;
                      };
  
                      //Get the total archived amount for the load container, if it's archived we assume it's picked and loaded
                      shipmentIndex = contents_archived_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          picked_archived_content = picked_archived_content + contents_archived[shipmentIndex].Amount;
                      };
  
                      //Get the total expected amount
                      shipmentIndex = expected_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          expected_total = expected_total + expected[shipmentIndex].Amount;
                      };
  
                      //Get the total processed amount
                      shipmentIndex = processed_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          processed_total = processed_total + processed[shipmentIndex].Amount;
                      };
  
                      //Get the total available amount
                      shipmentIndex = available_array.indexOf(shipmentId)
                      if (shipmentIndex >= 0) {
                          available_total = available_total + available[shipmentIndex].Amount;
                      };
  
                      picked_total = picked_total + picked_content + picked_archived_content
                      loaded_total = loaded_total + picked_content_loaded + picked_archived_content
                      pick_pending = pick_pending + expected_total - picked_total
  
                      if (pick_pending < 0) {
                          pick_pending = 0
                      };
  
                      total_amount = total_amount + expected_total;
                      total_available = total_available + processed_total + available_total;
                  };
  
                  if (loaded_total > 0 && total_amount > 0) {
                      row.cells.Loaded.displayControl.text = calculatePercentage(loaded_total, total_amount);
  
                      if (calculateIndicator(loaded_total, total_amount) == 'Completed') {
                          row.cells.Loaded.styles.setStatusCompleteClass();
                      }
                      else {
                          row.cells.Loaded.styles.setStatusCanceledClass();
                      }
  
                  } else {
                      row.cells.Loaded.displayControl.text = "0.0%";
                      row.cells.Loaded.styles.setImportantClass();
                  };
  
                  if (picked_total > 0 && total_amount > 0) {
                      row.cells.Picked.displayControl.text = calculatePercentage(picked_total, total_amount);
  
                      if (calculateIndicator(picked_total, total_amount) == 'Completed') {
                          row.cells.Picked.styles.setStatusCompleteClass();
                      }
                      else {
  
                          row.cells.Picked.styles.setStatusCanceledClass();
                      }
  
  
                  } else {
                      row.cells.Picked.displayControl.text = "0.0%";
                      row.cells.Picked.styles.setImportantClass();
                  };
  
  
              };
  
  
              row.cells.manual_allocations.displayControl.text = `None`
              for (let wave of waveResults)
                  if (row.entity.WaveId === wave.Shipment.Wave.Id) {
                      if (wave.TaskCount > 0) {
                          row.cells.manual_allocations.displayControl.text = `⚠ ${wave.TaskCount.toString()} manual tasks`;
                          row.cells.manual_allocations.styles.setAttentionClass();
                      }
                     
                  }
                  
          };
      };
  
  
  
  
  
  }
  
  function calculatePercentage(a, b) {
      if (b === 0) {
          return "Cannot divide by zero.";
      };
      const percentage = (a / b) * 100;
      return percentage.toFixed(2) + "%";
  };
  
  function calculateIndicator(a, b) {
      if (b === 0) {
          return "Cannot divide by zero.";
      };
      const percentage = (a / b) * 100;
      if (percentage >= 90) {
          return 'Completed'
      }
      else {
          return 'Attention';
      }
  
  };
  
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  // Initialize date format
  var dateFormat = $settings.FootPrintManager.DateFormat;
  
  $grid.filters.from_date.control.format = dateFormat;
  $grid.filters.to_date.control.format = dateFormat;
  
  
  // Default current date
  $grid.filters.from_date.control.value = $grid.inParams.fromDate ?? $utils.date.now()
  $grid.filters.to_date.control.value = $grid.inParams.toDate ?? $utils.date.now()
  
  
  // Emit event data
  await $grid.on_filter_changed();
  
  // Hide columns
  const appointmentType = $grid.inParams.typeIds
  if ($utils.isDefined(appointmentType) && appointmentType.length == 1) {
      // Inbound order
      if (appointmentType[0] == 1) {
          $grid.headers.Loaded.hidden = true;
          $grid.headers.Picked.hidden = true;
          $grid.headers.manual_allocations.hidden = true;
          $grid.headers.wave_id.hidden = true;
  
          $grid.headers.Received.hidden = false;
          $grid.headers.Putaway.hidden = false;
      }
      // Outbound order
      if (appointmentType[0] == 2) {
          $grid.headers.Received.hidden = true;
          $grid.headers.Putaway.hidden = true;
  
          $grid.headers.Loaded.hidden = false;
          $grid.headers.Picked.hidden = false;
          $grid.headers.manual_allocations.hidden = false;
          $grid.headers.wave_id.hidden = false;
  
      }
  }
  else {
      $grid.headers.Loaded.hidden = false;
      $grid.headers.Picked.hidden = false;
      $grid.headers.manual_allocations.hidden = false;
      $grid.headers.wave_id.hidden = false;
  
      $grid.headers.Received.hidden = false;
      $grid.headers.Putaway.hidden = false;
  }
  }
  on_filter_changed(event = null) {
    return this.on_filter_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_filter_changedInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Get warehouse timezone details
  if ($utils.isDefined($grid.filters.from_date.control.value) || $utils.isDefined($grid.filters.to_date.control.value)) {
      let timeZoneDates = (await $flows.DockAppointments.convert_dates_by_warehouse_timezones({ warehouseIds: $grid.inParams.warehouseIds, dates: { fromDate: $utils.date.startOf('day', $grid.filters.from_date.control.value), toDate: $utils.date.endOf('day', $grid.filters.to_date.control.value) } })).timeZoneDates;
      $grid.vars.timeZoneDates = timeZoneDates.map(tz => ({ timeZoneId: tz.timeZoneId, fromDate: tz.dates.fromDate, toDate: tz.dates.toDate }));
  }
  else {
      $grid.vars.timeZoneDates = [];
  }
  
  $grid.refresh();
  }
  on_previous_day(event = null) {
    return this.on_previous_dayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_previous_dayInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.add(-1, 'day', $grid.filters.from_date.control.value);
  $grid.filters.to_date.control.value = $utils.date.add(-1, 'day', $grid.filters.to_date.control.value);
  $grid.refresh();
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_next_day(event = null) {
    return this.on_next_dayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_next_dayInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.add(1, 'day', $grid.filters.from_date.control.value);
  $grid.filters.to_date.control.value = $utils.date.add(1, 'day', $grid.filters.to_date.control.value);
  $grid.refresh();
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_today(event = null) {
    return this.on_todayInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_todayInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.filters.from_date.control.value = $utils.date.now();
  $grid.filters.to_date.control.value = $utils.date.now();
  $grid.refresh();
  
  // Emit event data
  await $grid.on_filter_changed();
  }
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $grid: FootPrintManager_dock_appointments_dashboard_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $grid.refresh();
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
