<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
        <div data-cy="tool-id-edit" *ngIf="!toolbar.edit.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.edit.control.readOnly"
                [ngStyle]="toolbar.edit.control.styles.style"
                [ngClass]="toolbar.edit.control.styles.classes"
                (click)="on_edit_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.edit.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.edit.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.edit.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-create_inventory" *ngIf="!toolbar.create_inventory.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_inventory.control.readOnly"
                [ngStyle]="toolbar.create_inventory.control.styles.style"
                [ngClass]="toolbar.create_inventory.control.styles.classes"
                (click)="on_create_inventory_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_inventory.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_inventory.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_inventory.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_available_amount_widget.hidden" class="widget-container">
            <Inventory-total_available_amount_widget
            #$widgets_total_available_amount_widget 
              [lotId]="$widgets_total_available_amount_widget_inParams_lotId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_amount_widget')"
            >
            </Inventory-total_available_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [lotId]="$widgets_total_gross_weight_widget_inParams_lotId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden" class="widget-container">
            <Inventory-total_packaged_amount_widget
            #$widgets_total_packaged_amount_widget 
              [lotId]="$widgets_total_packaged_amount_widget_inParams_lotId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </Inventory-total_packaged_amount_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory.hidden" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.licenseplates.hidden" class="tab" data-cy="tab-licenseplates">
              <h2 [className]="tabs.licenseplates.active? 'active': ''" (click)="tabs.licenseplates.activate()">{{tabs.licenseplates.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.serialnumbers.hidden" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
            <div *ngIf="!tabs.udfs.hidden" class="tab" data-cy="tab-udfs">
              <h2 [className]="tabs.udfs.active? 'active': ''" (click)="tabs.udfs.activate()">{{tabs.udfs.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-inventory_location_grid *ngIf="tabs.inventory.active"
              #$tabs_inventory
              [lotId]="$tabs_inventory_inventory_location_grid_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory')">
              </FootPrintManager-inventory_location_grid>
              <FootPrintManager-licenseplate_list *ngIf="tabs.licenseplates.active"
              #$tabs_licenseplates
              [lotId]="$tabs_licenseplates_licenseplate_list_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplates')">
              </FootPrintManager-licenseplate_list>
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [lotId]="$tabs_tasks_inventory_tasks_grid_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [lotId]="$tabs_serialnumbers_serialnumbers_grid_inParams_lotId"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
              <FootPrintManager-lots_udf_grid *ngIf="tabs.udfs.active"
              #$tabs_udfs
              [lotId]="$tabs_udfs_lots_udf_grid_inParams_lotId"
              ($refreshEvent)="refresh(false, false, '$tabs_udfs')">
              </FootPrintManager-lots_udf_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>