<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-new_line" *ngIf="!topToolbar.new_line.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.new_line.control.readOnly"
            [ngStyle]="topToolbar.new_line.control.styles.style"
            [ngClass]="topToolbar.new_line.control.styles.classes"
            (click)="on_new_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.new_line.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.new_line.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.new_line.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-delete_line" *ngIf="!topToolbar.delete_line.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_line.control.readOnly"
            [ngStyle]="topToolbar.delete_line.control.styles.style"
            [ngClass]="topToolbar.delete_line.control.styles.classes"
            (click)="on_delete($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_line.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_line.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_line.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="LineNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LineNumber.displayControl.styles.style"
          [ngClass]="row.cells.LineNumber.displayControl.styles.classes">{{row.cells.LineNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="BillingAggregationStrategy">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.BillingAggregationStrategy.displayControl.styles.style"
          [ngClass]="row.cells.BillingAggregationStrategy.displayControl.styles.classes">{{row.cells.BillingAggregationStrategy.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="BillingObject">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.BillingObject.displayControl.styles.style"
          [ngClass]="row.cells.BillingObject.displayControl.styles.classes">{{row.cells.BillingObject.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="BillingMethod">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.BillingMethod.displayControl.styles.style"
          [ngClass]="row.cells.BillingMethod.displayControl.styles.classes">{{row.cells.BillingMethod.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ResultingBillingCode_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ResultingBillingCode_Name.displayControl.styles.style"
          [ngClass]="row.cells.ResultingBillingCode_Name.displayControl.styles.classes">{{row.cells.ResultingBillingCode_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="OperationCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.OperationCode.displayControl.styles.style"
          [ngClass]="row.cells.OperationCode.displayControl.styles.classes">{{row.cells.OperationCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Rate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Rate.displayControl.styles.style"
          [ngClass]="row.cells.Rate.displayControl.styles.classes">{{row.cells.Rate.displayControl.text | formatText : row.cells.Rate.displayControl.formatType : row.cells.Rate.displayControl.format  }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Warehouse_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Warehouse_Name.displayControl.styles.style"
          [ngClass]="row.cells.Warehouse_Name.displayControl.styles.classes">{{row.cells.Warehouse_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Priority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Priority.displayControl.styles.style"
          [ngClass]="row.cells.Priority.displayControl.styles.classes">{{row.cells.Priority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ResultingUom_Name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ResultingUom_Name.displayControl.styles.style"
          [ngClass]="row.cells.ResultingUom_Name.displayControl.styles.classes">{{row.cells.ResultingUom_Name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="StartDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.StartDate.displayControl.styles.style"
          [ngClass]="row.cells.StartDate.displayControl.styles.classes">{{row.cells.StartDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="EndDate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.EndDate.displayControl.styles.style"
          [ngClass]="row.cells.EndDate.displayControl.styles.classes">{{row.cells.EndDate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CreatedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysDateTime.displayControl.styles.classes">{{row.cells.CreatedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CreatedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysUser.displayControl.styles.classes">{{row.cells.CreatedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ModifiedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysDateTime.displayControl.styles.classes">{{row.cells.ModifiedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ModifiedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysUser.displayControl.styles.classes">{{row.cells.ModifiedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
