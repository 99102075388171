import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Locations_locations_dd_singleComponent } from './Locations.locations_dd_single.component'
import { Inventory_licenseplates_dd_singleComponent } from './Inventory.licenseplates_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_locations_dd_singleComponent),
    forwardRef(() => Inventory_licenseplates_dd_singleComponent),
  ],
  selector: 'FootPrintManager-get_licenseplate_form',
  templateUrl: './FootPrintManager.get_licenseplate_form.component.html'
})
export class FootPrintManager_get_licenseplate_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId: number } = { warehouseId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { locationId?: number, licenseplateId?: number } = { locationId: null, licenseplateId: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ locationId?: number, licenseplateId?: number }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    location: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    is_new_licenseplate: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    existing_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    new_licenseplate: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    location: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['location'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Location', true)
,
    is_new_licenseplate: new FieldModel(new CheckBoxModel(this.formGroup.controls['is_new_licenseplate'] as DatexFormControl, null, false, 'New license plate?')
, new ControlContainerStyles(null, null), '', false)
,
    existing_licenseplate: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['existing_licenseplate'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'License plate', true)
,
    new_licenseplate: new FieldModel(new TextBoxModel(this.formGroup.controls['new_licenseplate'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'License plate', true)
,
  };

  fieldsets = {
  location: new FieldsetModel('Location', true, false, true),
  licenseplate: new FieldsetModel('License plate', true, false, true),
};

  //#region fields inParams
  get $fields_location_selector_inParams_warehouseId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.inParams.warehouseId;
    
    return expr;
  }

  get $fields_location_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_existing_licenseplate_selector_inParams_locationId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.location.control.value;
    
    return expr;
  }

  get $fields_existing_licenseplate_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_existing_licenseplate_selector_inParams_archived(): boolean {
    const $form = this;
    const $utils = this.utils;
    const expr = false;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    location: this.fields.location.control.valueChanges
    ,
    is_new_licenseplate: this.fields.is_new_licenseplate.control.valueChanges
    ,
    existing_licenseplate: this.fields.existing_licenseplate.control.valueChanges
    ,
    new_licenseplate: this.fields.new_licenseplate.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Get license plate';
  
    const $form = this;
    const $utils = this.utils;

    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .location
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_location_changed();
      });
    this.$formGroupFieldValidationObservables
      .is_new_licenseplate
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_is_new_licenseplate_changed();
      });
    this.$formGroupFieldValidationObservables
      .existing_licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .new_licenseplate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_get_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.fields.new_licenseplate.hidden = true;
  }
  on_location_changed(event = null) {
    return this.on_location_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_changedInternal(
    $form: FootPrintManager_get_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($form.fields.location.control.value)) {
      // Check if loose location
      let location = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $form.fields.location.control.value })).result[0];
  
      if (location.IsLoose) {
          // Hide license plate fields
          $form.fields.is_new_licenseplate.hidden = true;
          $form.fields.existing_licenseplate.hidden = true;
          $form.fields.new_licenseplate.hidden = true;
      }
      else {
          // Show license plate fields
          $form.fields.is_new_licenseplate.hidden = false;
  
          if ($form.fields.is_new_licenseplate.control.value) {
              $form.fields.existing_licenseplate.hidden = true;
              $form.fields.new_licenseplate.hidden = false;
          }
          else {
              $form.fields.existing_licenseplate.hidden = false;
              $form.fields.new_licenseplate.hidden = true;
          }
      }
  }
  }
  on_is_new_licenseplate_changed(event = null) {
    return this.on_is_new_licenseplate_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_is_new_licenseplate_changedInternal(
    $form: FootPrintManager_get_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.is_new_licenseplate.control.value) {
      $form.fields.existing_licenseplate.hidden = true;
      $form.fields.new_licenseplate.hidden = false;
  }
  else {
      $form.fields.existing_licenseplate.hidden = false;
      $form.fields.new_licenseplate.hidden = true;
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_get_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.close();
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_get_licenseplate_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Confirm all fields are selected 
      if (!$utils.isDefined($form.fields.location.control.value)) {
          throw new Error('Please select a location.');
      }
  
      let selectedLocation = (await $datasources.Locations.ds_get_location_by_locationId.get({ locationId: $form.fields.location.control.value })).result[0];
  
  
      if (selectedLocation.IsLoose) {
          // Get loose license plate
          let licenseplates = (await $datasources.Locations.ds_get_licenseplates_by_locationId.get({ locationId: selectedLocation.Id })).result;
          let licenseplate = licenseplates.find(lp => lp.TypeId === 3);
  
          if (!$utils.isDefined(licenseplate)) {
              throw new Error(`Unable to find loose license plate for location ${selectedLocation.Name}.`);
          }
  
          $form.outParams.locationId = selectedLocation.Id;
          $form.outParams.licenseplateId = licenseplate.Id;
      }
      else {
          if ($form.fields.is_new_licenseplate.control.value) {
              // Create new license plate
              if (!$utils.isDefinedTrimmed($form.fields.new_licenseplate.control.value)) {
                  throw new Error(`Please provide a lookup code for the new license plate.`);
              }
  
              let licenseplateCode = $form.fields.new_licenseplate.control.value.trim();
  
              let matchingLicenseplates = (await $datasources.Inventory.ds_get_licenseplate_by_lookupcode_warehouse_archived.get({ warehouseId: $form.inParams.warehouseId, lookupcode: licenseplateCode, archived: false })).result;
              if ($utils.isDefined(matchingLicenseplates)) {
                  throw new Error(`License plate with lookup code '${licenseplateCode}' already exists within the warehouse.`);
              }
  
              let result = (await $flows.Inventory.create_licenseplate_flow({
                  dimensionsUomId: null,
                  locationId:selectedLocation.Id,
                  lookupCode: licenseplateCode,
                  statusId: 1,
                  typeId: 1,
                  volumeUomId: null,
                  warehouseId: selectedLocation.WarehouseId,
                  weightUomId: null
              }));
  
              if (!$utils.isDefined(result?.licensePlateId)) {
                  throw new Error(`Failed to create new license plate '${licenseplateCode}': ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result?.reasons})).formattedValue}`);
              }
  
              $form.outParams.locationId = selectedLocation.Id;
              $form.outParams.licenseplateId = result.licensePlateId;
          }
          else {
              // Confirm license plate is selected
              if (!$utils.isDefined($form.fields.existing_licenseplate.control.value)) {
                  throw new Error(`Please select a license plate.`);
              }
  
              $form.outParams.locationId = selectedLocation.Id;
              $form.outParams.licenseplateId = $form.fields.existing_licenseplate.control.value;
          }
      }
  
      $form.close();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      // TODO: display error
  }
  
  
  /****************************************************
   * FUNCTIONS
  *****************************************************/
  
  }
  //#endregion private flows
}
