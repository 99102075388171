<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div data-cy="tool-id-on_confirm" *ngIf="!topToolbar.on_confirm.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_confirm.control.readOnly"
            [ngStyle]="topToolbar.on_confirm.control.styles.style"
            [ngClass]="topToolbar.on_confirm.control.styles.classes"
            (click)="on_confirm_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_confirm.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_confirm.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_confirm.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-received" *ngIf="!topToolbar.received.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.received.control.readOnly"
            [ngStyle]="topToolbar.received.control.styles.style"
            [ngClass]="topToolbar.received.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.received.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.received.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.received.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-current_tally" *ngIf="!topToolbar.current_tally.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.current_tally.control.readOnly"
            [ngStyle]="topToolbar.current_tally.control.styles.style"
            [ngClass]="topToolbar.current_tally.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.current_tally.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.current_tally.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.current_tally.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="lineNumber">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lineNumber.displayControl.styles.style"
          [ngClass]="row.cells.lineNumber.displayControl.styles.classes">{{row.cells.lineNumber.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Materials-material_packagings_dd_single 
        data-cy="selector"
        [type]="row.cells.packaging.editControl.type"
        [formControl]="row.formGroup.controls['packaging_edit']"
        (displayTextChange)="row.cells.packaging.editControl.displayText=$event"
        [placeholder]="row.cells.packaging.editControl.placeholder"
        [styles]="row.cells.packaging.editControl.styles"
      [materialId]="row.$fields_packaging_selector_inParams_materialId"
    >
    </Materials-material_packagings_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.quantity.displayControl.styles.style"
          [ngClass]="row.cells.quantity.displayControl.styles.classes">{{row.cells.quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['quantity_edit']"
            matInput
            numberBox
            [format]="row.cells.quantity.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.quantity.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.quantity.editControl.placeholder}}"
            [ngStyle]="row.cells.quantity.editControl.styles.style"
            [ngClass]="row.cells.quantity.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Locations-locations_dd_single 
        data-cy="selector"
        [type]="row.cells.location.editControl.type"
        [formControl]="row.formGroup.controls['location_edit']"
        (displayTextChange)="row.cells.location.editControl.displayText=$event"
        [placeholder]="row.cells.location.editControl.placeholder"
        [styles]="row.cells.location.editControl.styles"
      [warehouseId]="row.$fields_location_selector_inParams_warehouseId"
      [typeId]="row.$fields_location_selector_inParams_typeId"
    >
    </Locations-locations_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['licenseplate_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.licenseplate.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.licenseplate.editControl.placeholder}}"
            [ngStyle]="row.cells.licenseplate.editControl.styles.style"
            [ngClass]="row.cells.licenseplate.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootPrintManager-lots_dd_single 
        data-cy="selector"
        [type]="row.cells.lot.editControl.type"
        [formControl]="row.formGroup.controls['lot_edit']"
        (displayTextChange)="row.cells.lot.editControl.displayText=$event"
        [placeholder]="row.cells.lot.editControl.placeholder"
        [styles]="row.cells.lot.editControl.styles"
      [materialId]="row.$fields_lot_selector_inParams_materialId"
      [vendorLotId]="row.$fields_lot_selector_inParams_vendorLotId"
    >
    </FootPrintManager-lots_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendorlot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vendorlot.displayControl.styles.style"
          [ngClass]="row.cells.vendorlot.displayControl.styles.classes">{{row.cells.vendorlot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootPrintManager-vendorlots_dd_single 
        data-cy="selector"
        [type]="row.cells.vendorlot.editControl.type"
        [formControl]="row.formGroup.controls['vendorlot_edit']"
        (displayTextChange)="row.cells.vendorlot.editControl.displayText=$event"
        [placeholder]="row.cells.vendorlot.editControl.placeholder"
        [styles]="row.cells.vendorlot.editControl.styles"
      [materialId]="row.$fields_vendorlot_selector_inParams_materialId"
      [lotId]="row.$fields_vendorlot_selector_inParams_lotId"
    >
    </FootPrintManager-vendorlots_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
