<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-view" *ngIf="!toolbar.view.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.view.control.readOnly"
              [ngStyle]="toolbar.view.control.styles.style"
              [ngClass]="toolbar.view.control.styles.classes"
              (click)="on_view($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.view.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.view.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.view.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-view1"
                     *ngIf="!fieldsets.view1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.view1.collapsible }">
                    <div *ngIf="!fieldsets.view1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.view1.toggle()">
                      <span class="fieldsetsTitle-text">View1</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.view1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.view1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.view1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.view1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-request_id" *ngIf="!fields.request_id.hidden" 
                            class="field-container full {{fields.request_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.request_id.styles.style"
                            [ngClass]="fields.request_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.request_id.label}}{{fields.request_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.request_id.label}}">{{fields.request_id.label}}<span *ngIf="fields.request_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="request_id"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.request_id.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.request_id.control.placeholder}}"
                                [ngStyle]="fields.request_id.control.styles.style"
                                [ngClass]="fields.request_id.control.styles.classes"> 
                        <ng-container *ngIf="fields.request_id.invalid">
                          <ng-container *ngFor="let error of fields.request_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-view1" *ngIf="!fields.view1.hidden" 
                            class="field-container full {{fields.view1.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.view1.styles.style"
                            [ngClass]="fields.view1.styles.classes">
                        <div class="label-container"
                              title="{{fields.view1.label}}{{fields.view1.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.view1.label}}">{{fields.view1.label}}<span *ngIf="fields.view1.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <ngx-codemirror
                                    formControlName="view1"
                                    [options]="{
                                    theme: 'base16-light',
                                    mode: fields.view1.control.codeMirrorMode,
                                    lineNumbers: true,
                                    lineWrapping: true,
                                    foldGutter: true,
                                    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
                                    autoCloseBrackets: true,
                                    matchBrackets: true,
                                    lint: true
                                    }"
                                    [CodeValidator]="fields.view1.control.mode"
                                    >
                        </ngx-codemirror>
                        
                        <button type="button margin-top-10"
                                (click)="fields.view1.control.beautify();"
                                class="datex-button secondary ">Beautify</button>
                        <ng-container *ngIf="fields.view1.invalid">
                          <ng-container *ngFor="let error of fields.view1.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>