import { ControlModel } from './control';

// TODO: To discuss if we want to do the same as field for tools
export class ToolModel<TC extends ControlModel> {
  control: TC;
  hidden: boolean;

  constructor(control: TC) {
    this.control = control;
  }
}