import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Returns_projects_dd_singleComponent } from './Returns.projects_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { Lots_lot_statuses_dd_singleComponent } from './Lots.lot_statuses_dd_single.component'
import { Returns_material_packagings_dd_singleComponent } from './Returns.material_packagings_dd_single.component'
import { Returns_condition_dd_singleComponent } from './Returns.condition_dd_single.component'


interface IFootPrintManager_blind_shipping_container_return_tasks_gridComponentEntity {
Id?: number, ActualInventoryAmount?: number, ActualPackagedAmount?: number, ExpectedPackagedAmount?: number, ExpectedTargetLocationId?: number, Result?: string, StatusId?: number, WarehouseId?: number, Material?: { Id?: number, LookupCode?: string }, VendorLot?: { Id?: number, ExpirationDate?: string, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string, Status?: { Id?: number, Name?: string } }, ActualPackagedPack?: { Id?: number, ShortName?: string }, Order?: { Id?: number, LookupCode?: string }, Project?: { Id?: number, LookupCode?: string }, SerialNumber?: { Id?: number, LookupCode?: string }, ActualTargetLicensePlate?: { Id?: number, LookupCode?: string }}

interface IFootPrintManager_blind_shipping_container_return_tasks_gridComponentInParams {
  warehouseId?: number, locationId?: number, sourceShippingContainerId: number, sourceLicensePlateId?: number, inputScan?: string}


class FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent;
  entity: IFootPrintManager_blind_shipping_container_return_tasks_gridComponentEntity;

  vars: { serialNumberId?: number, vendorLotId?: number, lotId?: number, licensePlateStatusId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    project_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    material_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    vendor_lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    expiration_date_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    lot_status_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    actual_packaged_amount_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    actual_packaging_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    serial_number_code_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    fill_percentage_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    condition_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    disposition_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    actual_target_license_plate_edit: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
  });

  override cells = {
    project: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['project_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    vendor_lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['vendor_lot_edit'] as DatexFormControl, null, false, '')
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['lot_edit'] as DatexFormControl, null, false, '')
      ),
    expiration_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new DateBoxModel(this.formGroup.controls['expiration_date_edit'] as DatexFormControl, null, false, '', 'date')
      ),
    lot_status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['lot_status_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    actual_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['actual_packaged_amount_edit'] as DatexFormControl, null, false, '', '')
      ),
    actual_packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['actual_packaging_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    serial_number_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['serial_number_code_edit'] as DatexFormControl, null, false, '')
      ),
    fill_percentage: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['fill_percentage_edit'] as DatexFormControl, null, false, '', '')
      ),
    condition: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['condition_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    disposition: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['disposition_edit'] as DatexFormControl, null, true, '')
      ),
    actual_target_license_plate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new TextBoxModel(this.formGroup.controls['actual_target_license_plate_edit'] as DatexFormControl, null, false, '')
      ),
    order_code: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }

  get $fields_project_selector_inParams_statusId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = 1;
    
    return expr;
  }
  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.project.editControl.value;
    
    return expr;
  }
  get $fields_actual_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
    this.formGroup
      .controls['project_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_change();
      });
    this.formGroup
      .controls['vendor_lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_change();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_change();
      });
    this.formGroup
      .controls['expiration_date_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_row_state();
      });
    this.formGroup
      .controls['serial_number_code_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_serial_number_code_change();
      });
    this.formGroup
      .controls['fill_percentage_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_fill_percentage_change();
      });
    this.formGroup
      .controls['condition_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.set_row_state();
      });
  }

  async $initializeExisting(grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, entity: IFootPrintManager_blind_shipping_container_return_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, entity?: IFootPrintManager_blind_shipping_container_return_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
      await this.on_row_init();
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      shippingContainerId:  $grid.inParams.sourceShippingContainerId ,
      taskStatusIds:  null ,
    };
    const data = await this.datasources.Returns.ds_blind_shipping_container_return_tasks_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.project.displayControl as TextModel).text = $row.entity?.Project?.LookupCode;
    (this.cells.project.editControl as SelectBoxModel).reset($row.entity?.Project?.Id);
    (this.cells.material.displayControl as TextModel).text = $row.entity?.Material?.LookupCode;
    (this.cells.vendor_lot.displayControl as TextModel).text = $row.entity?.VendorLot?.LookupCode;
    
    (this.cells.lot.displayControl as TextModel).text = $row.entity?.Lot?.LookupCode;
    
    (this.cells.expiration_date.displayControl as TextModel).text = $row.entity?.VendorLot?.ExpirationDate?.toString();
    (this.cells.expiration_date.editControl as DateBoxModel).reset($row.entity?.VendorLot?.ExpirationDate);
    (this.cells.lot_status.displayControl as TextModel).text = $row.entity?.Lot?.Status?.Name;
    (this.cells.lot_status.editControl as SelectBoxModel).reset($row.entity?.Lot?.Status?.Id);
    (this.cells.actual_packaged_amount.displayControl as TextModel).text = $row.entity?.ActualPackagedAmount?.toString();
    (this.cells.actual_packaged_amount.editControl as NumberBoxModel).reset($row.entity?.ActualPackagedAmount);
    (this.cells.actual_packaging.displayControl as TextModel).text = $row.entity?.ActualPackagedPack?.ShortName;
    (this.cells.actual_packaging.editControl as SelectBoxModel).reset($row.entity?.ActualPackagedPack?.Id);
    (this.cells.serial_number_code.displayControl as TextModel).text = $row.entity?.SerialNumber?.LookupCode;
    (this.cells.serial_number_code.editControl as TextBoxModel).reset($row.entity?.SerialNumber?.LookupCode);
    
    (this.cells.actual_target_license_plate.displayControl as TextModel).text = $row.entity?.ActualTargetLicensePlate?.LookupCode;
    (this.cells.actual_target_license_plate.editControl as TextBoxModel).reset($row.entity?.ActualTargetLicensePlate?.LookupCode);
    (this.cells.order_code.displayControl as TextModel).text = $row.entity?.Order?.LookupCode;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.entity)) {
      let result = $utils.parseXml($row.entity.Result);
  
      if ($utils.isDefined(result)) {
          $row.cells.fill_percentage.displayControl.text = result.OtherProperties.FillPercentage;
          $row.cells.condition.displayControl.text = result.OtherProperties.Condition;
          $row.cells.disposition.displayControl.text = result.OtherProperties.Disposition;
      }
  }
  
  
  // Set Date/Time Formats
  const format = `${$settings.FootPrintManager.DateFormat}`;
  
  if ($utils.isDefined(format)) {
  
      if ($utils.isDefined($row.entity?.VendorLot?.ExpirationDate)) {
          $row.cells.expiration_date.displayControl.text = $utils.date.format($row.entity.VendorLot.ExpirationDate, format);
      };
  }
  }
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      // Validate data
      if (!$utils.isDefined($row.cells.material.editControl.value)) { throw new Error(`Material '${$row.cells.material.editControl.value}' does not exist for project '${$row.cells.project.editControl.displayText}'`); }
  
      if (!$utils.isDefined($row.vars.vendorLotId) && !$utils.isDefinedTrimmed($row.cells.vendor_lot.editControl.value)) {
          throw new Error(`Please enter a valid value for 'Vendor lot'`);
      }
      if (!$utils.isDefined($row.vars.lotId) && !$utils.isDefinedTrimmed($row.cells.lot.editControl.value)) {
          throw new Error(`Please enter a valid value for 'Lot'`);
      }
  
      // Validate license plate
      if (!$utils.isDefinedTrimmed($row.cells.actual_target_license_plate.editControl.value)) { throw new Error('Please provide a target license plate'); }
  
  
      let material = (await $datasources.Materials.ds_get_material_by_materialId.getList({ $top: 1, materialId: $row.cells.material.editControl.value })).result[0];
  
      // Find or create lot
      if (material.ControllerTypeId === 2 || material.ControllerTypeId === 4) { // Is lot controlled
          if (!$utils.isDefined($row.vars.vendorLotId)) {
              $row.vars.vendorLotId = (await $flows.Lots.create_vendor_lot({
                  materialId: $row.cells.material.editControl.value,
                  lookupCode: $row.cells.vendor_lot.editControl.value,
                  expirationDate: $row.cells.expiration_date.editControl.value
              })).vendorLotId;
          }
  
          if (!$utils.isDefined($row.vars.lotId)) {
              $row.vars.lotId = (await $flows.Lots.create_lot({
                  vendorLotId: $row.vars.vendorLotId,
                  lookupCode: $row.cells.lot.editControl.value,
                  statusId: $row.cells.lot_status.editControl.value
              })).lotId;
          }
      }
      else {
          if (!$utils.isDefined($row.vars.vendorLotId)) {
              throw new Error(`Invalid data, cannot specify a vendor lot for a material that is not lot-controlled`);
          }
  
          if (!$utils.isDefined($row.vars.lotId)) {
              throw new Error(`Invalid data, cannot specify a lot for a material that is not lot-controlled`);
          }
      }
  
      // Find or create license plate
      let licenseplateId = await getLicensePlateId();
  
      // Find / Create serial number
      let serialNumberId = await getSerialNumberId(licenseplateId);
  
  
      // Create task
      let result = (await $flows.Returns.create_or_update_return_task_flow({
          projectId: $row.cells.project.editControl.value,
          materialId: $row.cells.material.editControl.value,
          vendorLotId: $row.vars.vendorLotId,
          lotId: $row.vars.lotId,
          packagingId: $row.cells.actual_packaging.editControl.value,
          packagedAmount: $row.cells.actual_packaged_amount.editControl.value,
          warehouseId: $grid.inParams.warehouseId,
          chainHead: 0,
          sourceLicensePlateId: $grid.inParams.sourceLicensePlateId,
          sourceShippingContainerId: $grid.inParams.sourceShippingContainerId,
          targetLicensePlateId: licenseplateId,
          serialNumberId: serialNumberId,
          otherProperties: {
              FillPercentage: $row.cells.fill_percentage.editControl.value,
              Condition: $row.cells.condition.editControl.value,
              Disposition: $row.cells.disposition.editControl.value
          }
      }));
  
      if ($utils.isDefined(result?.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
      }
  
      $row.entity.Id = result.taskId;
  
      $row.refresh();
  
      $grid.events.tasksChanged.emit();
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.Returns.openErrorDialog('Error confirming return task', `Could not confirm return task: ${targetError.message}.`);
      throw error;
  }
  
  
  /**********************************************
   * FUNCTIONS
  ***********************************************/
  
  async function getLicensePlateId() {
      let licenseplate = (await $datasources.Inventory.ds_get_licenseplate_by_lookupcode_warehouse_archived.getList({ warehouseId: $grid.inParams.warehouseId, lookupcode: $row.cells.actual_target_license_plate.editControl.value, archived: false })).result[0];
      let licenseplateId: number;
  
      if ($utils.isDefined(licenseplate)) {
  
          if (licenseplate.TypeId !== 5) { throw new Error('License plate already exists, but is not eligible to use for blind returns because it is not the correct type'); }
          if ($utils.isDefined(licenseplate.ShipmentId)) { throw new Error('License plate already exists, but is not eligible to use for blind returns because it is already on a shipment'); }
          if (($utils.isDefined($row.vars.licensePlateStatusId) && licenseplate.StatusId !== $row.vars.licensePlateStatusId) || licenseplate.LocationId !== $grid.inParams.locationId) { 
              throw new Error('The specified license plate is being used for a conflicting task'); 
          }
  
          let existingTasks = (await $datasources.Returns.ds_get_return_tasks_by_targetLicensePlateId.get({ targetLicensePlateId: licenseplate.Id })).result;
  
          if ($utils.isDefined(existingTasks.find(t => t.ProjectId !== $row.cells.project.editControl.value || t.ActualSourceShippingContainerId !== $grid.inParams.sourceShippingContainerId))) {
              throw new Error('The specified license plate is being used for a conflicting task');
          }
  
          licenseplateId = licenseplate.Id;
      }
      else {
          licenseplateId = (await $flows.Inventory.create_licenseplate_flow({
              archived: false,
              dimensionsUomId: null,
              volumeUomId: null,
              weightUomId: null,
              locationId: $grid.inParams.locationId,
              lookupCode: $row.cells.actual_target_license_plate.editControl.value,
              statusId: $row.vars.licensePlateStatusId ?? 1,
              typeId: 5,
              warehouseId: $grid.inParams.warehouseId
          })).licensePlateId;
      }
  
      return licenseplateId;
  }
  
  async function getSerialNumberId(licenseplateId: number) {
      let serialNumberId: number;
  
      if ($utils.isDefinedTrimmed($row.cells.serial_number_code.editControl.value)) {
          let existingSerial = (await $datasources.SerialNumbers.ds_find_serialnumbers.getList({ lookupcode: $row.cells.serial_number_code.editControl.value, lotId: $row.vars.lotId })).result[0];
  
          if ($utils.isDefined(existingSerial)) {
              if (existingSerial.Archived) { // Restore serial number
                  // Check if being used for another task
                  let taskCheck = (await $datasources.Returns.ds_find_tasks.get({ serialNumberId: existingSerial.Id, operationCodeId: 2, statusId: [1,4] })).result;
  
                  if ($utils.isDefined(taskCheck)) { throw new Error(`Serial number '${$row.cells.serial_number_code.editControl.value}' is already being used for another task`); }
  
                  serialNumberId = existingSerial.Id;
              }
              else {
                  throw new Error(`Serial number '${$row.cells.serial_number_code.editControl.value}' already exists for this lot`);
              }
          }
          else { // Create new serial number from lookup code
  
              let serialResult = (await $flows.SerialNumbers.create_serial_numbers_flow({
                  serialNumbers: [{
                      Archived: false, 
                      LicensePlateId: licenseplateId,
                      LotId: $row.vars.lotId,
                      PackagingId: $row.cells.actual_packaging.editControl.value,
                      StatusId: 1,
                      LookupCode: $row.cells.serial_number_code.editControl.value.trim(),
                      MaterialId: $row.cells.material.editControl.value
                  }]
              }));
  
              if ($utils.isDefined(serialResult.reasons)) {
                  throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: serialResult.reasons })).formattedValue);
              }
  
              serialNumberId = serialResult.serialNumberIds[0];
          }
      }
  
      return serialNumberId;
  }
  }
  on_material_change(event = null) {
    return this.on_material_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changeInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.set_row_state();
  }
  on_vendorlot_change(event = null) {
    return this.on_vendorlot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changeInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.set_row_state();
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      let result = (await $flows.Returns.create_or_update_return_task_flow({
          projectId: $row.cells.project.editControl.value, 
          materialId: $row.cells.material.editControl.value, 
          vendorLotId: $row.cells.vendor_lot.editControl.value, 
          lotId: $row.cells.lot.editControl.value, 
          packagingId: $row.cells.actual_packaging.editControl.value, 
          packagedAmount: $row.cells.actual_packaged_amount.editControl.value, 
          warehouseId: $grid.inParams.warehouseId, 
          chainHead: 0, 
          sourceLicensePlateId: $grid.inParams.sourceLicensePlateId, 
          sourceShippingContainerId: $grid.inParams.sourceShippingContainerId,
          targetLicensePlateId: $row.cells.actual_target_license_plate.editControl.value, 
          serialNumberId: $row.cells.serial_number_code.editControl.value,
          otherProperties: {
              FillPercentage: $row.cells.fill_percentage.editControl.value,
              Condition: $row.cells.condition.editControl.value,
              Disposition: $row.cells.disposition.editControl.value
          },
          taskId: $row.entity.Id
      }));
  
      if ($utils.isDefined(result?.reasons)) {
          throw new Error((await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue);
      }
  
      $row.refresh();
  }
  catch (error) {
      let targetError = error;
  
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      await $shell.Returns.openErrorDialog('Error confirming return task', `Could not confirm return task: ${targetError.message}.`);
  }
  }
  on_lot_change(event = null) {
    return this.on_lot_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changeInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.set_row_state();
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.set_row_state();
  }
  set_row_state(event = null) {
    return this.set_row_stateInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async set_row_stateInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$grid.vars.skipDelay) {
      await keystrokeDelay();
  }
  
  if (!$grid.vars.suppressFlows) {
      let isProjectReadOnly = false;
      let isMaterialReadOnly = false;
      let isVendorLotReadOnly = false;
      let isLotReadOnly = false;
      let isPackagingReadOnly = false;
      let isExpirationDateReadOnly = false;
      let isLotStatusReadOnly = false;
      let isPackagedAmountReadOnly = false;
      let isSerialNumberReadOnly = false;
  
      $row.vars.vendorLotId = null;
      $row.vars.lotId = null;
      $row.vars.licensePlateStatusId = null;
      $row.vars.serialNumberId = null;
  
      try {
          // Find project and gtin by vendor lot
          if (!$utils.isDefined($row.cells.project.editControl.value) && $utils.isDefined($row.cells.vendor_lot.editControl.value)) {
              // Check if material only exists for one project, if so, automatically choose project
              let vendorLotResponse = (await $datasources.Lots.ds_get_vendor_lots_by_lookupcode.getList({ lookupCode: $row.cells.vendor_lot.editControl.value, $top: 1 }));
  
              if (vendorLotResponse.totalCount === 1) {
                  $row.cells.project.editControl.value = vendorLotResponse.result[0].Material.Project.Id;
  
                  $row.cells.material.editControl.value = vendorLotResponse.result[0].Material.Id;
  
                  if (vendorLotResponse.result[0].Lots.length === 1) {
                      $row.cells.lot.editControl.value = vendorLotResponse.result[0].Lots[0].LookupCode;
                  }
              }
          }
  
          // Find project by serial
          if (!$utils.isDefined($row.cells.project.editControl.value) && $utils.isDefined($row.cells.serial_number_code.editControl.value)) {
              let result = (await $datasources.SerialNumbers.ds_find_serialnumbers.getList({ $top: 1, lookupcode: $row.cells.serial_number_code.editControl.value }));
              let serialNumber = result.result[0];
  
              if ($utils.isDefined(serialNumber)) {
                  $row.vars.serialNumberId = serialNumber.Id;
                  $row.cells.actual_packaging.editControl.value = serialNumber.PackagingId;
                  isPackagingReadOnly = true;
  
                  let lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: serialNumber.LotId })).result[0];
                  $row.vars.lotId = lot.Id;
                  $row.cells.lot.editControl.value = lot.LookupCode;
  
                  $row.cells.project.editControl.value = serialNumber.Lot.Material.ProjectId;
  
                  $row.cells.lot_status.editControl.value = lot.Status.Id;
                  isLotStatusReadOnly = true;
              }
              else {
                  isPackagingReadOnly = true;
                  $row.cells.actual_packaging.editControl.value = null;
              }
          }
  
          // Set row state
          if ($utils.isDefined($row.cells.project.editControl.value)) {
  
              // Material exists
              if ($utils.isDefined($row.cells.material.editControl.value)) {
                  isProjectReadOnly = true;
  
                  // Get data
                  let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result[0];
  
                  // Vlot and lot
                  if (material.ControllerTypeId === 1 || material.ControllerTypeId === 3) { // Not lot controlled
                      let lot = (await $datasources.Lots.ds_find_lots.getList({ materialId: $row.cells.material.editControl.value, $top: 1 })).result[0];
  
                      // Vendor lot
                      isVendorLotReadOnly = true;
                      $row.vars.vendorLotId = lot.VendorLotId;
                      $row.cells.vendor_lot.editControl.value = null;
  
                      $row.cells.expiration_date.editControl.value = lot.VendorLot.ExpirationDate;
                      isExpirationDateReadOnly = true;
  
                      // Lot
                      isLotReadOnly = true;
                      $row.vars.lotId = lot.Id;
                      $row.cells.lot.editControl.value = null;
  
                      $row.cells.lot_status.editControl.value = lot.StatusId;
                      isLotStatusReadOnly = true;
  
                  }
                  else {  // Lot controlled
                      let matchingVendorlot = (await $datasources.Lots.ds_get_vendorlot_by_lookupcode_and_materialId.get({ vendorLookupcode: $row.cells.vendor_lot.editControl.value, materialId: $row.cells.material.editControl.value })).result[0];
  
                      if ($utils.isDefined(matchingVendorlot)) {
                          isMaterialReadOnly = true;
                          $row.cells.expiration_date.editControl.value = matchingVendorlot.ExpirationDate;
                          isExpirationDateReadOnly = true;
  
                          $row.vars.vendorLotId = matchingVendorlot.Id;
                      }
  
                      // Add call to flow
                      let lotResult = (await $flows.Returns.get_blind_return_lot_flow({ materialId: $row.cells.material.editControl.value, vendorLotId: $row.vars.vendorLotId, vendorLotCode: $row.cells.vendor_lot.editControl.value, lotCode: $row.cells.lot.editControl.value,  shippingContainerId: $grid.inParams.sourceShippingContainerId }));
  
                      $row.vars.lotId = lotResult.lot?.id;
                      $row.cells.lot.editControl.value = lotResult.lot?.lookupcode;
                      if ($utils.isDefined(lotResult.lot?.id)) {
                          $row.cells.lot_status.editControl.value = lotResult.lot.statusId;
                          isLotStatusReadOnly = true;
                      }
                  }
  
                  // If single packaging, set and lock
                  let packagings = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
  
                  if (packagings.length === 1) {
                      $row.cells.actual_packaging.editControl.value = packagings[0].PackagingId;
                      isPackagingReadOnly = true;
                  }
  
                  // If serial number is defined and exists, set UOM to existing serial number
                  if (material.ControllerTypeId === 3 || material.ControllerTypeId === 4) {
                      $row.cells.actual_packaged_amount.editControl.value = 1;
                      isPackagedAmountReadOnly = true;
  
                      if ($utils.isDefinedTrimmed($row.cells.serial_number_code.editControl.value) && !$utils.isDefined($row.vars.serialNumberId)) {
                          let result = (await $datasources.SerialNumbers.ds_find_serialnumbers.getList({ $top: 1, lookupcode: $row.cells.serial_number_code.editControl.value, vendorLotId: $row.vars.vendorLotId }));
                          let serialNumber = result.result[0];
  
                          if ($utils.isDefined(serialNumber)) {
                              $row.vars.serialNumberId = serialNumber.Id;
                              $row.cells.actual_packaging.editControl.value = serialNumber.PackagingId;
                              isPackagingReadOnly = true;
  
                              if (result.totalCount === 1) {
                                  let lot = (await $datasources.Lots.ds_get_lot_by_lotId.get({ lotId: serialNumber.LotId })).result[0];
                                  $row.vars.lotId = lot.Id;
                                  $row.cells.lot.editControl.value = lot.LookupCode;
  
                                  $row.cells.lot_status.editControl.value = lot.Status.Id;
                                  isLotStatusReadOnly = true;
                              }
                          }
                          else {
                              isPackagingReadOnly = true;
                              $row.cells.actual_packaging.editControl.value = null;
                          }
                      }
                  }
                  else {
                      isSerialNumberReadOnly = true;
                      $row.cells.serial_number_code.editControl.value = null;
                  }
  
                  // Otherwise, default to Base packaging
                  if (!$utils.isDefined($row.cells.actual_packaging.editControl.value)) {
                      let basePackaging = (await $datasources.Materials.ds_get_materials_basepackaging_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
  
                      $row.cells.actual_packaging.editControl.value = basePackaging.PackagingId;
                  }
  
  
                  if (!$utils.isDefined($row.vars.lotId) && !isLotStatusReadOnly) {
                      let result = (await $flows.Returns.get_blind_return_new_lot_status({ expirationDate: $row.cells.expiration_date.editControl.value, materialId: $row.cells.material.editControl.value, lookupcode: $row.cells.lot.editControl.value }));
                      $row.cells.lot_status.editControl.value = result.recommendedStatusId ?? 1;
                      if (result.isMandatory) {
                          isLotStatusReadOnly = true;
                      }
                  }
              }
          }
  
          // Disposition
          let newLicensePlateStatus = (await $flows.Returns.get_blind_return_new_licenseplate_status({ lotStatusId: $row.cells.lot_status.editControl.value, expirationDate: $row.cells.expiration_date.editControl.value, otherProperties: { fillPercentage: $row.cells.fill_percentage.editControl.value, condition: $row.cells.condition.editControl.value, materialId: $row.cells.material.editControl.value } })).recommendedStatus;
          if ($utils.isDefined(newLicensePlateStatus.id)) { $row.vars.licensePlateStatusId = newLicensePlateStatus.id; }
          $row.cells.disposition.displayControl.text = (newLicensePlateStatus.name ?? '').toString();
          $row.cells.disposition.editControl.value = $row.cells.disposition.displayControl.text;
  
  
          // Set license plate based on values
          if ($utils.isDefined($row.cells.project.editControl.value) &&
              $utils.isDefinedTrimmed($row.cells.disposition.displayControl.text)) {
              let licenseplates = (await $datasources.Returns.ds_get_default_licenseplate_for_return_task.get({ shippingContainerId: $grid.inParams.sourceShippingContainerId, projectId: $row.cells.project.editControl.value, locationId: $grid.inParams.locationId })).result;
  
              let defaultLicensePlate = licenseplates.find(lp => lp.ExpectedTargetLicensePlate.Status.Name.trim().toUpperCase() === $row.cells.disposition.displayControl.text.trim().toUpperCase())?.ExpectedTargetLicensePlate;
  
              if ($utils.isDefined(defaultLicensePlate)) {
                  $row.cells.actual_target_license_plate.editControl.value = defaultLicensePlate.LookupCode;
              }
              else {
                  $row.cells.actual_target_license_plate.editControl.value = '';
              }
          }
  
          // Get data from serial number
          if ($utils.isDefinedTrimmed($row.cells.serial_number_code.editControl.value) && (!$utils.isDefined($row.cells.project.editControl.value) || !$utils.isDefined($row.cells.material.editControl.value) || !$utils.isDefined($row.vars.vendorLotId) || !$utils.isDefined($row.vars.lotId) || !$utils.isDefined($row.cells.actual_packaging.editControl.value))) {
              let result = (await $datasources.SerialNumbers.ds_find_serialnumbers.getList({ $top: 1, lookupcode: $row.cells.serial_number_code.editControl.value, lotId: $row.vars.lotId, vendorLotId: $row.vars.vendorLotId, materialId: $row.cells.material.editControl.value, packagingId: $row.cells.actual_packaging.editControl.value }));
  
              if (result.totalCount === 1) {
                  let serialNumber = result.result[0];
  
                  $row.cells.project.editControl.value = serialNumber.Lot.Material.ProjectId;
  
                  $row.cells.material.editControl.value = serialNumber.Lot.Material.Id;
  
                  $row.vars.vendorLotId = serialNumber.Lot.VendorLot.Id;
                  $row.cells.vendor_lot.editControl.value = serialNumber.Lot.VendorLot.LookupCode;
  
                  $row.vars.lotId = serialNumber.Lot.Id;
                  $row.cells.lot.editControl.value = serialNumber.Lot.LookupCode;
  
                  $row.cells.actual_packaging.editControl.value = serialNumber.PackagingId;
  
                  $row.cells.expiration_date.editControl.value = serialNumber.Lot.VendorLot.ExpirationDate;
  
                  $row.cells.lot_status.editControl.value = serialNumber.Lot.StatusId;
  
                  $row.cells.actual_packaged_amount.editControl.value = 1;
  
                  $row.vars.serialNumberId = serialNumber.Id;
              }
          }
  
          if ($utils.isDefined($row.vars.serialNumberId)) {
              isProjectReadOnly = true;
              isMaterialReadOnly = true;
              isVendorLotReadOnly = true;
              isLotReadOnly = true;
              isPackagingReadOnly = true;
              isExpirationDateReadOnly = true;
              isLotStatusReadOnly = true;
              isPackagedAmountReadOnly = true;
              isSerialNumberReadOnly = true;
          }
      }
      catch (error) {
          while ($utils.isDefined(error.error)) {
              error = error.error;
          }
  
          $shell.FootPrintManager.openErrorDialog('Error on row', error.message);
      }
  
      $row.cells.project.editControl.readOnly = isProjectReadOnly;
      $row.cells.material.editControl.readOnly = isMaterialReadOnly;
      $row.cells.vendor_lot.editControl.readOnly = isVendorLotReadOnly;
      $row.cells.lot.editControl.readOnly = isLotReadOnly;
      $row.cells.actual_packaging.editControl.readOnly = isPackagingReadOnly;
      $row.cells.expiration_date.editControl.readOnly = isExpirationDateReadOnly;
      $row.cells.lot_status.editControl.readOnly = isLotStatusReadOnly;
      $row.cells.actual_packaged_amount.editControl.readOnly = isPackagedAmountReadOnly;
      $row.cells.serial_number_code.editControl.readOnly = isSerialNumberReadOnly;
  }
  
  
  
  
  /**********************************************
   * FUNCTIONS
  ***********************************************/
  async function keystrokeDelay() {
      let keystrokeDelay = 1000;
      let intervalDelay = 50;
  
      $grid.vars.delayTimer = keystrokeDelay;
  
      if (!$grid.vars.suppressFlows) {
          // Initialize and decrement until 0
          $grid.vars.suppressFlows = true;
  
          while ($grid.vars.delayTimer > 0) {
              await new Promise(result => setTimeout(result, intervalDelay));
  
              $grid.vars.delayTimer -= intervalDelay;
          }
  
          $grid.vars.suppressFlows = false;
      }
  }
  }
  on_serial_number_code_change(event = null) {
    return this.on_serial_number_code_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_serial_number_code_changeInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.set_row_state();
  }
  on_row_init(event = null) {
    return this.on_row_initInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_initInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $row.cells.fill_percentage.editControl.value = 100;
  $row.cells.condition.editControl.value = 'Damaged';
  $row.cells.disposition.editControl.value = false;
  
  $row.cells.actual_target_license_plate.editControl.focus();
  
  
  }
  on_fill_percentage_change(event = null) {
    return this.on_fill_percentage_changeInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fill_percentage_changeInternal(
    $row: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel,
  $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($utils.isDefined($row.cells.fill_percentage.editControl.value)) {
      if ($row.cells.fill_percentage.editControl.value < 0) { $row.cells.fill_percentage.editControl.value = 0; }
      if ($row.cells.fill_percentage.editControl.value > 100) { $row.cells.fill_percentage.editControl.value = 100; }
  }
  
  $row.set_row_state();
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Returns_projects_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => Lots_lot_statuses_dd_singleComponent),
    forwardRef(() => Returns_material_packagings_dd_singleComponent),
    forwardRef(() => Returns_condition_dd_singleComponent),
  ],
  selector: 'FootPrintManager-blind_shipping_container_return_tasks_grid',
  templateUrl: './FootPrintManager.blind_shipping_container_return_tasks_grid.component.html'
})
export class FootPrintManager_blind_shipping_container_return_tasks_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_blind_shipping_container_return_tasks_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('fixedWidth', ['relaxed','set-width-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_blind_shipping_container_return_tasks_gridComponentInParams = { warehouseId: null, locationId: null, sourceShippingContainerId: null, sourceLicensePlateId: null, inputScan: null };


  //#region Variables
  vars: { suppressFlows?: boolean, skipDelay?: boolean, delayTimer?: number } = { };
  //#endregion
  //#region Events
  @Output()
  tasksChanged = new EventEmitter<void>();
  
  events = {
    tasksChanged: this.tasksChanged,
  }
  //#endregion

  headers = {
     project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', true, false, 200),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', true, false, 150),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', true, false, 100),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', true, false, 100),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expiration date', true, false, 100),       lot_status: new GridHeaderModel(new HeaderStyles(null, null), 'Lot status', false, false, 130),       actual_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Quantity', true, false, 100),       actual_packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', true, false, 100),       serial_number_code: new GridHeaderModel(new HeaderStyles(null, null), 'Serial number', false, false, 100),       fill_percentage: new GridHeaderModel(new HeaderStyles(null, null), 'Fill percentage', false, false, 100),       condition: new GridHeaderModel(new HeaderStyles(null, null), 'Condition', false, false, 200),       disposition: new GridHeaderModel(new HeaderStyles(null, null), 'Disposition', false, false, 100),       actual_target_license_plate: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', true, false, 150),       order_code: new GridHeaderModel(new HeaderStyles(null, null), 'Order', false, false, 100),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('locationId') set $inParams_locationId(value: any) {
    this.inParams['locationId'] = value;
  }
  get $inParams_locationId(): any {
    return this.inParams['locationId'] ;
  }
  @Input('sourceShippingContainerId') set $inParams_sourceShippingContainerId(value: any) {
    this.inParams['sourceShippingContainerId'] = value;
  }
  get $inParams_sourceShippingContainerId(): any {
    return this.inParams['sourceShippingContainerId'] ;
  }
  @Input('sourceLicensePlateId') set $inParams_sourceLicensePlateId(value: any) {
    this.inParams['sourceLicensePlateId'] = value;
  }
  get $inParams_sourceLicensePlateId(): any {
    return this.inParams['sourceLicensePlateId'] ;
  }
  @Input('inputScan') set $inParams_inputScan(value: any) {
    this.inParams['inputScan'] = value;
  }
  get $inParams_inputScan(): any {
    return this.inParams['inputScan'] ;
  }

  topToolbar = {
      add_row: new ToolModel(new ButtonModel('add_row', new ButtonStyles(['creation'], null), false, 'Add  row', 'ms-Icon ms-Icon--Add')
    ),
      grid_options: new ToolModel(new SplitButtonModel<{ select_fields: ButtonModel }>(
        'grid_options',
        new ButtonStyles(null, null),
        false,
        ' ',
        'ms-Icon ms-Icon--BulletedListText',
        [
          new ButtonModel('select_fields', new ButtonStyles(null, null), false, 'Select fields', '')
        ])
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      delete_row: new ToolModel(new ButtonModel('delete_row', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Return tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.sourceShippingContainerId)) {
        this.$missingRequiredInParams.push('sourceShippingContainerId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      shippingContainerId:  $grid.inParams.sourceShippingContainerId ,
      taskStatusIds:  null ,
    };
    try {
    const data = await this.datasources.Returns.ds_blind_shipping_container_return_tasks_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_blind_shipping_container_return_tasks_gridComponentEntity) {
    const row = new FootPrintManager_blind_shipping_container_return_tasks_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.vars.suppressFlows = false;
  $grid.vars.delayTimer = 0;
  $grid.vars.skipDelay = false;
  
  /***********************************
   * EVENTS
  ************************************/
  
  }
  on_delete_row_clicked(event = null) {
    return this.on_delete_row_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_row_clickedInternal(
    $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  for (let row of $grid.selectedRows) {
      if ($utils.isDefined(row.entity)) {
          let result = (await $flows.Returns.delete_return_task_flow({ taskId: row.entity.Id }));
  
          if ($utils.isDefined(result.reasons)) {
              throw new Error(`Error deleting task '${row.entity.Id}': ${(await $flows.Utilities.grammar_format_string_array_flow({ values: result.reasons })).formattedValue}`);
          }
      }
  }
  
  $grid.refresh();
  
  $grid.events.tasksChanged.emit();
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($grid.selectedRows.length > 0) {
      $grid.topToolbar.delete_row.control.readOnly = false;
  }
  else {
      $grid.topToolbar.delete_row.control.readOnly = true;
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let licenseplate = (await $datasources.Inventory.ds_get_licenseplate_by_licenseplateId.get({ licenseplateId: $grid.inParams.sourceLicensePlateId })).result;
  //let shipment = (await $datasources.Returns.ds_get_shipment_by_shipmentId.get({ shipmentId: licenseplate.ShipmentId })).result;
  
  // If SC is closed, don't allow add or edit 
  if (!$utils.isDefined(licenseplate) || licenseplate.Archived) {
      $grid.canAdd = false;
      $grid.canEdit = false;
      $grid.topToolbar.delete_row.hidden = true;
      $grid.topToolbar.add_row.hidden = true;
  
      $grid.headers.order_code.hidden = false;
  }
  // Otherwise, hide Order column
  else {
      $grid.headers.order_code.hidden = true;
  
      $grid.canAdd = true;
      $grid.canEdit = false;
      $grid.topToolbar.delete_row.hidden = false;
      $grid.topToolbar.add_row.hidden = false;
  
      try {
          if ($utils.isDefined($grid.inParams.inputScan)) {
              await parseInputScan($grid.inParams.inputScan);
              $grid.inParams.inputScan = null;
          }
      }
      catch (error) {
          while ($utils.isDefined(error.error)) {
              error = error.error;
          }
  
          await $shell.FootPrintManager.openErrorDialog('Error parsing input scan', error.message);
      }
  }
  
  /************************************
   * FUNCTION
  *************************************/
  async function parseInputScan(inputScan: string) {
      // Parse string
      let parseResult = (await $flows.BarcodeParsing.parse_gs1_barcode_flow({ inputScan: inputScan }));
  
      // Validate values
      if ($utils.isDefined(parseResult.reasons)) {
          throw new Error('Error parsing scanned value: ' + (await $flows.Utilities.grammar_format_string_array_flow({ values: parseResult.reasons })).formattedValue);
      }
  
      let validationResult = (await $flows.BarcodeParsing.validate_barcode_flow({ context: 'BlindReturn', barcode: inputScan, parsedValues: parseResult.extractedValues }));
  
      if ($utils.isDefined(validationResult.reasons)) {
          throw new Error('Invalid barcode: ' + (await $flows.Utilities.grammar_format_string_array_flow({ values: validationResult.reasons })).formattedValue);
      }
  
      // Get serial number lookup
      let serialNumberCode = '';
      if ($utils.isDefined(parseResult.extractedValues.serialNumber)) {
          let serialNumberCodeResult = (await $flows.BarcodeParsing.get_serial_number_code_by_parsedValues({ parsedValues: parseResult.extractedValues }));
  
          if ($utils.isDefined(serialNumberCodeResult.reasons)) {
              throw new Error('Error getting serial number code: ' + (await $flows.Utilities.grammar_format_string_array_flow({ values: parseResult.reasons })).formattedValue);
          }
  
          serialNumberCode = serialNumberCodeResult.serialNumberCode;
      }
  
      let material = await getMaterial(parseResult.extractedValues.gtin, parseResult.extractedValues.lotNumber, serialNumberCode);
  
      let newRow = (await $grid.addRow());
  
      $grid.rows.splice($grid.rows.indexOf(newRow), 1);
      $grid.rows.unshift(newRow);
  
      newRow.cells.project.editControl.value = material?.ProjectId;
      newRow.cells.material.editControl.value = material?.Id;
      newRow.cells.vendor_lot.editControl.value = parseResult.extractedValues.lotNumber;
      newRow.cells.lot.editControl.value = parseResult.extractedValues.lotNumber;
      newRow.cells.actual_packaging.editControl.value = null;
      newRow.cells.actual_packaged_amount.editControl.value = $utils.isDefined(parseResult.extractedValues.serialNumber) ? 1 : null;
      newRow.cells.expiration_date.editControl.value = parseResult.extractedValues.expirationDate;
      newRow.cells.lot_status.editControl.value = 1;
      newRow.cells.serial_number_code.editControl.value = serialNumberCode;
      newRow.cells.actual_target_license_plate.editControl.value = null;
      newRow.cells.fill_percentage.editControl.value = 100;
      newRow.cells.condition.editControl.value = 'Damaged';
      newRow.cells.disposition.editControl.value = null;
  
      $grid.vars.skipDelay = true;
      await newRow.set_row_state();
      $grid.vars.skipDelay = false;
  }
  
  
  async function getMaterial(gtin: string, lotNumber: string, serialNumberCode: string) {
      // Get material
      let materials = (await $datasources.Materials.ds_get_materials_by_gtin.get({ gtin: gtin })).result;
  
      if (materials.length === 1) { return materials[0]; }
  
      if (materials.length > 1) {
          // Check if serial number can be found
          if ($utils.isDefinedTrimmed(serialNumberCode)) {
              let serialNumbers = (await $datasources.SerialNumbers.ds_find_serialnumbers.get({ lookupcode: serialNumberCode })).result;
  
              if (serialNumbers.length === 1) {
                  return materials.find(m => m.Id === serialNumbers[0].Lot.Material.Id);
              }
          }
  
          // Check if lot can be found
          if ($utils.isDefinedTrimmed(lotNumber)) {
              let vendorLots = (await $datasources.Lots.ds_get_vendor_lots_by_lookupcode.get({ lookupCode: lotNumber })).result;
  
              if (vendorLots.length === 1) {
                  return materials.find(m => m.Id === vendorLots[0].Material.Id);
              }
          }
  
          // Popup for user to select project
          let projectResponse = (await $shell.FootPrintManager.openblind_return_project_selector_formDialog({ gtin: gtin }));
  
          if ($utils.isDefined(projectResponse.projectId)) {
              return materials.find(m => m.ProjectId === projectResponse.projectId);
          }
      }
  
      return null;
  }
  }
  on_select_fields_clicked(event = null) {
    return this.on_select_fields_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_select_fields_clickedInternal(
    $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let columns: { Key: string, Name: string, IsSelected: boolean }[] = [];
  
  for (let key in $grid.headers) {
      columns.push({ Key: key, Name: $grid.headers[key].name, IsSelected: !$grid.headers[key].hidden });
  }
  
  let result = (await $shell.FootPrintManager.openfield_selector_gridDialog({ Columns: columns }));
  
  if ($utils.isDefined(result.Columns)) {
      for (let key in $grid.headers) {
          $grid.headers[key].hidden = !result.Columns.find(column => column.Key === key)?.IsSelected
      }
  }
  }
  on_add_row_clicked(event = null) {
    return this.on_add_row_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_add_row_clickedInternal(
    $grid: FootPrintManager_blind_shipping_container_return_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if (!$grid.rows.find(r => r.isNew)) {
      let newRow = (await $grid.addRow());
  
      let rowIndex = $grid.rows.indexOf(newRow);
  
      $grid.rows.splice(rowIndex, 1);
      $grid.rows.unshift(newRow);
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
