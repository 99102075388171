<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"


>
    <ng-container topToolbar>
    <div data-cy="tool-id-confirm" *ngIf="!topToolbar.confirm.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.confirm.control.readOnly"
            [ngStyle]="topToolbar.confirm.control.styles.style"
            [ngClass]="topToolbar.confirm.control.styles.classes"
            (click)="on_confirm_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.confirm.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.confirm.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-cancel" *ngIf="!topToolbar.cancel.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.cancel.control.readOnly"
            [ngStyle]="topToolbar.cancel.control.styles.style"
            [ngClass]="topToolbar.cancel.control.styles.classes"
            (click)="on_cancel_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.cancel.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.cancel.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.name.displayControl.styles.style"
          [ngClass]="row.cells.name.displayControl.styles.classes">{{row.cells.name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="value">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.value.displayControl.styles.style"
          [ngClass]="row.cells.value.displayControl.styles.classes">{{row.cells.value.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['value_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.value.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.value.editControl.placeholder}}"
            [ngStyle]="row.cells.value.editControl.styles.style"
            [ngClass]="row.cells.value.editControl.styles.classes"> 
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
