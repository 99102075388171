<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-create_inventory" *ngIf="!toolbar.create_inventory.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_inventory.control.readOnly"
                [ngStyle]="toolbar.create_inventory.control.styles.style"
                [ngClass]="toolbar.create_inventory.control.styles.classes"
                (click)="on_create_inventory_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_inventory.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_inventory.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_inventory.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-import_inventory" *ngIf="!toolbar.import_inventory.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.import_inventory.control.readOnly"
                [ngStyle]="toolbar.import_inventory.control.styles.style"
                [ngClass]="toolbar.import_inventory.control.styles.classes"
                (click)="on_import_inventory_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.import_inventory.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.import_inventory.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.import_inventory.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-materials" *ngIf="!filters.materials.hidden" 
                                  class="field-container standard {{filters.materials.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.materials.styles.style"
                                  [ngClass]="filters.materials.styles.classes">
                              <div class="label-container"
                                    title="{{filters.materials.label}}{{filters.materials.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.materials.label}}">{{filters.materials.label}}<span *ngIf="filters.materials.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Materials-materials_by_project_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.materials.control.type"
                                  formControlName="materials"
                                  (displayTextChange)="filters.materials.control.displayText=$event"
                                  [placeholder]="filters.materials.control.placeholder"
                                  [styles]="filters.materials.control.styles"
                                [projectId]="$fields_materials_selector_inParams_projectId"
                              >
                              </Materials-materials_by_project_dd_multi>
                              <ng-container *ngIf="filters.materials.invalid">
                                <ng-container *ngFor="let error of filters.materials.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouses" *ngIf="!filters.warehouses.hidden" 
                                  class="field-container standard {{filters.warehouses.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouses.styles.style"
                                  [ngClass]="filters.warehouses.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouses.label}}{{filters.warehouses.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouses.label}}">{{filters.warehouses.label}}<span *ngIf="filters.warehouses.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-warehouses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.warehouses.control.type"
                                  formControlName="warehouses"
                                  (displayTextChange)="filters.warehouses.control.displayText=$event"
                                  [placeholder]="filters.warehouses.control.placeholder"
                                  [styles]="filters.warehouses.control.styles"
                              >
                              </Locations-warehouses_dd_multi>
                              <ng-container *ngIf="filters.warehouses.invalid">
                                <ng-container *ngFor="let error of filters.warehouses.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-search" *ngIf="!filters.search.hidden" 
                                  class="field-container double {{filters.search.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.search.styles.style"
                                  [ngClass]="filters.search.styles.classes">
                              <div class="label-container"
                                    title="{{filters.search.label}}{{filters.search.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.search.label}}">{{filters.search.label}}<span *ngIf="filters.search.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="textBox"formControlName="search"
                                      matInput
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-textbox {{filters.search.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.search.control.placeholder}}"
                                      [ngStyle]="filters.search.control.styles.style"
                                      [ngClass]="filters.search.control.styles.classes"> 
                              <ng-container *ngIf="filters.search.invalid">
                                <ng-container *ngFor="let error of filters.search.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-archived" *ngIf="!filters.archived.hidden" 
                                  class="field-container standard {{filters.archived.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.archived.styles.style"
                                  [ngClass]="filters.archived.styles.classes">
                              <div class="label-container"
                                    title="{{filters.archived.label}}{{filters.archived.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.archived.label}}">{{filters.archived.label}}<span *ngIf="filters.archived.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <FootPrintManager-archived_options_dd_single 
                                  data-cy="selector"
                                  [type]="filters.archived.control.type"
                                  formControlName="archived"
                                  (displayTextChange)="filters.archived.control.displayText=$event"
                                  [placeholder]="filters.archived.control.placeholder"
                                  [styles]="filters.archived.control.styles"
                              >
                              </FootPrintManager-archived_options_dd_single>
                              <ng-container *ngIf="filters.archived.invalid">
                                <ng-container *ngFor="let error of filters.archived.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_available_amount_widget.hidden" class="widget-container">
            <SerialNumbers-serialnumbers_total_available_amount_widget
            #$widgets_total_available_amount_widget 
              [warehouseIds]="$widgets_total_available_amount_widget_inParams_warehouseIds"
              [ownerId]="$widgets_total_available_amount_widget_inParams_ownerId"
              [projectId]="$widgets_total_available_amount_widget_inParams_projectId"
              [materialIds]="$widgets_total_available_amount_widget_inParams_materialIds"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_amount_widget')"
            >
            </SerialNumbers-serialnumbers_total_available_amount_widget>
          </div>
          <div *ngIf="!widgets.total_packaged_amount_widget.hidden" class="widget-container">
            <SerialNumbers-serialnumbers_total_amount_widget
            #$widgets_total_packaged_amount_widget 
              [warehouseId]="$widgets_total_packaged_amount_widget_inParams_warehouseId"
              [ownerId]="$widgets_total_packaged_amount_widget_inParams_ownerId"
              [projectId]="$widgets_total_packaged_amount_widget_inParams_projectId"
              [materialIds]="$widgets_total_packaged_amount_widget_inParams_materialIds"
            ($refreshEvent)="refresh(false, false, '$widgets_total_packaged_amount_widget')"
            >
            </SerialNumbers-serialnumbers_total_amount_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.serialnumbers.hidden" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [warehouseIds]="$tabs_serialnumbers_serialnumbers_grid_inParams_warehouseIds"
              [materialIds]="$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds"
              [projectId]="$tabs_serialnumbers_serialnumbers_grid_inParams_projectId"
              [ownerId]="$tabs_serialnumbers_serialnumbers_grid_inParams_ownerId"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              [search]="$tabs_serialnumbers_serialnumbers_grid_inParams_search"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>