<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.edit_transaction.readOnly"
                [ngStyle]="row.options.edit_transaction.styles.style"
                [ngClass]="row.options.edit_transaction.styles.classes"
                (click)="row.on_edit_transaction()">
          <span *ngIf="row.options.edit_transaction.label">{{row.options.edit_transaction.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
    <div data-cy="tool-id-new_transaction" *ngIf="!topToolbar.new_transaction.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.new_transaction.control.readOnly"
            [ngStyle]="topToolbar.new_transaction.control.styles.style"
            [ngClass]="topToolbar.new_transaction.control.styles.classes"
            (click)="on_create_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.new_transaction.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.new_transaction.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.new_transaction.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-delete_selected" *ngIf="!topToolbar.delete_selected.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_selected.control.readOnly"
            [ngStyle]="topToolbar.delete_selected.control.styles.style"
            [ngClass]="topToolbar.delete_selected.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_selected.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_selected.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_selected.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
            class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.project.styles.style"
            [ngClass]="filters.project.styles.classes">
        <div class="label-container"
              title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Owners-projects_dd_single 
            data-cy="selector"
            [type]="filters.project.control.type"
            formControlName="project"
            (displayTextChange)="filters.project.control.displayText=$event"
            [placeholder]="filters.project.control.placeholder"
            [styles]="filters.project.control.styles"
          [statusId]="$fields_project_selector_inParams_statusId"
        >
        </Owners-projects_dd_single>
        <ng-container *ngIf="filters.project.invalid">
          <ng-container *ngFor="let error of filters.project.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-pallet_class" *ngIf="!filters.pallet_class.hidden" 
            class="field-container standard {{filters.pallet_class.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.pallet_class.styles.style"
            [ngClass]="filters.pallet_class.styles.classes">
        <div class="label-container"
              title="{{filters.pallet_class.label}}{{filters.pallet_class.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.pallet_class.label}}">{{filters.pallet_class.label}}<span *ngIf="filters.pallet_class.required"
                  class="required-asterisk">*</span></label>
        </div>
        <FootPrintManager-pallet_classes_dd_single 
            data-cy="selector"
            [type]="filters.pallet_class.control.type"
            formControlName="pallet_class"
            (displayTextChange)="filters.pallet_class.control.displayText=$event"
            [placeholder]="filters.pallet_class.control.placeholder"
            [styles]="filters.pallet_class.control.styles"
          [project_id]="$fields_pallet_class_selector_inParams_project_id"
        >
        </FootPrintManager-pallet_classes_dd_single>
        <ng-container *ngIf="filters.pallet_class.invalid">
          <ng-container *ngFor="let error of filters.pallet_class.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_from" *ngIf="!filters.date_from.hidden" 
            class="field-container standard {{filters.date_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_from.styles.style"
            [ngClass]="filters.date_from.styles.classes">
        <div class="label-container"
              title="{{filters.date_from.label}}{{filters.date_from.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_from.label}}">{{filters.date_from.label}}<span *ngIf="filters.date_from.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_from"
            [format]="filters.date_from.control.format"
            [mode]="filters.date_from.control.mode"
            [ngStyle]="filters.date_from.control.styles.style"
            [ngClass]="filters.date_from.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.date_from.invalid">
          <ng-container *ngFor="let error of filters.date_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-date_to" *ngIf="!filters.date_to.hidden" 
            class="field-container standard {{filters.date_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.date_to.styles.style"
            [ngClass]="filters.date_to.styles.classes">
        <div class="label-container"
              title="{{filters.date_to.label}}{{filters.date_to.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.date_to.label}}">{{filters.date_to.label}}<span *ngIf="filters.date_to.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="date_to"
            [format]="filters.date_to.control.format"
            [mode]="filters.date_to.control.mode"
            [ngStyle]="filters.date_to.control.styles.style"
            [ngClass]="filters.date_to.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.date_to.invalid">
          <ng-container *ngFor="let error of filters.date_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="Project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Project.displayControl.styles.style"
          [ngClass]="row.cells.Project.displayControl.styles.classes">{{row.cells.Project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Order">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Order.displayControl.styles.style"
          [ngClass]="row.cells.Order.displayControl.styles.classes">{{row.cells.Order.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="PalletClass">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.PalletClass.displayControl.styles.style"
          [ngClass]="row.cells.PalletClass.displayControl.styles.classes">{{row.cells.PalletClass.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Amount.displayControl.styles.style"
          [ngClass]="row.cells.Amount.displayControl.styles.classes">{{row.cells.Amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Reference.displayControl.styles.style"
          [ngClass]="row.cells.Reference.displayControl.styles.classes">{{row.cells.Reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Employee">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Employee.displayControl.styles.style"
          [ngClass]="row.cells.Employee.displayControl.styles.classes">{{row.cells.Employee.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Date.displayControl.styles.style"
          [ngClass]="row.cells.Date.displayControl.styles.classes">{{row.cells.Date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CreatedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysDateTime.displayControl.styles.classes">{{row.cells.CreatedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="CreatedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.CreatedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.CreatedSysUser.displayControl.styles.classes">{{row.cells.CreatedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysDateTime">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ModifiedSysDateTime.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysDateTime.displayControl.styles.classes">{{row.cells.ModifiedSysDateTime.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ModifiedSysUser">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ModifiedSysUser.displayControl.styles.style"
          [ngClass]="row.cells.ModifiedSysUser.displayControl.styles.classes">{{row.cells.ModifiedSysUser.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
