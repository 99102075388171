import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { ShippingContainers_container_assignment_flowService } from './ShippingContainers.flow.index';
import { ShippingContainers_container_unassignment_flowService } from './ShippingContainers.flow.index';

import { $types } from './ShippingContainers.types'

@Injectable({ providedIn: 'root' })
export class ShippingContainers_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public ShippingContainers: ShippingContainers_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _container_assignment_flow: ShippingContainers_container_assignment_flowService;
  public async container_assignment_flow(inParams: { containerTypeId?: number, ownerId?: number, projectId?: number, materialId?: number }): Promise< { reasons?: string[] }> {
    if(!this._container_assignment_flow) {
      this._container_assignment_flow = this.injector.get(ShippingContainers_container_assignment_flowService);
    }
    return this._container_assignment_flow.run(inParams);
  }
  private _container_unassignment_flow: ShippingContainers_container_unassignment_flowService;
  public async container_unassignment_flow(inParams: { containerId: number, ownerId?: number, projectId?: number, materialId?: number, assignmentLevel?: string }): Promise< { reasons?: string[] }> {
    if(!this._container_unassignment_flow) {
      this._container_unassignment_flow = this.injector.get(ShippingContainers_container_unassignment_flowService);
    }
    return this._container_unassignment_flow.run(inParams);
  }
}
