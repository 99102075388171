<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded " class="blade-tools">
      <div data-cy="tool-id-save_container" *ngIf="!toolbar.save_container.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.save_container.control.readOnly"
              [ngStyle]="toolbar.save_container.control.styles.style"
              [ngClass]="toolbar.save_container.control.styles.classes"
              (click)="on_save_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.save_container.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.save_container.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.save_container.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Search parameters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Locations-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-statuses" *ngIf="!fields.statuses.hidden" 
                            class="field-container standard {{fields.statuses.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.statuses.styles.style"
                            [ngClass]="fields.statuses.styles.classes">
                        <div class="label-container"
                              title="{{fields.statuses.label}}{{fields.statuses.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.statuses.label}}">{{fields.statuses.label}}<span *ngIf="fields.statuses.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PurchaseOrders-orderstatuses_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.statuses.control.type"
                            formControlName="statuses"
                            (displayTextChange)="fields.statuses.control.displayText=$event"
                            [placeholder]="fields.statuses.control.placeholder"
                            [styles]="fields.statuses.control.styles"
                        >
                        </PurchaseOrders-orderstatuses_dd_multi>
                        <ng-container *ngIf="fields.statuses.invalid">
                          <ng-container *ngFor="let error of fields.statuses.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-owner" *ngIf="!fields.owner.hidden" 
                            class="field-container standard {{fields.owner.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.owner.styles.style"
                            [ngClass]="fields.owner.styles.classes">
                        <div class="label-container"
                              title="{{fields.owner.label}}{{fields.owner.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.owner.label}}">{{fields.owner.label}}<span *ngIf="fields.owner.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-owners_dd_single 
                            data-cy="selector"
                            [type]="fields.owner.control.type"
                            formControlName="owner"
                            (displayTextChange)="fields.owner.control.displayText=$event"
                            [placeholder]="fields.owner.control.placeholder"
                            [styles]="fields.owner.control.styles"
                          [statusId]="$fields_owner_selector_inParams_statusId"
                        >
                        </Owners-owners_dd_single>
                        <ng-container *ngIf="fields.owner.invalid">
                          <ng-container *ngFor="let error of fields.owner.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-project" *ngIf="!fields.project.hidden" 
                            class="field-container standard {{fields.project.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.project.styles.style"
                            [ngClass]="fields.project.styles.classes">
                        <div class="label-container"
                              title="{{fields.project.label}}{{fields.project.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.project.label}}">{{fields.project.label}}<span *ngIf="fields.project.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Owners-projects_dd_single 
                            data-cy="selector"
                            [type]="fields.project.control.type"
                            formControlName="project"
                            (displayTextChange)="fields.project.control.displayText=$event"
                            [placeholder]="fields.project.control.placeholder"
                            [styles]="fields.project.control.styles"
                          [statusId]="$fields_project_selector_inParams_statusId"
                          [ownerId]="$fields_project_selector_inParams_ownerId"
                        >
                        </Owners-projects_dd_single>
                        <ng-container *ngIf="fields.project.invalid">
                          <ng-container *ngFor="let error of fields.project.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order_type" *ngIf="!fields.order_type.hidden" 
                            class="field-container standard {{fields.order_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order_type.styles.style"
                            [ngClass]="fields.order_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.order_type.label}}{{fields.order_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order_type.label}}">{{fields.order_type.label}}<span *ngIf="fields.order_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <LoadContainers-order_types_single 
                            data-cy="selector"
                            [type]="fields.order_type.control.type"
                            formControlName="order_type"
                            (displayTextChange)="fields.order_type.control.displayText=$event"
                            [placeholder]="fields.order_type.control.placeholder"
                            [styles]="fields.order_type.control.styles"
                        >
                        </LoadContainers-order_types_single>
                        <ng-container *ngIf="fields.order_type.invalid">
                          <ng-container *ngFor="let error of fields.order_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.orders.hidden" class="tab" data-cy="tab-orders">
              <h2 [className]="tabs.orders.active? 'active': ''" (click)="tabs.orders.activate()">{{tabs.orders.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-load_container_unassigned_orders_grid *ngIf="tabs.orders.active"
              #$tabs_orders
              [ownerId]="$tabs_orders_load_container_unassigned_orders_grid_inParams_ownerId"
              [projectId]="$tabs_orders_load_container_unassigned_orders_grid_inParams_projectId"
              [statusIds]="$tabs_orders_load_container_unassigned_orders_grid_inParams_statusIds"
              [orderTypeId]="$tabs_orders_load_container_unassigned_orders_grid_inParams_orderTypeId"
              [warehouseId]="$tabs_orders_load_container_unassigned_orders_grid_inParams_warehouseId"
              [loadContainerId]="$tabs_orders_load_container_unassigned_orders_grid_inParams_loadContainerId"
              [lookupCode]="$tabs_orders_load_container_unassigned_orders_grid_inParams_lookupCode"
              ($refreshEvent)="refresh(false, false, '$tabs_orders')">
              </FootPrintManager-load_container_unassigned_orders_grid>
        </div>
      </ng-container>

      <div *ngIf="initialized && !$hasDataLoaded " class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>