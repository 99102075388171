import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { PackVerification_DatasourceService } from './PackVerification.datasource.index';

interface IPackVerification_shipping_label_reportServiceInParams {
  shippingContainerId: number}

interface IPackVerification_shipping_label_reportServiceData {
}

@Injectable({ providedIn: 'root' })
export class PackVerification_shipping_label_reportService extends ReportBaseService<IPackVerification_shipping_label_reportServiceInParams, IPackVerification_shipping_label_reportServiceData> {

  protected reportReferenceName = 'shipping_label_report';
  protected appReferenceName = 'PackVerification';

  constructor(
    utils: UtilsService,
    private datasources: PackVerification_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IPackVerification_shipping_label_reportServiceInParams) {
  }

  protected async getData(inParams: IPackVerification_shipping_label_reportServiceInParams): Promise<IPackVerification_shipping_label_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IPackVerification_shipping_label_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IPackVerification_shipping_label_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];


    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
