import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { PackVerification_begin_pack_verification_flowService } from './PackVerification.flow.index';
import { PackVerification_cancel_verification_tasks_flowService } from './PackVerification.flow.index';
import { PackVerification_complete_pack_verification_flowService } from './PackVerification.flow.index';
import { PackVerification_fail_pack_verification_flowService } from './PackVerification.flow.index';
import { PackVerification_get_configurations_flowService } from './PackVerification.flow.index';
import { PackVerification_get_failure_location_flowService } from './PackVerification.flow.index';
import { PackVerification_get_failure_reasoncode_flowService } from './PackVerification.flow.index';
import { PackVerification_get_serial_number_substitution_reason_code_flowService } from './PackVerification.flow.index';
import { PackVerification_initialize_pack_verification_flowService } from './PackVerification.flow.index';
import { PackVerification_is_pack_verification_location_valid_flowService } from './PackVerification.flow.index';
import { PackVerification_move_shipping_container_flowService } from './PackVerification.flow.index';
import { PackVerification_save_shipping_container_actual_weight_flowService } from './PackVerification.flow.index';
import { PackVerification_substitute_container_serial_numbers_flowService } from './PackVerification.flow.index';
import { PackVerification_unpick_unverified_shipping_container_content_flowService } from './PackVerification.flow.index';
import { PackVerification_validate_shipping_container_contents_flowService } from './PackVerification.flow.index';
import { PackVerification_validate_shipping_container_properties_flowService } from './PackVerification.flow.index';
import { PackVerification_verify_content_flowService } from './PackVerification.flow.index';

import { $types } from './PackVerification.types'

@Injectable({ providedIn: 'root' })
export class PackVerification_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public PackVerification: PackVerification_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _begin_pack_verification_flow: PackVerification_begin_pack_verification_flowService;
  public async begin_pack_verification_flow(inParams: { shippingContainerId: number, canUseExisting?: boolean }): Promise< { reasons?: string[], startEndVerificationTaskId?: number }> {
    if(!this._begin_pack_verification_flow) {
      this._begin_pack_verification_flow = this.injector.get(PackVerification_begin_pack_verification_flowService);
    }
    return this._begin_pack_verification_flow.run(inParams);
  }
  private _cancel_verification_tasks_flow: PackVerification_cancel_verification_tasks_flowService;
  public async cancel_verification_tasks_flow(inParams: { start_verification_task_id: number, license_plate_id: number, lot_id: number, packaging_id: number, quantity: number, serial_number_ids?: number[] }): Promise< { reasons?: string[] }> {
    if(!this._cancel_verification_tasks_flow) {
      this._cancel_verification_tasks_flow = this.injector.get(PackVerification_cancel_verification_tasks_flowService);
    }
    return this._cancel_verification_tasks_flow.run(inParams);
  }
  private _complete_pack_verification_flow: PackVerification_complete_pack_verification_flowService;
  public async complete_pack_verification_flow(inParams: { startPackVerificationTaskId: number, targetLocationId?: number, actualWeight?: number }): Promise< { reasons?: string[] }> {
    if(!this._complete_pack_verification_flow) {
      this._complete_pack_verification_flow = this.injector.get(PackVerification_complete_pack_verification_flowService);
    }
    return this._complete_pack_verification_flow.run(inParams);
  }
  private _fail_pack_verification_flow: PackVerification_fail_pack_verification_flowService;
  public async fail_pack_verification_flow(inParams: { shippingContainerId: number, reasonCodeId?: number, startPackVerificationTaskId: number, targetLocationId?: number }): Promise< { reasons?: string[] }> {
    if(!this._fail_pack_verification_flow) {
      this._fail_pack_verification_flow = this.injector.get(PackVerification_fail_pack_verification_flowService);
    }
    return this._fail_pack_verification_flow.run(inParams);
  }
  private _get_configurations_flow: PackVerification_get_configurations_flowService;
  public async get_configurations_flow(inParams: {  }): Promise< { configurations?: { name: string, value: string }[], reasons?: string[] }> {
    if(!this._get_configurations_flow) {
      this._get_configurations_flow = this.injector.get(PackVerification_get_configurations_flowService);
    }
    return this._get_configurations_flow.run(inParams);
  }
  private _get_failure_location_flow: PackVerification_get_failure_location_flowService;
  public async get_failure_location_flow(inParams: { inputs: any }): Promise< { targetLocationId?: number, reasons?: string[] }> {
    if(!this._get_failure_location_flow) {
      this._get_failure_location_flow = this.injector.get(PackVerification_get_failure_location_flowService);
    }
    return this._get_failure_location_flow.run(inParams);
  }
  private _get_failure_reasoncode_flow: PackVerification_get_failure_reasoncode_flowService;
  public async get_failure_reasoncode_flow(inParams: { inputs: any }): Promise< { reasonCodeId?: number, reasons?: string[] }> {
    if(!this._get_failure_reasoncode_flow) {
      this._get_failure_reasoncode_flow = this.injector.get(PackVerification_get_failure_reasoncode_flowService);
    }
    return this._get_failure_reasoncode_flow.run(inParams);
  }
  private _get_serial_number_substitution_reason_code_flow: PackVerification_get_serial_number_substitution_reason_code_flowService;
  public async get_serial_number_substitution_reason_code_flow(inParams: {  }): Promise< { reasonCodeId?: number, reasonCodeName?: string }> {
    if(!this._get_serial_number_substitution_reason_code_flow) {
      this._get_serial_number_substitution_reason_code_flow = this.injector.get(PackVerification_get_serial_number_substitution_reason_code_flowService);
    }
    return this._get_serial_number_substitution_reason_code_flow.run(inParams);
  }
  private _initialize_pack_verification_flow: PackVerification_initialize_pack_verification_flowService;
  public async initialize_pack_verification_flow(inParams: {  }): Promise< { reasons?: string[] }> {
    if(!this._initialize_pack_verification_flow) {
      this._initialize_pack_verification_flow = this.injector.get(PackVerification_initialize_pack_verification_flowService);
    }
    return this._initialize_pack_verification_flow.run(inParams);
  }
  private _is_pack_verification_location_valid_flow: PackVerification_is_pack_verification_location_valid_flowService;
  public async is_pack_verification_location_valid_flow(inParams: { shippingContainerId: number, locationId: number }): Promise< { reasons?: string[] }> {
    if(!this._is_pack_verification_location_valid_flow) {
      this._is_pack_verification_location_valid_flow = this.injector.get(PackVerification_is_pack_verification_location_valid_flowService);
    }
    return this._is_pack_verification_location_valid_flow.run(inParams);
  }
  private _move_shipping_container_flow: PackVerification_move_shipping_container_flowService;
  public async move_shipping_container_flow(inParams: { shippingContainerId: number, targetLocationId: number }): Promise< { reasons?: string[] }> {
    if(!this._move_shipping_container_flow) {
      this._move_shipping_container_flow = this.injector.get(PackVerification_move_shipping_container_flowService);
    }
    return this._move_shipping_container_flow.run(inParams);
  }
  private _save_shipping_container_actual_weight_flow: PackVerification_save_shipping_container_actual_weight_flowService;
  public async save_shipping_container_actual_weight_flow(inParams: { shippingContainerId: number, actualWeight: number }): Promise< { reasons?: string[] }> {
    if(!this._save_shipping_container_actual_weight_flow) {
      this._save_shipping_container_actual_weight_flow = this.injector.get(PackVerification_save_shipping_container_actual_weight_flowService);
    }
    return this._save_shipping_container_actual_weight_flow.run(inParams);
  }
  private _substitute_container_serial_numbers_flow: PackVerification_substitute_container_serial_numbers_flowService;
  public async substitute_container_serial_numbers_flow(inParams: { startVerificationTaskId: number }): Promise< { reasons?: string[] }> {
    if(!this._substitute_container_serial_numbers_flow) {
      this._substitute_container_serial_numbers_flow = this.injector.get(PackVerification_substitute_container_serial_numbers_flowService);
    }
    return this._substitute_container_serial_numbers_flow.run(inParams);
  }
  private _unpick_unverified_shipping_container_content_flow: PackVerification_unpick_unverified_shipping_container_content_flowService;
  public async unpick_unverified_shipping_container_content_flow(inParams: { lotId: number, packagingId: number, startEndTaskId: number, licenseplateId: number, targetLicensePlateId: number }): Promise< { reasons?: string[] }> {
    if(!this._unpick_unverified_shipping_container_content_flow) {
      this._unpick_unverified_shipping_container_content_flow = this.injector.get(PackVerification_unpick_unverified_shipping_container_content_flowService);
    }
    return this._unpick_unverified_shipping_container_content_flow.run(inParams);
  }
  private _validate_shipping_container_contents_flow: PackVerification_validate_shipping_container_contents_flowService;
  public async validate_shipping_container_contents_flow(inParams: { startPackVerificationTaskId: number, canSubstituteSerials?: boolean }): Promise< { reasons?: string[], isValid?: boolean }> {
    if(!this._validate_shipping_container_contents_flow) {
      this._validate_shipping_container_contents_flow = this.injector.get(PackVerification_validate_shipping_container_contents_flowService);
    }
    return this._validate_shipping_container_contents_flow.run(inParams);
  }
  private _validate_shipping_container_properties_flow: PackVerification_validate_shipping_container_properties_flowService;
  public async validate_shipping_container_properties_flow(inParams: { startPackVerificationTaskId: number, additionalInputs?: any }): Promise< { reasons?: string[], isValid?: boolean }> {
    if(!this._validate_shipping_container_properties_flow) {
      this._validate_shipping_container_properties_flow = this.injector.get(PackVerification_validate_shipping_container_properties_flowService);
    }
    return this._validate_shipping_container_properties_flow.run(inParams);
  }
  private _verify_content_flow: PackVerification_verify_content_flowService;
  public async verify_content_flow(inParams: { shippingContainerId: number, inputScan?: { materialCode?: string, serialNumberCode?: string }, inputManual?: { lotId?: number, packagingId?: number, packagedAmount?: number, licenseplateId?: number }, startPackVerificationTaskId: number, canSubstituteSerials?: boolean }): Promise< { reasons?: string[], isVerified?: boolean, materialId?: number, serialNumberId?: number, lotId?: number, packagingId?: number, isUpcCode?: boolean }> {
    if(!this._verify_content_flow) {
      this._verify_content_flow = this.injector.get(PackVerification_verify_content_flowService);
    }
    return this._verify_content_flow.run(inParams);
  }
}
