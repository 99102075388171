import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class LaborManagement_ds_work_class_operation_codes_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { operationCodes?: { id?: number, priority?: number, name?: string }[], $top?: number, $skip?: number }): Promise<{ result?: { Id?: number, Priority?: number, Name?: string }[], totalCount?: number }> {

    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_work_class_operation_codes_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { operationCodes?: { id?: number, priority?: number, name?: string }[], $keys: number[] }): Promise<{ result?: { Id?: number, Priority?: number, Name?: string }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/LaborManagement/datasources/ds_work_class_operation_codes_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
