import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { Addresses_addresses_library_homeComponent } from './Addresses.addresses_library_home.component';
import { Addresses_account_types_dd_singleComponent } from './Addresses.account_types_dd_single.component';
import { Addresses_contact_types_dd_singleComponent } from './Addresses.contact_types_dd_single.component';
import { Addresses_orderaddresses_types_dd_singleComponent } from './Addresses.orderaddresses_types_dd_single.component';
import { Addresses_ownersaccounts_dd_singleComponent } from './Addresses.ownersaccounts_dd_single.component';
import { Addresses_account_types_dd_multiComponent } from './Addresses.account_types_dd_multi.component';
import { Addresses_contact_types_dd_multiComponent } from './Addresses.contact_types_dd_multi.component';
import { Addresses_orderaddresses_types_dd_multiComponent } from './Addresses.orderaddresses_types_dd_multi.component';
import { Addresses_ownersaccounts_dd_multiComponent } from './Addresses.ownersaccounts_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Addresses_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Addresses: Addresses_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openaddresses_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'Addresses_addresses_library_home',
        component: Addresses_addresses_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openaddresses_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Addresses_addresses_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Addresses_addresses_library_home') {
      const title = 'Home';
      const component = Addresses_addresses_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_account_types_dd_single') {
      const title = 'account_types_dd';
      const component = Addresses_account_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_contact_types_dd_single') {
      const title = 'Contact Types';
      const component = Addresses_contact_types_dd_singleComponent;
      const inParams:{ typeIds?: number[] } = { typeIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_orderaddresses_types_dd_single') {
      const title = 'Order Addresses Types';
      const component = Addresses_orderaddresses_types_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_ownersaccounts_dd_single') {
      const title = 'Owner Accounts';
      const component = Addresses_ownersaccounts_dd_singleComponent;
      const inParams:{ ownerId: number, typeIds: number[], accountTypeId: number } = { ownerId: null, typeIds: [], accountTypeId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('accountTypeId'))) {
        const paramValueString = params.get('accountTypeId');
        inParams.accountTypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_account_types_dd_multi') {
      const title = 'account_types_dd';
      const component = Addresses_account_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_contact_types_dd_multi') {
      const title = 'Contact Types';
      const component = Addresses_contact_types_dd_multiComponent;
      const inParams:{ typeIds?: number[] } = { typeIds: [] };
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_orderaddresses_types_dd_multi') {
      const title = 'Order Addresses Types';
      const component = Addresses_orderaddresses_types_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Addresses_ownersaccounts_dd_multi') {
      const title = 'Owner Accounts';
      const component = Addresses_ownersaccounts_dd_multiComponent;
      const inParams:{ ownerId: number, typeIds: number[], accountTypeId: number } = { ownerId: null, typeIds: [], accountTypeId: null };
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('typeIds'))) {
        const paramValueString = params.get('typeIds');
        inParams.typeIds = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('accountTypeId'))) {
        const paramValueString = params.get('accountTypeId');
        inParams.accountTypeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
