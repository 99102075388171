import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_inventory_transactions_gridService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { ownerId?: number, projectId?: number, warehouseId?: number, createdFrom?: string, createdTo?: string, fullTextSearch?: string, materialIds?: number[], operationCodeIds?: number[], take?: number, skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, LotId?: number, ModifiedSysUser?: string, Project?: { LookupCode?: string }, Material?: { CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }, Order?: { Id?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, ProcedureCode?: string, VendorReference?: string, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualSourceLocation?: { Name?: string }, ActualTargetLocation?: { Name?: string }, OperationCode?: { Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } }, inbound_order?: { OrderId?: number, LotId?: number, order?: { Id?: number, bypassAllocationQuantity?: boolean, CountryOfOrigin?: string, Currency?: string, Label?: string, LookupCode?: string, PreferredWarehouseId?: number, PrintJob?: string, ProcedureCode?: string, PVA?: boolean, SAD?: string, subProjectFirst?: number, subProjectSecond?: number, subProjectThird?: number, ZAGGPO?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_transactions_grid/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { ownerId?: number, projectId?: number, warehouseId?: number, createdFrom?: string, createdTo?: string, fullTextSearch?: string, materialIds?: number[], operationCodeIds?: number[], take?: number, skip?: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, LotId?: number, ModifiedSysUser?: string, Project?: { LookupCode?: string }, Material?: { CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }, Order?: { Id?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, ProcedureCode?: string, VendorReference?: string, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualSourceLocation?: { Name?: string }, ActualTargetLocation?: { Name?: string }, OperationCode?: { Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } }, inbound_order?: { OrderId?: number, LotId?: number, order?: { Id?: number, bypassAllocationQuantity?: boolean, CountryOfOrigin?: string, Currency?: string, Label?: string, LookupCode?: string, PreferredWarehouseId?: number, PrintJob?: string, ProcedureCode?: string, PVA?: boolean, SAD?: string, subProjectFirst?: number, subProjectSecond?: number, subProjectThird?: number, ZAGGPO?: string } } }[], totalCount: number }> 
  {
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_transactions_grid/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerId?: number, projectId?: number, warehouseId?: number, createdFrom?: string, createdTo?: string, fullTextSearch?: string, materialIds?: number[], operationCodeIds?: number[], take?: number, skip?: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, ActualPackagedAmount?: number, CompletedDateTime?: string, CreatedSysDateTime?: string, ExpectedPackagedAmount?: number, LotId?: number, ModifiedSysUser?: string, Project?: { LookupCode?: string }, Material?: { CommodityCode?: string, CountryOfOrigin?: string, Description?: string, DutyRate?: number, LookupCode?: string, Project?: { LookupCode?: string, Owner?: { LookupCode?: string } } }, Order?: { Id?: number, LookupCode?: string, OrderClassId?: number, OwnerReference?: string, ProcedureCode?: string, VendorReference?: string, Account?: { Id?: number, AccountsContactsLookup?: { ContactId?: number, Contact?: { FirstName?: string, LastName?: string, PrimaryEmail?: string, PrimaryTelephone?: string, Address?: { AttentionOf?: string, City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] }, Addresses?: { AttentionOf?: string, City?: string, Country?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, PostalCode?: string, PrimaryEmail?: string, PrimaryTelephone?: string, State?: string }[] }, Warehouse?: { Name?: string }, Lot?: { LookupCode?: string, ReceiveDate?: string, VendorLot?: { LookupCode?: string, Vat?: string } }, ActualSourceLocation?: { Name?: string }, ActualTargetLocation?: { Name?: string }, OperationCode?: { Name?: string }, ExpectedSourceLicensePlate?: { Id?: number, LookupCode?: string, Location?: { Name?: string } }, inbound_order?: { OrderId?: number, LotId?: number, order?: { Id?: number, bypassAllocationQuantity?: boolean, CountryOfOrigin?: string, Currency?: string, Label?: string, LookupCode?: string, PreferredWarehouseId?: number, PrintJob?: string, ProcedureCode?: string, PVA?: boolean, SAD?: string, subProjectFirst?: number, subProjectSecond?: number, subProjectThird?: number, ZAGGPO?: string } } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_inventory_transactions_grid/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
