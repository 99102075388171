import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Utilities.types'

@Injectable({ providedIn: 'root' })
export class Utilities_flatten_object_propertyService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { flattenArray: any[], childName: string, flattenValueName?: string, returnReversed?: boolean }): Promise<{ result?: any[], reasons?: string[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.flattenArray)) {
      missingRequiredInParams.push('\'flattenArray\'');
    }
    if (isNil(inParams.childName)) {
      missingRequiredInParams.push('\'childName\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Utilities/functions/flatten_object_property`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

