<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-refresh_grid" *ngIf="!topToolbar.refresh_grid.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.refresh_grid.control.readOnly"
            [ngStyle]="topToolbar.refresh_grid.control.styles.style"
            [ngClass]="topToolbar.refresh_grid.control.styles.classes"
            (click)="on_refresh($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.refresh_grid.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.refresh_grid.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.refresh_grid.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-elapsed" *ngIf="!topToolbar.elapsed.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.elapsed.control.readOnly"
            [ngStyle]="topToolbar.elapsed.control.styles.style"
            [ngClass]="topToolbar.elapsed.control.styles.classes"
    >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.elapsed.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.elapsed.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.elapsed.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="test_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.test_name.displayControl.styles.style"
          [ngClass]="row.cells.test_name.displayControl.styles.classes">{{row.cells.test_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="records_available">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.records_available.displayControl.styles.style"
          [ngClass]="row.cells.records_available.displayControl.styles.classes">{{row.cells.records_available.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="records_returned">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.records_returned.displayControl.styles.style"
          [ngClass]="row.cells.records_returned.displayControl.styles.classes">{{row.cells.records_returned.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="elapsed_seconds">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.elapsed_seconds.displayControl.styles.style"
          [ngClass]="row.cells.elapsed_seconds.displayControl.styles.classes">{{row.cells.elapsed_seconds.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="seconds_per_record">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.seconds_per_record.displayControl.styles.style"
          [ngClass]="row.cells.seconds_per_record.displayControl.styles.classes">{{row.cells.seconds_per_record.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
