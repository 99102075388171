<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div *ngIf="!$hasMissingRequiredInParams"
          class="blade-tools">
        <div data-cy="tool-id-update" *ngIf="!toolbar.update.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.update.control.readOnly"
                [ngStyle]="toolbar.update.control.styles.style"
                [ngClass]="toolbar.update.control.styles.classes"
                (click)="on_edit_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.update.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.update.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.update.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-create_inventory" *ngIf="!toolbar.create_inventory.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_inventory.control.readOnly"
                [ngStyle]="toolbar.create_inventory.control.styles.style"
                [ngClass]="toolbar.create_inventory.control.styles.classes"
                (click)="on_create_inventory_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_inventory.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_inventory.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_inventory.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator2" *ngIf="!toolbar.separator2.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-on_delete" *ngIf="!toolbar.on_delete.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.on_delete.control.readOnly"
                [ngStyle]="toolbar.on_delete.control.styles.style"
                [ngClass]="toolbar.on_delete.control.styles.classes"
                (click)="on_delete($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.on_delete.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.on_delete.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-separator3" *ngIf="!toolbar.separator3.hidden" class="toolContainer">
        <div class="tool-separator"></div>
        </div>
        <div data-cy="tool-id-print" *ngIf="!toolbar.print.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.print.control.readOnly"
                [ngStyle]="toolbar.print.control.styles.style"
                [ngClass]="toolbar.print.control.styles.classes"
                (click)="on_print_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.print.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.print.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.print.control.label}}</div>
          </div>
        
        </button>
        </div>
        <div data-cy="tool-id-attachments" *ngIf="!toolbar.attachments.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.attachments.control.readOnly"
                [ngStyle]="toolbar.attachments.control.styles.style"
                [ngClass]="toolbar.attachments.control.styles.classes"
                (click)="on_attachments_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.attachments.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.attachments.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.attachments.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Label of newGroup1</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-inventory_type" *ngIf="!filters.inventory_type.hidden" 
                                  class="field-container double {{filters.inventory_type.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.inventory_type.styles.style"
                                  [ngClass]="filters.inventory_type.styles.classes">
                              <div class="label-container"
                                    title="{{filters.inventory_type.label}}{{filters.inventory_type.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.inventory_type.label}}">{{filters.inventory_type.label}}<span *ngIf="filters.inventory_type.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Inventory-inventory_aggregation_dd_single 
                                  data-cy="selector"
                                  [type]="filters.inventory_type.control.type"
                                  formControlName="inventory_type"
                                  (displayTextChange)="filters.inventory_type.control.displayText=$event"
                                  [placeholder]="filters.inventory_type.control.placeholder"
                                  [styles]="filters.inventory_type.control.styles"
                              >
                              </Inventory-inventory_aggregation_dd_single>
                              <ng-container *ngIf="filters.inventory_type.invalid">
                                <ng-container *ngFor="let error of filters.inventory_type.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
          <div class="hub-tools">
            <div data-cy="tool-id-assign_containers_button" *ngIf="!actionbar.assign_containers_button.hidden" class="toolContainer">
            <button mat-button
                    data-cy="button"
                    class="datex-button"
                    [disabled]="actionbar.assign_containers_button.control.readOnly"
                    [ngStyle]="actionbar.assign_containers_button.control.styles.style"
                    [ngClass]="actionbar.assign_containers_button.control.styles.classes"
                    (click)="on_assign_clicked($event)"
                    >
              <div class="button-label">
                <ng-container *ngIf="actionbar.assign_containers_button.control.icon === 'datex-default-spinner'; else showIcon">
                  <div class="button-icon">
                    <i data-cy="button-icon" class="datex-default-spinner"></i>
                  </div>
                </ng-container>
                <ng-template #showIcon>
                  <div class="button-icon">
                    <i data-cy="button-icon" class="icon {{actionbar.assign_containers_button.control.icon}}"></i>
                  </div>
                </ng-template>
                <div data-cy="button-label" class="button-text">{{actionbar.assign_containers_button.control.label}}</div>
              </div>
            
            </button>
            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.total_available_amount_widget.hidden" class="widget-container">
            <Inventory-total_available_amount_widget
            #$widgets_total_available_amount_widget 
              [materialId]="$widgets_total_available_amount_widget_inParams_materialId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_amount_widget')"
            >
            </Inventory-total_available_amount_widget>
          </div>
          <div *ngIf="!widgets.total_available_base_amount_widget.hidden" class="widget-container">
            <Inventory-total_available_base_amount_widget
            #$widgets_total_available_base_amount_widget 
              [materialId]="$widgets_total_available_base_amount_widget_inParams_materialId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_available_base_amount_widget')"
            >
            </Inventory-total_available_base_amount_widget>
          </div>
          <div *ngIf="!widgets.total_gross_weight_widget.hidden" class="widget-container">
            <Inventory-total_gross_weight_widget
            #$widgets_total_gross_weight_widget 
              [materialId]="$widgets_total_gross_weight_widget_inParams_materialId"
            ($refreshEvent)="refresh(false, false, '$widgets_total_gross_weight_widget')"
            >
            </Inventory-total_gross_weight_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory.hidden" class="tab" data-cy="tab-inventory">
              <h2 [className]="tabs.inventory.active? 'active': ''" (click)="tabs.inventory.activate()">{{tabs.inventory.title}}</h2>
            </div>
            <div *ngIf="!tabs.licenseplates.hidden" class="tab" data-cy="tab-licenseplates">
              <h2 [className]="tabs.licenseplates.active? 'active': ''" (click)="tabs.licenseplates.activate()">{{tabs.licenseplates.title}}</h2>
            </div>
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
            <div *ngIf="!tabs.packagings.hidden" class="tab" data-cy="tab-packagings">
              <h2 [className]="tabs.packagings.active? 'active': ''" (click)="tabs.packagings.activate()">{{tabs.packagings.title}}</h2>
            </div>
            <div *ngIf="!tabs.udfs.hidden" class="tab" data-cy="tab-udfs">
              <h2 [className]="tabs.udfs.active? 'active': ''" (click)="tabs.udfs.activate()">{{tabs.udfs.title}}</h2>
            </div>
            <div *ngIf="!tabs.serialnumbers.hidden" class="tab" data-cy="tab-serialnumbers">
              <h2 [className]="tabs.serialnumbers.active? 'active': ''" (click)="tabs.serialnumbers.activate()">{{tabs.serialnumbers.title}}</h2>
            </div>
            <div *ngIf="!tabs.replenishments.hidden" class="tab" data-cy="tab-replenishments">
              <h2 [className]="tabs.replenishments.active? 'active': ''" (click)="tabs.replenishments.activate()">{{tabs.replenishments.title}}</h2>
            </div>
            <div *ngIf="!tabs.instructions.hidden" class="tab" data-cy="tab-instructions">
              <h2 [className]="tabs.instructions.active? 'active': ''" (click)="tabs.instructions.activate()">{{tabs.instructions.title}}</h2>
            </div>
          </div>
        
              <ng-container
                *ngIf="tabs.inventory.active">
                  <mat-select (selectionChange)="onTabSelected($event)"
                              *ngIf="subTabGroups.inventory.tabs.length > 1"
                              class="datex-queryselector"
                              [value]="subTabGroups.inventory.activeTab"
                              disableOptionCentering
                              panelClass="dropdown-below datex-dropdownpanel">
                    <mat-option [value]="tab" *ngFor="let tab of subTabGroups.inventory.tabs">{{tab.title}}</mat-option>
                  </mat-select>
                
                  <FootPrintManager-inventory_location_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_location.active"
                  #$tabs_inventory_inventory_by_location
                  [materialIds]="$tabs_inventory_inventory_by_location_inventory_location_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_location')">
                  </FootPrintManager-inventory_location_grid>
                  <FootPrintManager-inventory_licenseplate_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_lp.active"
                  #$tabs_inventory_inventory_by_lp
                  [materialIds]="$tabs_inventory_inventory_by_lp_inventory_licenseplate_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_lp')">
                  </FootPrintManager-inventory_licenseplate_grid>
                  <FootPrintManager-inventory_lot_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_lot.active"
                  #$tabs_inventory_inventory_by_lot
                  [materialIds]="$tabs_inventory_inventory_by_lot_inventory_lot_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_lot')">
                  </FootPrintManager-inventory_lot_grid>
                  <FootPrintManager-inventory_material_grid
                   *ngIf="tabs.inventory.tabs.inventory_by_material.active"
                  #$tabs_inventory_inventory_by_material
                  [materialIds]="$tabs_inventory_inventory_by_material_inventory_material_grid_inParams_materialIds"
                  ($refreshEvent)="refresh(false, false, '$tabs_inventory_inventory_by_material')">
                  </FootPrintManager-inventory_material_grid>
              </ng-container>
              <FootPrintManager-licenseplate_list *ngIf="tabs.licenseplates.active"
              #$tabs_licenseplates
              [materialId]="$tabs_licenseplates_licenseplate_list_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_licenseplates')">
              </FootPrintManager-licenseplate_list>
              <FootPrintManager-inventory_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [materialId]="$tabs_tasks_inventory_tasks_grid_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-inventory_tasks_grid>
              <FootPrintManager-material_packagings_grid *ngIf="tabs.packagings.active"
              #$tabs_packagings
              [materialId]="$tabs_packagings_material_packagings_grid_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_packagings')">
              </FootPrintManager-material_packagings_grid>
              <FootPrintManager-materials_udf_grid *ngIf="tabs.udfs.active"
              #$tabs_udfs
              [materialIds]="$tabs_udfs_materials_udf_grid_inParams_materialIds"
              ($refreshEvent)="refresh(false, false, '$tabs_udfs')">
              </FootPrintManager-materials_udf_grid>
              <FootPrintManager-serialnumbers_grid *ngIf="tabs.serialnumbers.active"
              #$tabs_serialnumbers
              [materialIds]="$tabs_serialnumbers_serialnumbers_grid_inParams_materialIds"
              [archived]="$tabs_serialnumbers_serialnumbers_grid_inParams_archived"
              ($refreshEvent)="refresh(false, false, '$tabs_serialnumbers')">
              </FootPrintManager-serialnumbers_grid>
              <FootPrintManager-locations_replenishments_grid *ngIf="tabs.replenishments.active"
              #$tabs_replenishments
              [materialId]="$tabs_replenishments_locations_replenishments_grid_inParams_materialId"
              ($refreshEvent)="refresh(false, false, '$tabs_replenishments')">
              </FootPrintManager-locations_replenishments_grid>
              <FootPrintManager-instructions_grid *ngIf="tabs.instructions.active"
              #$tabs_instructions
              [entityName]="$tabs_instructions_instructions_grid_inParams_entityName"
              [enabled]="$tabs_instructions_instructions_grid_inParams_enabled"
              [entityKey1]="$tabs_instructions_instructions_grid_inParams_entityKey1"
              ($refreshEvent)="refresh(false, false, '$tabs_instructions')">
              </FootPrintManager-instructions_grid>
        </div>

      </ng-container>
      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>