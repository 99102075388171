<div class="datex-datecontainer {{disabled ? 'readonly' : ''}}">
  <!-- date picker -->
  <ng-container *ngIf="mode === 'date'">
    <input #input
           matInput
           autocomplete="off"
           class="date-input"
           [matDatepicker]="picker"
           (change)="onUserInputChanged($event)"
           (dateChange)="onValueChanged($event.value)"
           [ngModel]="value"
           [disabled]="disabled"
           (focusout)="onFocusOut()"
           (keydown)="onKeyDown($event)"
           data-cy="dateBox-input">
    <div class="date-button">
      <mat-datepicker-toggle matSuffix
                             *ngIf="!disabled"
                             [for]="$any(picker)"
                             data-cy="dateBox-toggle">
        <i matDatepickerToggleIcon
           class="icon icon-ic_fluent_calendar_20_regular"></i>
      </mat-datepicker-toggle>
    </div>
    <mat-datepicker #picker
                    panelClass="datex-calendarpicker"></mat-datepicker>
  </ng-container>
  <!-- datetime picker -->
  <ng-container *ngIf="mode === 'datetime'">
    <input #input
           matInput
           class="date-input"
           spellcheck="false"
           title="{{value}}"
           [ngxMatDatetimePicker]="picker"
           (dateChange)="onValueChanged($event.value)"
           [ngModel]="value"
           [disabled]="disabled"
           (focusout)="onFocusOut()"
           (keydown)="onKeyDown($event)"
           data-cy="dateBox-input">
    <div class="date-button">
      <mat-datepicker-toggle matSuffix
                             *ngIf="!disabled"
                             [for]="$any(picker)"
                             data-cy="dateBox-toggle">
        <i matDatepickerToggleIcon
           class="icon icon-ic_fluent_calendar_20_regular"></i>
      </mat-datepicker-toggle>
    </div>
    <ngx-mat-datetime-picker #picker
                             [defaultTime]="[0,0,0]"
                             [enableMeridian]="true"
                             panelClass="datex-calendarpicker"></ngx-mat-datetime-picker>
  </ng-container>
</div>