<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-create_rule" *ngIf="!topToolbar.create_rule.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.create_rule.control.readOnly"
            [ngStyle]="topToolbar.create_rule.control.styles.style"
            [ngClass]="topToolbar.create_rule.control.styles.classes"
            (click)="on_create_rule_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.create_rule.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.create_rule.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.create_rule.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-edit_rule" *ngIf="!topToolbar.edit_rule.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.edit_rule.control.readOnly"
            [ngStyle]="topToolbar.edit_rule.control.styles.style"
            [ngClass]="topToolbar.edit_rule.control.styles.classes"
            (click)="on_edit_rule_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.edit_rule.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.edit_rule.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.edit_rule.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-delete_rule" *ngIf="!topToolbar.delete_rule.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_rule.control.readOnly"
            [ngStyle]="topToolbar.delete_rule.control.styles.style"
            [ngClass]="topToolbar.delete_rule.control.styles.classes"
            (click)="on_delete_rule_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_rule.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_rule.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_rule.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator2" *ngIf="!topToolbar.separator2.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-execute" *ngIf="!topToolbar.execute.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.execute.control.readOnly"
            [ngStyle]="topToolbar.execute.control.styles.style"
            [ngClass]="topToolbar.execute.control.styles.classes"
            (click)="on_execute_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.execute.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.execute.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.execute.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-request_history" *ngIf="!topToolbar.request_history.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.request_history.control.readOnly"
            [ngStyle]="topToolbar.request_history.control.styles.style"
            [ngClass]="topToolbar.request_history.control.styles.classes"
            (click)="on_request_history_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.request_history.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.request_history.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.request_history.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator3" *ngIf="!topToolbar.separator3.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-field_selector" *ngIf="!topToolbar.field_selector.hidden" class="toolContainer">
    <button mat-button 
            data-cy="splitbutton"
            class="datex-button splitbutton" 
            [disabled]="topToolbar.field_selector.control.readOnly"
            [ngStyle]="topToolbar.field_selector.control.styles.style"
            [ngClass]="topToolbar.field_selector.control.styles.classes"
            [matMenuTriggerFor]="dropdownMenuOne">
      <div class="button-label">
        <div *ngIf="topToolbar.field_selector.control.icon"
              class="button-icon">
          <i data-cy="splitbutton-icon" class="icon {{topToolbar.field_selector.control.icon}}"></i>
        </div>
        <div data-cy="splitbutton-label" class="button-text">{{topToolbar.field_selector.control.label}}</div>
        <div class="button-icon splitbutton-drop-icon">
          <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
        </div>
      </div>
    </button>
    <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
      <div data-cy="splitbutton-options">
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_enabled.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_enabled.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_enabled.styles.classes"
                  (click)="($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_enabled.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_enabled.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_enabled.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_rule_id.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_rule_id.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_rule_id.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_rule_id.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_rule_id.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_rule_id.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_owner.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_owner.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_owner.styles.classes"
                  (click)="($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_owner.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_owner.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_owner.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_project.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_project.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_project.styles.classes"
                  (click)="($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_project.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_project.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_project.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_context.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_context.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_context.styles.classes"
                  (click)="($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_context.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_context.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_context.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_frequency.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_frequency.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_frequency.styles.classes"
                  (click)="($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_frequency.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_frequency.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_frequency.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_last_executed_on.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_last_executed_on.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_last_executed_on.styles.classes"
                  (click)="($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_last_executed_on.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_last_executed_on.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_last_executed_on.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_to.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_to.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_to.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_to.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_to.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_to.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_cc.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_cc.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_cc.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_cc.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_cc.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_cc.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_bcc.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_bcc.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_bcc.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_bcc.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_bcc.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_bcc.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_subject.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_subject.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_subject.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_subject.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_subject.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_subject.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_body.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_body.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_body.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_body.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_body.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_body.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_notes.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_notes.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_notes.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_notes.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_notes.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_notes.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_created_on.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_created_on.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_created_on.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_created_on.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_created_on.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_created_on.label}}</div>
            </div>
          </button>
        </div>
        <div mat-menu-item class="splitbutton-item-container">
          <button mat-button
                  data-cy="splitbutton-sub-item"
                  class="datex-button splitbutton-sub-item" 
                  [disabled]="topToolbar.field_selector.control.buttons.fs_modified_on.readOnly"
                  [ngStyle]="topToolbar.field_selector.control.buttons.fs_modified_on.styles.style"
                  [ngClass]="topToolbar.field_selector.control.buttons.fs_modified_on.styles.classes"
                  (click)="on_field_select_change($event)">
            <div class="button-label">
              <div *ngIf="topToolbar.field_selector.control.buttons.fs_modified_on.icon"
                  class="button-icon">
                <i data-cy="splitbutton-sub-item-icon" class="icon {{topToolbar.field_selector.control.buttons.fs_modified_on.icon}}"></i>
              </div>
              <div data-cy="splitbutton-sub-item-label"
                  class="button-text">{{topToolbar.field_selector.control.buttons.fs_modified_on.label}}</div>
            </div>
          </button>
        </div>
      </div>
    </mat-menu>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="is_enabled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-slide-toggle data-cy="slideToggle" [formControl]="row.formGroup.controls['is_enabled_display']"
                  color="primary"
                  class="datex-toggle"
                  [ngStyle]="row.cells.is_enabled.displayControl.styles.style"
                  [ngClass]="row.cells.is_enabled.displayControl.styles.classes">{{row.cells.is_enabled.displayControl.label}}</mat-slide-toggle>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="rule_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.rule_id.displayControl.styles.style"
          [ngClass]="row.cells.rule_id.displayControl.styles.classes">{{row.cells.rule_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="context">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.context.displayControl.styles.style"
          [ngClass]="row.cells.context.displayControl.styles.classes">{{row.cells.context.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="frequency">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.frequency.displayControl.styles.style"
          [ngClass]="row.cells.frequency.displayControl.styles.classes">{{row.cells.frequency.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_executed_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.last_executed_on.displayControl.styles.style"
          [ngClass]="row.cells.last_executed_on.displayControl.styles.classes">{{row.cells.last_executed_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="to">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.to.displayControl.styles.style"
          [ngClass]="row.cells.to.displayControl.styles.classes">{{row.cells.to.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.cc.displayControl.styles.style"
          [ngClass]="row.cells.cc.displayControl.styles.classes">{{row.cells.cc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="bcc">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.bcc.displayControl.styles.style"
          [ngClass]="row.cells.bcc.displayControl.styles.classes">{{row.cells.bcc.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="subject">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.subject.displayControl.styles.style"
          [ngClass]="row.cells.subject.displayControl.styles.classes">{{row.cells.subject.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="body">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.body.displayControl.styles.style"
          [ngClass]="row.cells.body.displayControl.styles.classes">{{row.cells.body.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_on.displayControl.styles.style"
          [ngClass]="row.cells.created_on.displayControl.styles.classes">{{row.cells.created_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_on">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.modified_on.displayControl.styles.style"
          [ngClass]="row.cells.modified_on.displayControl.styles.classes">{{row.cells.modified_on.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
