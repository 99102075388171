<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-move_up" *ngIf="!topToolbar.move_up.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.move_up.control.readOnly"
            [ngStyle]="topToolbar.move_up.control.styles.style"
            [ngClass]="topToolbar.move_up.control.styles.classes"
            (click)="on_move_up_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.move_up.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.move_up.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.move_up.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-move_down" *ngIf="!topToolbar.move_down.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.move_down.control.readOnly"
            [ngStyle]="topToolbar.move_down.control.styles.style"
            [ngClass]="topToolbar.move_down.control.styles.classes"
            (click)="on_move_down_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.move_down.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.move_down.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.move_down.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-remove" *ngIf="!topToolbar.remove.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.remove.control.readOnly"
            [ngStyle]="topToolbar.remove.control.styles.style"
            [ngClass]="topToolbar.remove.control.styles.classes"
            (click)="on_remove_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.remove.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.remove.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.remove.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="priority">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.priority.displayControl.styles.style"
          [ngClass]="row.cells.priority.displayControl.styles.classes">{{row.cells.priority.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="operation_code_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.operation_code_name.displayControl.styles.style"
          [ngClass]="row.cells.operation_code_name.displayControl.styles.classes">{{row.cells.operation_code_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <LaborManagement-operation_codes_dd_single 
        data-cy="selector"
        [type]="row.cells.operation_code_name.editControl.type"
        [formControl]="row.formGroup.controls['operation_code_name_edit']"
        (displayTextChange)="row.cells.operation_code_name.editControl.displayText=$event"
        [placeholder]="row.cells.operation_code_name.editControl.placeholder"
        [styles]="row.cells.operation_code_name.editControl.styles"
      [excludeIds]="row.$fields_operation_code_name_selector_inParams_excludeIds"
    >
    </LaborManagement-operation_codes_dd_single>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
