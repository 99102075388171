<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-deleteLine" *ngIf="!topToolbar.deleteLine.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.deleteLine.control.readOnly"
            [ngStyle]="topToolbar.deleteLine.control.styles.style"
            [ngClass]="topToolbar.deleteLine.control.styles.classes"
            (click)="on_delete($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.deleteLine.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.deleteLine.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.deleteLine.control.label}}</div>
      </div>
    
    </button>
    </div>
  </ng-container>


  <ng-container gridColumnDef="Id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Id.displayControl.styles.style"
          [ngClass]="row.cells.Id.displayControl.styles.classes">{{row.cells.Id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Project.displayControl.styles.style"
          [ngClass]="row.cells.Project.displayControl.styles.classes">{{row.cells.Project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <Owners-projects_dd_single 
        data-cy="selector"
        [type]="row.cells.Project.editControl.type"
        [formControl]="row.formGroup.controls['Project_edit']"
        (displayTextChange)="row.cells.Project.editControl.displayText=$event"
        [placeholder]="row.cells.Project.editControl.placeholder"
        [styles]="row.cells.Project.editControl.styles"
      [statusId]="row.$fields_Project_selector_inParams_statusId"
    >
    </Owners-projects_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Percentage">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Percentage.displayControl.styles.style"
          [ngClass]="row.cells.Percentage.displayControl.styles.classes">{{row.cells.Percentage.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['Percentage_edit']"
            matInput
            numberBox
            [format]="row.cells.Percentage.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.Percentage.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Percentage.editControl.placeholder}}"
            [ngStyle]="row.cells.Percentage.editControl.styles.style"
            [ngClass]="row.cells.Percentage.editControl.styles.classes">
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
