<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-create_work_order" *ngIf="!toolbar.create_work_order.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.create_work_order.control.readOnly"
                [ngStyle]="toolbar.create_work_order.control.styles.style"
                [ngClass]="toolbar.create_work_order.control.styles.classes"
                (click)="on_create_work_order_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.create_work_order.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.create_work_order.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.create_work_order.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-warehouse" *ngIf="!filters.warehouse.hidden" 
                                  class="field-container standard {{filters.warehouse.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.warehouse.styles.style"
                                  [ngClass]="filters.warehouse.styles.classes">
                              <div class="label-container"
                                    title="{{filters.warehouse.label}}{{filters.warehouse.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.warehouse.label}}">{{filters.warehouse.label}}<span *ngIf="filters.warehouse.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Locations-warehouses_dd_single 
                                  data-cy="selector"
                                  [type]="filters.warehouse.control.type"
                                  formControlName="warehouse"
                                  (displayTextChange)="filters.warehouse.control.displayText=$event"
                                  [placeholder]="filters.warehouse.control.placeholder"
                                  [styles]="filters.warehouse.control.styles"
                              >
                              </Locations-warehouses_dd_single>
                              <ng-container *ngIf="filters.warehouse.invalid">
                                <ng-container *ngFor="let error of filters.warehouse.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
                                  class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                    title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <WorkOrders-work_order_statuses_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                              >
                              </WorkOrders-work_order_statuses_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>

        <div class="widgets">
          <div *ngIf="!widgets.work_order_total_completed_units_by_date.hidden" class="widget-container">
            <WorkOrders-work_order_total_completed_units_by_date_widget
            #$widgets_work_order_total_completed_units_by_date 
              [date]="$widgets_work_order_total_completed_units_by_date_inParams_date"
              [ownerId]="$widgets_work_order_total_completed_units_by_date_inParams_ownerId"
              [projectId]="$widgets_work_order_total_completed_units_by_date_inParams_projectId"
              [warehouseId]="$widgets_work_order_total_completed_units_by_date_inParams_warehouseId"
              [statusIds]="$widgets_work_order_total_completed_units_by_date_inParams_statusIds"
            ($refreshEvent)="refresh(false, false, '$widgets_work_order_total_completed_units_by_date')"
            >
            </WorkOrders-work_order_total_completed_units_by_date_widget>
          </div>
          <div *ngIf="!widgets.work_order_total_open_units_by_date_widget.hidden" class="widget-container">
            <WorkOrders-work_order_total_open_units_by_date_widget
            #$widgets_work_order_total_open_units_by_date_widget 
              [date]="$widgets_work_order_total_open_units_by_date_widget_inParams_date"
              [ownerId]="$widgets_work_order_total_open_units_by_date_widget_inParams_ownerId"
              [projectId]="$widgets_work_order_total_open_units_by_date_widget_inParams_projectId"
              [warehouseId]="$widgets_work_order_total_open_units_by_date_widget_inParams_warehouseId"
              [statusIds]="$widgets_work_order_total_open_units_by_date_widget_inParams_statusIds"
            ($refreshEvent)="refresh(false, false, '$widgets_work_order_total_open_units_by_date_widget')"
            >
            </WorkOrders-work_order_total_open_units_by_date_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.work_orders.hidden" class="tab" data-cy="tab-work_orders">
              <h2 [className]="tabs.work_orders.active? 'active': ''" (click)="tabs.work_orders.activate()">{{tabs.work_orders.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-work_orders_grid *ngIf="tabs.work_orders.active"
              #$tabs_work_orders
              [ownerId]="$tabs_work_orders_work_orders_grid_inParams_ownerId"
              [projectId]="$tabs_work_orders_work_orders_grid_inParams_projectId"
              [warehouseId]="$tabs_work_orders_work_orders_grid_inParams_warehouseId"
              [statusIds]="$tabs_work_orders_work_orders_grid_inParams_statusIds"
              ($refreshEvent)="refresh(false, false, '$tabs_work_orders')">
              </FootPrintManager-work_orders_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>