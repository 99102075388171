import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { ExcelMaterialImport_excel_material_import_library_homeComponent } from './ExcelMaterialImport.excel_material_import_library_home.component';
import { ExcelMaterialImport_excel_material_import_gridComponent } from './ExcelMaterialImport.excel_material_import_grid.component';
import { ExcelMaterialImport_excel_material_import_staging_gridComponent } from './ExcelMaterialImport.excel_material_import_staging_grid.component';
import { ExcelMaterialImport_excel_materials_packagings_import_gridComponent } from './ExcelMaterialImport.excel_materials_packagings_import_grid.component';
import { ExcelMaterialImport_view_json_payloadComponent } from './ExcelMaterialImport.view_json_payload.component';
import { ExcelMaterialImport_view_xml_payloadComponent } from './ExcelMaterialImport.view_xml_payload.component';
import { ExcelMaterialImport_allocation_strategy_dd_singleComponent } from './ExcelMaterialImport.allocation_strategy_dd_single.component';
import { ExcelMaterialImport_import_statuses_dd_singleComponent } from './ExcelMaterialImport.import_statuses_dd_single.component';
import { ExcelMaterialImport_material_groups_dd_singleComponent } from './ExcelMaterialImport.material_groups_dd_single.component';
import { ExcelMaterialImport_material_packagings_dd_singleComponent } from './ExcelMaterialImport.material_packagings_dd_single.component';
import { ExcelMaterialImport_material_statuses_dd_singleComponent } from './ExcelMaterialImport.material_statuses_dd_single.component';
import { ExcelMaterialImport_measurement_units_dd_singleComponent } from './ExcelMaterialImport.measurement_units_dd_single.component';
import { ExcelMaterialImport_owners_dd_singleComponent } from './ExcelMaterialImport.owners_dd_single.component';
import { ExcelMaterialImport_projects_dd_singleComponent } from './ExcelMaterialImport.projects_dd_single.component';
import { ExcelMaterialImport_storage_category_rules_dd_singleComponent } from './ExcelMaterialImport.storage_category_rules_dd_single.component';
import { ExcelMaterialImport_yn_dd_singleComponent } from './ExcelMaterialImport.yn_dd_single.component';
import { ExcelMaterialImport_allocation_strategy_dd_multiComponent } from './ExcelMaterialImport.allocation_strategy_dd_multi.component';
import { ExcelMaterialImport_import_statuses_dd_multiComponent } from './ExcelMaterialImport.import_statuses_dd_multi.component';
import { ExcelMaterialImport_material_groups_dd_multiComponent } from './ExcelMaterialImport.material_groups_dd_multi.component';
import { ExcelMaterialImport_material_packagings_dd_multiComponent } from './ExcelMaterialImport.material_packagings_dd_multi.component';
import { ExcelMaterialImport_material_statuses_dd_multiComponent } from './ExcelMaterialImport.material_statuses_dd_multi.component';
import { ExcelMaterialImport_measurement_units_dd_multiComponent } from './ExcelMaterialImport.measurement_units_dd_multi.component';
import { ExcelMaterialImport_owners_dd_multiComponent } from './ExcelMaterialImport.owners_dd_multi.component';
import { ExcelMaterialImport_projects_dd_multiComponent } from './ExcelMaterialImport.projects_dd_multi.component';
import { ExcelMaterialImport_storage_category_rules_dd_multiComponent } from './ExcelMaterialImport.storage_category_rules_dd_multi.component';
import { ExcelMaterialImport_yn_dd_multiComponent } from './ExcelMaterialImport.yn_dd_multi.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class ExcelMaterialImport_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public ExcelMaterialImport: ExcelMaterialImport_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openexcel_material_import_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'ExcelMaterialImport_excel_material_import_library_home',
        component: ExcelMaterialImport_excel_material_import_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_material_import_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelMaterialImport_excel_material_import_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openexcel_material_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material import',
        referenceName: 'ExcelMaterialImport_excel_material_import_grid',
        component: ExcelMaterialImport_excel_material_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_material_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelMaterialImport_excel_material_import_gridComponent,
      'Material import',
      mode,
      dialogSize
    )
  }
  public openexcel_material_import_staging_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Excel material import staging',
        referenceName: 'ExcelMaterialImport_excel_material_import_staging_grid',
        component: ExcelMaterialImport_excel_material_import_staging_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_material_import_staging_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelMaterialImport_excel_material_import_staging_gridComponent,
      'Excel material import staging',
      mode,
      dialogSize
    )
  }
  public openexcel_materials_packagings_import_grid(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Material packaging import',
        referenceName: 'ExcelMaterialImport_excel_materials_packagings_import_grid',
        component: ExcelMaterialImport_excel_materials_packagings_import_gridComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openexcel_materials_packagings_import_gridDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelMaterialImport_excel_materials_packagings_import_gridComponent,
      'Material packaging import',
      mode,
      dialogSize
    )
  }
  public openview_json_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'view_json_payload',
        referenceName: 'ExcelMaterialImport_view_json_payload',
        component: ExcelMaterialImport_view_json_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_json_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelMaterialImport_view_json_payloadComponent,
      'view_json_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openview_xml_payload(inParams:{ payload?: any, title?: string }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'view_xml_payload',
        referenceName: 'ExcelMaterialImport_view_xml_payload',
        component: ExcelMaterialImport_view_xml_payloadComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openview_xml_payloadDialog(
    inParams:{ payload?: any, title?: string }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      ExcelMaterialImport_view_xml_payloadComponent,
      'view_xml_payload',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'ExcelMaterialImport_excel_material_import_library_home') {
      const title = 'Home';
      const component = ExcelMaterialImport_excel_material_import_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_excel_material_import_grid') {
      const title = 'Material import';
      const component = ExcelMaterialImport_excel_material_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_excel_material_import_staging_grid') {
      const title = 'Excel material import staging';
      const component = ExcelMaterialImport_excel_material_import_staging_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_excel_materials_packagings_import_grid') {
      const title = 'Material packaging import';
      const component = ExcelMaterialImport_excel_materials_packagings_import_gridComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_view_json_payload') {
      const title = 'view_json_payload';
      const component = ExcelMaterialImport_view_json_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_view_xml_payload') {
      const title = 'view_xml_payload';
      const component = ExcelMaterialImport_view_xml_payloadComponent;
      const inParams:{ payload?: any, title?: string } = { payload: null, title: null };
      if (!isNil(params.get('payload'))) {
        const paramValueString = params.get('payload');
        inParams.payload = this.convertToJSON(paramValueString);
      }
      if (!isNil(params.get('title'))) {
        const paramValueString = params.get('title');
        // TODO: date
        inParams.title = paramValueString;
              }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_allocation_strategy_dd_single') {
      const title = 'Allocation Strategy dropdown';
      const component = ExcelMaterialImport_allocation_strategy_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_import_statuses_dd_single') {
      const title = 'import_statuses_dd';
      const component = ExcelMaterialImport_import_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_material_groups_dd_single') {
      const title = 'Material Groups dropdown';
      const component = ExcelMaterialImport_material_groups_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_material_packagings_dd_single') {
      const title = 'Material Packagings dropdown';
      const component = ExcelMaterialImport_material_packagings_dd_singleComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_material_statuses_dd_single') {
      const title = 'Material Statuses dropdown';
      const component = ExcelMaterialImport_material_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_measurement_units_dd_single') {
      const title = 'Measurement Units dropdown';
      const component = ExcelMaterialImport_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_owners_dd_single') {
      const title = 'Owners dropdown';
      const component = ExcelMaterialImport_owners_dd_singleComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_projects_dd_single') {
      const title = 'Projects dropdown';
      const component = ExcelMaterialImport_projects_dd_singleComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_storage_category_rules_dd_single') {
      const title = 'storage_category_rules_dd';
      const component = ExcelMaterialImport_storage_category_rules_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_yn_dd_single') {
      const title = 'Yes No dropdown';
      const component = ExcelMaterialImport_yn_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_allocation_strategy_dd_multi') {
      const title = 'Allocation Strategy dropdown';
      const component = ExcelMaterialImport_allocation_strategy_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_import_statuses_dd_multi') {
      const title = 'import_statuses_dd';
      const component = ExcelMaterialImport_import_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_material_groups_dd_multi') {
      const title = 'Material Groups dropdown';
      const component = ExcelMaterialImport_material_groups_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_material_packagings_dd_multi') {
      const title = 'Material Packagings dropdown';
      const component = ExcelMaterialImport_material_packagings_dd_multiComponent;
      const inParams:{ materialId?: number } = { materialId: null };
      if (!isNil(params.get('materialId'))) {
        const paramValueString = params.get('materialId');
        inParams.materialId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_material_statuses_dd_multi') {
      const title = 'Material Statuses dropdown';
      const component = ExcelMaterialImport_material_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_measurement_units_dd_multi') {
      const title = 'Measurement Units dropdown';
      const component = ExcelMaterialImport_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_owners_dd_multi') {
      const title = 'Owners dropdown';
      const component = ExcelMaterialImport_owners_dd_multiComponent;
      const inParams:{ statusId?: number, projectId?: number } = { statusId: null, projectId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_projects_dd_multi') {
      const title = 'Projects dropdown';
      const component = ExcelMaterialImport_projects_dd_multiComponent;
      const inParams:{ statusId?: number, ownerId?: number } = { statusId: null, ownerId: null };
      if (!isNil(params.get('statusId'))) {
        const paramValueString = params.get('statusId');
        inParams.statusId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_storage_category_rules_dd_multi') {
      const title = 'storage_category_rules_dd';
      const component = ExcelMaterialImport_storage_category_rules_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'ExcelMaterialImport_yn_dd_multi') {
      const title = 'Yes No dropdown';
      const component = ExcelMaterialImport_yn_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
