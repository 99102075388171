<div class="blade-wrapper">
    <div class="blade-header" *ngIf="!showInDialog || (showInDialog && hasToolbar)">
      <div           class="blade-tools">
        <div data-cy="tool-id-new_contract" *ngIf="!toolbar.new_contract.hidden" class="toolContainer">
        <button mat-button
                data-cy="button"
                class="datex-button"
                [disabled]="toolbar.new_contract.control.readOnly"
                [ngStyle]="toolbar.new_contract.control.styles.style"
                [ngClass]="toolbar.new_contract.control.styles.classes"
                (click)="on_new_clicked($event)"
                >
          <div class="button-label">
            <ng-container *ngIf="toolbar.new_contract.control.icon === 'datex-default-spinner'; else showIcon">
              <div class="button-icon">
                <i data-cy="button-icon" class="datex-default-spinner"></i>
              </div>
            </ng-container>
            <ng-template #showIcon>
              <div class="button-icon">
                <i data-cy="button-icon" class="icon {{toolbar.new_contract.control.icon}}"></i>
              </div>
            </ng-template>
            <div data-cy="button-label" class="button-text">{{toolbar.new_contract.control.label}}</div>
          </div>
        
        </button>
        </div>
      </div>
      <ng-content></ng-content>
    </div>

  <div class="blade-content">
    <div class="datex-hub">
      <ng-container
                    *ngIf="initialized ">
        <div class="hubdata">
          <h1 class="hubname" *ngIf="!showInDialog">{{hubTitle}}</h1>
          <h6 class="hubdesc">{{hubDescription}}</h6>
          <div class="hub-filters">
            <div class="fieldsetsGroup">
              <div [formGroup]="formGroup"
                   class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!filtersets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': filtersets.newGroup1.collapsible }">
                    <div *ngIf="!filtersets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="filtersets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Filters</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="filtersets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !filtersets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': filtersets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="filtersets.newGroup1.expanded"
                         class="fieldsetsGroup">
                            <div data-cy="field-id-owner" *ngIf="!filters.owner.hidden" 
                                  class="field-container standard {{filters.owner.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.owner.styles.style"
                                  [ngClass]="filters.owner.styles.classes">
                              <div class="label-container"
                                    title="{{filters.owner.label}}{{filters.owner.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.owner.label}}">{{filters.owner.label}}<span *ngIf="filters.owner.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-owners_dd_single 
                                  data-cy="selector"
                                  [type]="filters.owner.control.type"
                                  formControlName="owner"
                                  (displayTextChange)="filters.owner.control.displayText=$event"
                                  [placeholder]="filters.owner.control.placeholder"
                                  [styles]="filters.owner.control.styles"
                                [statusId]="$fields_owner_selector_inParams_statusId"
                                [projectId]="$fields_owner_selector_inParams_projectId"
                              >
                              </Owners-owners_dd_single>
                              <ng-container *ngIf="filters.owner.invalid">
                                <ng-container *ngFor="let error of filters.owner.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
                                  class="field-container standard {{filters.project.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.project.styles.style"
                                  [ngClass]="filters.project.styles.classes">
                              <div class="label-container"
                                    title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Owners-projects_dd_single 
                                  data-cy="selector"
                                  [type]="filters.project.control.type"
                                  formControlName="project"
                                  (displayTextChange)="filters.project.control.displayText=$event"
                                  [placeholder]="filters.project.control.placeholder"
                                  [styles]="filters.project.control.styles"
                                [statusId]="$fields_project_selector_inParams_statusId"
                                [ownerId]="$fields_project_selector_inParams_ownerId"
                              >
                              </Owners-projects_dd_single>
                              <ng-container *ngIf="filters.project.invalid">
                                <ng-container *ngFor="let error of filters.project.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
                                  class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.status.styles.style"
                                  [ngClass]="filters.status.styles.classes">
                              <div class="label-container"
                                    title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <Invoices-billing_contracts_status_dd_multi 
                                  data-cy="selector-multi"
                                  [type]="filters.status.control.type"
                                  formControlName="status"
                                  (displayTextChange)="filters.status.control.displayText=$event"
                                  [placeholder]="filters.status.control.placeholder"
                                  [styles]="filters.status.control.styles"
                              >
                              </Invoices-billing_contracts_status_dd_multi>
                              <ng-container *ngIf="filters.status.invalid">
                                <ng-container *ngFor="let error of filters.status.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-expiring" *ngIf="!filters.expiring.hidden" 
                                  class="field-container standard {{filters.expiring.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.expiring.styles.style"
                                  [ngClass]="filters.expiring.styles.classes">
                              <div class="label-container"
                                    title="{{filters.expiring.label}}{{filters.expiring.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.expiring.label}}">{{filters.expiring.label}}<span *ngIf="filters.expiring.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <mat-slide-toggle data-cy="slideToggle" formControlName="expiring"
                                            color="primary"
                                            class="datex-toggle"
                                            [ngStyle]="filters.expiring.control.styles.style"
                                            [ngClass]="filters.expiring.control.styles.classes">{{filters.expiring.control.label}}</mat-slide-toggle>
                              <ng-container *ngIf="filters.expiring.invalid">
                                <ng-container *ngFor="let error of filters.expiring.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>              <div data-cy="field-id-expiring_days" *ngIf="!filters.expiring_days.hidden" 
                                  class="field-container standard {{filters.expiring_days.invalid ? 'invalid' : ''}}"
                                  [ngStyle]="filters.expiring_days.styles.style"
                                  [ngClass]="filters.expiring_days.styles.classes">
                              <div class="label-container"
                                    title="{{filters.expiring_days.label}}{{filters.expiring_days.required ? ' (required)' : ''}}">
                                <label data-cy="field-label" class="datex-label"
                                        title="{{filters.expiring_days.label}}">{{filters.expiring_days.label}}<span *ngIf="filters.expiring_days.required"
                                        class="required-asterisk">*</span></label>
                              </div>
                              <input data-cy="numberBox" formControlName="expiring_days"
                                      matInput
                                      numberBox
                                      [format]="filters.expiring_days.control.format"
                                      autocomplete="off"
                                      spellcheck="false"
                                      class="datex-numberbox {{filters.expiring_days.control.readOnly ? 'readonly ' : ''}}"
                                      placeholder="{{filters.expiring_days.control.placeholder}}"
                                      [ngStyle]="filters.expiring_days.control.styles.style"
                                      [ngClass]="filters.expiring_days.control.styles.classes">
                              <ng-container *ngIf="filters.expiring_days.invalid">
                                <ng-container *ngFor="let error of filters.expiring_days.errors">
                                  <span class="invalid-message">
                                    {{error}}
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>            </div>
                        </div>
                      </div>
                    </div>            </div>
          </div>
        </div>


        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.billing_contracts.hidden" class="tab" data-cy="tab-billing_contracts">
              <h2 [className]="tabs.billing_contracts.active? 'active': ''" (click)="tabs.billing_contracts.activate()">{{tabs.billing_contracts.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-billing_contracts_grid *ngIf="tabs.billing_contracts.active"
              #$tabs_billing_contracts
              [ownerId]="$tabs_billing_contracts_billing_contracts_grid_inParams_ownerId"
              [projectId]="$tabs_billing_contracts_billing_contracts_grid_inParams_projectId"
              [statusIds]="$tabs_billing_contracts_billing_contracts_grid_inParams_statusIds"
              [endDate]="$tabs_billing_contracts_billing_contracts_grid_inParams_endDate"
              ($refreshEvent)="refresh(false, false, '$tabs_billing_contracts')">
              </FootPrintManager-billing_contracts_grid>
        </div>

      </ng-container>
    </div>
  </div>
</div>