import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './EasyPost.types'

@Injectable({ providedIn: 'root' })
export class EasyPost_one_call_buy_shipment_request_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { carrierAccount: string, service: string, parcels: any[], fromAddress: any, toAddress: any, labelSize?: string, labelFormat?: string, printCustomOne?: string, contentsExplanation?: string, referenceNumber?: string, customsItems?: any[], customsSigner?: string, incoterm?: string, thirdPartyAddress?: { street1?: string, street2?: string, city?: string, state?: string, zip?: string, country?: string, phone?: string, email?: string, name?: string }, taxIdentifiers?: any[], buyerAddress?: any }): Promise<{ easyPostShipmentId?: string, easyPostTrackerId?: string, easyPostTrackingCode?: string, easyPostParcelWeight?: number, easyPostParcelLength?: number, easyPostParcelWidth?: number, easyPostParcelHeight?: number, easyPostShipmentCreationDate?: string, easyPostSelectedRate?: number, easyPostSelectedRateDeliveryDate?: string, easyPostPostageLabelUrl?: string[], outputDetails?: string, easyPostTrackerPublicUrl?: string, easyPostCommercialInvoiceUrl?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.carrierAccount)) {
      missingRequiredInParams.push('\'carrierAccount\'');
    }
    if (isNil(inParams.service)) {
      missingRequiredInParams.push('\'service\'');
    }
    if (isNil(inParams.parcels)) {
      missingRequiredInParams.push('\'parcels\'');
    }
    if (isNil(inParams.fromAddress)) {
      missingRequiredInParams.push('\'fromAddress\'');
    }
    if (isNil(inParams.toAddress)) {
      missingRequiredInParams.push('\'toAddress\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/EasyPost/functions/one_call_buy_shipment_request_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

