<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-name" *ngIf="!fields.name.hidden" 
                            class="field-container double {{fields.name.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.name.styles.style"
                            [ngClass]="fields.name.styles.classes">
                        <div class="label-container"
                              title="{{fields.name.label}}{{fields.name.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.name.label}}">{{fields.name.label}}<span *ngIf="fields.name.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="name"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.name.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.name.control.placeholder}}"
                                [ngStyle]="fields.name.control.styles.style"
                                [ngClass]="fields.name.control.styles.classes"> 
                        <ng-container *ngIf="fields.name.invalid">
                          <ng-container *ngFor="let error of fields.name.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container double {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> 
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-time_zone" *ngIf="!fields.time_zone.hidden" 
                            class="field-container double {{fields.time_zone.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.time_zone.styles.style"
                            [ngClass]="fields.time_zone.styles.classes">
                        <div class="label-container"
                              title="{{fields.time_zone.label}}{{fields.time_zone.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.time_zone.label}}">{{fields.time_zone.label}}<span *ngIf="fields.time_zone.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-timezones_dd_single 
                            data-cy="selector"
                            [type]="fields.time_zone.control.type"
                            formControlName="time_zone"
                            (displayTextChange)="fields.time_zone.control.displayText=$event"
                            [placeholder]="fields.time_zone.control.placeholder"
                            [styles]="fields.time_zone.control.styles"
                        >
                        </Locations-timezones_dd_single>
                        <ng-container *ngIf="fields.time_zone.invalid">
                          <ng-container *ngFor="let error of fields.time_zone.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-default_receiving_location" *ngIf="!fields.default_receiving_location.hidden" 
                            class="field-container double {{fields.default_receiving_location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.default_receiving_location.styles.style"
                            [ngClass]="fields.default_receiving_location.styles.classes">
                        <div class="label-container"
                              title="{{fields.default_receiving_location.label}}{{fields.default_receiving_location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.default_receiving_location.label}}">{{fields.default_receiving_location.label}}<span *ngIf="fields.default_receiving_location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.default_receiving_location.control.type"
                            formControlName="default_receiving_location"
                            (displayTextChange)="fields.default_receiving_location.control.displayText=$event"
                            [placeholder]="fields.default_receiving_location.control.placeholder"
                            [styles]="fields.default_receiving_location.control.styles"
                          [warehouseId]="$fields_default_receiving_location_selector_inParams_warehouseId"
                          [typeId]="$fields_default_receiving_location_selector_inParams_typeId"
                          [eligibleForAllocation]="$fields_default_receiving_location_selector_inParams_eligibleForAllocation"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.default_receiving_location.invalid">
                          <ng-container *ngFor="let error of fields.default_receiving_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-default_picking_location" *ngIf="!fields.default_picking_location.hidden" 
                            class="field-container double {{fields.default_picking_location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.default_picking_location.styles.style"
                            [ngClass]="fields.default_picking_location.styles.classes">
                        <div class="label-container"
                              title="{{fields.default_picking_location.label}}{{fields.default_picking_location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.default_picking_location.label}}">{{fields.default_picking_location.label}}<span *ngIf="fields.default_picking_location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-locations_dd_single 
                            data-cy="selector"
                            [type]="fields.default_picking_location.control.type"
                            formControlName="default_picking_location"
                            (displayTextChange)="fields.default_picking_location.control.displayText=$event"
                            [placeholder]="fields.default_picking_location.control.placeholder"
                            [styles]="fields.default_picking_location.control.styles"
                          [warehouseId]="$fields_default_picking_location_selector_inParams_warehouseId"
                          [typeId]="$fields_default_picking_location_selector_inParams_typeId"
                        >
                        </Locations-locations_dd_single>
                        <ng-container *ngIf="fields.default_picking_location.invalid">
                          <ng-container *ngFor="let error of fields.default_picking_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>