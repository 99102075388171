import {
Component,
OnInit,
OnDestroy,
OnChanges,
Input,
SimpleChanges,
Output,
EventEmitter,
Inject
} from '@angular/core';

import {
FormGroup,
Validators
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { DatexFormControl } from './models/datex-form-control';
import {
TextBoxModel,
NumberBoxModel,
SelectBoxModel,
ESelectBoxType,
DateBoxModel,
CheckBoxModel,
TextModel,
LabelModel,
ButtonModel,
SplitButtonModel,
SeparatorModel,
ImageModel,
DrawModel,
CodeBoxModel,
ButtonStyles
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';
import * as vkbeautify from 'vkbeautify';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Notifications_ShellService, EModalSize, EToasterType, EToasterPosition } from './Notifications.shell.service';
import { Notifications_OperationService } from './Notifications.operation.service';
import { Notifications_DatasourceService } from './Notifications.datasource.index';
import { Notifications_FlowService } from './Notifications.flow.index';
import { Notifications_ReportService } from './Notifications.report.index';
import { Notifications_LocalizationService } from './Notifications.localization.service';
import { Language } from './localization.service';
import { $types } from './Notifications.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Notifications-view_field_code_editor',
  templateUrl: './Notifications.view_field_code_editor.component.html'
})
export class Notifications_view_field_code_editorComponent implements OnInit, OnDestroy, OnChanges
{

inParams: { field_name: string, field_value: string } = { field_name: null, field_value: null };
//#region Inputs
@Input('field_name') set $inParams_field_name(v: string) {
  this.inParams.field_name = v;
}
get $inParams_field_name(): string {
  return this.inParams.field_name;
}
@Input('field_value') set $inParams_field_value(v: string) {
  this.inParams.field_value = v;
}
get $inParams_field_value(): string {
  return this.inParams.field_value;
}
//#endregion Inputs

//#region Outputs
@Output()
$finish = new EventEmitter();
//#endregion

//#region title
// Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
// The title is often meant to be shown from the parent (shell breadcrumb for example)
// and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
// the parent has already been checked and the child now change something on the parent 
// in dev, CD is run twice
$titleChange = new EventEmitter<string>(true);
private $_title: string;
get title(): string {
  return this.$_title;
}
set title(t: string) {
  this.$_title = t;
  this.$titleChange.emit(this.$_title);
}
//#endregion title
codeMirrorOptions = {
theme: 'base16-light',
mode: 'application/xml',
lineNumbers: true,
lineWrapping: true,
foldGutter: true,
gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
autoCloseBrackets: true,
matchBrackets: true,
lint: true
};

mode = 'application/xml';

value: string;

//#region Variables
//#endregion
//#region Events

//#endregion

toolbar = {
};

constructor(
private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Notifications_ShellService,
private datasources: Notifications_DatasourceService,
private flows: Notifications_FlowService,
private reports: Notifications_ReportService,
private localization: Notifications_LocalizationService,
private operations: Notifications_OperationService,
) {
}

ngOnInit(): void {
  this.$checkRequiredInParams();
  if(!this.$hasMissingRequiredInParams) {
    this.$init();
  }
}

private $isFirstNgOnChanges = true;
ngOnChanges(changes: SimpleChanges): void {
  if (this.$isFirstNgOnChanges) {
    this.$isFirstNgOnChanges = false;
  } else {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
}

private $unsubscribe$ = new Subject();
ngOnDestroy(): void {
  this.$unsubscribe$.next(null);
  this.$unsubscribe$.complete();
}
$missingRequiredInParams = [];
get $hasMissingRequiredInParams(): boolean {
  return !!this.$missingRequiredInParams.length;
}

$checkRequiredInParams() {
  this.$missingRequiredInParams = [];
    if(isNil(this.inParams.field_name)) {
      this.$missingRequiredInParams.push('field_name');
    }
    if(isNil(this.inParams.field_value)) {
      this.$missingRequiredInParams.push('field_value');
    }
}

initialized = false;

async $init() {
this.title = 'View field';

const $codeEditor = this;
const $utils = this.utils;


await this.on_init();

this.initialized = true;
}

close() {
this.$finish.emit();
}

beautify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xml(this.value) : vkbeautify.json(this.value);
}

minify(): void {
this.value = this.mode === 'application/xml' ? vkbeautify.xmlmin(this.value, [, true]) : vkbeautify.jsonmin(this.value);
}

refresh(
skipParent = false,
skipChildren = false,
childToSkip: string = null) {
}

//#region private flows
on_init(event = null) {
  return this.on_initInternal(
    this,
this.shell,
    this.datasources,
    this.flows,
    this.reports,
    this.settings,
    this.operations,
    this.utils,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    // this.localization,
    event);
}
async on_initInternal(
  $codeEditor: Notifications_view_field_code_editorComponent,

  $shell: Notifications_ShellService,
  $datasources: Notifications_DatasourceService,
  $flows: Notifications_FlowService,
  $reports: Notifications_ReportService,
  $settings: SettingsValuesService,
  $operations: Notifications_OperationService,
  $utils: UtilsService,
  // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
  //$l10n: Notifications_LocalizationService,
  $event: any
) {
$codeEditor.title = $codeEditor.inParams.field_name;
$codeEditor.value = $codeEditor.inParams.field_value;
}
//#endregion private flows
}