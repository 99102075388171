import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Lots_ds_lots_grid_flat_flow_basedService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }


  public async getList(inParams: { ownerIds?: number[], projectIds?: number[], materialIds?: number[], fullTextSearch?: string, statusIds?: number[], fromDate?: string, toDate?: string, dateType?: string, $top?: number, $skip?: number }): Promise<{ result?: { VendorLotId?: number, VendorLotLookupCode?: string, VendorLotDescription?: string, ExpirationDate?: string, ManufactureDate?: string, VendorLotNotes?: string, Vat?: string, VendorLotCreatedSysDateTime?: string, VendorLotModifiedSysDateTime?: string, MaterialId?: number, LotId?: number, LotDescription?: string, LotLookupCode?: string, LastReceiveDate?: string, ReceiveDate?: string, LotNotes?: string, StatusId?: number, LotCreatedSysDateTime?: string, LotModifiedSysDateTime?: string, StatusName?: string, MaterialLookupCode?: string, MaterialName?: string, MaterialDescription?: string, ProjectId?: number, ProjectLookupCode?: string, ProjectName?: string, OwnerId?: number, OwnerLookupCode?: string, OwnerName?: string }[], totalCount?: number }> {

    let url = `${environment.backendUrl}api/Lots/datasources/ds_lots_grid_flat_flow_based/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { ownerIds?: number[], projectIds?: number[], materialIds?: number[], fullTextSearch?: string, statusIds?: number[], fromDate?: string, toDate?: string, dateType?: string, $keys: { VendorLotId?: number, LotId?: number }[] }): Promise<{ result?: { VendorLotId?: number, VendorLotLookupCode?: string, VendorLotDescription?: string, ExpirationDate?: string, ManufactureDate?: string, VendorLotNotes?: string, Vat?: string, VendorLotCreatedSysDateTime?: string, VendorLotModifiedSysDateTime?: string, MaterialId?: number, LotId?: number, LotDescription?: string, LotLookupCode?: string, LastReceiveDate?: string, ReceiveDate?: string, LotNotes?: string, StatusId?: number, LotCreatedSysDateTime?: string, LotModifiedSysDateTime?: string, StatusName?: string, MaterialLookupCode?: string, MaterialName?: string, MaterialDescription?: string, ProjectId?: number, ProjectLookupCode?: string, ProjectName?: string, OwnerId?: number, OwnerLookupCode?: string, OwnerName?: string }[] }> {

    const missingRequiredInParams = [];
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Lots/datasources/ds_lots_grid_flat_flow_based/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
}
