import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { $types } from './Invoices.types'


interface IInvoices_invoicing_instructions_gridComponentEntity {
instructionId?: string, billingContractId?: number, projectId?: number, active?: boolean, instructionType?: string, instructionName?: string, billingAggregationStrategyIds?: number[], oneInvoicePerShipment?: boolean, oneInvoicePerWarehouse?: boolean, termId?: number, taxScheduleId?: number, createdOn?: string, createdBy?: string, modifiedOn?: string, modifiedBy?: string, useExistingInvoice?: boolean}

interface IInvoices_invoicing_instructions_gridComponentInParams {
  billingContractId?: number, projectId?: number}


class Invoices_invoicing_instructions_gridComponentRowModel extends GridRowModel {
  grid: Invoices_invoicing_instructions_gridComponent;
  entity: IInvoices_invoicing_instructions_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    active_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    active_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    one_invoice_per_shipment_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    one_invoice_per_warehouse_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    use_existing_invoice_display: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    id: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    active: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['active_display'] as DatexFormControl, null, false, '')
,
      new CheckBoxModel(this.formGroup.controls['active_edit'] as DatexFormControl, null, false, '')
      ),
    billingContractId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    projectId: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    type: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    term: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    tax_schedule: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    one_invoice_per_shipment: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['one_invoice_per_shipment_display'] as DatexFormControl, null, false, '')
,
null
      ),
    one_invoice_per_warehouse: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['one_invoice_per_warehouse_display'] as DatexFormControl, null, false, '')
,
null
      ),
    use_existing_invoice: new GridCellModel(
      new CellStyles(null, null),
      new CheckBoxModel(this.formGroup.controls['use_existing_invoice_display'] as DatexFormControl, null, false, '')
,
null
      ),
    billing_aggregation_strategies: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    createdOn: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    createdBy: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modifiedOn: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    modifiedBy: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
) {
    super();
    
    this.formGroup
      .controls['active_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_activate_clicked();
      });
  }

  async $initializeExisting(grid: Invoices_invoicing_instructions_gridComponent, entity: IInvoices_invoicing_instructions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.instructionId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_invoicing_instructions_gridComponent, entity?: IInvoices_invoicing_instructions_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.instructionId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.instructionId;
    const inParams = {
      $keys:[$resultKey],
      search:  null ,
      billingContractId:  $grid.inParams.billingContractId ,
      projectId:  $grid.inParams.projectId ,
      active:  null ,
      instructionId:  null ,
    };
    const data = await this.datasources.Invoices.ds_invoicing_instructions.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.id.displayControl as TextModel).text = $row.entity.instructionId;
    (this.cells.name.displayControl as TextModel).text = $row.entity.instructionName;
    (this.cells.active.displayControl as CheckBoxModel).reset($row.entity.active);
    (this.cells.active.editControl as CheckBoxModel).reset($row.entity.active);
    (this.cells.billingContractId.displayControl as TextModel).text = $row.entity.billingContractId?.toString();
    (this.cells.projectId.displayControl as TextModel).text = $row.entity.projectId?.toString();
    (this.cells.type.displayControl as TextModel).text = $row.entity.instructionType;
    (this.cells.one_invoice_per_shipment.displayControl as CheckBoxModel).reset($row.entity.oneInvoicePerShipment);
    (this.cells.one_invoice_per_warehouse.displayControl as CheckBoxModel).reset($row.entity.oneInvoicePerWarehouse);
    (this.cells.use_existing_invoice.displayControl as CheckBoxModel).reset($row.entity.useExistingInvoice);
    (this.cells.createdOn.displayControl as TextModel).text = $row.entity.createdOn?.toString();
    (this.cells.createdBy.displayControl as TextModel).text = $row.entity.createdBy;
    (this.cells.modifiedOn.displayControl as TextModel).text = $row.entity.modifiedOn?.toString();
    (this.cells.modifiedBy.displayControl as TextModel).text = $row.entity.modifiedBy;

  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'id' : {
        this.on_id_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_id_clicked(event = null) {
    return this.on_id_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_id_clickedInternal(
    $row: Invoices_invoicing_instructions_gridComponentRowModel,
  $grid: Invoices_invoicing_instructions_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  const projectId = $row.entity.projectId;
  const billingContractId = $row.entity.billingContractId;
  
  if ($utils.isAllDefined(projectId, billingContractId)) {
  
      await $shell.Invoices.openinvoicing_instructions_editorDialog({
          instructionId: $row.entity.instructionId,
          projectId: projectId,
          billingContractId: billingContractId
      }, "modal")
  
      $grid.refresh();
  }
  }
  on_activate_clicked(event = null) {
    return this.on_activate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_activate_clickedInternal(
    $row: Invoices_invoicing_instructions_gridComponentRowModel,
  $grid: Invoices_invoicing_instructions_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  if ($row.cells.active.editControl.isChanged) {
  
      let instructionId: string = $row.entity?.instructionId ?? "";
      let active: boolean = $row.cells.active.editControl.value ?? false;
  
      try {
  
          let update = (await $flows.Invoices.invoicing_instruction({
              action: 'Update',
              payload: {
                  instructionId: instructionId,
                  active: active
              }
          }));
  
          if (!update.success) {
              throw new Error(update.error.join("."))
          };
  
          await $row.refresh();
  
      } catch (error) {
          $shell.Invoices.showErrorDetails('Save', 'Error on save.', error);
          throw error; // to prevent displayMode 
      };
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Invoices-invoicing_instructions_grid',
  templateUrl: './Invoices.invoicing_instructions_grid.component.html'
})
export class Invoices_invoicing_instructions_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_invoicing_instructions_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IInvoices_invoicing_instructions_gridComponentInParams = { billingContractId: null, projectId: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     id: new GridHeaderModel(new HeaderStyles(null, null), 'ID', false, false, null),       name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       active: new GridHeaderModel(new HeaderStyles(null, null), 'Active', false, false, null),       billingContractId: new GridHeaderModel(new HeaderStyles(null, null), 'Billing contract ID', false, false, null),       projectId: new GridHeaderModel(new HeaderStyles(null, null), 'Project ID', false, false, null),       type: new GridHeaderModel(new HeaderStyles(null, null), 'Type', false, false, null),       term: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice term', false, false, null),       tax_schedule: new GridHeaderModel(new HeaderStyles(null, null), 'Tax schedule', false, false, null),       one_invoice_per_shipment: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice per shipment', false, false, null),       one_invoice_per_warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Invoice per warehouse', false, false, null),       use_existing_invoice: new GridHeaderModel(new HeaderStyles(null, null), 'Use existing invoice', false, false, null),       billing_aggregation_strategies: new GridHeaderModel(new HeaderStyles(null, null), 'Billing aggregation strategies', false, false, null),       createdOn: new GridHeaderModel(new HeaderStyles(null, null), 'Created on', false, false, null),       createdBy: new GridHeaderModel(new HeaderStyles(null, null), 'Created by', false, false, null),       modifiedOn: new GridHeaderModel(new HeaderStyles(null, null), 'Modified on', false, false, null),       modifiedBy: new GridHeaderModel(new HeaderStyles(null, null), 'Modified by', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_invoicing_instructions_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('billingContractId') set $inParams_billingContractId(value: any) {
    this.inParams['billingContractId'] = value;
  }
  get $inParams_billingContractId(): any {
    return this.inParams['billingContractId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }

  topToolbar = {
      new_instruction: new ToolModel(new ButtonModel('new_instruction', new ButtonStyles(['creation'], null), false, 'New', 'ms-Icon ms-Icon--NewAnalyticsQuery')
    ),
      delete_instructions: new ToolModel(new ButtonModel('delete_instructions', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    ) {
    this.title = 'Invoicing instructions grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      search:  null ,
      billingContractId:  $grid.inParams.billingContractId ,
      projectId:  $grid.inParams.projectId ,
      active:  null ,
      instructionId:  null ,
    };
    try {
    const data = await this.datasources.Invoices.ds_invoicing_instructions.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_invoicing_instructions_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: Invoices_invoicing_instructions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  const terms = (await $datasources.Invoices.ds_invoice_terms_dd.get({})).result;
  const taxes = (await $datasources.Invoices.ds_tax_schedules_dd.get({})).result;
  const billing_aggregation = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({})).result;
  
  for (let row of $grid.rows) {
  
      let bs_ids = row.entity.billingAggregationStrategyIds ?? [];
  
      if (bs_ids.length > 0) {
  
          let bs_names = billing_aggregation.filter(bs => bs_ids.indexOf(bs.Id) >= 0)?.map(bs => bs.EnumName).join(', ') ?? "";
  
          if (bs_names.length > 50) {
              bs_names = bs_names.slice(0, 47)?.trim()
              bs_names = `${bs_names}...`
          }
          row.cells.billing_aggregation_strategies.displayControl.text = bs_names
      }
  
      row.cells.term.displayControl.text = terms.find(t => t.Id === row.entity.termId)?.Name;
      row.cells.tax_schedule.displayControl.text = taxes.find(t => t.Id === row.entity.taxScheduleId)?.Label;
  }
  }
  on_delete_clicked(event = null) {
    return this.on_delete_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_clickedInternal(
    $grid: Invoices_invoicing_instructions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  const selected_rows = $grid.selectedRows;
  let errors: { name: string, id: string, errorMessages: string[] }[] = [];
  let deleted_records: string[] = [];
  
  if (selected_rows.length === 0) {
      $shell.Invoices.openErrorDialog('Delete error!', 'No records were selected!');
      return;
  } else {
  
      const confirm_purge = (await $shell.Invoices.openConfirmationDialog(`Confirm delete!`, `Do you want to delete ${selected_rows.length} records?`, `Delete`, `Cancel`));
  
      if (confirm_purge) {
  
          for (const row of selected_rows) {
  
              let current_error: string;
              let success = false;
              current_error = null;
  
              try {
  
                  let entity = row.entity
  
                  await instruction_delete({
                      "instructionId": entity.instructionId
                  });
  
                  await log_add({
                      "process": "invoicing_instructions_grid",
                      "level": 1,
                      "billingContractId": entity.billingContractId,
                      "projectId": entity.projectId,
                      "notes": `Deleted record! name: [${entity.instructionName}] Instruction Id: [${entity.instructionId}]`
                  });
                  success = true;
  
              } catch (error) {
  
                  let target_error = error;
  
                  if (typeof target_error === "string") {
                      target_error = { message: target_error };
                  } else {
                      while ($utils.isDefined(target_error.error)) {
                          target_error = target_error.error;
                      };
                  };
  
                  if (!$utils.isDefined(target_error.message)) {
                      target_error = { message: `Uncaught exception! ${JSON.stringify(target_error)}` };
                  };
  
                  current_error = target_error.message;
              };
  
              if (success) {
                  deleted_records.push(row.entity.instructionId.toString())
              } else {
                  errors.push({
                      name: row.entity.instructionName, id: row.entity.instructionId, errorMessages: [current_error]
                  });
              };
          };
  
          if ($utils.isDefined(errors)) {
              await $shell.Invoices.openErrorDialog(
                  `Delete record${selected_rows.length > 1 ? 's' : ''}`,
                  deleted_records.length > 0 ? `Record${selected_rows.length > 1 ? 's' : ''} ${(await $flows.Utilities.grammar_format_string_array_flow({ values: deleted_records })).formattedValue} successfully deleted.` : `No records were deleted.`,
                  null,
                  `Errors deleting record${selected_rows.length > 1 ? 's' : ''}`,
                  errors.map(error => ({ detail: error.errorMessages[0], message: `Id: ${error.id} name: ${error.name}` })))
          };
      };
  };
  
  $grid.refresh();
  
  const log_schema = (await $flows.Invoices.invoicing_log({ action: "" })).payload;
  async function log_add(input: typeof log_schema[0]) {
      await $flows.Invoices.invoicing_log({ action: "Write", payload: input });
  };
  
  const instruction_schema = (await $flows.Invoices.invoicing_instruction({ action: "" })).payload;
  async function instruction_delete(input: typeof instruction_schema[0]) {
      await $flows.Invoices.invoicing_instruction({ action: "Delete", payload: input });
  };
  }
  on_new_clicked(event = null) {
    return this.on_new_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_new_clickedInternal(
    $grid: Invoices_invoicing_instructions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/30/2024
  
  let allowDuplicates: boolean = false;
  let option = (await $flows.Invoices.invoicing_option({ action: "Read", payload: { dimension: "AllowDuplicateStrategies" } })).payload;
  if (option.length > 0) {
      let truth: any[] = ["TRUE", "1"]
      if (truth.includes(option[0].value.toUpperCase())) {
          allowDuplicates = true
      }
  } else {
      await $flows.Invoices.invoicing_option({ action: "Write", payload: { dimension: "AllowDuplicateStrategies", value: `${allowDuplicates}` } })
  }
  
  const projectId = $grid.inParams.projectId ?? 0;
  const billingContractId = $grid.inParams.billingContractId ?? 0;
  
  let can_create_instruction: boolean = false;
  
  if ($utils.isAllDefined(projectId, billingContractId)) {
  
      if (allowDuplicates) {
          can_create_instruction = true;
      } else {
  
          let instructions = (await $flows.Invoices.invoicing_instruction({
              action: "Read",
              payload: {
                  projectId: projectId,
                  billingContractId: billingContractId
              }
          })).payload;
  
          if (instructions.length > 0) {
  
              const bs = (await $datasources.Invoices.ds_billing_aggregation_strategies_dd.get({})).result;
  
              const bs_already_used = instructions.filter(i => $utils.isDefined(i.billingAggregationStrategyIds)).map(i => i.billingAggregationStrategyIds).flat();
  
              let bs_available = bs.filter(bs => bs_already_used.indexOf(bs.Id) === -1)?.map(bs => bs.Id)
  
              if (bs_available.length === 0) {
  
                  await $shell.Invoices.openInfoDialog("All assigned!", "All your billing aggregation strategies have been assigned to the instructions on your contract, you may not add another invoicing instruction!")
                  can_create_instruction = false;
  
              } else {
                  can_create_instruction = true;
              }
          } else {
              can_create_instruction = true;
          }
      }
  
      if (can_create_instruction) {
          let instruction: $types.Invoices.Instruction[] = [{
              instructionType: "Auto Invoicing",
              active: false,
              projectId: projectId,
              billingContractId: billingContractId
          }];
  
          instruction = (await $flows.Invoices.invoicing_instruction({ action: "Write", payload: instruction[0] })).payload;
  
          if (!$utils.isDefined(instruction)) {
              await $shell.Invoices.openErrorDialog("Failure", "Failed to create a new instruction!");
          }
  
          if (!$utils.isDefined(instruction[0].instructionId)) {
              await $shell.Invoices.openErrorDialog("Failure", "Failed to get the new instruction id!");
          }
  
          await $shell.Invoices.openinvoicing_instructions_editorDialog({
              instructionId: instruction[0].instructionId,
              projectId: projectId,
              billingContractId: billingContractId
          }, "modal")
      }
      $grid.refresh();
  }
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Invoices_invoicing_instructions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  const projectId = $grid.inParams.projectId ?? -1;
  const billingContractId = $grid.inParams.billingContractId ?? -1;
  
  $grid.topToolbar.new_instruction.hidden = true;
  $grid.topToolbar.delete_instructions.control.readOnly = true;
  
  if (projectId >= 0 || billingContractId >= 0) {
      $grid.topToolbar.new_instruction.hidden = false;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: Invoices_invoicing_instructions_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 04/17/2024
  
  const selectedRowsCount = $grid.selectedRows.length;
  
  $grid.topToolbar.delete_instructions.control.readOnly = (selectedRowsCount > 0 ? false : true);
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
