<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-add_equipment" *ngIf="!topToolbar.add_equipment.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.add_equipment.control.readOnly"
            [ngStyle]="topToolbar.add_equipment.control.styles.style"
            [ngClass]="topToolbar.add_equipment.control.styles.classes"
            (click)="on_add_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.add_equipment.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.add_equipment.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.add_equipment.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-refresh" *ngIf="!topToolbar.refresh.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.refresh.control.readOnly"
            [ngStyle]="topToolbar.refresh.control.styles.style"
            [ngClass]="topToolbar.refresh.control.styles.classes"
            (click)="on_refresh_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.refresh.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.refresh.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.refresh.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LookupCode.displayControl.styles.classes">{{row.cells.LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="typeId">

    <ng-template gridCellDisplayControlDef let-row>
    <Equipment-equipment_type_dd_single 
        data-cy="selector"
        [type]="row.cells.typeId.displayControl.type"
        [formControl]="row.formGroup.controls['typeId_display']"
        (displayTextChange)="row.cells.typeId.displayControl.displayText=$event"
        [placeholder]="row.cells.typeId.displayControl.placeholder"
        [styles]="row.cells.typeId.displayControl.styles"
    >
    </Equipment-equipment_type_dd_single>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="MacAddress">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.MacAddress.displayControl.styles.style"
          [ngClass]="row.cells.MacAddress.displayControl.styles.classes">{{row.cells.MacAddress.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouses">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouses.displayControl.styles.style"
          [ngClass]="row.cells.warehouses.displayControl.styles.classes">{{row.cells.warehouses.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
