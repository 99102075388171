import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { Waves_wave_library_homeComponent } from './Waves.wave_library_home.component';
import { Waves_pick_slip_by_wave_id_consolidated_reportComponent } from './Waves.pick_slip_by_wave_id_consolidated_report.component';
import { Waves_pick_slip_by_wave_id_reportComponent } from './Waves.pick_slip_by_wave_id_report.component';
import { Waves_wave_date_types_singleComponent } from './Waves.wave_date_types_single.component';
import { Waves_wave_priorities_dd_singleComponent } from './Waves.wave_priorities_dd_single.component';
import { Waves_wave_statuses_dd_singleComponent } from './Waves.wave_statuses_dd_single.component';
import { Waves_waves_warehouse_dd_singleComponent } from './Waves.waves_warehouse_dd_single.component';
import { Waves_wave_date_types_multiComponent } from './Waves.wave_date_types_multi.component';
import { Waves_wave_priorities_dd_multiComponent } from './Waves.wave_priorities_dd_multi.component';
import { Waves_wave_statuses_dd_multiComponent } from './Waves.wave_statuses_dd_multi.component';
import { Waves_waves_warehouse_dd_multiComponent } from './Waves.waves_warehouse_dd_multi.component';
import { Waves_order_count_widgetComponent } from './Waves.order_count_widget.component';
import { Waves_pick_tasks_widgetComponent } from './Waves.pick_tasks_widget.component';
import { Waves_pick_waves_widgetComponent } from './Waves.pick_waves_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class Waves_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public Waves: Waves_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openwave_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'wave_library_home',
        referenceName: 'Waves_wave_library_home',
        component: Waves_wave_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwave_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      Waves_wave_library_homeComponent,
      'wave_library_home',
      mode,
      dialogSize
    )
  }
  public openpick_slip_by_wave_id_consolidated_report(inParams:{ waveId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'Waves_pick_slip_by_wave_id_consolidated_report',
        component: Waves_pick_slip_by_wave_id_consolidated_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_slip_by_wave_id_consolidated_reportDialog(
    inParams:{ waveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Waves_pick_slip_by_wave_id_consolidated_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }
  public openpick_slip_by_wave_id_report(inParams:{ waveId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Pick Slip',
        referenceName: 'Waves_pick_slip_by_wave_id_report',
        component: Waves_pick_slip_by_wave_id_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openpick_slip_by_wave_id_reportDialog(
    inParams:{ waveId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      Waves_pick_slip_by_wave_id_reportComponent,
      'Pick Slip',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'Waves_wave_library_home') {
      const title = 'wave_library_home';
      const component = Waves_wave_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_pick_slip_by_wave_id_consolidated_report') {
      const title = 'Pick Slip';
      const component = Waves_pick_slip_by_wave_id_consolidated_reportComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_pick_slip_by_wave_id_report') {
      const title = 'Pick Slip';
      const component = Waves_pick_slip_by_wave_id_reportComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_wave_date_types_single') {
      const title = 'Date types for pick waves grid';
      const component = Waves_wave_date_types_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_wave_priorities_dd_single') {
      const title = 'Wave priorities';
      const component = Waves_wave_priorities_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_wave_statuses_dd_single') {
      const title = 'wave_statuses_dd';
      const component = Waves_wave_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_waves_warehouse_dd_single') {
      const title = 'waves_warehouse_dd';
      const component = Waves_waves_warehouse_dd_singleComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_wave_date_types_multi') {
      const title = 'Date types for pick waves grid';
      const component = Waves_wave_date_types_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_wave_priorities_dd_multi') {
      const title = 'Wave priorities';
      const component = Waves_wave_priorities_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_wave_statuses_dd_multi') {
      const title = 'wave_statuses_dd';
      const component = Waves_wave_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_waves_warehouse_dd_multi') {
      const title = 'waves_warehouse_dd';
      const component = Waves_waves_warehouse_dd_multiComponent;
      const inParams:{ warehouseId?: number } = { warehouseId: null };
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_order_count_widget') {
      const title = 'Orders';
      const component = Waves_order_count_widgetComponent;
      const inParams:{ waveId?: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_pick_tasks_widget') {
      const title = 'Total pick tasks';
      const component = Waves_pick_tasks_widgetComponent;
      const inParams:{ waveId: number } = { waveId: null };
      if (!isNil(params.get('waveId'))) {
        const paramValueString = params.get('waveId');
        inParams.waveId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'Waves_pick_waves_widget') {
      const title = 'Waves by status';
      const component = Waves_pick_waves_widgetComponent;
      const inParams:{ projectId?: number, ownerId?: number, warehouseId?: number, statusIds?: number[] } = { projectId: null, ownerId: null, warehouseId: null, statusIds: [] };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
