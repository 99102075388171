import { Component, OnInit, ElementRef, ViewChild, forwardRef, Input, SimpleChanges, OnChanges, HostListener, HostBinding, Output, EventEmitter, Inject } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isNil, set } from 'lodash-es';
import { Styles } from './models/style';
import { SelectorComponent } from './components/selector.component';
import { ESelectorType } from './components/selector.component';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Utilities_ShellService, EModalSize, EToasterType, EToasterPosition } from './Utilities.shell.service';
import { Utilities_OperationService } from './Utilities.operation.service';
import { Utilities_DatasourceService } from './Utilities.datasource.index';
import { Utilities_FlowService } from './Utilities.flow.index';
import { Utilities_ReportService } from './Utilities.report.index';
import { Utilities_LocalizationService } from './Utilities.localization.service';
import { Language } from './localization.service';
import { $types } from './Utilities.types'

@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Utilities-processes_dd_multi',
  templateUrl: './Utilities.processes_dd_multi.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Utilities_processes_dd_multiComponent),
      multi: true
    }
  ]
})
export class Utilities_processes_dd_multiComponent implements OnInit, ControlValueAccessor, OnChanges {

  @Input() type?: ESelectorType;
  _disabled = false;
  @Input() get disabled() { 
      return this._disabled 
      ; }
  set disabled(val: any) { this._disabled = val; }

  @Input() placeholder = '';
  @Input() styles: Styles;

  @Output() displayTextChange: EventEmitter<string> = new EventEmitter();

  @ViewChild(SelectorComponent) selector: SelectorComponent;

  @HostBinding('tabIndex') get tabIndex() { return -1; }
  @HostListener('focus')
  focus() {
    this.selector?.focus();
  }

  get isMulti() {
    return true;
  }

  get isSingle() {
    return false;
  }


  inParams: { application_name?: string } = { application_name: null };
  @Input('application_name') set $inParams_application_name(value: any) {
    this.inParams['application_name'] = value;
  }
  get $inParams_application_name(): any {
    return this.inParams['application_name'] ;
  }

  fullTextSearch: string;

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Utilities_ShellService,
    private datasources: Utilities_DatasourceService,
    private flows: Utilities_FlowService,
    private reports: Utilities_ReportService,
    private localization: Utilities_LocalizationService,
    private operations: Utilities_OperationService,
    private _elementRef: ElementRef
    ) {
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  $init() {
    this.selector?.init();
  }

  onKeyDown($event: KeyboardEvent) {
    //TODO:KeyDown    
  }

  //ControlValueAccessor
  _innerValue: any;

  onChange: any = () => { };
  onTouch: any = () => { };

  get value(): string | string[] {
    return this._innerValue;
  }

  set value(val:  string | string[]) {
    if (this._innerValue !== val) {
      this._innerValue = val;
      this.onChange(val);
      this.onTouch(val);
    }
  }

  writeValue(value: any): void {
    this._innerValue = value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  displayWithFn(value: any): Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}> {
    const promise = new Promise<{displayText:string, selected :Array<{ key: any; name: string; disabled?: boolean; selected?: boolean;}>}>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = null;
      const $fullTextSearch = null;
      this.datasources.Utilities.ds_processes_dd.getByKeys({
        $keys: value,
        application_name:  $selector.inParams.application_name ,
        full_text_search:  $selector.fullTextSearch ,
      }).then(result => {
        const selected = value.map(k => {
          const found = result.result
            .find(i => i.id === k);
          const e: { id?: string, process_name?: string } = found 
            ?? { 
              id : k
            };

          const $option = { entity: e };
          const $entity = e;
          const $resultOption = { key: null, name: found ? $option.entity.process_name : 'No data to display', disabled: false, selected:true };
          $resultOption.key = $option.entity.id;
          return $resultOption;
        });
        const displayText = value.map(k => {
          const found = result.result
            .find(i => i.id === k);
          if (found) {
            const $option = { entity: found };
            const $entity = found;
            return $option.entity.process_name;
          } else {
            return 'No data to display';
          }
        })
        .join(
           ', '
        );
        this.displayTextChange.emit(displayText);
        resolve({displayText, selected});
      });
    });
    return promise;
  }

  optionsFn(filterText: string): Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }> {

    const promise = new Promise<{ list: Array<{ key: any; name: string; disabled?: boolean; }>, totalCount?: number, top?: number }>((resolve, reject) => {

      const $selector = this;
      const $utils = this.utils;

      this.fullTextSearch = filterText;
      const $fullTextSearch = filterText;
      this.datasources.Utilities.ds_processes_dd.getList({
        $top: 25,
        $skip: 0,
        application_name:  $selector.inParams.application_name ,
        full_text_search:  $selector.fullTextSearch ,
      }).then(result => {
        resolve({
          list: result.result.map(item => {
            const $option = { entity: item };
            const $entity = item;
            const $resultOption = { key: null, name: $option.entity.process_name, disabled: null };
            $resultOption.key = $option.entity.id;
            return $resultOption;
          }),
          totalCount: result.totalCount,
          top: 25
        });
      });

    });

    return promise;

  }

  //#region private flows
  on_refresh(event = null) {
    return this.on_refreshInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_refreshInternal(
    $selector: Utilities_processes_dd_multiComponent,
  
    $shell: Utilities_ShellService,
    $datasources: Utilities_DatasourceService,
    $flows: Utilities_FlowService,
    $reports: Utilities_ReportService,
    $settings: SettingsValuesService,
    $operations: Utilities_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Utilities_LocalizationService,
    $event: any
  ) {
  //O.Arias - 06/19/2024
  
  await $flows.Utilities.list_processes({action: "Refresh"});
  }
  //#endregion private flows
}
