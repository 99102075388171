import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './InventoryTransfers.types'

@Injectable({ providedIn: 'root' })
export class InventoryTransfers_create_inventory_transfer_lines_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { inventoryTransferId: number, lines: { materialId?: number, vendorLotId?: number, lotId?: number, packagingId?: number, packagedAmount?: number, licenseplateId?: number, serialnumberId?: number, locationId?: number, warehouseId?: number, allowInactiveInventory?: boolean }[] }): Promise<{ reasons?: string[], inventoryTransferLineIds?: number[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.inventoryTransferId)) {
      missingRequiredInParams.push('\'inventoryTransferId\'');
    }
    if (isNil(inParams.lines)) {
      missingRequiredInParams.push('\'lines\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/InventoryTransfers/functions/create_inventory_transfer_lines_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

