<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Reason</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-error_message" *ngIf="!fields.error_message.hidden" 
                            class="field-container full {{fields.error_message.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.error_message.styles.style"
                            [ngClass]="fields.error_message.styles.classes">
                        <div class="label-container"
                              title="{{fields.error_message.label}}{{fields.error_message.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.error_message.label}}">{{fields.error_message.label}}<span *ngIf="fields.error_message.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.error_message.control.styles.style"
                              [ngClass]="fields.error_message.control.styles.classes">{{fields.error_message.control.text }}</div>
                        <ng-container *ngIf="fields.error_message.invalid">
                          <ng-container *ngFor="let error of fields.error_message.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-message" *ngIf="!fields.message.hidden" 
                            class="field-container full {{fields.message.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.message.styles.style"
                            [ngClass]="fields.message.styles.classes">
                        <div class="label-container"
                              title="{{fields.message.label}}{{fields.message.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.message.label}}">{{fields.message.label}}<span *ngIf="fields.message.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="message"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.message.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.message.control.placeholder}}"
                                [ngStyle]="fields.message.control.styles.style"
                                [ngClass]="fields.message.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.message.invalid">
                          <ng-container *ngFor="let error of fields.message.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-reason_code" *ngIf="!fields.reason_code.hidden" 
                            class="field-container standard {{fields.reason_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.reason_code.styles.style"
                            [ngClass]="fields.reason_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.reason_code.label}}{{fields.reason_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.reason_code.label}}">{{fields.reason_code.label}}<span *ngIf="fields.reason_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PackVerification-reasoncodes_dd_single 
                            data-cy="selector"
                            [type]="fields.reason_code.control.type"
                            formControlName="reason_code"
                            (displayTextChange)="fields.reason_code.control.displayText=$event"
                            [placeholder]="fields.reason_code.control.placeholder"
                            [styles]="fields.reason_code.control.styles"
                          [parentId]="$fields_reason_code_selector_inParams_parentId"
                        >
                        </PackVerification-reasoncodes_dd_single>
                        <ng-container *ngIf="fields.reason_code.invalid">
                          <ng-container *ngFor="let error of fields.reason_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>