<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-status_change" *ngIf="!topToolbar.status_change.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.status_change.control.readOnly"
            [ngStyle]="topToolbar.status_change.control.styles.style"
            [ngClass]="topToolbar.status_change.control.styles.classes"
            (click)="on_change_status_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.status_change.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.status_change.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.status_change.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-complete" *ngIf="!topToolbar.complete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.complete.control.readOnly"
            [ngStyle]="topToolbar.complete.control.styles.style"
            [ngClass]="topToolbar.complete.control.styles.classes"
            (click)="on_complete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.complete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.complete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.complete.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator3" *ngIf="!topToolbar.separator3.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-cancel" *ngIf="!topToolbar.cancel.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.cancel.control.readOnly"
            [ngStyle]="topToolbar.cancel.control.styles.style"
            [ngClass]="topToolbar.cancel.control.styles.classes"
            (click)="on_cancel_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.cancel.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.cancel.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-on_delete" *ngIf="!topToolbar.on_delete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_delete.control.readOnly"
            [ngStyle]="topToolbar.on_delete.control.styles.style"
            [ngClass]="topToolbar.on_delete.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_delete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_delete.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-on_export" *ngIf="!topToolbar.on_export.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_export.control.readOnly"
            [ngStyle]="topToolbar.on_export.control.styles.style"
            [ngClass]="topToolbar.on_export.control.styles.classes"
            (click)="on_export_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_export.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_export.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_export.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-attachments" *ngIf="!topToolbar.attachments.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.attachments.control.readOnly"
            [ngStyle]="topToolbar.attachments.control.styles.style"
            [ngClass]="topToolbar.attachments.control.styles.classes"
            (click)="on_attachments_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.attachments.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.attachments.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.attachments.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-print" *ngIf="!topToolbar.print.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.print.control.readOnly"
            [ngStyle]="topToolbar.print.control.styles.style"
            [ngClass]="topToolbar.print.control.styles.classes"
            (click)="on_print_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.print.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.print.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.print.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-created_from" *ngIf="!filters.created_from.hidden" 
            class="field-container standard {{filters.created_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.created_from.styles.style"
            [ngClass]="filters.created_from.styles.classes">
        <div class="label-container"
              title="{{filters.created_from.label}}{{filters.created_from.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.created_from.label}}">{{filters.created_from.label}}<span *ngIf="filters.created_from.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="created_from"
            [format]="filters.created_from.control.format"
            [mode]="filters.created_from.control.mode"
            [ngStyle]="filters.created_from.control.styles.style"
            [ngClass]="filters.created_from.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.created_from.invalid">
          <ng-container *ngFor="let error of filters.created_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-created_to" *ngIf="!filters.created_to.hidden" 
            class="field-container standard {{filters.created_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.created_to.styles.style"
            [ngClass]="filters.created_to.styles.classes">
        <div class="label-container"
              title="{{filters.created_to.label}}{{filters.created_to.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.created_to.label}}">{{filters.created_to.label}}<span *ngIf="filters.created_to.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="created_to"
            [format]="filters.created_to.control.format"
            [mode]="filters.created_to.control.mode"
            [ngStyle]="filters.created_to.control.styles.style"
            [ngClass]="filters.created_to.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.created_to.invalid">
          <ng-container *ngFor="let error of filters.created_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.lookupcode.displayControl.styles.classes">{{row.cells.lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status.displayControl.styles.style"
          [ngClass]="row.cells.status.displayControl.styles.classes">{{row.cells.status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total.displayControl.styles.style"
          [ngClass]="row.cells.total.displayControl.styles.classes">{{row.cells.total.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="due_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.due_date.displayControl.styles.style"
          [ngClass]="row.cells.due_date.displayControl.styles.classes">{{row.cells.due_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="shipment">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.shipment.displayControl.styles.style"
          [ngClass]="row.cells.shipment.displayControl.styles.classes">{{row.cells.shipment.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="reference_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.reference_code.displayControl.styles.style"
          [ngClass]="row.cells.reference_code.displayControl.styles.classes">{{row.cells.reference_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="attention_of">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.attention_of.displayControl.styles.style"
          [ngClass]="row.cells.attention_of.displayControl.styles.classes">{{row.cells.attention_of.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
