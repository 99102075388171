import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Manifesting.types'

@Injectable({ providedIn: 'root' })
export class Manifesting_get_ship_to_address_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { orderId?: number, shipmentId?: number }): Promise<{ address?: { name?: string, line1?: string, line2?: string, city?: string, state?: string, postalCode?: string, country?: string, email?: string, phone?: string, eori?: string, vat?: string } }> 
  {
    let url = `${environment.backendUrl}api/Manifesting/functions/get_ship_to_address_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

