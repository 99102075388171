<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-validate_credentials" *ngIf="!toolbar.validate_credentials.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.validate_credentials.control.readOnly"
              [ngStyle]="toolbar.validate_credentials.control.styles.style"
              [ngClass]="toolbar.validate_credentials.control.styles.classes"
              (click)="on_validate_credentials_button_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.validate_credentials.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.validate_credentials.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.validate_credentials.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-letter_only" *ngIf="!fields.letter_only.hidden" 
                            class="field-container standard {{fields.letter_only.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.letter_only.styles.style"
                            [ngClass]="fields.letter_only.styles.classes">
                        <div class="label-container"
                              title="{{fields.letter_only.label}}{{fields.letter_only.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.letter_only.label}}">{{fields.letter_only.label}}<span *ngIf="fields.letter_only.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="letter_only"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.letter_only.control.styles.style"
                                      [ngClass]="fields.letter_only.control.styles.classes">{{fields.letter_only.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.letter_only.invalid">
                          <ng-container *ngFor="let error of fields.letter_only.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-printers" *ngIf="!fields.printers.hidden" 
                            class="field-container double {{fields.printers.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.printers.styles.style"
                            [ngClass]="fields.printers.styles.classes">
                        <div class="label-container"
                              title="{{fields.printers.label}}{{fields.printers.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.printers.label}}">{{fields.printers.label}}<span *ngIf="fields.printers.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PrintNode-printers_dd_single 
                            data-cy="selector"
                            [type]="fields.printers.control.type"
                            formControlName="printers"
                            (displayTextChange)="fields.printers.control.displayText=$event"
                            [placeholder]="fields.printers.control.placeholder"
                            [styles]="fields.printers.control.styles"
                          [letterOnly]="$fields_printers_selector_inParams_letterOnly"
                        >
                        </PrintNode-printers_dd_single>
                        <ng-container *ngIf="fields.printers.invalid">
                          <ng-container *ngFor="let error of fields.printers.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-label" *ngIf="!fields.label.hidden" 
                            class="field-container standard {{fields.label.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.label.styles.style"
                            [ngClass]="fields.label.styles.classes">
                        <div class="label-container"
                              title="{{fields.label.label}}{{fields.label.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.label.label}}">{{fields.label.label}}<span *ngIf="fields.label.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="label"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.label.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.label.control.placeholder}}"
                                [ngStyle]="fields.label.control.styles.style"
                                [ngClass]="fields.label.control.styles.classes"> 
                        <ng-container *ngIf="fields.label.invalid">
                          <ng-container *ngFor="let error of fields.label.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-pdf_base64" *ngIf="!fields.pdf_base64.hidden" 
                            class="field-container standard {{fields.pdf_base64.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.pdf_base64.styles.style"
                            [ngClass]="fields.pdf_base64.styles.classes">
                        <div class="label-container"
                              title="{{fields.pdf_base64.label}}{{fields.pdf_base64.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.pdf_base64.label}}">{{fields.pdf_base64.label}}<span *ngIf="fields.pdf_base64.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="pdf_base64"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.pdf_base64.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.pdf_base64.control.placeholder}}"
                                [ngStyle]="fields.pdf_base64.control.styles.style"
                                [ngClass]="fields.pdf_base64.control.styles.classes"> 
                        <ng-container *ngIf="fields.pdf_base64.invalid">
                          <ng-container *ngFor="let error of fields.pdf_base64.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-print" *ngIf="!fields.print.hidden" 
                            class="field-container standard {{fields.print.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.print.styles.style"
                            [ngClass]="fields.print.styles.classes">
                        <div class="label-container"
                              title="{{fields.print.label}}{{fields.print.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.print.label}}">{{fields.print.label}}<span *ngIf="fields.print.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.print.control.readOnly"
                                [ngStyle]="fields.print.control.styles.style"
                                [ngClass]="fields.print.control.styles.classes"
                                (click)="on_print_button_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.print.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.print.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.print.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.print.invalid">
                          <ng-container *ngFor="let error of fields.print.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-print_job_id" *ngIf="!fields.print_job_id.hidden" 
                            class="field-container standard {{fields.print_job_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.print_job_id.styles.style"
                            [ngClass]="fields.print_job_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.print_job_id.label}}{{fields.print_job_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.print_job_id.label}}">{{fields.print_job_id.label}}<span *ngIf="fields.print_job_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="print_job_id"
                                matInput
                                numberBox
                                [format]="fields.print_job_id.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.print_job_id.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.print_job_id.control.placeholder}}"
                                [ngStyle]="fields.print_job_id.control.styles.style"
                                [ngClass]="fields.print_job_id.control.styles.classes">
                        <ng-container *ngIf="fields.print_job_id.invalid">
                          <ng-container *ngFor="let error of fields.print_job_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-printjobs" *ngIf="!fields.printjobs.hidden" 
                            class="field-container standard {{fields.printjobs.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.printjobs.styles.style"
                            [ngClass]="fields.printjobs.styles.classes">
                        <div class="label-container"
                              title="{{fields.printjobs.label}}{{fields.printjobs.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.printjobs.label}}">{{fields.printjobs.label}}<span *ngIf="fields.printjobs.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.printjobs.control.readOnly"
                                [ngStyle]="fields.printjobs.control.styles.style"
                                [ngClass]="fields.printjobs.control.styles.classes"
                                (click)="on_printjob_button_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.printjobs.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.printjobs.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.printjobs.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.printjobs.invalid">
                          <ng-container *ngFor="let error of fields.printjobs.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>