import { Inject, Injectable, Injector } from '@angular/core';


import { BarcodeParsing_get_serial_number_code_by_parsedValuesService } from './BarcodeParsing.flow.index';
import { BarcodeParsing_parse_barcode_flowService } from './BarcodeParsing.flow.index';
import { BarcodeParsing_parse_gs1_barcode_flowService } from './BarcodeParsing.flow.index';
import { BarcodeParsing_validate_barcode_flowService } from './BarcodeParsing.flow.index';

import { $types } from './BarcodeParsing.types'

@Injectable({ providedIn: 'root' })
export class BarcodeParsing_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public BarcodeParsing: BarcodeParsing_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _get_serial_number_code_by_parsedValues: BarcodeParsing_get_serial_number_code_by_parsedValuesService;
  public async get_serial_number_code_by_parsedValues(inParams: { parsedValues: { gtin?: string, serialNumber?: string, expirationDate?: string, lotNumber?: string, sscc?: string, varCount?: string } }): Promise< { serialNumberCode?: string, reasons?: string[] }> {
    if(!this._get_serial_number_code_by_parsedValues) {
      this._get_serial_number_code_by_parsedValues = this.injector.get(BarcodeParsing_get_serial_number_code_by_parsedValuesService);
    }
    return this._get_serial_number_code_by_parsedValues.run(inParams);
  }
  private _parse_barcode_flow: BarcodeParsing_parse_barcode_flowService;
  public async parse_barcode_flow(inParams: { inputString: string }): Promise< { material?: { lookupCode?: string }, vendorlot?: { lookupCode?: string, manufactureDate?: string, expirationDate?: string }, lot?: { lookupCode?: string }, serialNumber?: { lookupCode?: string }, quantity?: number, packaging?: { name?: string, UPC?: string }, weight?: { net?: number, gross?: number, unitOfMeasure?: string } }> {
    if(!this._parse_barcode_flow) {
      this._parse_barcode_flow = this.injector.get(BarcodeParsing_parse_barcode_flowService);
    }
    return this._parse_barcode_flow.run(inParams);
  }
  private _parse_gs1_barcode_flow: BarcodeParsing_parse_gs1_barcode_flowService;
  public async parse_gs1_barcode_flow(inParams: { inputScan: string }): Promise< { reasons?: string[], extractedValues?: { gtin?: string, serialNumber?: string, expirationDate?: string, lotNumber?: string, sscc?: string, varCount?: string } }> {
    if(!this._parse_gs1_barcode_flow) {
      this._parse_gs1_barcode_flow = this.injector.get(BarcodeParsing_parse_gs1_barcode_flowService);
    }
    return this._parse_gs1_barcode_flow.run(inParams);
  }
  private _validate_barcode_flow: BarcodeParsing_validate_barcode_flowService;
  public async validate_barcode_flow(inParams: { barcode: string, parsedValues: any, context: string }): Promise< { reasons?: string[] }> {
    if(!this._validate_barcode_flow) {
      this._validate_barcode_flow = this.injector.get(BarcodeParsing_validate_barcode_flowService);
    }
    return this._validate_barcode_flow.run(inParams);
  }
}
