import { Injectable } from '@angular/core';
import { ParamMap } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { isNil, cloneDeep } from 'lodash-es';

import { ShellService, EModalSize, EToasterType, EToasterPosition } from './shell.service'
export { EModalSize, EToasterType, EToasterPosition } from './shell.service';
import { ToastrService } from 'ngx-toastr';
import { WorkOrders_work_orders_library_homeComponent } from './WorkOrders.work_orders_library_home.component';
import { WorkOrders_work_order_reportComponent } from './WorkOrders.work_order_report.component';
import { WorkOrders_accessorial_operationcodes_by_projectId_dd_singleComponent } from './WorkOrders.accessorial_operationcodes_by_projectId_dd_single.component';
import { WorkOrders_measurement_units_dd_singleComponent } from './WorkOrders.measurement_units_dd_single.component';
import { WorkOrders_work_order_statuses_dd_singleComponent } from './WorkOrders.work_order_statuses_dd_single.component';
import { WorkOrders_accessorial_operationcodes_by_projectId_dd_multiComponent } from './WorkOrders.accessorial_operationcodes_by_projectId_dd_multi.component';
import { WorkOrders_measurement_units_dd_multiComponent } from './WorkOrders.measurement_units_dd_multi.component';
import { WorkOrders_work_order_statuses_dd_multiComponent } from './WorkOrders.work_order_statuses_dd_multi.component';
import { WorkOrders_work_order_completed_tasks_count_wdigetComponent } from './WorkOrders.work_order_completed_tasks_count_wdiget.component';
import { WorkOrders_work_order_open_tasks_count_widgetComponent } from './WorkOrders.work_order_open_tasks_count_widget.component';
import { WorkOrders_work_order_total_completed_units_by_date_widgetComponent } from './WorkOrders.work_order_total_completed_units_by_date_widget.component';
import { WorkOrders_work_order_total_open_units_by_date_widgetComponent } from './WorkOrders.work_order_total_open_units_by_date_widget.component';

import { Utilities_ShellService } from './Utilities.shell.service';

@Injectable({ providedIn: 'root' })
export class WorkOrders_ShellService extends ShellService  {
  constructor(
    dialog : MatDialog,
    toastr: ToastrService,
    public Utilities: Utilities_ShellService,
  ) {
    super(dialog, toastr);
  }

  public WorkOrders: WorkOrders_ShellService = this;

  // wizards shouldn't be opened in blades (hacky check with "#unless steps" to recognize the config type)
  public openwork_orders_library_home(replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Home',
        referenceName: 'WorkOrders_work_orders_library_home',
        component: WorkOrders_work_orders_library_homeComponent,
        replaceCurrentView: replaceCurrentView,
        
      }
    );
  }

  public openwork_orders_library_homeDialog(
    
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Standard;
    }

    return this.openDialog(
      WorkOrders_work_orders_library_homeComponent,
      'Home',
      mode,
      dialogSize
    )
  }
  public openwork_order_report(inParams:{ workOrderId: number }, replaceCurrentView?: boolean) {
    ShellService.openViewRequest$.next(
      {
        title: 'Work Order Report',
        referenceName: 'WorkOrders_work_order_report',
        component: WorkOrders_work_order_reportComponent,
        replaceCurrentView: replaceCurrentView,
        inParams: inParams
      }
    );
  }

  public openwork_order_reportDialog(
    inParams:{ workOrderId: number }, 
    mode: 'modal' | 'flyout' = 'modal',
    size?: EModalSize
  ): Promise<void> {
    let dialogSize;
    if (size) {
      dialogSize = size; 
    } else {
      dialogSize = EModalSize.Xlarge;
    }

    return this.openDialog(
      WorkOrders_work_order_reportComponent,
      'Work Order Report',
      mode,
      dialogSize
      , cloneDeep(inParams)
    )
  }

  public getComponentInformation(referenceName: string, params: ParamMap): { title: string, component: any, inParams: any } {
    if (referenceName === 'WorkOrders_work_orders_library_home') {
      const title = 'Home';
      const component = WorkOrders_work_orders_library_homeComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_report') {
      const title = 'Work Order Report';
      const component = WorkOrders_work_order_reportComponent;
      const inParams:{ workOrderId: number } = { workOrderId: null };
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_accessorial_operationcodes_by_projectId_dd_single') {
      const title = 'List of accessorial operation codes';
      const component = WorkOrders_accessorial_operationcodes_by_projectId_dd_singleComponent;
      const inParams:{ projectId: number, showAllOpCodes?: boolean } = { projectId: null, showAllOpCodes: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('showAllOpCodes'))) {
        const paramValueString = params.get('showAllOpCodes');
        inParams.showAllOpCodes = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_measurement_units_dd_single') {
      const title = 'Measurement Units dropdown';
      const component = WorkOrders_measurement_units_dd_singleComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_statuses_dd_single') {
      const title = 'List of work order statuses';
      const component = WorkOrders_work_order_statuses_dd_singleComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_accessorial_operationcodes_by_projectId_dd_multi') {
      const title = 'List of accessorial operation codes';
      const component = WorkOrders_accessorial_operationcodes_by_projectId_dd_multiComponent;
      const inParams:{ projectId: number, showAllOpCodes?: boolean } = { projectId: null, showAllOpCodes: null };
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('showAllOpCodes'))) {
        const paramValueString = params.get('showAllOpCodes');
        inParams.showAllOpCodes = this.convertToBoolean(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_measurement_units_dd_multi') {
      const title = 'Measurement Units dropdown';
      const component = WorkOrders_measurement_units_dd_multiComponent;
      const inParams:{ typeId?: number } = { typeId: null };
      if (!isNil(params.get('typeId'))) {
        const paramValueString = params.get('typeId');
        inParams.typeId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_statuses_dd_multi') {
      const title = 'List of work order statuses';
      const component = WorkOrders_work_order_statuses_dd_multiComponent;
      const inParams = null;

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_completed_tasks_count_wdiget') {
      const title = 'Completed Count';
      const component = WorkOrders_work_order_completed_tasks_count_wdigetComponent;
      const inParams:{ workOrderId: number } = { workOrderId: null };
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_open_tasks_count_widget') {
      const title = 'Open Count';
      const component = WorkOrders_work_order_open_tasks_count_widgetComponent;
      const inParams:{ workOrderId: number } = { workOrderId: null };
      if (!isNil(params.get('workOrderId'))) {
        const paramValueString = params.get('workOrderId');
        inParams.workOrderId = this.convertToNumber(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_total_completed_units_by_date_widget') {
      const title = 'Comp. Units Today';
      const component = WorkOrders_work_order_total_completed_units_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number, statusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: null, statusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }
    if (referenceName === 'WorkOrders_work_order_total_open_units_by_date_widget') {
      const title = 'Open Units Today';
      const component = WorkOrders_work_order_total_open_units_by_date_widgetComponent;
      const inParams:{ date: string, ownerId?: number, projectId?: number, warehouseId?: number, statusIds?: number[] } = { date: null, ownerId: null, projectId: null, warehouseId: null, statusIds: [] };
      if (!isNil(params.get('date'))) {
        const paramValueString = params.get('date');
        // TODO: date
        inParams.date = paramValueString;
              }
      if (!isNil(params.get('ownerId'))) {
        const paramValueString = params.get('ownerId');
        inParams.ownerId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('projectId'))) {
        const paramValueString = params.get('projectId');
        inParams.projectId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('warehouseId'))) {
        const paramValueString = params.get('warehouseId');
        inParams.warehouseId = this.convertToNumber(paramValueString);
      }
      if (!isNil(params.get('statusIds'))) {
        const paramValueString = params.get('statusIds');
        inParams.statusIds = this.convertToJSON(paramValueString);
      }

      return { title: title, component: component, inParams: inParams };
    }

    let result = null;
    result = this.Utilities.getComponentInformation(referenceName, params);
    if (result) {
      return result;
    }
    return result;
  }
}
