<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



[rowCommandTemplateRef]="rowCommand">
    <ng-template #rowCommand let-row>
      <div mat-menu-item class="grid-row-options">
        <button mat-menu-item
                class="datex-button"
                [disabled]="row.options.Delete.readOnly"
                [ngStyle]="row.options.Delete.styles.style"
                [ngClass]="row.options.Delete.styles.classes"
                (click)="row.on_delete()">
          <span *ngIf="row.options.Delete.label">{{row.options.Delete.label}}</span>
        </button>
      </div>
    </ng-template>
    <ng-container topToolbar>
    <div data-cy="tool-id-new_reason_code_button" *ngIf="!topToolbar.new_reason_code_button.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.new_reason_code_button.control.readOnly"
            [ngStyle]="topToolbar.new_reason_code_button.control.styles.style"
            [ngClass]="topToolbar.new_reason_code_button.control.styles.classes"
            (click)="on_new_reason_code_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.new_reason_code_button.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.new_reason_code_button.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.new_reason_code_button.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>


  <ng-container gridColumnDef="LookupCode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.LookupCode.displayControl.styles.style"
          [ngClass]="row.cells.LookupCode.displayControl.styles.classes">{{row.cells.LookupCode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Label">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Label.displayControl.styles.style"
          [ngClass]="row.cells.Label.displayControl.styles.classes">{{row.cells.Label.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="ParentId">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.ParentId.displayControl.styles.style"
          [ngClass]="row.cells.ParentId.displayControl.styles.classes">{{row.cells.ParentId.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="NumberOfMinutesBeforeExpiration">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.NumberOfMinutesBeforeExpiration.displayControl.styles.style"
          [ngClass]="row.cells.NumberOfMinutesBeforeExpiration.displayControl.styles.classes">{{row.cells.NumberOfMinutesBeforeExpiration.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
