<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-invoice" *ngIf="!topToolbar.invoice.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.invoice.control.readOnly"
            [ngStyle]="topToolbar.invoice.control.styles.style"
            [ngClass]="topToolbar.invoice.control.styles.classes"
            (click)="on_invoice_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.invoice.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.invoice.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.invoice.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-separator1" *ngIf="!topToolbar.separator1.hidden" class="toolContainer">
    <div class="tool-separator"></div>
    </div>
    <div data-cy="tool-id-cancel" *ngIf="!topToolbar.cancel.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.cancel.control.readOnly"
            [ngStyle]="topToolbar.cancel.control.styles.style"
            [ngClass]="topToolbar.cancel.control.styles.classes"
            (click)="on_cancel_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.cancel.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.cancel.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-on_delete" *ngIf="!topToolbar.on_delete.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.on_delete.control.readOnly"
            [ngStyle]="topToolbar.on_delete.control.styles.style"
            [ngClass]="topToolbar.on_delete.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.on_delete.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.on_delete.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.on_delete.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-created_from" *ngIf="!filters.created_from.hidden" 
            class="field-container standard {{filters.created_from.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.created_from.styles.style"
            [ngClass]="filters.created_from.styles.classes">
        <div class="label-container"
              title="{{filters.created_from.label}}{{filters.created_from.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.created_from.label}}">{{filters.created_from.label}}<span *ngIf="filters.created_from.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="created_from"
            [format]="filters.created_from.control.format"
            [mode]="filters.created_from.control.mode"
            [ngStyle]="filters.created_from.control.styles.style"
            [ngClass]="filters.created_from.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.created_from.invalid">
          <ng-container *ngFor="let error of filters.created_from.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-created_to" *ngIf="!filters.created_to.hidden" 
            class="field-container standard {{filters.created_to.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.created_to.styles.style"
            [ngClass]="filters.created_to.styles.classes">
        <div class="label-container"
              title="{{filters.created_to.label}}{{filters.created_to.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.created_to.label}}">{{filters.created_to.label}}<span *ngIf="filters.created_to.required"
                  class="required-asterisk">*</span></label>
        </div>
        <app-datebox 
            data-cy="dateBox"
            formControlName="created_to"
            [format]="filters.created_to.control.format"
            [mode]="filters.created_to.control.mode"
            [ngStyle]="filters.created_to.control.styles.style"
            [ngClass]="filters.created_to.control.styles.classes">
        </app-datebox>
        <ng-container *ngIf="filters.created_to.invalid">
          <ng-container *ngFor="let error of filters.created_to.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-billing_code" *ngIf="!filters.billing_code.hidden" 
            class="field-container standard {{filters.billing_code.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.billing_code.styles.style"
            [ngClass]="filters.billing_code.styles.classes">
        <div class="label-container"
              title="{{filters.billing_code.label}}{{filters.billing_code.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.billing_code.label}}">{{filters.billing_code.label}}<span *ngIf="filters.billing_code.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Invoices-billingcodes_dd_multi 
            data-cy="selector-multi"
            [type]="filters.billing_code.control.type"
            formControlName="billing_code"
            (displayTextChange)="filters.billing_code.control.displayText=$event"
            [placeholder]="filters.billing_code.control.placeholder"
            [styles]="filters.billing_code.control.styles"
        >
        </Invoices-billingcodes_dd_multi>
        <ng-container *ngIf="filters.billing_code.invalid">
          <ng-container *ngFor="let error of filters.billing_code.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="billing_task_id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.billing_task_id.displayControl.styles.style"
          [ngClass]="row.cells.billing_task_id.displayControl.styles.classes">{{row.cells.billing_task_id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billing_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.billing_code.displayControl.styles.style"
          [ngClass]="row.cells.billing_code.displayControl.styles.classes">{{row.cells.billing_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="billing_strategy_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.billing_strategy_name.displayControl.styles.style"
          [ngClass]="row.cells.billing_strategy_name.displayControl.styles.classes">{{row.cells.billing_strategy_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.actual_amount.displayControl.styles.style"
          [ngClass]="row.cells.actual_amount.displayControl.styles.classes">{{row.cells.actual_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="actual_uom">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.actual_uom.displayControl.styles.style"
          [ngClass]="row.cells.actual_uom.displayControl.styles.classes">{{row.cells.actual_uom.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="applicable_rate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.applicable_rate.displayControl.styles.style"
          [ngClass]="row.cells.applicable_rate.displayControl.styles.classes">{{row.cells.applicable_rate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="applicable_minimum_charge">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.applicable_minimum_charge.displayControl.styles.style"
          [ngClass]="row.cells.applicable_minimum_charge.displayControl.styles.classes">{{row.cells.applicable_minimum_charge.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total.displayControl.styles.style"
          [ngClass]="row.cells.total.displayControl.styles.classes">{{row.cells.total.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="invoiced">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.invoiced.displayControl.styles.style"
          [ngClass]="row.cells.invoiced.displayControl.styles.classes">{{row.cells.invoiced.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="owner">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.owner.displayControl.styles.style"
          [ngClass]="row.cells.owner.displayControl.styles.classes">{{row.cells.owner.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="details">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.details.displayControl.styles.style"
          [ngClass]="row.cells.details.displayControl.styles.classes">{{row.cells.details.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.notes.displayControl.styles.style"
          [ngClass]="row.cells.notes.displayControl.styles.classes">{{row.cells.notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cancelled">

    <ng-template gridCellDisplayControlDef let-row>
    <mat-checkbox data-cy="checkBox" [formControl]="row.formGroup.controls['cancelled_display']"
                  class="datex-checkbox"
                  color="primary"
                  [ngStyle]="row.cells.cancelled.displayControl.styles.style"
                  [ngClass]="row.cells.cancelled.displayControl.styles.classes">{{row.cells.cancelled.displayControl.label}}</mat-checkbox>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="cancelled_reason_code">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.cancelled_reason_code.displayControl.styles.style"
          [ngClass]="row.cells.cancelled_reason_code.displayControl.styles.classes">{{row.cells.cancelled_reason_code.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
