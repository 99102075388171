import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_project_editorService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { projectId: number }): 
  Promise<{ result: { Id?: number, IsDefault?: boolean, LookupCode?: string, Name?: string, Notes?: string, StatusId?: number, VAT?: string } }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.projectId)) {
      missingRequiredInParams.push('\'projectId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_project_editor/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
