<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-release_count" *ngIf="!toolbar.release_count.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.release_count.control.readOnly"
              [ngStyle]="toolbar.release_count.control.styles.style"
              [ngClass]="toolbar.release_count.control.styles.classes"
              (click)="on_release_count_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.release_count.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.release_count.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.release_count.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-complete_count" *ngIf="!toolbar.complete_count.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.complete_count.control.readOnly"
              [ngStyle]="toolbar.complete_count.control.styles.style"
              [ngClass]="toolbar.complete_count.control.styles.classes"
              (click)="on_complete_count_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.complete_count.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.complete_count.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.complete_count.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-duplicate_count" *ngIf="!toolbar.duplicate_count.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.duplicate_count.control.readOnly"
              [ngStyle]="toolbar.duplicate_count.control.styles.style"
              [ngClass]="toolbar.duplicate_count.control.styles.classes"
              (click)="on_duplicate_count_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.duplicate_count.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.duplicate_count.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.duplicate_count.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-hold_count" *ngIf="!toolbar.hold_count.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.hold_count.control.readOnly"
              [ngStyle]="toolbar.hold_count.control.styles.style"
              [ngClass]="toolbar.hold_count.control.styles.classes"
              (click)="on_hold_count_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.hold_count.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.hold_count.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.hold_count.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator2" *ngIf="!toolbar.separator2.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-cancel_count" *ngIf="!toolbar.cancel_count.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel_count.control.readOnly"
              [ngStyle]="toolbar.cancel_count.control.styles.style"
              [ngClass]="toolbar.cancel_count.control.styles.classes"
              (click)="on_cancel_count_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel_count.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel_count.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel_count.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-delete_count" *ngIf="!toolbar.delete_count.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.delete_count.control.readOnly"
              [ngStyle]="toolbar.delete_count.control.styles.style"
              [ngClass]="toolbar.delete_count.control.styles.classes"
              (click)="on_delete_count_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.delete_count.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.delete_count.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.delete_count.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">Header</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container full {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.status.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-event_result" *ngIf="!fields.event_result.hidden" 
                            class="field-container full {{fields.event_result.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.event_result.styles.style"
                            [ngClass]="fields.event_result.styles.classes">
                        <div class="label-container"
                              title="{{fields.event_result.label}}{{fields.event_result.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.event_result.label}}">{{fields.event_result.label}}<span *ngIf="fields.event_result.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="event_result"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.event_result.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.event_result.control.placeholder}}"
                                [ngStyle]="fields.event_result.control.styles.style"
                                [ngClass]="fields.event_result.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.event_result.invalid">
                          <ng-container *ngFor="let error of fields.event_result.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container standard {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <InventoryCounts-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </InventoryCounts-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                              title="{{fields.description.label}}{{fields.description.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.description.label}}">{{fields.description.label}}<span *ngIf="fields.description.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Locations</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-use_location_filter" *ngIf="!fields.use_location_filter.hidden" 
                            class="field-container standard {{fields.use_location_filter.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_location_filter.styles.style"
                            [ngClass]="fields.use_location_filter.styles.classes">
                        <div class="label-container"
                              title="{{fields.use_location_filter.label}}{{fields.use_location_filter.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.use_location_filter.label}}">{{fields.use_location_filter.label}}<span *ngIf="fields.use_location_filter.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="use_location_filter"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.use_location_filter.control.styles.style"
                                      [ngClass]="fields.use_location_filter.control.styles.classes">{{fields.use_location_filter.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.use_location_filter.invalid">
                          <ng-container *ngFor="let error of fields.use_location_filter.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-select_locations" *ngIf="!fields.select_locations.hidden" 
                            class="field-container full {{fields.select_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.select_locations.styles.style"
                            [ngClass]="fields.select_locations.styles.classes">
                        <div class="label-container"
                              title="{{fields.select_locations.label}}{{fields.select_locations.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.select_locations.label}}">{{fields.select_locations.label}}<span *ngIf="fields.select_locations.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.select_locations.control.readOnly"
                                [ngStyle]="fields.select_locations.control.styles.style"
                                [ngClass]="fields.select_locations.control.styles.classes"
                                (click)="on_select_locations_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.select_locations.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.select_locations.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.select_locations.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.select_locations.invalid">
                          <ng-container *ngFor="let error of fields.select_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_only_empty_locations" *ngIf="!fields.is_only_empty_locations.hidden" 
                            class="field-container standard {{fields.is_only_empty_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_only_empty_locations.styles.style"
                            [ngClass]="fields.is_only_empty_locations.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_only_empty_locations.label}}{{fields.is_only_empty_locations.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_only_empty_locations.label}}">{{fields.is_only_empty_locations.label}}<span *ngIf="fields.is_only_empty_locations.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_only_empty_locations"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_only_empty_locations.control.styles.style"
                                      [ngClass]="fields.is_only_empty_locations.control.styles.classes">{{fields.is_only_empty_locations.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_only_empty_locations.invalid">
                          <ng-container *ngFor="let error of fields.is_only_empty_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_skip_hard_allocation_locations" *ngIf="!fields.is_skip_hard_allocation_locations.hidden" 
                            class="field-container standard {{fields.is_skip_hard_allocation_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_skip_hard_allocation_locations.styles.style"
                            [ngClass]="fields.is_skip_hard_allocation_locations.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_skip_hard_allocation_locations.label}}{{fields.is_skip_hard_allocation_locations.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_skip_hard_allocation_locations.label}}">{{fields.is_skip_hard_allocation_locations.label}}<span *ngIf="fields.is_skip_hard_allocation_locations.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_skip_hard_allocation_locations"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_skip_hard_allocation_locations.control.styles.style"
                                      [ngClass]="fields.is_skip_hard_allocation_locations.control.styles.classes">{{fields.is_skip_hard_allocation_locations.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_skip_hard_allocation_locations.invalid">
                          <ng-container *ngFor="let error of fields.is_skip_hard_allocation_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_include_empty_locations" *ngIf="!fields.is_include_empty_locations.hidden" 
                            class="field-container standard {{fields.is_include_empty_locations.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_include_empty_locations.styles.style"
                            [ngClass]="fields.is_include_empty_locations.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_include_empty_locations.label}}{{fields.is_include_empty_locations.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_include_empty_locations.label}}">{{fields.is_include_empty_locations.label}}<span *ngIf="fields.is_include_empty_locations.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="is_include_empty_locations"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.is_include_empty_locations.control.styles.style"
                                      [ngClass]="fields.is_include_empty_locations.control.styles.classes">{{fields.is_include_empty_locations.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.is_include_empty_locations.invalid">
                          <ng-container *ngFor="let error of fields.is_include_empty_locations.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">Materials</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-use_material_filter" *ngIf="!fields.use_material_filter.hidden" 
                            class="field-container standard {{fields.use_material_filter.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.use_material_filter.styles.style"
                            [ngClass]="fields.use_material_filter.styles.classes">
                        <div class="label-container"
                              title="{{fields.use_material_filter.label}}{{fields.use_material_filter.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.use_material_filter.label}}">{{fields.use_material_filter.label}}<span *ngIf="fields.use_material_filter.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="use_material_filter"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.use_material_filter.control.styles.style"
                                      [ngClass]="fields.use_material_filter.control.styles.classes">{{fields.use_material_filter.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.use_material_filter.invalid">
                          <ng-container *ngFor="let error of fields.use_material_filter.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_filter_option" *ngIf="!fields.material_filter_option.hidden" 
                            class="field-container full {{fields.material_filter_option.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_filter_option.styles.style"
                            [ngClass]="fields.material_filter_option.styles.classes">
                        <div class="label-container"
                              title="{{fields.material_filter_option.label}}{{fields.material_filter_option.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material_filter_option.label}}">{{fields.material_filter_option.label}}<span *ngIf="fields.material_filter_option.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <InventoryCounts-material_filter_options_dd_single 
                            data-cy="selector"
                            [type]="fields.material_filter_option.control.type"
                            formControlName="material_filter_option"
                            (displayTextChange)="fields.material_filter_option.control.displayText=$event"
                            [placeholder]="fields.material_filter_option.control.placeholder"
                            [styles]="fields.material_filter_option.control.styles"
                        >
                        </InventoryCounts-material_filter_options_dd_single>
                        <ng-container *ngIf="fields.material_filter_option.invalid">
                          <ng-container *ngFor="let error of fields.material_filter_option.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-select_materials" *ngIf="!fields.select_materials.hidden" 
                            class="field-container full {{fields.select_materials.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.select_materials.styles.style"
                            [ngClass]="fields.select_materials.styles.classes">
                        <div class="label-container"
                              title="{{fields.select_materials.label}}{{fields.select_materials.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.select_materials.label}}">{{fields.select_materials.label}}<span *ngIf="fields.select_materials.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.select_materials.control.readOnly"
                                [ngStyle]="fields.select_materials.control.styles.style"
                                [ngClass]="fields.select_materials.control.styles.classes"
                                (click)="on_select_materials_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.select_materials.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.select_materials.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.select_materials.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.select_materials.invalid">
                          <ng-container *ngFor="let error of fields.select_materials.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-select_vendorlots" *ngIf="!fields.select_vendorlots.hidden" 
                            class="field-container full {{fields.select_vendorlots.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.select_vendorlots.styles.style"
                            [ngClass]="fields.select_vendorlots.styles.classes">
                        <div class="label-container"
                              title="{{fields.select_vendorlots.label}}{{fields.select_vendorlots.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.select_vendorlots.label}}">{{fields.select_vendorlots.label}}<span *ngIf="fields.select_vendorlots.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <button mat-button
                                data-cy="button"
                                class="datex-button"
                                [disabled]="fields.select_vendorlots.control.readOnly"
                                [ngStyle]="fields.select_vendorlots.control.styles.style"
                                [ngClass]="fields.select_vendorlots.control.styles.classes"
                                (click)="on_select_vendorlots_clicked($event)"
                                >
                          <div class="button-label">
                            <ng-container *ngIf="fields.select_vendorlots.control.icon === 'datex-default-spinner'; else showIcon">
                              <div class="button-icon">
                                <i data-cy="button-icon" class="datex-default-spinner"></i>
                              </div>
                            </ng-container>
                            <ng-template #showIcon>
                              <div class="button-icon">
                                <i data-cy="button-icon" class="icon {{fields.select_vendorlots.control.icon}}"></i>
                              </div>
                            </ng-template>
                            <div data-cy="button-label" class="button-text">{{fields.select_vendorlots.control.label}}</div>
                          </div>
                        
                        </button>
                        <ng-container *ngIf="fields.select_vendorlots.invalid">
                          <ng-container *ngFor="let error of fields.select_vendorlots.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-DateRange"
                     *ngIf="!fieldsets.DateRange.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.DateRange.collapsible }">
                    <div *ngIf="!fieldsets.DateRange.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.DateRange.toggle()">
                      <span class="fieldsetsTitle-text">DateRange</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.DateRange.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.DateRange.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.DateRange.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.DateRange.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-WeekDay" *ngIf="!fields.WeekDay.hidden" 
                            class="field-container double {{fields.WeekDay.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.WeekDay.styles.style"
                            [ngClass]="fields.WeekDay.styles.classes">
                        <div class="label-container"
                              title="{{fields.WeekDay.label}}{{fields.WeekDay.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.WeekDay.label}}">{{fields.WeekDay.label}}<span *ngIf="fields.WeekDay.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-daysOfWeek_zt_multi 
                            data-cy="selector-multi"
                            [type]="fields.WeekDay.control.type"
                            formControlName="WeekDay"
                            (displayTextChange)="fields.WeekDay.control.displayText=$event"
                            [placeholder]="fields.WeekDay.control.placeholder"
                            [styles]="fields.WeekDay.control.styles"
                        >
                        </FootPrintManager-daysOfWeek_zt_multi>
                        <ng-container *ngIf="fields.WeekDay.invalid">
                          <ng-container *ngFor="let error of fields.WeekDay.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-Month" *ngIf="!fields.Month.hidden" 
                            class="field-container double {{fields.Month.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.Month.styles.style"
                            [ngClass]="fields.Month.styles.classes">
                        <div class="label-container"
                              title="{{fields.Month.label}}{{fields.Month.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.Month.label}}">{{fields.Month.label}}<span *ngIf="fields.Month.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-monthsOfYear_zt_multi 
                            data-cy="selector-multi"
                            [type]="fields.Month.control.type"
                            formControlName="Month"
                            (displayTextChange)="fields.Month.control.displayText=$event"
                            [placeholder]="fields.Month.control.placeholder"
                            [styles]="fields.Month.control.styles"
                        >
                        </FootPrintManager-monthsOfYear_zt_multi>
                        <ng-container *ngIf="fields.Month.invalid">
                          <ng-container *ngFor="let error of fields.Month.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>
        <div class="widgets">
          <div *ngIf="!widgets.count_tasks_progress.hidden" class="widget-container">
            <InventoryCounts-count_tasks_progress_widget
            #$widgets_count_tasks_progress 
              [inventoryCountId]="$widgets_count_tasks_progress_inParams_inventoryCountId"
            ($refreshEvent)="refresh(false, false, '$widgets_count_tasks_progress')"
            >
            </InventoryCounts-count_tasks_progress_widget>
          </div>
        </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.inventory_count_tasks.hidden" class="tab" data-cy="tab-inventory_count_tasks">
              <h2 [className]="tabs.inventory_count_tasks.active? 'active': ''" (click)="tabs.inventory_count_tasks.activate()">{{tabs.inventory_count_tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-count_tasks_grid *ngIf="tabs.inventory_count_tasks.active"
              #$tabs_inventory_count_tasks
              [inventoryCountId]="$tabs_inventory_count_tasks_count_tasks_grid_inParams_inventoryCountId"
              [allowEdit]="$tabs_inventory_count_tasks_count_tasks_grid_inParams_allowEdit"
              ($refreshEvent)="refresh(false, false, '$tabs_inventory_count_tasks')">
              </FootPrintManager-count_tasks_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>