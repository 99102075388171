import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { Locations_licenseplate_statuses_dd_singleComponent } from './Locations.licenseplate_statuses_dd_single.component'
import { Lots_lot_statuses_dd_singleComponent } from './Lots.lot_statuses_dd_single.component'


interface IFootPrintManager_inventory_licenseplate_gridComponentEntity {
LicensePlateId?: number, LotId?: number, PackagedId?: number, GrossWeight?: number, PackagedAmount?: number, Lot?: { LookupCode?: string, MaterialId?: number, ReceiveDate?: string, VendorLot?: { ExpirationDate?: string, LookupCode?: string, ManufactureDate?: string, Vat?: string }, Material?: { Description?: string, LookupCode?: string, ProjectId?: number, Project?: { LookupCode?: string } } }, Packaging?: { ShortName?: string }, LicensePlate?: { LookupCode?: string, ParentId?: number, TypeId?: number, Location?: { Id?: number, Name?: string, Warehouse?: { Id?: number, Name?: string } }, ParentLicensePlate?: { Id?: number, LookupCode?: string } }, WeightUom?: { Short_name?: string }}

interface IFootPrintManager_inventory_licenseplate_gridComponentInParams {
  licenseplateId?: number, ownerId?: number, projectId?: number, warehouseId?: number, materialIds?: number[]}


class FootPrintManager_inventory_licenseplate_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inventory_licenseplate_gridComponent;
  entity: IFootPrintManager_inventory_licenseplate_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    licenseplate: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    parent_licenseplate: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    location: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    project: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    material: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    total_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    available_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    gross_weight: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    inbound_order: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    vendor_lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    expiration_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    manufacture_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    received_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    material_description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_inventory_licenseplate_gridComponent, entity: IFootPrintManager_inventory_licenseplate_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.LicensePlateId,this.entity.LotId,this.entity.PackagedId].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inventory_licenseplate_gridComponent, entity?: IFootPrintManager_inventory_licenseplate_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.LicensePlateId,this.entity.LotId,this.entity.PackagedId].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = {};
    set($resultKey, 'LicensePlateId',this.entity.LicensePlateId);
    set($resultKey, 'LotId',this.entity.LotId);
    set($resultKey, 'PackagedId',this.entity.PackagedId);
    const inParams = {
      $keys:[$resultKey],
      licenseplateId:  $grid.inParams.licenseplateId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
      licenseplateStatusId:  $grid.filters.licenseplate_status.control.value ,
      lotStatusId:  $grid.filters.lot_status.control.value ,
      vendorlotTextSearch:  $grid.filters.vendorlot.control.value ,
    };
    const data = await this.datasources.Inventory.ds_inventory_licenseplate_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity.LicensePlate.LookupCode;
    (this.cells.parent_licenseplate.displayControl as TextModel).text = $row.entity.LicensePlate?.ParentLicensePlate?.LookupCode;
    (this.cells.location.displayControl as TextModel).text = $row.entity.LicensePlate?.Location?.Name;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.LicensePlate?.Location?.Warehouse?.Name;
    (this.cells.project.displayControl as TextModel).text = $row.entity.Lot?.Material?.Project?.LookupCode;
    (this.cells.material.displayControl as TextModel).text = $row.entity.Lot?.Material?.LookupCode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.total_packaged_amount.displayControl as TextModel).text = $row.entity.PackagedAmount?.toString();
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.Packaging?.ShortName;
    (this.cells.gross_weight.displayControl as TextModel).text = $row.entity.GrossWeight?.toString();
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity.WeightUom?.Short_name;
    (this.cells.material_description.displayControl as TextModel).text = $row.entity.Lot?.Material?.Description;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'licenseplate' : {
        this.on_licenseplate_clicked();
        break;
      }
      case 'parent_licenseplate' : {
        this.on_parent_licenseplate_clicked();
        break;
      }
      case 'location' : {
        this.on_location_clicked();
        break;
      }
      case 'project' : {
        this.on_project_clicked();
        break;
      }
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'lot' : {
        this.on_lot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_lot_hub({lotId: $row.entity.LotId});
  }
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.Lot.MaterialId});
  }
  on_project_clicked(event = null) {
    return this.on_project_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_clickedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_project_hub({projectId: $row.entity.Lot.Material.ProjectId})
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Apply date format
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ExpirationDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var expirationDate = $utils.date.format($row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat);
      $row.cells.expiration_date.displayControl.text = expirationDate;
  }
  
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.ManufactureDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var manufactureDate = $utils.date.format($row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat);
      $row.cells.manufacture_date.displayControl.text = manufactureDate;
  }
  
  
  if ($utils.isDefined($row.entity.Lot?.ReceiveDate) && $utils.isDefined($settings.FootPrintManager.DateFormat)) {
      var dateFormat = $settings.FootPrintManager.DateFormat;
      var receivedDate = $utils.date.format($row.entity.Lot?.ReceiveDate, dateFormat);
      $row.cells.received_date.displayControl.text = receivedDate;
  }
  
  if ($utils.isDefined($row.entity.Lot?.VendorLot?.Vat)) {
  
      $row.cells.vendor_lot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode + ' ' + $row.entity.Lot?.VendorLot?.Vat
  } else {
      $row.cells.vendor_lot.displayControl.text = $row.entity.Lot?.VendorLot?.LookupCode
  }
  }
  on_licenseplate_clicked(event = null) {
    return this.on_licenseplate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_licenseplate_clickedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  if ($utils.isDefined($row.entity.LicensePlateId)) {
      $shell.FootPrintManager.opensingle_licenseplate_hub({ licenseplateId: $row.entity.LicensePlateId });
  }
  }
  on_location_clicked(event = null) {
    return this.on_location_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_location_clickedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  $shell.FootPrintManager.opensingle_location_hub({locationId: $row.entity.LicensePlate.Location.Id});
  }
  on_parent_licenseplate_clicked(event = null) {
    return this.on_parent_licenseplate_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_parent_licenseplate_clickedInternal(
    $row: FootPrintManager_inventory_licenseplate_gridComponentRowModel,
  $grid: FootPrintManager_inventory_licenseplate_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  //only open licenseplate hub if a Parent ID exists.
  if ($utils.isDefined($row.entity.LicensePlate.ParentId)) {
      $shell.FootPrintManager.opensingle_parent_licenseplate_hub({ licenseplateId: $row.entity.LicensePlate.ParentId });
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Locations_licenseplate_statuses_dd_singleComponent),
    forwardRef(() => Lots_lot_statuses_dd_singleComponent),
  ],
  selector: 'FootPrintManager-inventory_licenseplate_grid',
  templateUrl: './FootPrintManager.inventory_licenseplate_grid.component.html'
})
export class FootPrintManager_inventory_licenseplate_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inventory_licenseplate_gridComponentEntity[];

  pageSize = 50;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);

  fullTextSearch: string;

  inParams: IFootPrintManager_inventory_licenseplate_gridComponentInParams = { licenseplateId: null, ownerId: null, projectId: null, warehouseId: null, materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'LP', false, false, null),       parent_licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'Parent LP', false, false, null),       location: new GridHeaderModel(new HeaderStyles(null, null), 'Location', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       project: new GridHeaderModel(new HeaderStyles(null, null), 'Project', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       total_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Total amount', false, false, null),       available_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Available amount', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'Gross weight', false, false, null),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Weight UOM', false, false, null),       inbound_order: new GridHeaderModel(new HeaderStyles(null, null), 'Inbound order', false, false, null),       vendor_lot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       expiration_date: new GridHeaderModel(new HeaderStyles(null, null), 'Expiration date', false, false, null),       manufacture_date: new GridHeaderModel(new HeaderStyles(null, null), 'Manufacture date', false, false, null),       received_date: new GridHeaderModel(new HeaderStyles(null, null), 'Received date', false, false, null),       material_description: new GridHeaderModel(new HeaderStyles(null, null), 'Material description', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inventory_licenseplate_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('licenseplateId') set $inParams_licenseplateId(value: any) {
    this.inParams['licenseplateId'] = value;
  }
  get $inParams_licenseplateId(): any {
    return this.inParams['licenseplateId'] ;
  }
  @Input('ownerId') set $inParams_ownerId(value: any) {
    this.inParams['ownerId'] = value;
  }
  get $inParams_ownerId(): any {
    return this.inParams['ownerId'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
      adjust_inventory: new ToolModel(new ButtonModel('adjust_inventory', new ButtonStyles(null, null), false, 'Adjust inventory', 'icon-datex-Inventory')
    ),
      move_inventory: new ToolModel(new ButtonModel('move_inventory', new ButtonStyles(null, null), false, 'Move inventory', 'icon-datex-Inventory')
    ),
      transfer_inventory: new ToolModel(new ButtonModel('transfer_inventory', new ButtonStyles(null, null), false, 'Transfer inventory', 'icon-datex-Inventory')
    )
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    vendorlot: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    licenseplate_status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot_status: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    vendorlot: new FieldModel(new TextBoxModel(this.formGroup.controls['vendorlot'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Vendor lot', false)
,
    licenseplate_status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['licenseplate_status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'LP status', false)
,
    lot_status: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['lot_status'] as DatexFormControl, 
  ESelectBoxType.dropdown, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Lot status', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Inventory by LP';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;

    

    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      licenseplateId:  $grid.inParams.licenseplateId ,
      fullTextSearch:  $grid.fullTextSearch ,
      ownerId:  $grid.inParams.ownerId ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
      licenseplateStatusId:  $grid.filters.licenseplate_status.control.value ,
      lotStatusId:  $grid.filters.lot_status.control.value ,
      vendorlotTextSearch:  $grid.filters.vendorlot.control.value ,
    };
    try {
    const data = await this.datasources.Inventory.ds_inventory_licenseplate_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inventory_licenseplate_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_grid_data_loaded();
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $grid.topToolbar.adjust_inventory.control.readOnly = true;
  $grid.topToolbar.move_inventory.control.readOnly = false;
  $grid.topToolbar.transfer_inventory.control.readOnly = true;
  $grid.headers.parent_licenseplate.hidden = true;
  
  
  
  if ((await $operations.FootPrintManager.Disable_InventoryGrid_TransferInventory.isAuthorized())){
      $grid.topToolbar.transfer_inventory.hidden = true;
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const hasOneSelectedRows = $grid.selectedRows.length === 1;
  $grid.topToolbar.adjust_inventory.control.readOnly = !hasOneSelectedRows;
  $grid.topToolbar.transfer_inventory.control.readOnly = !hasOneSelectedRows;
  
  }
  on_adjust_inventory_clicked(event = null) {
    return this.on_adjust_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_adjust_inventory_clickedInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const lotId = $grid.selectedRows[0].entity.LotId;
  const packagedId = $grid.selectedRows[0].entity.PackagedId;
  const licenseplateId = $grid.selectedRows[0].entity.LicensePlateId;
  
  
  let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_licenseplateId_lotId_packagedId.get({ 
      lotId: lotId, packagedId: packagedId, licenseplateId: licenseplateId })).result;
  
  
  const dialogResult = await $shell.FootPrintManager.openinventory_adjustment_formDialog({
      licenseplateId: $grid.selectedRows[0].entity.LicensePlateId,
      lotId: $grid.selectedRows[0].entity.LotId,
      packagingId: $grid.selectedRows[0].entity.PackagedId,
      packagedAmount: $grid.selectedRows[0].entity.PackagedAmount,
      availablePackagedAmount: $utils.isDefined(lot_availability?.TotalAvailablePackagedAmount) ? lot_availability?.TotalAvailablePackagedAmount : 0
  })
  const userConfirmed = dialogResult.confirm;
  if (userConfirmed) {
      $grid.refresh()
  }
  
  
  }
  on_move_inventory_clicked(event = null) {
    return this.on_move_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_move_inventory_clickedInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Group Rows by licenseplate
  let licensePlates: {
      id: number,
      content: {
          lotId: number,
          packagingId: number,
          packagedAmount: number,
          availablePackagedAmount: number
      }[]
  }[] = [];
  
  if ($grid.selectedRows.length > 0) {
  
      $grid.selectedRows.forEach(row => {
          let foundIndex = licensePlates.findIndex(lp => lp.id === row.entity.LicensePlateId);
  
          
  
          if (foundIndex >= 0) {
              licensePlates[foundIndex].content.push({
                  lotId: row.entity.LotId,
                  packagingId: row.entity.PackagedId,
                  packagedAmount: row.entity.PackagedAmount,
                  availablePackagedAmount: row.entity.PackagedAmount
              });
          }
          else {
              licensePlates.push({
                  id: row.entity.LicensePlateId,
                  content: [
                      {
                          lotId: row.entity.LotId,
                          packagingId: row.entity.PackagedId,
                          packagedAmount: row.entity.PackagedAmount,
                          availablePackagedAmount: row.entity.PackagedAmount
                      }
                  ]
              });
          }
      });
  
      const dialogResult = await $shell.FootPrintManager.openinventory_and_licenseplate_move_formDialog({
          licenseplates: licensePlates
      });
  
      const userConfirmed = dialogResult?.confirm;
      if (userConfirmed) {
          await $grid.refresh()
      }
  }
  else {
      let licenseplates = (await $datasources.Inventory.ds_inventory_licenseplate_grid.get({
          licenseplateId: $grid.inParams.licenseplateId,
          fullTextSearch: $grid.fullTextSearch,
          ownerId: $grid.inParams.ownerId,
          projectId: $grid.inParams.projectId,
          warehouseId: $grid.inParams.warehouseId,
          materialIds: $grid.inParams.materialIds,
          vendorlotTextSearch: $grid.filters.vendorlot.control.value,
          licenseplateStatusId: $grid.filters.licenseplate_status.control.value,
          lotStatusId: $grid.filters.lot_status.control.value
      })).result;
  
      const dialogResult = await $shell.FootPrintManager.openbulk_licenseplate_directed_move_formDialog({
          licenseplates: licenseplates.filter(lp => (lp.LicensePlate.TypeId === 1 || lp.LicensePlate.TypeId === 2) && lp.LicensePlate.ParentId === null).map(lp => ({
              id: lp.LicensePlateId,
              warehouseId: lp.LicensePlate.Location.Warehouse.Id,
              typeId: lp.LicensePlate.TypeId,
              locationId: lp.LicensePlate.Location.Id
          })
          )
      });
  
      const userConfirmed = dialogResult?.confirm;
      if (userConfirmed) {
          await $grid.refresh()
      }
  }
  }
  on_transfer_inventory_clicked(event = null) {
    return this.on_transfer_inventory_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_transfer_inventory_clickedInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const lotId = $grid.selectedRows[0].entity.LotId;
  const packagedId = $grid.selectedRows[0].entity.PackagedId;
  const licenseplateId = $grid.selectedRows[0].entity.LicensePlateId;
  
  
  let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_licenseplateId_lotId_packagedId.get({
      lotId: lotId, packagedId: packagedId, licenseplateId: licenseplateId
  })).result;
  
  
  
  
  const dialogResult = await $shell.FootPrintManager.openinventory_transfer_formDialog({
  
      lotId: $grid.selectedRows[0].entity.LotId,
      packagingId: $grid.selectedRows[0].entity.PackagedId,
      licenseplateId: $grid.selectedRows[0].entity.LicensePlateId,
      packagedAmount: $grid.selectedRows[0].entity.PackagedAmount,
      materialId: $grid.selectedRows[0].entity.Lot.MaterialId,
      availablePackagedAmount: $utils.isDefined(lot_availability?.TotalAvailablePackagedAmount) ? lot_availability?.TotalAvailablePackagedAmount : 0
  })
  const userConfirmed = dialogResult.confirm;
  if (userConfirmed) {
      $grid.refresh()
  }
  }
  on_grid_data_loaded(event = null) {
    return this.on_grid_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_grid_data_loadedInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  hideUnusedColumns()
  
  function hideUnusedColumns() {
      for (let key in $grid.headers) {
          if (!$grid.rows.find(row => $utils.isDefinedTrimmed(row.cells[key].displayControl.text))) {
              $grid.headers[key].hidden = true;
          }
          else {
              $grid.headers[key].hidden = false;
          }
      }
  }
  
  
  const lotIds = ($grid.rows.map(r => r.entity?.LotId))
  const packagedIds = ($grid.rows.map(r => r.entity?.PackagedId))
  const licenseplateIds = ($grid.rows.map(r => r.entity?.LicensePlateId))
  
  
  let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched.get({ lotIds: lotIds, packagedIds: packagedIds, licenseplateIds: licenseplateIds })).result;
  let inboundOrders = (await $datasources.Inventory.ds_get_lot_original_inbound_order_batched.get({ lotIds: lotIds })).result
  
  
  for (let row of $grid.rows) {
      for (let available of lot_availability) {
          if (available.LotId === row.entity.LotId && available.LicensePlateId === row.entity.LicensePlateId  && available.PackagedId === row.entity.PackagedId) {
              row.cells.available_packaged_amount.displayControl.text = available.TotalAvailablePackagedAmount.toString();
          }
      }
      for (let order of inboundOrders){
          if(order.LotId === row.entity.LotId){
              row.cells.inbound_order.displayControl.text = order.order?.LookupCode;
          }
      }
  }
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_inventory_licenseplate_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  
  
  const exportLimit = $settings.FootPrintManager.ExcelExportLimit
  var topLimit: number = 5000;
  
  // Initialize
  var wb = $event;
  var transactions = [];
  var allData: any[] = [];
  var lot_availability: any[] = [];
  var inboundOrders: any[] = [];
  var freshData: any[] = [];
  
  // Set Date/Time Formats
  const dateFormat = `${$settings.FootPrintManager.DateFormat}`;
  
  
  try {
      //Handle user selected rows only. 
  
      if ($grid.selectedRows.length > 0) {
  
          let lotIds = [...new Set($grid.selectedRows.map(r => r.entity?.LotId))]
          let packagedIds = [...new Set($grid.selectedRows.map(r => r.entity?.PackagedId))]
          let licenseplateIds = [...new Set($grid.selectedRows.map(r => r.entity?.LicensePlateId))]
  
  
  
  
          let lot_availability = (await $datasources.Inventory.ds_get_available_inventory_by_licenseplateIds_lotIds_packagedIds_batched.get({
              lotIds: lotIds, packagedIds: packagedIds, licenseplateIds: licenseplateIds
          })).result;
          let inboundOrders = (await $datasources.Inventory.ds_get_lot_original_inbound_order_batched.get({ lotIds: lotIds })).result
  
          // Compile all data into JSON
          for (let row of $grid.selectedRows) {
  
              transactions.push({
                  "LP": row.entity.LicensePlate.LookupCode,
                  "Location": row.entity.LicensePlate?.Location?.Name,
                  "Warehouse": row.entity.LicensePlate?.Location?.Warehouse?.Name,
                  "Project": row.entity.Lot?.Material?.Project?.LookupCode,
                  "Material": row.entity.Lot?.Material?.LookupCode,
                  "Lot": row.entity.Lot?.LookupCode,
                  "Total Amount": row.entity.PackagedAmount,
                  "Available Amount": lot_availability.find(e => e?.LotId === row.entity?.LotId
                      && e?.LicensePlateId === row.entity?.LicensePlateId
                      && e?.PackagedId === row.entity?.PackagedId)?.TotalAvailablePackagedAmount.toString(),
                  "Packaging": row.entity.Packaging?.ShortName,
                  "Gross Weight": row.entity.GrossWeight,
                  "Weight Uom": row.entity.WeightUom?.Short_name,
                  "Inbound Order": inboundOrders.find(e => e?.LotId === row.entity?.LotId)?.order?.LookupCode,
                  "Vendor Lot": row.entity.Lot?.VendorLot?.LookupCode,
                  "Expiration Date": $utils.date.format(row.entity.Lot?.VendorLot?.ExpirationDate, dateFormat),
                  "Manufacture Date": $utils.date.format(row.entity.Lot?.VendorLot?.ManufactureDate, dateFormat),
                  "Received Date": $utils.date.format(row.entity.Lot?.ReceiveDate, dateFormat),
                  "Material Description": row.entity.Lot?.Material?.Description
  
              });
          }
          var ws = $utils.excel.json_to_sheet(transactions);
          $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
      }
      //take all rows in the dataset with applied filters.
      else {
  
          var totalRecords = (await $datasources.Inventory.ds_inventory_licenseplate_grid.getList({
              ownerId: $grid.inParams.ownerId,
              projectId: $grid.inParams.projectId,
              warehouseId: $grid.inParams.warehouseId,
              materialIds: $grid.inParams.materialIds,
              fullTextSearch: $grid.fullTextSearch,
              licenseplateStatusId: $grid.filters.licenseplate_status.control.value,
              lotStatusId: $grid.filters.lot_status.control.value,
              vendorlotTextSearch: $grid.filters.vendorlot.control.value,
              $top: 1
          })).totalCount;
  
  
          if (totalRecords > exportLimit) {
              var exportApproval = true;
              (await $shell.Inventory.openInfoDialog(`Export limitation of ${exportLimit} exceeded`, `Excel will only export ${exportLimit} records`));
          } else {
              exportApproval = true;
          }
  
  
          if (exportApproval) {
              var recordsTaken: number = 0;
              var remainingRecords: number = Math.min(exportLimit, totalRecords);
              do {
                  var response = await $datasources.Inventory.ds_inventory_licenseplate_grid.getList({
                      ownerId: $grid.inParams.ownerId,
                      projectId: $grid.inParams.projectId,
                      warehouseId: $grid.inParams.warehouseId,
                      materialIds: $grid.inParams.materialIds,
                      fullTextSearch: $grid.fullTextSearch,
                      licenseplateStatusId: $grid.filters.licenseplate_status.control.value,
                      lotStatusId: $grid.filters.lot_status.control.value,
                      vendorlotTextSearch: $grid.filters.vendorlot.control.value,
                      $top: Math.min(remainingRecords, topLimit),
                      $skip: recordsTaken
                  });
                  allData = allData.concat(response.result);
                  recordsTaken += response.result.length
                  remainingRecords -= response.result.length
  
              } while (remainingRecords > 0 && response.result.length > 0)
  
              // Compile all data into JSON
              for (let record of allData) {
  
  
  
                  transactions.push({
                      "LP": record.LicensePlate?.LookupCode,
                      "Location": record.LicensePlate?.Location?.Name,
                      "Warehouse": record.LicensePlate?.Location?.Warehouse?.Name,
                      "Project": record.Lot?.Material?.Project?.LookupCode,
                      "Material": record.Lot?.Material?.LookupCode,
                      "Lot": record.Lot?.LookupCode,
                      "Total Amount": record.PackagedAmount,
                      "Packaging": record.Packaging?.ShortName,
                      "Gross Weight": record.GrossWeight,
                      "Weight Uom": record.WeightUom?.Short_name,
                      "Vendor Lot": record.Lot?.VendorLot?.LookupCode,
                      "Expiration Date": $utils.date.format(record.Lot?.VendorLot?.ExpirationDate, dateFormat),
                      "Manufacture Date": $utils.date.format(record.Lot?.VendorLot?.ManufactureDate, dateFormat),
                      "Received Date": $utils.date.format(record.Lot?.ReceiveDate, dateFormat),
                      "Material Description": record.Lot?.Material?.Description
                  });
              }
              var ws = $utils.excel.json_to_sheet(transactions);
              $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
          }
      }
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_inventory_licenseplate_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
