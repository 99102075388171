<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-print" *ngIf="!toolbar.print.hidden" class="toolContainer">
      <button mat-button 
              data-cy="splitbutton"
              class="datex-button splitbutton" 
              [disabled]="toolbar.print.control.readOnly"
              [ngStyle]="toolbar.print.control.styles.style"
              [ngClass]="toolbar.print.control.styles.classes"
              [matMenuTriggerFor]="dropdownMenuOne">
        <div class="button-label">
          <div *ngIf="toolbar.print.control.icon"
                class="button-icon">
            <i data-cy="splitbutton-icon" class="icon {{toolbar.print.control.icon}}"></i>
          </div>
          <div data-cy="splitbutton-label" class="button-text">{{toolbar.print.control.label}}</div>
          <div class="button-icon splitbutton-drop-icon">
            <i data-cy="splitbutton-icon" class="icon icon-ic_fluent_chevron_down_20_regular"></i>
          </div>
        </div>
      </button>
      <mat-menu #dropdownMenuOne="matMenu" class="options-menu splitbutton-menu">
        <div data-cy="splitbutton-options">
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.print.control.buttons.packing_slip.readOnly"
                    [ngStyle]="toolbar.print.control.buttons.packing_slip.styles.style"
                    [ngClass]="toolbar.print.control.buttons.packing_slip.styles.classes"
                    (click)="on_print_packing_slip_clicked($event)">
              <div class="button-label">
                <div *ngIf="toolbar.print.control.buttons.packing_slip.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.print.control.buttons.packing_slip.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.print.control.buttons.packing_slip.label}}</div>
              </div>
            </button>
          </div>
          <div mat-menu-item class="splitbutton-item-container">
            <button mat-button
                    data-cy="splitbutton-sub-item"
                    class="datex-button splitbutton-sub-item" 
                    [disabled]="toolbar.print.control.buttons.shipping_label.readOnly"
                    [ngStyle]="toolbar.print.control.buttons.shipping_label.styles.style"
                    [ngClass]="toolbar.print.control.buttons.shipping_label.styles.classes"
                    (click)="on_print_shipping_label_clicked($event)">
              <div class="button-label">
                <div *ngIf="toolbar.print.control.buttons.shipping_label.icon"
                    class="button-icon">
                  <i data-cy="splitbutton-sub-item-icon" class="icon {{toolbar.print.control.buttons.shipping_label.icon}}"></i>
                </div>
                <div data-cy="splitbutton-sub-item-label"
                    class="button-text">{{toolbar.print.control.buttons.shipping_label.label}}</div>
              </div>
            </button>
          </div>
        </div>
      </mat-menu>
      </div>
      <div data-cy="tool-id-surveys" *ngIf="!toolbar.surveys.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.surveys.control.readOnly"
              [ngStyle]="toolbar.surveys.control.styles.style"
              [ngClass]="toolbar.surveys.control.styles.classes"
              (click)="on_surveys_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.surveys.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.surveys.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.surveys.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text">General information</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-container_code" *ngIf="!fields.container_code.hidden" 
                            class="field-container standard {{fields.container_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.container_code.styles.style"
                            [ngClass]="fields.container_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.container_code.label}}{{fields.container_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.container_code.label}}">{{fields.container_code.label}}<span *ngIf="fields.container_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="container_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.container_code.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.container_code.control.placeholder}}"
                                [ngStyle]="fields.container_code.control.styles.style"
                                [ngClass]="fields.container_code.control.styles.classes"> 
                        <ng-container *ngIf="fields.container_code.invalid">
                          <ng-container *ngFor="let error of fields.container_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-container_type" *ngIf="!fields.container_type.hidden" 
                            class="field-container standard {{fields.container_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.container_type.styles.style"
                            [ngClass]="fields.container_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.container_type.label}}{{fields.container_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.container_type.label}}">{{fields.container_type.label}}<span *ngIf="fields.container_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PackVerification-containertypes_dd_single 
                            data-cy="selector"
                            [type]="fields.container_type.control.type"
                            formControlName="container_type"
                            (displayTextChange)="fields.container_type.control.displayText=$event"
                            [placeholder]="fields.container_type.control.placeholder"
                            [styles]="fields.container_type.control.styles"
                        >
                        </PackVerification-containertypes_dd_single>
                        <ng-container *ngIf="fields.container_type.invalid">
                          <ng-container *ngFor="let error of fields.container_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Container details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-weight" *ngIf="!fields.weight.hidden" 
                            class="field-container standard {{fields.weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight.styles.style"
                            [ngClass]="fields.weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight.label}}{{fields.weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight.label}}">{{fields.weight.label}}<span *ngIf="fields.weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="weight"
                                matInput
                                numberBox
                                [format]="fields.weight.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.weight.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.weight.control.placeholder}}"
                                [ngStyle]="fields.weight.control.styles.style"
                                [ngClass]="fields.weight.control.styles.classes">
                        <ng-container *ngIf="fields.weight.invalid">
                          <ng-container *ngFor="let error of fields.weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden" 
                            class="field-container standard {{fields.weight_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_uom.label}}{{fields.weight_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_uom.label}}">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PackVerification-measurementunits_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </PackVerification-measurementunits_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-length" *ngIf="!fields.length.hidden" 
                            class="field-container standard {{fields.length.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.length.styles.style"
                            [ngClass]="fields.length.styles.classes">
                        <div class="label-container"
                              title="{{fields.length.label}}{{fields.length.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.length.label}}">{{fields.length.label}}<span *ngIf="fields.length.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="length"
                                matInput
                                numberBox
                                [format]="fields.length.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.length.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.length.control.placeholder}}"
                                [ngStyle]="fields.length.control.styles.style"
                                [ngClass]="fields.length.control.styles.classes">
                        <ng-container *ngIf="fields.length.invalid">
                          <ng-container *ngFor="let error of fields.length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width" *ngIf="!fields.width.hidden" 
                            class="field-container standard {{fields.width.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.width.styles.style"
                            [ngClass]="fields.width.styles.classes">
                        <div class="label-container"
                              title="{{fields.width.label}}{{fields.width.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.width.label}}">{{fields.width.label}}<span *ngIf="fields.width.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="width"
                                matInput
                                numberBox
                                [format]="fields.width.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.width.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.width.control.placeholder}}"
                                [ngStyle]="fields.width.control.styles.style"
                                [ngClass]="fields.width.control.styles.classes">
                        <ng-container *ngIf="fields.width.invalid">
                          <ng-container *ngFor="let error of fields.width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height" *ngIf="!fields.height.hidden" 
                            class="field-container standard {{fields.height.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.height.styles.style"
                            [ngClass]="fields.height.styles.classes">
                        <div class="label-container"
                              title="{{fields.height.label}}{{fields.height.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.height.label}}">{{fields.height.label}}<span *ngIf="fields.height.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="height"
                                matInput
                                numberBox
                                [format]="fields.height.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.height.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.height.control.placeholder}}"
                                [ngStyle]="fields.height.control.styles.style"
                                [ngClass]="fields.height.control.styles.classes">
                        <ng-container *ngIf="fields.height.invalid">
                          <ng-container *ngFor="let error of fields.height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dimensions_uom" *ngIf="!fields.dimensions_uom.hidden" 
                            class="field-container standard {{fields.dimensions_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dimensions_uom.styles.style"
                            [ngClass]="fields.dimensions_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.dimensions_uom.label}}{{fields.dimensions_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dimensions_uom.label}}">{{fields.dimensions_uom.label}}<span *ngIf="fields.dimensions_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PackVerification-measurementunits_dd_single 
                            data-cy="selector"
                            [type]="fields.dimensions_uom.control.type"
                            formControlName="dimensions_uom"
                            (displayTextChange)="fields.dimensions_uom.control.displayText=$event"
                            [placeholder]="fields.dimensions_uom.control.placeholder"
                            [styles]="fields.dimensions_uom.control.styles"
                          [typeId]="$fields_dimensions_uom_selector_inParams_typeId"
                        >
                        </PackVerification-measurementunits_dd_single>
                        <ng-container *ngIf="fields.dimensions_uom.invalid">
                          <ng-container *ngFor="let error of fields.dimensions_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume" *ngIf="!fields.volume.hidden" 
                            class="field-container standard {{fields.volume.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume.styles.style"
                            [ngClass]="fields.volume.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume.label}}{{fields.volume.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume.label}}">{{fields.volume.label}}<span *ngIf="fields.volume.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="volume"
                                matInput
                                numberBox
                                [format]="fields.volume.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.volume.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.volume.control.placeholder}}"
                                [ngStyle]="fields.volume.control.styles.style"
                                [ngClass]="fields.volume.control.styles.classes">
                        <ng-container *ngIf="fields.volume.invalid">
                          <ng-container *ngFor="let error of fields.volume.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume_uom" *ngIf="!fields.volume_uom.hidden" 
                            class="field-container standard {{fields.volume_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume_uom.styles.style"
                            [ngClass]="fields.volume_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume_uom.label}}{{fields.volume_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume_uom.label}}">{{fields.volume_uom.label}}<span *ngIf="fields.volume_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <PackVerification-measurementunits_dd_single 
                            data-cy="selector"
                            [type]="fields.volume_uom.control.type"
                            formControlName="volume_uom"
                            (displayTextChange)="fields.volume_uom.control.displayText=$event"
                            [placeholder]="fields.volume_uom.control.placeholder"
                            [styles]="fields.volume_uom.control.styles"
                          [typeId]="$fields_volume_uom_selector_inParams_typeId"
                        >
                        </PackVerification-measurementunits_dd_single>
                        <ng-container *ngIf="fields.volume_uom.invalid">
                          <ng-container *ngFor="let error of fields.volume_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup3"
                     *ngIf="!fieldsets.newGroup3.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup3.collapsible }">
                    <div *ngIf="!fieldsets.newGroup3.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup3.toggle()">
                      <span class="fieldsetsTitle-text">Shipping details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup3.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup3.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup3.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup3.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-carrier" *ngIf="!fields.carrier.hidden" 
                            class="field-container standard {{fields.carrier.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier.styles.style"
                            [ngClass]="fields.carrier.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier.label}}{{fields.carrier.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier.label}}">{{fields.carrier.label}}<span *ngIf="fields.carrier.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.carrier.control.styles.style"
                              [ngClass]="fields.carrier.control.styles.classes">{{fields.carrier.control.text }}</div>
                        <ng-container *ngIf="fields.carrier.invalid">
                          <ng-container *ngFor="let error of fields.carrier.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service_type" *ngIf="!fields.carrier_service_type.hidden" 
                            class="field-container standard {{fields.carrier_service_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier_service_type.styles.style"
                            [ngClass]="fields.carrier_service_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier_service_type.label}}{{fields.carrier_service_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier_service_type.label}}">{{fields.carrier_service_type.label}}<span *ngIf="fields.carrier_service_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.carrier_service_type.control.styles.style"
                              [ngClass]="fields.carrier_service_type.control.styles.classes">{{fields.carrier_service_type.control.text }}</div>
                        <ng-container *ngIf="fields.carrier_service_type.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-trailer_number" *ngIf="!fields.trailer_number.hidden" 
                            class="field-container standard {{fields.trailer_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.trailer_number.styles.style"
                            [ngClass]="fields.trailer_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.trailer_number.label}}{{fields.trailer_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.trailer_number.label}}">{{fields.trailer_number.label}}<span *ngIf="fields.trailer_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="trailer_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.trailer_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.trailer_number.control.placeholder}}"
                                [ngStyle]="fields.trailer_number.control.styles.style"
                                [ngClass]="fields.trailer_number.control.styles.classes"> 
                        <ng-container *ngIf="fields.trailer_number.invalid">
                          <ng-container *ngFor="let error of fields.trailer_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-tracking_number" *ngIf="!fields.tracking_number.hidden" 
                            class="field-container standard {{fields.tracking_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.tracking_number.styles.style"
                            [ngClass]="fields.tracking_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.tracking_number.label}}{{fields.tracking_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.tracking_number.label}}">{{fields.tracking_number.label}}<span *ngIf="fields.tracking_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="tracking_number"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.tracking_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.tracking_number.control.placeholder}}"
                                [ngStyle]="fields.tracking_number.control.styles.style"
                                [ngClass]="fields.tracking_number.control.styles.classes"> 
                        <ng-container *ngIf="fields.tracking_number.invalid">
                          <ng-container *ngFor="let error of fields.tracking_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-is_pack_verified" *ngIf="!fields.is_pack_verified.hidden" 
                            class="field-container standard {{fields.is_pack_verified.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.is_pack_verified.styles.style"
                            [ngClass]="fields.is_pack_verified.styles.classes">
                        <div class="label-container"
                              title="{{fields.is_pack_verified.label}}{{fields.is_pack_verified.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.is_pack_verified.label}}">{{fields.is_pack_verified.label}}<span *ngIf="fields.is_pack_verified.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="is_pack_verified"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.is_pack_verified.control.styles.style"
                                      [ngClass]="fields.is_pack_verified.control.styles.classes">{{fields.is_pack_verified.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.is_pack_verified.invalid">
                          <ng-container *ngFor="let error of fields.is_pack_verified.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.tasks.hidden" class="tab" data-cy="tab-tasks">
              <h2 [className]="tabs.tasks.active? 'active': ''" (click)="tabs.tasks.activate()">{{tabs.tasks.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-shipping_container_tasks_grid *ngIf="tabs.tasks.active"
              #$tabs_tasks
              [shippingContainerId]="$tabs_tasks_shipping_container_tasks_grid_inParams_shippingContainerId"
              ($refreshEvent)="refresh(false, false, '$tabs_tasks')">
              </FootPrintManager-shipping_container_tasks_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>