import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class Invoices_ds_get_invoice_line_details_by_invoiceId_neService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { invoiceId: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { CreatedSysDateTime?: string, MaterialId?: number, Notes?: string, ShipmentId?: number, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, Shipment?: { LookupCode?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, OperationCode?: { Name?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_get_invoice_line_details_by_invoiceId_ne/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { invoiceId: number, $top?: number, $skip?: number }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { CreatedSysDateTime?: string, MaterialId?: number, Notes?: string, ShipmentId?: number, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, Shipment?: { LookupCode?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, OperationCode?: { Name?: string } } }[] }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_get_invoice_line_details_by_invoiceId_ne/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { invoiceId: number, $keys: number[] }): 
  Promise<{ result: { Id?: number, CreatedSysDateTime?: string, Description?: string, LineTotal?: number, Quantity?: number, UnitPrice?: number, BillingCode?: { Name?: string }, BillingRecords?: { BillingTaskId?: number, InvoiceLineId?: number, BillingTask?: { CreatedSysDateTime?: string, MaterialId?: number, Notes?: string, ShipmentId?: number, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, Material?: { Description?: string, LookupCode?: string }, Shipment?: { LookupCode?: string }, Order?: { LookupCode?: string, OwnerReference?: string }, OperationCode?: { Name?: string } } }[] }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.invoiceId)) {
      missingRequiredInParams.push('\'invoiceId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/datasources/ds_get_invoice_line_details_by_invoiceId_ne/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
