<datex-grid #$gridComponent *ngIf="initialized && !$hasMissingRequiredInParams" [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-toggle_add_remove" *ngIf="!topToolbar.toggle_add_remove.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.toggle_add_remove.control.readOnly"
            [ngStyle]="topToolbar.toggle_add_remove.control.styles.style"
            [ngClass]="topToolbar.toggle_add_remove.control.styles.classes"
            (click)="on_toggle_add_remove_materials_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.toggle_add_remove.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.toggle_add_remove.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.toggle_add_remove.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-add_remove_selected_materials" *ngIf="!topToolbar.add_remove_selected_materials.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.add_remove_selected_materials.control.readOnly"
            [ngStyle]="topToolbar.add_remove_selected_materials.control.styles.style"
            [ngClass]="topToolbar.add_remove_selected_materials.control.styles.classes"
            (click)="on_add_remove_selected_materials_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.add_remove_selected_materials.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.add_remove_selected_materials.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.add_remove_selected_materials.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div data-cy="tool-id-add_remove_all_materials" *ngIf="!topToolbar.add_remove_all_materials.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.add_remove_all_materials.control.readOnly"
            [ngStyle]="topToolbar.add_remove_all_materials.control.styles.style"
            [ngClass]="topToolbar.add_remove_all_materials.control.styles.classes"
            (click)="on_add_remove_all_materials_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.add_remove_all_materials.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.add_remove_all_materials.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.add_remove_all_materials.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-project" *ngIf="!filters.project.hidden" 
            class="field-container full {{filters.project.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.project.styles.style"
            [ngClass]="filters.project.styles.classes">
        <div class="label-container"
              title="{{filters.project.label}}{{filters.project.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.project.label}}">{{filters.project.label}}<span *ngIf="filters.project.required"
                  class="required-asterisk">*</span></label>
        </div>
        <InventoryCounts-projects_dd_single 
            data-cy="selector"
            [type]="filters.project.control.type"
            formControlName="project"
            (displayTextChange)="filters.project.control.displayText=$event"
            [placeholder]="filters.project.control.placeholder"
            [styles]="filters.project.control.styles"
        >
        </InventoryCounts-projects_dd_single>
        <ng-container *ngIf="filters.project.invalid">
          <ng-container *ngFor="let error of filters.project.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-status" *ngIf="!filters.status.hidden" 
            class="field-container standard {{filters.status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.status.styles.style"
            [ngClass]="filters.status.styles.classes">
        <div class="label-container"
              title="{{filters.status.label}}{{filters.status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.status.label}}">{{filters.status.label}}<span *ngIf="filters.status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <InventoryCounts-material_statuses_dd_single 
            data-cy="selector"
            [type]="filters.status.control.type"
            formControlName="status"
            (displayTextChange)="filters.status.control.displayText=$event"
            [placeholder]="filters.status.control.placeholder"
            [styles]="filters.status.control.styles"
        >
        </InventoryCounts-material_statuses_dd_single>
        <ng-container *ngIf="filters.status.invalid">
          <ng-container *ngFor="let error of filters.status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-storage_category_rules" *ngIf="!filters.storage_category_rules.hidden" 
            class="field-container standard {{filters.storage_category_rules.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.storage_category_rules.styles.style"
            [ngClass]="filters.storage_category_rules.styles.classes">
        <div class="label-container"
              title="{{filters.storage_category_rules.label}}{{filters.storage_category_rules.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.storage_category_rules.label}}">{{filters.storage_category_rules.label}}<span *ngIf="filters.storage_category_rules.required"
                  class="required-asterisk">*</span></label>
        </div>
        <InventoryCounts-storage_category_rules_dd_single 
            data-cy="selector"
            [type]="filters.storage_category_rules.control.type"
            formControlName="storage_category_rules"
            (displayTextChange)="filters.storage_category_rules.control.displayText=$event"
            [placeholder]="filters.storage_category_rules.control.placeholder"
            [styles]="filters.storage_category_rules.control.styles"
        >
        </InventoryCounts-storage_category_rules_dd_single>
        <ng-container *ngIf="filters.storage_category_rules.invalid">
          <ng-container *ngFor="let error of filters.storage_category_rules.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="material_lookupcode">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_lookupcode.displayControl.styles.style"
          [ngClass]="row.cells.material_lookupcode.displayControl.styles.classes">{{row.cells.material_lookupcode.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="status_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.status_name.displayControl.styles.style"
          [ngClass]="row.cells.status_name.displayControl.styles.classes">{{row.cells.status_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="temperature_category_rule_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.temperature_category_rule_name.displayControl.styles.style"
          [ngClass]="row.cells.temperature_category_rule_name.displayControl.styles.classes">{{row.cells.temperature_category_rule_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
<div *ngIf="$hasMissingRequiredInParams" class="missing-params">
  <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
</div>
