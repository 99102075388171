import { Inject, Injectable, Injector } from '@angular/core';


import { BarTender_generate_label_to_sftp_flowService } from './BarTender.flow.index';
import { BarTender_print_asn_license_plate_labels_flowService } from './BarTender.flow.index';

import { $types } from './BarTender.types'

@Injectable({ providedIn: 'root' })
export class BarTender_FlowService {

  constructor(
    private injector: Injector
  ) {
  }

  public BarTender: BarTender_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _generate_label_to_sftp_flow: BarTender_generate_label_to_sftp_flowService;
  public async generate_label_to_sftp_flow(inParams: { options: { labelPath: string, printerName: string, quantity?: number }, variables: { name: string, value: any }[], sftp: { path: string, fileName?: string } }): Promise< { reasons?: string[] }> {
    if(!this._generate_label_to_sftp_flow) {
      this._generate_label_to_sftp_flow = this.injector.get(BarTender_generate_label_to_sftp_flowService);
    }
    return this._generate_label_to_sftp_flow.run(inParams);
  }
  private _print_asn_license_plate_labels_flow: BarTender_print_asn_license_plate_labels_flowService;
  public async print_asn_license_plate_labels_flow(inParams: { orderId: number, licensePlateId?: number, options?: { printerName?: string, labelPath?: string, quantity?: number } }): Promise< { reasons?: string[] }> {
    if(!this._print_asn_license_plate_labels_flow) {
      this._print_asn_license_plate_labels_flow = this.injector.get(BarTender_print_asn_license_plate_labels_flowService);
    }
    return this._print_asn_license_plate_labels_flow.run(inParams);
  }
}
