import { Inject, Injectable, Injector } from '@angular/core';

import { Utilities_FlowService } from './Utilities.flow.index';

import { Equipment_add_warehouse_to_equipment_flowService } from './Equipment.flow.index';
import { Equipment_create_equipment_flowService } from './Equipment.flow.index';
import { Equipment_remove_warehouse_from_equipmentService } from './Equipment.flow.index';
import { Equipment_update_equipment_flowService } from './Equipment.flow.index';
import { Equipment_validate_equipment_flowService } from './Equipment.flow.index';

import { $types } from './Equipment.types'

@Injectable({ providedIn: 'root' })
export class Equipment_FlowService {

  constructor(
    private injector: Injector
  ) {
    this.Utilities = this.injector.get(Utilities_FlowService);
  }

    public Utilities: Utilities_FlowService;
  public Equipment: Equipment_FlowService = this;

  // injecting lazily in order to avoid circular dependencies
  private _add_warehouse_to_equipment_flow: Equipment_add_warehouse_to_equipment_flowService;
  public async add_warehouse_to_equipment_flow(inParams: { warehouseId: number, equipmentId: number }): Promise< { reasons?: string[] }> {
    if(!this._add_warehouse_to_equipment_flow) {
      this._add_warehouse_to_equipment_flow = this.injector.get(Equipment_add_warehouse_to_equipment_flowService);
    }
    return this._add_warehouse_to_equipment_flow.run(inParams);
  }
  private _create_equipment_flow: Equipment_create_equipment_flowService;
  public async create_equipment_flow(inParams: { equipment?: { lookupCode: string, typeId: number, macAddress?: string, warehouses?: { warehouseId: number }[] } }): Promise< { equipmentId?: number, reasons?: string[], result?: any }> {
    if(!this._create_equipment_flow) {
      this._create_equipment_flow = this.injector.get(Equipment_create_equipment_flowService);
    }
    return this._create_equipment_flow.run(inParams);
  }
  private _remove_warehouse_from_equipment: Equipment_remove_warehouse_from_equipmentService;
  public async remove_warehouse_from_equipment(inParams: { equipmentId: number, warehouseId: number }): Promise< { reasons: string[] }> {
    if(!this._remove_warehouse_from_equipment) {
      this._remove_warehouse_from_equipment = this.injector.get(Equipment_remove_warehouse_from_equipmentService);
    }
    return this._remove_warehouse_from_equipment.run(inParams);
  }
  private _update_equipment_flow: Equipment_update_equipment_flowService;
  public async update_equipment_flow(inParams: { equipment: { lookupCode?: string, typeId?: number, macAddress?: string, warehouses?: { warehouseId: number }[], id: number } }): Promise< { reasons?: string[] }> {
    if(!this._update_equipment_flow) {
      this._update_equipment_flow = this.injector.get(Equipment_update_equipment_flowService);
    }
    return this._update_equipment_flow.run(inParams);
  }
  private _validate_equipment_flow: Equipment_validate_equipment_flowService;
  public async validate_equipment_flow(inParams: { equipment: { lookupCode?: string, typeId?: number, macAddress?: string, warehouses?: { warehouseId: number }[], id?: number } }): Promise< { reasons?: string[] }> {
    if(!this._validate_equipment_flow) {
      this._validate_equipment_flow = this.injector.get(Equipment_validate_equipment_flowService);
    }
    return this._validate_equipment_flow.run(inParams);
  }
}
