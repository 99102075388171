<div class="blade-wrapper">
  <div class="blade-header">
    <div  class="blade-tools">
      <div data-cy="tool-id-parse_input" *ngIf="!toolbar.parse_input.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.parse_input.control.readOnly"
              [ngStyle]="toolbar.parse_input.control.styles.style"
              [ngClass]="toolbar.parse_input.control.styles.classes"
              (click)="on_parse_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.parse_input.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.parse_input.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.parse_input.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-form">
      <ng-container *ngIf="initialized ">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-input"
                     *ngIf="!fieldsets.input.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.input.collapsible }">
                    <div *ngIf="!fieldsets.input.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.input.toggle()">
                      <span class="fieldsetsTitle-text">Input</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.input.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.input.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.input.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.input.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-input_scan" *ngIf="!fields.input_scan.hidden" 
                            class="field-container standard {{fields.input_scan.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.input_scan.styles.style"
                            [ngClass]="fields.input_scan.styles.classes">
                        <div class="label-container"
                              title="{{fields.input_scan.label}}{{fields.input_scan.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.input_scan.label}}">{{fields.input_scan.label}}<span *ngIf="fields.input_scan.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="input_scan"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.input_scan.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.input_scan.control.placeholder}}"
                                [ngStyle]="fields.input_scan.control.styles.style"
                                [ngClass]="fields.input_scan.control.styles.classes"> 
                        <ng-container *ngIf="fields.input_scan.invalid">
                          <ng-container *ngFor="let error of fields.input_scan.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-output"
                     *ngIf="!fieldsets.output.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.output.collapsible }">
                    <div *ngIf="!fieldsets.output.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.output.toggle()">
                      <span class="fieldsetsTitle-text">Output</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.output.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.output.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.output.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.output.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-gtin" *ngIf="!fields.gtin.hidden" 
                            class="field-container standard {{fields.gtin.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.gtin.styles.style"
                            [ngClass]="fields.gtin.styles.classes">
                        <div class="label-container"
                              title="{{fields.gtin.label}}{{fields.gtin.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.gtin.label}}">{{fields.gtin.label}}<span *ngIf="fields.gtin.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.gtin.control.styles.style"
                              [ngClass]="fields.gtin.control.styles.classes">{{fields.gtin.control.text }}</div>
                        <ng-container *ngIf="fields.gtin.invalid">
                          <ng-container *ngFor="let error of fields.gtin.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-serial_number" *ngIf="!fields.serial_number.hidden" 
                            class="field-container standard {{fields.serial_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.serial_number.styles.style"
                            [ngClass]="fields.serial_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.serial_number.label}}{{fields.serial_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.serial_number.label}}">{{fields.serial_number.label}}<span *ngIf="fields.serial_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.serial_number.control.styles.style"
                              [ngClass]="fields.serial_number.control.styles.classes">{{fields.serial_number.control.text }}</div>
                        <ng-container *ngIf="fields.serial_number.invalid">
                          <ng-container *ngFor="let error of fields.serial_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-expiration_date" *ngIf="!fields.expiration_date.hidden" 
                            class="field-container standard {{fields.expiration_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.expiration_date.styles.style"
                            [ngClass]="fields.expiration_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.expiration_date.label}}{{fields.expiration_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.expiration_date.label}}">{{fields.expiration_date.label}}<span *ngIf="fields.expiration_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.expiration_date.control.styles.style"
                              [ngClass]="fields.expiration_date.control.styles.classes">{{fields.expiration_date.control.text }}</div>
                        <ng-container *ngIf="fields.expiration_date.invalid">
                          <ng-container *ngFor="let error of fields.expiration_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-batch_number" *ngIf="!fields.batch_number.hidden" 
                            class="field-container standard {{fields.batch_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.batch_number.styles.style"
                            [ngClass]="fields.batch_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.batch_number.label}}{{fields.batch_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.batch_number.label}}">{{fields.batch_number.label}}<span *ngIf="fields.batch_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.batch_number.control.styles.style"
                              [ngClass]="fields.batch_number.control.styles.classes">{{fields.batch_number.control.text }}</div>
                        <ng-container *ngIf="fields.batch_number.invalid">
                          <ng-container *ngFor="let error of fields.batch_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>      </ng-container>

    </div>
  </div>
</div>