<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-save_line" *ngIf="!toolbar.save_line.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.save_line.control.readOnly"
              [ngStyle]="toolbar.save_line.control.styles.style"
              [ngClass]="toolbar.save_line.control.styles.classes"
              (click)="on_save_details($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.save_line.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.save_line.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.save_line.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-close" *ngIf="!toolbar.close.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.close.control.readOnly"
              [ngStyle]="toolbar.close.control.styles.style"
              [ngClass]="toolbar.close.control.styles.classes"
              (click)="on_close($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.close.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.close.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.close.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-description"
                     *ngIf="!fieldsets.description.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.description.collapsible }">
                    <div *ngIf="!fieldsets.description.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.description.toggle()">
                      <span class="fieldsetsTitle-text">Strategy description</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.description.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.description.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.description.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.description.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-description" *ngIf="!fields.description.hidden" 
                            class="field-container full {{fields.description.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.description.styles.style"
                            [ngClass]="fields.description.styles.classes">
                        <div class="label-container"
                              title="{{fields.description.label}}{{fields.description.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.description.label}}">{{fields.description.label}}<span *ngIf="fields.description.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="description"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.description.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.description.control.placeholder}}"
                                [ngStyle]="fields.description.control.styles.style"
                                [ngClass]="fields.description.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.description.invalid">
                          <ng-container *ngFor="let error of fields.description.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-basic_information"
                     *ngIf="!fieldsets.basic_information.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.basic_information.collapsible }">
                    <div *ngIf="!fieldsets.basic_information.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.basic_information.toggle()">
                      <span class="fieldsetsTitle-text">Basic Information</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.basic_information.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.basic_information.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.basic_information.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.basic_information.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-line_id" *ngIf="!fields.line_id.hidden" 
                            class="field-container standard {{fields.line_id.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.line_id.styles.style"
                            [ngClass]="fields.line_id.styles.classes">
                        <div class="label-container"
                              title="{{fields.line_id.label}}{{fields.line_id.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.line_id.label}}">{{fields.line_id.label}}<span *ngIf="fields.line_id.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="line_id"
                                matInput
                                numberBox
                                [format]="fields.line_id.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.line_id.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.line_id.control.placeholder}}"
                                [ngStyle]="fields.line_id.control.styles.style"
                                [ngClass]="fields.line_id.control.styles.classes">
                        <ng-container *ngIf="fields.line_id.invalid">
                          <ng-container *ngFor="let error of fields.line_id.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-line_number" *ngIf="!fields.line_number.hidden" 
                            class="field-container standard {{fields.line_number.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.line_number.styles.style"
                            [ngClass]="fields.line_number.styles.classes">
                        <div class="label-container"
                              title="{{fields.line_number.label}}{{fields.line_number.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.line_number.label}}">{{fields.line_number.label}}<span *ngIf="fields.line_number.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="line_number"
                                matInput
                                numberBox
                                [format]="fields.line_number.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.line_number.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.line_number.control.placeholder}}"
                                [ngStyle]="fields.line_number.control.styles.style"
                                [ngClass]="fields.line_number.control.styles.classes">
                        <ng-container *ngIf="fields.line_number.invalid">
                          <ng-container *ngFor="let error of fields.line_number.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-billing_aggregation_strategy" *ngIf="!fields.billing_aggregation_strategy.hidden" 
                            class="field-container standard {{fields.billing_aggregation_strategy.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_aggregation_strategy.styles.style"
                            [ngClass]="fields.billing_aggregation_strategy.styles.classes">
                        <div class="label-container"
                              title="{{fields.billing_aggregation_strategy.label}}{{fields.billing_aggregation_strategy.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.billing_aggregation_strategy.label}}">{{fields.billing_aggregation_strategy.label}}<span *ngIf="fields.billing_aggregation_strategy.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="billing_aggregation_strategy"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.billing_aggregation_strategy.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.billing_aggregation_strategy.control.placeholder}}"
                                [ngStyle]="fields.billing_aggregation_strategy.control.styles.style"
                                [ngClass]="fields.billing_aggregation_strategy.control.styles.classes"> 
                        <ng-container *ngIf="fields.billing_aggregation_strategy.invalid">
                          <ng-container *ngFor="let error of fields.billing_aggregation_strategy.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-accessorial_template" *ngIf="!fields.accessorial_template.hidden" 
                            class="field-container standard {{fields.accessorial_template.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.accessorial_template.styles.style"
                            [ngClass]="fields.accessorial_template.styles.classes">
                        <div class="label-container"
                              title="{{fields.accessorial_template.label}}{{fields.accessorial_template.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.accessorial_template.label}}">{{fields.accessorial_template.label}}<span *ngIf="fields.accessorial_template.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_accessorial_charges_templates_dd_single 
                            data-cy="selector"
                            [type]="fields.accessorial_template.control.type"
                            formControlName="accessorial_template"
                            (displayTextChange)="fields.accessorial_template.control.displayText=$event"
                            [placeholder]="fields.accessorial_template.control.placeholder"
                            [styles]="fields.accessorial_template.control.styles"
                          [enabled]="$fields_accessorial_template_selector_inParams_enabled"
                        >
                        </Invoices-billing_accessorial_charges_templates_dd_single>
                        <ng-container *ngIf="fields.accessorial_template.invalid">
                          <ng-container *ngFor="let error of fields.accessorial_template.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-operation_code" *ngIf="!fields.operation_code.hidden" 
                            class="field-container standard {{fields.operation_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.operation_code.styles.style"
                            [ngClass]="fields.operation_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.operation_code.label}}{{fields.operation_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.operation_code.label}}">{{fields.operation_code.label}}<span *ngIf="fields.operation_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-operation_codes_dd_single 
                            data-cy="selector"
                            [type]="fields.operation_code.control.type"
                            formControlName="operation_code"
                            (displayTextChange)="fields.operation_code.control.displayText=$event"
                            [placeholder]="fields.operation_code.control.placeholder"
                            [styles]="fields.operation_code.control.styles"
                          [operationTypeId]="$fields_operation_code_selector_inParams_operationTypeId"
                        >
                        </Invoices-operation_codes_dd_single>
                        <ng-container *ngIf="fields.operation_code.invalid">
                          <ng-container *ngFor="let error of fields.operation_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-billing_code" *ngIf="!fields.billing_code.hidden" 
                            class="field-container standard {{fields.billing_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_code.styles.style"
                            [ngClass]="fields.billing_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.billing_code.label}}{{fields.billing_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.billing_code.label}}">{{fields.billing_code.label}}<span *ngIf="fields.billing_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billingcodes_dd_single 
                            data-cy="selector"
                            [type]="fields.billing_code.control.type"
                            formControlName="billing_code"
                            (displayTextChange)="fields.billing_code.control.displayText=$event"
                            [placeholder]="fields.billing_code.control.placeholder"
                            [styles]="fields.billing_code.control.styles"
                        >
                        </Invoices-billingcodes_dd_single>
                        <ng-container *ngIf="fields.billing_code.invalid">
                          <ng-container *ngFor="let error of fields.billing_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-resulting_uom" *ngIf="!fields.resulting_uom.hidden" 
                            class="field-container standard {{fields.resulting_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.resulting_uom.styles.style"
                            [ngClass]="fields.resulting_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.resulting_uom.label}}{{fields.resulting_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.resulting_uom.label}}">{{fields.resulting_uom.label}}<span *ngIf="fields.resulting_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.resulting_uom.control.type"
                            formControlName="resulting_uom"
                            (displayTextChange)="fields.resulting_uom.control.displayText=$event"
                            [placeholder]="fields.resulting_uom.control.placeholder"
                            [styles]="fields.resulting_uom.control.styles"
                          [id]="$fields_resulting_uom_selector_inParams_id"
                          [typeId]="$fields_resulting_uom_selector_inParams_typeId"
                        >
                        </Invoices-measurement_units_dd_single>
                        <ng-container *ngIf="fields.resulting_uom.invalid">
                          <ng-container *ngFor="let error of fields.resulting_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-warehouse" *ngIf="!fields.warehouse.hidden" 
                            class="field-container standard {{fields.warehouse.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.warehouse.styles.style"
                            [ngClass]="fields.warehouse.styles.classes">
                        <div class="label-container"
                              title="{{fields.warehouse.label}}{{fields.warehouse.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.warehouse.label}}">{{fields.warehouse.label}}<span *ngIf="fields.warehouse.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-warehouses_dd_single 
                            data-cy="selector"
                            [type]="fields.warehouse.control.type"
                            formControlName="warehouse"
                            (displayTextChange)="fields.warehouse.control.displayText=$event"
                            [placeholder]="fields.warehouse.control.placeholder"
                            [styles]="fields.warehouse.control.styles"
                        >
                        </Invoices-warehouses_dd_single>
                        <ng-container *ngIf="fields.warehouse.invalid">
                          <ng-container *ngFor="let error of fields.warehouse.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-start_date" *ngIf="!fields.start_date.hidden" 
                            class="field-container standard {{fields.start_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.start_date.styles.style"
                            [ngClass]="fields.start_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.start_date.label}}{{fields.start_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.start_date.label}}">{{fields.start_date.label}}<span *ngIf="fields.start_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="start_date"
                            [format]="fields.start_date.control.format"
                            [mode]="fields.start_date.control.mode"
                            [ngStyle]="fields.start_date.control.styles.style"
                            [ngClass]="fields.start_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.start_date.invalid">
                          <ng-container *ngFor="let error of fields.start_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-end_date" *ngIf="!fields.end_date.hidden" 
                            class="field-container standard {{fields.end_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.end_date.styles.style"
                            [ngClass]="fields.end_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.end_date.label}}{{fields.end_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.end_date.label}}">{{fields.end_date.label}}<span *ngIf="fields.end_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <app-datebox 
                            data-cy="dateBox"
                            formControlName="end_date"
                            [format]="fields.end_date.control.format"
                            [mode]="fields.end_date.control.mode"
                            [ngStyle]="fields.end_date.control.styles.style"
                            [ngClass]="fields.end_date.control.styles.classes">
                        </app-datebox>
                        <ng-container *ngIf="fields.end_date.invalid">
                          <ng-container *ngFor="let error of fields.end_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-base_package_conversion" *ngIf="!fields.base_package_conversion.hidden" 
                            class="field-container standard {{fields.base_package_conversion.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.base_package_conversion.styles.style"
                            [ngClass]="fields.base_package_conversion.styles.classes">
                        <div class="label-container"
                              title="{{fields.base_package_conversion.label}}{{fields.base_package_conversion.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.base_package_conversion.label}}">{{fields.base_package_conversion.label}}<span *ngIf="fields.base_package_conversion.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="base_package_conversion"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.base_package_conversion.control.styles.style"
                                      [ngClass]="fields.base_package_conversion.control.styles.classes">{{fields.base_package_conversion.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.base_package_conversion.invalid">
                          <ng-container *ngFor="let error of fields.base_package_conversion.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-date_range" *ngIf="!fields.date_range.hidden" 
                            class="field-container full {{fields.date_range.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.date_range.styles.style"
                            [ngClass]="fields.date_range.styles.classes">
                        <div class="label-container"
                              title="{{fields.date_range.label}}{{fields.date_range.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.date_range.label}}">{{fields.date_range.label}}<span *ngIf="fields.date_range.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_contract_line_date_range_single 
                            data-cy="selector"
                            [type]="fields.date_range.control.type"
                            formControlName="date_range"
                            (displayTextChange)="fields.date_range.control.displayText=$event"
                            [placeholder]="fields.date_range.control.placeholder"
                            [styles]="fields.date_range.control.styles"
                        >
                        </Invoices-billing_contract_line_date_range_single>
                        <ng-container *ngIf="fields.date_range.invalid">
                          <ng-container *ngFor="let error of fields.date_range.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-recurring_storage"
                     *ngIf="!fieldsets.recurring_storage.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.recurring_storage.collapsible }">
                    <div *ngIf="!fieldsets.recurring_storage.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.recurring_storage.toggle()">
                      <span class="fieldsetsTitle-text">Recurring storage</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.recurring_storage.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.recurring_storage.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.recurring_storage.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.recurring_storage.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-recurring_billing_object" *ngIf="!fields.recurring_billing_object.hidden" 
                            class="field-container standard {{fields.recurring_billing_object.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_billing_object.styles.style"
                            [ngClass]="fields.recurring_billing_object.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_billing_object.label}}{{fields.recurring_billing_object.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_billing_object.label}}">{{fields.recurring_billing_object.label}}<span *ngIf="fields.recurring_billing_object.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-recurring_storage_billing_objects_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_billing_object.control.type"
                            formControlName="recurring_billing_object"
                            (displayTextChange)="fields.recurring_billing_object.control.displayText=$event"
                            [placeholder]="fields.recurring_billing_object.control.placeholder"
                            [styles]="fields.recurring_billing_object.control.styles"
                        >
                        </Invoices-recurring_storage_billing_objects_dd_single>
                        <ng-container *ngIf="fields.recurring_billing_object.invalid">
                          <ng-container *ngFor="let error of fields.recurring_billing_object.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_schedule" *ngIf="!fields.recurring_schedule.hidden" 
                            class="field-container full {{fields.recurring_schedule.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_schedule.styles.style"
                            [ngClass]="fields.recurring_schedule.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_schedule.label}}{{fields.recurring_schedule.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_schedule.label}}">{{fields.recurring_schedule.label}}<span *ngIf="fields.recurring_schedule.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-recurring_storage_schedule_types_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_schedule.control.type"
                            formControlName="recurring_schedule"
                            (displayTextChange)="fields.recurring_schedule.control.displayText=$event"
                            [placeholder]="fields.recurring_schedule.control.placeholder"
                            [styles]="fields.recurring_schedule.control.styles"
                          [ids]="$fields_recurring_schedule_selector_inParams_ids"
                        >
                        </Invoices-recurring_storage_schedule_types_dd_single>
                        <ng-container *ngIf="fields.recurring_schedule.invalid">
                          <ng-container *ngFor="let error of fields.recurring_schedule.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_frequency" *ngIf="!fields.recurring_frequency.hidden" 
                            class="field-container full {{fields.recurring_frequency.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_frequency.styles.style"
                            [ngClass]="fields.recurring_frequency.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_frequency.label}}{{fields.recurring_frequency.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_frequency.label}}">{{fields.recurring_frequency.label}}<span *ngIf="fields.recurring_frequency.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_frequency_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_frequency.control.type"
                            formControlName="recurring_frequency"
                            (displayTextChange)="fields.recurring_frequency.control.displayText=$event"
                            [placeholder]="fields.recurring_frequency.control.placeholder"
                            [styles]="fields.recurring_frequency.control.styles"
                        >
                        </Invoices-billing_frequency_dd_single>
                        <ng-container *ngIf="fields.recurring_frequency.invalid">
                          <ng-container *ngFor="let error of fields.recurring_frequency.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_day_interval" *ngIf="!fields.recurring_day_interval.hidden" 
                            class="field-container standard {{fields.recurring_day_interval.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_day_interval.styles.style"
                            [ngClass]="fields.recurring_day_interval.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_day_interval.label}}{{fields.recurring_day_interval.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_day_interval.label}}">{{fields.recurring_day_interval.label}}<span *ngIf="fields.recurring_day_interval.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="recurring_day_interval"
                                matInput
                                numberBox
                                [format]="fields.recurring_day_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.recurring_day_interval.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.recurring_day_interval.control.placeholder}}"
                                [ngStyle]="fields.recurring_day_interval.control.styles.style"
                                [ngClass]="fields.recurring_day_interval.control.styles.classes">
                        <ng-container *ngIf="fields.recurring_day_interval.invalid">
                          <ng-container *ngFor="let error of fields.recurring_day_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_week_interval" *ngIf="!fields.recurring_week_interval.hidden" 
                            class="field-container standard {{fields.recurring_week_interval.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_week_interval.styles.style"
                            [ngClass]="fields.recurring_week_interval.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_week_interval.label}}{{fields.recurring_week_interval.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_week_interval.label}}">{{fields.recurring_week_interval.label}}<span *ngIf="fields.recurring_week_interval.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="recurring_week_interval"
                                matInput
                                numberBox
                                [format]="fields.recurring_week_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.recurring_week_interval.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.recurring_week_interval.control.placeholder}}"
                                [ngStyle]="fields.recurring_week_interval.control.styles.style"
                                [ngClass]="fields.recurring_week_interval.control.styles.classes">
                        <ng-container *ngIf="fields.recurring_week_interval.invalid">
                          <ng-container *ngFor="let error of fields.recurring_week_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_days" *ngIf="!fields.recurring_days.hidden" 
                            class="field-container full {{fields.recurring_days.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_days.styles.style"
                            [ngClass]="fields.recurring_days.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_days.label}}{{fields.recurring_days.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_days.label}}">{{fields.recurring_days.label}}<span *ngIf="fields.recurring_days.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_weekdays_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.recurring_days.control.type"
                            formControlName="recurring_days"
                            (displayTextChange)="fields.recurring_days.control.displayText=$event"
                            [placeholder]="fields.recurring_days.control.placeholder"
                            [styles]="fields.recurring_days.control.styles"
                        >
                        </Invoices-billing_weekdays_dd_multi>
                        <ng-container *ngIf="fields.recurring_days.invalid">
                          <ng-container *ngFor="let error of fields.recurring_days.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_month_day" *ngIf="!fields.recurring_month_day.hidden" 
                            class="field-container standard {{fields.recurring_month_day.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_month_day.styles.style"
                            [ngClass]="fields.recurring_month_day.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_month_day.label}}{{fields.recurring_month_day.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_month_day.label}}">{{fields.recurring_month_day.label}}<span *ngIf="fields.recurring_month_day.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="recurring_month_day"
                                matInput
                                numberBox
                                [format]="fields.recurring_month_day.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.recurring_month_day.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.recurring_month_day.control.placeholder}}"
                                [ngStyle]="fields.recurring_month_day.control.styles.style"
                                [ngClass]="fields.recurring_month_day.control.styles.classes">
                        <ng-container *ngIf="fields.recurring_month_day.invalid">
                          <ng-container *ngFor="let error of fields.recurring_month_day.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_month_interval" *ngIf="!fields.recurring_month_interval.hidden" 
                            class="field-container standard {{fields.recurring_month_interval.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_month_interval.styles.style"
                            [ngClass]="fields.recurring_month_interval.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_month_interval.label}}{{fields.recurring_month_interval.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_month_interval.label}}">{{fields.recurring_month_interval.label}}<span *ngIf="fields.recurring_month_interval.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="recurring_month_interval"
                                matInput
                                numberBox
                                [format]="fields.recurring_month_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.recurring_month_interval.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.recurring_month_interval.control.placeholder}}"
                                [ngStyle]="fields.recurring_month_interval.control.styles.style"
                                [ngClass]="fields.recurring_month_interval.control.styles.classes">
                        <ng-container *ngIf="fields.recurring_month_interval.invalid">
                          <ng-container *ngFor="let error of fields.recurring_month_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_time" *ngIf="!fields.recurring_time.hidden" 
                            class="field-container standard {{fields.recurring_time.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_time.styles.style"
                            [ngClass]="fields.recurring_time.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_time.label}}{{fields.recurring_time.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_time.label}}">{{fields.recurring_time.label}}<span *ngIf="fields.recurring_time.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-hourly_options_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_time.control.type"
                            formControlName="recurring_time"
                            (displayTextChange)="fields.recurring_time.control.displayText=$event"
                            [placeholder]="fields.recurring_time.control.placeholder"
                            [styles]="fields.recurring_time.control.styles"
                        >
                        </FootPrintManager-hourly_options_dd_single>
                        <ng-container *ngIf="fields.recurring_time.invalid">
                          <ng-container *ngFor="let error of fields.recurring_time.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_anniversary_grace_period" *ngIf="!fields.recurring_anniversary_grace_period.hidden" 
                            class="field-container full {{fields.recurring_anniversary_grace_period.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_anniversary_grace_period.styles.style"
                            [ngClass]="fields.recurring_anniversary_grace_period.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_anniversary_grace_period.label}}{{fields.recurring_anniversary_grace_period.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_anniversary_grace_period.label}}">{{fields.recurring_anniversary_grace_period.label}}<span *ngIf="fields.recurring_anniversary_grace_period.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="recurring_anniversary_grace_period"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.recurring_anniversary_grace_period.control.styles.style"
                                      [ngClass]="fields.recurring_anniversary_grace_period.control.styles.classes">{{fields.recurring_anniversary_grace_period.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.recurring_anniversary_grace_period.invalid">
                          <ng-container *ngFor="let error of fields.recurring_anniversary_grace_period.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_anniversary_grace_period_value" *ngIf="!fields.recurring_anniversary_grace_period_value.hidden" 
                            class="field-container standard {{fields.recurring_anniversary_grace_period_value.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_anniversary_grace_period_value.styles.style"
                            [ngClass]="fields.recurring_anniversary_grace_period_value.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_anniversary_grace_period_value.label}}{{fields.recurring_anniversary_grace_period_value.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_anniversary_grace_period_value.label}}">{{fields.recurring_anniversary_grace_period_value.label}}<span *ngIf="fields.recurring_anniversary_grace_period_value.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="recurring_anniversary_grace_period_value"
                                matInput
                                numberBox
                                [format]="fields.recurring_anniversary_grace_period_value.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.recurring_anniversary_grace_period_value.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.recurring_anniversary_grace_period_value.control.placeholder}}"
                                [ngStyle]="fields.recurring_anniversary_grace_period_value.control.styles.style"
                                [ngClass]="fields.recurring_anniversary_grace_period_value.control.styles.classes">
                        <ng-container *ngIf="fields.recurring_anniversary_grace_period_value.invalid">
                          <ng-container *ngFor="let error of fields.recurring_anniversary_grace_period_value.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_anniversary_grace_period_option" *ngIf="!fields.recurring_anniversary_grace_period_option.hidden" 
                            class="field-container standard {{fields.recurring_anniversary_grace_period_option.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_anniversary_grace_period_option.styles.style"
                            [ngClass]="fields.recurring_anniversary_grace_period_option.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_anniversary_grace_period_option.label}}{{fields.recurring_anniversary_grace_period_option.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_anniversary_grace_period_option.label}}">{{fields.recurring_anniversary_grace_period_option.label}}<span *ngIf="fields.recurring_anniversary_grace_period_option.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_recurring_grace_periods_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_anniversary_grace_period_option.control.type"
                            formControlName="recurring_anniversary_grace_period_option"
                            (displayTextChange)="fields.recurring_anniversary_grace_period_option.control.displayText=$event"
                            [placeholder]="fields.recurring_anniversary_grace_period_option.control.placeholder"
                            [styles]="fields.recurring_anniversary_grace_period_option.control.styles"
                        >
                        </Invoices-billing_recurring_grace_periods_dd_single>
                        <ng-container *ngIf="fields.recurring_anniversary_grace_period_option.invalid">
                          <ng-container *ngFor="let error of fields.recurring_anniversary_grace_period_option.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_advanced_billing" *ngIf="!fields.recurring_advanced_billing.hidden" 
                            class="field-container full {{fields.recurring_advanced_billing.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_advanced_billing.styles.style"
                            [ngClass]="fields.recurring_advanced_billing.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_advanced_billing.label}}{{fields.recurring_advanced_billing.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_advanced_billing.label}}">{{fields.recurring_advanced_billing.label}}<span *ngIf="fields.recurring_advanced_billing.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="recurring_advanced_billing"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.recurring_advanced_billing.control.styles.style"
                                      [ngClass]="fields.recurring_advanced_billing.control.styles.classes">{{fields.recurring_advanced_billing.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.recurring_advanced_billing.invalid">
                          <ng-container *ngFor="let error of fields.recurring_advanced_billing.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_advanced_grace_period" *ngIf="!fields.recurring_advanced_grace_period.hidden" 
                            class="field-container standard {{fields.recurring_advanced_grace_period.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_advanced_grace_period.styles.style"
                            [ngClass]="fields.recurring_advanced_grace_period.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_advanced_grace_period.label}}{{fields.recurring_advanced_grace_period.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_advanced_grace_period.label}}">{{fields.recurring_advanced_grace_period.label}}<span *ngIf="fields.recurring_advanced_grace_period.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="recurring_advanced_grace_period"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.recurring_advanced_grace_period.control.styles.style"
                                      [ngClass]="fields.recurring_advanced_grace_period.control.styles.classes">{{fields.recurring_advanced_grace_period.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.recurring_advanced_grace_period.invalid">
                          <ng-container *ngFor="let error of fields.recurring_advanced_grace_period.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_advanced_grace_period_value" *ngIf="!fields.recurring_advanced_grace_period_value.hidden" 
                            class="field-container standard {{fields.recurring_advanced_grace_period_value.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_advanced_grace_period_value.styles.style"
                            [ngClass]="fields.recurring_advanced_grace_period_value.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_advanced_grace_period_value.label}}{{fields.recurring_advanced_grace_period_value.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_advanced_grace_period_value.label}}">{{fields.recurring_advanced_grace_period_value.label}}<span *ngIf="fields.recurring_advanced_grace_period_value.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="recurring_advanced_grace_period_value"
                                matInput
                                numberBox
                                [format]="fields.recurring_advanced_grace_period_value.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.recurring_advanced_grace_period_value.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.recurring_advanced_grace_period_value.control.placeholder}}"
                                [ngStyle]="fields.recurring_advanced_grace_period_value.control.styles.style"
                                [ngClass]="fields.recurring_advanced_grace_period_value.control.styles.classes">
                        <ng-container *ngIf="fields.recurring_advanced_grace_period_value.invalid">
                          <ng-container *ngFor="let error of fields.recurring_advanced_grace_period_value.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_advanced_grace_period_option" *ngIf="!fields.recurring_advanced_grace_period_option.hidden" 
                            class="field-container standard {{fields.recurring_advanced_grace_period_option.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_advanced_grace_period_option.styles.style"
                            [ngClass]="fields.recurring_advanced_grace_period_option.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_advanced_grace_period_option.label}}{{fields.recurring_advanced_grace_period_option.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_advanced_grace_period_option.label}}">{{fields.recurring_advanced_grace_period_option.label}}<span *ngIf="fields.recurring_advanced_grace_period_option.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_recurring_grace_periods_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_advanced_grace_period_option.control.type"
                            formControlName="recurring_advanced_grace_period_option"
                            (displayTextChange)="fields.recurring_advanced_grace_period_option.control.displayText=$event"
                            [placeholder]="fields.recurring_advanced_grace_period_option.control.placeholder"
                            [styles]="fields.recurring_advanced_grace_period_option.control.styles"
                        >
                        </Invoices-billing_recurring_grace_periods_dd_single>
                        <ng-container *ngIf="fields.recurring_advanced_grace_period_option.invalid">
                          <ng-container *ngFor="let error of fields.recurring_advanced_grace_period_option.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-billing_methods"
                     *ngIf="!fieldsets.billing_methods.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.billing_methods.collapsible }">
                    <div *ngIf="!fieldsets.billing_methods.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.billing_methods.toggle()">
                      <span class="fieldsetsTitle-text">Billing methods</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.billing_methods.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.billing_methods.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.billing_methods.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.billing_methods.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-billing_object" *ngIf="!fields.billing_object.hidden" 
                            class="field-container full {{fields.billing_object.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_object.styles.style"
                            [ngClass]="fields.billing_object.styles.classes">
                        <div class="label-container"
                              title="{{fields.billing_object.label}}{{fields.billing_object.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.billing_object.label}}">{{fields.billing_object.label}}<span *ngIf="fields.billing_object.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_objects_dd_single 
                            data-cy="selector"
                            [type]="fields.billing_object.control.type"
                            formControlName="billing_object"
                            (displayTextChange)="fields.billing_object.control.displayText=$event"
                            [placeholder]="fields.billing_object.control.placeholder"
                            [styles]="fields.billing_object.control.styles"
                        >
                        </Invoices-billing_objects_dd_single>
                        <ng-container *ngIf="fields.billing_object.invalid">
                          <ng-container *ngFor="let error of fields.billing_object.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-billing_method" *ngIf="!fields.billing_method.hidden" 
                            class="field-container standard {{fields.billing_method.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.billing_method.styles.style"
                            [ngClass]="fields.billing_method.styles.classes">
                        <div class="label-container"
                              title="{{fields.billing_method.label}}{{fields.billing_method.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.billing_method.label}}">{{fields.billing_method.label}}<span *ngIf="fields.billing_method.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_methods_dd_single 
                            data-cy="selector"
                            [type]="fields.billing_method.control.type"
                            formControlName="billing_method"
                            (displayTextChange)="fields.billing_method.control.displayText=$event"
                            [placeholder]="fields.billing_method.control.placeholder"
                            [styles]="fields.billing_method.control.styles"
                          [idsToInclude]="$fields_billing_method_selector_inParams_idsToInclude"
                        >
                        </Invoices-billing_methods_dd_single>
                        <ng-container *ngIf="fields.billing_method.invalid">
                          <ng-container *ngFor="let error of fields.billing_method.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-resulting_uom_bm" *ngIf="!fields.resulting_uom_bm.hidden" 
                            class="field-container standard {{fields.resulting_uom_bm.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.resulting_uom_bm.styles.style"
                            [ngClass]="fields.resulting_uom_bm.styles.classes">
                        <div class="label-container"
                              title="{{fields.resulting_uom_bm.label}}{{fields.resulting_uom_bm.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.resulting_uom_bm.label}}">{{fields.resulting_uom_bm.label}}<span *ngIf="fields.resulting_uom_bm.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.resulting_uom_bm.control.type"
                            formControlName="resulting_uom_bm"
                            (displayTextChange)="fields.resulting_uom_bm.control.displayText=$event"
                            [placeholder]="fields.resulting_uom_bm.control.placeholder"
                            [styles]="fields.resulting_uom_bm.control.styles"
                          [id]="$fields_resulting_uom_bm_selector_inParams_id"
                          [typeId]="$fields_resulting_uom_bm_selector_inParams_typeId"
                        >
                        </Invoices-measurement_units_dd_single>
                        <ng-container *ngIf="fields.resulting_uom_bm.invalid">
                          <ng-container *ngFor="let error of fields.resulting_uom_bm.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-enable_divide_by" *ngIf="!fields.enable_divide_by.hidden" 
                            class="field-container standard {{fields.enable_divide_by.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.enable_divide_by.styles.style"
                            [ngClass]="fields.enable_divide_by.styles.classes">
                        <div class="label-container"
                              title="{{fields.enable_divide_by.label}}{{fields.enable_divide_by.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.enable_divide_by.label}}">{{fields.enable_divide_by.label}}<span *ngIf="fields.enable_divide_by.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="enable_divide_by"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.enable_divide_by.control.styles.style"
                                      [ngClass]="fields.enable_divide_by.control.styles.classes">{{fields.enable_divide_by.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.enable_divide_by.invalid">
                          <ng-container *ngFor="let error of fields.enable_divide_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-divide_by" *ngIf="!fields.divide_by.hidden" 
                            class="field-container standard {{fields.divide_by.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.divide_by.styles.style"
                            [ngClass]="fields.divide_by.styles.classes">
                        <div class="label-container"
                              title="{{fields.divide_by.label}}{{fields.divide_by.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.divide_by.label}}">{{fields.divide_by.label}}<span *ngIf="fields.divide_by.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="divide_by"
                                matInput
                                numberBox
                                [format]="fields.divide_by.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.divide_by.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.divide_by.control.placeholder}}"
                                [ngStyle]="fields.divide_by.control.styles.style"
                                [ngClass]="fields.divide_by.control.styles.classes">
                        <ng-container *ngIf="fields.divide_by.invalid">
                          <ng-container *ngFor="let error of fields.divide_by.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-static_quantity" *ngIf="!fields.static_quantity.hidden" 
                            class="field-container standard {{fields.static_quantity.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.static_quantity.styles.style"
                            [ngClass]="fields.static_quantity.styles.classes">
                        <div class="label-container"
                              title="{{fields.static_quantity.label}}{{fields.static_quantity.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.static_quantity.label}}">{{fields.static_quantity.label}}<span *ngIf="fields.static_quantity.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="static_quantity"
                                matInput
                                numberBox
                                [format]="fields.static_quantity.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.static_quantity.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.static_quantity.control.placeholder}}"
                                [ngStyle]="fields.static_quantity.control.styles.style"
                                [ngClass]="fields.static_quantity.control.styles.classes">
                        <ng-container *ngIf="fields.static_quantity.invalid">
                          <ng-container *ngFor="let error of fields.static_quantity.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-enable_weight_measure" *ngIf="!fields.enable_weight_measure.hidden" 
                            class="field-container standard {{fields.enable_weight_measure.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.enable_weight_measure.styles.style"
                            [ngClass]="fields.enable_weight_measure.styles.classes">
                        <div class="label-container"
                              title="{{fields.enable_weight_measure.label}}{{fields.enable_weight_measure.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.enable_weight_measure.label}}">{{fields.enable_weight_measure.label}}<span *ngIf="fields.enable_weight_measure.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-slide-toggle data-cy="slideToggle" formControlName="enable_weight_measure"
                                      color="primary"
                                      class="datex-toggle"
                                      [ngStyle]="fields.enable_weight_measure.control.styles.style"
                                      [ngClass]="fields.enable_weight_measure.control.styles.classes">{{fields.enable_weight_measure.control.label}}</mat-slide-toggle>
                        <ng-container *ngIf="fields.enable_weight_measure.invalid">
                          <ng-container *ngFor="let error of fields.enable_weight_measure.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dim_factor" *ngIf="!fields.dim_factor.hidden" 
                            class="field-container standard {{fields.dim_factor.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dim_factor.styles.style"
                            [ngClass]="fields.dim_factor.styles.classes">
                        <div class="label-container"
                              title="{{fields.dim_factor.label}}{{fields.dim_factor.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dim_factor.label}}">{{fields.dim_factor.label}}<span *ngIf="fields.dim_factor.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="dim_factor"
                                matInput
                                numberBox
                                [format]="fields.dim_factor.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.dim_factor.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.dim_factor.control.placeholder}}"
                                [ngStyle]="fields.dim_factor.control.styles.style"
                                [ngClass]="fields.dim_factor.control.styles.classes">
                        <ng-container *ngIf="fields.dim_factor.invalid">
                          <ng-container *ngFor="let error of fields.dim_factor.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-volume_uom" *ngIf="!fields.volume_uom.hidden" 
                            class="field-container standard {{fields.volume_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.volume_uom.styles.style"
                            [ngClass]="fields.volume_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.volume_uom.label}}{{fields.volume_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.volume_uom.label}}">{{fields.volume_uom.label}}<span *ngIf="fields.volume_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.volume_uom.control.type"
                            formControlName="volume_uom"
                            (displayTextChange)="fields.volume_uom.control.displayText=$event"
                            [placeholder]="fields.volume_uom.control.placeholder"
                            [styles]="fields.volume_uom.control.styles"
                          [typeId]="$fields_volume_uom_selector_inParams_typeId"
                        >
                        </Invoices-measurement_units_dd_single>
                        <ng-container *ngIf="fields.volume_uom.invalid">
                          <ng-container *ngFor="let error of fields.volume_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-grace_period_billing"
                     *ngIf="!fieldsets.grace_period_billing.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.grace_period_billing.collapsible }">
                    <div *ngIf="!fieldsets.grace_period_billing.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.grace_period_billing.toggle()">
                      <span class="fieldsetsTitle-text">Grace period billing</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.grace_period_billing.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.grace_period_billing.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.grace_period_billing.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.grace_period_billing.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-days_remaining" *ngIf="!fields.days_remaining.hidden" 
                            class="field-container full {{fields.days_remaining.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.days_remaining.styles.style"
                            [ngClass]="fields.days_remaining.styles.classes">
                        <div class="label-container"
                              title="{{fields.days_remaining.label}}{{fields.days_remaining.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.days_remaining.label}}">{{fields.days_remaining.label}}<span *ngIf="fields.days_remaining.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_days_remaining_dd_single 
                            data-cy="selector"
                            [type]="fields.days_remaining.control.type"
                            formControlName="days_remaining"
                            (displayTextChange)="fields.days_remaining.control.displayText=$event"
                            [placeholder]="fields.days_remaining.control.placeholder"
                            [styles]="fields.days_remaining.control.styles"
                        >
                        </Invoices-billing_days_remaining_dd_single>
                        <ng-container *ngIf="fields.days_remaining.invalid">
                          <ng-container *ngFor="let error of fields.days_remaining.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-initiating_date" *ngIf="!fields.initiating_date.hidden" 
                            class="field-container full {{fields.initiating_date.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.initiating_date.styles.style"
                            [ngClass]="fields.initiating_date.styles.classes">
                        <div class="label-container"
                              title="{{fields.initiating_date.label}}{{fields.initiating_date.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.initiating_date.label}}">{{fields.initiating_date.label}}<span *ngIf="fields.initiating_date.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_initiating_date_dd_single 
                            data-cy="selector"
                            [type]="fields.initiating_date.control.type"
                            formControlName="initiating_date"
                            (displayTextChange)="fields.initiating_date.control.displayText=$event"
                            [placeholder]="fields.initiating_date.control.placeholder"
                            [styles]="fields.initiating_date.control.styles"
                        >
                        </Invoices-billing_initiating_date_dd_single>
                        <ng-container *ngIf="fields.initiating_date.invalid">
                          <ng-container *ngFor="let error of fields.initiating_date.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-grace_period" *ngIf="!fields.grace_period.hidden" 
                            class="field-container full {{fields.grace_period.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.grace_period.styles.style"
                            [ngClass]="fields.grace_period.styles.classes">
                        <div class="label-container"
                              title="{{fields.grace_period.label}}{{fields.grace_period.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.grace_period.label}}">{{fields.grace_period.label}}<span *ngIf="fields.grace_period.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_grace_periods_dd_single 
                            data-cy="selector"
                            [type]="fields.grace_period.control.type"
                            formControlName="grace_period"
                            (displayTextChange)="fields.grace_period.control.displayText=$event"
                            [placeholder]="fields.grace_period.control.placeholder"
                            [styles]="fields.grace_period.control.styles"
                        >
                        </Invoices-billing_grace_periods_dd_single>
                        <ng-container *ngIf="fields.grace_period.invalid">
                          <ng-container *ngFor="let error of fields.grace_period.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-grace_period_value" *ngIf="!fields.grace_period_value.hidden" 
                            class="field-container standard {{fields.grace_period_value.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.grace_period_value.styles.style"
                            [ngClass]="fields.grace_period_value.styles.classes">
                        <div class="label-container"
                              title="{{fields.grace_period_value.label}}{{fields.grace_period_value.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.grace_period_value.label}}">{{fields.grace_period_value.label}}<span *ngIf="fields.grace_period_value.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="grace_period_value"
                                matInput
                                numberBox
                                [format]="fields.grace_period_value.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.grace_period_value.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.grace_period_value.control.placeholder}}"
                                [ngStyle]="fields.grace_period_value.control.styles.style"
                                [ngClass]="fields.grace_period_value.control.styles.classes">
                        <ng-container *ngIf="fields.grace_period_value.invalid">
                          <ng-container *ngFor="let error of fields.grace_period_value.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-material_information"
                     *ngIf="!fieldsets.material_information.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.material_information.collapsible }">
                    <div *ngIf="!fieldsets.material_information.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.material_information.toggle()">
                      <span class="fieldsetsTitle-text">Material information</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.material_information.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.material_information.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.material_information.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.material_information.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-material_group" *ngIf="!fields.material_group.hidden" 
                            class="field-container standard {{fields.material_group.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_group.styles.style"
                            [ngClass]="fields.material_group.styles.classes">
                        <div class="label-container"
                              title="{{fields.material_group.label}}{{fields.material_group.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material_group.label}}">{{fields.material_group.label}}<span *ngIf="fields.material_group.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_groups_dd_single 
                            data-cy="selector"
                            [type]="fields.material_group.control.type"
                            formControlName="material_group"
                            (displayTextChange)="fields.material_group.control.displayText=$event"
                            [placeholder]="fields.material_group.control.placeholder"
                            [styles]="fields.material_group.control.styles"
                        >
                        </Materials-material_groups_dd_single>
                        <ng-container *ngIf="fields.material_group.invalid">
                          <ng-container *ngFor="let error of fields.material_group.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_package" *ngIf="!fields.material_package.hidden" 
                            class="field-container standard {{fields.material_package.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_package.styles.style"
                            [ngClass]="fields.material_package.styles.classes">
                        <div class="label-container"
                              title="{{fields.material_package.label}}{{fields.material_package.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material_package.label}}">{{fields.material_package.label}}<span *ngIf="fields.material_package.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.material_package.control.type"
                            formControlName="material_package"
                            (displayTextChange)="fields.material_package.control.displayText=$event"
                            [placeholder]="fields.material_package.control.placeholder"
                            [styles]="fields.material_package.control.styles"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.material_package.invalid">
                          <ng-container *ngFor="let error of fields.material_package.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-material_tags" *ngIf="!fields.material_tags.hidden" 
                            class="field-container standard {{fields.material_tags.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material_tags.styles.style"
                            [ngClass]="fields.material_tags.styles.classes">
                        <div class="label-container"
                              title="{{fields.material_tags.label}}{{fields.material_tags.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material_tags.label}}">{{fields.material_tags.label}}<span *ngIf="fields.material_tags.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-tags_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.material_tags.control.type"
                            formControlName="material_tags"
                            (displayTextChange)="fields.material_tags.control.displayText=$event"
                            [placeholder]="fields.material_tags.control.placeholder"
                            [styles]="fields.material_tags.control.styles"
                          [entityTypeId]="$fields_material_tags_selector_inParams_entityTypeId"
                        >
                        </Invoices-tags_dd_multi>
                        <ng-container *ngIf="fields.material_tags.invalid">
                          <ng-container *ngFor="let error of fields.material_tags.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-location_information"
                     *ngIf="!fieldsets.location_information.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.location_information.collapsible }">
                    <div *ngIf="!fieldsets.location_information.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.location_information.toggle()">
                      <span class="fieldsetsTitle-text">Location information</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.location_information.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.location_information.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.location_information.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.location_information.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-location_tags" *ngIf="!fields.location_tags.hidden" 
                            class="field-container standard {{fields.location_tags.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.location_tags.styles.style"
                            [ngClass]="fields.location_tags.styles.classes">
                        <div class="label-container"
                              title="{{fields.location_tags.label}}{{fields.location_tags.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.location_tags.label}}">{{fields.location_tags.label}}<span *ngIf="fields.location_tags.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-tags_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.location_tags.control.type"
                            formControlName="location_tags"
                            (displayTextChange)="fields.location_tags.control.displayText=$event"
                            [placeholder]="fields.location_tags.control.placeholder"
                            [styles]="fields.location_tags.control.styles"
                          [entityTypeId]="$fields_location_tags_selector_inParams_entityTypeId"
                        >
                        </Invoices-tags_dd_multi>
                        <ng-container *ngIf="fields.location_tags.invalid">
                          <ng-container *ngFor="let error of fields.location_tags.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-recurring_location_storage" *ngIf="!fields.recurring_location_storage.hidden" 
                            class="field-container standard {{fields.recurring_location_storage.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.recurring_location_storage.styles.style"
                            [ngClass]="fields.recurring_location_storage.styles.classes">
                        <div class="label-container"
                              title="{{fields.recurring_location_storage.label}}{{fields.recurring_location_storage.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.recurring_location_storage.label}}">{{fields.recurring_location_storage.label}}<span *ngIf="fields.recurring_location_storage.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-location_storage_categories_dd_single 
                            data-cy="selector"
                            [type]="fields.recurring_location_storage.control.type"
                            formControlName="recurring_location_storage"
                            (displayTextChange)="fields.recurring_location_storage.control.displayText=$event"
                            [placeholder]="fields.recurring_location_storage.control.placeholder"
                            [styles]="fields.recurring_location_storage.control.styles"
                        >
                        </Invoices-location_storage_categories_dd_single>
                        <ng-container *ngIf="fields.recurring_location_storage.invalid">
                          <ng-container *ngFor="let error of fields.recurring_location_storage.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-carrier_billing_methods"
                     *ngIf="!fieldsets.carrier_billing_methods.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.carrier_billing_methods.collapsible }">
                    <div *ngIf="!fieldsets.carrier_billing_methods.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.carrier_billing_methods.toggle()">
                      <span class="fieldsetsTitle-text">Carrier billing methods</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.carrier_billing_methods.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.carrier_billing_methods.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.carrier_billing_methods.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.carrier_billing_methods.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-service_type_method" *ngIf="!fields.service_type_method.hidden" 
                            class="field-container full {{fields.service_type_method.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.service_type_method.styles.style"
                            [ngClass]="fields.service_type_method.styles.classes">
                        <div class="label-container"
                              title="{{fields.service_type_method.label}}{{fields.service_type_method.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.service_type_method.label}}">{{fields.service_type_method.label}}<span *ngIf="fields.service_type_method.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_outbound_shipment_service_type_methods_dd_single 
                            data-cy="selector"
                            [type]="fields.service_type_method.control.type"
                            formControlName="service_type_method"
                            (displayTextChange)="fields.service_type_method.control.displayText=$event"
                            [placeholder]="fields.service_type_method.control.placeholder"
                            [styles]="fields.service_type_method.control.styles"
                        >
                        </Invoices-billing_outbound_shipment_service_type_methods_dd_single>
                        <ng-container *ngIf="fields.service_type_method.invalid">
                          <ng-container *ngFor="let error of fields.service_type_method.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_service_type" *ngIf="!fields.carrier_service_type.hidden" 
                            class="field-container standard {{fields.carrier_service_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier_service_type.styles.style"
                            [ngClass]="fields.carrier_service_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier_service_type.label}}{{fields.carrier_service_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier_service_type.label}}">{{fields.carrier_service_type.label}}<span *ngIf="fields.carrier_service_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-carrier_service_types_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier_service_type.control.type"
                            formControlName="carrier_service_type"
                            (displayTextChange)="fields.carrier_service_type.control.displayText=$event"
                            [placeholder]="fields.carrier_service_type.control.placeholder"
                            [styles]="fields.carrier_service_type.control.styles"
                        >
                        </Invoices-carrier_service_types_dd_single>
                        <ng-container *ngIf="fields.carrier_service_type.invalid">
                          <ng-container *ngFor="let error of fields.carrier_service_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-carrier_package" *ngIf="!fields.carrier_package.hidden" 
                            class="field-container standard {{fields.carrier_package.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.carrier_package.styles.style"
                            [ngClass]="fields.carrier_package.styles.classes">
                        <div class="label-container"
                              title="{{fields.carrier_package.label}}{{fields.carrier_package.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.carrier_package.label}}">{{fields.carrier_package.label}}<span *ngIf="fields.carrier_package.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Materials-material_packagings_dd_single 
                            data-cy="selector"
                            [type]="fields.carrier_package.control.type"
                            formControlName="carrier_package"
                            (displayTextChange)="fields.carrier_package.control.displayText=$event"
                            [placeholder]="fields.carrier_package.control.placeholder"
                            [styles]="fields.carrier_package.control.styles"
                        >
                        </Materials-material_packagings_dd_single>
                        <ng-container *ngIf="fields.carrier_package.invalid">
                          <ng-container *ngFor="let error of fields.carrier_package.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-retainer_billing"
                     *ngIf="!fieldsets.retainer_billing.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.retainer_billing.collapsible }">
                    <div *ngIf="!fieldsets.retainer_billing.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.retainer_billing.toggle()">
                      <span class="fieldsetsTitle-text">Retainer billing</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.retainer_billing.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.retainer_billing.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.retainer_billing.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.retainer_billing.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-retainer_amount" *ngIf="!fields.retainer_amount.hidden" 
                            class="field-container standard {{fields.retainer_amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_amount.styles.style"
                            [ngClass]="fields.retainer_amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_amount.label}}{{fields.retainer_amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_amount.label}}">{{fields.retainer_amount.label}}<span *ngIf="fields.retainer_amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="retainer_amount"
                                matInput
                                numberBox
                                [format]="fields.retainer_amount.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.retainer_amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.retainer_amount.control.placeholder}}"
                                [ngStyle]="fields.retainer_amount.control.styles.style"
                                [ngClass]="fields.retainer_amount.control.styles.classes">
                        <ng-container *ngIf="fields.retainer_amount.invalid">
                          <ng-container *ngFor="let error of fields.retainer_amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_rate" *ngIf="!fields.retainer_rate.hidden" 
                            class="field-container standard {{fields.retainer_rate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_rate.styles.style"
                            [ngClass]="fields.retainer_rate.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_rate.label}}{{fields.retainer_rate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_rate.label}}">{{fields.retainer_rate.label}}<span *ngIf="fields.retainer_rate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="retainer_rate"
                                matInput
                                numberBox
                                [format]="fields.retainer_rate.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.retainer_rate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.retainer_rate.control.placeholder}}"
                                [ngStyle]="fields.retainer_rate.control.styles.style"
                                [ngClass]="fields.retainer_rate.control.styles.classes">
                        <ng-container *ngIf="fields.retainer_rate.invalid">
                          <ng-container *ngFor="let error of fields.retainer_rate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_frequency" *ngIf="!fields.retainer_frequency.hidden" 
                            class="field-container full {{fields.retainer_frequency.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_frequency.styles.style"
                            [ngClass]="fields.retainer_frequency.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_frequency.label}}{{fields.retainer_frequency.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_frequency.label}}">{{fields.retainer_frequency.label}}<span *ngIf="fields.retainer_frequency.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_frequency_dd_single 
                            data-cy="selector"
                            [type]="fields.retainer_frequency.control.type"
                            formControlName="retainer_frequency"
                            (displayTextChange)="fields.retainer_frequency.control.displayText=$event"
                            [placeholder]="fields.retainer_frequency.control.placeholder"
                            [styles]="fields.retainer_frequency.control.styles"
                        >
                        </Invoices-billing_frequency_dd_single>
                        <ng-container *ngIf="fields.retainer_frequency.invalid">
                          <ng-container *ngFor="let error of fields.retainer_frequency.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_day_interval" *ngIf="!fields.retainer_day_interval.hidden" 
                            class="field-container standard {{fields.retainer_day_interval.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_day_interval.styles.style"
                            [ngClass]="fields.retainer_day_interval.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_day_interval.label}}{{fields.retainer_day_interval.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_day_interval.label}}">{{fields.retainer_day_interval.label}}<span *ngIf="fields.retainer_day_interval.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="retainer_day_interval"
                                matInput
                                numberBox
                                [format]="fields.retainer_day_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.retainer_day_interval.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.retainer_day_interval.control.placeholder}}"
                                [ngStyle]="fields.retainer_day_interval.control.styles.style"
                                [ngClass]="fields.retainer_day_interval.control.styles.classes">
                        <ng-container *ngIf="fields.retainer_day_interval.invalid">
                          <ng-container *ngFor="let error of fields.retainer_day_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_week_interval" *ngIf="!fields.retainer_week_interval.hidden" 
                            class="field-container standard {{fields.retainer_week_interval.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_week_interval.styles.style"
                            [ngClass]="fields.retainer_week_interval.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_week_interval.label}}{{fields.retainer_week_interval.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_week_interval.label}}">{{fields.retainer_week_interval.label}}<span *ngIf="fields.retainer_week_interval.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="retainer_week_interval"
                                matInput
                                numberBox
                                [format]="fields.retainer_week_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.retainer_week_interval.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.retainer_week_interval.control.placeholder}}"
                                [ngStyle]="fields.retainer_week_interval.control.styles.style"
                                [ngClass]="fields.retainer_week_interval.control.styles.classes">
                        <ng-container *ngIf="fields.retainer_week_interval.invalid">
                          <ng-container *ngFor="let error of fields.retainer_week_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_days" *ngIf="!fields.retainer_days.hidden" 
                            class="field-container full {{fields.retainer_days.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_days.styles.style"
                            [ngClass]="fields.retainer_days.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_days.label}}{{fields.retainer_days.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_days.label}}">{{fields.retainer_days.label}}<span *ngIf="fields.retainer_days.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Invoices-billing_weekdays_dd_multi 
                            data-cy="selector-multi"
                            [type]="fields.retainer_days.control.type"
                            formControlName="retainer_days"
                            (displayTextChange)="fields.retainer_days.control.displayText=$event"
                            [placeholder]="fields.retainer_days.control.placeholder"
                            [styles]="fields.retainer_days.control.styles"
                        >
                        </Invoices-billing_weekdays_dd_multi>
                        <ng-container *ngIf="fields.retainer_days.invalid">
                          <ng-container *ngFor="let error of fields.retainer_days.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_month_day" *ngIf="!fields.retainer_month_day.hidden" 
                            class="field-container standard {{fields.retainer_month_day.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_month_day.styles.style"
                            [ngClass]="fields.retainer_month_day.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_month_day.label}}{{fields.retainer_month_day.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_month_day.label}}">{{fields.retainer_month_day.label}}<span *ngIf="fields.retainer_month_day.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="retainer_month_day"
                                matInput
                                numberBox
                                [format]="fields.retainer_month_day.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.retainer_month_day.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.retainer_month_day.control.placeholder}}"
                                [ngStyle]="fields.retainer_month_day.control.styles.style"
                                [ngClass]="fields.retainer_month_day.control.styles.classes">
                        <ng-container *ngIf="fields.retainer_month_day.invalid">
                          <ng-container *ngFor="let error of fields.retainer_month_day.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_month_interval" *ngIf="!fields.retainer_month_interval.hidden" 
                            class="field-container standard {{fields.retainer_month_interval.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_month_interval.styles.style"
                            [ngClass]="fields.retainer_month_interval.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_month_interval.label}}{{fields.retainer_month_interval.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_month_interval.label}}">{{fields.retainer_month_interval.label}}<span *ngIf="fields.retainer_month_interval.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="retainer_month_interval"
                                matInput
                                numberBox
                                [format]="fields.retainer_month_interval.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.retainer_month_interval.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.retainer_month_interval.control.placeholder}}"
                                [ngStyle]="fields.retainer_month_interval.control.styles.style"
                                [ngClass]="fields.retainer_month_interval.control.styles.classes">
                        <ng-container *ngIf="fields.retainer_month_interval.invalid">
                          <ng-container *ngFor="let error of fields.retainer_month_interval.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-retainer_time" *ngIf="!fields.retainer_time.hidden" 
                            class="field-container standard {{fields.retainer_time.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.retainer_time.styles.style"
                            [ngClass]="fields.retainer_time.styles.classes">
                        <div class="label-container"
                              title="{{fields.retainer_time.label}}{{fields.retainer_time.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.retainer_time.label}}">{{fields.retainer_time.label}}<span *ngIf="fields.retainer_time.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <FootPrintManager-hourly_options_dd_single 
                            data-cy="selector"
                            [type]="fields.retainer_time.control.type"
                            formControlName="retainer_time"
                            (displayTextChange)="fields.retainer_time.control.displayText=$event"
                            [placeholder]="fields.retainer_time.control.placeholder"
                            [styles]="fields.retainer_time.control.styles"
                        >
                        </FootPrintManager-hourly_options_dd_single>
                        <ng-container *ngIf="fields.retainer_time.invalid">
                          <ng-container *ngFor="let error of fields.retainer_time.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-notes"
                     *ngIf="!fieldsets.notes.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.notes.collapsible }">
                    <div *ngIf="!fieldsets.notes.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.notes.toggle()">
                      <span class="fieldsetsTitle-text">Notes</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.notes.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.notes.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.notes.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.notes.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-notes" *ngIf="!fields.notes.hidden" 
                            class="field-container full {{fields.notes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.notes.styles.style"
                            [ngClass]="fields.notes.styles.classes">
                        <div class="label-container"
                              title="{{fields.notes.label}}{{fields.notes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.notes.label}}">{{fields.notes.label}}<span *ngIf="fields.notes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <textarea cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="5" data-cy="textBox-multi"formControlName="notes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.notes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.notes.control.placeholder}}"
                                [ngStyle]="fields.notes.control.styles.style"
                                [ngClass]="fields.notes.control.styles.classes"> </textarea>
                        <ng-container *ngIf="fields.notes.invalid">
                          <ng-container *ngFor="let error of fields.notes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

        <div class="datex-tabcontrol" *ngIf="!this.rootTabGroup.allTabsHidden">
          <div class="tab-container">
            <div *ngIf="!tabs.billing_rates.hidden" class="tab" data-cy="tab-billing_rates">
              <h2 [className]="tabs.billing_rates.active? 'active': ''" (click)="tabs.billing_rates.activate()">{{tabs.billing_rates.title}}</h2>
            </div>
            <div *ngIf="!tabs.weight_rates.hidden" class="tab" data-cy="tab-weight_rates">
              <h2 [className]="tabs.weight_rates.active? 'active': ''" (click)="tabs.weight_rates.activate()">{{tabs.weight_rates.title}}</h2>
            </div>
            <div *ngIf="!tabs.volume_rates.hidden" class="tab" data-cy="tab-volume_rates">
              <h2 [className]="tabs.volume_rates.active? 'active': ''" (click)="tabs.volume_rates.activate()">{{tabs.volume_rates.title}}</h2>
            </div>
            <div *ngIf="!tabs.billing_projects.hidden" class="tab" data-cy="tab-billing_projects">
              <h2 [className]="tabs.billing_projects.active? 'active': ''" (click)="tabs.billing_projects.activate()">{{tabs.billing_projects.title}}</h2>
            </div>
            <div *ngIf="!tabs.supplemental_fees.hidden" class="tab" data-cy="tab-supplemental_fees">
              <h2 [className]="tabs.supplemental_fees.active? 'active': ''" (click)="tabs.supplemental_fees.activate()">{{tabs.supplemental_fees.title}}</h2>
            </div>
          </div>
        
              <FootPrintManager-billing_rates_grid *ngIf="tabs.billing_rates.active"
              #$tabs_billing_rates
              [billingContractLineId]="$tabs_billing_rates_billing_rates_grid_inParams_billingContractLineId"
              [edit]="$tabs_billing_rates_billing_rates_grid_inParams_edit"
              [typeId]="$tabs_billing_rates_billing_rates_grid_inParams_typeId"
              ($refreshEvent)="refresh(false, false, '$tabs_billing_rates')">
              </FootPrintManager-billing_rates_grid>
              <FootPrintManager-billing_rates_grid *ngIf="tabs.weight_rates.active"
              #$tabs_weight_rates
              [billingContractLineId]="$tabs_weight_rates_billing_rates_grid_inParams_billingContractLineId"
              [edit]="$tabs_weight_rates_billing_rates_grid_inParams_edit"
              [typeId]="$tabs_weight_rates_billing_rates_grid_inParams_typeId"
              ($refreshEvent)="refresh(false, false, '$tabs_weight_rates')">
              </FootPrintManager-billing_rates_grid>
              <FootPrintManager-billing_rates_grid *ngIf="tabs.volume_rates.active"
              #$tabs_volume_rates
              [billingContractLineId]="$tabs_volume_rates_billing_rates_grid_inParams_billingContractLineId"
              [edit]="$tabs_volume_rates_billing_rates_grid_inParams_edit"
              [typeId]="$tabs_volume_rates_billing_rates_grid_inParams_typeId"
              ($refreshEvent)="refresh(false, false, '$tabs_volume_rates')">
              </FootPrintManager-billing_rates_grid>
              <FootPrintManager-billing_projects_grid *ngIf="tabs.billing_projects.active"
              #$tabs_billing_projects
              [billingContractLineId]="$tabs_billing_projects_billing_projects_grid_inParams_billingContractLineId"
              [edit]="$tabs_billing_projects_billing_projects_grid_inParams_edit"
              ($refreshEvent)="refresh(false, false, '$tabs_billing_projects')">
              </FootPrintManager-billing_projects_grid>
              <FootPrintManager-billing_supplemental_fees_grid *ngIf="tabs.supplemental_fees.active"
              #$tabs_supplemental_fees
              [billingContractLineId]="$tabs_supplemental_fees_billing_supplemental_fees_grid_inParams_billingContractLineId"
              [edit]="$tabs_supplemental_fees_billing_supplemental_fees_grid_inParams_edit"
              ($refreshEvent)="refresh(false, false, '$tabs_supplemental_fees')">
              </FootPrintManager-billing_supplemental_fees_grid>
        </div>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>