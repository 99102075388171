<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-newGroup1"
                     *ngIf="!fieldsets.newGroup1.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup1.collapsible }">
                    <div *ngIf="!fieldsets.newGroup1.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup1.toggle()">
                      <span class="fieldsetsTitle-text"></span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup1.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup1.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup1.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup1.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-lookupcode" *ngIf="!fields.lookupcode.hidden" 
                            class="field-container double {{fields.lookupcode.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lookupcode.styles.style"
                            [ngClass]="fields.lookupcode.styles.classes">
                        <div class="label-container"
                              title="{{fields.lookupcode.label}}{{fields.lookupcode.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lookupcode.label}}">{{fields.lookupcode.label}}<span *ngIf="fields.lookupcode.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lookupcode"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lookupcode.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lookupcode.control.placeholder}}"
                                [ngStyle]="fields.lookupcode.control.styles.style"
                                [ngClass]="fields.lookupcode.control.styles.classes"> 
                        <ng-container *ngIf="fields.lookupcode.invalid">
                          <ng-container *ngFor="let error of fields.lookupcode.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container double {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-licenseplate_statuses_dd_single 
                            data-cy="selector"
                            [type]="fields.status.control.type"
                            formControlName="status"
                            (displayTextChange)="fields.status.control.displayText=$event"
                            [placeholder]="fields.status.control.placeholder"
                            [styles]="fields.status.control.styles"
                        >
                        </Locations-licenseplate_statuses_dd_single>
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-archived" *ngIf="!fields.archived.hidden" 
                            class="field-container double {{fields.archived.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.archived.styles.style"
                            [ngClass]="fields.archived.styles.classes">
                        <div class="label-container"
                              title="{{fields.archived.label}}{{fields.archived.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.archived.label}}">{{fields.archived.label}}<span *ngIf="fields.archived.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <mat-checkbox data-cy="checkBox" formControlName="archived"
                                      class="datex-checkbox"
                                      color="primary"
                                      [ngStyle]="fields.archived.control.styles.style"
                                      [ngClass]="fields.archived.control.styles.classes">{{fields.archived.control.label}}</mat-checkbox>
                        <ng-container *ngIf="fields.archived.invalid">
                          <ng-container *ngFor="let error of fields.archived.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-newGroup2"
                     *ngIf="!fieldsets.newGroup2.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.newGroup2.collapsible }">
                    <div *ngIf="!fieldsets.newGroup2.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.newGroup2.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.newGroup2.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.newGroup2.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.newGroup2.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.newGroup2.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-container_type" *ngIf="!fields.container_type.hidden" 
                            class="field-container full {{fields.container_type.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.container_type.styles.style"
                            [ngClass]="fields.container_type.styles.classes">
                        <div class="label-container"
                              title="{{fields.container_type.label}}{{fields.container_type.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.container_type.label}}">{{fields.container_type.label}}<span *ngIf="fields.container_type.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <ShippingContainers-container_type_dd_single 
                            data-cy="selector"
                            [type]="fields.container_type.control.type"
                            formControlName="container_type"
                            (displayTextChange)="fields.container_type.control.displayText=$event"
                            [placeholder]="fields.container_type.control.placeholder"
                            [styles]="fields.container_type.control.styles"
                          [ownerId]="$fields_container_type_selector_inParams_ownerId"
                        >
                        </ShippingContainers-container_type_dd_single>
                        <ng-container *ngIf="fields.container_type.invalid">
                          <ng-container *ngFor="let error of fields.container_type.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-length" *ngIf="!fields.length.hidden" 
                            class="field-container double {{fields.length.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.length.styles.style"
                            [ngClass]="fields.length.styles.classes">
                        <div class="label-container"
                              title="{{fields.length.label}}{{fields.length.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.length.label}}">{{fields.length.label}}<span *ngIf="fields.length.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.length.control.styles.style"
                              [ngClass]="fields.length.control.styles.classes">{{fields.length.control.text }}</div>
                        <ng-container *ngIf="fields.length.invalid">
                          <ng-container *ngFor="let error of fields.length.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-width" *ngIf="!fields.width.hidden" 
                            class="field-container double {{fields.width.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.width.styles.style"
                            [ngClass]="fields.width.styles.classes">
                        <div class="label-container"
                              title="{{fields.width.label}}{{fields.width.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.width.label}}">{{fields.width.label}}<span *ngIf="fields.width.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.width.control.styles.style"
                              [ngClass]="fields.width.control.styles.classes">{{fields.width.control.text }}</div>
                        <ng-container *ngIf="fields.width.invalid">
                          <ng-container *ngFor="let error of fields.width.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-height" *ngIf="!fields.height.hidden" 
                            class="field-container double {{fields.height.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.height.styles.style"
                            [ngClass]="fields.height.styles.classes">
                        <div class="label-container"
                              title="{{fields.height.label}}{{fields.height.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.height.label}}">{{fields.height.label}}<span *ngIf="fields.height.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.height.control.styles.style"
                              [ngClass]="fields.height.control.styles.classes">{{fields.height.control.text }}</div>
                        <ng-container *ngIf="fields.height.invalid">
                          <ng-container *ngFor="let error of fields.height.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-dimension_uom" *ngIf="!fields.dimension_uom.hidden" 
                            class="field-container double {{fields.dimension_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.dimension_uom.styles.style"
                            [ngClass]="fields.dimension_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.dimension_uom.label}}{{fields.dimension_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.dimension_uom.label}}">{{fields.dimension_uom.label}}<span *ngIf="fields.dimension_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.dimension_uom.control.type"
                            formControlName="dimension_uom"
                            (displayTextChange)="fields.dimension_uom.control.displayText=$event"
                            [placeholder]="fields.dimension_uom.control.placeholder"
                            [styles]="fields.dimension_uom.control.styles"
                          [typeId]="$fields_dimension_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.dimension_uom.invalid">
                          <ng-container *ngFor="let error of fields.dimension_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-net_weight" *ngIf="!fields.net_weight.hidden" 
                            class="field-container double {{fields.net_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.net_weight.styles.style"
                            [ngClass]="fields.net_weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.net_weight.label}}{{fields.net_weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.net_weight.label}}">{{fields.net_weight.label}}<span *ngIf="fields.net_weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.net_weight.control.styles.style"
                              [ngClass]="fields.net_weight.control.styles.classes">{{fields.net_weight.control.text }}</div>
                        <ng-container *ngIf="fields.net_weight.invalid">
                          <ng-container *ngFor="let error of fields.net_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-gross_weight" *ngIf="!fields.gross_weight.hidden" 
                            class="field-container double {{fields.gross_weight.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.gross_weight.styles.style"
                            [ngClass]="fields.gross_weight.styles.classes">
                        <div class="label-container"
                              title="{{fields.gross_weight.label}}{{fields.gross_weight.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.gross_weight.label}}">{{fields.gross_weight.label}}<span *ngIf="fields.gross_weight.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <div data-cy="text" class="datex-text"
                              [ngStyle]="fields.gross_weight.control.styles.style"
                              [ngClass]="fields.gross_weight.control.styles.classes">{{fields.gross_weight.control.text }}</div>
                        <ng-container *ngIf="fields.gross_weight.invalid">
                          <ng-container *ngFor="let error of fields.gross_weight.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-weight_uom" *ngIf="!fields.weight_uom.hidden" 
                            class="field-container double {{fields.weight_uom.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.weight_uom.styles.style"
                            [ngClass]="fields.weight_uom.styles.classes">
                        <div class="label-container"
                              title="{{fields.weight_uom.label}}{{fields.weight_uom.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.weight_uom.label}}">{{fields.weight_uom.label}}<span *ngIf="fields.weight_uom.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <Locations-measurement_units_dd_single 
                            data-cy="selector"
                            [type]="fields.weight_uom.control.type"
                            formControlName="weight_uom"
                            (displayTextChange)="fields.weight_uom.control.displayText=$event"
                            [placeholder]="fields.weight_uom.control.placeholder"
                            [styles]="fields.weight_uom.control.styles"
                          [typeId]="$fields_weight_uom_selector_inParams_typeId"
                        >
                        </Locations-measurement_units_dd_single>
                        <ng-container *ngIf="fields.weight_uom.invalid">
                          <ng-container *ngFor="let error of fields.weight_uom.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>