<datex-grid #$gridComponent *ngIf="initialized "  [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-pallet_transaction" *ngIf="!topToolbar.pallet_transaction.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.pallet_transaction.control.readOnly"
            [ngStyle]="topToolbar.pallet_transaction.control.styles.style"
            [ngClass]="topToolbar.pallet_transaction.control.styles.classes"
            (click)="on_create_pallet_transaction($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.pallet_transaction.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.pallet_transaction.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.pallet_transaction.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-aggregate" *ngIf="!filters.aggregate.hidden" 
            class="field-container standard {{filters.aggregate.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.aggregate.styles.style"
            [ngClass]="filters.aggregate.styles.classes">
        <div class="label-container"
              title="{{filters.aggregate.label}}{{filters.aggregate.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.aggregate.label}}">{{filters.aggregate.label}}<span *ngIf="filters.aggregate.required"
                  class="required-asterisk">*</span></label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="aggregate"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.aggregate.control.styles.style"
                      [ngClass]="filters.aggregate.control.styles.classes">{{filters.aggregate.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.aggregate.invalid">
          <ng-container *ngFor="let error of filters.aggregate.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="PalletClass">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.PalletClass.displayControl.styles.style"
          [ngClass]="row.cells.PalletClass.displayControl.styles.classes">{{row.cells.PalletClass.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <FootPrintManager-pallet_classes_dd_single 
        data-cy="selector"
        [type]="row.cells.PalletClass.editControl.type"
        [formControl]="row.formGroup.controls['PalletClass_edit']"
        (displayTextChange)="row.cells.PalletClass.editControl.displayText=$event"
        [placeholder]="row.cells.PalletClass.editControl.placeholder"
        [styles]="row.cells.PalletClass.editControl.styles"
      [project_id]="row.$fields_PalletClass_selector_inParams_project_id"
    >
    </FootPrintManager-pallet_classes_dd_single>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Amount.displayControl.styles.style"
          [ngClass]="row.cells.Amount.displayControl.styles.classes">{{row.cells.Amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['Amount_edit']"
            matInput
            numberBox
            [format]="row.cells.Amount.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.Amount.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Amount.editControl.placeholder}}"
            [ngStyle]="row.cells.Amount.editControl.styles.style"
            [ngClass]="row.cells.Amount.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Direction">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Direction.displayControl.styles.style"
          [ngClass]="row.cells.Direction.displayControl.styles.classes">{{row.cells.Direction.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="In">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.In.displayControl.styles.style"
          [ngClass]="row.cells.In.displayControl.styles.classes">{{row.cells.In.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['In_edit']"
            matInput
            numberBox
            [format]="row.cells.In.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.In.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.In.editControl.placeholder}}"
            [ngStyle]="row.cells.In.editControl.styles.style"
            [ngClass]="row.cells.In.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Out">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Out.displayControl.styles.style"
          [ngClass]="row.cells.Out.displayControl.styles.classes">{{row.cells.Out.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['Out_edit']"
            matInput
            numberBox
            [format]="row.cells.Out.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.Out.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Out.editControl.placeholder}}"
            [ngStyle]="row.cells.Out.editControl.styles.style"
            [ngClass]="row.cells.Out.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Reference">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Reference.displayControl.styles.style"
          [ngClass]="row.cells.Reference.displayControl.styles.classes">{{row.cells.Reference.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['Reference_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.Reference.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Reference.editControl.placeholder}}"
            [ngStyle]="row.cells.Reference.editControl.styles.style"
            [ngClass]="row.cells.Reference.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Notes">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Notes.displayControl.styles.style"
          [ngClass]="row.cells.Notes.displayControl.styles.classes">{{row.cells.Notes.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['Notes_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.Notes.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.Notes.editControl.placeholder}}"
            [ngStyle]="row.cells.Notes.editControl.styles.style"
            [ngClass]="row.cells.Notes.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Employee">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Employee.displayControl.styles.style"
          [ngClass]="row.cells.Employee.displayControl.styles.classes">{{row.cells.Employee.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="Date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.Date.displayControl.styles.style"
          [ngClass]="row.cells.Date.displayControl.styles.classes">{{row.cells.Date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
