import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './EntityImport.types'

@Injectable({ providedIn: 'root' })
export class EntityImport_get_entity_import_request_xml_chunk_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { request_id: string, page_number: number }): Promise<{ chunk?: string, total_chunks?: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.request_id)) {
      missingRequiredInParams.push('\'request_id\'');
    }
    if (isNil(inParams.page_number)) {
      missingRequiredInParams.push('\'page_number\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/EntityImport/functions/get_entity_import_request_xml_chunk_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

