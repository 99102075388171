import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './ExcelInventoryImport.types'

@Injectable({ providedIn: 'root' })
export class ExcelInventoryImport_format_date_stringService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { dateString: string, fromFormat: string, toFormat: string }): Promise<{ dateString?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.dateString)) {
      missingRequiredInParams.push('\'dateString\'');
    }
    if (isNil(inParams.fromFormat)) {
      missingRequiredInParams.push('\'fromFormat\'');
    }
    if (isNil(inParams.toFormat)) {
      missingRequiredInParams.push('\'toFormat\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/ExcelInventoryImport/functions/format_date_string`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

