import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'


interface IFootPrintManager_inventory_lot_snapshot_gridComponentEntity {
id?: string, captureDate?: string, materialId?: number, packagingId?: number, warehouseId?: number, totalPackagedAmount?: number, totalBaseAmount?: number, projectId?: number, materialLookupcode?: string, packagingShortName?: string, warehouseName?: string, totalNetWeight?: number, totalGrossWeight?: number, weightShortName?: string, weightUomId?: number, lotId?: number, lotLookupcode?: string, receiveDate?: string, lastReceiveDate?: string}

interface IFootPrintManager_inventory_lot_snapshot_gridComponentInParams {
  captureDate: string, projectId: number, warehouseId: number, materialIds?: number[]}


class FootPrintManager_inventory_lot_snapshot_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_inventory_lot_snapshot_gridComponent;
  entity: IFootPrintManager_inventory_lot_snapshot_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    captureDate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null , 'datetime', 'MM/DD/YYYY')
,
null
      ),
    material: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    lot: new GridCellModel(
      new CellStyles(['grid-table-cell-link'], null),
      new TextModel(null, null )
,
null
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    warehouse: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total_packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total_base_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total_net_weight: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    total_gross_weight: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    weight_uom: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    receive_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    last_receive_date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: FootPrintManager_inventory_lot_snapshot_gridComponent, entity: IFootPrintManager_inventory_lot_snapshot_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_inventory_lot_snapshot_gridComponent, entity?: IFootPrintManager_inventory_lot_snapshot_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.id;
    const inParams = {
      $keys:[$resultKey],
      fullTextSearch:  null ,
      captureDate:  $grid.inParams.captureDate ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
    };
    const data = await this.datasources.Inventory.ds_inventory_lot_snapshot_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.captureDate.displayControl as TextModel).text = $row.entity.captureDate;
    (this.cells.material.displayControl as TextModel).text = $row.entity.materialLookupcode;
    (this.cells.lot.displayControl as TextModel).text = $row.entity.lotLookupcode;
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.packagingShortName;
    (this.cells.warehouse.displayControl as TextModel).text = $row.entity.warehouseName;
    (this.cells.total_packaged_amount.displayControl as TextModel).text = $row.entity.totalPackagedAmount?.toString();
    (this.cells.total_base_amount.displayControl as TextModel).text = $row.entity.totalBaseAmount?.toString();
    (this.cells.total_net_weight.displayControl as TextModel).text = $row.entity.totalNetWeight?.toString();
    (this.cells.total_gross_weight.displayControl as TextModel).text = $row.entity.totalGrossWeight?.toString();
    (this.cells.weight_uom.displayControl as TextModel).text = $row.entity.weightShortName;

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
      case 'material' : {
        this.on_material_clicked();
        break;
      }
      case 'lot' : {
        this.on_lot_clicked();
        break;
      }
    }
  }

  //#region private flows
  on_material_clicked(event = null) {
    return this.on_material_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_clickedInternal(
    $row: FootPrintManager_inventory_lot_snapshot_gridComponentRowModel,
  $grid: FootPrintManager_inventory_lot_snapshot_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $shell.FootPrintManager.opensingle_material_hub({materialId: $row.entity.materialId});
  }
  on_lot_clicked(event = null) {
    return this.on_lot_clickedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_clickedInternal(
    $row: FootPrintManager_inventory_lot_snapshot_gridComponentRowModel,
  $grid: FootPrintManager_inventory_lot_snapshot_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $shell.FootPrintManager.opensingle_lot_hub({lotId: $row.entity.lotId});
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_inventory_lot_snapshot_gridComponentRowModel,
  $grid: FootPrintManager_inventory_lot_snapshot_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/03/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.captureDate)) {
          $row.cells.captureDate.displayControl.text = $utils.date.format($row.entity.captureDate, format);
      };
      if ($utils.isDefined($row.entity.receiveDate)) {
          $row.cells.receive_date.displayControl.text = $utils.date.format($row.entity.receiveDate, format);
      };
      if ($utils.isDefined($row.entity.lastReceiveDate)) {
          $row.cells.last_receive_date.displayControl.text = $utils.date.format($row.entity.lastReceiveDate, format);
      };
  };
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'FootPrintManager-inventory_lot_snapshot_grid',
  templateUrl: './FootPrintManager.inventory_lot_snapshot_grid.component.html'
})
export class FootPrintManager_inventory_lot_snapshot_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_inventory_lot_snapshot_gridComponentEntity[];

  pageSize = 100;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['compact','fit-content-table']);


  inParams: IFootPrintManager_inventory_lot_snapshot_gridComponentInParams = { captureDate: null, projectId: null, warehouseId: null, materialIds: [] };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     captureDate: new GridHeaderModel(new HeaderStyles(null, null), 'Capture date', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),       warehouse: new GridHeaderModel(new HeaderStyles(null, null), 'Warehouse', false, false, null),       total_packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Total packaged amount', false, false, null),       total_base_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Total base amount', false, false, null),       total_net_weight: new GridHeaderModel(new HeaderStyles(null, null), 'Total net weight', false, false, null),       total_gross_weight: new GridHeaderModel(new HeaderStyles(null, null), 'Total gross weight', false, false, null),       weight_uom: new GridHeaderModel(new HeaderStyles(null, null), 'Weight UOM', false, false, null),       receive_date: new GridHeaderModel(new HeaderStyles(null, null), 'Receive date', false, false, null),       last_receive_date: new GridHeaderModel(new HeaderStyles(null, null), 'Last receive date', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_inventory_lot_snapshot_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('captureDate') set $inParams_captureDate(value: any) {
    this.inParams['captureDate'] = value;
  }
  get $inParams_captureDate(): any {
    return this.inParams['captureDate'] ;
  }
  @Input('projectId') set $inParams_projectId(value: any) {
    this.inParams['projectId'] = value;
  }
  get $inParams_projectId(): any {
    return this.inParams['projectId'] ;
  }
  @Input('warehouseId') set $inParams_warehouseId(value: any) {
    this.inParams['warehouseId'] = value;
  }
  get $inParams_warehouseId(): any {
    return this.inParams['warehouseId'] ;
  }
  @Input('materialIds') set $inParams_materialIds(value: any) {
    this.inParams['materialIds'] = value;
  }
  get $inParams_materialIds(): any {
    return this.inParams['materialIds'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Inventory lot snapshot';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.captureDate)) {
        this.$missingRequiredInParams.push('captureDate');
      }
      if(isNil(this.inParams.projectId)) {
        this.$missingRequiredInParams.push('projectId');
      }
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      fullTextSearch:  null ,
      captureDate:  $grid.inParams.captureDate ,
      projectId:  $grid.inParams.projectId ,
      warehouseId:  $grid.inParams.warehouseId ,
      materialIds:  $grid.inParams.materialIds ,
    };
    try {
    const data = await this.datasources.Inventory.ds_inventory_lot_snapshot_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_inventory_lot_snapshot_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }


  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: FootPrintManager_inventory_lot_snapshot_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  }
  on_export_flow(event = null) {
    return this.on_export_flowInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_export_flowInternal(
    $grid: FootPrintManager_inventory_lot_snapshot_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 08/01/2024
  
  try {
  
      // Initialize
      let wb = $event;
      let transactions = [];
  
      let snapshot = (await $datasources.Inventory.ds_inventory_lot_snapshot_grid.getList({
          captureDate: $grid.inParams.captureDate,
          projectId: $grid.inParams.projectId,
          warehouseId: $grid.inParams.warehouseId,
          materialIds: $grid.inParams.materialIds
      }))?.result
  
      if ($utils.isDefined(snapshot)) {
  
          let need_date = snapshot.filter(r => !$utils.isDefined(r.receiveDate));
  
          if ($utils.isDefined(need_date)) {
  
              let lots: { id: number, receive_date: string, last_receive_date: string }[] = [];
  
              let ids_to_query = need_date.map(r => r.lotId);
              ids_to_query = ids_to_query.filter((value, index, array) => array.indexOf(value) === index); //Distinct
  
              let group: number = 50 //Get 50 lots at a time
              let start: number = 0;
              let lot_ids: number[] = ids_to_query.slice(start, start + group);
  
              while (lot_ids?.length > 0) {
  
                  if (lot_ids.length > 0) {
  
                      let get_lots = (await $datasources.Inventory.ds_get_lot_by_lotIds.get({
                          lot_ids: lot_ids
                      }));
  
                      lots.push(...get_lots?.result?.map(l => { return { id: l.Id, receive_date: l.ReceiveDate, last_receive_date: l.LastReceiveDate } }))
                  }
  
                  start = start + group;
                  lot_ids = ids_to_query.slice(start, start + group);
              }
  
              if ($utils.isDefined(lots)) {
  
                  lots = lots.filter(l => $utils.isDefined(l.receive_date));
  
                  if ($utils.isDefined(lots)) {
  
                      need_date = need_date.filter(r => $utils.isDefined(lots.find(l => l.id === r.lotId)));
  
                      for (let row of need_date) {
  
                          let lot = lots.find(l => l.id === row.lotId);
  
                          row.receiveDate = lot.receive_date;
                          row.lastReceiveDate = lot.last_receive_date;
                      }
                  }
              }
          }
      }
  
      const format = `MM/DD/YYYY, LT`;
  
      // Compile all data into JSON
      for (let record of snapshot) {
  
          transactions.push({
  
              "Capture date": record.captureDate ? $utils.date.format(record.captureDate, format) : record.captureDate,
              "Material": record.materialLookupcode,
              "Lot": record.lotLookupcode,
              "Receive date": record.receiveDate ? $utils.date.format(record.receiveDate, format) : record.receiveDate,
              "Last receive date": record.lastReceiveDate ? $utils.date.format(record.lastReceiveDate, format) : record.lastReceiveDate,
              "Packaging": record.packagingShortName,
              "Warehouse": record.warehouseName,
              "Total packaged amount": record.totalPackagedAmount?.toString(),
              "Total base amount": record.totalBaseAmount?.toString(),
              "Total net weight": record.totalNetWeight?.toString(),
              "Total gross weight": record.totalGrossWeight?.toString(),
              "Weight uom": record.weightShortName
          });
      }
  
      var ws = $utils.excel.json_to_sheet(transactions);
      $utils.excel.book_append_sheet(wb, ws, "InventoryExport");
  
  } catch (error) {
      const errorMessage = $utils.isDefined(error?.error?.error) ? error?.error?.error.message : error;
      throw new Error(errorMessage);
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_inventory_lot_snapshot_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/12/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  let rows = $grid.rows;
  
  if ($utils.isDefined(rows)) {
  
      let need_date = rows.filter(r => !$utils.isDefined(r.entity.receiveDate));
  
      if ($utils.isDefined(need_date)) {
  
          let lots: { id: number, receive_date: string, last_receive_date: string }[] = [];
  
          let ids_to_query = need_date.map(r => r.entity.lotId);
          ids_to_query = ids_to_query.filter((value, index, array) => array.indexOf(value) === index); //Distinct
  
          let group: number = 50 //Get 50 lots at a time
          let start: number = 0;
          let lot_ids: number[] = ids_to_query.slice(start, start + group);
  
          while (lot_ids?.length > 0) {
  
              if (lot_ids.length > 0) {
  
                  let get_lots = (await $datasources.Inventory.ds_get_lot_by_lotIds.get({
                      lot_ids: lot_ids
                  }));
  
                  lots.push(...get_lots?.result?.map(l => { return { id: l.Id, receive_date: l.ReceiveDate, last_receive_date: l.LastReceiveDate } }))
              }
  
              start = start + group;
              lot_ids = ids_to_query.slice(start, start + group);
          }
  
          if ($utils.isDefined(lots)) {
  
              lots = lots.filter(l => $utils.isDefined(l.receive_date));
  
              if ($utils.isDefined(lots)) {
  
                  need_date = need_date.filter(r => $utils.isDefined(lots.find(l => l.id === r.entity.lotId)));
  
                  for (let row of need_date) {
  
                      let lot = lots.find(l => l.id === row.entity.lotId);
  
                      if ($utils.isDefined(lot.receive_date)) {
                          row.cells.receive_date.displayControl.text = $utils.date.format(lot.receive_date, format)
                      }
                      if ($utils.isDefined(lot.last_receive_date)) {
                          row.cells.last_receive_date.displayControl.text = $utils.date.format(lot.last_receive_date, format)
                      }
                  }
              }
          }
      }
  }
  }
  //#endregion private flows


  async $exportExcel() {
    const wb: WorkBook = excelUtils.book_new();

    await this.on_export_flow(wb);

    writeExcelFile(wb, 'FootPrintManager_inventory_lot_snapshot_gridComponent_export.xlsx');
  }
 
  close() {
    this.$finish.emit();
  }
}
