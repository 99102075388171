<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"



>
    <ng-container topToolbar>
    <div data-cy="tool-id-move_inventory" *ngIf="!topToolbar.move_inventory.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.move_inventory.control.readOnly"
            [ngStyle]="topToolbar.move_inventory.control.styles.style"
            [ngClass]="topToolbar.move_inventory.control.styles.classes"
            (click)="on_move_inventory_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.move_inventory.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.move_inventory.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.move_inventory.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-is_primary_pick" *ngIf="!filters.is_primary_pick.hidden" 
            class="field-container standard {{filters.is_primary_pick.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.is_primary_pick.styles.style"
            [ngClass]="filters.is_primary_pick.styles.classes">
        <div class="label-container"
              title="{{filters.is_primary_pick.label}}{{filters.is_primary_pick.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.is_primary_pick.label}}">{{filters.is_primary_pick.label}}<span *ngIf="filters.is_primary_pick.required"
                  class="required-asterisk">*</span></label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="is_primary_pick"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.is_primary_pick.control.styles.style"
                      [ngClass]="filters.is_primary_pick.control.styles.classes">{{filters.is_primary_pick.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.is_primary_pick.invalid">
          <ng-container *ngFor="let error of filters.is_primary_pick.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-licenseplate_status" *ngIf="!filters.licenseplate_status.hidden" 
            class="field-container standard {{filters.licenseplate_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.licenseplate_status.styles.style"
            [ngClass]="filters.licenseplate_status.styles.classes">
        <div class="label-container"
              title="{{filters.licenseplate_status.label}}{{filters.licenseplate_status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.licenseplate_status.label}}">{{filters.licenseplate_status.label}}<span *ngIf="filters.licenseplate_status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Locations-licenseplate_statuses_dd_single 
            data-cy="selector"
            [type]="filters.licenseplate_status.control.type"
            formControlName="licenseplate_status"
            (displayTextChange)="filters.licenseplate_status.control.displayText=$event"
            [placeholder]="filters.licenseplate_status.control.placeholder"
            [styles]="filters.licenseplate_status.control.styles"
        >
        </Locations-licenseplate_statuses_dd_single>
        <ng-container *ngIf="filters.licenseplate_status.invalid">
          <ng-container *ngFor="let error of filters.licenseplate_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-lot_status" *ngIf="!filters.lot_status.hidden" 
            class="field-container standard {{filters.lot_status.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.lot_status.styles.style"
            [ngClass]="filters.lot_status.styles.classes">
        <div class="label-container"
              title="{{filters.lot_status.label}}{{filters.lot_status.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.lot_status.label}}">{{filters.lot_status.label}}<span *ngIf="filters.lot_status.required"
                  class="required-asterisk">*</span></label>
        </div>
        <Lots-lot_statuses_dd_single 
            data-cy="selector"
            [type]="filters.lot_status.control.type"
            formControlName="lot_status"
            (displayTextChange)="filters.lot_status.control.displayText=$event"
            [placeholder]="filters.lot_status.control.placeholder"
            [styles]="filters.lot_status.control.styles"
        >
        </Lots-lot_statuses_dd_single>
        <ng-container *ngIf="filters.lot_status.invalid">
          <ng-container *ngFor="let error of filters.lot_status.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="licenseplate">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.licenseplate.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate.displayControl.styles.classes">{{row.cells.licenseplate.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="location">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.location.displayControl.styles.style"
          [ngClass]="row.cells.location.displayControl.styles.classes">{{row.cells.location.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="warehouse">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.warehouse.displayControl.styles.style"
          [ngClass]="row.cells.warehouse.displayControl.styles.classes">{{row.cells.warehouse.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="project">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.project.displayControl.styles.style"
          [ngClass]="row.cells.project.displayControl.styles.classes">{{row.cells.project.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material.displayControl.styles.style"
          [ngClass]="row.cells.material.displayControl.styles.classes">{{row.cells.material.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot.displayControl.styles.style"
          [ngClass]="row.cells.lot.displayControl.styles.classes">{{row.cells.lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="total_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.total_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.total_packaged_amount.displayControl.styles.classes">{{row.cells.total_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="available_packaged_amount">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.available_packaged_amount.displayControl.styles.style"
          [ngClass]="row.cells.available_packaged_amount.displayControl.styles.classes">{{row.cells.available_packaged_amount.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="packaging">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.packaging.displayControl.styles.style"
          [ngClass]="row.cells.packaging.displayControl.styles.classes">{{row.cells.packaging.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="vendor_lot">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.vendor_lot.displayControl.styles.style"
          [ngClass]="row.cells.vendor_lot.displayControl.styles.classes">{{row.cells.vendor_lot.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="lot_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.lot_status.displayControl.styles.style"
          [ngClass]="row.cells.lot_status.displayControl.styles.classes">{{row.cells.lot_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="licenseplate_status">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.licenseplate_status.displayControl.styles.style"
          [ngClass]="row.cells.licenseplate_status.displayControl.styles.classes">{{row.cells.licenseplate_status.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="expiration_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.expiration_date.displayControl.styles.style"
          [ngClass]="row.cells.expiration_date.displayControl.styles.classes">{{row.cells.expiration_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="last_picked_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.last_picked_date.displayControl.styles.style"
          [ngClass]="row.cells.last_picked_date.displayControl.styles.classes">{{row.cells.last_picked_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="open_pick_quantity">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.open_pick_quantity.displayControl.styles.style"
          [ngClass]="row.cells.open_pick_quantity.displayControl.styles.classes">{{row.cells.open_pick_quantity.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="manufacture_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.manufacture_date.displayControl.styles.style"
          [ngClass]="row.cells.manufacture_date.displayControl.styles.classes">{{row.cells.manufacture_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="material_description">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.material_description.displayControl.styles.style"
          [ngClass]="row.cells.material_description.displayControl.styles.classes">{{row.cells.material_description.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
