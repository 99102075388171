<datex-grid #$gridComponent *ngIf="initialized " [selection]="true" (selectionChange)="$selectionChanged($event)" [headers]="headers" [rows]="rows" [(pageSize)]="pageSize" [(pageSkip)]="pageSkip" [totalCount]="totalCount" [loadingStatus]="loadingStatus" (pageChange)="$dataLoad()" [containerStyles]="containerStyles"
[canEdit]="canEdit"
[canAdd]="canAdd" [addNewRowFn]="addRow.bind(this)" [addLineModel]="bottomToolbar.addLine"

>
    <ng-container topToolbar>
    <div data-cy="tool-id-delete_message" *ngIf="!topToolbar.delete_message.hidden" class="toolContainer">
    <button mat-button
            data-cy="button"
            class="datex-button"
            [disabled]="topToolbar.delete_message.control.readOnly"
            [ngStyle]="topToolbar.delete_message.control.styles.style"
            [ngClass]="topToolbar.delete_message.control.styles.classes"
            (click)="on_delete_clicked($event)"
            >
      <div class="button-label">
        <ng-container *ngIf="topToolbar.delete_message.control.icon === 'datex-default-spinner'; else showIcon">
          <div class="button-icon">
            <i data-cy="button-icon" class="datex-default-spinner"></i>
          </div>
        </ng-container>
        <ng-template #showIcon>
          <div class="button-icon">
            <i data-cy="button-icon" class="icon {{topToolbar.delete_message.control.icon}}"></i>
          </div>
        </ng-template>
        <div data-cy="button-label" class="button-text">{{topToolbar.delete_message.control.label}}</div>
      </div>
    
    </button>
    </div>
    <div class="toolContainer">
      <button mat-button
              class="datex-button"
              (click)="$exportExcel()">
        <div class="button-label">
          <div class="button-icon">
            <i class="icon icon-ic_fluent_document_arrow_down_20_regular"></i>
          </div>
          <div class="button-text">Export</div>
        </div>
      </button>
    </div>
    <div class="query-filter"><input matInput autocomplete="off" class="datex-textbox query-search" spellcheck="false" placeholder="Filter"
      [(ngModel)]="fullTextSearch" (ngModelChange)="reload()" [ngModelOptions]="{updateOn: 'blur'}"
      title="true"></div>
  </ng-container>

  <ng-container filters>
    <div class="fieldsetsContainer" [formGroup]="formGroup">
      <div class="fieldsetsGroup">
      <div data-cy="field-id-only_current" *ngIf="!filters.only_current.hidden" 
            class="field-container standard {{filters.only_current.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.only_current.styles.style"
            [ngClass]="filters.only_current.styles.classes">
        <div class="label-container"
              title="{{filters.only_current.label}}{{filters.only_current.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.only_current.label}}">{{filters.only_current.label}}<span *ngIf="filters.only_current.required"
                  class="required-asterisk">*</span></label>
        </div>
        <mat-slide-toggle data-cy="slideToggle" formControlName="only_current"
                      color="primary"
                      class="datex-toggle"
                      [ngStyle]="filters.only_current.control.styles.style"
                      [ngClass]="filters.only_current.control.styles.classes">{{filters.only_current.control.label}}</mat-slide-toggle>
        <ng-container *ngIf="filters.only_current.invalid">
          <ng-container *ngFor="let error of filters.only_current.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-integration_name" *ngIf="!filters.integration_name.hidden" 
            class="field-container double {{filters.integration_name.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.integration_name.styles.style"
            [ngClass]="filters.integration_name.styles.classes">
        <div class="label-container"
              title="{{filters.integration_name.label}}{{filters.integration_name.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.integration_name.label}}">{{filters.integration_name.label}}<span *ngIf="filters.integration_name.required"
                  class="required-asterisk">*</span></label>
        </div>
        <FootPrintApiManager-integrations_dd_single 
            data-cy="selector"
            [type]="filters.integration_name.control.type"
            formControlName="integration_name"
            (displayTextChange)="filters.integration_name.control.displayText=$event"
            [placeholder]="filters.integration_name.control.placeholder"
            [styles]="filters.integration_name.control.styles"
        >
        </FootPrintApiManager-integrations_dd_single>
        <ng-container *ngIf="filters.integration_name.invalid">
          <ng-container *ngFor="let error of filters.integration_name.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      <div data-cy="field-id-direction" *ngIf="!filters.direction.hidden" 
            class="field-container standard {{filters.direction.invalid ? 'invalid' : ''}}"
            [ngStyle]="filters.direction.styles.style"
            [ngClass]="filters.direction.styles.classes">
        <div class="label-container"
              title="{{filters.direction.label}}{{filters.direction.required ? ' (required)' : ''}}">
          <label data-cy="field-label" class="datex-label"
                  title="{{filters.direction.label}}">{{filters.direction.label}}<span *ngIf="filters.direction.required"
                  class="required-asterisk">*</span></label>
        </div>
        <FootPrintApiManager-directions_dd_single 
            data-cy="selector"
            [type]="filters.direction.control.type"
            formControlName="direction"
            (displayTextChange)="filters.direction.control.displayText=$event"
            [placeholder]="filters.direction.control.placeholder"
            [styles]="filters.direction.control.styles"
        >
        </FootPrintApiManager-directions_dd_single>
        <ng-container *ngIf="filters.direction.invalid">
          <ng-container *ngFor="let error of filters.direction.errors">
            <span class="invalid-message">
              {{error}}
            </span>
          </ng-container>
        </ng-container>
      </div>      </div>
    </div>
  </ng-container>

  <ng-container gridColumnDef="id">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.id.displayControl.styles.style"
          [ngClass]="row.cells.id.displayControl.styles.classes">{{row.cells.id.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="integration_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.integration_name.displayControl.styles.style"
          [ngClass]="row.cells.integration_name.displayControl.styles.classes">{{row.cells.integration_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['integration_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.integration_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.integration_name.editControl.placeholder}}"
            [ngStyle]="row.cells.integration_name.editControl.styles.style"
            [ngClass]="row.cells.integration_name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="user_name">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.user_name.displayControl.styles.style"
          [ngClass]="row.cells.user_name.displayControl.styles.classes">{{row.cells.user_name.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['user_name_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.user_name.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.user_name.editControl.placeholder}}"
            [ngStyle]="row.cells.user_name.editControl.styles.style"
            [ngClass]="row.cells.user_name.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="type">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.type.displayControl.styles.style"
          [ngClass]="row.cells.type.displayControl.styles.classes">{{row.cells.type.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['type_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.type.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.type.editControl.placeholder}}"
            [ngStyle]="row.cells.type.editControl.styles.style"
            [ngClass]="row.cells.type.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="identifier">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.identifier.displayControl.styles.style"
          [ngClass]="row.cells.identifier.displayControl.styles.classes">{{row.cells.identifier.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['identifier_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.identifier.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.identifier.editControl.placeholder}}"
            [ngStyle]="row.cells.identifier.editControl.styles.style"
            [ngClass]="row.cells.identifier.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="direction">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.direction.displayControl.styles.style"
          [ngClass]="row.cells.direction.displayControl.styles.classes">{{row.cells.direction.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="textBox"[formControl]="row.formGroup.controls['direction_edit']"
            matInput
            autocomplete="off"
            spellcheck="false"
            class="datex-textbox {{row.cells.direction.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.direction.editControl.placeholder}}"
            [ngStyle]="row.cells.direction.editControl.styles.style"
            [ngClass]="row.cells.direction.editControl.styles.classes"> 
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="count">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.count.displayControl.styles.style"
          [ngClass]="row.cells.count.displayControl.styles.classes">{{row.cells.count.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    <input data-cy="numberBox" [formControl]="row.formGroup.controls['count_edit']"
            matInput
            numberBox
            [format]="row.cells.count.editControl.format"
            autocomplete="off"
            spellcheck="false"
            class="datex-numberbox {{row.cells.count.editControl.readOnly ? 'readonly ' : ''}}"
            placeholder="{{row.cells.count.editControl.placeholder}}"
            [ngStyle]="row.cells.count.editControl.styles.style"
            [ngClass]="row.cells.count.editControl.styles.classes">
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="created_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.created_date.displayControl.styles.style"
          [ngClass]="row.cells.created_date.displayControl.styles.classes">{{row.cells.created_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>
  <ng-container gridColumnDef="modified_date">

    <ng-template gridCellDisplayControlDef let-row>
    <div data-cy="text" class="datex-text"
          [ngStyle]="row.cells.modified_date.displayControl.styles.style"
          [ngClass]="row.cells.modified_date.displayControl.styles.classes">{{row.cells.modified_date.displayControl.text }}</div>
    </ng-template>

    <ng-template gridCellEditControlDef let-row>
    </ng-template>

  </ng-container>

<ng-container bottomToolbar>
</ng-container>


</datex-grid>
