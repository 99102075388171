import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { app_ShellService, EModalSize, EToasterType, EToasterPosition } from './app.shell.service';
import { app_OperationService } from './app.operation.service';
import { app_DatasourceService } from './app.datasource.index';
import { app_FlowService } from './app.flow.index';
import { app_ReportService } from './app.report.index';
import { app_LocalizationService } from './app.localization.service';
import { Language } from './localization.service';
import { $types } from './app.types'
import { Owners_owners_dd_singleComponent } from './Owners.owners_dd_single.component'
import { Owners_projects_dd_singleComponent } from './Owners.projects_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'
import { Materials_material_groups_dd_singleComponent } from './Materials.material_groups_dd_single.component'
import { Materials_allocation_strategy_dd_singleComponent } from './Materials.allocation_strategy_dd_single.component'
import { Materials_decimal_precision_singleComponent } from './Materials.decimal_precision_single.component'
import { Materials_measurement_units_dd_singleComponent } from './Materials.measurement_units_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => Owners_owners_dd_singleComponent),
    forwardRef(() => Owners_projects_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
    forwardRef(() => Materials_material_groups_dd_singleComponent),
    forwardRef(() => Materials_allocation_strategy_dd_singleComponent),
    forwardRef(() => Materials_decimal_precision_singleComponent),
    forwardRef(() => Materials_measurement_units_dd_singleComponent),
  ],
  selector: 'app-custom_material_creation_form',
  templateUrl: './app.custom_material_creation_form.component.html'
})
export class app_custom_material_creation_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { projectId?: number, ownerId?: number } = { projectId: null, ownerId: null };
  //#region Inputs
  @Input('projectId') set $inParams_projectId(v: number) {
    this.inParams.projectId = v;
  }
  get $inParams_projectId(): number {
    return this.inParams.projectId;
  }
  @Input('ownerId') set $inParams_ownerId(v: number) {
    this.inParams.ownerId = v;
  }
  get $inParams_ownerId(): number {
    return this.inParams.ownerId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { materialId?: number, confirm?: boolean } = { materialId: null, confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ materialId?: number, confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    owner: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    project: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    lookupcode: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    name: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    description: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    packaging: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    material_group: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    allocation_strategy: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    upc_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    lot_controlled: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    serial_controlled: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    fixed_weight: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    fixed_volume: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    fixed_dimension: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'change' }),
    decimal_precision: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    weight: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    shipping_weight: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    weight_uom: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    volume: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    shipping_volume: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    volume_uom: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    length: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    width: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    height: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    dimension_uom: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    pallet_high: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    pallet_tie: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    shelf_life_span: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    allocation_quantity: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    commodity_code: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    country_of_origin: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    duty_rate: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    require_outbound_scanning: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    special_instructions: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    owner: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['owner'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Owner', true)
,
    project: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['project'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Project', true)
,
    lookupcode: new FieldModel(new TextBoxModel(this.formGroup.controls['lookupcode'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lookupcode', true)
,
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Name', false)
,
    description: new FieldModel(new TextBoxModel(this.formGroup.controls['description'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Description', true)
,
    packaging: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['packaging'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Packaging', true)
,
    material_group: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['material_group'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Group', true)
,
    allocation_strategy: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['allocation_strategy'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Allocation Strategy', true)
,
    upc_code: new FieldModel(new TextBoxModel(this.formGroup.controls['upc_code'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Upc Code', false)
,
    lot_controlled: new FieldModel(new CheckBoxModel(this.formGroup.controls['lot_controlled'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Lot Controlled', true)
,
    serial_controlled: new FieldModel(new CheckBoxModel(this.formGroup.controls['serial_controlled'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Serial Controlled', true)
,
    fixed_weight: new FieldModel(new CheckBoxModel(this.formGroup.controls['fixed_weight'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Fixed Weight', true)
,
    fixed_volume: new FieldModel(new CheckBoxModel(this.formGroup.controls['fixed_volume'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Fixed Volume', true)
,
    fixed_dimension: new FieldModel(new CheckBoxModel(this.formGroup.controls['fixed_dimension'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Fixed Dimension', true)
,
    decimal_precision: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['decimal_precision'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Decimal Precision', false)
,
    weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['weight'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Weight', true)
,
    shipping_weight: new FieldModel(new NumberBoxModel(this.formGroup.controls['shipping_weight'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Shipping Weight', true)
,
    weight_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['weight_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Weight Uom', true)
,
    volume: new FieldModel(new NumberBoxModel(this.formGroup.controls['volume'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Volume', true)
,
    shipping_volume: new FieldModel(new NumberBoxModel(this.formGroup.controls['shipping_volume'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Shipping Volume', true)
,
    volume_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['volume_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Volume Uom', true)
,
    length: new FieldModel(new NumberBoxModel(this.formGroup.controls['length'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Length', true)
,
    width: new FieldModel(new NumberBoxModel(this.formGroup.controls['width'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Width', true)
,
    height: new FieldModel(new NumberBoxModel(this.formGroup.controls['height'] as DatexFormControl, null, false, '0.00', '')
, new ControlContainerStyles(null, null), 'Height', true)
,
    dimension_uom: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['dimension_uom'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Dimension Uom', true)
,
    pallet_high: new FieldModel(new NumberBoxModel(this.formGroup.controls['pallet_high'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Pallet High', false)
,
    pallet_tie: new FieldModel(new NumberBoxModel(this.formGroup.controls['pallet_tie'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Pallet Tie', false)
,
    shelf_life_span: new FieldModel(new NumberBoxModel(this.formGroup.controls['shelf_life_span'] as DatexFormControl, null, false, '0', '')
, new ControlContainerStyles(null, null), 'Shelf Life', false)
,
    allocation_quantity: new FieldModel(new NumberBoxModel(this.formGroup.controls['allocation_quantity'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Allocation Quantity', false)
,
    commodity_code: new FieldModel(new TextBoxModel(this.formGroup.controls['commodity_code'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Commodity Code ', false)
,
    country_of_origin: new FieldModel(new TextBoxModel(this.formGroup.controls['country_of_origin'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Country Of Origin', false)
,
    duty_rate: new FieldModel(new NumberBoxModel(this.formGroup.controls['duty_rate'] as DatexFormControl, null, false, '', '')
, new ControlContainerStyles(null, null), 'Duty Rate', false)
,
    require_outbound_scanning: new FieldModel(new CheckBoxModel(this.formGroup.controls['require_outbound_scanning'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Require Outbound Scanning', false)
,
    special_instructions: new FieldModel(new TextBoxModel(this.formGroup.controls['special_instructions'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Special Instructions', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('Material', false, true, true),
  newGroup2: new FieldsetModel('Details', false, true, false),
  newGroup3: new FieldsetModel('Advanced', false, true, false),
};

  //#region fields inParams
  get $fields_owner_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_owner_selector_inParams_projectId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.project.control.value;
    
    return expr;
  }

  get $fields_project_selector_inParams_statusId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  get $fields_project_selector_inParams_ownerId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = $form.fields.owner.control.value;
    
    return expr;
  }

  get $fields_weight_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 3;
    
    return expr;
  }

  get $fields_volume_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 2;
    
    return expr;
  }

  get $fields_dimension_uom_selector_inParams_typeId(): number {
    const $form = this;
    const $utils = this.utils;
    const expr = 1;
    
    return expr;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    owner: this.fields.owner.control.valueChanges
    ,
    project: this.fields.project.control.valueChanges
    ,
    lookupcode: this.fields.lookupcode.control.valueChanges
    ,
    name: this.fields.name.control.valueChanges
    ,
    description: this.fields.description.control.valueChanges
    ,
    packaging: this.fields.packaging.control.valueChanges
    ,
    material_group: this.fields.material_group.control.valueChanges
    ,
    allocation_strategy: this.fields.allocation_strategy.control.valueChanges
    ,
    upc_code: this.fields.upc_code.control.valueChanges
    ,
    lot_controlled: this.fields.lot_controlled.control.valueChanges
    ,
    serial_controlled: this.fields.serial_controlled.control.valueChanges
    ,
    fixed_weight: this.fields.fixed_weight.control.valueChanges
    ,
    fixed_volume: this.fields.fixed_volume.control.valueChanges
    ,
    fixed_dimension: this.fields.fixed_dimension.control.valueChanges
    ,
    decimal_precision: this.fields.decimal_precision.control.valueChanges
    ,
    weight: this.fields.weight.control.valueChanges
    ,
    shipping_weight: this.fields.shipping_weight.control.valueChanges
    ,
    weight_uom: this.fields.weight_uom.control.valueChanges
    ,
    volume: this.fields.volume.control.valueChanges
    ,
    shipping_volume: this.fields.shipping_volume.control.valueChanges
    ,
    volume_uom: this.fields.volume_uom.control.valueChanges
    ,
    length: this.fields.length.control.valueChanges
    ,
    width: this.fields.width.control.valueChanges
    ,
    height: this.fields.height.control.valueChanges
    ,
    dimension_uom: this.fields.dimension_uom.control.valueChanges
    ,
    pallet_high: this.fields.pallet_high.control.valueChanges
    ,
    pallet_tie: this.fields.pallet_tie.control.valueChanges
    ,
    shelf_life_span: this.fields.shelf_life_span.control.valueChanges
    ,
    allocation_quantity: this.fields.allocation_quantity.control.valueChanges
    ,
    commodity_code: this.fields.commodity_code.control.valueChanges
    ,
    country_of_origin: this.fields.country_of_origin.control.valueChanges
    ,
    duty_rate: this.fields.duty_rate.control.valueChanges
    ,
    require_outbound_scanning: this.fields.require_outbound_scanning.control.valueChanges
    ,
    special_instructions: this.fields.special_instructions.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: app_ShellService,
private datasources: app_DatasourceService,
private flows: app_FlowService,
private reports: app_ReportService,
private localization: app_LocalizationService,
private operations: app_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  initialized = false;

  async $init() {
    this.title = 'Create Material';
  
    const $form = this;
    const $utils = this.utils;

    
    
    
    (this.fields.material_group.control as SelectBoxModel).reset(1);
    (this.fields.allocation_strategy.control as SelectBoxModel).reset(1);
    
    (this.fields.lot_controlled.control as CheckBoxModel).reset(false);
    (this.fields.serial_controlled.control as CheckBoxModel).reset(false);
    (this.fields.fixed_weight.control as CheckBoxModel).reset(true);
    (this.fields.fixed_volume.control as CheckBoxModel).reset(true);
    (this.fields.fixed_dimension.control as CheckBoxModel).reset(true);
    (this.fields.weight.control as NumberBoxModel).reset(1);
    (this.fields.shipping_weight.control as NumberBoxModel).reset(1);
    (this.fields.weight_uom.control as SelectBoxModel).reset(14);
    (this.fields.volume.control as NumberBoxModel).reset(1);
    (this.fields.shipping_volume.control as NumberBoxModel).reset(1);
    (this.fields.volume_uom.control as SelectBoxModel).reset(11);
    (this.fields.length.control as NumberBoxModel).reset(1);
    (this.fields.width.control as NumberBoxModel).reset(1);
    (this.fields.height.control as NumberBoxModel).reset(1);
    (this.fields.dimension_uom.control as SelectBoxModel).reset(5);
    (this.fields.shelf_life_span.control as NumberBoxModel).reset(0);
    
    
    

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .owner
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_owner_change();
      });
    this.$formGroupFieldValidationObservables
      .project
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_project_change();
      });
    this.$formGroupFieldValidationObservables
      .lookupcode
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .description
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .packaging
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .material_group
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .allocation_strategy
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .upc_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .lot_controlled
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .serial_controlled
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_serial_controlled_change();
      });
    this.$formGroupFieldValidationObservables
      .fixed_weight
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_fixed_weight_change();
      });
    this.$formGroupFieldValidationObservables
      .fixed_volume
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_fixed_volume_change();
      });
    this.$formGroupFieldValidationObservables
      .fixed_dimension
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_fixed_dimension_change();
      });
    this.$formGroupFieldValidationObservables
      .decimal_precision
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_weight
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .weight_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shipping_volume
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .volume_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .length
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .width
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .height
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .dimension_uom
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .pallet_high
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .pallet_tie
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .shelf_life_span
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .allocation_quantity
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .commodity_code
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .country_of_origin
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .duty_rate
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .require_outbound_scanning
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .special_instructions
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.materialId = null;
  $form.outParams.confirm = false;
  $form.close();
  
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.toolbar.confirm.control.readOnly = true;
  
  
  // Check required fields
  const allRequiredFieldHaveValue = $utils.isAllDefined(
      $form.fields.lookupcode.control.value,
      $form.fields.description.control.value,
      $form.fields.project.control.value,
      $form.fields.allocation_strategy.control.value,
      $form.fields.material_group.control.value,
      $form.fields.packaging.control.value,
      $form.fields.fixed_dimension.control.value,
      $form.fields.fixed_volume.control.value,
      $form.fields.fixed_weight.control.value,
      $form.fields.serial_controlled.control.value,
      $form.fields.lot_controlled.control.value
  );
  
  if (!allRequiredFieldHaveValue) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.Materials.openErrorDialog('Material Creation Error', 'Please enter in data in all required fields.');
      return;
  }
  
  if ($form.fields.fixed_weight.control.value === true) {
      const allRequiredFieldHaveValue = $utils.isAllDefined(
          $form.fields.weight.control.value,
          $form.fields.shipping_weight.control,
          $form.fields.weight_uom.control.value
      );
  
      if (!allRequiredFieldHaveValue) {
          $form.toolbar.confirm.control.readOnly = false;
          $shell.Materials.openErrorDialog('Material Creation Error', 'Please enter in data in all required weight fields.');
          return;
      }
  }
  
  if ($form.fields.fixed_volume.control.value === true) {
      const allRequiredFieldHaveValue = $utils.isAllDefined(
          $form.fields.volume.control.value,
          $form.fields.shipping_volume.control,
          $form.fields.volume_uom.control.value
      );
  
      if (!allRequiredFieldHaveValue) {
          $form.toolbar.confirm.control.readOnly = false;
          $shell.Materials.openErrorDialog('Material Creation Error', 'Please enter in data in all required volume fields.');
          return;
      }
  }
  
  if ($form.fields.fixed_dimension.control.value === true) {
      const allRequiredFieldHaveValue = $utils.isAllDefined(
          $form.fields.length.control.value,
          $form.fields.width.control,
          $form.fields.height.control.value,
          $form.fields.dimension_uom.control.value
      );
  
      if (!allRequiredFieldHaveValue) {
          $form.toolbar.confirm.control.readOnly = false;
          $shell.Materials.openErrorDialog('Material Creation Error', 'Please enter in data in all required dimension fields.');
          return;
      }
  }
  
  // Check for material lookupcode duplicates
  var projectId = $form.fields.project.control.value
  const materials = (await $datasources.Materials.ds_get_material_by_lookupcode_and_projectId.get(
      {
          lookupcode: $form.fields.lookupcode.control.value,
          projectId: projectId
  
      })).result;
  
  if ($utils.isDefined(materials)) {
  
      $form.toolbar.confirm.control.readOnly = false;
      $shell.Materials.openErrorDialog('Material Creation Error', 'Duplicate material lookupcode found for selected project.');
      return;
  
  }
  
  try {
  
      const materialResult = (await $flows.Materials.create_material_flow({
          projectId: $form.fields.project.control.value,
          lookupcode: $form.fields.lookupcode.control.value,
          lotControlled: $form.fields.lot_controlled.control.value,
          serialControlled: $form.fields.serial_controlled.control.value,
          materialGroupId: $form.fields.material_group.control.value,
          description: $form.fields.description.control.value,
          name: $form.fields.name.control.value,
          allocationStrategyId: $form.fields.allocation_strategy.control.value,
          isFixedWeight: $form.fields.fixed_weight.control.value,
          isFixedLength: $form.fields.fixed_dimension.control.value,
          isFixedVolume: $form.fields.fixed_volume.control.value,
          shelfLifeSpan: $form.fields.shelf_life_span.control.value,
          basePackagingId: $form.fields.packaging.control.value,
          netWeight: $form.fields.weight.control.value,
          shippingWeight: $form.fields.shipping_weight.control.value,
          weightUomId: $form.fields.weight_uom.control.value,
          height: $form.fields.height.control.value,
          length: $form.fields.length.control.value,
          width: $form.fields.width.control.value,
          dimensionUomId: $form.fields.dimension_uom.control.value,
          netVolume: $form.fields.volume.control.value,
          shippingVolume: $form.fields.shipping_volume.control.value,
          volumeUomId: $form.fields.volume_uom.control.value,
          palletTie: $form.fields.pallet_tie.control.value,
          palletHigh: $form.fields.pallet_high.control.value,
          upcCode: $form.fields.upc_code.control.value,
          quantityPrecisionScale: $form.fields.decimal_precision.control.value
  
      }))
  
      if ($utils.isDefined(materialResult.materialId)) {
  
  
          $form.outParams.materialId = materialResult.materialId;
  
          let payload: any = {};
  
          payload.CommodityCode = $form.fields.commodity_code.control.value;
          payload.CountryOfOrigin = $form.fields.country_of_origin.control.value;
          payload.DutyRate = $form.fields.duty_rate.control.value;
          payload.SpecialInstructions = $form.fields.special_instructions.control.value;
          payload.RequireOutboundScanning = $form.fields.require_outbound_scanning.control.value;
          payload.AllocationQuantity = $form.fields.allocation_quantity.control.value;
  
          try {
  
              await $flows.Utilities.crud_update_flow({ entitySet: 'Materials', id: materialResult.materialId, entity: payload });
  
  
          } catch (error) {
              $shell.showErrorDetails('Save', 'Error on save udf data.', error);
              throw error; // to prevent displayMode 
          }
          $form.outParams.confirm = true;
  
          $form.close();
      }
      else {
          $shell.Materials.openErrorDialog('Material Creation Error', 'Unable to create the requested material.');
          return;
  
      }
  }
  catch (error) {
      $form.toolbar.confirm.control.readOnly = false;
      $shell.Materials.showErrorDetails('Save', 'Error on save.', error);
      throw error; // to prevent displayMode 
  }
  }
  on_project_change(event = null) {
    return this.on_project_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_project_changeInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  const projectId = $form.fields.project.control.value;
  
  if ($utils.isDefined(projectId)) {
  
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: projectId })).result;
  
      if ($utils.isAllDefined(project, project[0].OwnerId)) {
          $form.fields.owner.control.value = project[0].OwnerId;
      }
  
  }
  }
  on_owner_change(event = null) {
    return this.on_owner_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_owner_changeInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.fields.project.control.value = null;
  }
  on_fixed_weight_change(event = null) {
    return this.on_fixed_weight_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fixed_weight_changeInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($form.fields.fixed_weight.control.value === true) {
  
      $form.fields.weight.hidden = false;
      $form.fields.shipping_weight.hidden = false;
      $form.fields.weight_uom.hidden = false;
  
      if ($form.fields.fixed_volume.control.value === true && $form.fields.fixed_dimension.control.value === true && $form.fields.serial_controlled.control.value === false) {
          $form.fields.decimal_precision.hidden = false;
  
      }
  
  } else if (($form.fields.fixed_weight.control.value === false)) {
  
      // Clear values
      $form.fields.weight.control.value = null;
      $form.fields.shipping_weight.control.value = null;
      $form.fields.weight_uom.control.value = null;
      $form.fields.decimal_precision.control.value = null;
  
      // Hide fields
      $form.fields.weight.hidden = true;
      $form.fields.shipping_weight.hidden = true;
      $form.fields.weight_uom.hidden = true;
      $form.fields.decimal_precision.hidden = true;
  }
  
  }
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  
  // Delay to give the form time to load before setting the focus logic
  await new Promise( resolve => setTimeout(resolve, 500) );
  
  if ($utils.isDefined($form.inParams.ownerId) && !$utils.isDefined($form.inParams.projectId)){
      $form.fields.owner.control.value = $form.inParams.ownerId;
      $form.fields.project.control.focus();
  }
  
  if ($utils.isDefined($form.inParams.projectId)) {
      $form.fields.project.control.value = $form.inParams.projectId;
  
      const project = (await $datasources.Materials.ds_get_project_by_projectId.get({ projectId: $form.inParams.projectId })).result;
      if ($utils.isAllDefined(project, project[0].OwnerId)) {
          $form.fields.owner.control.value = project[0].OwnerId;
          $form.fields.lookupcode.control.focus();
      }
  }
  
  
  
  
  }
  on_fixed_volume_change(event = null) {
    return this.on_fixed_volume_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fixed_volume_changeInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($form.fields.fixed_volume.control.value === true) {
  
      $form.fields.volume.hidden = false;
      $form.fields.shipping_volume.hidden = false;
      $form.fields.volume_uom.hidden = false;
  
      if ($form.fields.fixed_dimension.control.value === true && $form.fields.fixed_weight.control.value === true && $form.fields.serial_controlled.control.value === false) {
          $form.fields.decimal_precision.hidden = false;
  
      }
  
  } else if (($form.fields.fixed_volume.control.value === false)) {
  
      // Clear values
      $form.fields.volume.control.value = null;
      $form.fields.shipping_volume.control.value = null;
      $form.fields.volume_uom.control.value = null;
      $form.fields.decimal_precision.control.value = null;
  
      // Hide fields
      $form.fields.volume.hidden = true;
      $form.fields.shipping_volume.hidden = true;
      $form.fields.volume_uom.hidden = true;
      $form.fields.decimal_precision.hidden = true;
  }
  
  }
  on_fixed_dimension_change(event = null) {
    return this.on_fixed_dimension_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_fixed_dimension_changeInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($form.fields.fixed_dimension.control.value === true) {
  
      $form.fields.length.hidden = false;
      $form.fields.width.hidden = false;
      $form.fields.height.hidden = false;
      $form.fields.dimension_uom.hidden = false;
  
      if ($form.fields.fixed_volume.control.value === true && $form.fields.fixed_weight.control.value === true && $form.fields.serial_controlled.control.value === false) {
          $form.fields.decimal_precision.hidden = false;
  
      }
  
  } else if (($form.fields.fixed_dimension.control.value === false)) {
  
      // Clear values
      $form.fields.length.control.value = null;
      $form.fields.width.control.value = null;
      $form.fields.height.control.value = null;
      $form.fields.dimension_uom.control.value = null;
      $form.fields.decimal_precision.control.value = null;
  
      // Hide fields
      $form.fields.length.hidden = true;
      $form.fields.width.hidden = true;
      $form.fields.height.hidden = true;
      $form.fields.dimension_uom.hidden = true;
      $form.fields.decimal_precision.hidden = true;
  }
  
  }
  on_serial_controlled_change(event = null) {
    return this.on_serial_controlled_changeInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_serial_controlled_changeInternal(
    $form: app_custom_material_creation_formComponent,
  
    $shell: app_ShellService,
    $datasources: app_DatasourceService,
    $flows: app_FlowService,
    $reports: app_ReportService,
    $settings: SettingsValuesService,
    $operations: app_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: app_LocalizationService,
    $event: any
  ) {
  
  
  if ($form.fields.serial_controlled.control.value === false) {
  
      if ($form.fields.fixed_volume.control.value === true && $form.fields.fixed_weight.control.value === true && $form.fields.fixed_dimension.control.value === true) {
          $form.fields.decimal_precision.hidden = false;
  
      }
  
  } else if (($form.fields.serial_controlled.control.value === true)) {
  
      // Clear values
      $form.fields.decimal_precision.control.value = null;
  
      // Hide fields
      $form.fields.decimal_precision.hidden = true;
  }
  
  }
  //#endregion private flows
}
