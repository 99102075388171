import { Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { ReportBaseService } from './report-base.service';

import { UtilsService } from './utils.service';
import { Reports_DatasourceService } from './Reports.datasource.index';

interface IReports_custom_commercial_invoice_reportServiceInParams {
  orderId?: number}

interface IReports_custom_commercial_invoice_reportServiceData {
  Header?: { result?: { Id?: number, CreatedSysDateTime?: string, CreatedSysUser?: string, LookupCode?: string, Notes?: string, OwnerReference?: string, PVA?: boolean, RequestedDeliveryDate?: string, VendorReference?: string, ShipmentOrderLookups?: { OrderId?: number, ShipmentId?: number, Shipment?: { Id?: number, Incoterms?: string, ItemCount?: number, LookupCode?: string, TrackingIdentifier?: string, Carrier?: { Name?: string } } }[], Project?: { Name?: string, Owner?: { Name?: string } }, shipToAddress?: { AttentionOf?: string, FirstName?: string, LastName?: string, Line1?: string, Line2?: string, City?: string, State?: string, PostalCode?: string, Country?: string, PrimaryTelephone?: string, OrderId?: number, accountName?: string, accountLookupcode?: string }, shipByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, soldByAddress?: { Id?: number, Project?: { Id?: number, Name?: string, Owner?: { Id?: number, Name?: string, OwnersContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, PrimaryTelephone?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } }, billToAddress?: { Id?: number, Project?: { Id?: number, Name?: string, ProjectsContactsLookup?: { ContactId?: number, Contact?: { Id?: number, FirstName?: string, LastName?: string, TypeId?: number, Address?: { City?: string, Country?: string, Line1?: string, Line2?: string, PostalCode?: string, State?: string } } }[] } } } }
  Details?: { result?: { ShipmentLineId?: number, LicensePlateId?: number, LotId?: number, PackagedId?: number, PackagedAmount?: number, ShipmentLine?: { ShipmentId?: number, OrderLine?: { PackagedId?: number, Price?: number, TotalPrice?: number, Material?: { Id?: number, CommodityCode?: string, CountryOfOrigin?: string, Description?: string, LookupCode?: string } } }, materialPackaging?: { MaterialId?: number, PackagingId?: number, BasePackagingId?: number, BasePackagingQuantity?: number, ShippingWeight?: number, UpcCode?: string, Weight?: number }[], totalWeight?: number }[] }
}

@Injectable({ providedIn: 'root' })
export class Reports_custom_commercial_invoice_reportService extends ReportBaseService<IReports_custom_commercial_invoice_reportServiceInParams, IReports_custom_commercial_invoice_reportServiceData> {

  protected reportReferenceName = 'custom_commercial_invoice_report';
  protected appReferenceName = 'Reports';

  constructor(
    utils: UtilsService,
    private datasources: Reports_DatasourceService,
    ) {
      super(utils);
  }

  override throwIfMissingRequiredInParams (inParams: IReports_custom_commercial_invoice_reportServiceInParams) {
  }

  protected async getData(inParams: IReports_custom_commercial_invoice_reportServiceInParams): Promise<IReports_custom_commercial_invoice_reportServiceData> 
  {
    const $utils = this.utils;
    const $report: { inParams: IReports_custom_commercial_invoice_reportServiceInParams } = {
      inParams: inParams
    };

    const data: IReports_custom_commercial_invoice_reportServiceData = {
    };
    const datasourcesQueries: (() => Promise<void>)[] = [];

    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      const dsData = await this.datasources.Reports.custom_ds_commercial_invoice_report_header.get(dsParams);
      
      data.Header = dsData;
    });
    datasourcesQueries.push(async () => {
      const dsParams = {
        orderId:  $report.inParams.orderId 
      };
      const dsData = await this.datasources.Reports.custom_ds_commercial_invoice_report_lines.get(dsParams);
      
      data.Details = dsData;
    });

    await Promise.all(datasourcesQueries.map(t => t()));

    return data;
  }
}
