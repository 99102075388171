import { 
  Component, 
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  SimpleChanges,
  Output,
  EventEmitter,
  Inject,
  forwardRef
} from '@angular/core';

import { 
  FormGroup,
  Validators 
} from '@angular/forms';

import { isEqual, isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil, mergeMap, shareReplay } from 'rxjs/operators';

import { DatexFormControl, validateControlOnChange, validateFormOnControlChange } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles,
  ValueControlModel
} from './models/control';
import { Styles, ControlContainerStyles } from './models/style';
import { FieldModel } from './models/field';
import { FieldsetModel } from './models/fieldset';
import { ToolModel } from './models/tool';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { DockAppointments_areas_dd_singleComponent } from './DockAppointments.areas_dd_single.component'

@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => DockAppointments_areas_dd_singleComponent),
  ],
  selector: 'FootPrintManager-dock_door_creation_form',
  templateUrl: './FootPrintManager.dock_door_creation_form.component.html'
})
export class FootPrintManager_dock_door_creation_formComponent implements OnInit, OnDestroy, OnChanges {

  inParams: { warehouseId: number } = { warehouseId: null };
  //#region Inputs
  @Input('warehouseId') set $inParams_warehouseId(v: number) {
    this.inParams.warehouseId = v;
  }
  get $inParams_warehouseId(): number {
    return this.inParams.warehouseId;
  }
  //#endregion Inputs

  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  outParams: { confirm?: boolean } = { confirm: null };
  //#endregion

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  //#region Variables
  vars: { nextId?: number } = { };
  //#endregion
  //#region Events
  @Output()
  outParamsChange = new EventEmitter<{ confirm?: boolean }>();
  
  events = {
    outParamsChange: { emit: () => { this.outParamsChange.emit(this.outParams); } }
  }
  //#endregion

  formGroup: FormGroup = new FormGroup({
    name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    short_name: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    area: new DatexFormControl(null, { validators: [ Validators.required ], updateOn: 'blur' }),
    inbound_only: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    outbound_only: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  get valid(): boolean {
    return this.formGroup.valid;
  }
  
  toolbar = {
      confirm: new ToolModel(new ButtonModel('confirm', new ButtonStyles(['primary'], null), false, 'Confirm', '')
    ),
      cancel: new ToolModel(new ButtonModel('cancel', new ButtonStyles(['secondary'], null), false, 'Cancel', '')
    )
  };

  fields = {
    name: new FieldModel(new TextBoxModel(this.formGroup.controls['name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Name', true)
,
    short_name: new FieldModel(new TextBoxModel(this.formGroup.controls['short_name'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Location', true)
,
    area: new FieldModel(new SelectBoxModel(
  this.formGroup.controls['area'] as DatexFormControl, 
  null, null,
  false, 
  '')
, new ControlContainerStyles(null, null), 'Area', true)
,
    inbound_only: new FieldModel(new CheckBoxModel(this.formGroup.controls['inbound_only'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Inbound only', false)
,
    outbound_only: new FieldModel(new CheckBoxModel(this.formGroup.controls['outbound_only'] as DatexFormControl, null, false, '')
, new ControlContainerStyles(null, null), 'Outbound only', false)
,
  };

  fieldsets = {
  newGroup1: new FieldsetModel('', true, false, true),
};

  //#region fields inParams
  cacheValueFor_$fields_area_selector_inParams_warehouseIds: number[];
  get $fields_area_selector_inParams_warehouseIds(): number[] {
    const $form = this;
    const $utils = this.utils;
    const expr = [$form.inParams.warehouseId];
    
    if(!isEqual(this.cacheValueFor_$fields_area_selector_inParams_warehouseIds, expr)) {
      this.cacheValueFor_$fields_area_selector_inParams_warehouseIds = expr;
    }
    return this.cacheValueFor_$fields_area_selector_inParams_warehouseIds;
  }

  //#endregion fields inParams

  $formGroupFieldValidationObservables = {
    name: this.fields.name.control.valueChanges
    ,
    short_name: this.fields.short_name.control.valueChanges
    ,
    area: this.fields.area.control.valueChanges
    ,
    inbound_only: this.fields.inbound_only.control.valueChanges
    ,
    outbound_only: this.fields.outbound_only.control.valueChanges
    ,
  }
  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) { 
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }
  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.warehouseId)) {
        this.$missingRequiredInParams.push('warehouseId');
      }
  }

  initialized = false;

  async $init() {
    this.title = 'Create Dock Door';
  
    const $form = this;
    const $utils = this.utils;

    
    
    (this.fields.inbound_only.control as CheckBoxModel).reset(false);
    (this.fields.outbound_only.control as CheckBoxModel).reset(false);

    await this.on_init();

    this.initialized = true;
  }

  private $subscribeFormControlValueChanges() {
    this.$formGroupFieldValidationObservables
      .name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .short_name
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .area
      .pipe(takeUntil(this.$unsubscribe$))
      .subscribe();
    this.$formGroupFieldValidationObservables
      .inbound_only
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_inbound_only_changed();
      });
    this.$formGroupFieldValidationObservables
      .outbound_only
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_outbound_only_changed();
      });
  }

  close() {
    this.$finish.emit();
  }

  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }
 
  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $form: FootPrintManager_dock_door_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  $form.outParams.confirm = false;
  
  const nextId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LocationContainer' })).nextId;
  
  $form.vars.nextId = nextId;
  }
  on_confirm_clicked(event = null) {
    return this.on_confirm_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_confirm_clickedInternal(
    $form: FootPrintManager_dock_door_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  // Check required fields
  const allRequiredFieldsHaveValue = $utils.isAllDefined(
      $form.fields.area.control.value,
      $form.fields.name.control.value,
      $form.fields.short_name.control.value
  );
  
  var errors = '';
  
  if (allRequiredFieldsHaveValue === false) {
      $shell.Locations.openErrorDialog('Save', 'Missing required fields');
      throw new Error('Missing required fields'); // To prevent display mode
  }
  else {
      try {
  
          const location = (await $datasources.Locations.ds_get_locations_by_name_and_warehouseId.get({
              warehouseId: $form.inParams.warehouseId,
              name: $form.fields.name.control.value
          })).result;
          if ($utils.isDefined(location)) {
              throw new Error('Duplicate location found');
          }
  
          const payload = {
              "Id": $form.vars.nextId,
              "WarehouseId": $form.inParams.warehouseId,
              "ParentId": $form.fields.area.control.value,
              "StatusId": 1,
              "Depth": 1,
              "Path": '/',
              "Name": $form.fields.name.control.value,
              "TypeId": 5,
              "IsLoose": false,
              "EligibleForAllocation": $form.fields.inbound_only.control.value == 1 ? true : false,  // Inbound Only Hack
              "IsPrimaryPick": $form.fields.outbound_only.control.value == 1 ? true : false,   // Outbound Only Hack
              "Enabled": true,
              // Default values
              "AllowInventoryIfNotActive": true,
              "ManufacturingLineFlag": false,
              "RestrictToReplenishmentMaterials": false,
              "PurgeAddedInventory": false,
              "SkipCycleCount": false,
              "EligibleForManufacturingFeedback": false,
              "IsManufacturingStagingLocation": false,
              "AllowMoveTaskCreation": false,
              "DisableNonPreferredLocationAlertCreation": false,
              "SkipValidationRules": true,
              "BreakInventoryToBasePackaging": false,
              "ConfirmInventoryDeletion": false,
              "EligibleForScaleTicket": false,
              "SkipAfterPickingCountBack": false,
              "AutoSelectCountOnCountBack": false,
              "MobileForceUserCountBack": false,
              "IsPackageVerification": false,
              "IsDynamicPrimaryPick": false
          };
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'LocationContainers', entity: payload });
  
  
          // Create child inventory location
  
          const nextChildId = (await $flows.Utilities.reserve_nextId_flow({ entity: 'LocationContainer' })).nextId;
          const childPayload = {
              "Id": nextChildId,
              "WarehouseId": $form.inParams.warehouseId,
              "ParentId": $form.vars.nextId,
              "StatusId": 1,
              "Depth": 1,
              "Path": '/',
              "Name": $form.fields.short_name.control.value,
              "TypeId": 3,
              "IsLoose": false,
              "EligibleForAllocation": false,
              "IsPrimaryPick": false,
              "Enabled": true,
              // Default values
              "AllowInventoryIfNotActive": true,
              "ManufacturingLineFlag": false,
              "RestrictToReplenishmentMaterials": false,
              "PurgeAddedInventory": false,
              "SkipCycleCount": false,
              "EligibleForManufacturingFeedback": false,
              "IsManufacturingStagingLocation": false,
              "AllowMoveTaskCreation": false,
              "DisableNonPreferredLocationAlertCreation": false,
              "SkipValidationRules": true,
              "BreakInventoryToBasePackaging": false,
              "ConfirmInventoryDeletion": false,
              "EligibleForScaleTicket": false,
              "SkipAfterPickingCountBack": false,
              "AutoSelectCountOnCountBack": false,
              "MobileForceUserCountBack": false,
              "IsPackageVerification": false,
              "IsDynamicPrimaryPick": false
          };
  
          await $flows.Utilities.crud_create_flow({ entitySet: 'LocationContainers', entity: childPayload });
  
  
          $form.outParams.confirm = true;
  
          $form.close();
  
      }
      catch (error) {
          $shell.Locations.openErrorDialog('Save', 'Error on save');
          throw error; // To prevent display mode
      }
  }
  }
  on_cancel_clicked(event = null) {
    return this.on_cancel_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_clickedInternal(
    $form: FootPrintManager_dock_door_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  $form.outParams.confirm = false;
  $form.close();
  }
  on_inbound_only_changed(event = null) {
    return this.on_inbound_only_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_inbound_only_changedInternal(
    $form: FootPrintManager_dock_door_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($form.fields.inbound_only.control.value) {
      $form.fields.outbound_only.control.value = false
  }
  
  }
  on_outbound_only_changed(event = null) {
    return this.on_outbound_only_changedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_outbound_only_changedInternal(
    $form: FootPrintManager_dock_door_creation_formComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
   if ( $form.fields.outbound_only.control.value ){
      $form.fields.inbound_only.control.value = false;
   }
  }
  //#endregion private flows
}
