import { Inject, Injectable } from '@angular/core';
import { isNil } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

@Injectable({ providedIn: 'root' })
export class app_custom_ds_get_sales_order_lines_by_orderIdService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  public async get(inParams: { orderId: number, fullTextSearch?: string }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, Price?: number, StatusId?: number, TotalPrice?: number, VendorLotId?: number, Material?: { AllocationQuantity?: number, LookupCode?: string, Name?: string, ProjectId?: number, Project?: { LookupCode?: string } }, InventoryMeasurementUnit?: { Name?: string }, Order?: { Id?: number, PreferredWarehouseId?: number, ProjectId?: number, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedWarehouseId?: number } }[] }, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, OnHand?: { TotalPackagedAmount?: number }, SoftAllocated?: { TotalSoftPackagedAmount?: number, ExpectedPackagedId?: number, OrderLine?: { MaterialId?: number }, Shipment?: { ExpectedWarehouseId?: number } }, OnHandNotEligible?: { TotalPackagedAmountNotEligible?: number, PackagedId?: number, Lot?: { MaterialId?: number }, LicensePlate?: { WarehouseId?: number } }, Available?: { TotalAvailablePackagedAmount?: number, TotalAvailableBaseAmount?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_sales_order_lines_by_orderId/get`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getList(inParams: { orderId: number, fullTextSearch?: string, $top?: number, $skip?: number }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, Price?: number, StatusId?: number, TotalPrice?: number, VendorLotId?: number, Material?: { AllocationQuantity?: number, LookupCode?: string, Name?: string, ProjectId?: number, Project?: { LookupCode?: string } }, InventoryMeasurementUnit?: { Name?: string }, Order?: { Id?: number, PreferredWarehouseId?: number, ProjectId?: number, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedWarehouseId?: number } }[] }, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, OnHand?: { TotalPackagedAmount?: number }, SoftAllocated?: { TotalSoftPackagedAmount?: number, ExpectedPackagedId?: number, OrderLine?: { MaterialId?: number }, Shipment?: { ExpectedWarehouseId?: number } }, OnHandNotEligible?: { TotalPackagedAmountNotEligible?: number, PackagedId?: number, Lot?: { MaterialId?: number }, LicensePlate?: { WarehouseId?: number } }, Available?: { TotalAvailablePackagedAmount?: number, TotalAvailableBaseAmount?: number } }[], totalCount: number }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_sales_order_lines_by_orderId/getList`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }
  public async getByKeys(inParams: { orderId: number, fullTextSearch?: string, $keys: { OrderId?: number, LineNumber?: number }[] }): 
  Promise<{ result: { OrderId?: number, LineNumber?: number, LotId?: number, MaterialId?: number, Notes?: string, PackagedAmount?: number, PackagedId?: number, ParentLineNumber?: number, Price?: number, StatusId?: number, TotalPrice?: number, VendorLotId?: number, Material?: { AllocationQuantity?: number, LookupCode?: string, Name?: string, ProjectId?: number, Project?: { LookupCode?: string } }, InventoryMeasurementUnit?: { Name?: string }, Order?: { Id?: number, PreferredWarehouseId?: number, ProjectId?: number, ShipmentOrderLookups?: { ShipmentId?: number, Shipment?: { ExpectedWarehouseId?: number } }[] }, LicensePlate?: { LookupCode?: string }, Lot?: { LookupCode?: string }, OnHand?: { TotalPackagedAmount?: number }, SoftAllocated?: { TotalSoftPackagedAmount?: number, ExpectedPackagedId?: number, OrderLine?: { MaterialId?: number }, Shipment?: { ExpectedWarehouseId?: number } }, OnHandNotEligible?: { TotalPackagedAmountNotEligible?: number, PackagedId?: number, Lot?: { MaterialId?: number }, LicensePlate?: { WarehouseId?: number } }, Available?: { TotalAvailablePackagedAmount?: number, TotalAvailableBaseAmount?: number } }[] }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.orderId)) {
      missingRequiredInParams.push('\'orderId\'');
    }
    if (isNil(inParams.$keys)) {
      missingRequiredInParams.push('\'$keys\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/app/datasources/custom_ds_get_sales_order_lines_by_orderId/getByKeys`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}
