<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="!$hasMissingRequiredInParams"
         class="blade-tools">
      <div data-cy="tool-id-beautify" *ngIf="!toolbar.beautify.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.beautify.control.readOnly"
              [ngStyle]="toolbar.beautify.control.styles.style"
              [ngClass]="toolbar.beautify.control.styles.classes"
              (click)="on_beautify_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.beautify.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.beautify.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.beautify.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-separator1" *ngIf="!toolbar.separator1.hidden" class="toolContainer">
      <div class="tool-separator"></div>
      </div>
      <div data-cy="tool-id-first_chunk" *ngIf="!toolbar.first_chunk.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.first_chunk.control.readOnly"
              [ngStyle]="toolbar.first_chunk.control.styles.style"
              [ngClass]="toolbar.first_chunk.control.styles.classes"
              (click)="on_first_chunk_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.first_chunk.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.first_chunk.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.first_chunk.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-previous_chunk" *ngIf="!toolbar.previous_chunk.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.previous_chunk.control.readOnly"
              [ngStyle]="toolbar.previous_chunk.control.styles.style"
              [ngClass]="toolbar.previous_chunk.control.styles.classes"
              (click)="on_previous_chunk_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.previous_chunk.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.previous_chunk.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.previous_chunk.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-page" *ngIf="!toolbar.page.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.page.control.readOnly"
              [ngStyle]="toolbar.page.control.styles.style"
              [ngClass]="toolbar.page.control.styles.classes"
      >
        <div class="button-label">
          <ng-container *ngIf="toolbar.page.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.page.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.page.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-next_chunk" *ngIf="!toolbar.next_chunk.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.next_chunk.control.readOnly"
              [ngStyle]="toolbar.next_chunk.control.styles.style"
              [ngClass]="toolbar.next_chunk.control.styles.classes"
              (click)="on_next_chunk_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.next_chunk.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.next_chunk.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.next_chunk.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-last_chunk" *ngIf="!toolbar.last_chunk.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.last_chunk.control.readOnly"
              [ngStyle]="toolbar.last_chunk.control.styles.style"
              [ngClass]="toolbar.last_chunk.control.styles.classes"
              (click)="on_last_chunk_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.last_chunk.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.last_chunk.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.last_chunk.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content code-editor">
    <div>
      <ng-container
                    *ngIf="initialized && !$hasMissingRequiredInParams">
        <ngx-codemirror [(ngModel)]="value"
                        [options]="codeMirrorOptions"
                        [CodeValidator]="mode"></ngx-codemirror>
      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams"
           class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
    </div>
  </div>
</div>