import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { Invoices_ShellService, EModalSize, EToasterType, EToasterPosition } from './Invoices.shell.service';
import { Invoices_OperationService } from './Invoices.operation.service';
import { Invoices_DatasourceService } from './Invoices.datasource.index';
import { Invoices_FlowService } from './Invoices.flow.index';
import { Invoices_ReportService } from './Invoices.report.index';
import { Invoices_LocalizationService } from './Invoices.localization.service';
import { Language } from './localization.service';
import { $types } from './Invoices.types'


interface IInvoices_commodity_price_gridComponentEntity {
price_id?: string, name?: string, description?: string, value?: number, date?: string, date_number?: number}

interface IInvoices_commodity_price_gridComponentInParams {
  title?: string}


class Invoices_commodity_price_gridComponentRowModel extends GridRowModel {
  grid: Invoices_commodity_price_gridComponent;
  entity: IInvoices_commodity_price_gridComponentEntity;



 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
  });

  override cells = {
    name: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    description: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    value: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    date: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
  }



  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: Invoices_ShellService,
private datasources: Invoices_DatasourceService,
private flows: Invoices_FlowService,
private reports: Invoices_ReportService,
private localization: Invoices_LocalizationService,
private operations: Invoices_OperationService,
) {
    super();
    
  }

  async $initializeExisting(grid: Invoices_commodity_price_gridComponent, entity: IInvoices_commodity_price_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.price_id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: Invoices_commodity_price_gridComponent, entity?: IInvoices_commodity_price_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.price_id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.price_id;
    const inParams = {
      $keys:[$resultKey],
      name:  null ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
      full_text_search:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.Invoices.ds_commodity_price_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.name.displayControl as TextModel).text = $row.entity.name;
    (this.cells.description.displayControl as TextModel).text = $row.entity.description;
    (this.cells.value.displayControl as TextModel).text = $row.entity.value?.toLocaleString();
    (this.cells.date.displayControl as TextModel).text = $row.entity.date?.toString();

    await this.on_row_data_loaded();
  }

  override async refresh() {
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
    } else {
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: Invoices_commodity_price_gridComponentRowModel,
  $grid: Invoices_commodity_price_gridComponent, 
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/26/2024
  
  const format = `MM/DD/YYYY, LT`;
  
  if ($utils.isDefined(format)) {
      if ($utils.isDefined($row.entity.date)) {
          $row.cells.date.displayControl.text = $utils.date.format($row.entity.date, format);
      }
  }
  
  if ($utils.isDefined($row.entity.value)) {
      let num = $row.entity.value;
      $row.cells.value.displayControl.text = new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num);
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
  ],
  selector: 'Invoices-commodity_price_grid',
  templateUrl: './Invoices.commodity_price_grid.component.html'
})
export class Invoices_commodity_price_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IInvoices_commodity_price_gridComponentEntity[];

  pageSize = 25;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;


// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('cellsWidth', ['fit-content-table']);

  fullTextSearch: string;

  inParams: IInvoices_commodity_price_gridComponentInParams = { title: null };


  //#region Variables
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     name: new GridHeaderModel(new HeaderStyles(null, null), 'Name', false, false, null),       description: new GridHeaderModel(new HeaderStyles(null, null), 'Description', false, false, null),       value: new GridHeaderModel(new HeaderStyles(null, null), 'Value', false, false, null),       date: new GridHeaderModel(new HeaderStyles(null, null), 'Date', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: Invoices_commodity_price_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('title') set $inParams_title(value: any) {
    this.inParams['title'] = value;
  }
  get $inParams_title(): any {
    return this.inParams['title'] ;
  }

  topToolbar = {
  };

  bottomToolbar = {
  };

  formGroup: FormGroup = new FormGroup({
    date_from: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
    date_to: new DatexFormControl(null, { validators: [  ], updateOn: 'blur' }),
  });

  filters = {
    date_from: new FieldModel(new DateBoxModel(this.formGroup.controls['date_from'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'From', false)
,
    date_to: new FieldModel(new DateBoxModel(this.formGroup.controls['date_to'] as DatexFormControl, null, false, '', 'datetime')
, new ControlContainerStyles(null, null), 'To', false)
,
  }

  //#region filters inParams
  //#endregion filters inParams

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: Invoices_ShellService,
    private datasources: Invoices_DatasourceService,
    private flows: Invoices_FlowService,
    private reports: Invoices_ReportService,
    private localization: Invoices_LocalizationService,
    private operations: Invoices_OperationService,
    ) {
    this.title = 'commodity_price_grid';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$init();
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$init();
    }
  }


  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    await this.on_init();
    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

    this.formGroup.valueChanges.pipe(takeUntil(this.$unsubscribe$)).subscribe(value => {
      this.reload();
    });
  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    if(!this.formGroup.valid) {
      return;
    }
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      name:  null ,
      date_from:  $grid.filters.date_from.control.value ,
      date_to:  $grid.filters.date_to.control.value ,
      full_text_search:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.Invoices.ds_commodity_price_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new Invoices_commodity_price_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
  }

  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_init(event = null) {
    return this.on_initInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_initInternal(
    $grid: Invoices_commodity_price_gridComponent,
  
    $shell: Invoices_ShellService,
    $datasources: Invoices_DatasourceService,
    $flows: Invoices_FlowService,
    $reports: Invoices_ReportService,
    $settings: SettingsValuesService,
    $operations: Invoices_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: Invoices_LocalizationService,
    $event: any
  ) {
  //O.Arias - 07/26/2024
  
  //Default for the dates
  
  let date = $utils.date.now();
  
  let new_date = new Date(date);
  new_date.setUTCHours(0, 0, 0, 0);
  date = new_date.toISOString();
  
  $grid.filters.date_from.control.value = $utils.date.subtract(30, "day", date);
  $grid.filters.date_to.control.value = $utils.date.add(1, "day", date);
  
  $grid.title = $grid.inParams.title ?? "Commodity price grid"
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
