import { Inject, Injectable } from '@angular/core';
import { HttpResponse } from '@angular/common/http';
import { isNil, omit } from 'lodash-es';
import { environment } from '../environments/environment';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';

import { $types } from './Invoices.types'

@Injectable({ providedIn: 'root' })
export class Invoices_is_billing_task_cancelable_flowService {

  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    ) { 
  }

  async run(inParams: { billingTaskId: number }): Promise<{ reason?: string }> 
  {
    const missingRequiredInParams = [];
    if (isNil(inParams.billingTaskId)) {
      missingRequiredInParams.push('\'billingTaskId\'');
    }
    if (missingRequiredInParams.length) {
    }
    let url = `${environment.backendUrl}api/Invoices/functions/is_billing_task_cancelable_flow`;
    
    const options = {
    }
    
    const body = inParams;
    
    return this.utils.http.post(url, body, options);
    
  }

}

