<div class="blade-wrapper">
  <div class="blade-header">
    <div *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams" class="blade-tools">
      <div data-cy="tool-id-confirm" *ngIf="!toolbar.confirm.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.confirm.control.readOnly"
              [ngStyle]="toolbar.confirm.control.styles.style"
              [ngClass]="toolbar.confirm.control.styles.classes"
              (click)="on_confirm_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.confirm.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.confirm.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.confirm.control.label}}</div>
        </div>
      
      </button>
      </div>
      <div data-cy="tool-id-cancel" *ngIf="!toolbar.cancel.hidden" class="toolContainer">
      <button mat-button
              data-cy="button"
              class="datex-button"
              [disabled]="toolbar.cancel.control.readOnly"
              [ngStyle]="toolbar.cancel.control.styles.style"
              [ngClass]="toolbar.cancel.control.styles.classes"
              (click)="on_cancel_clicked($event)"
              >
        <div class="button-label">
          <ng-container *ngIf="toolbar.cancel.control.icon === 'datex-default-spinner'; else showIcon">
            <div class="button-icon">
              <i data-cy="button-icon" class="datex-default-spinner"></i>
            </div>
          </ng-container>
          <ng-template #showIcon>
            <div class="button-icon">
              <i data-cy="button-icon" class="icon {{toolbar.cancel.control.icon}}"></i>
            </div>
          </ng-template>
          <div data-cy="button-label" class="button-text">{{toolbar.cancel.control.label}}</div>
        </div>
      
      </button>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
  <div class="blade-content">
    <div class="datex-editor">
      <ng-container *ngIf="initialized && $hasDataLoaded && !$hasMissingRequiredInParams">
        <div [formGroup]="formGroup"
             class="formdata">
                <div data-cy="fieldset-id-general"
                     *ngIf="!fieldsets.general.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.general.collapsible }">
                    <div *ngIf="!fieldsets.general.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.general.toggle()">
                      <span class="fieldsetsTitle-text">General</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.general.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.general.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.general.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.general.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-priority" *ngIf="!fields.priority.hidden" 
                            class="field-container standard {{fields.priority.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.priority.styles.style"
                            [ngClass]="fields.priority.styles.classes">
                        <div class="label-container"
                              title="{{fields.priority.label}}{{fields.priority.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.priority.label}}">{{fields.priority.label}}<span *ngIf="fields.priority.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="numberBox" formControlName="priority"
                                matInput
                                numberBox
                                [format]="fields.priority.control.format"
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-numberbox {{fields.priority.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.priority.control.placeholder}}"
                                [ngStyle]="fields.priority.control.styles.style"
                                [ngClass]="fields.priority.control.styles.classes">
                        <ng-container *ngIf="fields.priority.invalid">
                          <ng-container *ngFor="let error of fields.priority.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-assigned_to" *ngIf="!fields.assigned_to.hidden" 
                            class="field-container standard {{fields.assigned_to.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.assigned_to.styles.style"
                            [ngClass]="fields.assigned_to.styles.classes">
                        <div class="label-container"
                              title="{{fields.assigned_to.label}}{{fields.assigned_to.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.assigned_to.label}}">{{fields.assigned_to.label}}<span *ngIf="fields.assigned_to.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <LaborManagement-user_list_dd_single 
                            data-cy="selector"
                            [type]="fields.assigned_to.control.type"
                            formControlName="assigned_to"
                            (displayTextChange)="fields.assigned_to.control.displayText=$event"
                            [placeholder]="fields.assigned_to.control.placeholder"
                            [styles]="fields.assigned_to.control.styles"
                          [restrictToOperationCodes]="$fields_assigned_to_selector_inParams_restrictToOperationCodes"
                        >
                        </LaborManagement-user_list_dd_single>
                        <ng-container *ngIf="fields.assigned_to.invalid">
                          <ng-container *ngFor="let error of fields.assigned_to.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-status" *ngIf="!fields.status.hidden" 
                            class="field-container standard {{fields.status.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.status.styles.style"
                            [ngClass]="fields.status.styles.classes">
                        <div class="label-container"
                              title="{{fields.status.label}}{{fields.status.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.status.label}}">{{fields.status.label}}<span *ngIf="fields.status.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="status"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.status.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.status.control.placeholder}}"
                                [ngStyle]="fields.status.control.styles.style"
                                [ngClass]="fields.status.control.styles.classes"> 
                        <ng-container *ngIf="fields.status.invalid">
                          <ng-container *ngFor="let error of fields.status.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-operation_code" *ngIf="!fields.operation_code.hidden" 
                            class="field-container standard {{fields.operation_code.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.operation_code.styles.style"
                            [ngClass]="fields.operation_code.styles.classes">
                        <div class="label-container"
                              title="{{fields.operation_code.label}}{{fields.operation_code.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.operation_code.label}}">{{fields.operation_code.label}}<span *ngIf="fields.operation_code.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="operation_code"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.operation_code.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.operation_code.control.placeholder}}"
                                [ngStyle]="fields.operation_code.control.styles.style"
                                [ngClass]="fields.operation_code.control.styles.classes"> 
                        <ng-container *ngIf="fields.operation_code.invalid">
                          <ng-container *ngFor="let error of fields.operation_code.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-eligible_work_classes" *ngIf="!fields.eligible_work_classes.hidden" 
                            class="field-container standard {{fields.eligible_work_classes.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.eligible_work_classes.styles.style"
                            [ngClass]="fields.eligible_work_classes.styles.classes">
                        <div class="label-container"
                              title="{{fields.eligible_work_classes.label}}{{fields.eligible_work_classes.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.eligible_work_classes.label}}">{{fields.eligible_work_classes.label}}<span *ngIf="fields.eligible_work_classes.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="eligible_work_classes"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.eligible_work_classes.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.eligible_work_classes.control.placeholder}}"
                                [ngStyle]="fields.eligible_work_classes.control.styles.style"
                                [ngClass]="fields.eligible_work_classes.control.styles.classes"> 
                        <ng-container *ngIf="fields.eligible_work_classes.invalid">
                          <ng-container *ngFor="let error of fields.eligible_work_classes.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-created_on" *ngIf="!fields.created_on.hidden" 
                            class="field-container standard {{fields.created_on.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.created_on.styles.style"
                            [ngClass]="fields.created_on.styles.classes">
                        <div class="label-container"
                              title="{{fields.created_on.label}}{{fields.created_on.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.created_on.label}}">{{fields.created_on.label}}<span *ngIf="fields.created_on.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="created_on"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.created_on.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.created_on.control.placeholder}}"
                                [ngStyle]="fields.created_on.control.styles.style"
                                [ngClass]="fields.created_on.control.styles.classes"> 
                        <ng-container *ngIf="fields.created_on.invalid">
                          <ng-container *ngFor="let error of fields.created_on.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
                <div data-cy="fieldset-id-details"
                     *ngIf="!fieldsets.details.hidden"
                     class="fieldset-container">
                  <div class="fieldsetsContainer"
                       [ngClass]="{'collapsible': fieldsets.details.collapsible }">
                    <div *ngIf="!fieldsets.details.hideTitle"
                         data-cy="fieldset-title"
                         class="fieldsetsTitle"
                         (click)="fieldsets.details.toggle()">
                      <span class="fieldsetsTitle-text">Details</span>
                      <i data-cy="fieldset-toggle"
                         *ngIf="fieldsets.details.collapsible"
                         class="icon"
                         [ngClass]="{'icon-ic_fluent_chevron_down_20_filled': !fieldsets.details.expanded, 'icon-ic_fluent_chevron_up_20_filled': fieldsets.details.expanded }"></i>
                    </div>
                    <div data-cy="fieldset-group"
                         *ngIf="fieldsets.details.expanded"
                         class="fieldsetsGroup">
                      <div data-cy="field-id-material" *ngIf="!fields.material.hidden" 
                            class="field-container standard {{fields.material.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.material.styles.style"
                            [ngClass]="fields.material.styles.classes">
                        <div class="label-container"
                              title="{{fields.material.label}}{{fields.material.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.material.label}}">{{fields.material.label}}<span *ngIf="fields.material.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="material"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.material.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.material.control.placeholder}}"
                                [ngStyle]="fields.material.control.styles.style"
                                [ngClass]="fields.material.control.styles.classes"> 
                        <ng-container *ngIf="fields.material.invalid">
                          <ng-container *ngFor="let error of fields.material.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-vendor_lot" *ngIf="!fields.vendor_lot.hidden" 
                            class="field-container standard {{fields.vendor_lot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.vendor_lot.styles.style"
                            [ngClass]="fields.vendor_lot.styles.classes">
                        <div class="label-container"
                              title="{{fields.vendor_lot.label}}{{fields.vendor_lot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.vendor_lot.label}}">{{fields.vendor_lot.label}}<span *ngIf="fields.vendor_lot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="vendor_lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.vendor_lot.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.vendor_lot.control.placeholder}}"
                                [ngStyle]="fields.vendor_lot.control.styles.style"
                                [ngClass]="fields.vendor_lot.control.styles.classes"> 
                        <ng-container *ngIf="fields.vendor_lot.invalid">
                          <ng-container *ngFor="let error of fields.vendor_lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-lot" *ngIf="!fields.lot.hidden" 
                            class="field-container standard {{fields.lot.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.lot.styles.style"
                            [ngClass]="fields.lot.styles.classes">
                        <div class="label-container"
                              title="{{fields.lot.label}}{{fields.lot.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.lot.label}}">{{fields.lot.label}}<span *ngIf="fields.lot.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="lot"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.lot.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.lot.control.placeholder}}"
                                [ngStyle]="fields.lot.control.styles.style"
                                [ngClass]="fields.lot.control.styles.classes"> 
                        <ng-container *ngIf="fields.lot.invalid">
                          <ng-container *ngFor="let error of fields.lot.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-amount" *ngIf="!fields.amount.hidden" 
                            class="field-container standard {{fields.amount.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.amount.styles.style"
                            [ngClass]="fields.amount.styles.classes">
                        <div class="label-container"
                              title="{{fields.amount.label}}{{fields.amount.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.amount.label}}">{{fields.amount.label}}<span *ngIf="fields.amount.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="amount"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.amount.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.amount.control.placeholder}}"
                                [ngStyle]="fields.amount.control.styles.style"
                                [ngClass]="fields.amount.control.styles.classes"> 
                        <ng-container *ngIf="fields.amount.invalid">
                          <ng-container *ngFor="let error of fields.amount.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-source_location" *ngIf="!fields.source_location.hidden" 
                            class="field-container standard {{fields.source_location.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.source_location.styles.style"
                            [ngClass]="fields.source_location.styles.classes">
                        <div class="label-container"
                              title="{{fields.source_location.label}}{{fields.source_location.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.source_location.label}}">{{fields.source_location.label}}<span *ngIf="fields.source_location.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="source_location"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.source_location.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.source_location.control.placeholder}}"
                                [ngStyle]="fields.source_location.control.styles.style"
                                [ngClass]="fields.source_location.control.styles.classes"> 
                        <ng-container *ngIf="fields.source_location.invalid">
                          <ng-container *ngFor="let error of fields.source_location.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-license_plate" *ngIf="!fields.license_plate.hidden" 
                            class="field-container standard {{fields.license_plate.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.license_plate.styles.style"
                            [ngClass]="fields.license_plate.styles.classes">
                        <div class="label-container"
                              title="{{fields.license_plate.label}}{{fields.license_plate.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.license_plate.label}}">{{fields.license_plate.label}}<span *ngIf="fields.license_plate.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="license_plate"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.license_plate.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.license_plate.control.placeholder}}"
                                [ngStyle]="fields.license_plate.control.styles.style"
                                [ngClass]="fields.license_plate.control.styles.classes"> 
                        <ng-container *ngIf="fields.license_plate.invalid">
                          <ng-container *ngFor="let error of fields.license_plate.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-order" *ngIf="!fields.order.hidden" 
                            class="field-container standard {{fields.order.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.order.styles.style"
                            [ngClass]="fields.order.styles.classes">
                        <div class="label-container"
                              title="{{fields.order.label}}{{fields.order.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.order.label}}">{{fields.order.label}}<span *ngIf="fields.order.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="order"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.order.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.order.control.placeholder}}"
                                [ngStyle]="fields.order.control.styles.style"
                                [ngClass]="fields.order.control.styles.classes"> 
                        <ng-container *ngIf="fields.order.invalid">
                          <ng-container *ngFor="let error of fields.order.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>              <div data-cy="field-id-wave" *ngIf="!fields.wave.hidden" 
                            class="field-container standard {{fields.wave.invalid ? 'invalid' : ''}}"
                            [ngStyle]="fields.wave.styles.style"
                            [ngClass]="fields.wave.styles.classes">
                        <div class="label-container"
                              title="{{fields.wave.label}}{{fields.wave.required ? ' (required)' : ''}}">
                          <label data-cy="field-label" class="datex-label"
                                  title="{{fields.wave.label}}">{{fields.wave.label}}<span *ngIf="fields.wave.required"
                                  class="required-asterisk">*</span></label>
                        </div>
                        <input data-cy="textBox"formControlName="wave"
                                matInput
                                autocomplete="off"
                                spellcheck="false"
                                class="datex-textbox {{fields.wave.control.readOnly ? 'readonly ' : ''}}"
                                placeholder="{{fields.wave.control.placeholder}}"
                                [ngStyle]="fields.wave.control.styles.style"
                                [ngClass]="fields.wave.control.styles.classes"> 
                        <ng-container *ngIf="fields.wave.invalid">
                          <ng-container *ngFor="let error of fields.wave.errors">
                            <span class="invalid-message">
                              {{error}}
                            </span>
                          </ng-container>
                        </ng-container>
                      </div>            </div>
                  </div>
                </div>
              </div>

      </ng-container>

      <div *ngIf="$hasMissingRequiredInParams" class="missing-params">
        <h3>Please provide {{$missingRequiredInParams.toString()}}</h3>
      </div>
      <div *ngIf="initialized && !$hasDataLoaded && !$hasMissingRequiredInParams" class="missing-params">
        <h3>No data to display</h3>
      </div>
    </div>
  </div>
</div>