import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, ViewChild, Inject, OnDestroy, forwardRef } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';

import { DatexFormControl } from './models/datex-form-control';
import { 
  TextBoxModel, 
  NumberBoxModel, 
  SelectBoxModel, 
  ESelectBoxType,
  DateBoxModel, 
  CheckBoxModel, 
  TextModel, 
  LabelModel, 
  ButtonModel,
  SplitButtonModel,
  SeparatorModel,
  ImageModel,
  DrawModel,
  CodeBoxModel,
  ButtonStyles 
} from './models/control';
import { GridHeaderModel, GridCellModel, GridRowModel, CellStyles, GridContainerStyle, HeaderStyles } from './models/grid'
import { FieldModel } from './models/field'
import { ToolModel } from './models/tool';
import { Styles, ControlContainerStyles } from './models/style';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isEqual, isNil, set } from 'lodash-es';
import { GridComponent, ELoadingStatus } from './components/grid.component';
import { WorkBook, read as readExcelFile, writeFile as writeExcelFile, utils as excelUtils } from 'xlsx';

import { SharedModule } from './shared.module';

import { UtilsService } from './utils.service';
import { SettingsValuesService } from './settings.values.service';
import { FootPrintManager_ShellService, EModalSize, EToasterType, EToasterPosition } from './FootPrintManager.shell.service';
import { FootPrintManager_OperationService } from './FootPrintManager.operation.service';
import { FootPrintManager_DatasourceService } from './FootPrintManager.datasource.index';
import { FootPrintManager_FlowService } from './FootPrintManager.flow.index';
import { FootPrintManager_ReportService } from './FootPrintManager.report.index';
import { FootPrintManager_LocalizationService } from './FootPrintManager.localization.service';
import { Language } from './localization.service';
import { $types } from './FootPrintManager.types'
import { AsnOrders_licenseplates_by_order_dd_singleComponent } from './AsnOrders.licenseplates_by_order_dd_single.component'
import { Materials_materials_by_project_dd_singleComponent } from './Materials.materials_by_project_dd_single.component'
import { FootPrintManager_lots_dd_singleComponent } from './FootPrintManager.lots_dd_single.component'
import { FootPrintManager_vendorlots_dd_singleComponent } from './FootPrintManager.vendorlots_dd_single.component'
import { Materials_material_packagings_dd_singleComponent } from './Materials.material_packagings_dd_single.component'


interface IFootPrintManager_asn_order_receiving_tasks_gridComponentEntity {
Id?: number, ExpectedInventoryAmount?: number, ExpectedPackagedAmount?: number, OrderId?: number, OrderLineNumber?: number, ProjectId?: number, ShipmentLineId?: number, StatusId?: number, Material?: { Id?: number, ControllerTypeId?: number, LookupCode?: string, PackagingLookups?: { PackagingId?: number }[] }, VendorLot?: { Id?: number, LookupCode?: string }, Lot?: { Id?: number, LookupCode?: string }, ExpectedPackagedPack?: { Id?: number, Name?: string, ShortName?: string }, Status?: { Name?: string }, ActualTargetLicensePlate?: { Id?: number, Archived?: boolean, LookupCode?: string, LicensePlateContents?: { LicensePlateId?: number, LotId?: number, PackagedId?: number, Amount?: number, PackagedAmount?: number }[], Location?: { Name?: string } }}

interface IFootPrintManager_asn_order_receiving_tasks_gridComponentInParams {
  orderId: number, orderStatusId: number}


class FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel extends GridRowModel {
  grid: FootPrintManager_asn_order_receiving_tasks_gridComponent;
  entity: IFootPrintManager_asn_order_receiving_tasks_gridComponentEntity;

  vars: { licenseplateId?: number } = { };


 // temporarily put required on all edit controls
  override formGroup = new FormGroup({
    licenseplate_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    material_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    lot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    vendorlot_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    packaged_amount_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
    packaging_edit: new DatexFormControl(null, { validators: [  ], updateOn: 'change' }),
  });

  override cells = {
    status: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    current_location: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
null
      ),
    licenseplate: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['licenseplate_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    material: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['material_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    lot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['lot_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    vendorlot: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['vendorlot_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
    packaged_amount: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new NumberBoxModel(this.formGroup.controls['packaged_amount_edit'] as DatexFormControl, null, false, '0', '')
      ),
    packaging: new GridCellModel(
      new CellStyles(null, null),
      new TextModel(null, null )
,
      new SelectBoxModel(
        this.formGroup.controls['packaging_edit'] as DatexFormControl, 
        ESelectBoxType.dropdown, null,
        false, 
        '')
      ),
  }

  get $fields_licenseplate_selector_inParams_orderId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.inParams.orderId;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_shipmentId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.shipmentId;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_warehouseId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.warehouseId;
    
    return expr;
  }
  get $fields_licenseplate_selector_inParams_defaultLocationId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $grid.vars.defaultReceivingLocationId;
    
    return expr;
  }
  get $fields_material_selector_inParams_projectId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.entity?.ProjectId ?? $grid.vars.projectId;
    
    return expr;
  }
  get $fields_lot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material?.editControl?.value;
    
    return expr;
  }
  get $fields_lot_selector_inParams_vendorLotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.vendorlot?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material?.editControl?.value;
    
    return expr;
  }
  get $fields_vendorlot_selector_inParams_lotId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.lot?.editControl?.value;
    
    return expr;
  }
  get $fields_packaging_selector_inParams_materialId(): number {
    if (!this.entity) return null; 
    const $row = this;
    const $utils = this.utils;
    const $grid = this.grid;
    const expr = $row.cells.material.editControl.value;
    
    return expr;
  }


  constructor(
    private utils: UtilsService,
private settings: SettingsValuesService,
private shell: FootPrintManager_ShellService,
private datasources: FootPrintManager_DatasourceService,
private flows: FootPrintManager_FlowService,
private reports: FootPrintManager_ReportService,
private localization: FootPrintManager_LocalizationService,
private operations: FootPrintManager_OperationService,
) {
    super();
    
    this.formGroup
      .controls['material_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_material_changed();
      });
    this.formGroup
      .controls['lot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_lot_changed();
      });
    this.formGroup
      .controls['vendorlot_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_vendorlot_changed();
      });
    this.formGroup
      .controls['packaging_edit']
      .valueChanges
      .pipe(
        takeUntil(this.$unsubscribe$)
      )
      .subscribe(() => {
        this.on_packaging_changed();
      });
  }

  async $initializeExisting(grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, entity: IFootPrintManager_asn_order_receiving_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.entity = entity;

    this.rowId = [this.entity.Id].join('-');

    await this.$dataLoaded();
  }

  async $initializeNew(grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, entity?: IFootPrintManager_asn_order_receiving_tasks_gridComponentEntity, propertyChangeCallback: (source: GridRowModel, property: string) => void = null) {
    this.$propertyChangeCallback = propertyChangeCallback;

    this.grid = grid;
    this.isNew = true;

    if (entity) {
      this.entity = entity;
    }
    else {
      this.entity = {};
    }

    this.rowId = [this.entity.Id].join('-');
    this.$dataLoaded();
    // mark the whole form as dirty, since this is a new row 
    // and even if it is valid, it should have a changed state
    this.formGroup.markAsDirty();
  }

  private $unsubscribe$ = new Subject();
  override destroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
  }

  $init() {
  }

  async $dataLoad() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    const $resultKey = this.entity.Id;
    const inParams = {
      $keys:[$resultKey],
      licenseplateId:  null ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    const data = await this.datasources.AsnOrders.ds_asn_order_licenseplates_receiving_tasks_grid.getByKeys(inParams);
    this.entity = data.result[0];
    await this.$dataLoaded();
  }

  async $dataLoaded() {
    const $row = this;
    const $grid = this.grid;
    const $utils = this.utils;
    (this.cells.status.displayControl as TextModel).text = $row.entity.Status?.Name;
    (this.cells.current_location.displayControl as TextModel).text = $row.entity.ActualTargetLicensePlate?.Location?.Name;
    (this.cells.licenseplate.displayControl as TextModel).text = $row.entity?.ActualTargetLicensePlate?.LookupCode;
    (this.cells.licenseplate.editControl as SelectBoxModel).reset($row.entity?.ActualTargetLicensePlate?.Id);
    (this.cells.material.displayControl as TextModel).text = $row.entity?.Material?.LookupCode;
    (this.cells.material.editControl as SelectBoxModel).reset($row.entity?.Material?.Id);
    (this.cells.lot.displayControl as TextModel).text = $row.entity.Lot?.LookupCode;
    (this.cells.lot.editControl as SelectBoxModel).reset($row.entity?.Lot?.Id);
    (this.cells.vendorlot.displayControl as TextModel).text = $row.entity?.VendorLot?.LookupCode;
    (this.cells.vendorlot.editControl as SelectBoxModel).reset($row.entity?.VendorLot?.Id);
    (this.cells.packaged_amount.displayControl as TextModel).text = $row.entity?.ExpectedPackagedAmount?.toString();
    (this.cells.packaged_amount.editControl as NumberBoxModel).reset($row.entity?.ExpectedPackagedAmount);
    (this.cells.packaging.displayControl as TextModel).text = $row.entity.ExpectedPackagedPack?.ShortName;
    (this.cells.packaging.editControl as SelectBoxModel).reset($row.entity?.ExpectedPackagedPack?.Id);

    await this.on_row_data_loaded();
  }

  override async refresh() {
    if (this.grid.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    await this.$dataLoad();
    // grid refresh skipSelf=true, skipParent=false, skipChildren=false  
    this.grid.$refreshEvent.emit();
  }

  override async save() {
    if (this.isNew) {
      await this.on_save_new_row();
      this.isNew = false;
    } else {
      await this.on_save_existing_row();
    } 
  }

  override $cellClicked(cellId: string) {
    super.$cellClicked(cellId);
    switch(cellId) {
    }
  }

  //#region private flows
  on_save_new_row(event = null) {
    return this.on_save_new_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_new_rowInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  try {
      /*** Validations ***/
      // Check that all required fields are populated
      if (!$utils.isAllDefined(
          $row.cells.licenseplate.editControl.value,
          $row.cells.lot.editControl.value,
          $row.cells.packaging.editControl.value,
          $row.cells.packaged_amount.editControl.value
      )) {
          throw new Error('Please enter data for all required fields.');
      }
  
      // Packaged Amount
      if ($row.cells.packaged_amount.editControl.value <= 0) {
          throw new Error('Order line quantity must be greater than 0.');
      }
  
      // Serial Numbers
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
      let adjustedPackagedAmount = $row.cells.packaged_amount.editControl.value;
  
      if (material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4 || !material[0].IsFixedWeight || !material[0].IsFixedVolume || !material[0].IsFixedLength) {
          if (adjustedPackagedAmount > 0) { // Create SerialNumbers
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId: material[0].Id, packagingId: $row.cells.packaging.editControl.value, packagedAmount: adjustedPackagedAmount, lotId: $row.cells.lot.editControl.value, licensePlateId: $row.cells.licenseplate.editControl.value });
  
              let serialnumbersResult = dialogResults.serialNumbers;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === adjustedPackagedAmount) {
                  var serialNumbersToCreate = serialnumbersResult;
                  // Add material, lot, packaging and licenseplate as serial creation flow in the ASN library requires it when creating new serial numbers
                  var serialNumbersWithAdditionalData = serialNumbersToCreate.map(obj => ({
                      ...obj,
                      MaterialId: material[0].Id,
                      LotId: $row.cells.lot.editControl.value,
                      PackagingId: $row.cells.packaging.editControl.value,
                      LicensePlateId: $row.cells.licenseplate.editControl.value
                  }));
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
      }
  
      // Create receiving task
      $row.entity.Id = (await $flows.AsnOrders.set_asn_receiving_task_flow({
          licenseplateId: $row.cells.licenseplate.editControl.value,
          lotId: $row.cells.lot.editControl.value,
          packagingId: $row.cells.packaging.editControl.value,
          orderId: $grid.inParams.orderId,
          materialId: $row.cells.material.editControl.value,
          vendorlotId: $row.cells.vendorlot.editControl.value,
          packagedAmount: $row.cells.packaged_amount.editControl.value,
          existingTaskId: null,
          serialNumbersToCreate: serialNumbersWithAdditionalData
      })).taskId;
  
      await $row.refresh();
  }
  catch (error) {
      let targetError = error;
      while ($utils.isDefined(targetError.error)) {
          targetError = targetError.error;
      }
  
      $shell.FootPrintManager.openErrorDialog('Error', targetError.message);
      throw Error(targetError.message); // To prevent display mode
  }
  
  /****************************************** 
   * FUNCTIONS
  ******************************************/
  
  }
  on_save_existing_row(event = null) {
    return this.on_save_existing_rowInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_save_existing_rowInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.packaged_amount.editControl.value <= 0) {
      $shell.FootPrintManager.openErrorDialog('Save', 'Order line quantity must be greater than 0.');
      throw new Error('Order line quantity must be greater than 0.'); // to prevent displayMode 
  }
  
  
  try {
      if ($row.cells.licenseplate.editControl.value !== $row.entity.ActualTargetLicensePlate.Id) {
          // Update task to new license plate
          await $flows.Utilities.crud_update_flow({
              entitySet: 'Tasks',
              id: $row.entity.Id,
              entity: {
                  ActualTargetLicensePlateId: $row.cells.licenseplate.editControl.value,
                  ExpectedTargetLicensePlateId: $row.cells.licenseplate.editControl.value
              }
          });
          // Update SerialNumbers to new license plate
          let serialnumbers = (await $datasources.SerialNumbers.ds_find_serialnumbers.get({ licenseplateId: $row.entity.ActualTargetLicensePlate.Id })).result;
  
          let serialnumberTasks = (await $datasources.AsnOrders.ds_get_tasks_by_serialnumberIds.get({ serialnumberIds: serialnumbers.map(sn => sn.Id) })).result;
  
          for (let task of serialnumberTasks) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'Tasks',
                  id: task.Id,
                  entity: {
                      ActualTargetLicensePlateId: $row.cells.licenseplate.editControl.value,
                      ActualSourceLicensePlateId: $row.cells.licenseplate.editControl.value
                  }
              });
          }
  
          for (let serialnumber of serialnumbers) {
              await $flows.Utilities.crud_update_flow({
                  entitySet: 'SerialNumbers',
                  id: serialnumber.Id,
                  entity: {
                      LicensePlateId: $row.cells.licenseplate.editControl.value
                  }
              });
          }
      }
  
      // Serial Numbers
      let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: $row.cells.material.editControl.value })).result;
      let adjustedPackagedAmount = $row.cells.packaged_amount.editControl.value - $row.entity.ExpectedPackagedAmount;
  
      if (material[0].ControllerTypeId === 3 || material[0].ControllerTypeId === 4 || !material[0].IsFixedWeight || !material[0].IsFixedVolume || !material[0].IsFixedLength) {
          if (adjustedPackagedAmount > 0) { // Create SerialNumbers
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_creation_gridDialog({ materialId: material[0].Id, packagingId: $row.cells.packaging.editControl.value, packagedAmount: adjustedPackagedAmount });
  
              let serialnumbersResult = dialogResults.serialNumbers;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumbersResult) && serialnumbersResult.length === adjustedPackagedAmount) {
                  var serialNumbersToCreate = serialnumbersResult;
                  // Add material, lot, packaging and licenseplate as serial creation flow in the ASN library requires it when creating new serial numbers
                  var serialNumbersWithAdditionalData = serialNumbersToCreate.map(obj => ({
                      ...obj,
                      MaterialId: material[0].Id,
                      LotId: $row.cells.lot.editControl.value,
                      PackagingId: $row.cells.packaging.editControl.value,
                      LicensePlateId: $row.cells.licenseplate.editControl.value
                  }));
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
          else if (adjustedPackagedAmount < 0) {
              let dialogResults = await $shell.FootPrintManager.openserialnumbers_selection_gridDialog({
                  lotId: $row.cells.lot.editControl.value,
                  licenseplateId: $row.cells.licenseplate.editControl.value,
                  packagingId: $row.cells.packaging.editControl.value,
                  packagedAmountRequest: adjustedPackagedAmount * -1
              });
  
              let serialnumberIds = dialogResults.SerialNumberIds;
              let confirmResult = dialogResults.confirm;
  
              if (confirmResult === true && $utils.isDefined(serialnumberIds)) {
                  var serialNumbersToDelete = serialnumberIds;
              }
              else {
                  throw Error('No serial numbers were provided.');
              }
          }
          else {
              //throw new Error('Error creating serial numbers.')
          }
      }
  
      await $flows.AsnOrders.set_asn_receiving_task_flow({
          licenseplateId: $row.cells.licenseplate.editControl.value,
          lotId: $row.cells.lot.editControl.value,
          packagingId: $row.cells.packaging.editControl.value,
          orderId: $grid.inParams.orderId,
          materialId: $row.cells.material.editControl.value,
          vendorlotId: $row.cells.vendorlot.editControl.value,
          packagedAmount: $row.cells.packaged_amount.editControl.value,
          existingTaskId: $row.entity.Id,
          serialNumberIdsToDelete: serialNumbersToDelete,
          serialNumbersToCreate: serialNumbersWithAdditionalData
      });
  
      // Update Grid
      if ($row.cells.packaged_amount.editControl.value > 0) {
          $row.refresh();
      }
      else {
          $grid.rows.splice($grid.rows.indexOf($row), 1);
      }
  }
  catch (ex) {
      let message = $utils.isDefined(ex.error?.error?.message) ? ex.error.error.message : ex.message;
      $shell.FootPrintManager.openErrorDialog('Error', message);
      throw Error(message); // To prevent display mode
  }
  }
  on_row_data_loaded(event = null) {
    return this.on_row_data_loadedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_data_loadedInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.entity?.StatusId === 2 || $row.entity?.StatusId === 3) {
      $row.cells.licenseplate.editControl.readOnly = true;
      $row.cells.material.editControl.readOnly = true;
      $row.cells.vendorlot.editControl.readOnly = true;
      $row.cells.lot.editControl.readOnly = true;
      $row.cells.packaging.editControl.readOnly = true;
      $row.cells.packaged_amount.editControl.readOnly = true;
  } else if ($grid.inParams.orderStatusId !== 1) {
      $row.cells.material.editControl.readOnly = true;
      $row.cells.vendorlot.editControl.readOnly = true;
      $row.cells.lot.editControl.readOnly = true;
      $row.cells.packaging.editControl.readOnly = true;
      $row.cells.packaged_amount.editControl.readOnly = true;
  }
  else {
      if ($utils.isDefined($row.entity.Id)) {
          // $row.cells.licenseplate.editControl.readOnly = true;
      }
      else {
          $row.cells.licenseplate.editControl.readOnly = false;
      }
  
      if ($row.entity?.Material?.ControllerTypeId === 1 || $row.entity?.Material?.ControllerTypeId === 3) {
          $row.cells.vendorlot.editControl.readOnly = true;
          $row.cells.lot.editControl.readOnly = true;
      }
      else {
          $row.cells.vendorlot.editControl.readOnly = false;
          $row.cells.lot.editControl.readOnly = false;
      }
  
      if ($row.entity?.Material?.PackagingLookups?.length === 1) {
          $row.cells.packaging.editControl.readOnly = true;
      }
      else {
          $row.cells.packaging.editControl.readOnly = false;
      }
  }
  }
  on_material_changed(event = null) {
    return this.on_material_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_material_changedInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.cells.material.editControl.value;
  
  
  if ($row.cells.material.editControl.isChanged) {
      // Clear dependent fields
      $row.cells.lot.editControl.value = null;
      $row.cells.vendorlot.editControl.value = null;
      $row.cells.packaging.editControl.value = null;
      unlockCell($row.cells.lot);
      unlockCell($row.cells.vendorlot);
      unlockCell($row.cells.packaging);
  
      if ($utils.isDefined($row.cells.material.editControl.value)) {
          // Get material
          let material = (await $datasources.Materials.ds_get_material_by_materialId.get({ materialId: materialId })).result[0];
          if (!$utils.isDefined(material)) { return; }
  
          // Automatically set Lot if Non-lotted material
          if ((material.ControllerTypeId === 1 || material.ControllerTypeId === 3)) {
              let globalLot = (await $datasources.Lots.ds_get_lots_by_materialId.get({ materialId: material.Id })).result.filter(l => l.TypeId === 1)[0];
              if ($utils.isDefined(globalLot)) {
                  $row.cells.vendorlot.editControl.value = globalLot.VendorLotId;
                  lockCell($row.cells.vendorlot);
                  $row.cells.lot.editControl.value = globalLot.Id;
                  lockCell($row.cells.lot);
              }
          }
  
          // Default the packaging
          let packagings = (await $datasources.Materials.ds_get_material_packagings_by_materialId.get({ materialId: material.Id })).result;
  
          if ($utils.isDefined(packagings)) {
  
              let defaultPackaging = packagings.find(p => p.IsBasePackaging);
              $row.cells.packaging.editControl.value = defaultPackaging.PackagingId;
  
              if (packagings.length === 1) { lockCell($row.cells.packaging); }
  
              // Default the decimal formatting
              const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
                  materialId: materialId,
                  packagingId: defaultPackaging.PackagingId
              }));
  
              if ($utils.isDefined(decimalFormat)) {
                  $row.cells.packaged_amount.editControl.format = decimalFormat.format;
              }
  
          }
  
      }
  }
  
  //Locking down all cells for orders not in a created status. 
  if($grid.inParams.orderStatusId !== 1){
      lockCell($row.cells.packaging)
      lockCell($row.cells.material)
      lockCell($row.cells.lot)
      lockCell($row.cells.vendorlot)
      lockCell($row.cells.packaged_amount)
  }
  
  /************************************************* 
   * FUNCTIONS
  *************************************************/
  function lockCell(cell: any) {
      cell.editControl.readOnly = true;
      cell.editControl.styles.setStyle('background', 'transparent');
  }
  
  function unlockCell(cell: any) {
      cell.editControl.readOnly = false;
      cell.editControl.styles.resetStyle();
  }
  }
  on_packaging_changed(event = null) {
    return this.on_packaging_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_packaging_changedInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  var materialId = $row.cells.material.editControl.value;
  var packagingId = $row.cells.packaging.editControl.value;
  
  
  // Default the decimal formatting
  const decimalFormat = (await $flows.Materials.get_material_packaging_decimal_precision_formatted({
      materialId: materialId,
      packagingId: packagingId
  }));
  
  if ($utils.isDefined(decimalFormat)) {
      $row.cells.packaged_amount.editControl.format = decimalFormat.format;
  
  }
  
  
  
  }
  on_lot_changed(event = null) {
    return this.on_lot_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_lot_changedInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.lot.editControl.isChanged) {
      if ($utils.isDefined($row.cells.lot.editControl.value)) {
          let vendorlot = (await $datasources.Lots.ds_find_vendorlots.get({lotId: $row.cells.lot.editControl.value})).result[0];
          if ($utils.isDefined(vendorlot)) {
              $row.cells.vendorlot.editControl.value = vendorlot.Id;
          }
      }
  }
  }
  on_vendorlot_changed(event = null) {
    return this.on_vendorlot_changedInternal(
      this,
  this.grid, this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_vendorlot_changedInternal(
    $row: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel,
  $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent, 
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  if ($row.cells.vendorlot.editControl.isChanged) {
      $row.cells.lot.editControl.value = null;
  }
  }
  //#endregion private flows

}


@Component({
  standalone: true,
  imports: [
    SharedModule,
    forwardRef(() => AsnOrders_licenseplates_by_order_dd_singleComponent),
    forwardRef(() => Materials_materials_by_project_dd_singleComponent),
    forwardRef(() => FootPrintManager_lots_dd_singleComponent),
    forwardRef(() => FootPrintManager_vendorlots_dd_singleComponent),
    forwardRef(() => Materials_material_packagings_dd_singleComponent),
  ],
  selector: 'FootPrintManager-asn_order_receiving_tasks_grid',
  templateUrl: './FootPrintManager.asn_order_receiving_tasks_grid.component.html'
})
export class FootPrintManager_asn_order_receiving_tasks_gridComponent implements OnInit, OnChanges, OnDestroy {
  //#region Outputs
  @Output()
  $finish = new EventEmitter();
  @Output()
  $refreshEvent = new EventEmitter();
  //#endregion Outputs

  entities: IFootPrintManager_asn_order_receiving_tasks_gridComponentEntity[];

  pageSize = 10;
  pageSkip = 0;
  totalCount = 0;
  loadingStatus = ELoadingStatus.Loading;

canEdit: boolean = true;
// to stop add from flow
// canAdd = false; //hide add button, check on exitFromLastCell 
// bottomToolbar.addLine.hidden = true; //hide add button, no check on exitFromLastCell 
// bottomToolbar.addLine.control.readOnly = true; //disable add button, check on exitFromLastCell 
canAdd: boolean = true; 
  
  containerStyles: GridContainerStyle = new GridContainerStyle('headersWidth', null);

  fullTextSearch: string;

  inParams: IFootPrintManager_asn_order_receiving_tasks_gridComponentInParams = { orderId: null, orderStatusId: null };


  //#region Variables
  vars: { projectId?: number, shipmentId?: number, warehouseId?: number, defaultReceivingLocationId?: number } = { };
  //#endregion
  //#region Events
  
  //#endregion

  headers = {
     status: new GridHeaderModel(new HeaderStyles(null, null), 'Status', false, false, null),       current_location: new GridHeaderModel(new HeaderStyles(null, null), 'Current location', false, false, null),       licenseplate: new GridHeaderModel(new HeaderStyles(null, null), 'License plate', false, false, null),       material: new GridHeaderModel(new HeaderStyles(null, null), 'Material', false, false, null),       lot: new GridHeaderModel(new HeaderStyles(null, null), 'Lot', false, false, null),       vendorlot: new GridHeaderModel(new HeaderStyles(null, null), 'Vendor lot', false, false, null),       packaged_amount: new GridHeaderModel(new HeaderStyles(null, null), 'Packaged amount', false, false, null),       packaging: new GridHeaderModel(new HeaderStyles(null, null), 'Packaging', false, false, null),  
  };

  //#region title
  // Make it async so that it won't cause expressionChangedAfterItHasBeenCheckedError
  // The title is often meant to be shown from the parent (shell breadcrumb for example)
  // and often it will cause an expressionChangedAfterItHasBeenCheckedError because 
  // the parent has already been checked and the child now change something on the parent 
  // in dev, CD is run twice
  $titleChange = new EventEmitter<string>(true);
  private $_title: string;
  get title(): string {
    return this.$_title;
  }
  set title(t: string) {
    this.$_title = t;
    this.$titleChange.emit(this.$_title);
  }
  //#endregion title
  rows: FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel[] = [];
  @ViewChild('$gridComponent', { read:  GridComponent}) $gridComponent: GridComponent;

  @Input('orderId') set $inParams_orderId(value: any) {
    this.inParams['orderId'] = value;
  }
  get $inParams_orderId(): any {
    return this.inParams['orderId'] ;
  }
  @Input('orderStatusId') set $inParams_orderStatusId(value: any) {
    this.inParams['orderStatusId'] = value;
  }
  get $inParams_orderStatusId(): any {
    return this.inParams['orderStatusId'] ;
  }

  topToolbar = {
      receive_tasks: new ToolModel(new ButtonModel('receive_tasks', new ButtonStyles(null, null), false, 'Receive', 'icon-datex-Receive')
    ),
      separator1: new ToolModel(new SeparatorModel(new Styles(null, null))
    ),
      cancel_tasks: new ToolModel(new ButtonModel('cancel_tasks', new ButtonStyles(null, null), false, 'Cancel', 'ms-Icon ms-Icon--CalculatorMultiply')
    ),
      delete_tasks: new ToolModel(new ButtonModel('delete_tasks', new ButtonStyles(['destructive'], null), false, 'Delete', 'ms-Icon ms-Icon--Delete')
    )
  };

  bottomToolbar = {
    addLine : new ToolModel(new ButtonModel(null, null, false, 'Add row', 'icon-ic_fluent_add_circle_20_regular')),
  };



  constructor(
    private utils: UtilsService,
    private settings: SettingsValuesService,
    private shell: FootPrintManager_ShellService,
    private datasources: FootPrintManager_DatasourceService,
    private flows: FootPrintManager_FlowService,
    private reports: FootPrintManager_ReportService,
    private localization: FootPrintManager_LocalizationService,
    private operations: FootPrintManager_OperationService,
    ) {
    this.title = 'Receiving tasks';
    this.$subscribeFormControlValueChanges();
  }

  ngOnInit(): void {
    this.$checkRequiredInParams();
    if(!this.$hasMissingRequiredInParams) {
      this.$init();
    }
  }
  
  private $isFirstNgOnChanges = true;
  ngOnChanges(changes: SimpleChanges): void {
    if (this.$isFirstNgOnChanges) {
      this.$isFirstNgOnChanges = false;
    } else {
      this.$checkRequiredInParams();
      if(!this.$hasMissingRequiredInParams) {
        this.$init();
      }
    }
  }

  $missingRequiredInParams = [];
  get $hasMissingRequiredInParams(): boolean {
    return !!this.$missingRequiredInParams.length;
  }
  
  $checkRequiredInParams() {
    this.$missingRequiredInParams = [];
      if(isNil(this.inParams.orderId)) {
        this.$missingRequiredInParams.push('orderId');
      }
      if(isNil(this.inParams.orderStatusId)) {
        this.$missingRequiredInParams.push('orderStatusId');
      }
  }

  initialized = false;

  private $unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.$unsubscribe$.next(null);
    this.$unsubscribe$.complete();
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
  }

  async $init() {
    const $grid = this;
    const $utils = this.utils;


    this.initialized = true;

    this.pageSkip = 0;
    this.refresh(true, true, null);
  }

  private $subscribeFormControlValueChanges() {

  }

  reload() {
    this.pageSkip = 0;
    this.refresh();
  }

  refresh(
    skipParent = false,
    skipChildren = false,
    childToSkip: string = null) {
    if (this.$hasMissingRequiredInParams) {
      return Promise.resolve(null);
    }
    // up
    if (skipParent === false) {
      this.$refreshEvent.emit();
    }

    // self
    const result = this.$dataLoad();

    // children
    if (skipChildren === false) {
    }

    return result;
  }

  $rowPropertyChangeCallback (source: GridRowModel, property: string): void {
    if (property === 'selected') {
      this.$gridComponent.updateAllSelected();
    }
  }

  async $dataLoad() {
    this.loadingStatus = ELoadingStatus.Loading;
    const $grid = this;
    const $utils = this.utils;
    const inParams = {
      $top: this.pageSize,
      $skip: this.pageSkip,
      licenseplateId:  null ,
      orderId:  $grid.inParams.orderId ,
      fullTextSearch:  $grid.fullTextSearch ,
    };
    try {
    const data = await this.datasources.AsnOrders.ds_asn_order_licenseplates_receiving_tasks_grid.getList(inParams);
      this.entities = data.result;
      this.totalCount = data.totalCount;
      await this.$dataLoaded();
      if (this.totalCount === 0) {
        this.loadingStatus = ELoadingStatus.NoResults;
      } else {
        this.loadingStatus = ELoadingStatus.Loaded;
      }
    } catch(error) {
      console.error("Error loading data:", error);
      this.loadingStatus = ELoadingStatus.Error;
    }
  }

  async $dataLoaded() {
    const $grid = this;
    const $utils = this.utils;
    
    if(this.rows && this.rows.length) {
      this.rows.forEach(r => r.destroy());
    }
    this.rows = [];

    if(this.entities) {
      let rowLoadPromises = [];
      for (let entity of this.entities) {
        const row = new FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel(
          this.utils,
          this.settings,
          this.shell, 
          this.datasources,
          this.flows,
          this.reports,
          this.localization,
          this.operations);
        rowLoadPromises.push( row.$initializeExisting(this, entity, this.$rowPropertyChangeCallback.bind(this)));
        this.rows.push(row);
      }
      await Promise.all(rowLoadPromises);
    }

    await this.on_data_loaded();
  }

  selectedRows = [];
  hasSelectedRows() {
    return this.selectedRows.length > 0;
  }

  $selectionChanged(selectedRows: any[]) {
    this.selectedRows = selectedRows;
    this.on_row_selected();
  }

  async addRow(entity?: IFootPrintManager_asn_order_receiving_tasks_gridComponentEntity) {
    const row = new FootPrintManager_asn_order_receiving_tasks_gridComponentRowModel(
      this.utils,
      this.settings,
      this.shell, 
      this.datasources,
      this.flows,
      this.reports,
      this.localization,
      this.operations);
    await row.$initializeNew(this, entity, this.$rowPropertyChangeCallback.bind(this));
    this.rows.push(row);
    row.setEditMode();
    return row;
  }
  
  openImageViewer(imageSource: string) {
    this.shell.openImageViewerDialog(imageSource);
  }

  //#region private flows
  on_delete_tasks_clicked(event = null) {
    return this.on_delete_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_delete_tasks_clickedInternal(
    $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let confirmDelete = await $shell.FootPrintManager.openConfirmationDialog('Delete tasks', 'Are you sure you want to delete the selected tasks?', 'Confirm', 'Cancel');
  
  if ($utils.isDefined(confirmDelete) && confirmDelete) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId !== 1) {
              var serialNumbers = (await $flows.AsnOrders.get_serials_to_delete_with_children_flow({ licenseplateId: row.entity.ActualTargetLicensePlate.Id }))
              if ($utils.isDefined(serialNumbers)) {
                  var parentSerials = serialNumbers.parentSerialNumbers
                  if ($utils.isDefined(serialNumbers.fourthChildSerials)) {
                      let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.fourthChildSerials })).reasons
                      if ($utils.isDefined(result)) {
                          $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.fourthChildSerials}`, result.join(' ,'))
                      }
                  }
                  if ($utils.isDefined(serialNumbers.thirdChildSerials)) {
                      let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.thirdChildSerials })).reasons
                      if ($utils.isDefined(result)) {
                          $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.thirdChildSerials}`, result.join(' ,'))
                      }
                  }
                  if ($utils.isDefined(serialNumbers.secondChildSerials)) {
                      let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.secondChildSerials })).reasons
                      if ($utils.isDefined(result)) {
                          $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.secondChildSerials}`, result.join(' ,'))
                      }
                  }
                  if ($utils.isDefined(serialNumbers.firstChildSerials)) {
                      let result = (await $flows.AsnOrders.delete_children_serial_numbers_flow({ serialnumberIds: serialNumbers.firstChildSerials })).reasons
                      if ($utils.isDefined(result)) {
                          $shell.FootPrintManager.openErrorDialog(`Error deleting children serial numbers ${serialNumbers.firstChildSerials}`, result.join(' ,'))
                      }
                  }
              }
          }
  
          await $flows.AsnOrders.set_asn_receiving_task_flow({
              licenseplateId: row.entity.ActualTargetLicensePlate.Id,
              lotId: row.entity.Lot.Id,
              packagingId: row.entity.ExpectedPackagedPack.Id,
              orderId: $grid.inParams.orderId,
              materialId: row.entity.Material.Id,
              vendorlotId: row.entity.VendorLot.Id,
              packagedAmount: 0,
              existingTaskId: row.entity.Id,
              serialNumberIdsToDelete: parentSerials
          });
      }
  
      $grid.refresh();
  }
  }
  on_row_selected(event = null) {
    return this.on_row_selectedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_row_selectedInternal(
    $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  const orderStatusId = $grid.inParams.orderStatusId;
  
  $grid.topToolbar.delete_tasks.control.readOnly = true;
  $grid.topToolbar.receive_tasks.control.readOnly = true;
  $grid.topToolbar.cancel_tasks.control.readOnly = true;
  
  // No rows selected
  if ($grid.selectedRows.length > 0) {
      if (orderStatusId === 1) {
          $grid.topToolbar.cancel_tasks.control.readOnly = !(await checkAllCancelable());
          $grid.topToolbar.delete_tasks.control.readOnly = !(await checkAllDeletable());
      } else if (orderStatusId === 2) {
          $grid.topToolbar.receive_tasks.control.readOnly = !(await checkAllReceivable());
          $grid.topToolbar.cancel_tasks.control.readOnly = !(await checkAllCancelable());
      }
  }
  
  /**************************************************** 
   * FUNCTIONS
  *****************************************************/
  async function checkAllDeletable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          let taskStatusId = row.entity.StatusId
          if (taskStatusId != 4) {
              return false;
          }
      }
      return allow;
  }
  
  async function checkAllReceivable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          let taskStatusId = row.entity.StatusId
          if (taskStatusId != 4) {
              return false;
          }
      }
      return allow;
  }
  
  async function checkAllCancelable() {
      let allow = $grid.selectedRows.length > 0;
  
      for (let row of $grid.selectedRows) {
          let taskStatusId = row.entity.StatusId
          if (taskStatusId != 4) {
              return false;
          }
      }
      return allow;
  }
  }
  on_receive_tasks_clicked(event = null) {
    return this.on_receive_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_receive_tasks_clickedInternal(
    $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  
  let isConfirm = (await $shell.FootPrintManager.openauto_receive_and_putaway_formDialog({
      orderId: $grid.inParams.orderId,
      taskIds: $grid.selectedRows.map(r => r.entity.Id)
  })).isConfirm;
  
  if (isConfirm) {
      await $grid.refresh();
  }
  
  
  
  }
  on_cancel_tasks_clicked(event = null) {
    return this.on_cancel_tasks_clickedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_cancel_tasks_clickedInternal(
    $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let confirmDelete = await $shell.FootPrintManager.openConfirmationDialog('Cancel tasks', 'Are you sure you want to cancel the selected tasks?', 'Confirm', 'Cancel');
  
  if ($utils.isDefined(confirmDelete) && confirmDelete) {
      for (let row of $grid.selectedRows) {
          if (row.entity.StatusId !== 2 && row.entity.StatusId !== 3) {
  
              await $flows.AsnOrders.cancel_receiving_task_flow({ taskId: row.entity.Id });
          }
      }
  
      $grid.refresh();
  }
  }
  on_data_loaded(event = null) {
    return this.on_data_loadedInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async on_data_loadedInternal(
    $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let order = (await $datasources.AsnOrders.ds_get_order_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  let shipments = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  if (!$utils.isDefined(shipments)) {
      throw new Error(`No shipments exist for order '${order.LookupCode}'.`);
  }
  
  $grid.vars.projectId = order.ProjectId;
  $grid.vars.shipmentId = shipments[0].ShipmentId;
  $grid.vars.warehouseId = shipments[0].Shipment.ActualWarehouseId ?? shipments[0].Shipment.ExpectedWarehouseId;
  
  let warehouse = (await $datasources.Locations.ds_get_warehouse_by_warehouseId.get({ warehouseId: $grid.vars.warehouseId })).result;
  
  $grid.vars.defaultReceivingLocationId = warehouse.DefaultReceivingLocationId;
  
  if($grid.inParams.orderStatusId !== 1){
      $grid.topToolbar.delete_tasks.control.readOnly = true;
  }
  
  
  // Call common flow to apply the role based permissions
  await $grid.apply_operations();
  
  }
  apply_operations(event = null) {
    return this.apply_operationsInternal(
      this,
  this.shell,
      this.datasources,
      this.flows,
      this.reports,
      this.settings,
      this.operations,
      this.utils,
      // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
      // this.localization,
      event);
  }
  async apply_operationsInternal(
    $grid: FootPrintManager_asn_order_receiving_tasks_gridComponent,
  
    $shell: FootPrintManager_ShellService,
    $datasources: FootPrintManager_DatasourceService,
    $flows: FootPrintManager_FlowService,
    $reports: FootPrintManager_ReportService,
    $settings: SettingsValuesService,
    $operations: FootPrintManager_OperationService,
    $utils: UtilsService,
    // Localization was developed as a POC while working on a spike 123236. This $l10n is hidden for now.
    //$l10n: FootPrintManager_LocalizationService,
    $event: any
  ) {
  let results = (await $datasources.AsnOrders.ds_get_shipments_by_orderId.get({ orderId: $grid.inParams.orderId })).result;
  
  if ($utils.isDefined(results)) {
  
      var shipmentStatus = results[0].Shipment?.StatusId;
      if ($utils.isDefined(shipmentStatus)) {
  
          // Check for completed or cancelled
          if (shipmentStatus !== 8 && shipmentStatus !== 16) {
  
              if (shipmentStatus == 1) {
                  $grid.canEdit = true;
                  $grid.canAdd = true;
  
              }
  
              else if (await $operations.FootPrintManager.Disable_AsnOrders_LicenseplateEditing.isAuthorized()) {
                  $grid.canEdit = false;
                  $grid.canAdd = false;
  
              }
              else {
                  $grid.canEdit = true;
                  $grid.canAdd = true;
              }
  
          }
          else {
              $grid.canEdit = false;
              $grid.canAdd = false;
          }
  
  
      }
  
  }
  
  
  
  //Disable entire grid
  if (await $operations.FootPrintManager.Disable_AsnOrders_OrderEdits.isAuthorized()) {
      $grid.canEdit = false;
      $grid.canAdd = false;
  
      $grid.topToolbar.cancel_tasks.control.readOnly = true;
      $grid.topToolbar.delete_tasks.control.readOnly = true;
      $grid.topToolbar.receive_tasks.control.readOnly = true;
  }
  }
  //#endregion private flows


 
  close() {
    this.$finish.emit();
  }
}
